<template>
    <div class="wrap">
        <el-drawer v-model="drawerDisplay" title="详情" size="60%" @close="onCancel">
            <div class="content">
                <div class="box">
                    <div class="title">发货日期：</div>
                    <div>{{ $utils.formatDate(data.send_time).split(' ')[0] }}</div>
                </div>
                <div class="box">
                    <div class="title">订单型号：</div>
                    <div>{{ data.order }}</div>
                </div>
                <div class="box">
                    <div class="title">收货人（客户）：</div>
                    <div>{{ data.custom }}</div>
                </div>
                <div class="box">
                    <div class="title">联系电话：</div>
                    <div>{{ data.phone }}</div>
                </div>
                <div class="box">
                    <div class="title">订单数量：</div>
                    <div>{{ data.order_num }}</div>
                </div>
                <div class="box">
                    <div class="title">发货地址：</div>
                    <div>{{ data.address }}</div>
                </div>
                <div class="box">
                    <div class="title">物流：</div>
                    <div>{{ data.ems }}</div>
                </div>
                <div class="box">
                    <div class="title">物流号：</div>
                    <div>{{ data.ems_num }}</div>
                </div>
            </div>
        </el-drawer>
    </div>
</template>

<script setup>
import { ref, watch, defineProps } from 'vue';

const emit = defineEmits(['cancel'])

const props = defineProps({
    data: {
        type: Object,
        default: () => {
            return {};
        },
    },
});

const drawerDisplay = ref(false);

watch(
    () => props.data.id,
    (newVal) => {
        drawerDisplay.value = true;
    }
);


// 取消
const onCancel = () => {
    drawerDisplay.value = false;
	emit('cancel')
};
</script>

<style scoped>
.content {
    display: flex;
    flex-wrap: wrap;
}
.box {
	display: flex;
    width: 40%;
}

.title {
    width: 110px;
}
</style>
