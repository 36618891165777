<template>
    <div>
        <mainone :mainonebread="mainonebread"></mainone>
        <dpwCard title="添加入库单">
            <template #content>
                <div class="content-box">
                    <div class="content-box-div">
                        <el-form ref="ruleForm" :model="ruleForm" :rules="rules" label-width="110px" class="demo-ruleForm">
                            <dpwTitle title="基本信息"></dpwTitle>
                            <div>
                                <el-form-item label="入库类型：" prop="in_type">
                                    <el-select v-model="ruleForm.in_type" filterable placeholder="" style="width: 100%">
                                        <el-option v-for="(item, index) in in_type_options" :key="index" :label="item.name" :value="item.id" />
                                    </el-select>
                                </el-form-item>
                                <el-form-item label="入库日期：" prop="in_time">
                                    <el-date-picker v-model="ruleForm.in_time" type="date" placeholder="请选择入库日期" style="width: 100%"> </el-date-picker>
                                </el-form-item>
                                <el-form-item label="供应商：" prop="supplier_id">
                                    <el-select v-model="ruleForm.supplier_id" placeholder="" style="width: 100%">
                                        <el-option v-for="(item, index) in supplier_id_options" :key="index" :label="item.name" :value="item.id" />
                                    </el-select>
                                </el-form-item>
                                <el-form-item label="仓库：" prop="pack_id">
                                    <el-select v-model="ruleForm.pack_id" placeholder="" style="width: 100%">
                                        <el-option v-for="(item, index) in pack_id_options" :key="index" :label="item.name" :value="item.id" />
                                    </el-select>
                                </el-form-item>
                                <el-form-item label="入库单照片：" prop="imgs">
                                    <div>
                                        <mainimgesup
                                            :model="ruleForm.imgs"
                                            :myimgesup="myimgesup"
                                            :myimgesupisshow="myimgesupisshow"
                                            :filelist="ruleForm.imgs_list"
                                            v-on:getuploadfile="(data) => getUploadFileEvent(data)"
                                            v-on:deluploadfile="(data) => deluploadfileEvent(data)"
                                        >
                                        </mainimgesup>
                                    </div>
                                </el-form-item>
                                <el-form-item label="备注：" prop="remark">
                                    <el-input v-model="ruleForm.remark" type="textarea" clearable maxlength="200" show-word-limit></el-input>
                                </el-form-item>
                            </div>
                            <dpwTitle title="产品列表"></dpwTitle>
                            <div>
                                <el-form-item prop="goods" label-width="0">
                                    <addGoodsInfo
                                        :table="{ tabletitle: goods_title, tableData: goods_info, has_tableData: tableData }"
                                        :default_multipleSelection="multipleSelection"
                                        @multipleSelection="multipleSelectionEvent"
                                        @tanPostData="(data) => tanPostData(data, 1)"
                                    />
                                </el-form-item>
                                <dpwTable
                                    v-loading="loading"
                                    element-loading-text="正在飞速加载中..."
                                    ref="myTableRef"
                                    :tabletitle="tableTitle"
                                    :tabledata="tableData"
                                    :totalcount="totalCount"
                                    :currentpage="currentPage"
                                    :pagesize="PageSize"
                                    :Lastpage="lastpage"
                                    :Operation="{ isshow: true, width: 280 }"
                                >
                                    <template #content="{ scope, prop }">
                                        <div v-if="prop === 'in_type'">
                                            <span>{{ go_in_type_options[scope.row[prop]].text }}</span>
                                        </div>
                                        <div v-else-if="prop === 'goods_num'">
                                            <div v-if="scope.row['in_type'] == 2">
                                                <onlyKeysTable
                                                    :vmodel="scope.row[prop]"
                                                    :span_style="{ color: 'var(--el-color-primary)', cursor: 'pointer' }"
                                                    :table="{ tabletitle: tan_table_title, tableData: scope.row.goods_info, Operation: { isshow: true, width: 120 } }"
                                                    :dialog="{ title: '实收数量-详情【' + scope.row['name'] + '】', width: '30%' }"
                                                    @tanPostData="(data) => tanPostData(data, 4, scope.$index)"
                                                />
                                            </div>
                                            <div v-else>
                                                <span>{{ scope.row[prop] }}</span>
                                            </div>
                                        </div>
                                        <div v-else>
                                            <span>{{ scope.row[prop] }}</span>
                                        </div>
                                    </template>
                                    <template #special="{ scope }">
                                        <div class="my-flex-row">
                                            <numberEnter
                                                v-if="scope.row['in_type'] == 1"
                                                :vmodel="scope.row.goods_num"
                                                @tanPostData="(data) => tanPostData(data, 2, scope.$index)"
                                            />
                                            <textEnter
                                                v-if="scope.row['in_type'] == 2"
                                                :vmodel="scope.row.goods_info"
                                                @tanPostData="(data) => tanPostData(data, 3, scope.$index)"
                                            />
                                            <div v-if="scope.row['in_type'] == 2">
                                                <mainimgesup
                                                    ref="uploadfile"
                                                    :myimgesup="myimgesup_1"
                                                    :myimgesupisshow="myimgesupisshow_1"
                                                    :disabled="disabled"
                                                    v-on:getuploadfileIsData="(data) => upgetUploadFileEvent(data, scope.$index)"
                                                    v-on:beginUpload="beginUploadEvent"
                                                    v-on:errorUpload="errorUploadEvent"
                                                >
                                                </mainimgesup>
                                            </div>
                                            <el-button type="danger" size="small" @click="delEvent(scope.$index)">删除</el-button>
                                        </div>
                                    </template>
                                </dpwTable>
                            </div>
                            <div style="margin: 40px 0">
                                <el-button type="primary" style="width: 40%; min-width: 200px" @click="submitForm('ruleForm')" :disabled="tijiao_disabled">提交入库单</el-button>
                                <el-button @click="resetForm('ruleForm')">取消</el-button>
                            </div>
                        </el-form>
                    </div>
                </div>
            </template>
        </dpwCard>
    </div>
</template>

<script>
import mainone from '@/components/Mainone'
import mainimgesup from '@/components/Mainimgesup.vue'
import dpwCard from '../../acommon/dpw-card.vue'
import dpwTitle from '../../acommon/dpw-title.vue'
import dpwTable from '../../acommon/dpw-table.vue'
import dict from '../../assets/js/dict.js'
import axios from 'axios'
import { defineComponent, ref } from 'vue'
import { ElMessageBox, ElMessage } from 'element-plus'
import textEnter from '../../acommon/models/textEnter.vue'
import numberEnter from '../../acommon/models/numberEnter.vue'
import onlyKeysTable from '../../acommon/models/onlyKeysTable.vue'
import addGoodsInfo from '../../acommon/models/addGoodsInfo.vue'

export default defineComponent({
    name: 'vgoinmonadadd',
    inject: ['reload'], //刷新引用
    data() {
        return {
            mainonebread: [
                {
                    name: '仓库'
                },
                {
                    name: '出入库管理'
                },
                {
                    name: '入库单'
                },
                {
                    name: '添加入库单'
                }
            ],

            in_type_options: [],
            supplier_id_options: [],
            pack_id_options: [],
            goods_info: [],
            ruleForm: {
                in_type: '',
                in_time: '',
                supplier_id: '',
                pack_id: '',
                imgs: '',
                imgs_list: [],
                remark: '',
                goods: []
            },
            rules: {
                in_type: [
                    {
                        required: true,
                        message: '入库类型为必选项',
                        trigger: 'blur'
                    }
                ],
                in_time: [
                    {
                        required: true,
                        message: '入库日期为必选项',
                        trigger: 'blur'
                    }
                ],
                supplier_id: [
                    {
                        required: true,
                        message: '供应商为必选项',
                        trigger: 'blur'
                    }
                ],
                pack_id: [
                    {
                        required: true,
                        message: '仓库为必选项',
                        trigger: 'blur'
                    }
                ],
                imgs: [
                    {
                        required: false,
                        message: '入库单照片为必选项',
                        trigger: 'blur'
                    }
                ],
                remark: [
                    {
                        required: false,
                        message: '备注为必选项',
                        trigger: 'blur'
                    }
                ],
                goods: [
                    {
                        required: true,
                        message: '入库产品不能为空',
                        trigger: 'blur'
                    }
                ]
            },

            tableTitle: [
                {
                    fixed: 'left',
                    prop: 'id',
                    label: 'ID',
                    width: ''
                },
                {
                    fixed: false,
                    prop: 'name',
                    label: '产品名称',
                    width: ''
                },
                {
                    fixed: false,
                    prop: 'goods_cate',
                    label: '类别',
                    width: ''
                },
                {
                    fixed: false,
                    prop: 'in_type',
                    label: '填报方式',
                    width: ''
                },
                {
                    fixed: false,
                    prop: 'goods_num',
                    label: '实收数量',
                    width: ''
                }
            ],
            tableData: [],
            totalCount: 0, //数据列表中-数据总条数
            currentpage: 1, //数据列表中-数据当前页数
            lastpage: 1, //数据列表中-数据总页数（默认以20条一页）
            loading: false, //初始化-loading加载动效，默认是false关闭
            currentPage: 1,
            pageSizes: [10, 20, 50, 100],
            PageSize: 1000,

            go_in_type_options: dict.wmsystem.go_in_type_options,
            // 添加产品入库
            goods_title: [
                {
                    fixed: 'left',
                    prop: 'id',
                    label: 'ID',
                    width: ''
                },
                {
                    fixed: false,
                    prop: 'name',
                    label: '产品名称',
                    width: ''
                },
                {
                    fixed: false,
                    prop: 'goods_cate',
                    label: '类别',
                    width: ''
                },
                {
                    fixed: false,
                    prop: 'in_type',
                    label: '填报方式',
                    width: ''
                }
            ],
            multipleSelection: [],

            // 实收数量
            tan_table_title: [
                {
                    fixed: false,
                    prop: 'only_key',
                    label: '唯一标识',
                    width: ''
                }
            ],

            // 入库单照片
            myimgesupisshow: true, //form表单-imgesuploade上传图片
            myimgesup: {
                uploadsize: 1, // 1-前端直传 oss ； 2-前端传后端
                listtype: 'picture', //list展示类型，text/picture/picture-card；默认不填为text
                ismust: true, //是否必填，true表示必填
                action: '/manage/upload/file11', //upload上传API地址
                headers: { Auth: localStorage.getItem('token') }, //upload上传headers
                title: '上传文件', //button-按钮文字
                tips: '最多上传 5 张，支持扩展名：*.jpeg、*.jpg、*.png', //提示&建议文字
                limit: '5', //上传数量限制
                multiple: true,
                target_id: 100, //上传文件target_id ：图片分类，区分存储文件和存储时长，不传默认存储1天后过期；1-二维码； 2-打印文件； 3-文库文件； 4-课程文件； 5-广告主宣传页； 6-广告主产生的pdf文件； 7-证件照文件； 50-设备广告； 100-通用
                rules: {
                    images: {
                        image_width: 0, //图片宽度，0表示不限宽
                        image_height: 0, //图片高度，0表示不限高
                        size: 20000, //文件大小（单位kb）
                        type: ['image/jpeg', 'image/jpg', 'image/png'], //文件格式
                        typechart: 'png/jpg/jpeg' //文件格式提示规则
                    }
                }
            },

            //  Excel 导入
            myimgesupisshow_1: true,
            myimgesup_1: {
                uploadsize: 2, // 1阿里 oss ；2前端传后端
                listtype: 'picture', //list展示类型，text/picture/picture-card；默认不填为text
                showfilelist: false, //list展示列表是否显示，显示true，隐藏false
                ismust: true, //是否必填，true表示必填
                action: '/manage/pack/packInExcel', //upload上传API地址
                headers: { Auth: localStorage.getItem('token') }, //upload上传headers
                multiple: false, //上传多个文件
                title: 'Excel导入', //button-按钮文字
                title_type: 'primary', //button-按钮显示样式,primary&text
                title_size: 'small', //button-按钮大小，medium / small / mini
                title_plain: false, //button-按钮是否为线性（plain）
                isshow: false, //button-按钮是否显示，注意：false表示显示，true表示不显示
                tips: '', //提示&建议文字
                limit: '', //上传数量限制
                target_id: 100, //上传文件target_id ：图片分类，区分存储文件和存储时长，不传默认存储1天后过期；1-二维码； 2-打印文件； 3-文库文件； 4-课程文件； 5-广告主宣传页； 6-广告主产生的pdf文件； 7-证件照文件； 50-设备广告； 100-通用；
                rules: {
                    files: {
                        size: 200000, //文件大小（单位kb）
                        type: ['application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'], //文件格式
                        typechart: 'Excel' //文件格式提示规则
                    }
                }
            },
            disabled: false,

            tijiao_disabled:false,

            mypowerlimits: JSON.parse(localStorage.getItem('powerlimits')), //获取权限
            mytoken: localStorage.getItem('token'), //获取存储的token
            pagesdatas: [] //存储最近一次的筛选条件，供分页使用
        }
    },
    methods: {
        exchengEvent(array_01, array_02) {
            let array = []
            let need_change = array_02.map((n) => {
                return n.id
            })
            array = array_01.map((n) => {
                if (need_change.includes(n.id)) {
                    return array_02
                        .map((m) => {
                            if (m.id == n.id) {
                                return m
                            }
                        })
                        .filter((k) => k)[0]
                } else {
                    return n
                }
            })
            return array
        },
        // 1-添加产品入库；2-数量; 3-文本录入; 4-实收数量
        tanPostData(data, flag, index) {
            if (flag == 1) {
                let array = this.exchengEvent(data.tableData, this.tableData)
                this.tableData = array
            } else if (flag == 2) {
                this.tableData[index].goods_num = data.number
            } else if (flag == 3) {
                this.tableData[index].goods_num = data.goods_num
                this.tableData[index].goods_info = data.goods_info
            } else if (flag == 4) {
                this.tableData[index].goods_num = data.goods_num
                this.tableData[index].goods_info = data.goods_info
            }
        },
        multipleSelectionEvent(value) {
            this.multipleSelection = value
        },
        // 删除-产品
        delEvent(index) {
            this.tableData.splice(index, 1)
            this.multipleSelectionEvent(this.tableData)
        },
        // 处理 goods 数据结构&异常抛出
        isDetermine() {
            let goods = this.tableData.map((n) => {
                let data = {}
                if (n.in_type == 2) {
                    data = {
                        id: n.id,
                        num: n.goods_num,
                        info: n.goods_info
                            ? n.goods_info.map((m) => {
                                  return m.only_key
                              })
                            : []
                    }
                } else {
                    data = {
                        id: n.id,
                        num: n.goods_num
                    }
                }
                return data
            })

            if (goods.length != 0) {
                goods.map((n, key) => {
                    if (n.num == 0) {
                        setTimeout(() => {
                            ElMessage({
                                message: '产品列表：第' + (key + 1) + '行，数据错误！实收数量不能为 0',
                                type: 'error',
                                duration: 5000
                            })
                        }, 1)
                        throw new Error('')
                    }
                })
            }

            return goods
        },
        // 提交入库单
        submitForm(formName) {
            let goods = this.isDetermine()
            this.ruleForm.goods = goods

            let getdatas = this.$refs[formName].model
            this.$refs[formName].validate((valid) => {
                if (valid) {
                    var post_datas = {
                        in_type: getdatas.in_type,
                        in_time: getdatas.in_time.valueOf() / 1000,
                        supplier_id: getdatas.supplier_id,
                        pack_id: getdatas.pack_id,
                        imgs: getdatas.imgs,
                        remark: getdatas.remark,
                        goods: getdatas.goods
                    }

                    this.postAxiosEvent(post_datas)
                        .then((data) => {
                            console.log('data:', data)
                            if (data != undefined && data.length > 0) {
                                let html_text = ''
                                data.forEach((element) => {
                                    html_text += '<div><span>' + element.id + '：' + element.info.join(", ") + '</span></div>'
                                })

                                ElMessageBox.confirm('<div><span>监测到有以下重复入库设备：</span></div>' + html_text, '温馨提示', {
                                    confirmButtonText: '确定',
                                    cancelButtonText: '取消',
                                    type: 'warning',
                                    dangerouslyUseHTMLString: true
                                })
                                    .then(() => {
                                        post_datas['is_ignore'] = 1
                                        this.postAxiosEvent(post_datas)
                                    })
                                    .catch(() => {
                                        ElMessage({
                                            type: 'info',
                                            message: '取消删除'
                                        })
                                        this.tijiao_disabled = false
                                    })
                            }
                        })
                        .catch((error) => {
                            console.error(error)
                            this.tijiao_disabled = false
                        })
                } else {
                    return false
                }
            })
        },
        postAxiosEvent(data) {
            this.tijiao_disabled = true
            return axios
                .post('/manage/pack/packInSave', data, {
                    headers: {
                        Auth: localStorage.getItem('token')
                    }
                })
                .then((response) => {
                    if (response.data.code === 0) {
                        if (response.data.result != null) {
                            return response.data.result
                        } else {
                            ElMessage({
                                type: 'success',
                                message: '提交成功！',
                                duration: 1000,
                                onClose: () => {
                                    this.$router.back()
                                    this.tijiao_disabled = false
                                }
                            })
                        }
                    }
                })
                .catch(function (error) {
                    console.log(error)
                    this.tijiao_disabled = false
                })
        },

        //重置form表单中填写的数据&跳转上一页
        resetForm(formName) {
            this.$refs[formName].resetFields()
            this.$router.back()
        },
        // 上传入库单照片
        //获取upload子组件回传的数据
        getUploadFileEvent(data) {
            // console.log('当前获取的回传数据：', data)
            this.ruleForm.imgs_list.push({ url: data.url, name: data.name })
            this.ruleForm.imgs = this.ruleForm.imgs_list
                .map((n) => {
                    return n.url
                })
                .filter((n) => n)
                .join(',')
            //特殊的内容再次进行了校验，如果符合检验规则，错误提示就是消失了。
            // this.$refs.ruleForm.validateField('imgs', (valid) => {
            //     if (!valid) {
            //         return
            //     }
            // })
        },
        // 删除上传的文件-事件（upload组件）
        deluploadfileEvent(data) {
            // console.log('当前删除事件-返回的数据：', data)
            this.ruleForm.imgs_list = data.map((n) => {
                return { url: n.url, name: n.name }
            })
            this.ruleForm.imgs = this.ruleForm.imgs_list
                .map((n) => {
                    return n.url
                })
                .filter((n) => n)
                .join(',')
            //特殊的内容再次进行了校验，如果符合检验规则，错误提示就是消失了。
            // this.$refs.ruleForm.validateField('imgs', (valid) => {
            //     if (!valid) {
            //         return
            //     }
            // })
        },

        // Excel 导入
        // 开始上传
        beginUploadEvent() {
            this.disabled = true
        },
        // 上传报错
        errorUploadEvent() {
            this.disabled = false
        },
        // 获取upload子组件回传的数据-file文件url等数据
        upgetUploadFileEvent(data, index) {
            let goods_info = data.result.map((n) => {
                return { only_key: n.text }
            })
            let has_goods = this.deepcopy(this.tableData[index].goods_info == '' ? [] : this.tableData[index].goods_info)
            let all_goods = [...has_goods, ...goods_info]
            this.tableData[index].goods_info = this.quChongEvent(all_goods)
            this.tableData[index].goods_num = this.tableData[index].goods_info.length

            // 删除upload列队中的数据
            this.$refs.uploadfile.handleRemove(data)
            this.disabled = false
        },
        //
        quChongEvent(array_data) {
            const uniqueArray = array_data.filter((item, index) => {
                return index === array_data.findIndex((obj) => obj.only_key === item.only_key)
            })
            return uniqueArray
        },

        // 深拷贝
        deepcopy(obj, cache = []) {
            function find(list, f) {
                return list.filter(f)[0]
            }

            // just return if obj is immutable value
            if (obj === null || typeof obj !== 'object') {
                return obj
            }

            // if obj is hit, it is in circular structure
            const hit = find(cache, (c) => c.original === obj)
            if (hit) {
                return hit.copy
            }

            const copy = Array.isArray(obj) ? [] : {}
            // put the copy into cache at first
            // because we want to refer it in recursive deepCopy
            cache.push({
                original: obj,
                copy
            })

            Object.keys(obj).forEach((key) => {
                copy[key] = this.deepcopy(obj[key], cache)
            })

            return copy
        },

        // 获取当前页面的配置信息
        getInfoEvent() {
            axios
                .post(
                    '/manage/pack/packInConfig',
                    {
                        is_check: 1
                    },
                    {
                        headers: {
                            Auth: localStorage.getItem('token')
                        }
                    }
                )
                .then((response) => {
                    let getdatas = response.data.result

                    this.in_type_options = getdatas.in_type
                    this.supplier_id_options = getdatas.supplier
                    this.pack_id_options = getdatas.pack
                    this.goods_info = getdatas.goods
                })
                .catch(function (error) {
                    console.log(error)
                })
        }
    },
    created() {
        if (localStorage.getItem('token') === null || !localStorage.getItem('token')) {
            this.isshow = false
            this.$router.push('/').catch((error) => error)
        } else {
            this.getInfoEvent()
        }
    },
    components: {
        mainone,
        mainimgesup,
        dpwCard,
        dpwTitle,
        dpwTable,
        textEnter,
        numberEnter,
        onlyKeysTable,
        addGoodsInfo
    }
})
</script>

<style scoped>
.content-box {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: flex-start;
    flex-wrap: nowrap;
    gap: 20px;
}
.content-box-div {
    flex: 1;
    max-width: 800px;
}
</style>
