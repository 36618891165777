<template>
    <div class="page-container">
        <div class="container-bread">
            风控 <el-icon><ArrowRight /></el-icon> 风控管理 <el-icon><ArrowRight /></el-icon> 批次款统计
        </div>
        <el-tabs class="container-tabs" v-model="active">
            <el-tab-pane label="月统计" name="1" lazy>
                <Statisics :type="2" :urlParams="urlParams" />
            </el-tab-pane>
            <el-tab-pane label="日统计" name="2" lazy>
                <Statisics :type="1" :urlParams="urlParams" />
            </el-tab-pane>
        </el-tabs>
    </div>
</template>

<script setup>
import { ArrowRight } from '@element-plus/icons'
import Statisics from './Statisics.vue'
import { ref } from 'vue'
import { useRoute } from 'vue-router'
const active = ref('1')
const urlParams = useRoute().query
</script>

<style lang="scss" scoped>
.page-container {
    .container-bread {
        font-size: 14px;
        color: #666;
        display: flex;
        align-items: center;
    }

    .container-tabs {
        background-color: #fff;
        margin-top: 8px;
        padding: 8px 16px 0px;
    }
}
</style>
