export default {
    wmsystem: {
        status_options: {
            '-1': { text: '待接单', color: 'var(--el-color-warning)' },
            1: { text: '已接单', color: 'var(--el-color-primary)' },
            2: { text: '已出库', color: 'var(--el-color-success)' },
            3: { text: '已撤单', color: 'var(--el-color-info)' },
        },

        go_in_type_options:{
            1: { text: '计数', color: '#606266' },
            2: { text: '扫码', color: 'var(--el-color-primary)' },
        }
    }
}
