<template>
    <div>
        <el-button :type="button.type" :plain="button.plain" :size="button.size" @click="tanClickEvent()">{{ button.text }}</el-button>
        <!-- 弹窗 -->
        <el-dialog v-model="tanbox.isshow" :title="dialog.title" :width="dialog.width" append-to-body :before-close="(done) => handleClose(done, 'tanNumbersRef')">
            <dpwTable
                v-loading="tanbox.loading"
                element-loading-text="正在飞速加载中..."
                ref="myTableDataRef"
                :tabletitle="table.tabletitle"
                :tabledata="tanbox.tableData"
                :totalcount="tanbox.totalCount"
                :currentpage="tanbox.currentPage"
                :pagesize="tanbox.PageSize"
                :Lastpage="tanbox.lastpage"
                :Operation="table.Operation"
                :selectionIsShow="true"
                @handleSelection="handleSelectionEvent"
                @handleSelectionOnline="handleSelectionChangeOnline"
            >
                <template #content="{ scope, prop }">
                    <div v-if="prop === 'in_type'">
                        <span>{{ scope.row[prop] ? go_in_type_options[scope.row[prop]].text : '' }}</span>
                    </div>
                    <div v-else>
                        <span>{{ scope.row[prop] }}</span>
                    </div>
                </template>
            </dpwTable>
            <div style="text-align: right; margin: 20px 0">
                <el-button @click="tanbox.isshow = false">取消</el-button>
                <el-button type="primary" @click="submitEvent(this.multipleSelection, (tanbox.isshow = false))">确定</el-button>
            </div>
        </el-dialog>
    </div>
</template>

<script>
import { defineComponent } from 'vue'
import dpwTable from '../dpw-table.vue'
import dict from '../../assets/js/dict.js'

export default defineComponent({
    name: 'addGoodsInfo',
    props: {
        button: {
            type: Object,
            default: () => {
                return {
                    text: '添加产品入库',
                    size: 'small',
                    type: 'primary',
                    plain: true
                }
            }
        },
        dialog: {
            type: Object,
            default: () => {
                return {
                    title: '数量',
                    width: '30%'
                }
            }
        },
        table: {
            type: Object,
            default: () => {
                return {
                    tabletitle: [
                        {
                            fixed: 'left',
                            prop: 'id',
                            label: 'ID',
                            width: ''
                        },
                        {
                            fixed: false,
                            prop: 'name',
                            label: '产品名称',
                            width: ''
                        },
                        {
                            fixed: false,
                            prop: 'goods_cate',
                            label: '类别',
                            width: ''
                        },
                        {
                            fixed: false,
                            prop: 'in_type',
                            label: '填报方式',
                            width: ''
                        },
                        {
                            fixed: false,
                            prop: 'goods_num',
                            label: '实收数量',
                            width: ''
                        }
                    ],
                    tableData: [],
                    has_tableData: [],
                    Operation: { isshow: false, width: 120 }
                }
            }
        },
        default_multipleSelection: {
            type: Array,
            default: () => {
                return []
            }
        }
    },
    data() {
        return {
            tanbox: {
                isshow: false,
                tableData: [],
                totalCount: 0,
                currentPage: 1,
                PageSize: 10000,
                pageSizes: [10, 20, 50, 100],
                lastpage: 1
            },
            multipleSelection: [],
            go_in_type_options: dict.wmsystem.go_in_type_options
        }
    },
    methods: {
        // ********* 多选 *********
        // 深拷贝
        deepcopy(obj, cache = []) {
            function find(list, f) {
                return list.filter(f)[0]
            }

            // just return if obj is immutable value
            if (obj === null || typeof obj !== 'object') {
                return obj
            }

            // if obj is hit, it is in circular structure
            const hit = find(cache, (c) => c.original === obj)
            if (hit) {
                return hit.copy
            }

            const copy = Array.isArray(obj) ? [] : {}
            // put the copy into cache at first
            // because we want to refer it in recursive deepCopy
            cache.push({
                original: obj,
                copy
            })

            Object.keys(obj).forEach((key) => {
                copy[key] = this.deepcopy(obj[key], cache)
            })

            return copy
        },
        // 去重
        reduce(datas) {
            let obj = {}
            let reduce = []
            reduce = datas.reduce(function (item, next) {
                //item为没有重复id的数组，next为当前对象
                obj[next.id] ? '' : (obj[next.id] = true && item.push(next))
                return item
            }, [])

            return reduce
        },
        // 弹窗-多选数据-选中
        handleSelectionEvent(datas) {
            let all_datas = [...this.multipleSelection, ...this.deepcopy(datas)]
            // 去重
            this.multipleSelection = this.reduce(all_datas)

            if (datas.length == 0) {
                this.multipleSelection = []
            }
        },
        // 弹窗-多选数据-取消
        handleSelectionChangeOnline({ datas, row }) {
            this.multipleSelection = this.multipleSelection.filter((n) => n.id != row.id)
            this.handleSelectionEvent(datas)
        },
        // 弹窗-确定
        submitEvent(datas) {
            let now_data = datas.map((n) => {
                return { ...n, ...{ goods_num: 0, goods_info: '' } }
            })
            console.log('this.multipleSelection:', this.multipleSelection)
            this.$emit('multipleSelection', this.multipleSelection)
            this.$emit('tanPostData', { tableData: now_data })
        },

        // 弹窗-多选-检查反显
        moreDataBackShow(datas) {
            if (this.$refs.myTableDataRef) {
                this.$refs.myTableDataRef.backShow(datas)
            }
        },
        removeDuplicateObjects(arr_1, arr_2) {
            // 使用 filter() 方法从 arr1 中过滤掉与 arr2 相同的对象

            let arr1 = arr_1.map((n) => {
                return n.id
            })
            let arr2 = arr_2.map((n) => {
                return n.id
            })

            const filteredArray = arr1.concat(arr2).filter((item) => !arr1.includes(item) || !arr2.includes(item))
            let has_remove = arr_1
                .map((n) => {
                    if (filteredArray.includes(n.id)) {
                        return n
                    }
                })
                .filter((n) => n)

            return has_remove
        },
        // ************ 多选（end） *********************
        tanClickEvent() {
            this.tanbox.tableData = this.removeDuplicateObjects(this.table.tableData, this.table.has_tableData)
            this.tanbox.isshow = true

            this.multipleSelection = this.default_multipleSelection
            setTimeout(() => {
                this.moreDataBackShow(this.default_multipleSelection)
            }, 100)
        },
        cancelEvent() {
            this.tanbox.isshow = false
        },
        handleClose(done) {
            done()
        }
    },
    components: {
        dpwTable
    }
})
</script>

<style scoped>
.tanbox-boxs {
    display: flex;
    flex-direction: row;
    justify-content: left;
    align-items: center;
    flex-wrap: nowrap;
    gap: 20px;
    margin: 10px 0 20px;
}
</style>
