<template>
    <div>
        <el-row style="margin-top: 10px">
            <el-col :span="24" :xs="24" :sm="24" :lg="6">
                <div style="width: 100%"></div>
            </el-col>
            <el-col :span="24" :xs="24" :sm="24" :lg="10">
                <el-form ref="ruleForm" :model="ruleForm" :rules="ruleForm_rules" label-width="146px" class="demo-ruleForm">
                    <div>
                        <div>
                            <span>基本配置</span>
                        </div>
                        <div class="fangfa-box">
                            <el-form-item :label="'AppID：'" :prop="'AppID'">
                                <el-input v-model="ruleForm['AppID']" clearable></el-input>
                            </el-form-item>
                            <el-form-item :label="'AppKey：'" :prop="'AppKey'">
                                <el-input v-model="ruleForm['AppKey']" clearable></el-input>
                            </el-form-item>
                            <el-form-item :label="'SignKey：'" :prop="'SignKey'">
                                <el-input v-model="ruleForm['SignKey']" clearable></el-input>
                            </el-form-item>
                            <el-form-item :label="'SecretKey：'" :prop="'SecretKey'">
                                <el-input v-model="ruleForm['SecretKey']" clearable></el-input>
                            </el-form-item>
                            <el-form-item label="授权方式：" :prop="'display'">
                                <el-radio-group v-model="ruleForm['display']">
                                    <el-radio label="-1" size="large">客服授权</el-radio>
                                    <el-radio label="1" size="large">公众号授权</el-radio>
                                </el-radio-group>
                            </el-form-item>
                        </div>
                    </div>
                    <!-- 方法  -->
                    <div v-if="ruleForm.config && ruleForm.display != '-1'">
                        <div class="fangfa-box-top">
                            <div>
                                <span>方法一</span>
                            </div>
                            <div class="fangfa-box">
                                <el-form-item label="粘贴消息内容：" :prop="'config.a.paste'">
                                    <el-input v-model="ruleForm['config']['a']['paste']" clearable></el-input>
                                </el-form-item>
                                <el-form-item label="公众号二维码：" :prop="'config.a.other'">
                                    <mainimgesup
                                        ref="uploadimages"
                                        v-model="ruleForm['config']['a']['other']"
                                        :myimgesup="myimgesup"
                                        :myimgesupisshow="myimgesupisshow"
                                        :filelist="filelist"
                                        v-on:getuploadfile="(data) => getUploadFileEvent(data)"
                                        v-on:deluploadfile="(data) => deluploadfileEvent(data)"
                                    ></mainimgesup>
                                </el-form-item>
                            </div>
                        </div>
                        <div class="fangfa-box-top">
                            <div>
                                <span>方法二</span>
                            </div>
                            <div class="fangfa-box">
                                <el-form-item label="粘贴消息内容：" :prop="'config.b.paste'">
                                    <el-input v-model="ruleForm['config']['b']['paste']" clearable></el-input>
                                </el-form-item>
                                <el-form-item label="粘贴搜索内容：" :prop="'config.b.other'">
                                    <el-input v-model="ruleForm['config']['b']['other']" clearable></el-input>
                                </el-form-item>
                            </div>
                        </div>
                    </div>
                    <el-form-item v-if="ruleForm" class="fangfa-box-top">
                        <el-button type="primary" @click="submitEvent('ruleForm')" style="width: 40%; margin-right: 10px">保存</el-button>
                        <el-button @click="this.$router.go(-1)">取消</el-button>
                    </el-form-item>
                </el-form>
            </el-col>
            <el-col :span="24" :xs="24" :sm="24" :lg="8">
                <div style="width: 100%"></div>
            </el-col>
        </el-row>
    </div>
</template>
<script>
import { defineComponent } from 'vue'
import axios from 'axios'
import { ElMessage } from 'element-plus'
import mainimgesup from '@/components/Mainimgesup.vue'

export default defineComponent({
    name: 'formDefault',
    props: ['Form', 'FormRules', 'GetAxios'],
    watch: {
        Form: {
            handler(newVal) {
                this.ruleForm = newVal
            },
            deep: true,
            immediate: true
        },
        FormRules: {
            handler(newVal) {
                this.ruleForm_rules = newVal
            },
            deep: true,
            immediate: true
        },
        GetAxios: {
            handler(newVal) {
                this.my_axios = newVal
            },
            deep: true,
            immediate: true
        }
    },
    data() {
        return {
            myimgesupisshow: true, //form表单-imgesuploade上传图片
            myimgesup: {
                uploadsize: 1, //选择上传图片的展现框-1表示小框，2表示中等框，3表示大框
                listtype: 'picture', //list展示类型，text/picture/picture-card；默认不填为text
                ismust: true, //是否必填，true表示必填
                action: '/api/admin/upload/openFile', //upload上传API地址
                headers: {
                    Auth: localStorage.getItem('token')
                },
                title: '上传图片', //button-按钮文字
                tips: '(1)图片支持格式：*.jpg、*.jpeg、*.png; (3)最大支持上传20M文件', //提示&建议文字
                limit: '1', //上传数量限制
                target_id: 100, //上传文件target_id ：图片分类，区分存储文件和存储时长，不传默认存储1天后过期；1-二维码； 2-打印文件； 3-文库文件； 4-课程文件； 5-广告主宣传页； 6-广告主产生的pdf文件； 7-证件照文件； 50-设备广告； 100-通用；
                rules: {
                    images: {
                        image_width: 0, //图片宽度
                        image_height: 0, //图片高度
                        size: 20000, //文件大小（单位kb）
                        type: ['image/jpeg', 'image/jpg', 'image/png'], //文件格式
                        typechart: 'jpg/png' //文件格式提示规则
                    }
                }
            },
            filelist: [],

            ruleForm: {
                AppID: '',
                AppKey: '',
                SecretKey: '',
                SignKey: '',
                display: '',
                config: {
                    a: {
                        paste: '',
                        other: ''
                    },
                    b: {
                        paste: '',
                        other: ''
                    }
                }
            },
            ruleForm_rules: {
                AppID: [
                    {
                        required: true,
                        message: 'AppID为必填项',
                        trigger: 'blur'
                    }
                ],
                AppKey: [
                    {
                        required: true,
                        message: 'AppKey为必填项',
                        trigger: 'blur'
                    }
                ],
                SecretKey: [
                    {
                        required: true,
                        message: 'SecretKey为必填项',
                        trigger: 'blur'
                    }
                ],
                SignKey: [
                    {
                        required: true,
                        message: 'SignKey为必填项',
                        trigger: 'blur'
                    }
                ],
                display: [
                    {
                        required: true,
                        message: 'display为必填项',
                        trigger: 'blur'
                    }
                ],
                'config.a.paste': [
                    {
                        required: true,
                        message: '粘贴消息内容为必填项',
                        trigger: 'blur'
                    }
                ],
                'config.a.other': [
                    {
                        required: true,
                        message: '公众号二维码为必填项',
                        trigger: 'blur'
                    }
                ],
                'config.b.paste': [
                    {
                        required: true,
                        message: '粘贴消息内容为必填项',
                        trigger: 'blur'
                    }
                ],
                'config.b.other': [
                    {
                        required: true,
                        message: '粘贴搜索内容为必填项',
                        trigger: 'blur'
                    }
                ]
            },

            // 接口
            my_axios: {
                get_axios: '/manage/Manage/document',
                key_name: 'document_baidu'
            },

            // 标题替换
            exchange: { a_paste: '粘贴消息内容', a_other: '公众号二维码', b_paste: '粘贴消息内容', b_other: '粘贴搜索内容' }
        }
    },
    methods: {
        // 上传图片-获取upload子组件回传的数据-file文件url等数据
        getUploadFileEvent(data) {
            // console.log('当前获取的回传数据：', data)
            this.filelist = [
                {
                    name: data.name,
                    url: data.url
                }
            ]
            this.ruleForm['config']['a']['other'] = data.url

            // 校验单个字段
            this.$refs['ruleForm'].validateField('config.a.other')
        },

        // 上传图片-删除上传的文件-事件（upload组件）
        deluploadfileEvent(data) {
            // console.log('当前删除事件-返回的数据：', data)
            if (data.length == 0) {
                this.ruleForm['config']['a']['other'] = ''
                this.filelist = []
                this.$refs['ruleForm'].validateField('config.a.other')
            }
        },

        // 提交
        submitEvent(formName) {
            // var getdata = this.$refs[formName].model
            this.$refs[formName].validate((valid) => {
                if (valid) {
                    axios
                        .post(this.my_axios.get_axios + '&id=' + this.$route.query.id + '&key_name=' + this.my_axios.key_name, this.ruleForm, {
                            headers: {
                                Auth: localStorage.getItem('token')
                            }
                        })
                        .then((response) => {
                            if (response.data.code === 0) {
                                ElMessage({
                                    type: 'success',
                                    message: '提交成功！'
                                })
                            }
                        })
                        .catch(function(error) {
                            console.log(error)
                        })
                } else {
                    console.log('error submit!!')
                    return false
                }
            })
        },
        // 反显
        showBackEvent(myaxios) {
            axios
                .get(myaxios.get_axios, {
                    headers: {
                        Auth: localStorage.getItem('token')
                    },
                    params: {
                        id: this.$route.query.id,
                        key_name: myaxios.key_name
                    }
                })
                .then((response) => {
                    let getdatas = response.data.result
                    if (Object.values(getdatas).length == 0) {
                        // 百度文档配置
                        if (this.my_axios.key_name == 'document_baidu') {
                            this.ruleForm = {
                                AppID: '',
                                AppKey: '',
                                SecretKey: '',
                                SignKey: '',
                                display: '-1',
                                config: {
                                    a: {
                                        paste: '',
                                        other: ''
                                    },
                                    b: {
                                        paste: '',
                                        other: ''
                                    }
                                }
                            }
                        }
                    } else {
                        this.ruleForm = getdatas

                        // 反显-方法一中-图片
                        let getImage = getdatas['config']['a']['other']
                        if (getImage != '') {
                            this.filelist = [
                                {
                                    name: getImage.split('/')[getImage.split('/').length - 1],
                                    url: getImage
                                }
                            ]
                        }
                    }
                })
                .catch(function(error) {
                    console.log(error)
                })
        }
    },
    created() {
        this.showBackEvent(this.my_axios)
    },
    components: {
        mainimgesup
    }
})
</script>
<style scoped>
.fangfa-box {
    border: 1px dashed var(--el-border-color-base);
    padding: 20px 20px 0 0;
    border-radius: 4px;
    margin-top: 20px;
}
.fangfa-box-top {
    margin-top: 20px;
}
</style>
