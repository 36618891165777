<template>
  <div class="area-select">
    <template v-for="(opt, o) in areaOpt" :key="o">
      <el-select
        style="width: 100px; margin-right: 10px"
        v-model="selected[o]"
        :disabled="opt.opt.length === 0 && o > 0"
        :placeholder="opt.placeholder"
        @click="getAreaOpt(undefined, 0)"
        @change="selectChange($event, o)"
      >
        <template v-for="(item, index) in opt.opt" :key="index">
          <el-option :value="item.code" :label="item.name" />
        </template>
      </el-select>
    </template>
  </div>
</template>

<script setup>
import {useAxios} from '../useAxios'
import {ref, computed} from 'vue'

const props = defineProps(['modelValue'])
const emits = defineEmits(['update:modelValue'])
const selected = computed({
  get(){
    return props.modelValue
  },
  set(val){
    emits('update:modelValue', val)
  }
})
const areaOpt = ref([
  {
    placeholder: '选择省',
    opt: []
  },
  {
    placeholder: '选择市',
    opt: []
  },
  {
    placeholder: '选择区县',
    opt: []
  },
])

const clear = () => {
  selected.value = []
  areaOpt.value = [
    {
      placeholder: '选择省',
      opt: []
    },
    {
      placeholder: '选择市',
      opt: []
    },
    {
      placeholder: '选择区县',
      opt: []
    },
  ]
}

const selectChange = (e, index) => {
  if(e && index < 2) getAreaOpt(e, index+1)
  selected.value.splice(index+1)
  areaOpt.value.forEach((item, i) => {
    if(i >= index+1) item.opt = []
  })
  
}

const getAreaOpt = (pcode, index = 0) => {
  if(index !== 0 || areaOpt.value[0].opt.length === 0) {
    useAxios(`admin/getArea`, 'get', {pcode}).then(res => {
      areaOpt.value[index].opt = [
        {
          code: '',
          name: '全部'
        },
        ...res.data.result.list
      ]
    })
  }
}

defineExpose({
  clear
})
</script>

<style scoped>
.area-select{
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}
</style>
