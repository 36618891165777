<template>
    <div class="page-container">
        <div class="container-filter">
            <el-form ref="filterRef" :model="params" inline>
                <div>
					<el-form-item prop="range" label="入库月份：">
                        <el-date-picker v-model="params.range" type="monthrange" :clearable="false" placeholder="选择日期" />
                    </el-form-item>
                    <el-form-item prop="product_name" label="材料名称：">
                        <el-input v-model="params.product_name" placeholder="请输入材料名称" clearable />
                    </el-form-item>
                </div>
                <div>
                    <el-button @click="resetFilter">重置</el-button>
                    <el-button type="primary" @click="getTableData()" :loading="loading">查询</el-button>
                </div>
            </el-form>
        </div>
        <div class="container-table">
            <div class="table-header">
                <div>数据列表</div>
                <div>
                    <el-button type="primary" @click="exportRecord">导出</el-button>
                </div>
            </div>
            <el-table :border="true" :data="table.data" v-loading="loading" >
                <el-table-column prop="" label="序号" fixed="left">
                    <template #default="{ $index }">
                        <span>{{ $index + 1 }}</span>
                    </template>
                </el-table-column>
                <el-table-column prop="product_name" label="材料名称" fixed="left" />
                <el-table-column prop="unit" label="单位" fixed="left" />
                <el-table-column prop="in_time" label="入库月份" fixed="left" />
				<template v-for="i in 31" :key="i">
					<el-table-column :prop="`day${i}`" :label="i"/>
				</template>
                <el-table-column prop="total" label="合计" fixed="right"/>
            </el-table>
            <div class="tabler-pagination">
                <el-pagination
                    @size-change="getTableData()"
                    @current-change="getTableData"
                    :current-page="params.page"
                    :page-sizes="[20, 50, 100, 1000]"
                    v-model:page-size="params.page_size"
                    :total="table.total"
                    layout="total, sizes, prev, pager, next, jumper"
                />
            </div>
        </div>
    </div>
</template>

<script setup>
import { ref, reactive } from 'vue'
import http from '@/assets/js/http'

// 获取权限
const mypowerlimits = reactive(JSON.parse(localStorage.getItem('powerlimits')))

// 过滤
const filterRef = ref()
const params = reactive({
    range: [],
	product_name: '',
    page: 1,
    page_size: 20
})

// 表格
const loading = ref(false)
const table = reactive({
    data: [],
    columns: [],
    total: 0
})

const getTableData = async (page = 1) => {
	params.page = page
    loading.value = true
    try {
        const res = await http.post('packOut/packInDataList',  getParams())
		Object.assign(table, res)
    } catch (e) {
        console.log(e)
    }
    loading.value = false
}

// 操作
// 重置查询条件
const resetFilter = () => {
    filterRef.value?.resetFields()
    getTableData()
}

// 导出
const exportRecord = async () => {
    await http.post('packOut/packInDataList',  getParams(true), { sMsg: true })
}

const getParams = (exp = false)=> {
    const { range, product_name, page, page_size } = params
	const [start, end] = range
    const in_time = (start && end) ? `${start.valueOf() / 1000} ~ ${end.valueOf() / 1000}` : ''
	const _params = { in_time, product_name, page, page_size }
	if(exp) _params.export = 1
	return _params
}

getTableData()
</script>

<style lang="scss" scoped>
.page-container {
    background-color: #e9eef3;
    .container-filter {
        padding: 12px 24px;
        background-color: #fff;
        .el-form {
            display: flex;
            justify-content: space-between;
        }
        .el-form-item {
            margin-right: 20px;
            margin-bottom: 0px;
        }
    }

    .container-table {
        margin-top: 4px;
        padding: 24px;
        background-color: #fff;
        .table-header {
            display: flex;
            justify-content: space-between;
            margin-bottom: 12px;
        }

        .skip {
            cursor: pointer;
            color: var(--el-color-primary);
        }
        .tabler-pagination {
            display: flex;
            justify-content: space-between;
            margin-top: 12px;
        }
    }
}

.container-drawer {
    .filter-tree {
        margin-top: 24px;
    }
    .drawer-footer {
        margin-top: 24px;
        display: flex;
        justify-content: flex-end;
    }
}
</style>
