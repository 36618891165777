<template>
    <div>
        <div>
            <el-form ref="searchformRef" :model="searchForm" class="search-form" inline label-suffix=":">
                <el-form-item :label="item.label" v-for="(item, index) in select" :key="index">
                    <xwCascader v-if="item.component == 'xw-cascader'" :model_value="searchForm[item.prop]" @modelValue="(data) => (searchForm[item.prop] = data)" />
                    <xwSelectRemote
                        v-else-if="item.component == 'xw-select-remote'"
                        :placeholder="item.placeholder"
                        :axios_type="item.axios_type"
                        :axios_url="item.axios_url"
                        :axios_key="item.axios_key"
                        :axios_exprops="item.axios_exprops"
                    />
                    <component v-else v-model="searchForm[item.prop]" :is="item.component" :placeholder="item.placeholder" v-bind="item.props"></component>
                </el-form-item>
                <el-form-item>
                    <el-button type="primary" @click="searchEvent(searchForm)">查询</el-button>
                    <el-button @click="resetSearchForm">重置</el-button>
                </el-form-item>
            </el-form>
        </div>
        <slot name="tips"></slot>
        <div>
            <el-table :data="tableData" border v-loading="loading" element-loading-text="正在飞速加载中...">
                <template v-for="(item, index) in table_list" :key="index">
                    <el-table-column :label="item.label" :prop="item.prop" :min-width="item.minWidth" :width="item.width" :fixed="item.fixed"> </el-table-column>
                </template>
            </el-table>
            <div style="margin-top: 20px">
                <el-pagination
                    :total="total"
                    v-model:current-page="page"
                    v-model:page-size="page_size"
                    :page-sizes="[1, 10, 20, 50, 100]"
                    layout="total, sizes, prev, pager, next, jumper"
                    background
                    @size-change="(data) => handleChange(data, 1)"
                    @current-change="(data) => handleChange(data, 2)"
                ></el-pagination>
            </div>
        </div>
    </div>
</template>

<script>
import http from '@/assets/js/http'
import xwCascader from './xwCascader.vue'
import xwSelectRemote from './xwSelectRemote.vue'

export default {
    name: 'xwTabler',
    props: {
        search_form: {
            type: Object,
            default() {
                return {}
            }
        },
        select: {
            type: Array,
            default() {
                return []
            }
        },
        table_list: {
            type: Array,
            defdefault() {
                return []
            }
        },
        axios_type: {
            type: String,
            default: 'post'
        },
        axios_url: {
            type: String,
            default: ''
        }
    },
    watch: {
        search_form: {
            handler(newVal) {
                this.searchForm = newVal
            },
            deep: true,
            immediate: true
        },
        axios_type: {
            handler(newVal) {
                this.axiosType = newVal
            },
            deep: true,
            immediate: true
        },
        axios_url: {
            handler(newVal) {
                this.axiosUrl = newVal
            },
            deep: true,
            immediate: true
        }
    },
    data() {
        return {
            searchForm: {},
            tableData: [],
            axiosType: '',
            axiosUrl: '',
            page: 1,
            page_size: 10,
            total: 0,

            pageDatas: {
                page: 1,
                page_size: 10
            },

            loading: false
        }
    },
    methods: {
        async searchEvent(searchForm) {
            this.loading = true
            let post_datas = { ...searchForm, ...this.pageDatas }
            if (this.axiosType == 'get') post_datas = { params: post_datas }
            let result = await http[this.axiosType](this.axiosUrl, post_datas)

            this.tableData = result.data
            this.page = result.current_page
            this.total = result.total

            this.loading = false
        },
        resetSearchForm() {
            this.$props.select.map((n) => {
                this.searchForm[n.prop] = ''
            })
        },
        // 分页选择
        handleChange(data, flag) {
            let key = flag == 1 ? 'page_size' : 'page'
            this.pageDatas[key] = data
            this.searchEvent(this.searchForm)
        }
    },
    mounted() {
        if (this.axiosUrl) this.searchEvent(this.searchForm)
    },
    components: {
        xwCascader,
        xwSelectRemote
    }
}
</script>
