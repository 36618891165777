import axios from 'axios'
import router from '@/router'
import { ElMessage } from 'element-plus'

const api = {
	development: 'https://dev.jsshareprint.com/manage/',
	test: 'https://demo.jsshareprint.com/manage/',
	production: 'https://home.jsyinba.com/manage/'
}

const apiKey = process.env.NODE_ENV

const message = '登陆过期或未登陆，请重新登陆'

const http = axios.create({ timeout: 60000 })

http.interceptors.request.use((request) => {
    const token = localStorage.getItem('token')
    request.baseURL = api[apiKey]
	if (token) {
		request.headers = { Auth: token, 'Content-Type': 'application/json;charset=UTF-8' }
	} else {
		router.push('/')
		localStorage.clear()
		return Promise.reject(message)
	}
    return request
})


http.interceptors.response.use((response) => {
    const { data, status } = response
    if (status === 200) {
        if (data.code === 0) {
            let sMsg = response.config.sMsg
            if (sMsg) {
                let message = '操作成功'
                if (typeof data.result === 'string') message = data.result
                else if (typeof sMsg === 'string') message = sMsg
                else if (data.message || data.msg) message = data.message || data.msg
                ElMessage({ message, title: '温馨提示', type: 'success' })
            }
            return data.result || data.data || data
        } else if (data.code === 101) {
			router.push('/')
			localStorage.clear()
            return Promise.reject(message)
        } else {
            ElMessage({ message: data.message || data.msg || response.config.eMsg || '操作失败', title: '提示', type: 'warning' })
            return Promise.reject(data.message)
        }
    } else {
        return Promise.reject(response)
    }
})


export default http
