<template>
    <div>
        <batchManageInfo :mainonebread="mainonebread" :isshow="false"></batchManageInfo>
    </div>
</template>

<script>
import batchManageInfo from '../batchmanage/vbatchmanageinfo.vue'
import { defineComponent } from 'vue'

export default defineComponent({
    name: 'vbatchmanagefunderinfo',
    data() {
        return {
            mainonebread: [
                {
                    name: '风控'
                },
                {
                    name: '风控管理'
                },
                {
                    name: '批次管理'
                },
                {
                    name: '详情'
                }
            ]
        }
    },
    created() {},
    components: {
        batchManageInfo
    }
})
</script>

<style scoped></style>
