<template>
    <div class="page-container">
        <div class="container-filter">
            <el-form ref="filterRef" :model="params" :rules="rules" inline>
                <div>
                    <el-form-item prop="name" label="批次名称：">
                        <span v-if="urlParams?.name">{{ urlParams.name }}</span>
                        <el-input v-model="params.name" placeholder="请输入批次名称" clearable v-else />
                    </el-form-item>
                    <el-form-item prop="status" label="状态：">
                        <el-select v-model="params.status" placeholder="请选择状态" clearable>
                            <el-option label="全部" value="" />
                            <el-option v-for="(val, key) in status" :key="key" :label="val" :value="key" />
                        </el-select>
                    </el-form-item>
                    <el-form-item prop="range" label="日期：">
                        <el-date-picker v-model="params.range" :type="$props.type === 2 ? 'monthrange' : 'daterange'" :clearable="false" placeholder="选择日期" />
                    </el-form-item>
                </div>
                <div>
                    <el-button type="primary" @click="getTableData()" :loading="loading">查询</el-button>
                    <el-button @click="resetForm">重置</el-button>
                </div>
            </el-form>
        </div>
        <div class="container-table">
            <div class="table-header">
                <div>数据列表</div>
                <div>
                    <el-button type="primary" @click="exportRecord">导出</el-button>
                </div>
            </div>
            <el-table v-loading="loading" :border="true" :data="table.data">
                <el-table-column type="index" label="序号" align="center" :width="60" />
                <el-table-column prop="id" label="ID" align="center" width="60" v-if="!isCapital">
                    <template #default="{ row, $index }">
                        <span v-if="$index > 0">{{ row.id }}</span>
                    </template>
                </el-table-column>
                <el-table-column prop="name" label="批次名称" :width="240" />
                <el-table-column prop="price" label="结算单价(元)" :width="120" />
                <el-table-column prop="num" label="总数量" :width="100" />
                <el-table-column prop="total_money" label="总价款(元)" :width="100" />
                <el-table-column prop="advance" label="预付款(元)" :width="100" />
                <el-table-column prop="residue" label="余款(元)" :width="100" />
                <el-table-column prop="addtime" label="状态" :width="100">
                    <template #default="{ row, $index }">
                        <template v-if="$index > 0">
                            <span :style="{ color: statusColor[row.status] }">{{ row.status_name }}</span>
                        </template>
                    </template>
                </el-table-column>
                <el-table-column prop="checktime" label="验收时间" :width="120" />
                <el-table-column prop="repay_first_day" label="还款首日" :width="120" />
                <el-table-column :prop="item" :label="item" v-for="item in table.columns" :key="item" :width="100"></el-table-column>
                <el-table-column prop="total" label="尾款合计" fixed="right" :width="140" />
            </el-table>
            <div class="tabler-pagination">
                <el-pagination
                    @size-change="getTableData()"
                    @current-change="getTableData"
                    :current-page="params.page"
                    :page-sizes="[10, 20, 50, 100]"
                    v-model:page-size="params.page_size"
                    :total="table.total"
                    layout="total, sizes, prev, pager, next, jumper"
                />
            </div>
        </div>
    </div>
</template>

<script setup>
import { ref, reactive } from 'vue'
import { ElNotification } from 'element-plus'
// @ts-ignore
import http from '@/assets/js/http'
// @ts-ignore
// @ts-ignore
const props = defineProps({
    // 传入的参数 2:月统计 1:日统计
    type: { required: true, type: Number },
    isCapital: Boolean,
    urlParams: Object
})
const status = { 1: '进行中', 2: '未开始', 4: '已完结' }
const statusColor = {
    '-2': '#FF0000',
    '-1': '#FF00FF',
    1: '#008000',
    2: '#333333',
    3: '#FFA500',
    4: '#FF4500'
}
let api_url
if (props.isCapital) {
    api_url = 'RiskControlAdvance/capitalStatistics'
} else {
    status['-1'] = '已终止'
    api_url = 'RiskControlAdvance/statistics'
}

const today = new Date()
const fullyear = today.getFullYear()
let start_date = new Date(fullyear, today.getMonth(), 1)
let end_date = new Date(fullyear, today.getMonth() + 1, 0)
if (props.type === 2) {
    start_date = new Date(fullyear, 0, 1)
    end_date = new Date(fullyear, 12, 0)
}
end_date.setHours(23, 59, 59, 0)
// 1查询，2导出
let filter_auth_type = 1

// 过滤
const filterRef = ref()
const params = reactive({
    status: '',
    name: '',
    range: [start_date, end_date],
    page: 1,
    page_size: 10
})
const rules = {
    range: [
        {
            // @ts-ignore
            // @ts-ignore
            validator(rule, value, cb) {
                if (value) {
                    const [start, end] = value
                    let _end = new Date(end)
                    if (props.type === 2) {
                        const end_days = new Date(end.getFullYear(), end.getMonth() + 1, 0).getDate()
                        _end.setDate(end_days)
                    }
                    _end.setHours(23, 59, 59, 0)
                    const range = props.type === 1 && filter_auth_type === 1 ? 93 : 732
                    if (_end.valueOf() - start.valueOf() > range * 24 * 60 * 60 * 1000) {
                        cb('最大选择' + (range === 93 ? '93天' : '2年'))
                    } else cb()
                } else cb()
            },
            trigger: 'auth'
        }
    ]
}

// 表格
const loading = ref(false)
const table = reactive({
    data: [],
    columns: [],
    total: 0
})
const getParams = (exp, page) => {
    const { name, status, range, page_size } = params
    const [start, end] = range
    const _end = new Date(end)
    if (props.type === 2) {
        const end_days = new Date(end.getFullYear(), end.getMonth() + 1, 0).getDate()
        _end.setDate(end_days)
    }
    _end.setHours(23, 59, 59, 0)
    const replaytime = `${start.valueOf() / 1000}~${_end.valueOf() / 1000}`
    const _params = { replaytime, page_size, type: props.type }
    if (name) _params.name = name
    if (status) _params.status = status
    if (exp) _params.export = 1
    if (props.urlParams?.id) _params.id = props.urlParams.id
    else {
        _params.page = page
        _params.page_size = page_size
    }
    return _params
}

const getTableData = async (page = 1) => {
    filter_auth_type = 1
    loading.value = true
    try {
        await filterRef.value?.validate()
        // @ts-ignore
        const { total, data, titleField, current_page: _page } = await http.post(api_url, getParams(false, page))
        table.data = data
        table.total = total
        params.page = _page
        table.columns = Object.values(titleField)
    } catch (e) {
        console.error(e)
    }
    loading.value = false
}

// 操作
// 重置查询表单
const resetForm = () => {
    filterRef.value?.resetFields()
    getTableData()
}

// 导出
const exportRecord = async () => {
    filter_auth_type = 2
    await filterRef.value?.validate()
    // @ts-ignore
    const { code, message } = await http.post(api_url, getParams(true))
    ElNotification({ title: '提示', type: code === 0 ? 'success' : 'warning', message })
}

getTableData()
// @ts-ignore
</script>

<style lang="scss" scoped>
.page-container {
    background-color: #e9eef3;
    .container-bread {
        font-size: 14px;
        color: #666;
        display: flex;
        align-items: center;
    }
    .container-filter {
        padding: 12px 24px;
        background-color: #fff;
        .el-form {
            display: flex;
            justify-content: space-between;
        }
        .el-form-item {
            margin-right: 20px;
        }
    }

    .container-table {
        margin-top: 4px;
        padding: 12px 24px;
        background-color: #fff;
        .table-header {
            display: flex;
            justify-content: space-between;
            align-items: center;
            margin-bottom: 12px;
        }
        .tabler-pagination {
            display: flex;
            justify-content: flex-end;
            margin-top: 12px;
        }
    }
}
</style>
