<template>
  <div>
    <mainone :mainonebread="mainonebread"></mainone>
    <maintwo :maintwoiconshow="maintwoiconshow" :maintwoforminfo="maintwoforminfo"
      :myinputoneisshow="maintwoforminfo.myinputoneisshow" :myinputone="maintwoforminfo.myinputone"
      :myselectleftisshow="maintwoforminfo.selectleftisshow" :myselectleft="maintwoforminfo.selectleft"
      :myinputishow="myinputishow" :myinput="myinput" :myinputonefullisshow="maintwoforminfo.myinputonefullisshow"
      :myinputonefull="maintwoforminfo.myinputonefull" :myselectisshow="myselectisshow" :myselect="myselect"
      :myselectcityisshow="myselectcityisshow" :myselectcity="myselectcity" v-on:getsearchinfo="getSeachinfoEvent"
      v-on:searchForm="searchFormEvent" v-on:selectdatainfogao="selectDatainfoGaoEvent"></maintwo>
    <!--table表单-->
    <div>
      <!--主list列表-->
      <div style="
          margin-top: 10px;
          background-color: #ffffff;
          border-radius: 4px;
          line-height: 40px;
          padding: 0 20px;
        ">
        <div style="line-height: 60px">
          <!--左侧名称-->
          <div style="float: left; font-size: 14px">数据列表</div>
          <!--右侧按钮-->
          <div style="float: right">
            <!--自定义按钮-点击出现弹框&点击跳转子类页面@click="routeraddrClick('/XX路由')"-->
            <!--1、点击出现弹框@click="addTanClick(XX弹框配置信息,dialogVisible = true);resetFormSenior(XXform表单)"-->
            <!--2、点击跳转子类页面@click="routeraddrClick('/XX路由')-->
            <el-button type="primary" plain @click="tansubmitForm(this.multipleSelection, 6)"
              v-if="mypowerlimits.dyzyk_plzyfl">批量转移分类</el-button>
            <el-button type="primary" plain @click="routeraddrClick('/printresourcesaddmore')"
              v-if="mypowerlimits.dyzyk_pltj">批量添加</el-button>
            <el-button type="primary" plain @click="routeraddrClick('/printresourcesadd')"
              v-if="mypowerlimits.dyzyk_tj">添加</el-button>
            <!--导出按钮-点击导出数据-->
            <el-button type="warning" plain @click="exportDatas()">导出</el-button>
          </div>
        </div>
        <!-- 数据列表 -->
        <div>
          <!--table表单-->
          <el-table ref="multipleTable" :row-class-name="tableRowClassName" :data="this.realtabledata"
            style="width: 100%" min-height="900" border v-loading="loading" element-loading-text="正在飞速加载中..."
            @selection-change="handleSelectionChange" @cell-click="handleOneData"
            :default-sort="{ prop: 'date', order: 'descending' }" @sort-change="sortchangeEvent">
            <!--多选框-用于用户选择性导出数据-->
            <el-table-column type="selection" width="55" v-if="mainthreeisshowset" />
            <el-table-column v-for="(mytabletitle, i) in mainthreetabletitle" :key="i" :fixed="mytabletitle.fixedstatu"
              :prop="mytabletitle.prop" :label="mytabletitle.label" :width="mytabletitle.width"
              :sortable="mytabletitle.prop == 'weight' ? 'custom' : false">
              <!--这里先通过table列表中的title值进行判断，在进行渲染展示-->
              <!--例如：status状态字段，判断最终的值是那种表现形式等-->
              <template #default="scope" v-if="
                mytabletitle.prop === 'status' ||
                mytabletitle.prop === 'mark' ||
                mytabletitle.prop === 'checker'
              ">
                <!-- 审核人-数据渲染 -->
                <div v-if="mytabletitle.prop === 'checker'">
                  <span>{{ scope.row.checker === "0" ? "" : scope.row.checker }}</span>
                </div>
                <!-- 评分-数据渲染 -->
                <div v-if="mytabletitle.prop === 'mark'">
                  <div class="mytabletext" @click="routeraddrClick('/printreSourcesMarklist', scope.row)">
                    <span>
                      {{ scope.row.mark.toFixed(1) }}</span>
                  </div>
                </div>
                <!-- 状态-渲染 -->
                <div v-if="mytabletitle.prop === 'status'">
                  <div v-if="scope.row.switchstatus === 4" id="switch">
                    <el-switch v-model="scope.row.status" active-text="开启" inactive-text="关闭" :active-value="1"
                      :inactive-value="0" active-color="#13ce66" inactive-color="#cbcbcb"
                      @change="mypowerlimits.dyzyk_qy ? changeStatus(scope.row) : ''"
                      :disabled="!mypowerlimits.dyzyk_qy">
                    </el-switch>
                  </div>
                </div>
              </template>
            </el-table-column>
            <!--操作&按钮-->
            <el-table-column :fixed="false" label="操作" :width="mainthreehandle" v-if="mainthreeisshowope"
              prop="myoperation" id="operation">
              <template #default="scope">
                <!--操作：按照页面区分，且每个按钮对应相关函数-->
                <!--操作-打印资源库-->
                <div v-if="mainthreetableope === 12">
                  <el-button type="primary" size="mini" @click="open_win(scope.row)" v-if="mypowerlimits.dyzyk_ylxzwd">
                    预览/下载文档</el-button>
                  <el-button type="primary" size="mini" v-if="scope.row.examine && mypowerlimits.dyzyk_sh"
                    @click="routeraddrClick('/resourcesexamine', scope.row)">审核</el-button>
                  <el-button type="primary" size="mini" v-if="scope.row.edit && mypowerlimits.dyzyk_tj"
                    @click="routeraddrClick('/resourcesedit', scope.row)">编辑</el-button>
                  <!-- 这里的权限目前没有编辑按钮的权限，后端让先绑定到添加按钮上；使用有添加就可以编辑的逻辑 -->
                  <el-button v-if="scope.row.delete && mypowerlimits.dyzyk_sc" @click.prevent="deleteRow(scope.row)"
                    type="danger" size="mini" icon="el-icon-delete"></el-button>
                </div>
              </template>
            </el-table-column>
          </el-table>
          <!--分页器-->
          <div class="block" style="text-align: center; margin: 30px 0">
            <el-pagination background @size-change="handleSizeChange" @current-change="handleCurrentChange"
              :current-page="currentPage" :page-sizes="pageSizes" :page-size="PageSize"
              layout="total, sizes, prev, pager, next, jumper" :total="totalCount">
            </el-pagination>
          </div>
        </div>
      </div>

      <!--弹出框-批量转移分类-->
      <el-dialog v-model="dialogVisible" :title="'批量转移分类 (共' + this.ruleForm.numbers + '个文档)'" :width="this.searchwidth"
        :before-close="handleCloseSenior">
        <el-form ref="ruleForm" :model="ruleForm" :rules="rules" label-width="0px" class="demo-ruleForm">
          <div style="
              max-height: 450px;
              overflow: hidden;
              overflow-y: visible;
              margin: 20px 0 0 0;
            ">
            <div style="margin-bottom: 10px">
              <span>选择转移后的文档分类</span>
            </div>
            <el-form-item>
              <el-row :gutter="10">
                <!-- 平台来源 -->
                <el-col :span="24" :xs="24" :sm="24" :lg="12">
                  <el-form-item prop="id" :rules="rules.id">
                    <el-select v-model="ruleForm.id" placeholder="请选择" style="width: 100%">
                      <el-option :label="getsales.cate_name" :value="getsales.id" v-for="(getsales, chicundex) in this.getmytype[0]
                      .gettype" :key="chicundex" @click="finduserSaleList(getsales)"></el-option>
                    </el-select>
                  </el-form-item>
                </el-col>
                <!-- 子分类-多级联选 -->
                <!-- :props="{value:'id',label:'name'}" 自定义字段-->
                <el-col :span="24" :xs="24" :sm="24" :lg="12">
                  <el-form-item prop="cate_id" :rules="rules.cateid">
                    <el-cascader v-model="ruleForm.cate_id" style="width: 100%"
                      :options="this.getmytype[0].gettypechild" :show-all-levels="false" :props="{
                        value: 'id',
                        label: 'cate_name',
                        children: 'child',
                      }" clearable :disabled="ruleForm.disabled" @change="handleChange"></el-cascader>
                  </el-form-item>
                </el-col>
              </el-row>
            </el-form-item>
            <div style="text-align: right">
              <el-button @click="resetForm('ruleForm')">重置</el-button>
              <el-button type="primary" @click="submitForm('ruleForm')">确认</el-button>
            </div>
          </div>
        </el-form>
      </el-dialog>
    </div>
  </div>
</template>

<script>
import mainone from "@/components/Mainone";
import maintwo from "@/components/Maintwo";
import { ElMessage, ElMessageBox } from "element-plus";
import axios from "axios";
import { defineComponent, ref } from "vue";

export default defineComponent({
  name: "vprintresources",
  inject: ["reload"],
  data() {
    return {
      mainonebread: [
        {
          name: "教育",
        },
        {
          name: "文档管理",
        },
        {
          name: "打印资源库",
          path: "/printresources",
        },
      ], // 当前页-【面包屑导航】
      maintwoiconshow: true, // 筛选-【高级筛选按钮】
      maintwoforminfo: {
        myinputoneisshow: true, //筛选查询-左侧字+input
        myinputone: {
          divinterval: "0", //框右侧距离
          titlewidth: "74px", //标题宽度
          inputwidth: "200px", //input宽度
          inputinterval: "20px", //input右侧距离
          input: [
            {
              ismust: false, //是否必填，true表示必填
              title: "文档名称：",
              placeholder: "请输入文档名称",
              name: "sourcename",
              content: "",
            },
          ],
        },
        selectleftisshow: true, //筛选查询-左侧字+select单框||select时间
        selectleft: {
          width: "none",
          divinterval: "", //框右侧距离
          selectwidth: "200px",
          selectinterval: "20px", //select右侧距离
          select: [
            {
              selectisshow: true,
              ismust: false,
              title: "审核状态：",
              placeholder: "全部",
              name: "check_status",
              value: "",
              options: [
                {
                  label: "全部",
                  value: "0",
                },
                {
                  label: "待审核",
                  value: "1",
                },
                {
                  label: "审核通过",
                  value: "2",
                },
                {
                  label: "审核拒绝",
                  value: "3",
                },
              ],
            },
            {
              selectisshow: true,
              ismust: false,
              title: "平台：",
              placeholder: "全部",
              name: "plat",
              value: "",
              options: [
                {
                  label: "全部",
                  value: "0",
                },
                {
                  label: "自助版",
                  value: "1",
                },
                {
                  label: "家庭版",
                  value: "2",
                },
                {
                  label: "企业版",
                  value: "3",
                },
              ],
            },
          ],
        },
      }, // 筛选-控件信息
      mainthreeisshowbut: true, // 数据列表中-【添加按钮】
      mainthreeadddata: "/printresourcesadd", //点击添加按钮-跳转路由
      mainthreeisshowset: true, // 数据列表中-复选框
      mainthreeisshowope: true, // 数据列表中-操作-根据页面显示模块（整页）
      mainthreetableope: 12, // 数据列表中-操作-根据页面显示控件（整页）
      mainthreehandle: "220", // 数据列表中-操作-宽度
      mainthreetabletitle: [
        {
          fixedstatu: "left",
          prop: "id",
          label: "资源ID",
          width: "80",
        },
        {
          fixedstatu: false,
          prop: "sourcename",
          label: "文档名称",
          width: "200",
        },
        {
          fixedstatu: false,
          prop: "check_status",
          label: "审核状态",
          width: "",
        },
        {
          fixedstatu: false,
          prop: "type",
          label: "来源",
          width: "",
        },
        {
          fixedstatu: false,
          prop: "income_type",
          label: "收益类型",
          width: "",
        },
        {
          fixedstatu: false,
          prop: "print_num",
          label: "打印量",
          width: "",
        },
        {
          fixedstatu: false,
          prop: "pages",
          label: "页数",
          width: "",
        },
        {
          fixedstatu: false,
          prop: "view_num",
          label: "浏览量",
          width: "",
        },
        {
          fixedstatu: false,
          prop: "weight",
          label: "排序",
          width: "",
        },
        {
          fixedstatu: false,
          prop: "print_price",
          label: "文档价格",
          width: "",
        },
        {
          fixedstatu: false,
          prop: "mark",
          label: "评分",
          width: "60",
        },
        {
          fixedstatu: false,
          prop: "add_user",
          label: "上传人",
          width: "",
        },
        {
          fixedstatu: false,
          prop: "checker",
          label: "审核人",
          width: "",
        },
        {
          fixedstatu: false,
          prop: "status",
          label: "状态",
          width: "",
        },
        {
          fixedstatu: false,
          prop: "addtime",
          label: "创建时间",
          width: "180",
        },
      ], // 数据列表中-数据表头
      mainthreestatus: true, // 数据列表中-属于search按钮表示状态
      mainthreetableData: [], // 数据列表中-具体数据
      totalCount: 1, //数据列表中-数据总条数
      currentpage: 1, //数据列表中-数据当前页数
      lastpage: 1, //数据列表中-数据总页数（默认以20条一页）
      loading: false, //初始化-loading加载动效，默认是false关闭

      //高级筛选
      myinputishow: true, //高级筛选-input单框
      myinput: {
        divinterval: "3%", //框右侧距离
        inputwidth: "47%",
        inputmaxwidth: "100%",
        input: [
          {
            ismust: false, //是否必填，true表示必填
            title: "文档名称：",
            placeholder: "请输入文档名称",
            name: "sourcename",
            content: "",
          },
        ],
      },
      myselectisshow: true, //高级筛选-select单框
      myselect: {
        divinterval: "3%", //框右侧距离
        selectwidth: "47%",
        selectmaxwidth: "100%",
        select: [
          {
            selectisshow: true,
            ismust: false,
            title: "审核状态：",
            placeholder: "全部",
            name: "check_status",
            value: "0",
            options: [
              {
                value: "0",
                label: "全部",
              },
              {
                value: "1",
                label: "待审核",
              },
              {
                value: "2",
                label: "审核通过",
              },
              {
                value: "3",
                label: "审核拒绝",
              },
            ],
          },
          {
            selectisshow: true,
            ismust: false,
            title: "收益类型:",
            placeholder: "全部",
            name: "income_type",
            value: "",
            options: [
              {
                value: "0",
                label: "全部",
              },
              {
                value: "1",
                label: "共享文档",
              },
              {
                value: "2",
                label: "收费文档",
              },
            ],
          },
          {
            selectisshow: true,
            ismust: false,
            onchange: 2,
            title: "平台:",
            placeholder: "请选择平台",
            name: "plat",
            value: "",
            options: [
              {
                value: "1",
                label: "自助版",
              },
              {
                value: "2",
                label: "家庭版",
              },
              {
                value: "3",
                label: "企业版",
              },
            ],
          },
          {
            selectisshow: true,
            ismust: false,
            onchange: 3,
            title: "一级分类:",
            placeholder: "全部",
            name: "cate_one",
            value: "",
            options: [],
          },
          {
            selectisshow: true,
            ismust: false,
            onchange: 4,
            title: "二级分类:",
            placeholder: "全部",
            name: "cate_two",
            value: "",
            options: [],
          },
          {
            selectisshow: true,
            ismust: false,
            onchange: 5,
            title: "三级分类:",
            placeholder: "全部",
            name: "cate_three",
            value: "",
            options: [],
          },
          {
            selectisshow: true,
            ismust: false,
            onchange: 6,
            title: "四级分类:",
            placeholder: "全部",
            name: "cate_four",
            value: "",
            options: [],
          },
          {
            selectisshow: true,
            ismust: false,
            title: "来源:",
            placeholder: "全部",
            name: "type",
            value: "",
            options: [
              {
                value: "0",
                label: "全部",
              },
              {
                value: "1",
                label: "小程序",
              },
              {
                value: "2",
                label: "后台",
              },
            ],
          },
          {
            selectisshow: true,
            ismust: false,
            title: "标签:",
            placeholder: "全部",
            name: "tab_info",
            value: "",
            options: [],
          },
          {
            selecttimeduanisshow: true, //该字段表示时间段模块状态：且已写死（字段名不可修改!）
            ismust: false,
            title: "创建时间：",
            placeholder: {
              placeholderstart: "请选择开始时间",
              placeholderend: "请选择结束时间",
            },
            name: "add_time", //参数名
            value: {
              valuestart: ref(""),
              valueend: ref(""),
              endstatus: true,
            },
            rangeseparator: "至", //分隔符
            divinterval: "3%", //上方字中-框右侧距离
            selectwidth: "100%", //上方字中-总框长度
            selectmaxwidth: "97%", //时间段-最大宽度
            marginright: "0", //与右侧距离
          },
        ],
      },

      alltype: [],
      alloptions: [],

      // table表单的数据
      // 由于table表单与分页结合，导致点击单选框或者其余弹窗事件时，多选框状态会被清空！
      // 因此将双向绑定的动态数据重新赋值给realtabledata做数据断离层，即可解决选中状态被清空问题！！！
      realtabledata: [],

      //table表单相关数据
      seniorform: {},
      currentPage: 1,
      pageSizes: [20, 50, 100],
      PageSize: 20,
      multipleSelection: [], //多选选择的数据
      thispagename: "", //通过路由获取当前页面的path值
      searchwidth: "580px",
      //弹框
      ruleForm: {
        numbers: 123123,
        ids: "", //选中的数据id集
        id: "", //选择平台的id值
        cate_id: "", //选中的分类第4级id
        disabled: true,
        changedatas: [], //选中的数据集
      },
      //弹窗中-form表单-必填规则
      rules: {
        id: [
          {
            required: true,
            message: "平台不能为空",
            trigger: "blur",
          },
        ],
        cateid: [
          {
            required: true,
            message: "分类不能为空",
            trigger: "blur",
          },
        ],
      },
      gettypeall: [], //接收总分类
      getmytype: [
        {
          gettype: [], //分类-平台
          gettypechild: [], // 分类-子分类
        },
      ], //分类-配置

      mypowerlimits: JSON.parse(localStorage.getItem("powerlimits")), //获取权限
      mytoken: localStorage.getItem("token"), //获取存储的token
      pagesdatas: [], //存储最近一次的筛选条件，供分页使用
      resArr: [],
    };
  },
  setup() {
    const dialogVisible = ref(false);
    const handleCloseSenior = (done) => {
      done();
    };

    return {
      dialogVisible,
      handleCloseSenior,
    };
  },
  mounted() {
    //获取当前页面路由path值
    this.thispagename = this.$route.path;
  },
  methods: {
    // 按钮-[批量转移分类]
    tansubmitForm(datas, flag) {
      // 批量转移分类
      if (flag == 6) {
        if (datas.length > 0) {
          this.dialogVisible = true
          this.addTanClick()
        } else {
          ElMessage({
            type: "error",
            message: "请至少选择一个资源 , 再批量转移分类",
          });
        }
      }
    },
    // ------------排序--------------------------
    sortchangeEvent(e) {
      console.log(e);

      let orderval = "";
      if (e.order == "ascending") {
        orderval = 1;
      } else {
        orderval = -1;
      }

      if (e.prop != null) {
        this.pagesdatas.orderkey = e.prop;
        this.pagesdatas.orderval = orderval;

        //加载loading
        this.loading = true; //通过Ajax向后台获取数据
        //通过Ajax向后台获取数据
        axios
          .post(
            "/manage/sharePrintSource/indexSource",
            {
              sourcename: this.pagesdatas.sourcename,
              type: this.pagesdatas.type,
              income_type: this.pagesdatas.income_type,
              check_status: this.pagesdatas.check_status,
              plat: this.pagesdatas.plat,
              cate_one: this.pagesdatas.cate_one,
              cate_two: this.pagesdatas.cate_two,
              cate_three: this.pagesdatas.cate_three,
              cate_four: this.pagesdatas.cate_four,
              tab_info: this.pagesdatas.tab_info,
              add_time: this.pagesdatas.add_time,
              orderkey: e.prop,
              orderval: orderval,
              page: 1,
              page_size: this.PageSize,
            },
            {
              headers: {
                Auth: localStorage.getItem("token"),
              },
            }
          )
          .then((response) => {
            this.myaxiosfunc(response.data.result);
          })
          .catch(function (error) {
            // 请求失败处理
            console.log(error);
          });
      }
    },
    // 改变状态
    changeStatus(data) {
      // console.log("切换状态的data值：" + data.status);
      axios
        .post(
          "/manage/sharePrintSource/changeSourceStatus",
          {
            id: data.id,
          },
          {
            headers: {
              Auth: localStorage.getItem("token"),
            },
          }
        )
        .then((response) => {
          if (response.data.code == 0) {
            ElMessage({
              type: "success",
              message: response.data.message,
            });
          } else {
            // 切换状态失败-复原状态
            if (data.status == 1) {
              data.status = 0;
            } else {
              data.status = 1;
            }
          }
        })
        .catch(function (error) {
          // 请求失败处理
          console.log(error);
        });
    },
    // 【高级筛选】接收select选择的返回值-并进行函数操作
    selectDatainfoGaoEvent(datas) {
      console.log(datas);
      if (datas.name == "plat") {
        var filterArr1 = this.resArr
          .filter((item) => item.pid == datas.changeinfo.value)
          .map((it) => {
            return { label: it.cate_name, value: it.id };
          });
        var filterArr = [{ label: "全部", value: 0 }, ...filterArr1];
        this.myselect.select[3].options = filterArr;
        this.myselect.select[3].value = filterArr[0].value;
        this.myselect.select[4].options = [];
        this.myselect.select[4].value = "";

        this.myselect.select[5].options = [];
        this.myselect.select[5].value = "";
        this.myselect.select[6].options = [];
        this.myselect.select[6].value = "";
      }
      if (datas.name == "cate_one") {
        const objArr1 = this.resArr
          .filter((item) => item.pid == datas.changeinfo.value)
          .map((it) => {
            return { label: it.cate_name, value: it.id };
          });
        const objArr = [{ label: "全部", value: 0 }, ...objArr1];
        this.myselect.select[4].value = objArr[0].value;
        this.myselect.select[4].options = objArr;
        this.myselect.select[5].options = [];
        this.myselect.select[5].value = "";
        this.myselect.select[6].options = [];
        this.myselect.select[6].value = "";
      }
      if (datas.name == "cate_two") {
        const catetwoArr1 = this.resArr
          .filter((item) => item.pid == datas.changeinfo.value)
          .map((it) => {
            return { label: it.cate_name, value: it.id };
          });
        const catetwoArr = [{ label: "全部", value: 0 }, ...catetwoArr1];
        this.myselect.select[5].options = catetwoArr;
        this.myselect.select[5].value = catetwoArr[0].value;
        this.myselect.select[6].options = [];
        this.myselect.select[6].value = "";
      }
      if (datas.name == "cate_three") {
        const catethreeArr1 = this.resArr
          .filter((item) => item.pid == datas.changeinfo.value)
          .map((it) => {
            return { label: it.cate_name, value: it.id };
          });
        const catethreeArr = [{ label: "全部", value: 0 }, ...catethreeArr1];
        this.myselect.select[6].options = catethreeArr;
        this.myselect.select[6].value = catethreeArr[0].value;
      }
    },
    toArr(tree) {
      let arrs = [];
      let result = [];
      arrs = arrs.concat(tree);
      // 把数组中每一项全部拉平
      while (arrs.length) {
        let first = arrs.shift(); // 弹出第一个元素
        // 直到每一项数据都没有children
        if (first.child) {
          //如果有children
          arrs = arrs.concat(first.child);
          delete first["child"];
        }
        result.push(first);
      }
      return result;
    },
    //批量转移分类 -用于下拉框-点击&选择事件-控制框是否可选择
    finduserSaleList(data) {
      if (data.child) {
        this.ruleForm.disabled = false;
        this.getmytype[0].gettypechild = data.child;
      } else {
        this.ruleForm.disabled = true;
        this.ruleForm.cate_id = "";
      }
    },
    //批量转移分类 - 多级联选-点击事件
    handleChange(value) {
      console.log(value);
    },
    //批量转移分类 - 提交
    submitForm(formName) {
      var formdatas = this.$refs[formName].model;
      this.$refs[formName].validate((valid) => {
        if (valid) {
          // cate第4级id
          var cate = formdatas.cate_id[formdatas.cate_id.length - 1];
          axios
            .post(
              "/manage/sharePrintSource/changePrintCate",
              {
                id: formdatas.ids,
                cate_id: cate,
              },
              {
                headers: {
                  Auth: localStorage.getItem("token"),
                },
              }
            )
            .then((response) => {
              if (response.data.code === 0) {
                this.dialogVisible = true
                ElMessage({
                  type: "success",
                  message: "提交成功！",
                  duration: 1000,
                  onClose: () => {
                    this.reload(); //刷新
                  },
                });
              }
            })
            .catch(function (error) {
              // 请求失败处理
              console.log(error);
            });
        } else {
          console.log("error submit!!");
          return false;
        }
      });
    },
    //批量转移分类 -重置
    resetForm(formName) {
      // 清空且移除校验
      this.$refs[formName].clearValidate(
        this.ruleForm.cate_id,
        this.ruleForm.id
      );
      // 重置cate_id&disabled&id
      this.ruleForm.cate_id = "";
      this.ruleForm.disabled = true;
      this.ruleForm.id = "";
    },
    //【批量转移分类】点击按钮-弹出弹框并赋值弹窗基本配置
    addTanClick() {
      // 重置cate_id&disabled
      this.ruleForm.cate_id = "";
      this.ruleForm.disabled = true;
      this.ruleForm.id = "";
      this.ruleForm.ids = "";

      // 赋值numbers与changedatas与ids集
      this.ruleForm.numbers = this.multipleSelection.length;
      this.ruleForm.changedatas = this.multipleSelection;
      var getids = [];
      for (let index = 0; index < this.multipleSelection.length; index++) {
        const element = this.multipleSelection[index];
        getids.push(element.id);
      }
      this.ruleForm.ids = getids.join(",");
    },
    // 预览/下载文档
    open_win(data) {
      window.open(data.sourcefile);
    },
    //table表-操作中-删除按钮（函数）
    deleteRow(rows) {
      //在table表中删除当前行信息，而rows.splice(index, 1)仅属于前端删除，可配合后具体操作
      ElMessageBox.confirm("确定删除:“" + rows.sourcename + "”吗?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          axios
            .post(
              "/manage/SharePrintSource/delSource",
              {
                id: rows.id,
              },
              {
                headers: {
                  Auth: localStorage.getItem("token"),
                },
              }
            )
            .then((response) => {
              if (response.data.code === 0) {
                ElMessage({
                  type: "success",
                  message: "删除成功！",
                  duration: 1000,
                  onClose: () => {
                    this.reload(); //刷新
                  },
                });
              }
            })
            .catch(function (error) {
              // 请求失败处理
              console.log(error);
            });
        })
        .catch(function () {
          // 请求失败处理
          ElMessage({
            type: "info",
            message: "取消删除",
          });
        });
    },
    //table表中-当选项发生变化是触发该函数
    handleSelectionChange(val) {
      console.log("this is:" + val);
      this.multipleSelection = val;
    },
    //分页器中-当选择条数时触发该函数
    handleSizeChange(val) {
      console.log(`每页 ${val} 条`);
      this.PageSize = val;
      this.currentPage = 1;

      //加载loading
      this.loading = true;
      //通过Ajax向后台获取数据
      axios
        .post(
          "/manage/sharePrintSource/indexSource",
          {
            sourcename: this.pagesdatas.sourcename,
            type: this.pagesdatas.type,
            income_type: this.pagesdatas.income_type,
            check_status: this.pagesdatas.check_status,
            plat: this.pagesdatas.plat,
            cate_one: this.pagesdatas.cate_one,
            cate_two: this.pagesdatas.cate_two,
            cate_three: this.pagesdatas.cate_three,
            cate_four: this.pagesdatas.cate_four,
            tab_info: this.pagesdatas.tab_info,
            add_time: this.pagesdatas.add_time,
            orderkey: this.pagesdatas.orderkey,
            orderval: this.pagesdatas.orderval,
            page: 1,
            page_size: val,
          },
          {
            headers: {
              Auth: localStorage.getItem("token"),
            },
          }
        )
        .then((response) => {
          this.myaxiosfunc(response.data.result);
        })
        .catch(function (error) {
          // 请求失败处理
          console.log(error);
        });
    },
    //分页器中-当选择分页时触发该函数
    handleCurrentChange(val) {
      console.log(`当前页: 第${val}页`);
      this.currentPage = val;

      //加载loading
      this.loading = true;
      //通过Ajax向后台获取数据
      axios
        .post(
          "/manage/sharePrintSource/indexSource",
          {
            sourcename: this.pagesdatas.sourcename,
            type: this.pagesdatas.type,
            income_type: this.pagesdatas.income_type,
            check_status: this.pagesdatas.check_status,
            plat: this.pagesdatas.plat,
            cate_one: this.pagesdatas.cate_one,
            cate_two: this.pagesdatas.cate_two,
            cate_three: this.pagesdatas.cate_three,
            cate_four: this.pagesdatas.cate_four,
            tab_info: this.pagesdatas.tab_info,
            add_time: this.pagesdatas.add_time,
            orderkey: this.pagesdatas.orderkey,
            orderval: this.pagesdatas.orderval,
            page: val,
            page_size: this.PageSize,
          },
          {
            headers: {
              Auth: localStorage.getItem("token"),
            },
          }
        )
        .then((response) => {
          this.myaxiosfunc(response.data.result);
        })
        .catch(function (error) {
          // 请求失败处理
          console.log(error);
        });
    },
    //table表中-表格带状态-可扩展
    tableRowClassName({ rowIndex }) {
      //隔行显示
      if ((rowIndex + 1) % 2 === 0) {
        return "warning-row";
      }
      return "";
    },
    //点击进行路由页面跳转以及携带参数
    routeraddrClick(routeraddr, data) {
      if (data) {
        this.$router
          .push({
            path: routeraddr,
            query: { id: data.id, sourcename: data.sourcename },
          })
          .catch((error) => error);
      } else {
        this.$router.push(routeraddr).catch((error) => error);
      }
    },
    //导出数据
    exportDatas() {
      //通过Ajax向后台获取数据
      axios
        .post(
          "/manage/sharePrintSource/indexSource",
          {
            sourcename: this.pagesdatas.sourcename,
            type: this.pagesdatas.type,
            income_type: this.pagesdatas.income_type,
            check_status: this.pagesdatas.check_status,
            plat: this.pagesdatas.plat,
            cate_one: this.pagesdatas.cate_one,
            cate_two: this.pagesdatas.cate_two,
            cate_three: this.pagesdatas.cate_three,
            cate_four: this.pagesdatas.cate_four,
            tab_info: this.pagesdatas.tab_info,
            add_time: this.pagesdatas.add_time,
            orderkey: this.pagesdatas.orderkey,
            orderval: this.pagesdatas.orderval,
            export: 1,
          },
          {
            headers: {
              Auth: localStorage.getItem("token"),
            },
          }
        )
        .then((response) => {
            console.log(response.data.message)
        //   ElMessage({ type: "success", message: response.data.message });
        })
        .catch(function (error) {
          // 请求失败处理
          console.log(error);
        });
    },
    // 重置-下拉数据
    resetDownBoxEvent() {
      this.myselect.select[3].options = [];
      this.myselect.select[4].options = [];
      this.myselect.select[5].options = [];
      this.myselect.select[6].options = [];
    },

    //获取当前windows页面信息-实时修改【弹窗】的宽度
    getwindowinfo() {
      this.sreenheight = document.documentElement.clientHeight;
      this.sreenwidth = document.documentElement.clientWidth;
      if (this.sreenwidth < 600) {
        this.searchwidth = this.sreenwidth * 0.8 + "px";
      } else {
        this.searchwidth = "580px";
      }
    },

    //提交等相关event事件函数
    //获取Container-Pagedata-Maintwo-高级筛选-开始检索返回的数据
    searchFormEvent(data) {
      console.log("高级筛选-开始检索返回数据(页面):" + JSON.stringify(data));
      //加载loading
      this.pagesdatas = data; //将筛选条件赋值给本页的pagesdatas存储，供分页使用
      //加载loading
      this.loading = true;
      //判断-添加时间
      var getaddtime = null;
      if (data.add_time.start == "") {
        getaddtime = "";
      } else {
        if (data.add_time.end == "") {
          getaddtime =
            data.add_time.start + " ~ " + (data.add_time.start + 86400 - 1);
        } else {
          getaddtime =
            data.add_time.start + " ~ " + (data.add_time.end + 86400 - 1);
        }
      }
      this.pagesdatas.add_time = getaddtime;
      //通过Ajax向后台获取数据
      axios
        .post(
          "/manage/sharePrintSource/indexSource",
          {
            sourcename: data.sourcename,
            type: data.type,
            income_type: data.income_type,
            check_status: data.check_status,
            plat: data.plat,
            cate_one: data.cate_one == 0 ? "" : data.cate_one,
            cate_two: data.cate_two == 0 ? "" : data.cate_two,
            cate_three: data.cate_three == 0 ? "" : data.cate_three,
            cate_four: data.cate_four == 0 ? "" : data.cate_four,
            tab_info: data.tab_info,
            add_time: getaddtime,
            orderkey: this.pagesdatas.orderkey,
            orderval: this.pagesdatas.orderval,
            page: 1,
            page_size: this.PageSize,
          },
          {
            headers: {
              Auth: localStorage.getItem("token"),
            },
          }
        )
        .then((response) => {
          this.myaxiosfunc(response.data.result);
        })
        .catch(function (error) {
          // 请求失败处理
          console.log(error);
        })
        .finally(() => {
          this.myselect.select[4].options = [];
          this.myselect.select[5].options = [];
          this.myselect.select[6].options = [];
          this.myselect.select[3].options = [];
        });

      // 重置-下拉数据
      this.resetDownBoxEvent();
    },
    //获取Container-Pagedata-Maintwo-搜索-返回的数据
    getSeachinfoEvent(data) {
      console.log("点击查询获取的data数组：" + JSON.stringify(data));
      this.pagesdatas = data; //将筛选条件赋值给本页的pagesdatas存储，供分页使用
      //加载loading
      this.loading = true;
      //通过Ajax向后台获取数据
      axios
        .post(
          "/manage/sharePrintSource/indexSource",
          {
            sourcename: data.sourcename,
            check_status: data.check_status,
            plat: data.plat,
            orderkey: this.pagesdatas.orderkey,
            orderval: this.pagesdatas.orderval,
            page: 1,
            page_size: this.PageSize,
          },
          {
            headers: {
              Auth: localStorage.getItem("token"),
            },
          }
        )
        .then((response) => {
          this.myaxiosfunc(response.data.result);
        })
        .catch(function (error) {
          // 请求失败处理
          console.log(error);
        });

      // 重置-下拉数据
      this.resetDownBoxEvent();
    },
    //数据渲染
    myaxiosfunc(successdata) {
      var newdata = successdata.data;
      for (var i = 0; i < newdata.length; i++) {
        //该字段表示search按钮，且status为1表示启用，2表示禁用
        newdata[i].switchstatus = 4;

        //转换-创建时间
        if (newdata[i].addtime) {
          if (newdata[i].addtime === 0 || newdata[i].addtime === null) {
            newdata[i].addtime = "无创建时间";
          } else {
            newdata[i].addtime = this.$utils.formatDate(newdata[i].addtime);
          }
        } else {
          newdata[i].addtime = "无创建时间";
        }

        //审核-编辑-删除状态
        if (newdata[i].check_status) {
          newdata[i].delete = true;
          if (
            newdata[i].check_status === "待审核" &&
            newdata[i].type === "小程序"
          ) {
            newdata[i].examine = true;
          }
          if (
            newdata[i].check_status === "审核通过" &&
            newdata[i].type === "后台"
          ) {
            newdata[i].edit = true;
          }
          if (
            newdata[i].check_status === "审核通过" &&
            newdata[i].type === "小程序"
          ) {
            newdata[i].edit = true;
          }
        } else {
          newdata[i].delete = true;
        }
      }
      // console.log("当前页面的data:" + JSON.stringify(newdata));
      this.mainthreetableData = newdata;
      this.totalCount = successdata.total;
      this.currentpage = successdata.current_page; //当前页面页数
      this.currentPage = successdata.current_page; //当前页面页数
      this.lastpage = successdata.last_page;
      this.loading = false;

      // 将双向绑定的值赋值给realtabledata做断离层，避免弹窗清空多选被选中的状态！
      this.realtabledata = this.mainthreetableData.slice(
        (this.currentPage - this.lastpage) * this.PageSize,
        this.currentPage * this.PageSize
      );
    },
  },
  created() {
    if (localStorage.getItem("token") === null || !localStorage.getItem("token")) {
      this.isshow = false;
      this.$router.push("/").catch((error) => error);
    } else {
      window.addEventListener("resize", this.getwindowinfo); // 注册监听器-实时检测浏览器页面宽高
      this.getwindowinfo();
      //通过API获取标签列表
      axios
        .post(
          "/manage/SharePrintSource/sourceTabInfo",
          {},
          {
            headers: {
              Auth: localStorage.getItem("token"),
            },
          }
        )
        .then((response) => {
          let configinfo = response.data
          var alldatas = Array();
          var num = 0;
          alldatas[num] = { value: num, label: "全部" };
          for (var key in configinfo) {
            num = num + 1;
            alldatas[num] = { value: configinfo[key].sort_val, label: configinfo[key].sort_key };
          }
          //这里的select[0],是写死的，后续可优化！注意写死的状况下，select的位置不可移动
          this.myselect.select[8].options = alldatas;
        })
        .catch(function (error) {
          // 请求失败处理
          console.log(error);
        });

      //通过API获取配置数据-无限级分类列表
      axios
        .post(
          "/manage/SharePrintSource/sourceCateInfo",
          {
            page: 1,
            page_size: this.PageSize,
          },
          {
            headers: {
              Auth: localStorage.getItem("token"),
            },
          }
        )
        .then((response) => {
          this.gettypeall = response.data;
          this.getmytype[0].gettype = this.gettypeall;
          this.resArr = this.toArr(JSON.parse(JSON.stringify(response.data)));
        })
        .catch(function (error) {
          // 请求失败处理
          console.log(error);
        });

      //加载loading
      this.loading = true;

      this.pagesdatas = {
        page: 1,
        page_size: this.PageSize,
      }
      let routerinfo = this.$route.query;
      // 平台
      if (routerinfo.plat) {
        this.maintwoforminfo.selectleft.select[1].value = routerinfo.plat
        this.myselect.select[2].value = routerinfo.plat
      }
      // 一级分类
      if (routerinfo.cate_one) {
        this.pagesdatas.cate_one = routerinfo.cate_one;
        // this.myselect.select[3].value = routerinfo.cate_one
      }
      // 二级分类
      if (routerinfo.cate_two) {
        this.pagesdatas.cate_two = routerinfo.cate_two
        // this.myselect.select[4].value = routerinfo.cate_two
      }
      // 三级分类
      if (routerinfo.cate_three) {
        this.pagesdatas.cate_three = routerinfo.cate_three
        // this.myselect.select[5].value = routerinfo.cate_three
      }
      // 四级分类
      if (routerinfo.cate_four) {
        this.pagesdatas.cate_four = routerinfo.cate_four
        // this.myselect.select[6].value = routerinfo.cate_four
      }
      // 标签
      if (routerinfo.tabinfoid) {
        this.pagesdatas.tab_info = routerinfo.tabinfoid;
        this.myselect.select[8].value = parseInt(routerinfo.tabinfoid)
      }

      //通过Ajax向后台获取数据
      axios
        .post(
          "/manage/sharePrintSource/indexSource",
          this.pagesdatas,
          {
            headers: {
              Auth: localStorage.getItem("token"),
            },
          }
        )
        .then((response) => {
          if (response.data.code === 0) {
            this.myaxiosfunc(response.data.result);
          }
        })
        .catch(function (error) {
          console.log(error);
        });
    }
  },
  components: {
    mainone,
    maintwo,
  },
});
</script>

<style scoped>
/*局部控制-Switch按钮-按钮中显示状态文字*/
/* switch按钮款式 */
#switch :deep(.el-switch__label) {
  position: absolute;
  display: none;
  color: #fff !important;
}

/*关上时文字地位设置*/
#switch :deep(.el-switch__label--right) {
  z-index: 1;
}

/* 调整关上时文字的显示位子 */
#switch :deep(.el-switch__label--right) span {
  margin-left: 3px;
  font-size: 12px;
}

/*敞开时文字地位设置*/
#switch :deep(.el-switch__label--left) {
  z-index: 1;
}

/* 调整敞开时文字的显示位子 */
#switch :deep(.el-switch__label--left) span {
  margin-left: 15px;
  font-size: 12px;
}

/*显示文字*/
#switch :deep(.el-switch__label.is-active) {
  display: block;
}

/* 调整按钮的宽度 */
#switch :deep(.el-switch) .el-switch__core,
#switch :deep(.el-switch) .el-switch__label {
  width: 55px !important;
  margin: 0;
  padding: 5px;
}

/*操作中-按钮样式*/
.el-button--mini {
  min-height: 28px;
  padding: 0 8px;
  font-size: 12px;
  border-radius: calc(var(--el-border-radius-base) - 1px);
}

.mytabletext {
  color: var(--el-color-primary);
  cursor: pointer;
}
</style>