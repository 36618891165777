<template>
    <div class="wrap">
        <el-dialog v-model="dialogDisplay" :title="formData.id ? '编辑' : '添加'">
            <el-form ref="ruleFormRef" :model="formData" :rules="rules" label-width="180px" style="padding: 0 20px 0 0">
                <el-form-item label="批次名称：" prop="name">
                    <el-input v-model="formData.name" style="width: 80%" placeholder="请输入批次名称" maxlength="50"></el-input>
                </el-form-item>
				<el-form-item label="结算单价：" prop="price">
                    <el-input v-model="formData.price" style="width: 80%" placeholder="请输入结算单价"></el-input>
                </el-form-item>
				<el-form-item label="总数量：" prop="num">
                    <el-input v-model="formData.num" style="width: 80%" placeholder="请输入总数量"></el-input>
                </el-form-item>
				<el-form-item label="总价款（元）：" prop="total">
					<span class="c-red">{{ formatPrice(formData.total) }}</span>
                </el-form-item>
				<el-form-item label="预付款比例：" prop="advance_ratio">
                    <el-input v-model="formData.advance_ratio" style="width: 25%" placeholder="请输入预付款比例"></el-input>
					<span> %</span>
                </el-form-item>
				<el-form-item label="预付款（元）：" prop="advance">
					<span class="c-red">{{ formatPrice(formData.advance) }}</span>
                </el-form-item>
				<el-form-item label="余款（元）：" prop="residue">
					<span class="c-red">{{ formatPrice(formData.residue) }}</span>
                </el-form-item>
                <el-form-item label="还款状态：" prop="repay_status">
                    <el-radio-group v-model="formData.repay_status">
                        <el-radio label="1">开启</el-radio>
                        <el-radio label="-1">关闭</el-radio>
                    </el-radio-group>
                </el-form-item>
                <div style="text-align: right">
                    <el-button @click="onCancel">取消 </el-button>
                    <el-button type="primary" @click="onSubmit">确定 </el-button>
                </div>
            </el-form>
        </el-dialog>
    </div>
</template>

<script setup>
import { ref, reactive, watch, defineProps, defineEmits, watchEffect } from 'vue'
import { ElMessage } from 'element-plus'
import http from '@/assets/js/http'

const props = defineProps({
	data: {
		type: Object,
		default: ()=> {
			return {
				data: {},
				api: 'RiskControlAdvance/create',
			}
		}
	}
})

const emit = defineEmits(['success'])
const formData = ref({})
const ruleFormRef = ref()
const dialogDisplay = ref(false)
const rules = reactive({
	name: [
		{
			required: true,
			message: '请输入名称',
			trigger: 'blur',
		},
	],
	price: [
		{
			required: true,
			message: '请输入结算单价',
			trigger: 'blur',
		},
		{
			// 正则表达式(大于等于0的正数或保留两位小数的小数)：/^(([1-9]{1}\d*)|(0{1}))(\.\d{1,2})?$/
			pattern: /^(([1-9]{1}\d*))(\.\d{1,3})?$/,
			message: '结算单价必须为保留三位小数的非零正数',
			trigger: ['blur', 'change'],
		},
	],
	num: [
		{
			required: true,
			message: '请输入总数量',
			trigger: 'blur',
		},
		{
			validator: (rule, value, callback)=> {
				if (value <= 0 || value > 100000) {
					callback(new Error('总数量在0-100000之间'))
				} else {
					callback()
				}
			},
			trigger: ['blur', 'change'],
		}
	],
	advance_ratio: [
		{
			required: true,
			message: '请输入预付款比例',
			trigger: 'blur',
		},
		{
			validator: (rule, value, callback)=> {
				if (value <= 0 || value >= 100) {
					callback(new Error('预付款比例须大于0, 小于100'))
				} else {
					callback()
				}
			},
			trigger: ['blur', 'change'],
		}
	],
	repay_status: [
		{
			required: true,
			message: '请选择还款状态',
			trigger: 'change',
		},
	],
})

// 四舍五入取小数
const roundToDecimal = (number, decimalPlaces = 0)=> {
	const factor = Math.pow(10, decimalPlaces);
	return Math.round(number * factor) / factor;
}

watch(props.data, (newVal)=> {
	const { id, name, price, num, total, advance_ratio, advance, residue, repay_status } = newVal.value.data
	dialogDisplay.value = true
	formData.value = {
		id,
		name: name || '',
		price: price ? price / 100 : '',
		num: num || '',
		total: total || '',
		advance_ratio: advance_ratio || '',
		advance: advance || '',
		residue: residue || '',
		repay_status: repay_status ? String(repay_status) : '1',
	}
})

watchEffect(()=> {
	const { price, num, advance_ratio } = formData.value
	if(price || num) {
		let amount = roundToDecimal((+price * 100) * (+num), 1)
		formData.value.total = amount
		formData.value.advance = roundToDecimal(((+amount) * (advance_ratio ? (+advance_ratio) : 0) / 100), 1)
		formData.value.residue = (+amount) - (+formData.value.advance)
	}
})

const formatPrice = (data) => {
	return data ? ((+data) / 100).toFixed(3) : data 
}

// 提交
const onSubmit = async ()=> {
	try {
		await ruleFormRef.value.validate()
	} catch (err) {
		return false
	}
	const { api } = props.data.value
	const params = {
		...formData.value,
		price: roundToDecimal(Number(formData.value.price) * 100, 1)
	}
	
	await http.post(api, params)

	dialogDisplay.value = false
	emit('success')
}

// 取消
const onCancel = ()=> {
	dialogDisplay.value = false
}

</script>

<style scoped>
.c-red {
	color: #FF0000
}
</style>
