<template>
    <div>
        <el-row style="margin-top: 20px">
            <el-col :span="24" :xs="24" :sm="24" :lg="5">
                <div style="width: 100%"></div>
            </el-col>
            <el-col :span="24" :xs="24" :sm="24" :lg="11">
                <el-form ref="ruleForm" :model="ruleForm" :rules="ruleForm_rules" label-width="180px" class="demo-ruleForm">
                    <el-form-item :label="index + '：'" :prop="index" v-for="(item, index) in ruleForm" :key="index">
                        <el-input v-model="ruleForm[index]" clearable></el-input>
                    </el-form-item>
                    <el-form-item v-if="ruleForm">
                        <el-button type="primary" @click="submitEvent('ruleForm')" style="width: 40%; margin-right: 10px">保存</el-button>
                        <el-button @click="this.$router.go(-1)">取消</el-button>
                    </el-form-item>
                </el-form>
            </el-col>
            <el-col :span="24" :xs="24" :sm="24" :lg="8">
                <div style="width: 100%"></div>
            </el-col>
        </el-row>
    </div>
</template>
<script>
import { defineComponent } from 'vue'
import axios from 'axios'
import { ElMessage } from 'element-plus'

export default defineComponent({
    name: 'formDefault',
    props: ['Form', 'FormRules', 'GetAxios'],
    watch: {
        Form: {
            handler(newVal) {
                this.ruleForm = newVal
            },
            deep: true,
            immediate: true
        },
        FormRules: {
            handler(newVal) {
                this.ruleForm_rules = newVal
            },
            deep: true,
            immediate: true
        },
        GetAxios: {
            handler(newVal) {
                this.my_axios = newVal
            },
            deep: true,
            immediate: true
        }
    },
    data() {
        return {
            ruleForm: {
                client_id: '',
                client_secret: ''
            },
            ruleForm_rules: {
                client_id: [
                    {
                        required: true,
                        message: 'client_id为必填项',
                        trigger: 'blur'
                    }
                ],
                client_secret: [
                    {
                        required: true,
                        message: 'client_secret为必填项',
                        trigger: 'blur'
                    }
                ]
            },

            // 接口
            my_axios: {
                get_axios: '/manage/Manage/tencent',
                key_name: ''
            }
        }
    },
    methods: {
        // 提交
        submitEvent(formName) {
            // var getdata = this.$refs[formName].model
            this.$refs[formName].validate((valid) => {
                if (valid) {
                    axios
                        .post(this.my_axios.get_axios + '&id=' + this.$route.query.id + '&key_name=' + this.my_axios.key_name, this.ruleForm, {
                            headers: {
                                Auth: localStorage.getItem('token')
                            }
                        })
                        .then((response) => {
                            if (response.data.code === 0) {
                                ElMessage({
                                    type: 'success',
                                    message: '提交成功！'
                                })
                            }
                        })
                        .catch(function(error) {
                            console.log(error)
                        })
                } else {
                    console.log('error submit!!')
                    return false
                }
            })
        },
        // 反显
        showBackEvent(myaxios) {
            axios
                .get(myaxios.get_axios, {
                    headers: {
                        Auth: localStorage.getItem('token')
                    },
                    params: {
                        id: this.$route.query.id,
                        key_name: myaxios.key_name
                    }
                })
                .then((response) => {
                    let getdatas = response.data.result
                    if (Object.values(getdatas).length == 0) {
                        // 腾讯文档配置
                        if (this.my_axios.key_name == 'document_tencent') {
                            this.ruleForm = {
                                client_id: '',
                                client_secret: ''
                            }
                        }
                        // 金山文档配置
                        if (this.my_axios.key_name == 'document_goldenHill') {
                            this.ruleForm = {
                                app_id: '',
                                app_key: ''
                            }
                        }
                        // 百度文档配置
                        if (this.my_axios.key_name == 'document_baidu') {
                            this.ruleForm = {
                                AppID: '',
                                AppKey: '',
                                SecretKey: '',
                                SignKey: ''
                            }
                        }
                    } else {
                        this.ruleForm = getdatas
                    }
                })
                .catch(function(error) {
                    console.log(error)
                })
        }
    },
    created() {
        this.showBackEvent(this.my_axios)
    }
})
</script>
<style scoped></style>
