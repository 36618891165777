<template>
    <div>
        <mainone :mainonebread="mainonebread"></mainone>
        <!--table表单-->
        <div>
            <!--主list列表-->
            <div style="margin-top: 10px; background-color: #ffffff; border-radius: 4px; line-height: 40px; padding: 0 20px">
                <div style="line-height: 60px">
                    <!--左侧名称-->
                    <div style="float: left; font-size: 14px">数据列表</div>
                    <!--右侧按钮-->
                    <div style="float: right">
                        <!--自定义按钮-点击出现弹框&点击跳转子类页面@click="routeraddrClick('/XX路由')"-->
                        <!--1、点击出现弹框@click="addTanClick(XX弹框配置信息,dialogVisible = true);resetFormSenior(XXform表单)"-->
                        <!--2、点击跳转子类页面@click="routeraddrClick('/XX路由')-->
                        <el-button type="primary" plain @click="add" v-if="mypowerlimits.cklx_xz">添加</el-button>
                    </div>
                </div>

                <div>
                    <!--table表单-->
                    <el-table ref="multipleTable" :row-class-name="tableRowClassName" :data="mainthreetableData.slice((currentPage - lastpage) * PageSize, currentPage * PageSize)" style="width: 100%" min-height="900" border v-loading="loading" element-loading-text="正在飞速加载中..." @selection-change="handleSelectionChange" @cell-click="handleOneData">
                        <!--多选框-用于用户选择性导出数据-->
                        <el-table-column type="selection" width="55" v-if="mainthreeisshowset" />
                        <el-table-column v-for="(mytabletitle, i) in mainthreetabletitle" :key="i" :fixed="mytabletitle.fixedstatu" :prop="mytabletitle.prop" :label="mytabletitle.label" :width="mytabletitle.width">
                            <!--这里先通过table列表中的title值进行判断，在进行渲染展示-->
                            <!--例如：status状态字段，判断最终的值是那种表现形式等-->
                            <template #default="scope" v-if="mytabletitle.prop === 'status'">
                                <div v-if="mytabletitle.prop === 'status'">
                                    <div v-if="scope.row.switchstatus === 4" id="switch">
                                        <el-switch v-model="scope.row.status" active-text="开启" inactive-text="关闭" :active-value="1" :inactive-value="-1" active-color="#13ce66" @click="mypowerlimits.cklx_qy == true ? changeStatus(scope.row) : ''" :disabled="!mypowerlimits.cklx_qy" inactive-color="#cbcbcb"> </el-switch>
                                    </div>
                                </div>
                            </template>
                        </el-table-column>
                        <!--操作&按钮-->
                        <el-table-column :fixed="false" label="操作" :width="mainthreehandle" v-if="mainthreeisshowope" prop="myoperation" id="operation">
                            <template #default="scope">
                                <!--操作：按照页面区分，且每个按钮对应相关函数-->
                                <!--操作-资源管理-资源分类-->
                                <div v-if="mainthreetableope === 11">
                                    <el-button @click.prevent="deleteRow(scope.$index, mainthreetableData)" type="danger" size="mini" icon="el-icon-delete" v-if="mypowerlimits.cklx_sc"></el-button>
                                </div>
                            </template>
                        </el-table-column>
                    </el-table>
                    <!--分页器-->
                    <div class="block" style="text-align: center; margin: 30px 0">
                        <el-pagination background @size-change="handleSizeChange" @current-change="handleCurrentChange" :current-page="currentPage" :page-sizes="pageSizes" :page-size="PageSize" layout="total, sizes, prev, pager, next, jumper" :total="totalCount"> </el-pagination>
                    </div>
                </div>
            </div>

            <!--增加标签组-弹出框-->
            <FormDialog :tagsgroupData="tagsgroupData" @success="successEvent"></FormDialog>
        </div>
    </div>
</template>

<script>
import mainone from '@/components/Mainone';
import { ElMessageBox, ElMessage } from 'element-plus';
import axios from 'axios';
import { defineComponent } from 'vue';
import FormDialog from './components/formDialog.vue';

export default defineComponent({
    name: 'vresourcesclassify',
    data() {
        return {
            mainonebread: [
                {
                    name: '仓库',
                },
                {
                    name: '基础配置',
                },
                {
                    name: '出库类型',
                },
            ], // 当前页-【面包屑导航】
            mainthreeisshowbut: true, // 数据列表中-【添加按钮】
            mainthreeadddata: '/resourcesclassifyadd', //点击添加按钮-跳转路由
            mainthreeisshowset: false, // 数据列表中-复选框
            mainthreeisshowope: true, // 数据列表中-操作-根据页面显示模块（整页）
            mainthreetableope: 11, // 数据列表中-操作-根据页面显示控件（整页）
            mainthreehandle: 120, // 数据列表中-操作-宽度
            mainthreetabletitle: [
                {
                    fixedstatu: 'left',
                    prop: 'id',
                    label: 'ID',
                    width: '100',
                },
                {
                    fixedstatu: false,
                    prop: 'name',
                    label: '类型名称',
                    width: '',
                },
                {
                    fixedstatu: false,
                    prop: 'remark',
                    label: '备注',
                    width: '',
                },
                {
                    fixedstatu: false,
                    prop: 'status',
                    label: '状态',
                    width: '',
                },
                {
                    fixedstatu: false,
                    prop: 'truename',
                    label: '创建人',
                    width: '',
                },
                {
                    fixedstatu: false,
                    prop: 'addtime',
                    label: '创建时间',
                    width: '',
                },
            ], // 数据列表中-数据表头
            mainthreestatus: true, // 数据列表中-属于search按钮表示状态
            mainthreetableData: [], // 数据列表中-具体数据
            totalCount: 1, //数据列表中-数据总条数
            lastpage: 1, //数据列表中-数据总页数（默认以20条一页）

            //table表单相关数据
            seniorform: {},
            currentPage: 1,
            pageSizes: [20, 50, 100, 1000],
            PageSize: 20,
            multipleSelection: [],
            thispagename: '', //通过路由获取当前页面的path值
            searchwidth: '580px',
            tancontent: {
                title: '弹框标题',
                content: {},
            }, //弹框

            mypowerlimits: JSON.parse(localStorage.getItem('powerlimits')), //获取权限
            mytoken: localStorage.getItem('token'), //获取存储的token
            pagesdatas: [], //存储最近一次的筛选条件，供分页使用
            tagsgroupData: {
                data: {},
            },
        };
    },
    mounted() {
        //获取当前页面路由path值
        this.thispagename = this.$route.path;
    },
    methods: {
        // table表单-状态改变
        changeStatus(data) {
            axios
                .post(
                    '/manage/WarehouseConfig/outInTypeSwitch',
                    {
                        id: data.id,
                        status: data.status,
                    },
                    {
                        headers: {
                            Auth: localStorage.getItem('token'),
                        },
                    }
                )
                .then((response) => {
                    if (response.data.code === 0) {
                        ElMessage({
                            type: 'success',
                            message: response.data.message,
                        });
                    } else {
                        // 切换状态失败-复原状态
                        if (data.status == 1) {
                            data.status = -1;
                        } else {
                            data.status = 1;
                        }
                    }
                })
                .catch(function (error) {
                    console.log(error);
                });
        },

        //table表-操作中-删除按钮（函数）
        deleteRow(index, rows) {
            console.log(rows);
            ElMessageBox.confirm("确定删除该条 ' " + rows[index].name + " ' 数据吗?", '提示：', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning',
            })
                .then(() => {
                    axios
                        .post(
                            '/manage/WarehouseConfig/outInTypeSwitch',
                            {
                                id: rows[index].id,
                                status: -2,
                            },
                            {
                                headers: {
                                    Auth: localStorage.getItem('token'),
                                },
                            }
                        )
                        .then((response) => {
                            if (response.data.code === 0) {
                                ElMessage({
                                    type: 'success',
                                    message: '删除成功',
                                });
                                //在table表中删除当前行信息，而rows.splice(index, 1)仅属于前端删除，可配合后具体操作
                                rows.splice(index, 1);
                            }
                        })
                        .catch(function (error) {
                            // 请求失败处理
                            ElMessage({
                                type: 'error',
                                message: '删除失败！' + error,
                            });
                        });
                })
                .catch(() => {
                    ElMessage({
                        type: 'info',
                        message: '取消删除',
                    });
                });
        },
        //table表中-当选项发生变化是触发该函数
        handleSelectionChange(val) {
            console.log('this is:' + val);
            this.multipleSelection = val;
        },
        //分页器中-当选择条数时触发该函数
        handleSizeChange(val) {
            console.log(`每页 ${val} 条`);
            this.PageSize = val;
            this.currentPage = 1;

            //加载loading
            this.loading = true;
            //通过Ajax向后台获取数据
            axios
                .get('/manage/WarehouseConfig/outInTypeList', {
                    headers: {
                        Auth: localStorage.getItem('token'),
                    },
                    params: {
                        page: 1,
                        page_size: val,
                    },
                })
                .then((response) => {
                    this.myaxiosfunc(response.data.result);
                })
                .catch(function (error) {
                    console.log(error);
                });
        },
        //分页器中-当选择分页时触发该函数
        handleCurrentChange(val) {
            console.log(`当前页: 第${val}页`);
            this.currentPage = val || 1;

            //加载loading
            this.loading = true;
            //通过Ajax向后台获取数据
            axios
                .get('/manage/WarehouseConfig/outInTypeList', {
                    headers: {
                        Auth: localStorage.getItem('token'),
                    },
                    params: {
                        page: val || 1,
                        page_size: this.PageSize,
                    },
                })
                .then((response) => {
                    this.myaxiosfunc(response.data.result);
                })
                .catch(function (error) {
                    console.log(error);
                });
        },
        //table表中-表格带状态-可扩展
        tableRowClassName({ rowIndex }) {
            //隔行显示
            if ((rowIndex + 1) % 2 === 0) {
                return 'warning-row';
            }
            return '';
        },
        //点击按钮-弹出弹框并赋值弹窗基本配置
        addTanClick(taninfo) {
            console.log(taninfo);
            this.tancontent.content = taninfo.content;
            this.tancontent.title = taninfo.title;
        },
        //点击进行路由页面跳转以及携带参数
        routeraddrClick(routeraddr, rowdata) {
            if (rowdata) {
                this.$router
                    .push({
                        path: routeraddr,
                        query: { pid: rowdata.id },
                    })
                    .catch((error) => error);
            } else {
                //仅跳转
                this.$router.push(routeraddr).catch((error) => error);
            }
        },

        //获取当前windows页面信息-实时修改【弹窗】的宽度
        getwindowinfo() {
            this.sreenheight = document.documentElement.clientHeight;
            this.sreenwidth = document.documentElement.clientWidth;
            if (this.sreenwidth < 600) {
                this.searchwidth = this.sreenwidth * 0.8 + 'px';
            } else {
                this.searchwidth = '580px';
            }
        },
        //数据渲染
        myaxiosfunc(successdata) {
            var newdata = successdata.data;
            for (var i = 0; i < newdata.length; i++) {
                //转换更新时间
                if (newdata[i].addtime === 0 || newdata[i].addtime === null) {
                    newdata[i].addtime = '-';
                } else {
                    newdata[i].addtime = this.$utils.formatDate(newdata[i].addtime);
                }

                //该字段表示search按钮，且status为1表示启用，2表示禁用
                newdata[i].switchstatus = 4;
            }
            // console.log('当前页面的data:' + JSON.stringify(newdata))
            this.mainthreetableData = newdata;
            this.totalCount = successdata.total;
            this.currentpage = successdata.current_page; //当前页面页数
            this.lastpage = successdata.last_page;
            this.loading = false;
        },

        add() {
            this.tagsgroupData = {
                data: {},
                dialog: true,
                api: '/manage/WarehouseConfig/outInTypeEdit',
            };
        },

        // 操作成功
        successEvent() {
            this.handleCurrentChange(this.pagesdatas.page);
        },
    },
    created() {
        if (localStorage.getItem('token') === null || !localStorage.getItem('token')) {
            this.isshow = false;
            this.$router.push('/').catch((error) => error);
        } else {
            window.addEventListener('resize', this.getwindowinfo); // 注册监听器-实时检测浏览器页面宽高
            this.getwindowinfo();

            // 权限控制-启用禁用-[当其为false的时候不显示该列]
            if (this.mypowerlimits.tpfl_qy == false) {
                this.mainthreetabletitle.splice(this.mainthreetabletitle.length - 1, 1);
            }

            //加载loading
            this.loading = true;
            //通过Ajax向后台获取数据
            axios
                .get('/manage/WarehouseConfig/outInTypeList', {
                    headers: {
                        Auth: localStorage.getItem('token'),
                    },
                })
                .then((response) => {
                    console.log({ response });
                    this.myaxiosfunc(response.data.result);
                })
                .catch(function (error) {
                    console.log(error);
                });
        }
    },
    components: {
        mainone,
        FormDialog,
    },
});
</script>
<style scoped>
/*局部控制-Switch按钮-按钮中显示状态文字*/
/* switch按钮款式 */
#switch :deep(.el-switch__label) {
    position: absolute;
    display: none;
    color: #fff !important;
}

/*关上时文字地位设置*/
#switch :deep(.el-switch__label--right) {
    z-index: 1;
}

/* 调整关上时文字的显示位子 */
#switch :deep(.el-switch__label--right) span {
    margin-left: 3px;
    font-size: 12px;
}

/*敞开时文字地位设置*/
#switch :deep(.el-switch__label--left) {
    z-index: 1;
}

/* 调整敞开时文字的显示位子 */
#switch :deep(.el-switch__label--left) span {
    margin-left: 15px;
    font-size: 12px;
}

/*显示文字*/
#switch :deep(.el-switch__label.is-active) {
    display: block;
}

/* 调整按钮的宽度 */
#switch :deep(.el-switch) .el-switch__core,
#switch :deep(.el-switch) .el-switch__label {
    width: 55px !important;
    margin: 0;
    padding: 5px;
}

/*操作中-按钮样式*/
.el-button--mini {
    min-height: 28px;
    padding: 0 8px;
    font-size: 12px;
    border-radius: calc(var(--el-border-radius-base) - 1px);
}
</style>
