<template>
    <div class="page-container">
        <div class="container-filter">
            <el-form ref="filterRef" :model="params" inline>
                <div>
                    <el-form-item prop="name" label="批次名称：">
                        <el-input v-model="params.name" placeholder="请输入批次名称" clearable />
                    </el-form-item>
                    <el-form-item prop="status" label="状态：">
						<el-select v-model="params.status" placeholder="请选择状态" clearable>
							<el-option
								v-for="item in statusOptions"
								:key="item.id"
								:label="item.label"
								:value="item.id"
							/>
						</el-select>
                    </el-form-item>
                </div>
                <div>
                    <el-button @click="resetFilter">重置</el-button>
                    <el-button type="primary" @click="getTableData()" :loading="loading">查询</el-button>
                </div>
            </el-form>
        </div>
        <div class="container-table">
            <div class="table-header">
                <div>数据列表</div>
                <div>
                    <el-button type="primary" @click="exportRecord">导出</el-button>
                </div>
            </div>
            <el-table :border="true" :data="table.data" v-loading="loading">
                <el-table-column prop="" label="序号" fixed="left">
                    <template #default="{ $index }">
                        <span>{{ $index + 1 }}</span>
                    </template>
                </el-table-column>
                <el-table-column prop="name" label="批次名称" min-width="180" >
					<template v-if="mypowerlimits.pcktj" #default="{ row }">
                        <span class="skip" @click="goUrl('/batchpaymentfunder', { id: row.id, name: row.name })">{{ row.name }}</span>
                    </template>
                    <template v-else #default="{ row }">
                        <span>{{ row.name }}</span>
                    </template>
                </el-table-column>
                <el-table-column prop="price" label="结算单价（元）" min-width="180" >
					<template #default="{ row }">
                        <span>{{ formatPrice(row.price) }}</span>
                    </template>
                </el-table-column>
                <el-table-column prop="num" label="总数量"/>
                <el-table-column prop="total" label="总价款（元）" min-width="120" >
					<template #default="{ row }">
                        <span>{{ formatPrice(row.total) }}</span>
                    </template>
                </el-table-column>
                <el-table-column prop="advance" label="预付款（元）"  min-width="120">
					<template #default="{ row }">
                        <span>{{ formatPrice(row.advance) }}</span>
                    </template>
                </el-table-column>
                <el-table-column prop="residue" label="余款（元）" min-width="120" >
					<template #default="{ row }">
                        <span>{{ formatPrice(row.residue) }}</span>
                    </template>
                </el-table-column>
                <el-table-column prop="status" label="状态" >
					<template #default="{ row }">
                        <span :style="{ color: statusColor[String(row.status)] }">{{ findLabel(statusOptions, row.status) }}</span>
                    </template>
                </el-table-column>
                <el-table-column prop="checktime" label="验收时间"  width="120">
					<template #default="{ row }">
                        <span>{{ row.checktime ? $utils.formatDate(row.checktime).split(' ')[0] : '' }}</span>
                    </template>
                </el-table-column>
                <el-table-column prop="repay_first_day" label="还款首日"  width="120">
					<template #default="{ row }">
                        <span>{{ row.repay_first_day ? $utils.formatDate(row.repay_first_day).split(' ')[0] : '' }}</span>
                    </template>
                </el-table-column>
                <el-table-column prop="adminid_name" label="操作人" min-width="120" />
                <el-table-column prop="addtime" label="操作时间"  width="180">
                    <template #default="{ row }">
                        <span>{{ row.addtime ? $utils.formatDate(row.addtime) : '' }}</span>
                    </template>
                </el-table-column>
                <el-table-column label="操作" width="80" fixed="right">
                    <template #default="{ row }">
						<el-button type="primary" size="small" @click="goUrl('/batchmanagefunderinfo', { id: row.id })" v-if="mypowerlimits.pcglzf_xq">详情</el-button>
                    </template>
                </el-table-column>
            </el-table>
            <div class="tabler-pagination">
                <el-pagination
                    @size-change="getTableData()"
                    @current-change="getTableData"
                    :current-page="params.page"
                    :page-sizes="[20, 50, 100, 1000]"
                    v-model:page-size="params.page_size"
                    :total="table.total"
                    layout="total, sizes, prev, pager, next, jumper"
                />
            </div>
        </div>
    </div>
</template>

<script setup>
import { ref, reactive } from 'vue'
import http from '@/assets/js/http'
import router from '@/router'
import dict from '../../../js/dict'

// 获取权限
const mypowerlimits = reactive(JSON.parse(localStorage.getItem('powerlimits')))

const statusOptions = reactive([ {id: '', label: '全部'} ,...dict.batchFunderStatusOptions ])

const statusColor = reactive({
    '-2': '#FF0000',
    '-1': '#FF00FF',
    '1': '#008000',
    '2': '#333333',
    '3': '#FFA500',
    '4': '#FF4500',
})

// 过滤
const filterRef = ref()
const params = reactive({
	name: '',
	status: '',
    t: 'z',
    page: 1,
    page_size: 20
})

// 表格
const loading = ref(false)
const table = reactive({
    data: [],
    columns: [],
    total: 0
})

const getTableData = async (page = 1) => {
    params.page = page
    loading.value = true
    try {
        const res = await http.get('RiskControlAdvance/riskList', { params })
		Object.assign(table, res)
    } catch (e) {
        console.log(e)
    }
    loading.value = false
}

const formatPrice = (data) => {
	return data ? ((+data) / 100).toFixed(3) : data 
}

const findLabel = (list, data) => {
	return list.find((item) => item.id === data)?.label
}

// 操作
// 重置查询条件
const resetFilter = () => {
    filterRef.value?.resetFields()
    getTableData()
}

const goUrl = (path, query) => {
	router.push({path, query})
}

// 导出
const exportRecord = async () => {
    await http.get('RiskControlAdvance/riskList', { params: { ...params, export: 1 }, sMsg: true })
}

getTableData()
</script>

<style lang="scss" scoped>
.page-container {
    background-color: #e9eef3;
    .container-filter {
        padding: 12px 24px;
        background-color: #fff;
        .el-form {
            display: flex;
            justify-content: space-between;
        }
        .el-form-item {
            margin-right: 20px;
            margin-bottom: 0px;
        }
    }

    .container-table {
        margin-top: 4px;
        padding: 24px;
        background-color: #fff;
        .table-header {
            display: flex;
            justify-content: space-between;
            margin-bottom: 12px;
        }

        .skip {
            cursor: pointer;
            color: var(--el-color-primary);
        }
        .tabler-pagination {
            display: flex;
            justify-content: space-between;
            margin-top: 12px;
        }
    }
}

.container-drawer {
    .filter-tree {
        margin-top: 24px;
    }
    .drawer-footer {
        margin-top: 24px;
        display: flex;
        justify-content: flex-end;
    }
}
</style>
