import axios from "axios";

export const useAxios = (url, method, data) => {
  return new Promise((resolve, reject) => {
    const axiosConfig = {
      url,
      method: method.toUpperCase(),
    }
    if(method === 'get') axiosConfig.params = data
    else axiosConfig.data = data
    axios({
      ...axiosConfig,
      headers: {
        Auth: localStorage.getItem('token')
      }
    }).then(res => {
      resolve(res)
    }).catch(err => {
      reject(err)
    })
  })
}
