<template>
  <div>
    <mainone :mainonebread="mainonebread"></mainone>
    <div>
      <!--标题-->
      <div class="box" style="">
        <div class="box_title" style="">
          <div class="box_title_font">添加分类</div>
          <div class="box_title_button">
            <!--可添加按钮-->
            <!--<el-button type="primary" class="max_search" @click="exportDatas(mainthreetableData)">导出数据</el-button>-->
          </div>
        </div>
        <!--内容-->
        <div class="box_content">
          <el-row style="margin-top: 20px">
            <!--占位符-->
            <el-col :span="24" :xs="24" :sm="24" :lg="7">
              <div style="width: 100%"></div>
            </el-col>
            <!--主表样式-->
            <el-col :span="24" :xs="24" :sm="24" :lg="9">
              <el-form
                ref="ruleForm"
                :model="ruleForm"
                :rules="rules"
                label-width="120px"
                class="demo-ruleForm"
              >
                <el-form-item label="分类名称：" prop="sort_key">
                  <el-input v-model="ruleForm.sort_key"></el-input>
                </el-form-item>
                <el-form-item label="排序：" prop="order">
                  <el-input v-model="ruleForm.order"></el-input>
                </el-form-item>
                <el-form-item label="是否显示：" prop="status">
                  <el-radio-group v-model="ruleForm.status">
                    <el-radio label="1">是</el-radio>
                    <el-radio label="-1">否</el-radio>
                  </el-radio-group>
                </el-form-item>
                <el-form-item>
                  <el-button
                    type="primary"
                    @click="submitForm('ruleForm')"
                    style="width: 40%; margin-right: 10px"
                    >确认
                  </el-button>
                  <el-button @click="resetForm()">取消</el-button>
                </el-form-item>
              </el-form>
            </el-col>
            <!--占位符-->
            <el-col :span="24" :xs="24" :sm="24" :lg="8">
              <div style="width: 100%"></div>
            </el-col>
          </el-row>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import mainone from "@/components/Mainone";
import { ref } from "vue";
import { ElMessage } from "element-plus";
import axios from "axios";

export default {
  name: "vresourcesclassifyadd",
  inject: ["reload"], //刷新引用
  data() {
    return {
      mainonebread: [
        {
          name: "打印",
        },
        {
          name: "资源管理",
        },
        {
          name: "分类列表",
          path: "/resourcesclassify",
        },
        {
          name: "添加分类",
          path: "/resourcesclassifyadd",
        },
      ], // 当前页-【面包屑导航】

      myselectcityisshow: true, //form表单-selectcity选框
      myselectcity: {
        selectwidth: "100%",
        select: {
          ismust: true,
          name: "area_code",
          valuesheng: "",
          valueshi: "",
          valuequ: "",
          disshi: true,
          disqu: true,
        },
      },
      ruleForm: {
        sort_key: "",
        order: "",
        status: ref("1"),
      }, //form表单
      rules: {
        sort_key: [
          {
            required: true,
            message: "分类名称为必填项",
            trigger: "blur",
          },
        ],
        order: [
          {
            required: true,
            message: "排序为必填项",
            trigger: "blur",
          },
        ],
        status: [
          {
            required: false,
            message: "请至少选择一个状态",
            trigger: "change",
          },
        ],
      }, //form表单-必填规则

      mypowerlimits: JSON.parse(localStorage.getItem("powerlimits")), //获取权限
      mytoken: localStorage.getItem("token"), //获取存储的token
    };
  },
  methods: {
    //提交form表单-并判断是否必填
    submitForm(formName) {
      var formdata = this.$refs[formName].model;
      console.log(formdata)
      this.$refs[formName].validate((valid) => {
        if (valid) {
          axios
            .post(
              "/manage/sort/create",
              {
                keyword: "share_world",
                sort_key: formdata.sort_key,
                order: formdata.order,
                status: formdata.status,
              },
              {
                headers: {
                  Auth: localStorage.getItem("token"),
                },
              }
            )
            .then((response) => {
              if (response.data.code === 0) {
                ElMessage({
                  type: "success",
                  message: "提交成功！",
                  duration: 1000,
                  onClose: () => {
                    // this.$router.push("/resourcesclassify").catch((error) => error);
                    // this.reload(); //刷新
                    this.$router.go(-1); // 返回上一页
                  },
                });
              }
            })
            .catch(function (error) {
              console.log(error);
            });
        } else {
          console.log("error submit!!");
          return false;
        }
      });
    },
    //重置form表单中填写的数据
    resetForm() {
      // this.$refs[formName].resetFields();
      this.$router.go(-1); // 返回上一页
    },
  },
  created() {
    if (localStorage.getItem("token") === null || !localStorage.getItem("token")) {
      this.isshow = false;
      this.$router.push("/").catch((error) => error);
    }
  },
  components: {
    mainone,
  },
};
</script>

<style scoped>
.box {
  background-color: #ffffff;
  border-radius: 4px;
  color: #333333;
  padding: 10px 20px;
}

.box_title {
  line-height: 40px;
  height: 40px;
}

.box_title_font {
  float: left;
  font-weight: 400;
  font-size: 14px;
  color: #333333;
}

.box_title_button {
  float: right;
  font-weight: 400;
  font-size: 14px;
  color: #94a3d2;
}

.box_title_button:hover {
  cursor: pointer;
}

.box_content {
  border-top: 1px solid #eeeeee;
  margin-top: 10px;
}
</style>