<template>
    <div>
        <mainone :mainonebread="mainonebread"></mainone>
        <div v-loading="loading" element-loading-text="正在飞速加载中...">
            <!--标题-->
            <div class="box">
                <!--内容-->
                <div class="box_content">
                    <el-tabs
                        v-model="activeName"
                        @tab-click="
                            (tab, event) => {
                                handleClick(tab, event)
                            }
                        "
                    >
                        <!-- 操作权限 -->
                        <el-tab-pane label="操作权限" name="one">
                            <div class="quanxian_box">
                                <div class="quanxian_box_child">
                                    <el-tree
                                        ref="menuTree"
                                        :data="data"
                                        :check-strictly="this.checkStrictly"
                                        node-key="id"
                                        show-checkbox="true"
                                        default-expand-all="expand"
                                        :props="defaultProps"
                                        @node-click="handleNodeClick"
                                    ></el-tree>
                                </div>
                                <div class="quanxian_box_button">
                                    <el-button type="primary" @click="save()" style="min-width: 300px">保存</el-button>
                                </div>
                            </div>
                        </el-tab-pane>
                    </el-tabs>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import mainone from '@/components/Mainone'
import { ElMessage } from 'element-plus'
import axios from 'axios'
import { defineComponent } from 'vue'

export default defineComponent({
    name: 'vpowerset',
    inject: ['reload'],
    data() {
        return {
            mainonebread: [
                {
                    name: '权限'
                },
                {
                    name: '权限管理'
                },
                {
                    name: '权限管理',
                    path: '/power'
                },
                {
                    name: '权限设置',
                    path: ''
                }
            ], // 当前页-【面包屑导航】

            activeName: 'one', //tabs标签-默认显示页

            // 操作权限-相关字段
            data: [],
            selectIds: [],
            showids: [],

            loading: false,
            mypowerlimits: JSON.parse(localStorage.getItem('powerlimits')), //获取权限
            mytoken: localStorage.getItem('token'), //获取存储的token

            checkStrictly: true
        }
    },
    methods: {
        // 点击时触发
        handleNodeClick() {
            this.checkStrictly = false
        },

        // 切换tabs标签页面，点击触发事件
        handleClick(tab) {
            // 操作权限
            if (tab.props.name === 'one') {
                //获取所有权限项-操作权限
                axios
                    .get('/manage/manage/getMenu', {
                        headers: {
                            Auth: localStorage.getItem('token')
                        }
                    })
                    .then((response) => {
                        if (response.data.code == 0) {
                            this.data = response.data.result
                            this.setchecked()
                            this.$refs.menuTree.setCheckedKeys(this.showids)
                        }
                    })
                    .catch(function (error) {
                        console.log(error)
                    })
            }
        },
        // ----------------【操作权限】---------------
        // 【操作权限】-保存已选择的权限
        save() {
            this.selectIds = this.$refs.menuTree.getCheckedKeys()

            if (this.$route.query.group_type && this.$route.query.group_type == 3) {
                // 判断是否为【管理员组】权限设置
                // 【权限】相关的必须有
                let display_ids = [
                    115, 116, 117, 355, 356, 357, 431, 432, 118, 358, 359, 360, 433, 525, 434, 119, 361, 362, 363, 435, 121, 120, 364, 365, 366, 436, 122, 367, 368, 369, 437, 123,
                    370, 539, 540
                ]
                let select_ids = Array.from(this.selectIds)
                let array_ids = display_ids.filter(function (val) {
                    return select_ids.indexOf(val) > -1
                })

                if (display_ids.length == array_ids.length) {
                    axios
                        .post(
                            '/manage/manage/groupSet',
                            {
                                id: this.$route.query.id,
                                ids: this.selectIds
                            },
                            {
                                headers: {
                                    Auth: localStorage.getItem('token')
                                }
                            }
                        )
                        .then((response) => {
                            if (response.data.code == 0) {
                                ElMessage({
                                    type: 'success',
                                    message: response.data.message,
                                    duration: 1000,
                                    onClose: () => {
                                        axios
                                            .post(
                                                '/manage/Manage/allAbleClick',
                                                {},
                                                {
                                                    headers: {
                                                        Auth: localStorage.getItem('token')
                                                    }
                                                }
                                            )
                                            .then((response) => {
                                                if (response.data.code == 0) {
                                                    // 重新加载权限-成功
                                                    localStorage.setItem('powerlimits', JSON.stringify(response.data.result)) //存储powerlimits权限

                                                    //登录成功之后，进入后台,通过token得到相关的models
                                                    axios
                                                        .get('/manage/Manage/menu', { headers: { Auth: localStorage.getItem('token') } })
                                                        .then((response) => {
                                                            if (response.data.code == 0) {
                                                                localStorage.setItem('mymodels', JSON.stringify(response.data.result)) //存储导航信息

                                                                this.$router.go(-1) // 返回上一页
                                                            }
                                                        })
                                                        .catch(function (error) {
                                                            console.log(error)
                                                        })
                                                }
                                            })
                                            .catch(function (error) {
                                                console.log(error)
                                            })
                                    }
                                })
                            }
                        })
                        .catch(function (error) {
                            console.log(error)
                        })
                } else {
                    ElMessage({
                        type: 'error',
                        message: '保存失败！【管理员组】的【权限】相关必须全部勾选'
                    })
                }
            } else {
                axios
                    .post(
                        '/manage/manage/groupSet',
                        {
                            id: this.$route.query.id,
                            ids: this.selectIds
                        },
                        {
                            headers: {
                                Auth: localStorage.getItem('token')
                            }
                        }
                    )
                    .then((response) => {
                        if (response.data.code == 0) {
                            ElMessage({
                                type: 'success',
                                message: response.data.message,
                                duration: 1000,
                                onClose: () => {
                                    axios
                                        .post(
                                            '/manage/Manage/allAbleClick',
                                            {},
                                            {
                                                headers: {
                                                    Auth: localStorage.getItem('token')
                                                }
                                            }
                                        )
                                        .then((response) => {
                                            if (response.data.code == 0) {
                                                // 重新加载权限-成功
                                                localStorage.setItem('powerlimits', JSON.stringify(response.data.result)) //存储powerlimits权限

                                                //登录成功之后，进入后台,通过token得到相关的models
                                                axios
                                                    .get('/manage/Manage/menu', { headers: { Auth: localStorage.getItem('token') } })
                                                    .then((response) => {
                                                        if (response.data.code == 0) {
                                                            localStorage.setItem('mymodels', JSON.stringify(response.data.result)) //存储导航信息

                                                            this.$router.go(-1) // 返回上一页
                                                        }
                                                    })
                                                    .catch(function (error) {
                                                        console.log(error)
                                                    })
                                            }
                                        })
                                        .catch(function (error) {
                                            console.log(error)
                                        })
                                }
                            })
                        }
                    })
                    .catch(function (error) {
                        console.log(error)
                    })
            }
        },
        // 【操作权限】-js递归重组数据
        setchecked() {
            this.showids = []
            for (var i = 0; i < this.data.length; i++) {
                if (this.data[i]['checked'] == true) {
                    this.showids.push(this.data[i]['id'])
                }
                if ('children' in this.data[i] && this.data[i]['children'].length > 0) {
                    this.setcheckedsub(this.data[i])
                }
            }
        },
        setcheckedsub(node) {
            for (var i = 0; i < node.children.length; i++) {
                if (node.children[i]['checked'] == true) {
                    this.showids.push(node.children[i]['id'])
                }
                if ('children' in node.children[i] && node.children[i]['children'].length > 0) {
                    this.setcheckedsub(node.children[i])
                }
            }
        }
    },
    created() {
        if (this.mytoken === null) {
            this.isshow = false
            this.$router.push('/').catch((error) => error)
        } else {
            this.checkStrictly = true
            this.loading = true
            //获取所有权限项-操作权限
            axios
                .get('/manage/manage/getMenu', {
                    headers: {
                        Auth: localStorage.getItem('token')
                    },
                    params: {
                        group_id: this.$route.query.id
                    }
                })
                .then((response) => {
                    if (response.data.code == 0) {
                        this.data = response.data.result
                        this.setchecked()
                        this.$refs.menuTree.setCheckedKeys(this.showids)
                        setTimeout(() => {
                            this.checkStrictly = false
                        }, 500)
                    }
                    this.loading = false
                })
                .catch(function (error) {
                    console.log(error)
                    this.loading = false
                })
        }
    },
    components: {
        mainone
    }
})
</script>

<style scoped>
.box {
    background-color: #ffffff;
    border-radius: 4px;
    color: #333333;
    padding: 10px 20px;
}

.box_title {
    line-height: 40px;
    height: 40px;
}

.box_title_font {
    float: left;
    font-weight: 400;
    font-size: 14px;
    color: #333333;
}

.box_title_button {
    float: right;
    font-weight: 400;
    font-size: 14px;
    color: #94a3d2;
}

.box_title_button:hover {
    cursor: pointer;
}

.box_content :deep(.el-form-item__error) {
    position: static !important;
}

.box_content :deep(.el-upload) {
    display: block !important;
}

/* 操作权限 */
.quanxian_box {
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    justify-content: space-between;
}

.quanxian_box_child {
    margin-bottom: 50px;
}

.quanxian_box_button {
    margin-bottom: 20px;
}
</style>
