<template>
  <div>
    <!-- 暂时加入-租户平台-设置-基本设置中 -->
    <mainone :mainonebread="mainonebread"></mainone>
    <div>
      <!--标题-->
      <div class="box" style="">
        <div class="box_title" style="">
          <div class="box_title_font">小程序审核线路设置</div>
          <div class="box_title_button"></div>
        </div>
        <!--内容-->
        <div class="box_content">
          <el-row style="margin-top: 20px">
            <!--占位符-->
            <el-col :span="24" :xs="24" :sm="24" :lg="7">
              <div style="width: 100%"></div>
            </el-col>
            <!--主表样式-->
            <el-col :span="24" :xs="24" :sm="24" :lg="9">
              <el-form ref="ruleForm" :model="ruleForm" :rules="rules" label-width="180px" class="demo-ruleForm">
                <!--文本审核线路-->
                <el-form-item label="文本审核线路：" prop="text">
                  <el-radio-group v-model="ruleForm.text">
                    <el-radio label="1">微信</el-radio>
                    <el-radio label="2">支付宝</el-radio>
                  </el-radio-group>
                </el-form-item>
                <!--图片审核线路-->
                <el-form-item label="图片审核线路：" prop="img">
                  <el-radio-group v-model="ruleForm.img">
                    <el-radio label="1">阿里云</el-radio>
                    <el-radio label="2">支付宝</el-radio>
                    <el-radio label="3">七牛云</el-radio>
                  </el-radio-group>
                </el-form-item>
                <!--button按钮-->
                <el-form-item style="margin-top: 50px" v-if="mypowerlimits.zhsz_tj">
                  <el-button type="primary" @click="submitForm('ruleForm')" style="width: 40%">提交</el-button>
                  <el-button @click="resetForm('ruleForm')">重置</el-button>
                </el-form-item>
              </el-form>
            </el-col>
            <!--占位符-->
            <el-col :span="24" :xs="24" :sm="24" :lg="8">
              <div style="width: 100%"></div>
            </el-col>
          </el-row>
        </div>
      </div>
    </div>
  </div>
</template>
  
<script>
import mainone from "@/components/Mainone.vue";
// import { h } from 'vue'
// import { ElNotification } from 'element-plus'
import { ElMessage } from "element-plus";
// import { ref } from "vue";
import axios from "axios";

export default {
  name: "vsetphotoexamine",
  data() {
    return {
      mainonebread: [
        {
          name: "设置",
        },
        {
          name: "审核设置",
        },
        {
          name: "小程序审核线路设置",
        },
      ], // 当前页-【面包屑导航】

      ruleForm: {
        text: "2",
        img: "2",
      }, //form表单数据
      rules: {
        text: [
          {
            required: true,
            message: "文本审核线路为必选项",
            trigger: "blur",
          },
        ],
        img: [
          {
            required: true,
            message: "图片审核线路为必选项",
            trigger: "blur",
          },
        ],
      }, //form表单填写规则-前端判断是否必填

      mypowerlimits: JSON.parse(localStorage.getItem("powerlimits")), //获取权限
      mytoken: localStorage.getItem("token"), //获取存储的token
      myuserinfo: localStorage.getItem("userinfo"), //获取存储的用户信息
    };
  },
  mounted() { },
  methods: {
    //提交form表单-并判断是否必填
    submitForm(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          axios
            .post(
              "/manage/Manage/userEditPassword1",
              {
                img: this.$refs[formName].model.img,
                text: this.$refs[formName].model.text,
              },
              {
                headers: {
                  Auth: localStorage.getItem("token"),
                },
              }
            )
            .then((response) => {
              if (response.data.code === 0) {
                ElMessage({
                  type: "success",
                  message: "提交成功！",
                });
              }
            })
            .catch(function (error) {
              console.log(error);
            });
        } else {
          console.log("error submit!!");
          return false;
        }
      });
    },
    //重置form表单中填写的数据
    resetForm(formName) {
      this.$refs[formName].resetFields();
    },
  },
  created() {
    if (localStorage.getItem("token") === null || !localStorage.getItem("token")) {
      this.isshow = false;
      this.$router.push("/").catch((error) => error);
    } else {
      //  console.log(JSON.parse(this.myuserinfo));
      
    }
  },
  components: {
    mainone,
  },
};
</script>
  
<style scoped>
.box {
  background-color: #ffffff;
  border-radius: 4px;
  color: #97a0c3;
  padding: 5px 20px;
}

.box_title {
  line-height: 40px;
  height: 40px;
}

.box_title_font {
  float: left;
  font-weight: 400;
  font-size: 14px;
  color: #333333;
}

.box_title_button {
  float: right;
  font-weight: 400;
  font-size: 14px;
  color: #94a3d2;
}

.box_title_button:hover {
  cursor: pointer;
}

.box_content {
  border-top: 1px solid #eeeeee;
}
</style>
  