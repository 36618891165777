<template>
  <div>
    <mainone :mainonebread="mainonebread"></mainone>
    <maintwo :maintwoiconshow="maintwoiconshow" :maintwoforminfo="maintwoforminfo"
      :myinputoneisshow="maintwoforminfo.myinputoneisshow" :myinputone="maintwoforminfo.myinputone"
      :myselectleftisshow="maintwoforminfo.selectleftisshow" :myselectleft="maintwoforminfo.selectleft"
      :myinputishow="myinputishow" :myinput="myinput" :myinputonefullisshow="maintwoforminfo.myinputonefullisshow"
      :myinputonefull="maintwoforminfo.myinputonefull" :myselectisshow="myselectisshow" :myselect="myselect"
      :myselectcityisshow="myselectcityisshow" :myselectcity="myselectcity" v-on:getsearchinfo="getSeachinfoEvent"
      v-on:searchForm="searchFormEvent"></maintwo>
    <!--table表单-->
    <div>
      <!--主list列表-->
      <div style="margin-top: 10px;background-color: #ffffff;border-radius: 4px;line-height: 40px;padding: 0 20px;">
        <div style="line-height: 60px">
          <!--左侧名称-->
          <div style="float: left;font-size: 14px;"> 数据列表</div>
          <!--右侧按钮-->
          <div style="float: right">
            <!--自定义按钮-点击出现弹框&点击跳转子类页面@click="routeraddrClick('/XX路由')"-->
            <!--1、点击出现弹框@click="addTanClick(XX弹框配置信息,dialogVisible = true);resetFormSenior(XXform表单)"-->
            <!--2、点击跳转子类页面@click="routeraddrClick('/XX路由')-->
            <el-button type="primary" plain @click="routeraddrClick('/recpositionadd')">
              添加导航</el-button>
          </div>
        </div>

        <div>
          <!--table表单-->
          <el-table ref="multipleTable" :row-class-name="tableRowClassName"
            :data="mainthreetableData.slice((currentPage - lastpage) * PageSize, currentPage * PageSize)"
            style="width: 100%" min-height="900" border v-loading="loading" element-loading-text="正在飞速加载中..."
            @selection-change="handleSelectionChange" @cell-click="handleOneData">
            <!--多选框-用于用户选择性导出数据-->
            <el-table-column type="selection" width="55" v-if="mainthreeisshowset" />
            <el-table-column v-for="(mytabletitle, i) in mainthreetabletitle" :key="i" :fixed="mytabletitle.fixedstatu"
              :prop="mytabletitle.prop" :label="mytabletitle.label" :width="mytabletitle.width">
              <!--这里先通过table列表中的title值进行判断，在进行渲染展示-->
              <!--例如：status状态字段，判断最终的值是那种表现形式等-->
              <template #default="scope" v-if="mytabletitle.prop === 'type' || mytabletitle.prop === 'is_show'">
                <!-- 类型-数据渲染 -->
                <div v-if="mytabletitle.prop === 'type'">
                  <div>
                    <span>{{ scope.row.type == 1 ? '分类' : '专题' }}</span>
                  </div>
                </div>
                <!-- 上架-数据渲染 -->
                <div v-if="mytabletitle.prop === 'is_show'">
                  <div id="switch">
                    <el-switch v-model="scope.row.is_show" active-text="显示" inactive-text="隐藏" :active-value='1'
                      :inactive-value='2' active-color="#13ce66"
                      @click="!mypowerlimits.tjwpz_qy  ? changeStatus(scope.row) : ''" :disabled="mypowerlimits.tjwpz_qy"
                      inactive-color="#cbcbcb">
                    </el-switch>
                  </div>
                </div>
              </template>
            </el-table-column>
            <!--操作&按钮-->
            <el-table-column fixed="right" label="操作" :width="mainthreehandle" v-if="mainthreeisshowope"
              prop="myoperation" id="operation">
              <template #default="scope">
                <!--操作：按照页面区分，且每个按钮对应相关函数-->
                <!--操作-课程列表-->
                <div>
                  <el-button type="primary" size="mini" @click="routeraddrClick('/recpositionedit', scope.row)" v-if="!mypowerlimits.tjwpz_bj">编辑
                  </el-button>
                  <el-button type="primary" size="mini" @click="routeraddrClick('/recpositionaddspecial', scope.row)"
                    v-if="scope.row.type == 2 && !mypowerlimits.tjwpz_tjzt">添加专题
                  </el-button>
                  <el-button @click="deleteRow(scope.$index, mainthreetableData, scope.row)" type="danger" size="mini"
                    icon="el-icon-delete" v-if="!mypowerlimits.tjwpz_sc"></el-button>
                </div>
              </template>
            </el-table-column>
          </el-table>
          <!--分页器-->
          <div class="block" style="text-align: center;margin: 30px 0">
            <el-pagination background @size-change="handleSizeChange" @current-change="handleCurrentChange"
              :current-page="currentPage" :page-sizes="pageSizes" :page-size="PageSize"
              layout="total, sizes, prev, pager, next, jumper" :total="totalCount">
            </el-pagination>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import mainone from '@/components/Mainone'
import maintwo from '@/components/Maintwo'
import { ElMessageBox, ElMessage } from "element-plus";
import axios from "axios";
import { defineComponent } from "vue";

export default defineComponent({
  name: "recposition",
  inject: ['reload'],
  data() {
    return {
      mainonebread: [
        {
          name: '教育',

        },
        {
          name: '文档管理',

        },
        {
          name: '推荐位管理',
          path: '/recposition'
        }
      ], // 当前页-【面包屑导航】
      maintwoiconshow: false, // 筛选-【高级筛选按钮】
      maintwoforminfo: {
        myinputoneisshow: true, //筛选查询-左侧字+input
        myinputone: {
          divinterval: '0', //框右侧距离
          titlewidth: '74px',  //标题宽度
          inputwidth: '200px',  //input宽度
          inputinterval: '20px', //input右侧距离
          input: [
            {
              ismust: false, //是否必填，true表示必填
              title: '输入搜索：',
              placeholder: '导航名称',
              name: 'title',
              content: '',
            }
          ],
        },
        selectleftisshow: true, //筛选查询-左侧字+select单框||select时间
        selectleft: {
          width: 'none',
          divinterval: '', //框右侧距离
          selectwidth: '200px',
          selectinterval: '20px', //select右侧距离
          select: [
            {
              selectisshow: true,
              ismust: false,
              title: '类型：',
              placeholder: '全部',
              name: 'type',
              value: '',
              options: [
                {
                  label: '全部',
                  value: ''
                },
                {
                  label: '分类',
                  value: '1'
                },
                {
                  label: '专题',
                  value: '2'
                },
              ],
            },
            {
              selectisshow: true,
              ismust: false,
              title: '是否显示：',
              placeholder: '全部',
              name: 'is_show',
              value: '',
              options: [
                {
                  label: '全部',
                  value: ''
                },
                {
                  label: '显示',
                  value: '1'
                },
                {
                  label: '隐藏',
                  value: '2'
                }
              ],
            },
          ]
        }
      }, // 筛选-控件信息
      mainthreeisshowset: false, // 数据列表中-复选框
      mainthreeisshowope: true, // 数据列表中-操作-根据页面显示模块（整页）
      mainthreetableope: 15, // 数据列表中-操作-根据页面显示控件（整页）
      mainthreehandle: 180, // 数据列表中-操作-宽度
      mainthreetabletitle: [
        {
          fixedstatu: 'left',
          prop: 'id',
          label: 'ID',
          width: ''
        },
        {
          fixedstatu: false,
          prop: 'title',
          label: '导航名称',
          width: ''
        },
        {
          fixedstatu: false,
          prop: 'type',
          label: '类型',
          width: ''
        },
        {
          fixedstatu: false,
          prop: 'special_num',
          label: '专题封面数',
          width: ''
        },
        {
          fixedstatu: false,
          prop: 'is_show',
          label: '是否显示',
          width: ''
        },
        {
          fixedstatu: false,
          prop: 'sort',
          label: '排序',
          width: ''
        },
      ], // 数据列表中-数据表头
      mainthreestatus: true, // 数据列表中-属于search按钮表示状态
      mainthreetableData: [],// 数据列表中-具体数据
      totalCount: 1, //数据列表中-数据总条数
      currentpage: 1, //数据列表中-数据当前页数
      lastpage: 1, //数据列表中-数据总页数（默认以20条一页）
      loading: false, //初始化-loading加载动效，默认是false关闭

      //table表单相关数据
      seniorform: {},
      currentPage: 1,
      pageSizes: [20, 50, 100],
      PageSize: 20,
      multipleSelection: [],
      thispagename: '', //通过路由获取当前页面的path值
      searchwidth: '580px',
      tancontent: {
        title: '弹框标题',
        content: {},
      },//弹框

      mypowerlimits: JSON.parse(localStorage.getItem("powerlimits")), //获取权限
      mytoken: localStorage.getItem('token'),  //获取存储的token
      pagesdatas: [], //存储最近一次的筛选条件，供分页使用
    }
  },

  mounted() {
    //获取当前页面路由path值
    this.thispagename = this.$route.path;
  },
  methods: {
    // 切换-上架/下架
    changeStatus(data) {
      console.log(data)
      axios
        .post(
          "/manage/Diy/changeStatus",
          {
            id: data.id,
          },
          {
            headers: {
              Auth: localStorage.getItem("token"),
            },
          }
        )
        .then((response) => {
          if (response.data.code === 0) {
            ElMessage({
              type: "success",
              message: response.data.message,
            });
          } else {
            // 切换状态失败-复原状态
            if (data.is_show == 1) {
              data.is_show = 2
            } else {
              data.is_show = 1
            }
          }
        })
        .catch(function (error) {
          console.log(error);
        });
    },
    //table表-操作中-删除按钮（函数）
    deleteRow(index, rows) {
      ElMessageBox.confirm('确定删除:“' + rows[index].title + '”吗？', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: "取消",
        type: "warning",
      }).then(() => {
        axios
          .post(
            "/manage/Diy/delRecommend",
            {
              id: rows[index].id,
            },
            {
              headers: {
                Auth: localStorage.getItem("token"),
              },
            }
          )
          .then((response) => {
            if (response.data.code == 0) {
              ElMessage({
                type: "success",
                message: "删除成功！",
                duration: 1000,
                onClose: () => {
                  rows.splice(index, 1);
                },
              });
            }
          })
      }).catch(() => {
        ElMessage({
          type: "info",
          message: "取消删除",
        });
      })
    },
    //table表中-当选项发生变化是触发该函数
    handleSelectionChange(val) {
      console.log('this is:' + val)
      this.multipleSelection = val
    },
    //分页器中-当选择条数时触发该函数
    handleSizeChange(val) {
      console.log(`每页 ${val} 条`)
      this.PageSize = val
      this.currentPage = 1

      //加载loading
      this.loading = true
      //通过Ajax向后台获取数据
       axios.post('/manage/Diy/indexRecommend',
        {
          title: this.pagesdatas.title,
          type: this.pagesdatas.type,
          is_show: this.pagesdatas.is_show,
          page: 1,
          page_size: val,
        },
        {
          headers: {
            Auth: localStorage.getItem("token")
          }
        })
        .then(response => {
          this.myaxiosfunc(response.data.result)
        })
        .catch(function (error) { // 请求失败处理
          console.log(error)
        })
    },
    //分页器中-当选择分页时触发该函数
    handleCurrentChange(val) {
      console.log(`当前页: 第${val}页`)
      this.currentPage = val

      //加载loading
      this.loading = true
      //通过Ajax向后台获取数据
       axios.post('/manage/Diy/indexRecommend',
        {
          title: this.pagesdatas.title,
          type: this.pagesdatas.type,
          is_show: this.pagesdatas.is_show,
          page: val,
          page_size: this.PageSize,
        },
        {
          headers: {
            Auth: localStorage.getItem("token")
          }
        })
        .then(response => {
          this.myaxiosfunc(response.data.result)
        })
        .catch(function (error) { // 请求失败处理
          console.log(error)
        })
    },
    //点击进行路由页面跳转以及携带参数
    routeraddrClick(routeraddr, rowdata) {
      if (rowdata) {
        // 跳转-编辑导航
        if (routeraddr === "/recpositionedit") {
          this.$router
            .push({
              path: routeraddr,
              query: { id: rowdata.id },
            })
            .catch((error) => error);
        }
        // 跳转-添加专题
        if (routeraddr === "/recpositionaddspecial") {
          this.$router
            .push({
              path: routeraddr,
              query: { id: rowdata.id },
            })
            .catch((error) => error);
        }
      } else {
        //仅跳转
        this.$router.push(routeraddr).catch((error) => error);
      }
    },

    //提交等相关event事件函数
    //获取Container-Pagedata-Maintwo-搜索-返回的数据
    getSeachinfoEvent(data) {
      console.log('点击查询获取的data数组：' + JSON.stringify(data))
      this.pagesdatas = data;//将筛选条件赋值给本页的pagesdatas存储，供分页使用
      //加载loading
      this.loading = true
      //通过Ajax向后台获取数据
      axios.post('/manage/Diy/indexRecommend',
        {
          title: data.title,
          type: data.type,
          is_show: data.is_show,
          page: 1,
          page_size: this.PageSize,
        },
        {
          headers: {
            Auth: localStorage.getItem("token")
          }
        })
        .then(response => {
          this.myaxiosfunc(response.data.result)
        })
        .catch(function (error) { // 请求失败处理
          console.log(error)
        })
    },
    //数据渲染
    myaxiosfunc(successdata) {
      var newdata = successdata.data
      for (var i = 0; i < newdata.length; i++) {
        //该字段表示search按钮，且status为1表示启用，2表示禁用
        newdata[i].switchstatus = 4;
      }
      // console.log('当前页面的data:' + JSON.stringify(newdata))
      this.mainthreetableData = newdata;
      this.totalCount = successdata.total
      this.currentpage = successdata.current_page; //当前页面页数
      this.lastpage = successdata.last_page;
      this.loading = false;
    }
  },
  created() {
    if (localStorage.getItem("token") === null || !localStorage.getItem("token")) {
      this.isshow = false
      this.$router.push('/').catch(error => error)
    } else {
      //加载loading
      this.loading = true

      this.pagesdatas = {
        page: 1,
        page_size: this.PageSize,
      }

      //通过Ajax向后台获取数据
      axios.post('/manage/Diy/indexRecommend',
        this.pagesdatas,
        {
          headers: {
            Auth: localStorage.getItem("token")
          }
        })
        .then(response => {
          this.myaxiosfunc(response.data.result)
        })
        .catch(function (error) {
          console.log(error)
        })


    }
  },
  components: {
    mainone,
    maintwo,
  }
});
</script>

<style scoped>
/*局部控制-Switch按钮-按钮中显示状态文字*/
/* switch按钮款式 */
#switch :deep(.el-switch__label) {
  position: absolute;
  display: none;
  color: #fff !important;
}

/*关上时文字地位设置*/
#switch :deep(.el-switch__label--right) {
  z-index: 1;
}

/* 调整关上时文字的显示位子 */
#switch :deep(.el-switch__label--right) span {
  margin-left: 3px;
  font-size: 12px;
}

/*敞开时文字地位设置*/
#switch :deep(.el-switch__label--left) {
  z-index: 1;
}

/* 调整敞开时文字的显示位子 */
#switch :deep(.el-switch__label--left) span {
  margin-left: 15px;
  font-size: 12px;
}

/*显示文字*/
#switch :deep(.el-switch__label.is-active) {
  display: block;
}

/* 调整按钮的宽度 */
#switch :deep(.el-switch) .el-switch__core,
#switch :deep(.el-switch) .el-switch__label {
  width: 55px !important;
  margin: 0;
  padding: 5px;
}

/*操作中-按钮样式*/
.el-button--mini {
  min-height: 28px;
  padding: 0 8px;
  font-size: 12px;
  border-radius: calc(var(--el-border-radius-base) - 1px);
}

.mytabletext {
  color: var(--el-color-primary);
  cursor: pointer;
}
</style>