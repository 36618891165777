<template>
    <div>
        <mainone :mainonebread="mainonebread"></mainone>
        <div>
            <!--标题-->
            <div class="box">
                <div class="box_title">
                    <div class="box_title_font">添加公司</div>
                    <div class="box_title_button">
                        <!--可添加按钮-->
                        <!--<el-button type="primary" class="max_search" @click="exportDatas(mainthreetableData)">导出数据</el-button>-->
                    </div>
                </div>
                <!--内容-->
                <div class="box_content">
                    <el-row style="margin-top: 20px">
                        <!--占位符-->
                        <el-col :span="24" :xs="24" :sm="24" :lg="7">
                            <div style="width: 100%"></div>
                        </el-col>
                        <!--主表样式-->
                        <el-col :span="24" :xs="24" :sm="24" :lg="9">
                            <el-form ref="ruleForm" :model="ruleForm" :rules="rules" label-width="170px" class="demo-ruleForm">
                                <el-form-item label="公司名称：" prop="name">
                                    <el-input v-model="ruleForm.name" clearable></el-input>
                                </el-form-item>
                                <el-form-item label="设备编号前缀：" prop="client_pre">
                                    <el-input v-model="ruleForm.client_pre" clearable placeholder="仅2个拼音字母，eg: BS" maxlength="2"> </el-input>
                                </el-form-item>
                                <el-form-item label="手机号：" prop="username">
                                    <el-input v-model="ruleForm.username" clearable placeholder="用于登录系统用户名"></el-input>
                                </el-form-item>
                                <el-form-item label="登录密码：" prop="password">
                                    <el-input v-model="ruleForm.password" type="password" autocomplete="off" clearable></el-input>
                                </el-form-item>
                                <el-form-item label="确认密码：" prop="checkPass">
                                    <el-input v-model="ruleForm.checkPass" type="password" autocomplete="off" clearable></el-input>
                                </el-form-item>
                                <el-form-item label="小程序名称：" prop="mini_name">
                                    <el-input v-model="ruleForm.mini_name" clearable :placeholder="'eg: ' + this.brand + '智印'"></el-input>
                                </el-form-item>
                                <el-form-item label="品牌名称：" prop="brand_name">
                                    <el-input v-model="ruleForm.brand_name" clearable :placeholder="'eg:' + this.brand"></el-input>
                                </el-form-item>
                                <el-form-item label="logo：" prop="logo">
                                    <mainimgesup
                                        :myimgesup="mainpagedata.myimgesup"
                                        v-model="ruleForm.logo"
                                        :myimgesupisshow="mainpagedata.myimgesupisshow"
                                        v-on:getuploadfile="(data) => getUploadFileEvent(data)"
                                    ></mainimgesup>
                                </el-form-item>
                                <el-form-item label="佣金比例最高限制：" prop="max_ratio">
                                    <el-input v-model="ruleForm.max_ratio" maxlength="4" clearable></el-input>
                                </el-form-item>
                                <el-form-item label="客服电话：" prop="customer">
                                    <el-input v-model="ruleForm.customer" clearable></el-input>
                                </el-form-item>
                                <!--选择地区-->
                                <el-form-item label="选择地区：" prop="city">
                                    <mainselect
                                        ref="citycode"
                                        v-model="ruleForm.city"
                                        :myselectcityleftisshow="myselectcityisshow"
                                        :myselectcity="myselectcity"
                                        v-on:getcitycode="getCityCodeEvent"
                                    ></mainselect>
                                </el-form-item>
                                <el-form-item label="详细地址：" prop="address">
                                    <el-input v-model="ruleForm.address" type="textarea"></el-input>
                                </el-form-item>
                                <el-form-item label="跨公司切换用户：" prop="switch">
                                    <el-radio-group v-model="ruleForm.switch">
                                        <el-radio label="1">允许</el-radio>
                                        <el-radio label="-1">不允许</el-radio>
                                    </el-radio-group>
                                </el-form-item>
                                <el-form-item label="全品文库：" prop="show_extra_library">
                                    <el-radio-group v-model="ruleForm.show_extra_library">
                                        <el-radio label="1">显示</el-radio>
                                        <el-radio label="-1">隐藏</el-radio>
                                    </el-radio-group>
                                </el-form-item>
                                <el-form-item label="全品文库价格：" prop="extra_library_price">
                                    <el-input v-model="ruleForm.extra_library_price" clearable></el-input>
                                </el-form-item>
                                <el-form-item label="状态：" prop="status">
                                    <el-radio-group v-model="ruleForm.status">
                                        <el-radio label="1">启用</el-radio>
                                        <el-radio label="-1">禁用</el-radio>
                                    </el-radio-group>
                                </el-form-item>
                                <el-form-item>
                                    <el-button type="primary" @click="submitForm('ruleForm')" style="width: 40%; margin-right: 10px">确认 </el-button>
                                    <el-button @click="resetForm('ruleForm')">取消</el-button>
                                </el-form-item>
                            </el-form>
                        </el-col>
                        <!--占位符-->
                        <el-col :span="24" :xs="24" :sm="24" :lg="8">
                            <div style="width: 100%"></div>
                        </el-col>
                    </el-row>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import mainone from '@/components/Mainone'
import mainselect from '@/components/Mainselect.vue'
import axios from 'axios'
import { ElMessage } from 'element-plus'
import { defineComponent, ref } from 'vue'
import mainimgesup from '@/components/Mainimgesup.vue'
import dist from '@/assets/js/dist.js'

export default defineComponent({
    name: 'vcompanyadd',
    data() {
        // 默认佣金比例-校验
        var checkRatio = (rule, value, callback) => {
            // console.log(rule, value);
            if (!value) {
                return callback(new Error('佣金比例最高限制不能为空'))
            }
            if (value.toString().indexOf(' ') != -1) {
                return callback(new Error('值中不能有空格'))
            }
            setTimeout(() => {
                // 当输入为浮点型的判断
                if (!Number.parseFloat(value) && Number.parseFloat(value) != 0) {
                    callback(new Error('请输入小数'))
                } else {
                    // 判断字符串是否为文字
                    if (isNaN(value)) {
                        callback(new Error('请输入数字'))
                    } else {
                        if (value > 1) {
                            callback(new Error('佣金比例最高限制值需小于等于1'))
                        } else {
                            if (value < 0) {
                                callback(new Error('佣金比例最高限制值需大于等于0'))
                            } else {
                                callback()
                            }
                        }
                    }
                }
            }, 500)
        }
        // 手机号-校验
        var checkPhone = (rule, value, callback) => {
            if (!value) {
                return callback(new Error('手机号不能为空'))
            }
            if (value.toString().indexOf(' ') != -1) {
                return callback(new Error('值中不能有空格'))
            }
            setTimeout(() => {
                // 判断字符串是否存在小数点
                let result = value.toString().indexOf('.')
                if (result != -1) {
                    callback(new Error('请输入正确的手机号'))
                } else {
                    // 判断字符串是否为文字
                    if (isNaN(value)) {
                        callback(new Error('请输入正确的手机号'))
                    } else {
                        // 判断字符串是否为数字
                        if (!Number.parseInt(value) && Number.parseInt(value) != 0) {
                            callback(new Error('请输入正确的手机号'))
                        } else {
                            if (value < 0) {
                                callback(new Error('请输入正确的手机号'))
                            } else {
                                callback()
                            }
                        }
                    }
                }
            }, 500)
        }
        return {
            brand: dist.public_words.brand,
            mainonebread: [
                {
                    name: '租户管理'
                },
                {
                    name: '租户管理'
                },
                {
                    name: '公司列表',
                    path: '/companylist'
                },
                {
                    name: '添加公司'
                }
            ], // 当前页-【面包屑导航】

            myselectcityisshow: true, //form表单-selectcity选框
            myselectcity: {
                selectwidth: '100%',
                select: {
                    ismust: true,
                    name: 'area_code',
                    valuesheng: '',
                    valueshi: '',
                    valuequ: '',
                    disshi: true,
                    disqu: true
                }
            },
            ruleForm: {
                id: '',
                name: '',
                client_pre: '',
                username: '',
                password: '',
                checkPass: '',
                mini_name: '',
                brand_name: '',
                logo: '',
                max_ratio: '',
                customer: '',
                city: '',
                address: '',
                switch: ref('-1'),
                show_extra_library: ref('-1'),
                extra_library_price: '',
                status: ref('1')
            }, //form表单
            rules: {
                name: [
                    {
                        required: true,
                        message: '公司名称为必填项',
                        trigger: 'blur'
                    }
                ],
                client_pre: [
                    {
                        required: true,
                        message: '设备编号前缀为必填项',
                        trigger: 'blur'
                    },
                    {
                        required: true,
                        message: '设备编号前缀为必填项',
                        trigger: 'blur'
                    }
                ],
                username: [
                    {
                        required: true,
                        validator: checkPhone,
                        trigger: 'change'
                    }
                ],
                password: [
                    {
                        required: true,
                        validator: this.validatePass,
                        trigger: 'blur'
                    }
                ],
                checkPass: [
                    {
                        required: true,
                        validator: this.validatePass2,
                        trigger: 'blur'
                    }
                ],
                mini_name: [
                    {
                        required: true,
                        message: '小程序名称为必填项',
                        trigger: 'blur'
                    }
                ],
                brand_name: [
                    {
                        required: true,
                        message: '品牌名称为必填项',
                        trigger: 'blur'
                    }
                ],
                logo: [
                    {
                        required: true,
                        message: '公司logo为必填项',
                        trigger: ['blur', 'change']
                    }
                ],
                max_ratio: [
                    {
                        required: true,
                        message: '佣金比例最高限制为必填项',
                        trigger: ['blur', 'change']
                    },
                    {
                        validator: checkRatio,
                        trigger: 'change'
                    }
                ],
                customer: [
                    {
                        required: true,
                        message: '客服电话为必填项',
                        trigger: 'change'
                    }
                ],
                city: [
                    {
                        required: true,
                        message: '地区为必填项',
                        trigger: 'blur'
                    }
                ],
                address: [
                    {
                        required: true,
                        message: '详细地址为必填项',
                        trigger: 'blur'
                    }
                ],
                switch: [
                    {
                        required: true,
                        message: '跨公司切换用户为必选项',
                        trigger: 'change'
                    }
                ],
                show_extra_library: [
                    {
                        required: true,
                        message: '全品文库为必选项',
                        trigger: 'change'
                    }
                ],
                extra_library_price: [
                    {
                        required: true,
                        message: '全品文库价格为必填项',
                        trigger: 'change'
                    },
                    {
                        // 正则表达式(大于等于0的正数或保留两位小数的小数)：/^(([1-9]{1}\d*)|(0{1}))(\.\d{1,2})?$/
                        pattern: /^(([1-9]{1}\d*)|(0{1}))(\.\d{1,2})?$/,
                        message: '大于等于0的正数或保留两位小数的小数',
                        trigger: ['blur', 'change']
                    }
                ],
                status: [
                    {
                        required: true,
                        message: '请至少选择一个状态',
                        trigger: 'change'
                    }
                ]
            }, //form表单-必填规则
            mainpagedata: {
                myimgesupisshow: true, //form表单-imgesuploade上传图片
                myimgesup: {
                    uploadsize: 1, //选择上传图片的展现框-1表示小框，2表示中等框，3表示大框
                    listtype: 'picture', //list展示类型，text/picture/picture-card；默认不填为text
                    ismust: true, //是否必填，true表示必填
                    action: '/api/manage/upload/file', //upload上传API地址
                    headers: {
                        Auth: localStorage.getItem('token')
                    }, //upload上传headers
                    title: '上传图片', //button-按钮文字
                    tips: '', //提示&建议文字
                    limit: '1', //上传数量限制
                    target_id: 100, //上传文件target_id ：图片分类，区分存储文件和存储时长，不传默认存储1天后过期；1-二维码； 2-打印文件； 3-文库文件； 4-课程文件； 5-广告主宣传页； 6-广告主产生的pdf文件； 7-证件照文件； 50-设备广告； 100-通用；
                    rules: {
                        images: {
                            image_width: 0, //图片宽度
                            image_height: 0, //图片高度
                            size: 2000, //文件大小（单位kb）
                            type: ['image/jpeg', 'image/jpg', 'image/png'], //文件格式
                            typechart: 'jpg/png' //文件格式提示规则
                        }
                    }
                }
            },

            mypowerlimits: JSON.parse(localStorage.getItem('powerlimits')), //获取权限
            mytoken: localStorage.getItem('token') //获取存储的token
        }
    },
    methods: {
        getUploadFileEvent(data) {
            console.log(data)
            this.ruleForm.logo = data.url
            console.log(this.ruleForm.logo)
        },
        routeraddrClick(routeraddr) {
            //点击通过路由跳转
            this.$router.push(routeraddr).catch((error) => error)
        },
        //获取公共组件回传的值-citycode
        getCityCodeEvent(data) {
            console.log('当前获取的citycode值为：' + data)
            this.ruleForm.city = data
        },
        //提交第一个密码
        validatePass(rule, value, callback) {
            if (value === '') {
                callback(new Error('请输入登录密码'))
            } else {
                if (this.ruleForm.checkPass !== '') {
                    this.$refs.ruleForm.validateField('checkPass')
                }
                callback()
            }
        },
        //校验-第二个密码
        validatePass2(rule, value, callback) {
            if (value === '') {
                callback(new Error('请输入确认密码'))
            } else if (value !== this.ruleForm.password) {
                callback(new Error('2次输入的密码不同！请重新输入'))
            } else {
                callback()
            }
        },
        //from表单-提交表单
        submitForm(formName) {
            var getdata = this.$refs[formName].model
            // console.log(getdata)
            this.$refs[formName].validate((valid) => {
                if (valid) {
                    axios
                        .post(
                            '/manage/Manage/comInsert',
                            {
                                name: getdata.name,
                                client_pre: getdata.client_pre,
                                username: getdata.username,
                                password: getdata.password,
                                mini_name: getdata.mini_name,
                                brand_name: getdata.brand_name,
                                logo: getdata.logo,
                                area_code: getdata.city,
                                address: getdata.address,
                                status: getdata.status,
                                customer: getdata.customer,
                                max_ratio: getdata.max_ratio,
                                switch: getdata.switch,
                                show_extra_library: getdata.show_extra_library,
                                extra_library_price: getdata.extra_library_price * 100
                            },
                            {
                                headers: {
                                    Auth: localStorage.getItem('token')
                                }
                            }
                        )
                        .then((response) => {
                            if (response.data.code === 0) {
                                ElMessage({
                                    type: 'success',
                                    message: '提交成功！'
                                })
                                this.$router.back()
                            }
                        })
                        .catch(function (error) {
                            console.log(error)
                        })
                } else {
                    console.log('error submit!!')
                    return false
                }
            })
        },
        //from表单-重置表单-(返回上一页)
        resetForm() {
            // 返回上一页
            this.$router.go(-1)

            // this.$refs[formName].resetFields();
            // // 清空citycode
            // this.$refs.citycode.resetCitycode();
        }
    },
    created() {
        if (localStorage.getItem('token') === null || !localStorage.getItem('token')) {
            this.isshow = false
            this.$router.push('/').catch((error) => error)
        }
    },
    components: {
        mainone,
        mainselect,
        mainimgesup
    }
})
</script>
<style scoped>
.box {
    background-color: #ffffff;
    border-radius: 4px;
    color: #333333;
    padding: 10px 20px;
}

.box_title {
    line-height: 40px;
    height: 40px;
}

.box_title_font {
    float: left;
    font-weight: 400;
    font-size: 14px;
    color: #333333;
}

.box_title_button {
    float: right;
    font-weight: 400;
    font-size: 14px;
    color: #94a3d2;
}

.box_title_button:hover {
    cursor: pointer;
}

.box_content {
    border-top: 1px solid #eeeeee;
    margin-top: 10px;
}
</style>
