<template>
    <div>
        <el-form ref="searchformRef" :model="searchForm" class="search-form" label-suffix=":">
            <el-form-item :label="item.label" :prop="item.prop" v-for="(item, index) in select" :key="index">
                <component v-model="searchForm[item.prop]" :is="item.component" :placeholder="item.placeholder" v-bind="item.props"></component>
            </el-form-item>
            <el-form-item>
                <div style="text-align: right">
                    <el-button @click="resetSearchForm(1)">取消</el-button>
                    <el-button type="primary" @click="searchEvent('searchformRef', searchForm)">确定</el-button>
                </div>
            </el-form-item>
        </el-form>
    </div>
</template>

<script>
import http from '@/assets/js/http'
export default {
    name: 'xwNotes',
    props: {
        search_form: {
            type: Object,
            default() {
                return {}
            }
        },
        select: {
            type: Array,
            default() {
                return []
            }
        },
        axios_type: {
            type: String,
            default: 'post'
        },
        axios_url: {
            type: String,
            default: ''
        }
    },
    watch: {
        search_form: {
            handler(newVal) {
                this.searchForm = newVal
            },
            deep: true,
            immediate: true
        },
        axios_type: {
            handler(newVal) {
                this.axiosType = newVal
            },
            deep: true,
            immediate: true
        },
        axios_url: {
            handler(newVal) {
                this.axiosUrl = newVal
            },
            deep: true,
            immediate: true
        }
    },
    data() {
        return {
            searchForm: {},
            axiosType: '',
            axiosUrl: ''
        }
    },
    methods: {
        searchEvent(formName, searchForm) {
            this.$refs[formName].validate(async (valid) => {
                if (valid) {
                    let post_datas = searchForm
                    if (this.axiosType == 'get') post_datas = { params: post_datas }
                    await http[this.axiosType](this.axiosUrl, post_datas)
                    this.$emit('submit', true)
                } else {
                    return false
                }
            })
        },
        resetSearchForm(flag) {
            this.$props.select.map((n) => {
                this.searchForm[n.prop] = ''
            })
            if(flag) this.$emit('reset', true)
        }
    }
}
</script>
