<template>
  <div class="agent-level">
    <template v-for="(opts, o) in agentOpt" :key="o">
      <el-select
        :style="computedStyle"
        v-model="selected[o]"
        placeholder="请选择"
        clearable
        filterable
        @change="selectedChange($event, o)"
        @clear="clearSelected(o)"
      >
        <template v-for="(opt, i) in opts" :key="i">
          <el-option :value="opt.id" :label="opt.name" />
        </template>
      </el-select>
    </template>
  </div>
</template>

<script setup>
import {ref, computed} from "vue"
import {useAxios} from '../useAxios'

const props = defineProps(['modelValue'])
const emits = defineEmits(['update:modelValue'])

const agentOpt = ref([[]])
const companyId = ref('')
const selected = computed({
  get(){
    const arr = []
    props.modelValue.forEach((id, i) => {
      arr.push(+id)
    })
    return arr
  },
  set(val){
    emits('update:modelValue', val)
  }
})

const getAgentOpt = (companyid, pid = '', index = 0) => {
  if(companyid) {
    companyId.value = companyid
    props.modelValue.forEach((id, i) => {
      if(i < 2 && !agentOpt.value[i+1]) {
        getAgentOpt(undefined, props.modelValue[i], i+1)
      }
    })
  }
  useAxios(`manage/Equipment/getAgentSelect`, 'get', {
    companyid: companyId.value,
    pid
  }).then(res => {
    if(res.data.result && res.data.result.length)
      agentOpt.value[index] = res.data.result
  })
}

const selectedChange = (e, index) => {
  if(e && index < 2){
    getAgentOpt(null, e, index+1)
    selected.value.splice(index+1)
    agentOpt.value.splice(index+1)
  }
  emits('update:modelValue', selected.value)
}
const clearSelected = (index) => {
  const arr = selected.value
  arr.splice(index, 1)
  emits('update:modelValue', arr)
}

const computedStyle = computed(() => {
  const length = agentOpt.value.length
  return {
    width: length === 1 ? '100%' : `calc(${(1/length)*100}% - 10px)`
  }
})

defineExpose({
  getAgentOpt
})
</script>

<style lang="scss" scoped>
.agent-level{
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
}
</style>
