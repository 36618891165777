<template>
    <div class="page-container">
        <div class="container-filter">
            <el-form ref="filterRef" :model="params" inline>
                <div>
                    <el-form-item prop="orderNo" label="订单编号：">
                        <el-input v-model="params.orderNo" placeholder="请输入订单编号" clearable />
                    </el-form-item>
                    <el-form-item prop="user_type" label="签约用户：">
						<el-select v-model="params.user_type" placeholder="请选择" clearable>
							<el-option
								v-for="item in userTypeOpt"
								:key="item.id"
								:label="item.label"
								:value="item.id"
							/>
						</el-select>
                    </el-form-item>
					<el-form-item prop="realname" label="客户名：">
                        <el-input v-model="params.realname" placeholder="请输入客户名" clearable />
                    </el-form-item>
                    <el-form-item prop="alipay" label="支付宝账号：">
                        <el-input v-model="params.alipay" placeholder="请输入支付宝账号" clearable />
                    </el-form-item>
                    <el-form-item prop="shopid" label="归属代理商：">
                        <el-input v-model="params.shopid" placeholder="请输入归属代理商" clearable />
                    </el-form-item>
					<el-form-item prop="repayStatus" label="还款状态：">
						<el-select v-model="params.repayStatus" placeholder="请选择" clearable>
							<el-option
								v-for="item in repayStatusOpt"
								:key="item.id"
								:label="item.label"
								:value="item.id"
							/>
						</el-select>
                    </el-form-item>
                    <el-form-item prop="refundStatus" label="退款状态：">
						<el-select v-model="params.refundStatus" placeholder="请选择" clearable>
							<el-option
								v-for="item in refundStatusOpt"
								:key="item.id"
								:label="item.label"
								:value="item.id"
							/>
						</el-select>
                    </el-form-item>
					<el-form-item prop="settleStatus" label="结算状态：">
						<el-select v-model="params.settleStatus" placeholder="请选择" clearable>
							<el-option
								v-for="item in settleStatusOpt"
								:key="item.id"
								:label="item.label"
								:value="item.id"
							/>
						</el-select>
                    </el-form-item>
                    <el-form-item prop="com_status" label="平台状态：">
						<el-select v-model="params.com_status" placeholder="请选择" clearable>
							<el-option
								v-for="item in platformStatusOpt"
								:key="item.id"
								:label="item.label"
								:value="item.id"
							/>
						</el-select>
                    </el-form-item>
                </div>
                <div class="filter-btn">
                    <el-button @click="resetFilter">重置</el-button>
                    <el-button type="primary" @click="getTableData()" :loading="loading">查询</el-button>
                </div>
            </el-form>
        </div>
        <div class="container-table">
            <div class="table-header">
                <div>数据列表</div>
                <div>
                    <el-button type="primary" @click="downTemp" :disabled="downTempBtn">下载模板</el-button>
                    <el-button type="primary" @click="uploadTemp" v-if="mypowerlimits.wzj_order_s_pldr">批量导入模板</el-button>
                    <el-button type="primary" @click="exportRecord">导出</el-button>
                </div>
            </div>
            <el-table :border="true" :data="table.data" v-loading="loading" >
                <el-table-column prop="" label="序号" fixed="left">
                    <template #default="{ $index }">
                        <span>{{ $index + 1 }}</span>
                    </template>
                </el-table-column>
                <el-table-column prop="orderNo" label="订单编号" min-width="200" />
                <el-table-column prop="orderDetailNo" label="订单分期号" min-width="200" />
                <el-table-column prop="user_type" label="签约用户" >
                    <template #default="{ row }">
                        <span>{{ findLabel(userTypeOpt, row.user_type) }}</span>
                    </template>
                </el-table-column>
                <el-table-column prop="realname" label="客户名" />
                <el-table-column prop="userCardid" label="客户身份证号码" min-width="200" />
                <!-- <el-table-column prop="" label="产品类型" /> -->
                <el-table-column prop="settleMerchantName" label="当前还款结算商户" min-width="150" />
                <el-table-column prop="stage" label="期款" ></el-table-column>
                <!-- <el-table-column prop="stage" label="租期" >
                </el-table-column> -->
                <el-table-column prop="planRepayTime" label="预计扣款日" min-width="120" >
					<template #default="{ row }">
                        <div>
                            <span v-if="row.planRepayTime == '0'"></span>
                            <span v-else>{{ row.planRepayTime ? $utils.formatDate(row.planRepayTime).split(' ')[0] : '' }}</span>
                        </div>
                    </template>
                </el-table-column>
                <el-table-column prop="realRepayTime" label="实际支付日" min-width="120" >
                    <template #default="{ row }">
                        <div>
                            <span v-if="row.realRepayTime == '0'"></span>
                            <span v-else>{{ row.realRepayTime ? $utils.formatDate(row.realRepayTime).split(' ')[0] : '' }}</span>
                        </div>
                    </template>
                </el-table-column>
                <el-table-column prop="amount" label="当前分期金额" min-width="120" >
					<template #default="{ row }">
                        <span>{{ formatPrice(row.amount) }}</span>
                    </template>
				</el-table-column>
                <el-table-column prop="realRepayAmount" label="实际还款金额" min-width="120" >
					<template #default="{ row }">
                        <span>{{ formatPrice(row.realRepayAmount) }}</span>
                    </template>
                </el-table-column>
                <el-table-column prop="settleAmount" label="结算金额" >
					<template #default="{ row }">
                        <span>{{ formatPrice(row.settleAmount) }}</span>
                    </template>
                </el-table-column>
                <el-table-column prop="settleTime" label="结算时间" min-width="120" >
					<template #default="{ row }">
                        <div>
                            <span v-if="row.settleTime == '0'"></span>
                            <span v-else>{{ row.settleTime ? $utils.formatDate(row.settleTime).split(' ')[0] : '' }}</span>
                        </div>
                    </template>
				</el-table-column>
                <el-table-column prop="deductionTimes" label="扣款次数" min-width="120" />
                <el-table-column prop="vouchAmount" label="担保账户扣除金额"  min-width="160"/>
                <el-table-column prop="vouchMerchantName" label="担保商户名" min-width="120" />
                <el-table-column prop="relate_client" label="关联设备" min-width="180" >
					<template #default="{ row }">
                        <span class="skip" @click="tanEvent(row,1)">{{ row.relate_client }}</span>
                    </template>
				</el-table-column>
                <el-table-column prop="relate_client_money" label="上月平均流水" min-width="210" >
					<template #default="{ row }">
                        <div>
                            <div>金额：{{ formatPrice(row.relate_client_money) }}</div>
                            <div>{{ row.relate_client_money_time }}</div>
                        </div>
                        <!-- <span class="skip" @click="tanEvent(row,1)">{{ row.relate_client_money }}</span> -->
                    </template>
				</el-table-column>
                <el-table-column prop="companyid_name" label="归属公司" min-width="180" />
                <el-table-column prop="shopid_name" label="归属代理商" min-width="120" />
                <el-table-column prop="area_code_name" label="代理商地区" min-width="180" />
                <el-table-column prop="policy" label="合作政策" min-width="180" />
                <el-table-column prop="alipay" label="支付宝账号" min-width="180" />
                <!-- <el-table-column prop="" label="支付方式" /> -->
                <el-table-column prop="repayStatus" label="还款状态" >
					<template #default="{ row }">
                        <span :style="{ color: statusColor[String(row.repayStatus)] }">{{ findLabel(repayStatusOpt, row.repayStatus) }}</span>
                    </template>
				</el-table-column>
                <el-table-column prop="refundStatus" label="退款状态" >
					<template #default="{ row }">
                        <span :style="{ color: statusColor[String(row.refundStatus)] }">{{ findLabel(refundStatusOpt, row.refundStatus) }}</span>
                    </template>
                </el-table-column>
                <el-table-column prop="settleStatus" label="结算状态" >
                    <template #default="{ row }">
                        <span :style="{ color: statusColor[String(row.settleStatus)] }">{{ findLabel(settleStatusOpt, row.settleStatus) }}</span>
                    </template>
                </el-table-column>
                <el-table-column prop="com_status" label="平台状态" >
                    <template #default="{ row }">
                        <span :style="{ color: statusColor[String(row.com_status)] }">{{ findLabel(platformStatusOpt, row.com_status) }}</span>
                    </template>
                </el-table-column>
                <el-table-column prop="com_remark" label="备注" min-width="300" >
                    <template #default="{ row }">
                        <div>
                            <el-tooltip
                                effect="light"
                                placement="top-start"
                            >
                                <template #content>
                                    <div style="max-width: 600px;">{{ row.com_remark ? row.com_remark : '' }}</div>
                                </template>
                                <span>{{ formatStrLen(row.com_remark, 35) }}</span>
                            </el-tooltip>
                        </div>
                    </template>
                </el-table-column>
                <el-table-column label="操作" width="80" fixed="right">
                    <template #default="{ row }">
						<el-button v-if="mypowerlimits.wzj_order_s_bz" type="primary" size="small" @click="tanEvent(row,2)">备注</el-button>
                    </template>
                </el-table-column>
            </el-table>
            <div class="tabler-pagination">
                <el-pagination
                    @size-change="getTableData()"
                    @current-change="getTableData"
                    :current-page="params.page"
                    :page-sizes="[20, 50, 100]"
                    v-model:page-size="params.page_size"
                    :total="table.total"
                    layout="total, sizes, prev, pager, next, jumper"
                />
            </div>
        </div>

        <!-- 弹窗-关联设备||备注 -->
        <xwDialog :is_show="isshow" :title="isflag == 1 ? '关联设备' : '备注'" @close="(data)=>closeEvent(data,isflag)" :is_footer="isflag == 1 ? true : false">
            <template #content>
                <!-- 关联设备 -->
                <div v-if="isflag == 1">
                    <xwTabler
                        ref="xwTablerRef"
                        :search_form="cilent_search_form"
                        :select="client_select"
                        :table_list="client_table_list"
                        axios_type="post"
                        axios_url="/RiskControlAdvance/wozujiStageclientMoney"
                    >
                        <template #tips>
                            <div style="margin-bottom: 10px"><span>上月流水-月底的流水统计</span></div>
                        </template>
                    </xwTabler>
                </div>
                <!-- 备注 -->
                <div v-else-if="isflag == 2">
                    <xwNotes ref="xwNotesRef" :search_form="Notes_form" :select="Notes_select" axios_type="post" axios_url="RiskControlAdvance/wozujiStageRemark" @reset="isshow = false" @submit="submitEvent"/>
                </div>
            </template>
        </xwDialog>
    </div>
</template>

<script setup>
import { ref, reactive } from 'vue'
import { ElMessageBox, ElNotification, ElMessage, inputEmits } from 'element-plus'
import http from '@/assets/js/http'
import { useRoute } from 'vue-router'
import xwDialog from '../../acommon/xwDialog'
import xwTabler from '../../acommon/xwTabler'
import xwNotes from '../../acommon/xwNotes'

// 弹窗-显示&隐藏-字段
const isshow = ref(false)
// 弹窗-判别 1关联设备 2备注
const isflag = ref('')
// 弹窗-账单列表-设备编号-关联设备
const cilent_search_form = ref({ id:'', slientId: [], client: '' })
const client_select = ref([
    {
        label: '设备编号',
        prop: 'client',
        placeholder: '请输入设备编号',
        component: 'el-input'
    }
 ])
 const  client_table_list = ref([
    {
        label: 'ID',
        prop: 'id',
        width: 100
    },
    {
        label: '设备编号',
        prop: 'client',
        minWidth: 200
    },
    {
        label: '设备地址',
        prop: 'area_code_name',
        minWidth: 200
    },
    {
        label: '上月流水(元)',
        prop: 'total',
        minWidth: 200
    }
])
// 弹窗-账单列表-备注
const Notes_form = ref({ id: '', com_remark: '' })
const Notes_select = ref([
    {
        label: '备注',
        prop: 'com_remark',
        placeholder: '请输入备注',
        component: 'el-input',
        props: { type: 'textarea', 'show-word-limit': true, maxlength: 200, autosize: { minRows: 6 } }
    }
])

// 弹窗-显示
const xwNotesRef = ref(null)
const xwTablerRef = ref(null)
const tanEvent = (row, flag) => {
    isflag.value = flag
    isshow.value = true
    if(flag == 1){
        // 关联账单id
        cilent_search_form.value.id = row.id
        // 关联设备
        cilent_search_form.value.slientId = row.relate_clientid
        if(xwTablerRef.value != null) xwTablerRef.value.searchEvent(cilent_search_form.value)
    }else{
        // 备注
        Notes_form.value.id = row.id
    }
}
// 弹窗-关闭
const closeEvent = (data,flag) =>{
    isshow.value = false
    if(flag == 1) {
        xwTablerRef.value.resetSearchForm()
    }
    if(flag == 2) xwNotesRef.value.resetSearchForm()
}
// 弹窗-备注-确认后
const submitEvent = () =>{
    closeEvent(null, 2)
    getTableData()
}



// 获取权限
const mypowerlimits = reactive(JSON.parse(localStorage.getItem('powerlimits')))

const userTypeOpt = reactive([ {id: '', label: '全部' }, { id: '1', label: '代理商' }, { id: '2', label: '商户' }, { id: '-1', label: '未分配' } ])

const repayStatusOpt = reactive([ { id: '', label: '全部' }, { id: '1', label: '已还款'}, { id: '0', label: '未还款' } ])

const refundStatusOpt = reactive([ { id: '', label: '全部' }, { id: '1', label: '已退款'}, { id: '0', label: '未退款' } ])

const settleStatusOpt = reactive([ { id: '', label: '全部' }, { id: '1', label: '已结算'}, { id: '0', label: '未结算' } ])

const platformStatusOpt = reactive([ { id: '', label: '全部' }, { id: '1', label: '已完成'}, { id: '-1', label: '待处理' }, { id: '2', label: '暂停' } ])

const statusColor = reactive({
    '-1': '#000000',
    '0': '#000000',
    '1': '#32CD32',
    '2': '#FF0000',
})

// 过滤
const route = useRoute()
const filterRef = ref()
const params = reactive({
    orderNo: route.query.orderNo || '',
    page: 1,
    page_size: 20
})

// 表格
const loading = ref(false)
const table = reactive({
    data: [],
    columns: [],
    total: 0
})

const getTableData = async (page = 1) => {
    params.page = page
    loading.value = true
    try {
        const res = await http.get('RiskControlAdvance/wozujiStageList', { params })
		Object.assign(table, res)
    } catch (e) {
        console.log(e)
    }
    loading.value = false
}

const formatPrice = (data) => {
	return data ? ((+data) / 100).toFixed(2) : data 
}

const formatStrLen = (data, len = 20) => {
    if(!data) return ''
    return data.length > len ? `${data.slice(0, len)}...` : data
}

const findLabel = (list, data) => {
	return list.find((item) => item.id === data)?.label
}

// 操作
// 重置查询条件
const resetFilter = () => {
    filterRef.value?.resetFields()
    getTableData()
}

// 导出
const exportRecord = async () => {
    await http.get('RiskControlAdvance/wozujiStageList', { params: { ...params, export: 1 }, sMsg: true })
}

const downTempBtn = ref(false)

const downTemp = async () => {
    downTempBtn.value = true
    const { download } = await http.get('RiskControlAdvance/downTemplate', { params: { templateName: 'wozuji_com_status' } })
    const link = document.createElement('a')
    link.href = download
    link.click()
    downTempBtn.value = false
}

const uploadTemp = async () => {
    const input = document.createElement('input')
    input.type = 'file'
    input.accept = '.xlsx,.xls'
    input.click()
    input.onchange = async (e) => {
        const file = e.target.files[0]
        if(!file) return
        if(file.size > 1024 * 1024 * 10) {
            ElMessage({ type: 'warning', message: '文件大小不能超过10M', duration: 3000 })
            return
        }
        const formData = new FormData()
        formData.append('file', file)
        const { total, succ, fail } = await http.post('RiskControlAdvance/uploadTemplate', formData, { headers: { 'Content-type': 'multipart/form-data' } })
        let message = `共${total || 0}条记录`
        if (succ > 0) message += `<br />导入成功${succ}条`
        if (fail > 0) message += `<br />导入失败${fail}条`
        ElMessageBox.alert(message, '提示', { type: 'info', dangerouslyUseHTMLString: true })

        getTableData()
    }
}

getTableData()
</script>

<style lang="scss" scoped>
.page-container {
    background-color: #e9eef3;
    .container-filter {
        padding: 20px 24px 0 24px;
        background-color: #fff;
        .el-form {
            display: flex;
            justify-content: space-between;
        }
        .el-form-item {
            margin-right: 20px;
            margin-bottom: 20px;
        }

		.filter-btn {
            min-width: 180px;
        }
    }

    .container-table {
        margin-top: 4px;
        padding: 24px;
        background-color: #fff;
        .table-header {
            display: flex;
            justify-content: space-between;
            margin-bottom: 12px;
        }

        .skip {
            cursor: pointer;
            color: var(--el-color-primary);
        }
        .tabler-pagination {
            display: flex;
            justify-content: space-between;
            margin-top: 12px;
        }
    }
}

.container-drawer {
    .filter-tree {
        margin-top: 24px;
    }
    .drawer-footer {
        margin-top: 24px;
        display: flex;
        justify-content: flex-end;
    }
}
</style>
