<template>
    <div>
        <mainone :mainonebread="mainonebread"></mainone>
        <!-- <maintwo
            :maintwoiconshow="maintwoiconshow"
            :maintwoforminfo="maintwoforminfo"
            :myinputoneisshow="maintwoforminfo.myinputoneisshow"
            :myinputone="maintwoforminfo.myinputone"
            :myselectleftisshow="maintwoforminfo.selectleftisshow"
            :myselectleft="maintwoforminfo.selectleft"
            :myinputishow="myinputishow"
            :myinput="myinput"
            :myinputonefullisshow="maintwoforminfo.myinputonefullisshow"
            :myinputonefull="maintwoforminfo.myinputonefull"
            :myselectisshow="myselectisshow"
            :myselect="myselect"
            :myselectcityisshow="myselectcityisshow"
            :myselectcity="myselectcity"
            v-on:getsearchinfo="getSeachinfoEvent"
            v-on:searchForm="searchFormEvent"
        ></maintwo> -->
        <!--table表单-->
        <div>
            <!--主list列表-->
            <div class="box-list">
                <div style="line-height: 60px">
                    <!--左侧名称-->
                    <div style="float: left; font-size: 14px">数据列表</div>
                    <!--右侧按钮-->
                    <div style="float: right">
                        <!--自定义按钮-点击出现弹框&点击跳转子类页面@click="routeraddrClick('/XX路由')"-->
                        <!--1、点击出现弹框@click="addTanClick(XX弹框配置信息,dialogVisible = true);resetFormSenior(XXform表单)"-->
                        <!--2、点击跳转子类页面@click="routeraddrClick('/XX路由')-->
                        <!--<el-button ></el-button>-->
                        <!--导出按钮-点击导出数据-->
                        <el-button type="warning" plain @click="exportDatas()">导出</el-button>
                    </div>
                </div>

                <div>
                    <!--table表单-->
                    <el-table
                        :header-cell-style="{ background: '#F5F7FA' }"
                        ref="multipleTable"
                        :data="mainthreetableData.slice((currentPage - lastpage) * PageSize, currentPage * PageSize)"
                        style="width: 100%"
                        min-height="900"
                        border
                        v-loading="loading"
                        element-loading-text="正在飞速加载中..."
                        @selection-change="handleSelectionChange"
                    >
                        <!--多选框-用于用户选择性导出数据-->
                        <el-table-column type="selection" width="55" v-if="mainthreeisshowset" />
                        <el-table-column
                            v-for="(mytabletitle, i) in mainthreetabletitle"
                            :key="i"
                            :fixed="mytabletitle.fixedstatu"
                            :prop="mytabletitle.prop"
                            :label="mytabletitle.label"
                            :width="mytabletitle.width"
                            :min-width="mytabletitle.minWidth"
                        >
                        </el-table-column>
                    </el-table>
                    <!--分页器-->
                    <div class="block" style="text-align: center; margin: 30px 0">
                        <el-pagination
                            background
                            @size-change="handleSizeChange"
                            @current-change="handleCurrentChange"
                            :current-page="currentPage"
                            :page-sizes="pageSizes"
                            :page-size="PageSize"
                            layout="total, sizes, prev, pager, next, jumper"
                            :total="totalCount"
                        >
                        </el-pagination>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import mainone from '@/components/Mainone'
// import maintwo from '@/components/Maintwo'
import axios from 'axios'
import {defineComponent} from 'vue'

export default defineComponent({
    name: 'vreservenums',
    inject: ['reload'], //刷新引用
    data() {
        return {
            mainonebread: [
                {
                    name: '仓库'
                },
                {
                    name: '出入库管理'
                },
                {
                    name: '现存量'
                }
            ], // 当前页-【面包屑导航】
            // maintwoiconshow: false, // 筛选-【高级筛选按钮】
            // maintwoforminfo: {
            //     selectleftisshow: true, //筛选查询-左侧字+select单框||select时间
            //     selectleft: {
            //         width: 'none',
            //         divinterval: '20px', //框右侧距离
            //         selectwidth: '200px',
            //         selectinterval: '', //select右侧距离
            //         select: [
            //             {
            //                 selecttimeduanisshow: true, //该字段表示时间段模块状态：且已写死（字段名不可修改!）
            //                 ismust: false,
            //                 title: '入库时间：',
            //                 placeholder: {
            //                     placeholderstart: '请选择开始时间',
            //                     placeholderend: '请选择结束时间'
            //                 },
            //                 name: 'gointime',
            //                 value: {
            //                     valuestart: ref(''),
            //                     valueend: ref(''),
            //                     endstatus: true
            //                 },
            //                 rangeseparator: '至', //分隔符
            //                 divinterval: '3%', //上方字中-框右侧距离
            //                 selectwidth: '100%', //上方字中-总框长度
            //                 selectmaxwidth: '97%', //时间段-最大宽度
            //                 marginright: '0' //与右侧距离
            //             }
            //         ]
            //     }
            // }, // 筛选-控件信息
            mainthreeisshowbut: false, // 数据列表中-【添加按钮】
            mainthreeisshowset: false, // 数据列表中-复选框
            mainthreeisshowope: true, // 数据列表中-操作-根据页面显示模块（整页）
            mainthreetableope: 26, // 数据列表中-操作-根据页面显示控件（整页）
            mainthreehandle: 100, // 数据列表中-操作-宽度
            mainthreetabletitle: [], // 数据列表中-数据表头
            mainthreestatus: true, // 数据列表中-属于search按钮表示状态
            mainthreetableData: [], // 数据列表中-具体数据
            totalCount: 1, //数据列表中-数据总条数
            currentpage: 1, //数据列表中-数据当前页数
            lastpage: 1, //数据列表中-数据总页数（默认以20条一页）
            loading: false, //初始化-loading加载动效，默认是false关闭

            //table表单相关数据
            seniorform: {},
            currentPage: 1,
            pageSizes: [20, 50, 100],
            PageSize: 20,
            multipleSelection: [],

            mypowerlimits: JSON.parse(localStorage.getItem('powerlimits')), //获取权限
            mytoken: localStorage.getItem('token'), //获取存储的token
            pagesdatas: [] //存储最近一次的筛选条件，供分页使用
        }
    },
    methods: {
        //table表中-当选项发生变化是触发该函数
        handleSelectionChange(val) {
            this.multipleSelection = val
        },
        //分页器中-当选择条数时触发该函数
        handleSizeChange(val) {
            // console.log(`每页 ${val} 条`)
            this.PageSize = val
            this.currentPage = 1

            this.pagesdatas = {
                // gointime: this.pagesdatas.gointime,
                page: 1,
                page_size: val
            }

            this.postAxiosEvent(this.pagesdatas)
        },
        //分页器中-当选择分页时触发该函数
        handleCurrentChange(val) {
            // console.log(`当前页: 第${val}页`)
            this.currentPage = val

            this.pagesdatas = {
                // gointime: this.pagesdatas.gointime,
                page: val,
                page_size: this.PageSize
            }

            this.postAxiosEvent(this.pagesdatas)
        },
        //导出数据
        exportDatas() {
            axios
                .post(
                    '/manage/pack/packStockIndex',
                    {
                        // gointime: this.pagesdatas.gointime,
                        export: 1
                    },
                    {
                        headers: {
                            Auth: localStorage.getItem('token')
                        }
                    }
                )
                .then(() => {})
                .catch(function (error) {
                    // 请求失败处理
                    console.log(error)
                })
        },
        // //提交等相关event事件函数
        // //获取Container-Pagedata-Maintwo-搜索-返回的数据
        // getSeachinfoEvent(data) {
        //     // console.log('点击查询获取的data数组：' + JSON.stringify(data))
        //     this.pagesdatas = {
        //         gointime: data.gointime,
        //         page: 1,
        //         page_size: this.PageSize
        //     }
        //     this.postAxiosEvent(this.pagesdatas)
        // },
        //数据渲染
        myaxiosfunc(successdata) {
          // console.log('当前页面的data:' + JSON.stringify(newdata))
            this.mainthreetableData = successdata.data
            this.totalCount = successdata.total
            this.currentpage = successdata.current_page //当前页面页数
            this.lastpage = successdata.last_page
            this.loading = false
        },
        // 接口
        postAxiosEvent(data) {
            this.loading = true

            axios
                .post('/manage/pack/packStockIndex', data, {
                    headers: {
                        Auth: localStorage.getItem('token')
                    }
                })
                .then((response) => {
                    let getdatas = response.data.result
                    // 列表动态 title
                    let guding_title = [
                        {
                            fixedstatu: 'left',
                            prop: 'id',
                            label: 'ID',
                            width: '100'
                        },
                        {
                            fixedstatu: false,
                            prop: 'name',
                            label: '产品名称',
                            width: ''
                        },
                        {
                            prop: 'unit',
                            label: '单位',
                            minWidth: 100
                        },
                        {
                            fixedstatu: false,
                            prop: 'goods_cate',
                            label: '类别',
                            width: ''
                        }
                    ]
                    let get_title = getdatas.tabletitle
                    let keys_title = Object.keys(get_title)
                    let push_title = keys_title.map((n) => {
                        let n_data = {
                            fixedstatu: false,
                            prop: n,
                            label: get_title[n],
                            width: ''
                        }
                        return n_data
                    })
                    let all_numbers = [
                        {
                            fixedstatu: false,
                            prop: 'total_stock',
                            label: '总存量',
                            width: ''
                        }
                    ]
                    this.mainthreetabletitle = [...guding_title, ...push_title, ...all_numbers]

                    // 列表数据
                    this.myaxiosfunc(getdatas)
                })
                .catch(function (error) {
                    // 请求失败处理
                    console.log(error)
                })
        }
    },
    created() {
        if (localStorage.getItem('token') === null || !localStorage.getItem('token')) {
            this.isshow = false
            this.$router.push('/').catch((error) => error)
        } else {
            this.pagesdatas = {
                page: 1,
                page_size: 20
            }
            this.postAxiosEvent(this.pagesdatas)
        }
    },
    components: {
        mainone
        // maintwo
    }
})
</script>

<style scoped>
/*操作中-按钮样式*/
.el-button--mini {
    min-height: 28px;
    padding: 0 8px;
    font-size: 12px;
    border-radius: calc(var(--el-border-radius-base) - 1px);
}

.box-list {
    margin-top: 10px;
    background-color: #ffffff;
    border-radius: 4px;
    line-height: 40px;
    padding: 0 20px;
}
</style>
