<template>
  <div>
    <mainone :mainonebread="mainonebread"></mainone>
    <div>
      <!--标题-->
      <div class="box" style="">
        <div class="box_title" style="">
          <div class="box_title_font">添加打印介质</div>
          <div class="box_title_button"></div>
        </div>
        <!--内容-->
        <div class="box_content">
          <el-row style="margin-top: 20px">
            <!--占位符-->
            <el-col :span="24" :xs="24" :sm="24" :lg="2" :xl="2">
              <div style="width: 100%"></div>
            </el-col>
            <!--主表样式-->
            <el-col :span="24" :xs="24" :sm="24" :lg="17" :xl="17">
              <el-form ref="ruleForm" :model="ruleForm" label-width="150px" class="demo-ruleForm">
                <!--选择打印介质 style="width: 47%; margin-right: 6%" @visible-change="finduserSaleList('1')"-->
                <el-form-item label="选择打印介质：">
                  <el-select v-model="ruleForm.printtype" placeholder="请选择" style="width: 100%"
                    @change="changeJiezhiEvent(ruleForm.printtype)">
                    <el-option :label="getsales" :value="idex" v-for="(getsales, idex) in this.printtype_datas"
                      :key="idex"></el-option>
                  </el-select>
                </el-form-item>
                <!--选择类型-->
                <el-form-item label="选择类型：" v-if="ruleForm.printtype === '3'">
                  <el-radio-group v-model="ruleForm.menutype">
                    <el-radio :label="i" v-for="(getsales, i) in this.menutype" :key="i">
                      {{ getsales }}</el-radio>
                  </el-radio-group>
                </el-form-item>
                <!--名称-->
                <el-form-item label="名称：" required="true">
                  <el-input v-model="ruleForm.name"></el-input>
                </el-form-item>
                <!--打印类型名称-->
                <el-form-item required="true" label="打印类型名称：" v-if="
                  !(ruleForm.menutype == '1' && ruleForm.printtype === '3')
                ">
                  <el-input v-model="ruleForm.aliasname"></el-input>
                </el-form-item>
                <!--描述信息-->
                <el-form-item required="true" label="描述信息：" v-if="
                  !(ruleForm.menutype == '1' && ruleForm.printtype === '3')
                ">
                  <el-input v-model="ruleForm.describe"></el-input>
                </el-form-item>
                <!--分类-->
                <el-form-item label="分类：" v-if="ruleForm.printtype === '3' && ruleForm.menutype === '2'">
                  <el-select v-model="ruleForm.type" placeholder="请选择" style="width: 100%"
                    @visible-change="finduserSaleList('1')">
                    <el-option :label="getsales.name" :value="getsales.id" v-for="(getsales, i) in this.type" :key="i">
                    </el-option>
                  </el-select>
                </el-form-item>
                <!--图标-->
                <el-form-item label="图标：">
                  <mainimgesup v-model="ruleForm.logo" :myimgesup="myimgesup" :myimgesupisshow="myimgesupisshow"
                    v-on:getuploadfile="(data) => getimagesinfoEvent(data)">
                  </mainimgesup>
                </el-form-item>
                <!--平铺图标库地址-->
                <el-form-item label="平铺图标库地址：" required="true" v-if="ruleForm.printtype != '3'">
                  <el-input v-model="ruleForm.icon" placeholder="" clearable></el-input>
                </el-form-item>
                <!--尺寸（mm）-->
                <el-form-item required="true" label="尺寸（mm）："
                  v-if="ruleForm.printtype === '3' && ruleForm.menutype === '2'">
                  <el-input v-model="ruleForm.size" placeholder="如：8*9"></el-input>
                </el-form-item>
                <!--排版-->
                <el-form-item required="true" label="排版："
                  v-if="ruleForm.printtype === '3' && ruleForm.menutype === '2'">
                  <el-input v-model="ruleForm.typesetting" placeholder="如：5*6"></el-input>
                </el-form-item>
                <!--电子版大小-->
                <el-form-item required="true" label="电子版大小："
                  v-if="ruleForm.printtype === '3' && ruleForm.menutype === '2'">
                  <el-input v-model="ruleForm.electronic_px" placeholder="如：2*3"></el-input>
                </el-form-item>
                <!--注意事项-->
                <el-form-item required="true" label="注意事项："
                  v-if="ruleForm.printtype === '3' && ruleForm.menutype === '2'">
                  <el-input v-model="ruleForm.name"></el-input>
                </el-form-item>
                <!--跳转路径-->
                <el-form-item required="true" label="跳转路径：" v-if="
                  !(ruleForm.printtype === '3' && ruleForm.menutype == '1')
                ">
                  <el-input v-model="ruleForm.eventname" placeholder="跳转路径："></el-input>
                </el-form-item>
                <!-- 关联价格-证件照 -->
                <el-form-item required="true" label="关联价格："
                  v-if="ruleForm.printtype === '3' && ruleForm.menutype === '2'">
                  <el-select v-model="ruleForm.price" placeholder="请选择">
                    <el-option :label="getsales" :value="pricedex" v-for="(getsales, pricedex) in this.getprice"
                      :key="pricedex"></el-option>
                  </el-select>
                </el-form-item>
                <!--关联价格-文档&合成&复印&照片-->
                <el-form-item required="true" label="关联价格：" v-if="ruleForm.printtype !== '3'">
                  <!-- 添加按钮 -->
                  <div>
                    <el-button type="primary" plain size="small" @click="addRechargeEvent(ruleForm.glprice)">添加
                    </el-button>
                  </div>
                  <div v-for="(getgldatas, l) in ruleForm.glprice" :key="l">
                    <el-row :gutter="10" style="margin-top: 10px">
                      <!-- 关联价格-选择尺寸 -->
                      <el-col :span="24" :xs="24" :sm="24" :lg="10">
                        <el-select v-model="getgldatas.getchicun" placeholder="请选择"
                          @visible-change="refreshspec(getgldatas.getchicun, l)" style="width: 100%">
                          <el-option :label="getsales.name" :value="chicundex"
                            v-for="(getsales, chicundex) in this.getpricechicun" :key="chicundex"></el-option>
                        </el-select>
                      </el-col>
                      <!-- 关联价格-选择尺寸的价格 -->
                      <el-col :span="24" :xs="24" :sm="24" :lg="10">
                        <el-select v-model="getgldatas.getprice" placeholder="请选择" style="width: 100%">
                          <el-option :label="getsales" :value="pricedex" v-for="(getsales, pricedex) in this.getprice"
                            :key="pricedex">
                          </el-option>
                        </el-select>
                      </el-col>
                      <!-- 关联价格-添加&删除按钮 -->
                      <el-col :span="24" :xs="24" :sm="24" :lg="4">
                        <el-button type="text" @click="delRechargeEvent(l, ruleForm.glprice)">删除</el-button>
                      </el-col>
                    </el-row>
                  </div>
                </el-form-item>
                <!--电子版-->
                <el-form-item required="true" label="是否户电子版："
                  v-if="ruleForm.printtype === '3' && ruleForm.menutype === '2'">
                  <el-radio-group v-model="ruleForm.electronic">
                    <el-radio label="1">启用</el-radio>
                    <el-radio label="-1">禁用</el-radio>
                  </el-radio-group>
                </el-form-item>
                <!--添加文字-->
                <el-form-item required="true" label="添加文字："
                  v-if="ruleForm.printtype === '3' && ruleForm.menutype === '2'">
                  <el-radio-group v-model="ruleForm.addtext">
                    <el-radio label="1">是</el-radio>
                    <el-radio label="-1">否</el-radio>
                  </el-radio-group>
                </el-form-item>
                <!--排序-->
                <el-form-item label="排序：" required="true">
                  <el-input v-model="ruleForm.sort" placeholder="数字越大排序越高"></el-input>
                </el-form-item>
                <!--状态-->
                <el-form-item label="状态：">
                  <el-radio-group v-model="ruleForm.status">
                    <el-radio label="1">启用</el-radio>
                    <el-radio label="-1">禁用</el-radio>
                  </el-radio-group>
                </el-form-item>
                <!--尺寸角标是否显示-->
                <el-form-item label="尺寸角标是否显示：" v-if="
                  ruleForm.printtype === '1' ||
                  ruleForm.printtype === '2' ||
                  ruleForm.printtype === '5'
                ">
                  <el-radio-group v-model="ruleForm.showsizetag">
                    <el-radio label="1">是</el-radio>
                    <el-radio label="-1">否</el-radio>
                  </el-radio-group>
                </el-form-item>
                <!--平台位置-多选-->
                <el-form-item required="true" label="平台位置：" v-if="
                  !(ruleForm.menutype == '1' && ruleForm.printtype === '3')
                ">
                  <el-checkbox-group v-model="ruleForm.position">
                    <el-checkbox :label="ldex" v-for="(getchecked, ldex) in position_data" :key="ldex">{{ getchecked }}
                    </el-checkbox>
                  </el-checkbox-group>
                </el-form-item>
                <!--显示平台-多选-->
                <el-form-item label="显示平台：" v-if="
                  !(ruleForm.menutype == '1' && ruleForm.printtype === '3')
                ">
                  <el-checkbox-group v-model="ruleForm.plateform">
                    <el-checkbox :label="kdex" v-for="(getchecked, kdex) in plateform_data" :key="kdex">{{ getchecked }}
                    </el-checkbox>
                  </el-checkbox-group>
                </el-form-item>
                <!--添加规格-合成-->
                <el-form-item label="添加规格：" v-if="ruleForm.printtype === '4'">
                  <el-button type="primary" plain @click="addSpec()">添加规格</el-button>
                  <!-- 两个尺寸两个规格 -->
                  <div>
                    <!-- 规格1 -->
                    <el-row :gutter="10" style="margin-top: 20px" v-for="(item, i) in ruleForm.specs" :key="i">
                      <!-- 一个尺寸规格内容 -->
                      <el-col :span="20" :xs="24" :sm="24" :lg="20">
                        <div style="
                            padding: 10px;
                            border: 1px dashed #333333;
                            border-radius: 4px;
                          ">
                          <el-form label-width="130px" class="demo-ruleForm">
                            <!-- 尺寸1 -->
                            <el-form-item required="true" label="尺寸：" v-for="(sub, l) in item.spec" :key="l"
                              style="margin-bottom: 10px">
                              <el-row :gutter="10">
                                <!-- 尺寸 -->
                                <el-col :span="24" :xs="24" :sm="24" :lg="6">
                                  <el-select v-model="sub.price" placeholder="选择关联尺寸" disabled>
                                    <el-option :label="getsales.name" :value="chicundex" v-for="(getsales, chicundex) in this
                                    .getpricechicun" :key="chicundex"></el-option>
                                  </el-select>
                                  <!-- <el-input
                                    v-model="sub.price"
                                    v-show="false"
                                    placeholder=""
                                  >
                                  </el-input>
                                  <el-input
                                    v-model="sub.pricename"
                                    :readonly="true"
                                    placeholder=""
                                  >
                                  </el-input> -->
                                </el-col>
                                <!-- 左侧顶点x，y坐标 -->
                                <el-col :span="24" :xs="24" :sm="24" :lg="9">
                                  <el-input v-model="sub.start" placeholder="左侧顶点x，y坐标" clearable></el-input>
                                </el-col>
                                <!-- 右侧底点x，y坐标 -->
                                <el-col :span="24" :xs="24" :sm="24" :lg="9">
                                  <el-input v-model="sub.end" placeholder="右侧底点x，y坐标" clearable></el-input>
                                </el-col>
                              </el-row>
                            </el-form-item>

                            <!-- 背景图 -->
                            <el-form-item label="背景图：" style="margin: 10px 0">
                              <mainimgesup v-model="item.bg" :myimgesup="myimgesup1" :myimgesupisshow="myimgesupisshow"
                                v-on:getuploadfile="
                                  (data) => getimagesinfoEvent1(data, i)
                                ">
                              </mainimgesup>
                            </el-form-item>
                            <!-- 背景图说明 -->
                            <el-form-item label="背景图说明：" required="true">
                              <el-input v-model="item.bgdesc" clearable></el-input>
                            </el-form-item>
                            <el-form-item label="相机背景图：" required="true">
                              <mainimgesup v-model="item.camerabg" :myimgesup="myimgesup2"
                                :myimgesupisshow="myimgesupisshow" v-on:getuploadfile="
                                  (data) => getimagesinfoEvent2(data, i)
                                ">
                              </mainimgesup>
                            </el-form-item>
                          </el-form>
                        </div>
                      </el-col>
                      <!-- 删除按钮 -->
                      <el-col :span="4" :xs="24" :sm="24" :lg="4">
                        <el-button @click="delspec(i)" type="danger">删除</el-button>
                      </el-col>
                    </el-row>
                  </div>
                </el-form-item>
                <!--button按钮-->
                <el-form-item style="margin-top: 50px">
                  <el-button type="primary" @click="submitForm('ruleForm')" style="width: 40%">提交
                  </el-button>
                  <el-button @click="resetForm()">取消</el-button>
                </el-form-item>
              </el-form>
            </el-col>
            <!--占位符-->
            <el-col :span="24" :xs="24" :sm="24" :lg="5" :xl="5">
              <div style="width: 100%"></div>
            </el-col>
          </el-row>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import mainone from "@/components/Mainone.vue";
import mainimgesup from "@/components/Mainimgesup.vue";
import { ElMessage } from "element-plus";
import { ref } from "vue";
import axios from "axios";

export default {
  name: "vphotoidadd",
  inject: ["reload"],
  data() {
    return {
      mainonebread: [
        {
          name: "打印",
        },
        {
          name: "打印介质管理",
        },
        {
          name: "打印介质",
          path: "/photoid",
        },
        {
          name: "添加打印介质",
          path: "/photoidadd",
        },
      ], // 当前页-【面包屑导航】

      //上传图片组件
      myimgesupisshow: true, //form表单-imgesuploade上传图片
      myimgesup: {
        uploadsize: 1, //选择上传图片的展现框-1表示小框，2表示中等框，3表示大框
        listtype: "picture", //list展示类型，text/picture/picture-card；默认不填为text
        ismust: true, //是否必填，true表示必填
        action: "/admin/upload/openFile", //upload上传API地址
        headers: {
          Auth: localStorage.getItem("token"),
        }, //upload上传headers
        title: "上传图片", //button-按钮文字
        tips: "(1)建议尺寸：1070*1830像素; (2)视频仅持格式：*.mp4; (3)图片支持格式：*.jpg; (4)最大支持上传20M文件", //提示&建议文字
        limit: "1", //上传数量限制
        target_id: 100, //上传文件target_id ：图片分类，区分存储文件和存储时长，不传默认存储1天后过期；1-二维码； 2-打印文件； 3-文库文件； 4-课程文件； 5-广告主宣传页； 6-广告主产生的pdf文件； 7-证件照文件； 50-设备广告； 100-通用；
        rules: {
          images: {
            image_width: 0, //图片宽度
            image_height: 0, //图片高度
            size: 2000, //文件大小（单位kb）
            type: ["image/jpeg", "image/jpg", "image/png"], //文件格式
            typechart: "jpg/png", //文件格式提示规则
          },
          video: {
            size: 10000, //文件大小（单位kb）
            type: ["video/mp4"], //文件格式
            typechart: "mp4", //文件格式提示规则
          },
        },
      },
      myimgesup1: {
        uploadsize: 1, //选择上传图片的展现框-1表示小框，2表示中等框，3表示大框
        listtype: "picture", //list展示类型，text/picture/picture-card；默认不填为text
        ismust: true, //是否必填，true表示必填
        action: "/admin/upload/openFile", //upload上传API地址
        headers: {
          Auth: localStorage.getItem("token"),
        }, //upload上传headers
        title: "上传图片", //button-按钮文字
        tips: "(1)建议尺寸：1070*1830像素; (2)视频仅持格式：*.mp4; (3)图片支持格式：*.jpg; (4)最大支持上传20M文件", //提示&建议文字
        limit: "1", //上传数量限制
        target_id: 100, //上传文件target_id ：图片分类，区分存储文件和存储时长，不传默认存储1天后过期；1-二维码； 2-打印文件； 3-文库文件； 4-课程文件； 5-广告主宣传页； 6-广告主产生的pdf文件； 7-证件照文件； 50-设备广告； 100-通用；
        rules: {
          images: {
            image_width: 0, //图片宽度
            image_height: 0, //图片高度
            size: 2000, //文件大小（单位kb）
            type: ["image/jpeg", "image/jpg", "image/png"], //文件格式
            typechart: "jpg/png", //文件格式提示规则
          },
          video: {
            size: 10000, //文件大小（单位kb）
            type: ["video/mp4"], //文件格式
            typechart: "mp4", //文件格式提示规则
          },
        },
      },
      myimgesup2: {
        uploadsize: 1, //选择上传图片的展现框-1表示小框，2表示中等框，3表示大框
        listtype: "picture", //list展示类型，text/picture/picture-card；默认不填为text
        ismust: true, //是否必填，true表示必填
        action: "/api/manage/upload/openFile", //upload上传API地址
        headers: {
          Auth: localStorage.getItem("token"),
        }, //upload上传headers
        title: "上传图片", //button-按钮文字
        tips: "(1)建议尺寸：1070*1830像素; (2)视频仅持格式：*.mp4; (3)图片支持格式：*.jpg; (4)最大支持上传20M文件", //提示&建议文字
        limit: "1", //上传数量限制
        target_id: 12, //上传文件target_id ：图片分类，区分存储文件和存储时长，不传默认存储1天后过期；1-二维码； 2-打印文件； 3-文库文件； 4-课程文件； 5-广告主宣传页； 6-广告主产生的pdf文件； 7-证件照文件； 50-设备广告； 100-通用；
        rules: {
          images: {
            image_width: 0, //图片宽度
            image_height: 0, //图片高度
            size: 2000, //文件大小（单位kb）
            type: ["image/jpeg", "image/jpg", "image/png"], //文件格式
            typechart: "jpg/png", //文件格式提示规则
          },
          video: {
            size: 10000, //文件大小（单位kb）
            type: ["video/mp4"], //文件格式
            typechart: "mp4", //文件格式提示规则
          },
        },
      },

      //配置
      printtype_datas: "", //打印介质
      specs_datas: [
        {
          id: "1",
          name: "一个尺寸一个规格",
        },
        {
          id: "2",
          name: "一个尺寸两个规格",
        },
        {
          id: "3",
          name: "两个尺寸一个规格",
        },
      ], //添加规格
      getpricechicun: [], //关联价格-尺寸
      getprice: "", //关联价格-价格（证件照只有价格）
      getposition: "", //获取的平台位置-配置
      position_data: "", //平台位置
      plateform_data: "", //平台显示
      idpaper: "",
      menutype: [],

      ruleForm: {
        printtype: "1", //打印介质
        type: ref(""), // 证件照-类型
        position: ["1"], //平台位置
        plateform: ref(["1"]), //平台显示
        price: "", //关联价格（证件照中）
        eventname: "", //跳转路径
        name: "", //名称
        icon: '', //平铺图标库地址
        aliasname: "", //打印类型名称
        describe: "", //描述信息
        showsizetag: "1", //尺寸角标是否显示
        status: "-1",
        menutype: "1",
        electronic: "1",
        addtext: "1",
        logo: "",
        sort: 0,
        glprice: [
          {
            getchicun: "1", //尺寸
            getprice: "1", //价格
            addisshow: true,
          },
        ], //关联价格
        specs: [
          {
            spec: [{ price: "1", start: 0, end: 0 }],
            bg: "",
            bgdesc: "",
            camerabg: "",
          },
        ],
      }, //form表单数据

      mypowerlimits: JSON.parse(localStorage.getItem("powerlimits")), //获取权限
      mytoken: localStorage.getItem("token"), //获取存储的token

      addisshow: true,
    };
  },
  mounted() { },
  methods: {
    getimagesinfoEvent(data) {
      this.ruleForm.logo = data.url;
    },
    getimagesinfoEvent1(data, i) {
      this.ruleForm.specs[i].bg = data.url;
    },
    getimagesinfoEvent2(data, i) {
      this.ruleForm.specs[i].camerabg = data.url;
    },
    addSpec() {
      // 倒叙插入
      if (this.ruleForm.specs && this.ruleForm.specs.length > 0) {
        this.ruleForm.specs.unshift({
          spec: [],
          bg: "",
          bgdesc: "",
        }); //在数组起始位置插入数据
      }
      for (var k = 0; k < this.ruleForm.specs.length; k++) {
        for (var i = 0; i < this.ruleForm.glprice.length; i++) {
          if (
            this.ruleForm.specs[k].spec.length < this.ruleForm.glprice.length
          ) {
            console.log(this.ruleForm.glprice[i].getchicun);
            this.ruleForm.specs[k].spec.push({
              price: this.ruleForm.glprice[i].getchicun,
              start: 0,
              end: 0,
            });
          }
        }
      }

      // this.ruleForm.specs.push({
      //   spec: [],
      //   bg: "",
      //   bgdesc: "",
      // });
      // for (let k = 0; k < this.ruleForm.specs.length; k++) {
      //   this.ruleForm.specs[k].spec = [];
      //   for (let i = 0; i < this.ruleForm.glprice.length; i++) {
      //     this.ruleForm.specs[k].spec.push({
      //       price: this.ruleForm.glprice[i].getchicun,
      //       start: 0,
      //       end: 0,
      //     });
      //   }
      // }
    },
    delspec(i) {
      this.ruleForm.specs.splice(i, 1);
    },
    refreshspec(data, i) {
      if (this.ruleForm.printtype == "4") {
        for (let k = 0; k < this.ruleForm.specs.length; k++) {
          this.ruleForm.specs[k].spec[i].price = data;
          // this.ruleForm.specs[k].spec[i]["pricename"] = this.getpricechicun[data].name;
        }
      }
    },
    //点击打印介质触发函数
    changeJiezhiEvent(data) {
      if (data !== "3") {
        this.ruleForm.type = "";
        this.ruleForm.position = ['1']
      } else {
        this.ruleForm.type = ref("");
        this.ruleForm.position = ['3']
      }
      // 选择打印介质后-加载-平台位置
      this.position_data = this.getposition[data];
    },
    //关联价格-添加事件
    addRechargeEvent(fromdata) {
      console.log(fromdata);

      var oneAddChats = {
        getchicun: "", //尺寸
        getprice: "", //价格
        addisshow: false,
      };
      // js中通过push向数组最后新增指定的元素
      this.ruleForm.glprice.push(oneAddChats);

      for (var i = 0; i < this.ruleForm.specs.length; i++) {
        this.ruleForm.specs[i].spec.push({ price: '', start: 0, end: 0 });
      }
    },
    //关联价格-删除事件
    delRechargeEvent(ide, formdata) {
      console.log("获取的删除id为：" + ide);
      // js中通过splice删除指定的元素
      formdata.splice(ide, 1);
      for (var i = 0; i < this.ruleForm.specs.length; i++) {
        this.ruleForm.specs[i].spec.splice(ide, 1);
      }
    },

    //提交form表单-并判断是否必填
    submitForm(formName) {
      let getdatas = this.$refs[formName].model;

      if (getdatas.icon == '' && getdatas.printtype != '3') {
        ElMessage({
          type: "error",
          message: '平铺图标库地址不能为空',
        });
      } else {
        this.$refs[formName].validate((valid) => {
          if (valid) {
            console.log(
              "当前提交成功-获取的form表单值：" +
              JSON.stringify(this.$refs[formName].model)
            );
            //??此处增加post调用
            var queryParam = {
              printtype: this.ruleForm.printtype,
              type: this.ruleForm.menutype,
              name: this.ruleForm.name,
              aliasname: this.ruleForm.aliasname,
              describe: this.ruleForm.describe,
              eventname: this.ruleForm.eventname,
              sort: this.ruleForm.sort,
              icon: this.ruleForm.icon, // 平铺图标库地址
              showsizetag: this.ruleForm.showsizetag,
              status: this.ruleForm.status,
              position: this.ruleForm.position,
              platform: this.ruleForm.plateform,
              addtext: this.ruleForm.addtext,
              size: this.ruleForm.size,
              typesetting: this.ruleForm.typesetting,
              logo: this.ruleForm.logo,
            };

            if (
              this.ruleForm.printtype == "1" ||
              this.ruleForm.printtype == "2" ||
              this.ruleForm.printtype == "4" ||
              this.ruleForm.printtype == "5"
            ) {
              queryParam["price"] = [];
              for (var i = 0; i < this.ruleForm.glprice.length; i++) {
                queryParam.price[this.ruleForm.glprice[i].getchicun] =
                  this.ruleForm.glprice[i].getprice;
              }
            }
            if (this.ruleForm.printtype == "3") {
              if (this.ruleForm.menutype == "1") {
                queryParam["price"] = this.ruleForm.price;
              }
              if (this.ruleForm.menutype == "2") {
                queryParam["electronic"] = this.ruleForm.electronic;
                queryParam["electronic_px"] = this.ruleForm.electronic_px;
                queryParam["price"] = [];
                queryParam["pid"] = this.ruleForm.type;
                queryParam.price[this.idpaper] = this.ruleForm.price;
              }
            }
            if (this.ruleForm.printtype == "4") {
              queryParam["specs"] = [];
              for (var k = 0; k < this.ruleForm.specs.length; k++) {
                queryParam.specs.push({
                  spec: [],
                  bg: "",
                  bgdesc: "",
                  camerabg: "",
                });

                for (var l = 0; l < this.ruleForm.glprice.length; l++) {
                  for (var m = 0; m < this.ruleForm.specs[k].spec.length; m++) {
                    if (
                      this.ruleForm.specs[k].spec[m].price ==
                      this.ruleForm.glprice[l].getchicun
                    ) {
                      queryParam.specs[k].spec[
                        this.ruleForm.glprice[l].getchicun
                      ] = {
                        start: this.ruleForm.specs[k].spec[m].start,
                        end: this.ruleForm.specs[k].spec[m].end,
                      };
                    }
                  }
                }
                queryParam.specs[k]["bg"] = this.ruleForm.specs[k].bg;
                queryParam.specs[k]["bgdesc"] = this.ruleForm.specs[k].bgdesc;
                queryParam.specs[k]["camerabg"] = this.ruleForm.specs[k].camerabg;
                // queryParam.specs[k]["camerabg"] = encodeURIComponent(
                //   this.ruleForm.specs[k].camerabg
                // );
                // console.log(this.ruleForm.specs[k].camerabg)
                // queryParam.specs[k]["camerabg"] = encodeURIComponent(
                //   this.ruleForm.specs[k].camerabg
                // );
              }
            }
            axios
              .post("/manage/shareprintcate/add", queryParam, {
                headers: {
                  Auth: localStorage.getItem("token"),
                },
              })
              .then((response) => {
                if (response.data.code == 0) {
                  ElMessage({
                    type: "success",
                    message: response.data.message,
                    duration: 1000,
                    onClose: () => {
                      // this.reload(); //刷新
                      this.$router.go(-1); // 返回上一页
                    },
                  });
                }
              })
              .catch(function (error) {
                console.log(error);
              });
          } else {
            console.log("error submit!!");
            return false;
          }
        });
      }

    },
    //重置form表单中填写的数据-取消-返回上一页
    resetForm() {
      // this.$refs[formName].resetFields();
      this.$router.go(-1); // 返回上一页
    },
    //用于下拉框-点击&选择事件
    finduserSaleList(depttype) {
      console.log(depttype);
    },
  },
  created() {
    if (localStorage.getItem("token") === null || !localStorage.getItem("token")) {
      this.isshow = false;
      this.$router.push("/").catch((error) => error);
    } else {
      //通过API获取相关配置
      axios
        .get("/manage/shareprintcate/add", {
          headers: {
            Auth: localStorage.getItem("token"),
          },
          params: {},
        })
        .then((response) => {
          console.log(response.data.result);
          var getdatas = response.data.result;

          //渲染-打印介质-配置
          var arrprinttype = [];
          for (const [key, value] of Object.entries(getdatas.printtype)) {
            arrprinttype.push({
              id: key,
              name: value,
            });
          }
          this.printtype_datas = getdatas.printtype;

          //关联价格-证件照
          this.getprice = getdatas.price;
          //平台位置
          this.getposition = getdatas.position;
          console.log(this.getposition)
          this.position_data = this.getposition[1]; //初始化，默认首次进入显示打印介质：文档
          this.getpricechicun = getdatas.paper;
          //显示平台
          this.plateform_data = getdatas.plateform;
          this.type = getdatas.type;
          this.menutype = getdatas.menutype;
          this.idpaper = getdatas.idpaper;

          this.ruleForm.specs[0].spec[0]["pricename"] =
            this.getpricechicun[1].name;
        })
        .catch(function (error) {
          console.log(error);
        });
    }
  },
  components: {
    mainone,
    mainimgesup,
  },
};
</script>

<style scoped>
.box {
  background-color: #ffffff;
  border-radius: 4px;
  color: #97a0c3;
  padding: 5px 20px;
}

.box_title {
  line-height: 40px;
  height: 40px;
}

.box_title_font {
  float: left;
  font-weight: 400;
  font-size: 14px;
  color: #333333;
}

.box_title_button {
  float: right;
  font-weight: 400;
  font-size: 14px;
  color: #94a3d2;
}

.box_title_button:hover {
  cursor: pointer;
}

.box_content {
  border-top: 1px solid #eeeeee;
}
</style>
