<template>
    <div>
        <mainone :mainonebread="mainonebread"></mainone>
        <div>
            <!--标题-->
            <div class="box" style="">
                <div class="box_title" style="">
                    <div class="box_title_font">添加-活动送京币</div>
                    <div class="box_title_button"></div>
                </div>
                <!--内容-->
                <div class="box_content">
                    <el-row style="margin-top: 20px">
                        <!--占位符-->
                        <el-col :span="24" :xs="24" :sm="24" :lg="7">
                            <div style="width: 100%"></div>
                        </el-col>
                        <!--主表样式-->
                        <el-col :span="24" :xs="24" :sm="24" :lg="9">
                            <el-form ref="ruleForm" :model="ruleForm" :rules="rules" label-width="120px" class="demo-ruleForm">
                                <!--兑换公司-->
                                <el-form-item label="兑换公司：" prop="allow_companyids">
                                    <el-select v-model="ruleForm.allow_companyids" multiple filterable placeholder="公司名+小程序名（多选）" style="width: 100%">
                                        <el-option v-for="(item, index) in allow_companyids_options" :key="index" :label="item.label" :value="item.value" />
                                    </el-select>
                                </el-form-item>
                                <!--活动名称-->
                                <el-form-item label="活动名称：" prop="name">
                                    <el-input v-model="ruleForm.name" clearable maxlength="20" show-word-limit></el-input>
                                </el-form-item>
                                <!--备注-->
                                <el-form-item label="备注：" prop="remark">
                                    <el-input type="textarea" v-model="ruleForm.remark" clearable maxlength="50" show-word-limit></el-input>
                                </el-form-item>
                                <!--发行量-->
                                <el-form-item label="发行量：" prop="total">
                                    <el-input type="number" v-model.number="ruleForm.total" :min="0" :max="100" maxlength="3" placeholder="请输入正整数" clearable> </el-input>
                                </el-form-item>
                                <!--京币面额-->
                                <el-form-item label="京币面额：" prop="amount">
                                    <el-input type="number" v-model.number="ruleForm.amount" :min="0" :max="10000" maxlength="5" placeholder="请输入正整数" clearable></el-input>
                                </el-form-item>
                                <!--有效时间段-->
                                <el-form-item label="有效时间段：" prop="use_time">
                                    <el-date-picker
                                        v-model="ruleForm.use_time"
                                        type="datetimerange"
                                        range-separator="至"
                                        start-placeholder="开始时间"
                                        end-placeholder="结束时间"
                                        style="width: 100%;"
                                        @change="(value) => changeTimes(value)"
                                    >
                                    </el-date-picker>
                                </el-form-item>
                                <!--button按钮-->
                                <el-form-item style="margin-top: 50px" v-if="mypowerlimits.zhsz_tj">
                                    <el-button type="primary" @click="submitForm('ruleForm')" style="width: 40%" :disabled="disabled_btn">提交</el-button>
                                    <el-button @click="resetForm('ruleForm')">重置</el-button>
                                </el-form-item>
                            </el-form>
                        </el-col>
                        <!--占位符-->
                        <el-col :span="24" :xs="24" :sm="24" :lg="8">
                            <div style="width: 100%"></div>
                        </el-col>
                    </el-row>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import mainone from '@/components/Mainone.vue'
import { ElMessage } from 'element-plus'
import axios from 'axios'

export default {
    name: 'vaccountset',
    data() {
        return {
            mainonebread: [
                {
                    name: '运营'
                },
                {
                    name: '京币'
                },
                {
                    name: '活动送京币',
                    path: '/yingbipresent'
                },
                {
                    name: '添加'
                }
            ], // 当前页-【面包屑导航】

            ruleForm: {
                allow_companyids: '',
                name: '',
                remark: '',
                total: '',
                amount: '',
                use_time: ''
            }, //form表单数据
            rules: {
                allow_companyids: [
                    {
                        required: true,
                        message: '兑换公司为必选项',
                        trigger: 'blur'
                    }
                ],
                name: [
                    {
                        required: true,
                        message: '活动名称为必填项',
                        trigger: 'change'
                    }
                ],
                remark: [
                    {
                        required: false,
                        message: '备注为必填项',
                        trigger: 'change'
                    }
                ],
                total: [
                    {
                        required: true,
                        message: '发行量为必填项',
                        trigger: 'change'
                    },
                    {
                        type: 'number',
                        min: 0,
                        max: 100,
                        message: '发行量区间在0~100之间',
                        trigger: 'change'
                    },
                    {
                        type: 'integer',
                        // 正则表达式(大于等于0的正整数)：/^\+?[0-9]\d*$/  或 /^\d\d*$/
                        pattern: /^\d\d*$/,
                        message: '发行量必须为正整数',
                        trigger: 'change'
                    }
                ],
                amount: [
                    {
                        required: true,
                        message: '京币面额为必填项',
                        trigger: 'change'
                    },
                    {
                        type: 'number',
                        min: 0,
                        max: 10000,
                        message: '京币面额区间在0~10000之间',
                        trigger: 'change'
                    },
                    {
                        type: 'integer',
                        // 正则表达式(大于等于0的正整数)：/^\+?[0-9]\d*$/  或 /^\d\d*$/
                        pattern: /^\d\d*$/,
                        message: '京币面额必须为正整数',
                        trigger: 'change'
                    }
                    // {
                    //     // 正则表达式(大于等于0的正数或保留两位小数的小数)：/^(([1-9]{1}\d*)|(0{1}))(\.\d{1,2})?$/
                    //     pattern: /^(([1-9]{1}\d*)|(0{1}))(\.\d{1,2})?$/,
                    //     message: '京币面额必须为保留两位小数的正数',
                    //     trigger: 'change'
                    // }
                ],
                use_time: [
                    {
                        required: true,
                        message: '有效时间段为必填项',
                        trigger: 'blur'
                    }
                ]
            }, //form表单填写规则-前端判断是否必填

            // 兑换公司下拉
            allow_companyids_options: null,

            // 防抖按钮
            disabled_btn: false,

            mypowerlimits: JSON.parse(localStorage.getItem('powerlimits')), //获取权限
            mytoken: localStorage.getItem('token'), //获取存储的token
            myuserinfo: localStorage.getItem('userinfo') //获取存储的用户信息
        }
    },
    mounted() {},
    methods: {
        // 选中时间段
        changeTimes(datas) {
            console.log(datas)
        },

        //提交form表单-并判断是否必填
        submitForm(formName) {
            let getdatas = this.$refs[formName].model
            this.$refs[formName].validate((valid) => {
                if (valid) {
                    // 解析有效期时间段
                    let [start, end] = getdatas.use_time
                    let starttime = start.valueOf() / 1000
                    let endtime = end.valueOf() / 1000

                    this.disabled_btn = true
                    axios
                        .post(
                            '/manage/coinActivity/add',
                            {
                                allow_companyids: getdatas.allow_companyids,
                                name: getdatas.name,
                                remark: getdatas.remark,
                                total: getdatas.total,
                                amount: getdatas.amount,
                                starttime: starttime,
                                endtime: endtime
                            },
                            {
                                headers: {
                                    Auth: localStorage.getItem('token')
                                }
                            }
                        )
                        .then((response) => {
                            if (response.data.code === 0) {
                                ElMessage({
                                    type: 'success',
                                    message: '提交成功！',
                                    duration: 1000,
                                    onClose: () => {
                                        this.disabled_btn = false
                                    }
                                })
                                this.$router.go(-1)
                            } else {
                                this.disabled_btn = false
                            }
                        })
                        .catch(function(error) {
                            this.disabled_btn = false
                            console.log(error)
                        })
                } else {
                    console.log('error submit!!')
                    return false
                }
            })
        },
        //重置form表单中填写的数据
        resetForm(formName) {
            this.$refs[formName].resetFields()
        },

        // 获取公司小程序-下拉列表
        getCompanyOptions() {
            axios
                .get('/manage/Manage/comList', {
                    headers: {
                        Auth: localStorage.getItem('token')
                    },
                    params: {
                        name: '',
                        status: '',
                        page: 1,
                        page_size: 10000
                    }
                })
                .then((response) => {
                    this.allow_companyids_options = response.data.result.data.map((n) => {
                        return { label: n.name + '（ ' + n.brand_name + ' ）', value: n.id }
                    })
                })
                .catch(function(error) {
                    console.log(error)
                })
        }
    },
    created() {
        if (localStorage.getItem('token') === null || !localStorage.getItem('token')) {
            this.isshow = false
            this.$router.push('/').catch((error) => error)
        } else {
            this.getCompanyOptions()
        }
    },
    components: {
        mainone
    }
}
</script>

<style scoped>
.box {
    background-color: #ffffff;
    border-radius: 4px;
    color: #97a0c3;
    padding: 5px 20px;
}

.box_title {
    line-height: 40px;
    height: 40px;
}

.box_title_font {
    float: left;
    font-weight: 400;
    font-size: 14px;
    color: #333333;
}

.box_title_button {
    float: right;
    font-weight: 400;
    font-size: 14px;
    color: #94a3d2;
}

.box_title_button:hover {
    cursor: pointer;
}

.box_content {
    border-top: 1px solid #eeeeee;
}
</style>
