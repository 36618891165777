<template>
    <el-dialog v-model="isShow" :title="title" :width="width" :before-close="handleClose">
        <slot name="content"></slot>
        <template #footer>
            <el-button v-if="is_footer" @click="handleClose">返回</el-button>
        </template>
    </el-dialog>
</template>

<script>
export default {
    name: 'xwDialog',
    props: {
        is_show: {
            type: Boolean,
            default: false
        },
        title: {
            type: String,
            default: ''
        },
        width: {
            type: String,
            default: '50%'
        },
        is_footer: {
            type: Boolean,
            default: true
        }
    },
    watch: {
        is_show: {
            handler(newVal) {
                this.isShow = newVal
            },
            deep: true,
            immediate: true
        }
    },
    data() {
        return {
            isShow: false
        }
    },
    methods: {
        handleClose() {
            this.$emit('close', true)
        }
    }
}
</script>
