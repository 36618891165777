<template>
    <div>
        <mainone :mainonebread="mainonebread"></mainone>
        <div v-loading="this.loading" element-loading-text="正在飞速加载中...">
            <!--标题-->
            <div class="box" style="">
                <div class="box_title" style="">
                    <div class="box_title_font">编辑分类</div>
                    <div class="box_title_button">
                        <!--可添加按钮-->
                        <!--<el-button type="primary" class="max_search" @click="exportDatas(mainthreetableData)">导出数据</el-button>-->
                    </div>
                </div>
                <!--内容-->
                <div class="box_content">
                    <el-row style="margin-top: 20px">
                        <!--占位符-->
                        <el-col :span="24" :xs="24" :sm="24" :lg="6">
                            <div style="width: 100%"></div>
                        </el-col>
                        <!--主表样式-->
                        <el-col :span="24" :xs="24" :sm="24" :lg="9">
                            <el-form ref="ruleForm" :model="ruleForm" :rules="rules" label-width="120px" class="demo-ruleForm">
                                <el-form-item label="分类名称：" prop="cate_name">
                                    <el-input v-model="ruleForm.cate_name" clearable></el-input>
                                </el-form-item>
                                <!-- 上级分类 -->
                                <el-form-item label="上级分类：" prop="version">
                                    <div style="display: flex; flex-direction: row; flex-wrap: wrap; justify-content: space-between;">
                                        <div style="min-width: 230px;margin: 5px 0;">
                                            <el-select v-model="ruleForm.version" placeholder="请选择" style="width: 100%;">
                                                <el-option
                                                    :label="getsales.cate_name"
                                                    :value="getsales.id"
                                                    v-for="(getsales, chicundex) in this.gettype"
                                                    :key="chicundex"
                                                    @click="finduserSaleList(getsales)"
                                                ></el-option>
                                            </el-select>
                                        </div>
                                        <div style="min-width: 230px;margin: 5px 0;">
                                            <!-- 子分类-多级联选 -->
                                            <!-- :props="{value:'id',label:'name'}" 自定义字段-->
                                            <el-cascader
                                                v-model="ruleForm.version_data"
                                                :options="gettypechild"
                                                :show-all-levels="false"
                                                style="width: 100%;"
                                                :props="{
                                                    value: 'id',
                                                    label: 'cate_name',
                                                    children: 'child',
                                                    checkStrictly: true
                                                }"
                                                clearable
                                                :disabled="ruleForm.disabled"
                                                @change="handleChange"
                                            ></el-cascader>
                                        </div>
                                    </div>
                                </el-form-item>
                                <el-form-item label="分类图标：" prop="icon">
                                    <mainimgesup
                                        v-model="ruleForm.icon"
                                        :myimgesup="myimgesup"
                                        :myimgesupisshow="myimgesupisshow"
                                        :filelist="ruleForm.icon_list"
                                        v-on:getuploadfile="getUploadFileEvent"
                                        v-on:deluploadfile="(data) => deluploadfileEvent(data)"
                                    >
                                    </mainimgesup>
                                </el-form-item>
                                <el-form-item label="排序：" prop="sort">
                                    <el-input v-model="ruleForm.sort" placeholder="数字越大排序越高" clearable></el-input>
                                </el-form-item>
                                <el-form-item label="是否显示：" prop="status">
                                    <el-radio-group v-model="ruleForm.status">
                                        <el-radio label="1">是</el-radio>
                                        <el-radio label="2">否</el-radio>
                                    </el-radio-group>
                                </el-form-item>
                                <el-form-item>
                                    <el-button type="primary" @click="submitForm('ruleForm')" style="width: 40%; margin-right: 10px">提交 </el-button>
                                    <el-button @click="resetForm('ruleForm')">取消</el-button>
                                </el-form-item>
                            </el-form>
                        </el-col>
                        <!--占位符-->
                        <el-col :span="24" :xs="24" :sm="24" :lg="9">
                            <div style="width: 100%"></div>
                        </el-col>
                    </el-row>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import mainone from '@/components/Mainone'
import mainimgesup from '@/components/Mainimgesup.vue'
import { ref } from 'vue'
import { ElMessage } from 'element-plus'
import axios from 'axios'

export default {
    name: 'vuseradd',
    data() {
        return {
            mainonebread: [
                {
                    name: '教育'
                },
                {
                    name: '文档管理'
                },
                {
                    name: '文档分类',
                    path: '/fileclassify'
                },
                {
                    name: '编辑分类'
                }
            ], // 当前页-【面包屑导航】

            myimgesupisshow: true, //form表单-imgesuploade上传图片
            myimgesup: {
                uploadsize: 2, //选择上传图片的展现框-1表示小框，2表示中等框，3表示大框
                listtype: 'picture', //list展示类型，text/picture/picture-card；默认不填为text
                showfilelist: true, //list展示列表是否显示，显示true，隐藏false
                ismust: true, //是否必填，true表示必填
                action: '/admin/upload/openFile', //upload上传API地址
                headers: { Auth: localStorage.getItem('token') }, //upload上传headers
                multiple: false, //上传多个文件
                title: '点击上传', //button-按钮文字
                title_type: 'primary', //button-按钮显示样式,primary&text
                title_plain: true, //button-按钮是否为线性（plain）
                isshow: false, //button-按钮是否显示，注意：false表示显示，true表示不显示
                tips: '', //提示&建议文字
                limit: '1', //上传数量限制
                target_id: 100, //上传文件target_id ：图片分类，区分存储文件和存储时长，不传默认存储1天后过期；1-二维码； 2-打印文件； 3-文库文件； 4-课程文件； 5-广告主宣传页； 6-广告主产生的pdf文件； 7-证件照文件； 50-设备广告； 100-通用；
                rules: {
                    images: {
                        image_width: 0, //图片宽度，0表示不限宽
                        image_height: 0, //图片高度，0表示不限高
                        size: 10000, //文件大小（单位kb）
                        type: ['image/jpeg', 'image/jpg', 'image/png'], //文件格式
                        typechart: 'png/jpg/jpeg' //文件格式提示规则
                    }
                }
            },

            gettype: [], //分类-平台
            gettypechild: [], // 分类-子分类
            ruleForm: {
                cate_name: '',
                version: '',
                version_data: '',
                disabled: true,
                icon: '',
                sort: '',
                status: ref('1')
            }, //form表单
            rules: {
                cate_name: [
                    {
                        required: true,
                        message: '分类名称为必填项',
                        trigger: 'blur'
                    }
                ],
                version: [
                    {
                        required: true,
                        message: '上级分类为必选项',
                        trigger: 'blur'
                    }
                ],
                status: [
                    {
                        required: true,
                        message: '是否显示为必选项',
                        trigger: 'blur'
                    }
                ]
            }, //form表单-必填规则

            mypowerlimits: JSON.parse(localStorage.getItem('powerlimits')), //获取权限
            mytoken: localStorage.getItem('token'), //获取存储的token

            loading: false
        }
    },
    methods: {
        // 选择上级分类-末级
        handleChange(data) {
            if (data.length == 4) {
                ElMessage({
                    type: 'warning',
                    message: '上级分类最多选择到第3级'
                })
            }
        },
        //获取upload子组件回传的数据
        getUploadFileEvent(data) {
            console.log('当前获取的回传数据：', data)
            this.ruleForm.icon = data.url
        },
        // 删除上传的文件-事件（upload组件）
        deluploadfileEvent(data) {
            console.log('当前删除事件-返回的数据：', data)
            this.ruleForm.icon = ''
        },
        //用于下拉框-点击&选择事件-控制框是否可选择
        finduserSaleList(data) {
            console.log(data)
            if (data.child) {
                this.ruleForm.disabled = false
                this.gettypechild = data.child
            } else {
                this.ruleForm.disabled = true
                this.ruleForm.version_data = ''
            }
        },
        //提交form表单-并判断是否必填
        submitForm(formName) {
            var getdatas = this.$refs[formName].model
            this.$refs[formName].validate((valid) => {
                if (valid) {
                    var pid = ''
                    if (getdatas.version_data == '') {
                        pid = getdatas.version
                    } else {
                        pid = getdatas.version_data.pop()
                    }
                    axios
                        .post(
                            '/manage/SharePrintSource/editCate',
                            {
                                pid: pid,
                                cate_name: getdatas.cate_name,
                                sort: getdatas.sort,
                                icon: getdatas.icon,
                                status: getdatas.status,
                                id: this.$route.query.id
                            },
                            {
                                headers: {
                                    Auth: localStorage.getItem('token')
                                }
                            }
                        )
                        .then((response) => {
                            if (response.data.code === 0) {
                                ElMessage({
                                    type: 'success',
                                    message: '提交成功！',
                                    duration: 1000,
                                    onClose: () => {
                                        this.$router.back() // 返回上一页
                                    }
                                })
                            }
                        })
                        .catch(function(error) {
                            console.log(error)
                        })
                } else {
                    console.log('error submit!!')
                    return false
                }
            })
        },
        //重置form表单中填写的数据
        resetForm() {
            this.$router.back()
        },

        // 递归刷id
        getidsEvent(child, contrast, ids) {
            if (child) {
                for (let i = 0; i < child.length; i++) {
                    let id = child[i].id
                    if (id != contrast) {
                        ids.push(id)
                        let result = this.getidsEvent(child[i].child, contrast, ids)
                        // 递归中-数据的返回，这一步很重要！！！
                        if (result) {
                            return result
                        }
                    } else {
                        ids.push(id)
                        return ids
                    }
                }
            }
        }
    },
    created() {
        if (localStorage.getItem('token') === null || !localStorage.getItem('token')) {
            this.isshow = false
            this.$router.push('/').catch((error) => error)
        } else {
            this.loading = true
            //通过API获取配置数据-无限级分类列表
            axios
                .post(
                    '/manage/SharePrintSource/sourceCateInfo',
                    {
                        id: -1
                    },
                    {
                        headers: {
                            Auth: localStorage.getItem('token')
                        }
                    }
                )
                .then((response) => {
                    var getdata = response.data
                    this.gettype = getdata
                })
                .catch(function(error) {
                    // 请求失败处理
                    console.log(error)
                })

            let routerinfo = this.$route.query
            // 分类-返显
            setTimeout(() => {
                this.finduserSaleList(this.gettype[routerinfo.platnum - 1])
                let getversiondata = []
                let versiondata = []
                // 判断-是否显示为【是】时，分类即可显示
                if (routerinfo.status == 1) {
                    getversiondata = this.getidsEvent(this.gettype[routerinfo.platnum - 1].child, routerinfo.id, [])
                    let level = ''
                    if (routerinfo.level == '一级') {
                        level = 1
                    }
                    if (routerinfo.level == '二级') {
                        level = 2
                    }
                    if (routerinfo.level == '三级') {
                        level = 3
                    }
                    if (routerinfo.level == '四级') {
                        level = 4
                    }
                    versiondata = getversiondata.slice(getversiondata.length - level, getversiondata.length)
                }
                // 返显数据
                this.ruleForm = {
                    cate_name: routerinfo.cate_name,
                    version: routerinfo.platnum ? parseInt(routerinfo.platnum) : '',
                    version_data: versiondata ? versiondata.slice(0, -1) : [],
                    icon: routerinfo.icon,
                    icon_list: routerinfo.icon ? [{ url: routerinfo.icon, name: routerinfo.cate_name }] : [],
                    sort: routerinfo.sort,
                    status: ref(routerinfo.status)
                }

                this.loading = false
            }, 1000)
        }
    },
    components: {
        mainone,
        mainimgesup
    }
}
</script>

<style scoped>
.box {
    background-color: #ffffff;
    border-radius: 4px;
    color: #333333;
    padding: 10px 20px;
}

.box_title {
    line-height: 40px;
    height: 40px;
}

.box_title_font {
    float: left;
    font-weight: 400;
    font-size: 14px;
    color: #333333;
}

.box_title_button {
    float: right;
    font-weight: 400;
    font-size: 14px;
    color: #94a3d2;
}

.box_title_button:hover {
    cursor: pointer;
}

.box_content {
    border-top: 1px solid #eeeeee;
    margin-top: 10px;
}
</style>
