<template>
    <div>
        <mainone :mainonebread="this.mainonebread"></mainone>
        <div v-loading="loading" element-loading-text="正在飞速加载中...">
            <!--标题-->
            <div class="box" style="">
                <div class="box_title" style="">
                    <div class="box_title_font">编辑课件</div>
                    <div class="box_title_button"></div>
                </div>
                <!--内容-->
                <div class="box_content">
                    <el-form ref="ruleForm" :model="ruleForm" :rules="rules" label-width="120px" class="demo-ruleForm" style="margin-top: 20px">
                        <div>
                            <el-row>
                                <!--占位符-->
                                <el-col :span="24" :xs="24" :sm="24" :lg="6">
                                    <div style="width: 100%"></div>
                                </el-col>
                                <el-col :span="24" :xs="24" :sm="24" :lg="11">
                                    <el-form-item label="课件名称：" prop="title">
                                        <el-input v-model="ruleForm.title" clearable></el-input>
                                    </el-form-item>
                                    <el-form-item label="课件分类：" prop="category">
                                        <el-select v-model="ruleForm.category" placeholder="请选择" style="width: 100%" @visible-change="finduserSaleList()">
                                            <el-option :label="getsales.sort_key" :value="getsales.sort_val" v-for="(getsales, i) in ruleForm.category_data" :key="i"></el-option>
                                        </el-select>
                                    </el-form-item>
                                    <el-form-item label="课件格式：" prop="type">
                                        <el-radio-group @change="switchType" v-model="ruleForm.type">
                                            <el-radio label="1">文档</el-radio>
                                            <el-radio label="3">视频</el-radio>
                                            <el-radio label="2">音频</el-radio>
                                        </el-radio-group>
                                    </el-form-item>
                                    <el-form-item label="附件：" prop="file_url">
                                        <mainimgesup
                                            v-model="ruleForm.file_url"
                                            :myimgesup="myimgesup"
                                            :myimgesupisshow="myimgesupisshow"
                                            :filelist="this.filelist"
                                            v-on:getuploadfile="(data) => getUploadFileEvent(data)"
                                            v-on:deluploadfile="(data) => delUploadFileEvent(data)"
                                        >
                                        </mainimgesup>
                                    </el-form-item>
                                    <el-form-item label="是否试看：" prop="is_try_read">
                                        <el-radio-group v-model="ruleForm.is_try_read">
                                            <el-radio label="1">是</el-radio>
                                            <el-radio label="2">否</el-radio>
                                        </el-radio-group>
                                    </el-form-item>
                                    <el-form-item>
                                        <el-button type="primary" @click="submitForm('ruleForm')" style="width: 40%; margin-right: 10px">提交 </el-button>
                                        <el-button @click="resetForm('ruleForm')">取消</el-button>
                                    </el-form-item>
                                </el-col>
                                <!--占位符-->
                                <el-col :span="24" :xs="24" :sm="24" :lg="7">
                                    <div style="width: 100%"></div>
                                </el-col>
                            </el-row>
                        </div>
                    </el-form>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import mainone from '@/components/Mainone.vue'
import mainimgesup from '@/components/Mainimgesup.vue'
import { ElMessage } from 'element-plus'
import { ref } from 'vue'
import axios from 'axios'

export default {
    name: 'curadd',
    data() {
        return {
            mainonebread: [
                {
                    name: '教育'
                },
                {
                    name: '课程管理'
                },
                {
                    name: '课件列表',
                    path: '/coursewarelist'
                },
                {
                    name: '编辑课件'
                }
            ], // 当前页-【面包屑导航】

            myimgesupisshow: true, //form表单-imgesuploade上传图片
            myimgesup: {
                uploadsize: 1, //选择上传图片的展现框-1表示小框，2表示中等框，3表示大框
                listtype: 'text', //list展示类型，text/picture/picture-card；默认不填为text
                ismust: true, //是否必填，true表示必填
                action: '/admin/upload/file', //upload上传API地址
                headers: { Auth: localStorage.getItem('token') }, //upload上传headers
                title: '点击上传', //button-按钮文字
                tips: '[文档]支持格式：*.doc, *.docx, *.ppt, *.pptx, *.pdf', //提示&建议文字
                limit: '1', //上传数量限制
                target_id: 4, //上传文件target_id ：图片分类，区分存储文件和存储时长，不传默认存储1天后过期；1-二维码； 2-打印文件； 3-文库文件； 4-课程文件； 5-广告主宣传页； 6-广告主产生的pdf文件； 7-证件照文件； 50-设备广告； 100-通用；
                rules: {
                    files: {
                        size: 100000, //文件大小（单位kb）
                        type: [
                            'application/msword',
                            'application/mspowerpoint',
                            'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
                            'application/vnd.ms-powerpoint',
                            'application/vnd.openxmlformats-officedocument.presentationml.presentation',
                            'application/pdf'
                        ], //文件格式
                        typechart: 'doc/docx/ppt/pptx/pdf' //文件格式提示规则
                    }
                }
            },
            filelist: [],

            // form表单-数据
            ruleForm: {
                title: '',
                category: '',
                category_data: [],
                type: ref('1'),
                file_url: '',
                time_length: 0,
                pages: 0,
                is_try_read: ref('1')
            },
            // form表单-判断校验
            rules: {
                title: [
                    {
                        required: true,
                        message: '课件名称为必填项',
                        trigger: 'blur'
                    }
                ],
                category: [
                    {
                        required: true,
                        message: '课件分类为必选项',
                        trigger: 'blur'
                    }
                ],
                type: [
                    {
                        required: true,
                        message: '课件格式为必选项',
                        trigger: 'change'
                    }
                ],
                file_url: [
                    {
                        required: true,
                        message: '附件不能为空',
                        trigger: 'blur'
                    }
                ],
                is_try_read: [
                    {
                        required: true,
                        message: '是否试看状态为必选项',
                        trigger: 'change'
                    }
                ]
            }, //form表单-必填规则

            mypowerlimits: JSON.parse(localStorage.getItem('powerlimits')), //获取权限
            mytoken: localStorage.getItem('token'), //获取存储的token

            loading: false
        }
    },
    methods: {
        // 【课件分类】获取下拉框数据
        finduserSaleList() {
            axios
                .post(
                    '/manage/Course/selectCoursewareCate',
                    {},
                    {
                        headers: {
                            Auth: localStorage.getItem('token')
                        }
                    }
                )
                .then((response) => {
                    this.ruleForm.category_data = response.data.result.map((item) => {
                        return { sort_key: item.name, sort_val: item.code }
                    })
                })
                .catch(function(error) {
                    console.log(error)
                })
        },
        // 【课件格式】-选择-判断校验条件
        switchType(e) {
            if (e == 1) {
                this.myimgesup.rules = {
                    files: {
                        size: 100000, //文件大小（单位kb）
                        type: [
                            'application/msword',
                            'application/mspowerpoint',
                            'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
                            'application/vnd.ms-powerpoint',
                            'application/vnd.openxmlformats-officedocument.presentationml.presentation',
                            'application/pdf'
                        ], //文件格式
                        typechart: 'doc/docx/ppt/pptx/pdf' //文件格式提示规则
                    }
                }
                this.myimgesup.tips = '[文档]支持格式：*.doc, *.docx, *.ppt, *.pptx, *.pdf'
            }
            if (e == 3) {
                this.myimgesup.rules = {
                    video: {
                        size: 500000, //文件大小（单位kb）
                        type: ['video/mp4', 'video/3gpp', 'audio/x-mpegurl'], //文件格式
                        typechart: 'mp4/3gp/m3u8' //文件格式提示规则
                    }
                }
                this.myimgesup.tips = '[视频]支持格式：*.mp4, *.3gp, *.m3u8'
            }
            if (e == 2) {
                this.myimgesup.rules = {
                    audio: {
                        size: 200000, //文件大小（单位kb）
                        type: ['audio/mpeg', 'audio/vnd.dlna.adts', 'audio/x-wav'], //文件格式
                        typechart: 'mp3/acc/wav' //文件格式提示规则
                    }
                }
                this.myimgesup.tips = '[音频]支持格式：*.mp3, *.acc, *.wav'
            }
            // 切换-删除附件文件数据
            this.delUploadFileEvent()
        },
        // 【附件】获取upload子组件回传的数据
        getUploadFileEvent(data) {
            // console.log("当前获取的回传数据：", data);
            this.ruleForm.file_url = data.res.requestUrls[0]
            this.filelist = [{ name: data.name, url: data.res.requestUrls[0] }]
            // 判断属于视频||音频-填充时长
            if (['mp4', '3gp', 'm3u8', 'mp3', 'acc', 'wav'].indexOf(data.type) != -1) {
                this.ruleForm.time_length = data.fileLen
            }
            //特殊的内容再次进行了校验，如果符合检验规则，错误提示就是消失了。
            this.$refs.ruleForm.validateField('file_url', (valid) => {
                if (!valid) {
                    return
                }
            })
        },
        // 【附件】删除当前的文件数据行
        delUploadFileEvent(data) {
            // console.log("当前删除事件-返回的数据：", data);
            console.log(data)
            this.ruleForm.file_url = ''
            this.filelist = []
        },
        //提交form表单-并判断是否必填
        submitForm(formName) {
            let getdatas = this.$refs[formName].model
            this.$refs[formName].validate((valid) => {
                if (valid) {
                    axios
                        .post(
                            '/manage/Course/editCourseware',
                            {
                                id: this.$route.query.id,
                                title: getdatas.title,
                                category: getdatas.category,
                                type: getdatas.type,
                                file_url: getdatas.file_url,
                                is_try_read: getdatas.is_try_read,
                                time_length: getdatas.time_length,
                                pages: getdatas.pages
                            },
                            {
                                headers: {
                                    Auth: localStorage.getItem('token')
                                }
                            }
                        )
                        .then((response) => {
                            if (response.data.code === 0) {
                                ElMessage({
                                    type: 'success',
                                    message: '添加成功！',
                                    duration: 1000,
                                    onClose: () => {
                                        this.$router.back()
                                    }
                                })
                            }
                        })
                        .catch(function(error) {
                            console.log(error)
                        })
                } else {
                    console.log('error submit!!')
                    return false
                }
            })
        },
        //重置form表单中填写的数据
        resetForm(formName) {
            this.$refs[formName].resetFields()
            this.$router.back()
        }
    },
    created() {
        if (localStorage.getItem('token') === null || !localStorage.getItem('token')) {
            this.isshow = false
            this.$router.push('/').catch((error) => error)
        } else {
            this.loading = true

            this.finduserSaleList()

            //通过Ajax向后台获取数据
            axios
                .post(
                    '/manage/Course/viewCourseware',
                    {
                        id: this.$route.query.id
                    },
                    {
                        headers: {
                            Auth: localStorage.getItem('token')
                        }
                    }
                )
                .then((response) => {
                    let routedatas = response.data.result
                    this.switchType(routedatas.type.toString())

                    this.ruleForm.title = routedatas.title
                    this.ruleForm.category = parseInt(routedatas.category)
                    this.ruleForm.type = ref(routedatas.type.toString())
                    this.ruleForm.file_url = routedatas.file_url
                    this.ruleForm.time_length = routedatas.time_length
                    this.ruleForm.pages = routedatas.pages
                    this.ruleForm.is_try_read = ref(routedatas.is_try_read.toString())

                    // 返显-附件-文件
                    let getfile = routedatas.file_url.split('/')
                    let getfilename = getfile[getfile.length - 1]
                    this.filelist = [{ name: getfilename, url: routedatas.file_url }]

                    this.loading = false
                })
                .catch(function(error) {
                    console.log(error)
                    this.loading = false
                })
        }
    },
    components: {
        mainone,
        mainimgesup
    }
}
</script>

<style scoped>
.box {
    background-color: #ffffff;
    border-radius: 4px;
    color: #333333;
    padding: 10px 20px;
}

.box_title {
    line-height: 40px;
    height: 40px;
}

.box_title_font {
    float: left;
    font-weight: 400;
    font-size: 14px;
    color: #333333;
}

.box_title_button {
    float: right;
    font-weight: 400;
    font-size: 14px;
}

.box_title_button:hover {
    cursor: pointer;
}

.box_content {
    border-top: 1px solid #eeeeee;
    margin-top: 10px;
}
</style>
