<template>
    <div>
        <mainone :mainonebread="mainonebread"></mainone>
        <div v-loading="loading" element-loading-text="正在飞速加载中...">
            <!--标题-->
            <div class="box" style="">
                <div class="box_title" style="">
                    <div class="box_title_font">菜单工具栏</div>
                    <div class="box_title_button"></div>
                </div>
                <!--内容-->
                <div class="box_content">
                    <el-row style="margin-top: 20px">
                        <!--占位符-->
                        <el-col :span="24" :xs="24" :sm="24" :lg="2">
                            <div style="width: 100%"></div>
                        </el-col>
                        <!--主表样式-->
                        <el-col :span="24" :xs="24" :sm="24" :lg="18">
                            <!-- 添加按钮 -->
                            <div style="margin-bottom: 20px;">
                                <el-button type="primary" plain style="width: 200px" @click="clickAddToolsEvent(tableData)">添加工具栏 </el-button>
                            </div>
                            <!-- form表单 -->
                            <el-form ref="tableData" :model="tableData" :rules="formdatarules" label-width="0px" class="demo-ruleForm">
                                <el-form-item>
                                    <div style="margin-bottom: 20px">
                                        <el-table :data="tableData" style="width: 100%" border>
                                            <el-table-column v-for="(item, index) in this.formdatatitle" :key="index" :prop="item.prop" :label="item.label">
                                                <template #default="scope" v-if="item.prop === 'title' || item.prop === 'show_title' || item.prop === 'sort'">
                                                    <!-- 工具栏名称 -->
                                                    <div v-if="item.prop === 'title'" style="height: 60px;margin-top: 20px;">
                                                        <el-form-item :prop="scope.$index + '.title'" :rules="formdatarules.title">
                                                            <el-input v-model="scope.row.title" clearable></el-input>
                                                        </el-form-item>
                                                    </div>
                                                    <!-- 常用工具 -->
                                                    <div v-if="item.prop === 'show_title'" style="height: 60px;margin-top: 20px;">
                                                        <el-form-item :prop="scope.$index + '.show_title'" :rules="formdatarules.show_title">
                                                            <el-input v-model="scope.row.show_title" clearable></el-input>
                                                        </el-form-item>
                                                    </div>
                                                    <!-- 排序 -->
                                                    <div v-if="item.prop === 'sort'" style="height: 60px;margin-top: 20px;">
                                                        <el-form-item :prop="scope.$index + '.sort'" :rules="formdatarules.sort">
                                                            <el-input v-model="scope.row.sort" clearable></el-input>
                                                        </el-form-item>
                                                    </div>
                                                </template>
                                            </el-table-column>
                                            <!-- 操作 -->
                                            <el-table-column fixed="right" label="操作" prop="myoperation" id="operation" width="80px">
                                                <template #default="scope">
                                                    <!--操作：按照页面区分，且每个按钮对应相关函数-->
                                                    <div>
                                                        <!-- 删除 -->
                                                        <el-button @click="clickDelToolsEvent(scope.$index, scope.row)" type="danger" size="mini" icon="el-icon-delete"></el-button>
                                                    </div>
                                                </template>
                                            </el-table-column>
                                        </el-table>
                                    </div>
                                </el-form-item>
                                <el-form-item style="text-align: center;">
                                    <el-button type="primary" @click="submitForm('tableData', tableData)" style="width: 20%; max-width: 200px; min-width: 80px; margin-right: 10px"
                                        >提交
                                    </el-button>
                                    <el-button @click="resetForm()">取消</el-button>
                                </el-form-item>
                            </el-form>
                        </el-col>
                        <!--占位符-->
                        <el-col :span="24" :xs="24" :sm="24" :lg="4">
                            <div style="width: 100%"></div>
                        </el-col>
                    </el-row>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import mainone from '@/components/Mainone'
import { ElMessageBox, ElMessage } from 'element-plus'
import axios from 'axios'

export default {
    name: 'vtoolseidt',
    data() {
        // 文本input框-校验（文本-值中不能有空格）【常用工具||】
        var checkInputEventWord = (rule, value, callback) => {
            if (!value) {
                return callback(new Error('必填项不能为空'))
            }
            if (value.toString().indexOf(' ') != -1) {
                return callback(new Error('值中不能有空格'))
            }
            setTimeout(() => {
                callback()
            }, 500)
        }
        // 数字input框-校验-(仅整数)【排序||】
        var checkInputEventInt = (rule, value, callback) => {
            if (!value && value != 0) {
                return callback(new Error('必填项不能为空'))
            }
            if (value.toString().indexOf(' ') != -1) {
                return callback(new Error('值中不能有空格'))
            }
            setTimeout(() => {
                // 判断字符串是否存在小数点
                let result = value.toString().indexOf('.')
                if (result != -1) {
                    callback(new Error('请输入整数'))
                } else {
                    // 判断字符串是否为文字
                    if (isNaN(value)) {
                        callback(new Error('请输入整数'))
                    } else {
                        // 判断字符串是否为数字
                        if (!Number.parseInt(value) && Number.parseInt(value) != 0) {
                            callback(new Error('请输入整数'))
                        } else {
                            if (value < 0) {
                                callback(new Error('值需大于0'))
                            } else {
                                callback()
                            }
                        }
                    }
                }
            }, 500)
        }
        return {
            mainonebread: [
                {
                    name: '设置'
                },
                {
                    name: '店铺'
                },
                {
                    name: '个人中心',
                    path: '/personalinfo'
                },
                {
                    name: '编辑工具栏',
                    path: '/toolsedit'
                }
            ], // 当前页-【面包屑导航】

            // form表单中-table标题
            formdatatitle: [
                {
                    prop: 'title',
                    label: '工具栏名称'
                },
                {
                    prop: 'show_title',
                    label: '显示名称'
                },
                {
                    prop: 'sort',
                    label: '排序'
                }
            ],
            // form表单中-table数据
            tableData: [],
            formdatarules: {
                title: [
                    {
                        required: true,
                        validator: checkInputEventWord,
                        trigger: 'blur'
                    }
                ],
                show_title: [
                    {
                        required: true,
                        validator: checkInputEventWord,
                        trigger: 'blur'
                    }
                ],
                sort: [
                    {
                        required: true,
                        validator: checkInputEventInt,
                        trigger: 'blur'
                    }
                ]
            },

            mypowerlimits: JSON.parse(localStorage.getItem('powerlimits')), //获取权限
            mytoken: localStorage.getItem('token'), //获取存储的token

            loading: false
        }
    },
    created() {
        if (localStorage.getItem('token') === null || !localStorage.getItem('token')) {
            this.isshow = false
            this.$router.push('/').catch((error) => error)
        } else {
            this.loading = true
            // 获取相关配置配置-工具栏配置
            axios
                .post(
                    '/manage/config/info',
                    {
                        key_name: 'navigation_tool'
                    },
                    {
                        headers: {
                            Auth: localStorage.getItem('token')
                        }
                    }
                )
                .then((response) => {
                    if (response.data.code == 0) {
                        this.tableData = response.data.result
                    }
                    this.loading = false
                })
                .catch(function(error) {
                    console.log(error)
                    this.loading = false
                })
        }
    },
    methods: {
        // 添加-工具栏
        clickAddToolsEvent(data) {
            if (data.length > 0) {
                var getarray = []
                data.forEach((element) => {
                    getarray.push(element.id)
                })
                // 获取id中的最大值
                var maxnum = Math.max.apply(null, getarray)
                // 插入数据
                this.tableData.push({
                    id: maxnum + 1,
                    toolname: '',
                    showname: '',
                    sort: ''
                })
            } else {
                this.tableData.push({
                    id: 1,
                    toolname: '',
                    showname: '',
                    sort: ''
                })
            }
        },
        // 删除-工具栏
        clickDelToolsEvent(index, data) {
            ElMessageBox.confirm('确定删除:“' + data.title + '”吗？', '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning'
            })
                .then(() => {
                    axios
                        .post(
                            '/manage/nav/toolDel',
                            {
                                id: data.id
                            },
                            {
                                headers: {
                                    Auth: localStorage.getItem('token')
                                }
                            }
                        )
                        .then((response) => {
                            if (response.data.code === 0) {
                                ElMessage({
                                    type: 'success',
                                    message: '删除成功！',
                                    duration: 1000,
                                    onClose: () => {
                                        this.tableData.splice(index, 1)
                                    }
                                })
                            }
                        })
                        .catch(function(error) {
                            console.log(error)
                        })
                })
                .catch(() => {
                    ElMessage({
                        type: 'info',
                        message: '取消删除'
                    })
                })
        },
        //提交form表单-并判断是否必填
        submitForm(formName) {
            let getdatas = this.$refs[formName].model
            console.log(getdatas)
            this.$refs[formName].validate((valid) => {
                if (valid) {
                    axios
                        .post(
                            '/manage/nav/toolUpdate',
                            {
                                value: getdatas
                            },
                            {
                                headers: {
                                    Auth: localStorage.getItem('token')
                                }
                            }
                        )
                        .then((response) => {
                            if (response.data.code === 0) {
                                ElMessage({
                                    type: 'success',
                                    message: '提交成功！',
                                    duration: 1000,
                                    onClose: () => {
                                        this.$router.push('/personalinfo').catch((error) => error)
                                    }
                                })
                            }
                        })
                        .catch(function(error) {
                            console.log(error)
                        })
                } else {
                    console.log('error submit!!')
                    return false
                }
            })
        },
        //重置form表单中填写的数据
        resetForm() {
            this.$router.go(-1) // 返回上一页
        }
    },
    components: {
        mainone
    }
}
</script>

<style>
.box {
    background-color: #ffffff;
    border-radius: 4px;
    color: #333333;
    padding: 10px 20px;
}

.box_title {
    line-height: 40px;
    height: 40px;
}

.box_title_font {
    float: left;
    font-weight: 400;
    font-size: 14px;
    color: #333333;
}

.box_title_button {
    float: right;
    font-weight: 400;
    font-size: 14px;
    color: #94a3d2;
}

.box_title_button:hover {
    cursor: pointer;
}

.box_content {
    border-top: 1px solid #eeeeee;
    margin-top: 10px;
}
</style>
