<template>
    <div>
        <!-- 级联 -->
        <el-cascader
            v-model="modelValue"
            :placeholder="myformitem.placeholder"
            :options="myformitem.isaddress ? areacode_options : myformitem.options"
            :props="myformitem.props ? myformitem.props : {}"
            :collapse-tags="myformitem.collapsetags ? myformitem.collapsetags : false"
            :collapse-tags-tooltip="myformitem.collapsetagstooltip ? myformitem.collapsetagstooltip : false"
            @change="handleChange"
        ></el-cascader>
    </div>
</template>

<script>
import areacode from '@/assets/json/areacode.json'
export default {
    name: 'xwCascader',
    props: {
        model_value: {
            default: ''
        },
        model_props: {
            type: Object,
            default() {
                return {
                    placeholder: '请选择地区',
                    isaddress: true, // 判断是否为地址-当为地址时可不传 options
                    // options:[],
                    props: { multiple: false, checkStrictly: true, emitPath: false }, // tree层级props
                    collapsetags: true,
                    collapsetagstooltip: true
                }
            }
        }
    },
    watch: {
        model_value: {
            handler(newVal) {
                this.modelValue = newVal
            },
            deep: true,
            immediate: true
        },
        model_props: {
            handler(newVal) {
                this.myformitem = newVal
            },
            deep: true,
            immediate: true
        }
    },
    data() {
        return {
            // 地址options
            areacode_options: areacode,
            // 级联value
            modelValue: '',
            // 级联的属性
            myformitem: {}
        }
    },
    methods: {
        handleChange(data) {
            this.$emit('modelValue', data)
        }
    }
}
</script>
