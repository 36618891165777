<template>
    <div>
        <mainone :mainonebread="mainonebread"></mainone>
        <maintwo :maintwoiconshow="maintwoiconshow" :maintwoforminfo="maintwoforminfo" :myinputoneisshow="maintwoforminfo.myinputoneisshow" :myinputone="maintwoforminfo.myinputone" :myselectleftisshow="maintwoforminfo.selectleftisshow" :myselectleft="maintwoforminfo.selectleft" :myinputishow="myinputishow" :myinput="myinput" :myinputonefullisshow="maintwoforminfo.myinputonefullisshow" :myinputonefull="maintwoforminfo.myinputonefull" :myselectisshow="myselectisshow" :myselect="myselect" :myselectcityisshow="myselectcityisshow" :myselectcity="myselectcity" v-on:getsearchinfo="getSeachinfoEvent" v-on:searchForm="searchFormEvent"></maintwo>
        <!--table表单-->
        <div>
            <!--主list列表-->
            <div style="margin-top: 10px; background-color: #ffffff; border-radius: 4px; line-height: 40px; padding: 0 20px">
                <div style="line-height: 60px">
                    <!--左侧名称-->
                    <div style="float: left; font-size: 14px">数据列表</div>
                    <!--右侧按钮-->
                    <div style="float: right">
                        <!--自定义按钮-点击出现弹框&点击跳转子类页面@click="routeraddrClick('/XX路由')"-->
                        <!--1、点击出现弹框@click="addTanClick(XX弹框配置信息,dialogVisible = true);resetFormSenior(XXform表单)"-->
                        <!--2、点击跳转子类页面@click="routeraddrClick('/XX路由')-->
                        <div class="mybutton_box">
                            <div class="mybutton_box_btn" v-if="mypowerlimits.jbqgl_sc" style="margin-right: 10px">
                                <el-button type="danger" plain @click="batchDelete(this.multipleSelection)">批量删除</el-button>
                            </div>
                            <div class="mybutton_box_btn" v-if="mypowerlimits.jbqgl_xzdrmb">
                                <el-button type="primary" plain @click="changeDomeEvent()">下载导入模板</el-button>
                            </div>
                            <!-- Excel批量导入 -->
                            <div style="margin: 0 10px 0 10px">
                                <mainimgesup ref="uploadfile" :myimgesup="myimgesup" :myimgesupisshow="myimgesupisshow" v-on:getuploadfile="(data) => getUploadFileEvent(data)" v-if="mypowerlimits.jbqgl_pldrbq"> </mainimgesup>
                            </div>
                            <div></div>
                            <div class="mybutton_box_btn">
                                <el-button type="primary" plain @click="add('/resourcesglobaladd')" v-if="mypowerlimits.jbqgl_tj">添加</el-button>
                                <!--导出按钮-点击导出数据-->
                                <el-button type="warning" plain @click="exportDatas()">导出</el-button>
                            </div>
                        </div>
                    </div>
                </div>

                <div>
                    <commTable :loading="loading" element-loading-text="正在飞速加载中..." ref="mymultipleTableRef" :tabletitle="mainthreetabletitle" :tabledata="mainthreetableData" :totalcount="totalCount" :currentpage="currentPage" :pagesize="PageSize" :Lastpage="lastpage" :Operation="{ width: 140 }" @handleSelection="handleSelectionEvent" @handleSelectionOnline="handleSelectionChangeOnline">
                        <template #content="{ scope, prop }">
                            <!-- 状态-渲染数据 -->
                            <div v-if="prop === 'keyword'">
                                <div>
                                    <el-tooltip effect="dark" :content="scope.row.keyword" placement="top-start" popper-class="tool-tip">
                                        <div>{{ formatLen(scope.row.keyword, 100) }}</div>
                                    </el-tooltip>
                                </div>
                            </div>
                            <!-- 其他 -->
                            <div v-else>
                                <span>{{ scope.row[prop] }}</span>
                            </div>
                        </template>
                        <template #special="{ scope }">
                            <div>
                                <el-button type="primary" size="mini" @click="edit(scope.row)" v-if="mypowerlimits.jbqgl_bj">编辑</el-button>
                                <el-button @click="deleteRow(scope.$index, mainthreetableData)" type="danger" size="mini" icon="el-icon-delete" v-if="mypowerlimits.jbqgl_sc"></el-button>
                            </div>
                        </template>
                    </commTable>

                    <!--分页器-->
                    <div class="block" style="text-align: center; margin: 30px 0">
                        <el-pagination background @size-change="handleSizeChange" @current-change="handleCurrentChange" :current-page="currentPage" :page-sizes="pageSizes" :page-size="PageSize" layout="total, sizes, prev, pager, next, jumper" :total="totalCount"> </el-pagination>
                    </div>
                </div>
            </div>
            <!--  添加 编辑  -->
            <TagsForm :tagsData="tagsData" :tagGrounpList="tagGrounpList" @success="successEvent"></TagsForm>
        </div>
    </div>
</template>

<script>
import mainone from '@/components/Mainone';
import maintwo from '@/components/Maintwo';
import mainimgesup from '@/components/Mainimgesup.vue';
import TagsForm from './components/tagsForm.vue';
import commTable from './components/commTable.vue';
import { ElMessageBox, ElMessage, ElNotification } from 'element-plus';
import axios from 'axios';
import { defineComponent } from 'vue';

export default defineComponent({
    name: 'vresourcesglobal',
    data() {
        return {
            mainonebread: [
                {
                    name: '打印',
                },
                {
                    name: '标签管理',
                },
                {
                    name: '标签管理',
                },
            ], // 当前页-【面包屑导航】
            maintwoiconshow: false, // 筛选-【高级筛选按钮】
            maintwoforminfo: {
                myinputoneisshow: true, //筛选查询-左侧字+input
                myinputone: {
                    divinterval: '0', //框右侧距离
                    titlewidth: '74px', //标题宽度
                    inputwidth: '200px', //input宽度
                    inputinterval: '20px', //input右侧距离
                    input: [
                        {
                            ismust: false, //是否必填，true表示必填
                            title: '标签名称：',
                            placeholder: '标签名称',
                            name: 'name',
                            content: '',
                        },
                    ],
                },
                selectleftisshow: true, //筛选查询-左侧字+select单框||select时间
                selectleft: {
                    width: 'none',
                    divinterval: '', //框右侧距离
                    selectwidth: '200px',
                    selectinterval: '20px', //select右侧距离
                    select: [
                        {
                            selectisshow: true,
                            ismust: false,
                            title: '所属标签组：',
                            placeholder: '所属标签组',
                            name: 'pid',
                            value: '',
                            options: [
                                {
                                    value: '0',
                                    label: '全部',
                                },
                            ],
                        },
                    ],
                },
            }, // 筛选-控件信息
            mainthreeisshowbut: true, // 数据列表中-【添加按钮】
            mainthreeadddata: '/resourcesglobaladd', //点击添加按钮-跳转路由
            mainthreeisshowset: true, // 数据列表中-复选框
            mainthreeisshowope: true, // 数据列表中-操作-根据页面显示模块（整页）
            mainthreetableope: 10, // 数据列表中-操作-根据页面显示控件（整页）
            mainthreehandle: 120, // 数据列表中-操作-宽度
            mainthreetabletitle: [
                {
                    fixedstatu: 'left',
                    prop: 'id',
                    label: 'ID',
                    width: '100',
                },
                {
                    fixedstatu: false,
                    prop: 'name',
                    label: '标签名称',
                    width: '200',
                },
                {
                    fixedstatu: false,
                    prop: 'groupName',
                    label: '所属标签组',
                    width: '300',
                },
                {
                    fixedstatu: false,
                    prop: 'keyword',
                    label: '关键词',
                    width: '',
                },
            ], // 数据列表中-数据表头
            mainthreestatus: true, // 数据列表中-属于search按钮表示状态
            mainthreetableData: [], // 数据列表中-具体数据
            totalCount: 1, //数据列表中-数据总条数
            lastpage: 1, //数据列表中-数据总页数（默认以20条一页）

            //table表单相关数据
            seniorform: {},
            currentPage: 1,
            pageSizes: [20, 50, 100, 1000],
            PageSize: 20,
            multipleSelection: [],
            thispagename: '', //通过路由获取当前页面的path值
            searchwidth: '580px',
            tancontent: {
                title: '弹框标题',
                content: {},
            }, //弹框

            //上传-【Excel批量退货】-imgesuploade上传图片
            myimgesupisshow: true,
            //上传-【Excel批量退货】-Excel批量退货-文件
            myimgesup: {
                uploadsize: 2, //选择上传图片的展现框-1表示小框，2表示中等框，3表示大框
                listtype: 'picture', //list展示类型，text/picture/picture-card；默认不填为text
                showfilelist: false, //list展示列表是否显示，显示true，隐藏false
                ismust: true, //是否必填，true表示必填
                action: '/manage/Equipment/tagUploadTemplate', //upload上传API地址
                headers: { Auth: localStorage.getItem('token') }, //upload上传headers
                multiple: false, //上传多个文件
                title: '批量导入标签', //button-按钮文字
                title_type: 'primary', //button-按钮显示样式,primary&text
                title_size: 'medium', //button-按钮大小，medium / small / mini
                title_plain: true, //button-按钮是否为线性（plain）
                isshow: false, //button-按钮是否显示，注意：false表示显示，true表示不显示
                tips: '', //提示&建议文字
                limit: '', //上传数量限制
                target_id: 100, //上传文件target_id ：图片分类，区分存储文件和存储时长，不传默认存储1天后过期；1-二维码； 2-打印文件； 3-文库文件； 4-课程文件； 5-广告主宣传页； 6-广告主产生的pdf文件； 7-证件照文件； 50-设备广告； 100-通用；
                rules: {
                    files: {
                        size: 10000, //文件大小（单位kb）
                        type: ['application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'], //文件格式
                        typechart: 'Excel', //文件格式提示规则
                    },
                },
            },

            mypowerlimits: JSON.parse(localStorage.getItem('powerlimits')), //获取权限
            mytoken: localStorage.getItem('token'), //获取存储的token
            pagesdatas: [], //存储最近一次的筛选条件，供分页使用
            tagsData: {
                data: {}
            },
            tagGrounpList: [],
            loading: false,
        };
    },
    mounted() {
        //获取当前页面路由path值
        this.thispagename = this.$route.path;
    },
    methods: {
        // 批量删除
        batchDelete(datas) {
            const ids = datas.map((item) => item.id);
            ElMessageBox.confirm('确定删除吗？', '提示：', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'primary',
            }).then(() => {
                    axios.post('/manage/Equipment/tagDelete',
                            {
                                id: ids,
                            },
                            {
                                headers: {
                                    Auth: localStorage.getItem('token'),
                                },
                            }
                        )
                        .then((response) => {
                            if (response.data.code === 0) {
                                this.multipleSelection = []
                                ElMessage({
                                    type: 'success',
                                    message: response.data.message,
                                    duration: 1000,
                                    onClose: () => {
                                        this.successEvent();
                                    },
                                });
                            }
                        })
                        .catch(function (error) {
                            console.log(error);
                        });
                })
                .catch(() => {
                    ElMessage({
                        type: 'info',
                        message: '已取消',
                    });
                });
        },
        // **************多选**************
        // 深拷贝
        deepcopy(obj, cache = []) {
            function find(list, f) {
                return list.filter(f)[0];
            }

            // just return if obj is immutable value
            if (obj === null || typeof obj !== 'object') {
                return obj;
            }

            // if obj is hit, it is in circular structure
            const hit = find(cache, (c) => c.original === obj);
            if (hit) {
                return hit.copy;
            }

            const copy = Array.isArray(obj) ? [] : {};
            // put the copy into cache at first
            // because we want to refer it in recursive deepCopy
            cache.push({
                original: obj,
                copy,
            });

            Object.keys(obj).forEach((key) => {
                copy[key] = this.deepcopy(obj[key], cache);
            });

            return copy;
        },
        // 去重
        reduce(datas) {
            let obj = {};
            let reduce = [];
            reduce = datas.reduce(function (item, next) {
                //item为没有重复id的数组，next为当前对象
                obj[next.id] ? '' : (obj[next.id] = true && item.push(next));
                return item;
            }, []);

            return reduce;
        },
        // 多选数据-全选
        handleSelectionEvent(datas) {
            var all_datas = [];
            let { data, rowdatas } = datas;
            if (data.length > 0) {
                // 全选
                all_datas = [...this.multipleSelection, ...this.deepcopy(data)];
            } else {
                // 取消全选
                let ids = rowdatas.map((n) => {
                    return n.id;
                });
                this.multipleSelection = this.multipleSelection.filter((n) => ids.indexOf(n.id) == -1);
                all_datas = [...this.multipleSelection];
            }
            // 去重
            this.multipleSelection = this.reduce(all_datas);
        },
        // 多选数据-单选
        handleSelectionChangeOnline({ datas, row }) {
            this.multipleSelection = this.multipleSelection.filter((n) => n.id != row.id);
            this.handleSelectionEvent({ data: datas, rowdatas: this.mainthreetableData });
        },
        // 多选-检查反显
        moreDataBackShow(datas) {
            // console.log(this.$refs.mymultipleTableRef)
            if (this.$refs.mymultipleTableRef) {
                // console.log('fanxian:', datas)
                this.$refs.mymultipleTableRef.backShow(datas, this.mainthreetableData);
            }
        },
        // **************************************************

        // 【下载批量退货模板】
        changeDomeEvent() {
            axios
                .get('/manage/Equipment/downTemplate', {
                    headers: {
                        Auth: localStorage.getItem('token'),
                    },
                    params: {
                        templateName: 'batch_tag_template',
                    },
                })
                .then((response) => {
                    if (response.data.code == 0) {
                        let url = response.data.result.download;
                        window.open(url, '_blank');
                    }
                })
                .catch(function (error) {
                    console.log(error);
                });
        },

        // 【批量导入标签】-获取upload子组件回传的数据-file文件url等数据
        getUploadFileEvent(data) {
            console.log('当前获取的回传数据：' + JSON.stringify(data))
            let messages = ''
            let total = Number(this.totalCount) + Number(data.succ)
            if (data.failLine.length != 0) {
                messages =
                    '<div><div>导入条数：' +
                    data.total +
                    '</div><div>成功条数：' +
                    data.succ +
                    '</div><div>失败条数：' +
                    data.fail +
                    '</div><div>失败原因：第' +
                    data.failLine.join('、') +
                    '行存在标签组/标签名称/关键词/触发值有误导入失败' + 
                    (total >= 3000 ? '，导入不能超过3000' : '') +
                    '</div></div>'
            } else {
                messages = '<div><div>导入条数：' + data.total + '</div><div>成功条数：' + data.succ + '</div><div>失败条数：' + data.fail + '</div></div>'
            }
            // 通知
            ElNotification({
                title: 'Excel批量导入-反馈',
                dangerouslyUseHTMLString: true,
                duration: 8000,
                message: messages,
                type: 'success'
            })
            // 刷新列表
            this.successEvent()
            // 删除upload列队中的数据
            this.$refs.uploadfile.handleRemove(data)
        },

        // table表单-状态改变
        changeStatus(data) {
            console.log('切换状态的data值：' + data.status);
            axios
                .post(
                    '/manage/Equipment/worldImgSwitch',
                    {
                        id: data.id,
                    },
                    {
                        headers: {
                            Auth: localStorage.getItem('token'),
                        },
                    }
                )
                .then((response) => {
                    if (response.data.code === 0) {
                        ElMessage({
                            type: 'success',
                            message: response.data.message,
                        });
                    } else {
                        // 切换状态失败-复原状态
                        if (data.status == 1) {
                            data.status = -1;
                        } else {
                            data.status = 1;
                        }
                    }
                })
                .catch(function (error) {
                    console.log(error);
                });
        },
        //table表单-点击单位格-带参跳转等
        // handleOneData(row, column, cell, event) {
        // console.log(row); //获取当前行数据
        // console.log(column); //获取当前单位格信息 ，通过column.property可获取当前单元的title得name值
        // console.log(event); //获取当前指针事件
        // console.log(event.path[1].id); //获取当前指针所指向的单元格id值，配合标签的id使用
        // },
        //table表-操作中-删除按钮（函数）
        deleteRow(index, rows) {
            ElMessageBox.confirm('确定删除吗？', '提示：', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'primary',
            })
                .then(() => {
                    axios
                        .post(
                            '/manage/Equipment/tagDelete',
                            {
                                id: rows[index].id,
                            },
                            {
                                headers: {
                                    Auth: localStorage.getItem('token'),
                                },
                            }
                        )
                        .then((response) => {
                            if (response.data.code === 0) {
                                ElMessage({
                                    type: 'success',
                                    message: response.data.message,
                                    duration: 1000,
                                    onClose: () => {
                                        //在table表中删除当前行信息，而rows.splice(index, 1)仅属于前端删除，可配合后具体操作
                                        rows.splice(index, 1);
                                    },
                                });
                            }
                        })
                        .catch(function (error) {
                            console.log(error);
                        });
                })
                .catch(() => {
                    ElMessage({
                        type: 'info',
                        message: '已取消',
                    });
                });
        },
        //分页器中-当选择条数时触发该函数
        handleSizeChange(val) {
            console.log(`每页 ${val} 条`);
            this.PageSize = val;
            this.currentPage = 1;

            //加载loading
            this.loading = true;
            //通过Ajax向后台获取数据
            axios
                .get('/manage/Equipment/tagList', {
                    headers: {
                        Auth: localStorage.getItem('token'),
                    },
                    params: {
                        name: this.pagesdatas.name,
                        pid: this.pagesdatas.pid,
                        page: 1,
                        page_size: val,
                    },
                })
                .then((response) => {
                    this.myaxiosfunc(response.data.result);
                })
                .catch(function (error) {
                    console.log(error);
                });
        },
        //分页器中-当选择分页时触发该函数
        handleCurrentChange(val) {
            console.log(`当前页: 第${val}页`);
            this.currentPage = val || 1;

            //加载loading
            this.loading = true;
            //通过Ajax向后台获取数据
            axios
                .get('/manage/Equipment/tagList', {
                    headers: {
                        Auth: localStorage.getItem('token'),
                    },
                    params: {
                        name: this.pagesdatas.name,
                        pid: this.pagesdatas.pid,
                        page: val || 1,
                        page_size: this.PageSize,
                    },
                })
                .then((response) => {
                    this.myaxiosfunc(response.data.result);
                })
                .catch(function (error) {
                    console.log(error);
                });
        },
        //table表中-表格带状态-可扩展
        tableRowClassName({ rowIndex }) {
            //隔行显示
            if ((rowIndex + 1) % 2 === 0) {
                return 'warning-row';
            }
            return '';
        },
        //点击按钮-弹出弹框并赋值弹窗基本配置
        addTanClick(taninfo) {
            console.log(taninfo);
            this.tancontent.content = taninfo.content;
            this.tancontent.title = taninfo.title;
        },
        //点击进行路由页面跳转以及携带参数
        routeraddrClick(routeraddr, rowdata) {
            if (rowdata) {
                this.$router
                    .push({
                        path: routeraddr,
                        query: { id: rowdata.id },
                    })
                    .catch((error) => error);
            } else {
                //仅跳转
                this.$router.push(routeraddr).catch((error) => error);
            }
        },
        //导出数据
        exportDatas() {
            axios
                .get('/manage/Equipment/tagList', {
                    headers: {
                        Auth: localStorage.getItem('token')
                    },
                    params: {
                        name: this.pagesdatas.name,
                        pid: this.pagesdatas.pid,
                        export: 1
                    }
                })
                .then((response) => {
                    console.log(response.data.message)
                    //   ElMessage({ type: "success", message: response.data.message });
                })
                .catch(function(error) {
                    // 请求失败处理
                    console.log(error)
                })

        },

        //获取当前windows页面信息-实时修改【弹窗】的宽度
        getwindowinfo() {
            this.sreenheight = document.documentElement.clientHeight;
            this.sreenwidth = document.documentElement.clientWidth;
            if (this.sreenwidth < 600) {
                this.searchwidth = this.sreenwidth * 0.8 + 'px';
            } else {
                this.searchwidth = '580px';
            }
        },
        //提交等相关event事件函数
        //获取Container-Pagedata-Maintwo-搜索-返回的数据
        getSeachinfoEvent(data) {
            console.log('点击查询获取的data数组：' + JSON.stringify(data));
            this.pagesdatas = data; //将筛选条件赋值给本页的pagesdatas存储，供分页使用
            //加载loading
            this.loading = true;
            //通过Ajax向后台获取数据
            axios
                .get('/manage/Equipment/tagList', {
                    headers: {
                        Auth: localStorage.getItem('token'),
                    },
                    params: {
                        name: data.name,
                        pid: data.pid,
                        page: 1,
                        page_size: this.PageSize,
                    },
                })
                .then((response) => {
                    this.myaxiosfunc(response.data.result);
                })
                .catch(function (error) {
                    console.log(error);
                });
        },
        //数据渲染
        myaxiosfunc(successdata) {
            var newdata = successdata.data;
            for (var i = 0; i < newdata.length; i++) {
                //该字段表示search按钮，且status为1表示启用，2表示禁用
                newdata[i].switchstatus = 4;
                if (newdata[i].width === '') {
                    newdata[i].wheight = '无宽高';
                } else {
                    newdata[i].wheight = newdata[i].width + '*' + newdata[i].height;
                }
                if (newdata[i].thumb_path) {
                    newdata[i].images = newdata[i].thumb_path;
                }
                //转换更新时间
                if (newdata[i].uptime === 0 || newdata[i].uptime === null) {
                    newdata[i].uptime = '无更新时间';
                } else {
                    newdata[i].uptime = this.$utils.formatDate(newdata[i].uptime);
                }
            }
            // console.log('当前页面的data:' + JSON.stringify(newdata))
            this.mainthreetableData = newdata;
            this.totalCount = successdata.total;
            this.currentpage = successdata.current_page; //当前页面页数
            this.lastpage = successdata.last_page;
            this.loading = false;

            this.moreDataBackShow(this.multipleSelection);
        },

        add() {
            this.tagsData = {
                data: {},
                dialog: true,
                api: '/manage/Equipment/tagInsert',
            };
        },

        edit(item) {
            this.tagsData = {
                data: {
                    id: item.id,
                    pid: item.pid,
                    name: item.name,
                    keyword: item.keyword,
                    trigger: item.trigger,
                    valid: String(item.valid),
                },
                dialog: true,
                api: '/manage/Equipment/tagEdit',
            };
        },

        // 操作成功
        successEvent() {
            this.handleCurrentChange(this.pagesdatas.page);
        },

        async getTagGrounp() {
            try {
                const { data } = await axios.get('/manage/Equipment/tagGroupList', { headers: { Auth: localStorage.getItem('token') }, params: { page: 1, page_size: 9999 } });
                this.tagGrounpList = data.result.data;
                const tag = data.result.data.map((item) => {
                    return {
                        value: item.id,
                        label: item.name,
                    };
                });
                tag.unshift({ value: '', label: '全部' });
                //这里的select[0],是写死的，后续可优化！注意写死的状况下，select的位置不可移动
                this.maintwoforminfo.selectleft.select[0].options = tag;
            } catch (error) {
                console.log(error);
            }
        },

        formatLen(val, len) {
            if(val) {
                return val.length > len ? `${val.slice(0, len)}...` : val
            } else {
                return ''
            }
        },

    },
    created() {
        if (localStorage.getItem('token') === null || !localStorage.getItem('token')) {
            this.isshow = false;
            this.$router.push('/').catch((error) => error);
        } else {
            window.addEventListener('resize', this.getwindowinfo); // 注册监听器-实时检测浏览器页面宽高
            this.getwindowinfo();

            //加载loading
            this.loading = true; //通过Ajax向后台获取数据
            let routerdata = this.$route.query;
            if (routerdata.pid) {
                this.maintwoforminfo.selectleft.select[0].value = parseInt(
                routerdata.pid);
            }

            //通过Ajax向后台获取数据
            axios
                .get('/manage/Equipment/tagList', {
                    headers: {
                        Auth: localStorage.getItem('token'),
                    },
                    params: {
                        pid: routerdata.pid || '',
                        page_size: this.PageSize,
                    },
                })
                .then((response) => {
                    this.myaxiosfunc(response.data.result);
                })
                .catch(function (error) {
                    console.log(error);
                });

            // 获取标签组
            this.getTagGrounp();
        }
    },
    components: {
        mainone,
        maintwo,
        mainimgesup,
        TagsForm,
        commTable,
    },
});
</script>
<style scoped>
/*局部控制-Switch按钮-按钮中显示状态文字*/
/* switch按钮款式 */
#switch :deep(.el-switch__label) {
    position: absolute;
    display: none;
    color: #fff !important;
}

/*关上时文字地位设置*/
#switch :deep(.el-switch__label--right) {
    z-index: 1;
}

/* 调整关上时文字的显示位子 */
#switch :deep(.el-switch__label--right) span {
    margin-left: 3px;
    font-size: 12px;
}

/*敞开时文字地位设置*/
#switch :deep(.el-switch__label--left) {
    z-index: 1;
}

/* 调整敞开时文字的显示位子 */
#switch :deep(.el-switch__label--left) span {
    margin-left: 15px;
    font-size: 12px;
}

/*显示文字*/
#switch :deep(.el-switch__label.is-active) {
    display: block;
}

/* 调整按钮的宽度 */
#switch :deep(.el-switch) .el-switch__core,
#switch :deep(.el-switch) .el-switch__label {
    width: 55px !important;
    margin: 0;
    padding: 5px;
}

/*操作中-按钮样式*/
.el-button--mini {
    min-height: 28px;
    padding: 0 8px;
    font-size: 12px;
    border-radius: calc(var(--el-border-radius-base) - 1px);
}

.mybutton_box {
    display: flex;
    flex-direction: row;
    align-items: center;
}

.mybutton_box_btn :deep(.el-button) {
    padding: 8px 20px;
    height: 36px;
    min-height: 36px;
}
</style>
