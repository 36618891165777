export default {
    public_words: {
        brand: '聚邻社'
    },

    // 设备信息-资金方
    dvc_info: {
        client_type: { 1: '打印机', 2: '复印机' }
    }
}
