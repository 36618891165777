<template>
    <div v-loading="loading" element-loading-text="正在飞速加载中...">
        <mainone :mainonebread="mainonebread"></mainone>
        <dpwCard title="详情">
            <template #span>
                <span class="span-box" v-if="status !== ''" :style="{ background: status_options[status].color }">{{ status_options[status].text }}</span>
            </template>
            <template #buttons>
                <el-button v-if="status == -1 && mypowerlimits.ckd_jd_jd" type="primary" plain @click="isTrueGetMonadEvent()" :disabled="is_disabled">确认接单</el-button>
            </template>
            <template #content>
                <div>
                    <dpwTitle title="基本信息"></dpwTitle>
                    <dpwDetails :datas="data_options"></dpwDetails>
                    <el-divider />
                </div>
                <div v-if="status == 3">
                    <dpwTitle title="撤单详情"></dpwTitle>
                    <dpwDetails :datas="reason_options"></dpwDetails>
                    <el-divider />
                </div>
                <div>
                    <dpwTitle title="详情列表"></dpwTitle>
                    <dpwTable
                        v-loading="loading"
                        element-loading-text="正在飞速加载中..."
                        ref="myTableRef"
                        :tabletitle="tableTitle"
                        :tabledata="tableData"
                        :totalcount="totalCount"
                        :currentpage="currentPage"
                        :pagesize="PageSize"
                        :Lastpage="lastpage"
                        :Operation="(status == 1 || status == 2) ? { isshow: true, width: 200 } : null"
                        >
                        <template #content="{ scope, prop }">
                            <div v-if="prop === 'real_num'">
                                <div v-if="scope.row['in_type'] == 2 && status == 1">
                                    <onlyKeysTable
                                        :vmodel="scope.row[prop]"
                                        :span_style="{ color: 'var(--el-color-primary)', cursor: 'pointer' }"
                                        :table="{ tabletitle: tan_table_title, tableData: scope.row.goods_info, Operation: { isshow: true, width: 120 } }"
                                        :dialog="{ title: '实出库量-详情【' + scope.row['name'] + '】', width: '30%' }"
                                        @tanPostData="(data) => tanPostData(data, 4, scope.$index)"
                                    />
                                </div>
                                <div v-else-if="scope.row['in_type'] == 2 && status == 2">
                                    <onlyKeysTablePages
                                        :vmodel="scope.row[prop]"
                                        :span_style="{ color: 'var(--el-color-primary)', cursor: 'pointer' }"
                                        :dialog="{ title: '实出库量-详情【' + scope.row['name'] + '】', width: '30%' }"
                                        :axios="{ path: '/manage/packOut/onlyCodeList', data: { id: this.$route.query.id, goods_id: scope.row['id'] } }"
                                    />
                                </div>
                                <div v-else>
                                    <span>{{ scope.row[prop] }}</span>
                                </div>
                            </div>
                            <div v-else-if="prop === 'images'">
                                <div v-if="scope.row['img'] == 1">
                                    <div v-if="status == 1">
                                        <uploadImgsEnter
                                            :vmodel="scope.row['images']"
                                            :span_style="{ color: 'var(--el-color-primary)', cursor: 'pointer' }"
                                            :span_info="{ name: scope.row['name'], type: scope.row['category'] }"
                                            @tanPostData="(data) => tanPostData(data, 5, scope.$index)"
                                        />
                                    </div>
                                    <div v-else-if="status == 2">
                                        <showImgsEnter
                                            :vmodel="scope.row['images']"
                                            :span_style="{ color: 'var(--el-color-primary)', cursor: 'pointer' }"
                                            :span_info="{ name: scope.row['name'], type: scope.row['category'] }"
                                        />
                                    </div>
                                </div>
                                <div v-else></div>
                            </div>
                            <div v-else>
                                <span>{{ scope.row[prop] }}</span>
                            </div>
                        </template>
                        <template #special="{ scope }">
                            <div  v-if="status == 1" class="my-flex-row">
                                <numberEnter v-if="scope.row['in_type'] == 1" :vmodel="scope.row.real_num" @tanPostData="(data) => tanPostData(data, 2, scope.$index)" />
                                <textEnter v-if="scope.row['in_type'] == 2" :vmodel="scope.row.goods_info" @tanPostData="(data) => tanPostData(data, 3, scope.$index)" />
                                <div v-if="scope.row['in_type'] == 2">
                                    <mainimgesup
                                        ref="uploadfile"
                                        :myimgesup="myimgesup_1"
                                        :myimgesupisshow="myimgesupisshow_1"
                                        :disabled="up_disabled"
                                        v-on:getuploadfileIsData="(data) => upgetUploadFileEvent(data, scope.$index)"
                                        v-on:beginUpload="beginUploadEvent"
                                        v-on:errorUpload="errorUploadEvent"
                                    >
                                    </mainimgesup>
                                </div>
                            </div>
                            <div v-if="status == 2">
                                <revocationNum v-if="scope.row['in_type'] == 1 && scope.row['real_num'] > 0 " :info="scope.row" :id="$route.query.id" @success="getInfoEvent({ id: $route.query.id })"></revocationNum>
                                <revocationTable v-if="scope.row['in_type'] == 2 && scope.row['real_num'] > 0 " :info="scope.row" :id="$route.query.id" @success="getInfoEvent({ id: $route.query.id })"></revocationTable>
                            </div>
                        </template>
                    </dpwTable>
                    <div v-if="status == 1">
                        <el-divider />
                        <div style="width: 30%; min-width: 400px">
                            <el-form ref="outBoundRef" :model="outBound" :rules="outBound_rules" label-width="120px">
                                <el-form-item prop="express_type" label="快递公司：">
                                    <el-select v-model="outBound.express_type" class="m-2" placeholder="请输入快递公司" style="width: 100%">
                                        <el-option v-for="(item, index) in express_type_options" :key="index" :label="item.label" :value="item.value" />
                                    </el-select>
                                </el-form-item>
                                <el-form-item prop="express_number" label="快递运单号：">
                                    <el-input v-model="outBound.express_number" clearable placeholder="请输入快递运单号" maxlength="200"></el-input>
                                </el-form-item>
                                <el-form-item>
                                    <el-button v-if="mypowerlimits.ckd_jd_ckd" type="primary" style="width: 10%; min-width: 200px" @click="submitOutboundEvent('outBoundRef')"
                                        >提交出库</el-button
                                    >
                                </el-form-item>
                            </el-form>
                        </div>
                    </div>
                </div>
            </template>
        </dpwCard>
    </div>
</template>

<script>
import revocationNum from '../../acommon/models/revocationNum.vue'
import revocationTable from '../../acommon/models/revocationTable.vue'
import textEnter from '../../acommon/models/textEnter.vue'
import numberEnter from '../../acommon/models/numberEnter.vue'
import onlyKeysTable from '../../acommon/models/onlyKeysTable.vue'
import onlyKeysTablePages from '../../acommon/models/onlyKeysTablePages.vue'
import uploadImgsEnter from '../../acommon/models/uploadImgsEnter.vue'
import showImgsEnter from '../../acommon/models/showImgsEnter.vue'
import mainimgesup from '@/components/Mainimgesup.vue'
import { defineComponent } from 'vue'
import mainone from '@/components/Mainone'
import dpwDetails from '../../acommon/dpw-details.vue'
import dpwCard from '../../acommon/dpw-card.vue'
import dpwTitle from '../../acommon/dpw-title.vue'
import dpwTable from '../../acommon/dpw-table.vue'
import axios from 'axios'
import dict from '../../assets/js/dict.js'
import { ElMessage } from 'element-plus'

export default defineComponent({
    name: 'vgoinmonadinfo',
    props: {
        mainonebread: {
            default: [
                {
                    name: '仓库'
                },
                {
                    name: '出入库管理'
                },
                {
                    name: '出库单'
                },
                {
                    name: '详情'
                }
            ]
        },
        is_all: {
            type: Number,
            default: 1
        }
    },
    data() {
        return {
            status: '',
            status_options: dict.wmsystem.status_options,

            data_options: [],
            tableTitle: [
                {
                    fixed: 'left',
                    prop: 'id',
                    label: 'ID',
                    width: '150'
                },
                {
                    fixed: false,
                    prop: 'name',
                    label: '产品名称',
                    width: ''
                },
                {
                    fixed: false,
                    prop: 'category',
                    label: '类别',
                    width: ''
                },
                {
                    fixed: false,
                    prop: 'expect_num',
                    label: '预出库量',
                    width: ''
                },
                {
                    fixed: false,
                    prop: 'real_num',
                    label: '实出库量',
                    width: ''
                },
                {
                    fixed: false,
                    prop: 'unit',
                    label: '单位',
                    width: ''
                },
                {
                    fixed: false,
                    prop: 'images',
                    label: '照片',
                    width: ''
                },
            ],
            tableData: [],
            totalCount: 0, //数据列表中-数据总条数
            lastpage: 1, //数据列表中-数据总页数（默认以20条一页）
            loading: false, //初始化-loading加载动效，默认是false关闭
            currentPage: 1,
            pageSizes: [10, 20, 50, 100],
            PageSize: 1000,

            disabled: false,
            formdata: {},

            // 撤单相关
            reason_options: [],

            // 照片相关
            images_info: {
                is_upload: '',
                images: ''
            },

            // 确认接单相关
            is_disabled: false,
            outBound: {
                id: '',
                express_type: '',
                express_number: '',
                goods: []
            },
            outBound_rules: {
                express_type: [
                    {
                        required: true,
                        message: '快递公司为必选项',
                        trigger: 'blur'
                    }
                ],
                express_number: [
                    {
                        required: true,
                        message: '快递运单号为必填项',
                        trigger: 'blur'
                    }
                ]
            },

            // 快递公司-下拉
            express_type_options: [],

            // 实出库量
            tan_table_title: [
                {
                    fixed: false,
                    prop: 'only_key',
                    label: '唯一标识',
                    width: ''
                }
            ],

            //  Excel 导入
            myimgesupisshow_1: true,
            myimgesup_1: {
                uploadsize: 2, // 1阿里 oss ；2前端传后端
                listtype: 'picture', //list展示类型，text/picture/picture-card；默认不填为text
                showfilelist: false, //list展示列表是否显示，显示true，隐藏false
                ismust: true, //是否必填，true表示必填
                action: '/manage/pack/packInExcel', //upload上传API地址
                headers: { Auth: localStorage.getItem('token') }, //upload上传headers
                multiple: false, //上传多个文件
                title: 'Excel导入', //button-按钮文字
                title_type: 'primary', //button-按钮显示样式,primary&text
                title_size: 'small', //button-按钮大小，medium / small / mini
                title_plain: false, //button-按钮是否为线性（plain）
                isshow: false, //button-按钮是否显示，注意：false表示显示，true表示不显示
                tips: '', //提示&建议文字
                limit: '', //上传数量限制
                target_id: 100, //上传文件target_id ：图片分类，区分存储文件和存储时长，不传默认存储1天后过期；1-二维码； 2-打印文件； 3-文库文件； 4-课程文件； 5-广告主宣传页； 6-广告主产生的pdf文件； 7-证件照文件； 50-设备广告； 100-通用；
                rules: {
                    files: {
                        size: 200000, //文件大小（单位kb）
                        type: ['application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'], //文件格式
                        typechart: 'Excel' //文件格式提示规则
                    }
                }
            },
            up_disabled: false,

            mypowerlimits: JSON.parse(localStorage.getItem('powerlimits')), //获取权限
            mytoken: localStorage.getItem('token') //获取存储的token
        }
    },
    methods: {
        // 确认接单
        isTrueGetMonadEvent() {
            this.is_disabled = true
            axios
                .post(
                    '/manage/packout/confirmGet',
                    { id: this.$route.query.id },
                    {
                        headers: {
                            Auth: localStorage.getItem('token')
                        }
                    }
                )
                .then((response) => {
                    if (response.data.code == 0) {
                        ElMessage({
                            type: 'success',
                            message: response.data.message,
                            duration: 1000,
                            onClose: () => {
                                this.$router.push({ path: '/gooutreceivemonadinfo', query: { id: this.$route.query.id, is_all: 3 } }).catch((error) => error)
                                this.is_disabled = false
                            }
                        })
                    } else {
                        this.is_disabled = false
                    }
                })
                .catch(function () {
                    this.is_disabled = false
                })
        },
        // 2-数量; 3-文本录入; 4-实出库量; 5-照片
        tanPostData(data, flag, index) {
            if (flag == 2) {
                this.tableData[index].real_num = data.number
            } else if (flag == 3) {
                this.tableData[index].real_num = data.goods_num
                this.tableData[index].goods_info = data.goods_info
            } else if (flag == 4) {
                this.tableData[index].real_num = data.goods_num
                this.tableData[index].goods_info = data.goods_info
            } else if (flag == 5) {
                this.tableData[index].images = data.imgs_list
            }
        },

        // Excel 导入
        // 开始上传
        beginUploadEvent() {
            this.up_disabled = true
        },
        // 上传报错
        errorUploadEvent() {
            this.up_disabled = false
        },
        // 获取upload子组件回传的数据-file文件url等数据
        upgetUploadFileEvent(data, index) {
            this.tableData[index].goods_info = data.result.map((n) => {
                return { only_key: n.text }
            })
            this.tableData[index].real_num = this.tableData[index].goods_info.length

            // 删除upload列队中的数据
            this.$refs.uploadfile.handleRemove(data)
            this.up_disabled = false
        },
        // 处理 goods 数据结构&异常抛出
        isDetermine() {
            let goods = this.tableData.map((n) => {
                let data = {
                    id: n.id,
                    num: n.real_num
                }
                if (n.in_type == 2) {
                    data['info'] = n.goods_info
                        ? n.goods_info.map((m) => {
                              return m.only_key
                          })
                        : []
                }
                if (n.img == 1) {
                    data['img'] = n.images
                        .map((m) => {
                            return m.url
                        })
                        .filter((k) => k)
                        .join(',')
                }
                return data
            })

            if (goods.length != 0) {
                goods.map((n, key) => {
                    if (n.num == 0) {
                        setTimeout(() => {
                            ElMessage({
                                message: '详情列表：第' + (key + 1) + '行，数据错误！【实出库量】不能为 0',
                                type: 'error',
                                duration: 5000
                            })
                        }, 1)
                        throw new Error('')
                    }

                    if (n.img == '') {
                        setTimeout(() => {
                            ElMessage({
                                message: '详情列表：第' + (key + 1) + '行，数据错误！【照片】不能为 0',
                                type: 'error',
                                duration: 5000
                            })
                        }, 1)
                        throw new Error('')
                    }
                })
            }
            return goods
        },

        // 撤回
        revocation(data) {
            console.log(data)
        },

        // 提交出库
        submitOutboundEvent(formName) {
            let goods = this.isDetermine()
            this.outBound.goods = goods

            let getdatas = this.$refs[formName].model
            this.$refs[formName].validate((valid) => {
                if (valid) {
                    axios
                        .post(
                            '/manage/packout/save',
                            {
                                id: this.$route.query.id,
                                express_type: getdatas.express_type,
                                express_number: getdatas.express_number,
                                goods: getdatas.goods
                            },
                            {
                                headers: {
                                    Auth: localStorage.getItem('token')
                                }
                            }
                        )
                        .then((response) => {
                            if (response.data.code === 0) {
                                ElMessage({
                                    type: 'success',
                                    message: '提交成功！',
                                    duration: 1000,
                                    onClose: () => {
                                        this.$router.push({ path: '/gooutreceivemonad', query: { is_all: 2 } }).catch((error) => error)
                                    }
                                })
                            }
                        })
                        .catch(function (error) {
                            console.log(error)
                        })
                } else {
                    return false
                }
            })
        },

        // 获取各种下拉
        getAllDownLoading() {
            // 获取快递公司
            axios
                .post(
                    '/manage/config/info',
                    {
                        key_name: 'express_set'
                    },
                    {
                        headers: {
                            Auth: localStorage.getItem('token')
                        }
                    }
                )
                .then((response) => {
                    let getdatas = response.data.result
                    let new_datas = Object.keys(getdatas).map((n) => {
                        return { label: getdatas[n], value: n }
                    })
                    this.express_type_options = new_datas
                })
        },
        // 获取详情
        getInfoEvent(data) {
            this.loading = true

            axios
                .post('/manage/packout/info' + this.is_all, data, {
                    headers: {
                        Auth: localStorage.getItem('token')
                    }
                })
                .then((response) => {
                    let getdatas = response.data.result
                    this.formdata = getdatas

                    // 状态
                    this.status = getdatas.status

                    // 基本信息
                    this.data_options = [
                        { title: '出库编号', value: getdatas.trade_no },
                        { title: '出库类型', value: getdatas.out_type_name },
                        { title: '出库日期', value: getdatas.outdate },
                        { title: '仓库', value: getdatas.pack_name },
                        { title: '收货人', value: getdatas.receive_man },
                        { title: '收货人电话', value: getdatas.receive_mobile },
                        { title: '收货人地址', value: getdatas.receive_address },
                        { title: '预出库量', value: getdatas.expect_num },
                        { title: '实出库量', value: getdatas.real_num },
                        { title: '接单人', value: getdatas.get_truename },
                        { title: '出库时间', value: getdatas.outtime != 0 ? this.$utils.formatDate(getdatas.outtime) : '' },
                        { title: '创建人', value: getdatas.create_truename },
                        { title: '创建时间', value: getdatas.addtime != 0 ? this.$utils.formatDate(getdatas.addtime) : '' },
                        {
                            title: '快递公司',
                            value: getdatas.express_type && getdatas.express_type != null && getdatas.express_type != 0
                                ? this.express_type_options
                                      .map((n) => {
                                          if (getdatas.express_type == n.value) return n
                                      })
                                      .filter((n) => n)[0].label
                                : ''
                        },
                        { title: '快递运单号', value: getdatas.express_number }
                    ]

                    // 撤单详情
                    this.reason_options =
                        getdatas.status == 3
                            ? [
                                  { title: '撤单原因', value: getdatas.reason },
                                  { title: '撤单备注', value: getdatas.remark },
                                  { title: '撤单时间', value: getdatas.canceltime != 0 || etdatas.canceltime != null ? this.$utils.formatDate(getdatas.canceltime) : '' }
                              ]
                            : []

                    // 详情列表
                    this.tableData = getdatas.goods_info.map((n) => {
                        if (n.images && n.images != '') {
                            n.images = n.images.split(',').map((n) => {
                                return { url: n }
                            })
                        } else {
                            n.images = []
                        }
                        return { ...n, ...{ goods_info: '' } }
                    })

                    this.loading = false
                })
                .catch(function (error) {
                    // 请求失败处理
                    console.log(error)
                })
        }
    },
    created() {
        if (localStorage.getItem('token') === null || !localStorage.getItem('token')) {
            this.$router.push('/').catch((error) => error)
        } else {
            this.getAllDownLoading()

            let routerInfo = this.$route.query
            this.getInfoEvent({ id: routerInfo.id })
        }
    },
    components: {
        revocationNum,
        revocationTable,
        textEnter,
        numberEnter,
        onlyKeysTable,
        onlyKeysTablePages,
        uploadImgsEnter,
        showImgsEnter,
        mainimgesup,
        mainone,
        dpwCard,
        dpwDetails,
        dpwTitle,
        dpwTable
    }
})
</script>

<style scoped>
.span-box {
    color: #fff;
    font-weight: bold;
    padding: 4px 8px;
    margin-left: 20px;
    border: 0px solid #dedede;
    border-radius: 4px;
}

.mytabletext {
    color: var(--el-color-primary);
    cursor: pointer;
}
</style>
