<template>
    <div>
        <gooutMonadInfo :mainonebread="mainonebread" :is_all="is_all"></gooutMonadInfo>
    </div>
</template>

<script>
import gooutMonadInfo from '../gooutmonad/vgooutmonadinfo.vue'
import { defineComponent } from 'vue'

export default defineComponent({
    name: 'vgooutreceivemonadinfo',
    data() {
        return {
            mainonebread: [
                {
                    name: '仓库'
                },
                {
                    name: '出入库管理'
                },
                {
                    name: '我的出库接单'
                },
                {
                    name: '详情'
                }
            ],

            is_all: 2
        }
    },
    created() {
        let routerInfo = this.$route.query
        this.is_all = routerInfo.is_all
    },
    components: {
        gooutMonadInfo
    }
})
</script>

<style scoped></style>
