<template>
    <div>
        <div>
            <el-button :type="button.type" :plain="button.plain" :size="button.size" @click="revocation()">撤回</el-button>
        </div>
        <el-dialog v-model="dialog.show" :title="dialog.title" append-to-body :before-close="cancel" @close="close">
			<el-table :data="tableData.data" border row-key="id" @selection-change="handleSelectionChange" ref="multipleTableRef">
				<el-table-column type="selection" :reserve-selection="true" width="55" />
				<el-table-column prop="id" label="ID" />
				<el-table-column prop="only_code" label="唯一标识" />
			</el-table>
			<div style="margin-top: 20px;">
				<el-pagination
					background
					@size-change="getTableData()"
                    @current-change="getTableData"
					layout="total, sizes, prev, pager, next"
					v-model:current-page="params.page"
					v-model:page-size="params.page_size"
					:page-sizes="[20, 50, 100]"
					:total="tableData.total"
				/>
			</div>
            <div style="text-align: right; margin: 20px 0">
                <el-button @click="cancel()">取消</el-button>
                <el-button type="primary" :disabled="disabledBtn" @click="submit()">确定</el-button>
            </div>
        </el-dialog>
    </div>
</template>

<script setup>
import { reactive, ref, defineProps } from 'vue'
import { ElMessage } from 'element-plus'
import http from '@/assets/js/http.js'

const props = defineProps(['info', 'id'])
const emit = defineEmits(['success'])

const params = reactive({
	page: 1,
	page_size: 20,
	goods_id: props.info.id,
	id: props.id
})

const button = ref({
	type: 'primary',
    plain: false,
    size: 'small'
})

const dialog = reactive({
	show: false,
    title: '撤回',
})
const multipleTableRef = ref()
const multipleSelection = ref([])

const tableData = reactive({
	data: [],
	columns: [],
	total: 0,
})


const revocation = async ()=> {
	dialog.show = true
	getTableData()
}

const getTableData = async (page = 1)=> {
	params.page = page
	try {
		const res = await http.post('packOut/onlyCodeList', params)
		Object.assign(tableData, res)		
	} catch(err) {
	    console.log(err)
	}
}

const disabledBtn = ref(false)

const submit = async ()=> {
	if(multipleSelection.value.length === 0) {
		ElMessage({
    		message: '请选中一条数据',
    		type: 'warning',
  		})
		return
	}

	const id = props.id
	const goods_id = props.info.id
	const only_code = multipleSelection.value.map(item=> item.only_code)

	disabledBtn.value = true

	try {
		await http.post('packout/revoked', { id, goods_id, only_code })
		emit('success')
		dialog.show = false
		disabledBtn.value = false
		ElMessage({ message: '操作成功', type: 'success' })
	} catch(err) {
		console.log(err)
	}
}

const close = ()=> {
	multipleSelection.value = []
	multipleTableRef.value.clearSelection()
}

const cancel = ()=> {
    dialog.show = false
}

const handleSelectionChange = (data)=> {
	multipleSelection.value = data
}

</script>

<style lang="scss" scoped></style>
