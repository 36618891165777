<template>
  <div>
    <mainone :mainonebread="mainonebread"></mainone>
    <maintwo :maintwoiconshow="maintwoiconshow" :maintwoforminfo="maintwoforminfo"
      :myinputoneisshow="maintwoforminfo.myinputoneisshow" :myinputone="maintwoforminfo.myinputone"
      :myselectleftisshow="maintwoforminfo.selectleftisshow" :myselectleft="maintwoforminfo.selectleft"
      :myinputishow="myinputishow" :myinput="myinput" :myinputonefullisshow="maintwoforminfo.myinputonefullisshow"
      :myinputonefull="maintwoforminfo.myinputonefull" :myselectisshow="myselectisshow" :myselect="myselect"
      :myselectcityisshow="myselectcityisshow" :myselectcity="myselectcity" v-on:getsearchinfo="getSeachinfoEvent">
    </maintwo>
    <!--table表单-->
    <div>
      <!--主list列表-->
      <div style="
          margin-top: 10px;
          background-color: #ffffff;
          border-radius: 4px;
          line-height: 40px;
          padding: 0 20px 20px;
        ">
        <div style="line-height: 60px">
          <!--左侧名称-->
          <div style="float: left; font-size: 14px">数据列表</div>
          <!--右侧按钮-->
          <div style="float: right">
            <!--自定义按钮-点击出现弹框&点击跳转子类页面@click="routeraddrClick('/XX路由')"-->
            <!--1、点击出现弹框@click="addTanClick(XX弹框配置信息,dialogVisible = true);resetFormSenior(XXform表单)"-->
            <!--2、点击跳转子类页面@click="routeraddrClick('/XX路由')-->
            <!--添加XX按钮-点击跳转添加类页面-->
            <el-button type="primary" plain class="max_search" v-show="mainthreeisshowbut"
              @click="routeraddrClick(mainthreeadddata)" v-if="mypowerlimits.dyjz_tj">添加</el-button>
          </div>
        </div>

        <div id="switch">
          <el-table ref="multipleTable" :row-class-name="tableRowClassName" :data="mainthreetableData"
            style="width: 100%" min-height="900" row-key="id" border default-expand-all v-loading="loading"
            element-loading-text="正在飞速加载中..." @selection-change="handleSelectionChange" @cell-click="handleOneData">
            <!--多选框-用于用户选择性导出数据-->
            <el-table-column type="selection" width="55" v-if="mainthreeisshowset" />
            <el-table-column v-for="(mytabletitle, i) in mainthreetabletitle" :key="i" :fixed="mytabletitle.fixedstatu"
              :prop="mytabletitle.prop" :label="mytabletitle.label" :width="mytabletitle.width">
              <!--这里先通过table列表中的title值进行判断，在进行渲染展示-->
              <!--例如：status状态字段，判断最终的值是那种表现形式等-->
              <template #default="scope" v-if="
                mytabletitle.prop == 'status' ||
                mytabletitle.prop == 'id' ||
                mytabletitle.prop == 'addtime'
              ">
                <div v-if="mytabletitle.prop == 'id'">
                  <div v-if="parseInt(scope.row.id) > 0">
                    <span>{{ scope.row.id }}</span>
                  </div>
                </div>
                <div v-if="mytabletitle.prop == 'addtime'">
                  <div v-if="scope.row.addtime">
                    <!-- 通过函数赋值的方式将时间进行转换 -->
                    <span>
                      {{ this.$utils.formatDate(scope.row.addtime) }}
                    </span>
                  </div>
                </div>
                <div v-if="mytabletitle.prop == 'status'">
                  <el-switch v-if="scope.row.status" v-model="scope.row.status" active-text="开启" inactive-text="关闭"
                    active-value="1" inactive-value="-1" active-color="#13ce66"
                    @click="mypowerlimits.dyjz_qy == true ? changeStatus(scope.row) : ''"
                    :disabled="!mypowerlimits.dyjz_qy" inactive-color="#cbcbcb">
                  </el-switch>
                </div>
              </template>
            </el-table-column>
            <!--操作&按钮-->
            <el-table-column label="操作" :width="mainthreehandle" v-if="mainthreeisshowope" prop="myoperation"
              id="operation">
              <template #default="scope">
                <!--操作：按照页面区分，且每个按钮对应相关函数-->
                <!--操作-打印介质-->
                <div v-if="parseInt(scope.row.id) > 0">
                  <el-button type="primary" @click="toEdit(scope.row.id)" size="mini" v-if="mypowerlimits.dyjz_bj">编辑
                  </el-button>
                  <el-button @click="
                    deleteRow(scope.$index, mainthreetableData, scope.row.id)
                  " v-if="mypowerlimits.dyjz_sc" type="danger" size="mini" icon="el-icon-delete"></el-button>
                </div>
              </template>
            </el-table-column>
          </el-table>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import mainone from "@/components/Mainone";
import maintwo from "@/components/Maintwo";
import { ElMessageBox, ElMessage } from "element-plus";
import axios from "axios";
import { defineComponent } from "vue";

export default defineComponent({
  name: "vphotoid",
  data() {
    return {
      mainonebread: [
        {
          name: "打印",
        },
        {
          name: "打印介质管理",
        },
        {
          name: "打印介质",
          path: "/photoid",
        },
      ], // 当前页-【面包屑导航】
      maintwoiconshow: false, // 筛选-【高级筛选按钮】
      maintwoforminfo: {
        myinputoneisshow: true, //筛选查询-左侧字+input
        myinputone: {
          divinterval: "0", //框右侧距离
          titlewidth: "74px", //标题宽度
          inputwidth: "200px", //input宽度
          inputinterval: "20px", //input右侧距离
          input: [
            {
              ismust: false, //是否必填，true表示必填
              title: "介质名称：",
              placeholder: "请输入名称",
              name: "name",
              content: "",
            },
          ],
        },
        selectleftisshow: true, //筛选查询-左侧字+select单框||select时间
        selectleft: {
          width: "none",
          divinterval: "", //框右侧距离
          selectwidth: "200px",
          selectinterval: "20px", //select右侧距离
          select: [
            {
              selectisshow: true,
              ismust: false,
              title: "状态：",
              placeholder: "全部",
              name: "status",
              value: "",
              options: [
                {
                  value: "0",
                  label: "全部",
                },
                {
                  value: "1",
                  label: "启用",
                },
                {
                  value: "-1",
                  label: "禁止",
                },
              ],
            },
          ],
        },
      }, // 筛选-控件信息
      mainthreeisshowbut: true, // 数据列表中-【添加按钮】
      mainthreeadddata: "/photoidadd", //点击添加按钮-跳转路由
      mainthreeisshowset: false, // 数据列表中-复选框
      mainthreeisshowope: true, // 数据列表中-操作-根据页面显示模块（整页）
      mainthreetableope: 9, // 数据列表中-操作-根据页面显示控件（整页）
      mainthreehandle: 150, // 数据列表中-操作-宽度
      mainthreetabletitle: [
        {
          fixedstatu: false,
          prop: "name",
          label: "名称",
          width: "",
        },
        {
          fixedstatu: false,
          prop: "sort",
          label: "排序",
          width: "",
        },
        {
          fixedstatu: false,
          prop: "printtype_name",
          label: "打印类型名",
          width: "",
        },
        {
          fixedstatu: false,
          prop: "status",
          label: "状态",
          width: "",
        },
        {
          fixedstatu: false,
          prop: "adminname",
          label: "创建人",
          width: "",
        },
        {
          fixedstatu: false,
          prop: "addtime",
          label: "创建时间",
          width: "",
        },
      ], // 数据列表中-数据表头
      mainthreestatus: true, // 数据列表中-属于search按钮表示状态
      mainthreetableData: [], // 数据列表中-具体数据

      //table表单相关数据
      multipleSelection: [],
      thispagename: "", //通过路由获取当前页面的path值

      seniorform: {},
      currentPage: 1,
      pageSizes: [20, 50, 100, 1000],
      PageSize: 20,

      tancontent: {
        title: "弹框标题",
        content: {},
      }, //弹框

      mypowerlimits: JSON.parse(localStorage.getItem("powerlimits")), //获取权限
      mytoken: localStorage.getItem("token"), //获取存储的token
      pagesdatas: [], //存储最近一次的筛选条件，供分页使用
    };
  },
  mounted() {
    //获取当前页面路由path值
    this.thispagename = this.$route.path;
  },
  methods: {
    // table表单-状态改变
    changeStatus(data) {
      console.log("切换状态的data值：" + data.status);
      axios
        .post(
          "/manage/shareprintcate/switch",
          {
            id: data.id,
          },
          {
            headers: {
              Auth: localStorage.getItem("token"),
            },
          }
        )
        .then((response) => {
          if (response.data.code == 0) {
            ElMessage({
              type: "success",
              message: response.data.message,
            });
          } else {
            // 切换状态失败-复原状态
            if (data.status == 1) {
              data.status = -1;
            } else {
              data.status = 1;
            }
          }
        })
        .catch(function (error) {
          console.log(error);
        });
    },
    // 路由跳转编辑介质页面
    toEdit(rowid) {
      this.$router
        .push({
          path: "/photoidedit",
          query: { id: rowid },
        })
        .catch((error) => error);
    },
    //table表单-点击单位格-带参跳转等
    handleOneData(row, column, cell, event) {
      // console.log(row); //获取当前行数据
      // console.log(column); //获取当前单位格信息 ，通过column.property可获取当前单元的title得name值
      // console.log(event); //获取当前指针事件
      console.log(event.path[1].id); //获取当前指针所指向的单元格id值，配合标签的id使用
    },
    //table表-操作中-删除按钮（函数）
    deleteRow(index, rows, ide) {
      ElMessageBox.confirm("确定删除吗？", "提示：", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "primary",
      })
        .then(() => {
          for (let key = 0; key < rows.length; key++) {
            if (rows[key]["children"]) {
              for (let p = 0; p < rows[key]["children"].length; p++) {
                if (!rows[key]["children"][p]["children"]) {
                  if (ide == rows[key]["children"][p].id) {
                    rows[key]["children"].splice(p, 1);
                  }
                } else {
                  this.deleteRowsub(rows[key]["children"][p], ide);
                }
              }
            }
          }
          this.mainthreetableData = rows;
          axios
            .post(
              "/manage/shareprintcate/delete",
              {
                id: ide,
              },
              {
                headers: {
                  Auth: localStorage.getItem("token"),
                },
              }
            )
            .then((response) => {
              if (response.data.code == 0) {
                ElMessage({
                  type: "success",
                  message: response.data.message,
                });
              }
            })
            .catch(function (error) {
              console.log(error);
            });
        })
        .catch(() => {
          ElMessage({
            type: "info",
            message: "已取消",
          });
        });
    },
    deleteRowsub(node, ide) {
      for (var i = 0; i < node.children.length; i++) {
        if (!node["children"][i]["children"]) {
          if (ide == node["children"][i].id) {
            node["children"].splice(i, 1);
          }
        } else {
          this.deleteRowsub(node["children"][i], ide);
        }
      }
    },
    //table表中-当选项发生变化是触发该函数
    handleSelectionChange(val) {
      console.log("this is:" + val);
      this.multipleSelection = val;
    },
    //table表中-表格带状态-可扩展
    tableRowClassName({ rowIndex }) {
      //隔行显示
      if ((rowIndex + 1) % 2 === 0) {
        return "warning-row";
      }
      return "";
    },
    //点击按钮-弹出弹框并赋值弹窗基本配置
    addTanClick(taninfo) {
      console.log(taninfo);
      this.tancontent.content = taninfo.content;
      this.tancontent.title = taninfo.title;
    },
    //点击进行路由页面跳转以及携带参数
    routeraddrClick(routeraddr) {
      this.$router.push(routeraddr).catch((error) => error);
    },
    //导出数据
    exportDatas(tableData) {
      console.log(tableData);
    },

    //获取Container-Pagedata-Maintwo-搜索-返回的数据
    getSeachinfoEvent(data) {
      console.log("点击查询获取的data数组：" + JSON.stringify(data));
      this.pagesdatas = data; //将筛选条件赋值给本页的pagesdatas存储，供分页使用
      //加载loading
      this.loading = true;
      //通过Ajax向后台获取数据
      axios
        .post(
          "/manage/shareprintcate/index",
          {
            name: data.name,
            status: data.status,
          },
          {
            headers: {
              Auth: localStorage.getItem("token"),
            },
          }
        )
        .then((response) => {
          this.myaxiosfunc(response);
        })
        .catch(function (error) {
          console.log(error);
        });
    },
    //数据渲染
    myaxiosfunc(response) {
      var getnumber = Object.keys(response.data.result);
      var arrdata = [];
      getnumber.forEach(function (value) {
        response.data.result[value]["id"] = "-" + value;
        arrdata.push(response.data.result[value]);
        if (response.data.result[value]["children"]) {
          var arrdatachild = [];
          var getnumberchild = Object.keys(
            response.data.result[value]["children"]
          );
          getnumberchild.forEach(function (value1) {
            arrdatachild.push(response.data.result[value]["children"][value1]);
          });
          response.data.result[value]["children"] = arrdatachild;
        }
      });
      // console.log("获取的arrdata is :" + JSON.stringify(arrdata));
      this.mainthreetableData = arrdata;
      this.loading = false;
    },
  },
  created() {
    if (localStorage.getItem("token") === null || !localStorage.getItem("token")) {
      this.isshow = false;
      this.$router.push("/").catch((error) => error);
    } else {
      // 权限控制-启用禁用-[当其为false的时候不显示该列]
      if (this.mypowerlimits.dyjz_qy == false) {
        this.mainthreetabletitle.splice(3, 1);
      }

      //加载loading
      this.loading = true; //通过Ajax向后台获取数据
      //通过Ajax向后台获取数据
      axios
        .post(
          "/manage/shareprintcate/index",
          {},
          {
            headers: {
              Auth: localStorage.getItem("token"),
            },
          }
        )
        .then((response) => {
          this.myaxiosfunc(response);
        })
        .catch(function (error) {
          console.log(error);
        });
    }
  },
  components: {
    mainone,
    maintwo,
  },
});
</script>
<style scoped>
/*局部控制-Switch按钮-按钮中显示状态文字*/
/* switch按钮款式 */
#switch :deep(.el-switch__label) {
  position: absolute;
  display: none;
  color: #fff !important;
}

/*关上时文字地位设置*/
#switch :deep(.el-switch__label--right) {
  z-index: 1;
}

/* 调整关上时文字的显示位子 */
#switch :deep(.el-switch__label--right) span {
  margin-left: 3px;
  font-size: 12px;
}

/*敞开时文字地位设置*/
#switch :deep(.el-switch__label--left) {
  z-index: 1;
}

/* 调整敞开时文字的显示位子 */
#switch :deep(.el-switch__label--left) span {
  margin-left: 15px;
  font-size: 12px;
}

/*显示文字*/
#switch :deep(.el-switch__label.is-active) {
  display: block;
}

/* 调整按钮的宽度 */
#switch :deep(.el-switch) .el-switch__core,
#switch :deep(.el-switch) .el-switch__label {
  width: 55px !important;
  margin: 0;
  padding: 5px;
}

/*操作中-按钮样式*/
.el-button--mini {
  min-height: 28px;
  padding: 0 8px;
  font-size: 12px;
  border-radius: calc(var(--el-border-radius-base) - 1px);
}
</style>