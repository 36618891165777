<template>
  <div>
    <mainone :mainonebread="mainonebread"></mainone>
    <maintwo :maintwoiconshow="maintwoiconshow" :maintwoforminfo="maintwoforminfo"
      :myinputoneisshow="maintwoforminfo.myinputoneisshow" :myinputone="maintwoforminfo.myinputone"
      :myselectleftisshow="maintwoforminfo.selectleftisshow" :myselectleft="maintwoforminfo.selectleft"
      :myselecttopisshow="maintwoforminfo.selecttopisshow" :myselecttop="maintwoforminfo.selecttop"
      :myinputishow="myinputishow" :myinput="myinput" :myinputonefullisshow="maintwoforminfo.myinputonefullisshow"
      :myinputonefull="maintwoforminfo.myinputonefull" :myselectisshow="myselectisshow" :myselect="myselect"
      :myselectcityisshow="myselectcityisshow" :myselectcity="myselectcity" v-on:getsearchinfo="getSeachinfoEvent"
      v-on:searchForm="searchFormEvent"></maintwo>
    <!--table表单-->
    <div>
      <!--主list列表-->
      <div style="margin-top: 10px;background-color: #ffffff;border-radius: 4px;line-height: 40px;padding: 0 20px;">
        <div style="line-height: 60px">
          <!--左侧名称-->
          <div style="float: left;font-size: 14px;"> 数据列表</div>
          <!--右侧按钮-->
          <div style="float: right">
            <!--自定义按钮-点击出现弹框&点击跳转子类页面@click="routeraddrClick('/XX路由')"-->
            <!--1、点击出现弹框@click="addTanClick(XX弹框配置信息,dialogVisible = true);resetFormSenior(XXform表单)"-->
            <!--2、点击跳转子类页面@click="routeraddrClick('/XX路由')-->
            <!--<el-button ></el-button>-->
            <!--导出按钮-点击导出数据-->
            <el-button type="warning" plain @click="exportDatas()">导出</el-button>
          </div>
        </div>

        <div>
          <!--table表单-->
          <el-table ref="multipleTable" :row-class-name="tableRowClassName"
            :data="mainthreetableData.slice((currentPage - lastpage) * PageSize, currentPage * PageSize)"
            style="width: 100%" min-height="900" border v-loading="loading" element-loading-text="正在飞速加载中..."
            @selection-change="handleSelectionChange" @cell-click="handleOneData">
            <!--多选框-用于用户选择性导出数据-->
            <el-table-column type="selection" width="55" v-if="mainthreeisshowset" />
            <el-table-column v-for="(mytabletitle, i) in mainthreetabletitle" :key="i" :fixed="mytabletitle.fixedstatu"
              :prop="mytabletitle.prop" :label="mytabletitle.label" :width="mytabletitle.width">
              <!--这里先通过table列表中的title值进行判断，在进行渲染展示-->
              <!--例如：status状态字段，判断最终的值是那种表现形式等-->
              <template #default="scope" v-if="mytabletitle.prop === 'images' ||
                mytabletitle.prop === 'status' ||
                mytabletitle.prop === 'price'
              
              ">
                <div v-if="mytabletitle.prop === 'images'">
                  <div v-if="scope.row.images">
                    <el-image class="imagesshow" :src="scope.row.images" :preview-src-list="[scope.row.images]"
                      fit="cover"></el-image>
                  </div>
                </div>
                <div v-if="mytabletitle.prop === 'status'">
                  <div v-if="scope.row.status">
                    {{ scope.row.status }}
                  </div>
                </div>
                <div v-if="mytabletitle.prop === 'price'">
                  <div v-if="scope.row.price">
                    {{ scope.row.price / 100 }}
                  </div>
                </div>
              </template>
            </el-table-column>
            <!--操作&按钮-->
            <el-table-column fixed="right" label="操作" :width="mainthreehandle" v-if="mainthreeisshowope"
              prop="myoperation" id="operation">
              <template #default="scope">
                <!--操作：按照页面区分，且每个按钮对应相关函数-->
                <!--操作-订单-申请退款-->
                <div v-if="mainthreetableope === 19">
                  <el-button type="primary" size="mini" @click="routeraddrClick('/refundapplicationinfo')">查看详情
                  </el-button>
                  <el-button type="primary" size="mini" v-if="scope.row.handleclick">处理</el-button>
                </div>
              </template>
            </el-table-column>
          </el-table>
          <!--分页器-->
          <div class="block" style="text-align: center;margin: 30px 0">
            <el-pagination background @size-change="handleSizeChange" @current-change="handleCurrentChange"
              :current-page="currentPage" :page-sizes="pageSizes" :page-size="PageSize"
              layout="total, sizes, prev, pager, next, jumper" :total="totalCount">
            </el-pagination>
          </div>
        </div>
      </div>

      <!--弹出框-->
      <el-dialog v-model="dialogVisible" :title="tancontent.title" :width="this.searchwidth"
        :before-close="handleCloseSenior">
        <el-form ref="seniorform" :model="seniorform">
          <div style="max-height: 450px;overflow: hidden;overflow-y:visible;padding-left:5%;margin: 20px 0;">
            <el-form-item>
              <!--以下是左侧字-->
              <maininput :myinputoneisshow="tancontent.content.myinputoneisshow"
                :myinputone="tancontent.content.myinputone"></maininput>
              <!--以下是上方字-->
              <maininput :myinputishow="tancontent.content.myinputishow" :myinput="tancontent.content.myinput">
              </maininput>
              <mainselect :myselectisshow="tancontent.content.myselectisshow" :myselect="tancontent.content.myselect">
              </mainselect>
              <mainselect :myselectcityisshow="tancontent.content.myselectcityisshow"
                :myselectcity="tancontent.content.myselectcity"></mainselect>
            </el-form-item>
          </div>
          <el-form-item>
            <div style="text-align: center" class="main_one_button">
              <el-button
                @click="resetFormSenior(tancontent.content.myinputone, tancontent.content.myinput, tancontent.content.myselect, tancontent.content.myselectcity)">
                <i class="el-icon-refresh-left"></i> 重置
              </el-button>
              <el-button type="primary"
                @click="onSubmitSenior(tancontent.content.myinputone, tancontent.content.myinput, tancontent.content.myselect, tancontent.content.myselectcity)"
                class="main_two_search">确定
              </el-button>
            </div>
          </el-form-item>
        </el-form>
      </el-dialog>
    </div>
  </div>
</template>

<script>
import mainone from '@/components/Mainone'
import maintwo from '@/components/Maintwo'
import maininput from '@/components/Maininput'
import mainselect from '@/components/Mainselect'
import { ElMessage } from "element-plus";
import axios from "axios";
import { defineComponent, ref } from "vue";

export default defineComponent({
  name: "vcurriculumorder",
  data() {
    return {
      mainonebread: [
        {
          name: '订单',

        },
        {
          name: '教育管理',

        },
        {
          name: '课程订单列表',
          path: '/curriculumorder'
        }
      ], // 当前页-【面包屑导航】
      maintwoiconshow: true, // 筛选-【高级筛选按钮】
      maintwoforminfo: {
        myinputoneisshow: true, //筛选查询-左侧字+input
        myinputone: {
          divinterval: '0', //框右侧距离
          titlewidth: '74px',  //标题宽度
          inputwidth: '200px',  //input宽度
          inputinterval: '20px', //input右侧距离
          input: [
            {
              ismust: false, //是否必填，true表示必填
              title: '输入搜索：',
              placeholder: '订单编号/课程名称',
              name: 'keywords',
              content: '',
            }
          ],
        },
        selectleftisshow: true, //筛选查询-左侧字+select单框||select时间
        selectleft: {
          width: 'none',
          divinterval: '', //框右侧距离
          selectwidth: '200px',
          selectinterval: '20px', //select右侧距离
          select: [
            {
              selectisshow: true,
              ismust: false,
              title: '订单状态：',
              placeholder: '全部',
              name: 'status',
              value: '',
              options: [
                {
                  label: '全部',
                  value: '0'
                },
                {
                  label: '已报名',
                  value: '1'
                },
                {
                  label: '待付款',
                  value: '2'
                },
                {
                  label: '已付款',
                  value: '3'
                }
              ],
            }
          ]
        },
        selecttopisshow: true,
        selecttop: {
          width: "none",
          divinterval: "", //框右侧距离
          selectwidth: "200px",
          selectinterval: "20px", //select右侧距离
          select: [
            {
              selectisshow: true,
              ismust: false,
              title: "平台来源：",
              placeholder: "全部",
              name: "platform",
              value: "", //显示值，初始状况，显示值与默认值需相同
              valueisshow: true,
              valuedefault: "", //设置默认值
              options: [
                {
                  value: "",
                  label: "全部",
                },
                {
                  value: "1",
                  label: "微信自助版",
                },
                {
                  value: "2",
                  label: "支付宝自助版",
                },
              ],
            },
          ],
        },
      }, // 筛选-控件信息
      mainthreeisshowbut: false, // 数据列表中-【添加按钮】
      mainthreeisshowset: false, // 数据列表中-复选框
      mainthreeisshowope: true, // 数据列表中-操作-根据页面显示模块（整页）
      mainthreetableope: 19, // 数据列表中-操作-根据页面显示控件（整页）
      mainthreehandle: 150, // 数据列表中-操作-宽度
      mainthreetabletitle: [
        {
          fixedstatu: 'left',
          prop: 'trade_no',
          label: '订单编号',
          width: '250'
        },
        {
          fixedstatu: false,
          prop: 'course_name',
          label: '平台来源',
          width: '300'
        },
        {
          fixedstatu: false,
          prop: 'course_name',
          label: '课程名称',
          width: '300'
        },
        {
          fixedstatu: false,
          prop: 'status',
          label: '订单状态',
          width: '150'
        },
        {
          fixedstatu: false,
          prop: 'addtime',
          label: '支付/报名时间',
          width: '215'
        },
        {
          fixedstatu: false,
          prop: 'uid',
          label: '用户ID',
          width: '200'
        },
        {
          fixedstatu: false,
          prop: 'pay_type',
          label: '支付方式',
          width: '150'
        },
        {
          fixedstatu: false,
          prop: 'course_price',
          label: '课程价格',
          width: '150'
        },
        {
          fixedstatu: false,
          prop: 'price',
          label: '实付金额',
          width: ''
        },
      ], // 数据列表中-数据表头
      mainthreestatus: true, // 数据列表中-属于search按钮表示状态
      mainthreetableData: [],// 数据列表中-具体数据
      totalCount: 1, //数据列表中-数据总条数
      currentpage: 1, //数据列表中-数据当前页数
      lastpage: 1, //数据列表中-数据总页数（默认以20条一页）
      loading: false, //初始化-loading加载动效，默认是false关闭

      //table表单相关数据
      seniorform: {},
      currentPage: 1,
      pageSizes: [20, 50, 100, 1000],
      PageSize: 20,
      multipleSelection: [],
      thispagename: '', //通过路由获取当前页面的path值
      searchwidth: '580px',
      tancontent: {
        title: '弹框标题',
        content: {},
      },//弹框
      //高级筛选
      myinputishow: true, //高级筛选-input单框
      myinput: {
        divinterval: "3%", //框右侧距离
        inputwidth: "47%",
        inputmaxwidth: "100%",
        input: [
          {
            ismust: false, //是否必填，true表示必填
            title: "输入搜索：",
            placeholder: "订单编号/课程名称",
            name: "keywords",
            content: "",
          },
          {
            ismust: false, //是否必填，true表示必填
            title: "输入搜索",
            placeholder: "用户ID",
            name: "uid",
            content: "",
          },
        ],
      },
      myselectisshow: true, //高级筛选-select单框
      myselect: {
        divinterval: "3%", //框右侧距离
        selectwidth: "47%",
        selectmaxwidth: "100%",
        select: [
          {
            selectisshow: true,
            ismust: false,
            title: "平台来源：",
            placeholder: "全部",
            name: "platform",
            value: "1", //显示值，初始状况，显示值与默认值需相同
            options: [
              {
                value: "",
                label: "全部",
              },
              {
                value: "1",
                label: "微信自助版",
              },
              {
                value: "2",
                label: "支付宝自助版",
              },
            ],
          },
          {
            selectisshow: true,
            ismust: false,
            title: "状态:",
            placeholder: "全部",
            name: "status",
            value: "",
            options: [
              {
                label: "全部",
                value: "0",
              },
              {
                label: "已报名",
                value: "1",
              },
              {
                label: "待付款",
                value: "2",
              },
              {
                label: "已付款",
                value: "3",
              },
            ],
          },
          {
            selectisshow: true,
            ismust: false,
            title: "支付方式:",
            placeholder: "全部",
            name: "pay_type",
            value: "",
            options: [
              {
                label: "全部",
                value: "0",
              },
              {
                label: "微信支付",
                value: "2",
              },
              {
                label: "余额支付",
                value: "1",
              },
            ],
          },
          {
            selecttimeisshow: true,
            ismust: false,
            title: "支付时间：",
            placeholder: "请选择时间",
            name: "addtime",
            value: "",
          },
        ],
      },
      mytoken: localStorage.getItem('token'),  //获取存储的token
      pagesdatas: [], //存储最近一次的筛选条件，供分页使用
    }
  },
  setup() {
    const dialogVisible = ref(false);
    const handleCloseSenior = (done) => {
      done()
    }

    return {
      dialogVisible,
      handleCloseSenior,
    }
  },
  mounted() {
    //获取当前页面路由path值
    this.thispagename = this.$route.path;
  },
  methods: {
    timestampToTime(row, column) {
      console.log("---column:")
      console.log(column)
      var date = new Date(row.addtime) //时间戳为10位需*1000，时间戳为13位的话不需乘1000
      var Y = date.getFullYear() + '-'
      var M = (date.getMonth() + 1 < 10 ? '0' + (date.getMonth() + 1) : date.getMonth() + 1) + '-'
      var D = date.getDate() + ' '
      var h = date.getHours() + ':'
      var m = date.getMinutes() + ':'
      var s = date.getSeconds()
      return Y + M + D + h + m + s

    },
    //table表单-点击单位格-带参跳转等
    handleOneData(row, column, cell, event) {
      console.log(row);  //获取当前行数据
      console.log(column); //获取当前单位格信息 ，通过column.property可获取当前单元的title得name值
      console.log(event); //获取当前指针事件
      console.log(event.path[1].id)  //获取当前指针所指向的单元格id值，配合标签的id使用

      //获取所点击按钮的id值-并根据不同的id值进行跳转&传值等
      if (event.path[1].id) {
        //由操作-合作-公司列表-向-公司关联设备页面传值
        if (event.path[1].id === 'companydvcid') {
          this.$router.push({ path: '/companydvclist', query: { id: row.id, name: row.name } }).catch(error => error)
        }
        //由操作-合作-设备合伙人列表-向-合伙人关联设备页面传值
        if (event.path[1].id === 'partnerdvcid') {

          this.$router.push({ path: '/partnerdvclist', query: { id: row.id } }).catch(error => error)
        }
      }

      console.log('当前的column.property 是：' + column.property)
      if (column.property === 'secondagent') {
        //跳转二级代理
        this.$router.push('/agentcensustwo').catch(error => error)
      }
    },
    //table表-操作中-删除按钮（函数）
    deleteRow(index, rows) {
      //在table表中删除当前行信息，而rows.splice(index, 1)仅属于前端删除，可配合后具体操作
      rows.splice(index, 1)
    },
    //table表中-当选项发生变化是触发该函数
    handleSelectionChange(val) {
      console.log('this is:' + val)
      this.multipleSelection = val
    },
    //分页器中-当选择条数时触发该函数
    handleSizeChange(val) {
      console.log(`每页 ${val} 条`)
      this.PageSize = val
      this.currentPage = 1
      //通过Ajax向后台获取数据
      axios.post('/manage/Course/orderIndex',
        {
          keywords: this.pagesdatas.keywords,
          uid: this.pagesdatas.uid,
          status: this.pagesdatas.status,
          extend: this.pagesdatas.extend,
          platform: this.pagesdatas.platform,
          pay_type: this.pagesdatas.pay_type,
          addtime: this.pagesdatas.addtime,
          page_size: this.PageSize,
          page: "1"
        },
        {
          headers: {
            Auth: localStorage.getItem("token")
          }
        })
        .then(response => {
          this.myaxiosfunc(response.data.result)
        })
        .catch(function (error) { // 请求失败处理
          console.log(error)
        })
    },
    //分页器中-当选择分页时触发该函数
    handleCurrentChange(val) {
      console.log(`当前页: 第${val}页`)
      this.currentPage = val

      //加载loading
      this.loading = true
      //通过Ajax向后台获取数据
      axios.post('/manage/Course/orderIndex',
        {

          keywords: this.pagesdatas.keywords,
          uid: this.pagesdatas.uid,
          status: this.pagesdatas.status,
          extend: this.pagesdatas.extend,
          platform: this.pagesdatas.platform,
          pay_type: this.pagesdatas.pay_type,
          addtime: this.pagesdatas.addtime,
          page_size: "20",
          page: this.currentPage
        },
        {
          headers: {
            Auth: localStorage.getItem("token")
          }
        })
        .then(response => {
          this.myaxiosfunc(response.data.result)
        })
        .catch(function (error) { // 请求失败处理
          console.log(error)
        })
    },
    //table表中-表格带状态-可扩展
    tableRowClassName({ rowIndex }) {
      //隔行显示
      if ((rowIndex + 1) % 2 === 0) {
        return 'warning-row'
      }
      return ''
    },
    //点击按钮-弹出弹框并赋值弹窗基本配置
    addTanClick(taninfo) {
      console.log(taninfo)
      this.tancontent.content = taninfo.content
      this.tancontent.title = taninfo.title
    },
    //点击进行路由页面跳转以及携带参数
    routeraddrClick(routeraddr) {
      this.$router.push(routeraddr).catch(error => error)
    },
    //导出数据
    exportDatas(tableData) {
      console.log(tableData);
    },
    //弹出框-提交表单
    onSubmitSenior(myinputone, myinputform, myselectform, myselectcityform) {
      this.dialogVisible = false; //隐藏高级筛选模块
      //获取输入框字段与字段名并合成json数据
      var seniorformlist = {};

      if (myinputone) {
        for (var m = 0; m < myinputone.input.length; m++) {
          seniorformlist[myinputone.input[m].name] = myinputone.input[m].content
        }
      }
      if (myinputform) {
        for (var i = 0; i < myinputform.input.length; i++) {
          seniorformlist[myinputform.input[i].name] = myinputform.input[i].content
        }
      }
      if (myselectform) {
        for (var j = 0; j < myselectform.select.length; j++) {
          seniorformlist[myselectform.select[j].name] = myselectform.select[j].value
          if (myselectform.select[j].selecttimeduanisshow === true && myselectform.select[j].value.valuestart !== '') {
            if (myselectform.select[j].value.valueend === '') {
              seniorformlist[myselectform.select[j].name] = '';
              ElMessage({
                message: '错误：结束时间不能为空',
                type: 'error',
                offset: 70,
              })
            }
          }
        }
      }
      if (myselectcityform) { // !myselectcityform【!+参数 在if条件中表示检测该参数未定义】
        if (myselectcityform.select.valuequ !== '') {
          if (myselectcityform.select.valuequ === '0' || myselectcityform.select.valuequ === '暂无相关县/区') { //这里的判断根据Maintwo中的valuequ值而判断
            seniorformlist[myselectcityform.select.name] = myselectcityform.select.valueshi
          } else {
            seniorformlist[myselectcityform.select.name] = myselectcityform.select.valuequ
          }
        } else {
          if (myselectcityform.select.valueshi !== '') {
            if (myselectcityform.select.valueshi === '0' || myselectcityform.select.valueshi === '暂无相关市') {
              seniorformlist[myselectcityform.select.name] = myselectcityform.select.valuesheng
            } else {
              seniorformlist[myselectcityform.select.name] = myselectcityform.select.valueshi
            }
          } else {
            seniorformlist[myselectcityform.select.name] = myselectcityform.select.valuesheng
          }
        }
      }
      //将数据传向父组件
      this.$emit('searchFormtan', seniorformlist)
      console.log(JSON.stringify(seniorformlist))

    },
    //弹出框-重置
    resetFormSenior(myinputone, myinputform, myselectform, myselectcityform) {
      if (myinputone) {
        for (var m = 0; m < myinputone.input.length; m++) {
          myinputone.input[m].content = ''
        }
      }
      if (myinputform) {
        for (var i = 0; i < myinputform.input.length; i++) {
          myinputform.input[i].content = ''
        }
      }
      if (myselectform) {
        for (var j = 0; j < myselectform.select.length; j++) {
          if (myselectform.select[j].selecttimeduanisshow === true) {
            myselectform.select[j].value.valuestart = ''
            myselectform.select[j].value.valueend = ''
            myselectform.select[j].value.endstatus = true
          } else {
            myselectform.select[j].value = ''
          }
        }
      }
      if (myselectcityform) { // !myselectcityform【!+参数 在if条件中表示检测该参数未定义】
        console.log(myselectcityform);
        myselectcityform.select.valuesheng = '';
        myselectcityform.select.valueshi = '';
        myselectcityform.select.valuequ = '';
        myselectcityform.select.disshi = true;
        myselectcityform.select.disqu = true
      }

    },
    //获取当前windows页面信息-实时修改【弹窗】的宽度
    getwindowinfo() {
      this.sreenheight = document.documentElement.clientHeight;
      this.sreenwidth = document.documentElement.clientWidth;
      if (this.sreenwidth < 600) {
        this.searchwidth = (this.sreenwidth * 0.8) + 'px';
      } else {
        this.searchwidth = '580px'
      }
    },
    // 高级搜索
    searchFormEvent(data) {
      this.pagesdatas = data;//将筛选条件赋值给本页的pagesdatas存储，供分页使用
      //加载loading
      this.loading = true
      //通过Ajax向后台获取数据
      axios.post('/manage/Course/orderIndex',
        {

          keywords: data.keywords,
          uid: data.uid,
          status: data.status,
          extend: data.extend,
          platform: data.platform,
          pay_type: data.pay_type,
          addtime: data.addtime,
          page_size: "20",
          page: "1"
        },
        {
          headers: {
            Auth: localStorage.getItem("token")
          }
        })
        .then(response => {
          this.myaxiosfunc(response.data.result)
        })
        .catch(function (error) { // 请求失败处理
          console.log(error)
        })
    },
    //提交等相关event事件函数
    //获取Container-Pagedata-Maintwo-搜索-返回的数据
    getSeachinfoEvent(data) {
      console.log('点击查询获取的data数组：' + JSON.stringify(data))
      this.pagesdatas = data;//将筛选条件赋值给本页的pagesdatas存储，供分页使用
      //加载loading
      this.loading = true
      //通过Ajax向后台获取数据
      axios.post('/manage/Course/orderIndex',
        {

          keywords: data.keywords,
          uid: data.uid,
          status: data.status,
          extend: data.extend,
          platform: data.platform,
          pay_type: data.pay_type,
          addtime: data.addtime,
          page_size: "20",
          page: "1",

        },
        {
          headers: {
            Auth: localStorage.getItem("token")
          }
        })
        .then(response => {
          this.myaxiosfunc(response.data.result)
        })
        .catch(function (error) { // 请求失败处理
          console.log(error)
        })
    },
    //数据渲染
    myaxiosfunc(successdata) {
      var newdata = successdata.data
      for (var i = 0; i < newdata.length; i++) {
        //该字段表示search按钮，且status为1表示启用，2表示禁用
        // newdata[i].switchstatus = 4;
        newdata[i].addtime = this.$utils.formatDate(newdata[i].addtime)
      }
      // console.log('当前页面的data:' + JSON.stringify(newdata))
      this.mainthreetableData = newdata;
      this.totalCount = successdata.total
      this.currentpage = successdata.current_page; //当前页面页数
      this.lastpage = successdata.last_page;
      this.loading = false;
    }
  },
  watch: {
    //检测父组件传来的currentpagenum值变化
    currentpagenum: function () {
      // console.log('检测到的数据：' + this.currentpagenum)
      if (this.currentpagenum) {
        this.currentPage = this.currentpagenum
      } else {
        this.currentPage = 1
      }
    }
  },
  created() {
    if (localStorage.getItem("token") === null || !localStorage.getItem("token")) {
      this.isshow = false
      this.$router.push('/').catch(error => error)
    } else {
      window.addEventListener('resize', this.getwindowinfo); // 注册监听器-实时检测浏览器页面宽高
      this.getwindowinfo()

      //加载loading
      this.loading = true      //通过Ajax向后台获取数据
      //通过Ajax向后台获取数据
      axios.post('/manage/Course/orderIndex',
        {
          page: "1",
          page_size: "20",
        },
        {
          headers: {
            Auth: localStorage.getItem("token")
          }
        })
        .then(response => {
          console.log("----->>")
          console.log(response.data)
          this.myaxiosfunc(response.data.result)
        })
        .catch(function (error) {
          console.log(error)
        })
    }
  },
  components: {
    mainone,
    maintwo,
    maininput,
    mainselect,
  }
});
</script>

<style scoped>
/*局部控制-Switch按钮-按钮中显示状态文字*/
/* switch按钮款式 */
#switch :deep(.el-switch__label) {
  position: absolute;
  display: none;
  color: #fff !important;
}

/*关上时文字地位设置*/
#switch :deep(.el-switch__label--right) {
  z-index: 1;
}

/* 调整关上时文字的显示位子 */
#switch :deep(.el-switch__label--right) span {
  margin-left: 3px;
  font-size: 12px;
}

/*敞开时文字地位设置*/
#switch :deep(.el-switch__label--left) {
  z-index: 1;
}

/* 调整敞开时文字的显示位子 */
#switch :deep(.el-switch__label--left) span {
  margin-left: 15px;
  font-size: 12px;
}

/*显示文字*/
#switch :deep(.el-switch__label.is-active) {
  display: block;
}

/* 调整按钮的宽度 */
#switch :deep(.el-switch) .el-switch__core,
#switch :deep(.el-switch) .el-switch__label {
  width: 55px !important;
  margin: 0;
  padding: 5px;
}

/*操作中-按钮样式*/
.el-button--mini {
  min-height: 28px;
  padding: 0 8px;
  font-size: 12px;
  border-radius: calc(var(--el-border-radius-base) - 1px);
}
</style>