<template>
    <div class="wrap">
        <el-dialog v-model="dialogDisplay" :title="formData.id ? '编辑' : '添加'">
            <el-form ref="ruleFormRef" :model="formData" :rules="rules" label-width="150px" style="padding: 0 20px 0 0">
                <el-form-item :label="formData?.pid ? '二级名称：' : '一级名称' " prop="name">
                    <el-input v-model="formData.name" style="width: 80%" placeholder="请输入名称"></el-input>
                </el-form-item>
                <div style="text-align: right">
                    <el-button @click="resetEvent">取消 </el-button>
                    <el-button type="primary" @click="onSubmit">确定 </el-button>
                </div>
            </el-form>
        </el-dialog>
    </div>
</template>

<script>
import axios from 'axios'
import { ElMessage } from 'element-plus'

export default {
    name: 'FormDialog',
    props: {
        tagsgroupData: {
            type: Object,
            default: () => {
                return {
                    dialog: false,
                    data: {},
                    api: '',
                }
            }
        }
    },
    data() {
        return {
            dialogDisplay: false,
            formData: {
                id: undefined,
                name: '',
            },

            rules: {
                name: [
                    {
                        required: true,
                        message: '名称不能为空',
                        trigger: 'blur'
                    }
                ],
            },

            mypowerlimits: JSON.parse(localStorage.getItem('powerlimits')), //获取权限
            mytoken: localStorage.getItem('token'), //获取存储的token
            myuserinfo: localStorage.getItem('userinfo') //获取存储的用户信息
        }
    },

    watch: {
        tagsgroupData: {
            handler(newVal) {
                this.dialogDisplay = newVal.dialog
                this.formData = {
                    id: newVal.data.id || undefined,
                    pid: newVal.data.pid || undefined,
                    name: newVal.data.name || '',
                }
                this.api = newVal.api
            },
            deep: true,
            immediate: true
        }
    },

    methods: {
        //发行量弹框确认
        async onSubmit() {
            try{
                await this.$refs['ruleFormRef'].validate()
            }catch(err){
                console.log({err})
                return false
            }

            try {
                const { data } = await axios.post(this.tagsgroupData.api, this.formData, { headers: { Auth: localStorage.getItem('token')} })

                if (data.code === 0) {
                    this.dialogDisplay = false
                    ElMessage({
                        type: 'success',
                        message: '操作成功！',
                        duration: 1000,
                        onClose: () => {
                            this.$emit('success', true)
                        }
                    })
                }

            } catch (error) {
                console.log(error)
            }
        },

        //增加发行量输入框弹出框-取消
        resetEvent() {
            this.$refs['ruleFormRef'].resetFields() //重置弹窗内容
            this.dialogDisplay = false
        }
    },
}
</script>

<style scoped>
.tips-box {
    height: 20px;
    line-height: 20px;
}
</style>
