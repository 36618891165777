<template>
    <div>
        <div>
            <el-button :type="button.type" :plain="button.plain" :size="button.size" @click="tanClickEvent()">{{ button.text }}</el-button>
        </div>
        <!-- 弹窗 -->
        <div>
            <el-dialog v-model="tanbox.isshow" :title="dialog.title" :width="dialog.width" append-to-body :before-close="(done) => handleClose(done, 'tanNumbersRef')">
                <div>
                    <el-form ref="tanNumbersRef" :model="tanNumbers" :rules="tanNumbers_rules">
                        <el-form-item prop="number" label="实收数量：">
                            <el-input v-model.number="tanNumbers.number" clearable placeholder="请输入实收数量" maxlength="20"></el-input>
                        </el-form-item>
                    </el-form>
                </div>
                <div style="text-align: right; margin: 20px 0">
                    <el-button @click="cancelEvent('tanNumbersRef')">取消</el-button>
                    <el-button type="primary" @click="submitEventText('tanNumbersRef')">确定</el-button>
                </div>
            </el-dialog>
        </div>
    </div>
</template>

<script>
import { defineComponent } from 'vue'

export default defineComponent({
    name: 'numberEnter',
    props: {
        button: {
            type: Object,
            default: () => {
                return {
                    text: '数量',
                    size: 'small',
                    type: 'primary',
                    plain: false
                }
            }
        },
        dialog: {
            type: Object,
            default: () => {
                return {
                    title: '数量',
                    width: '30%'
                }
            }
        },
        vmodel: {
            type: String,
            default: ''
        }
    },
    data() {
        return {
            tanbox: {
                isshow: false
            },
            tanNumbers: {
                number: ''
            },
            tanNumbers_rules: {
                number: [
                    {
                        required: true,
                        message: '实收数量为必填项',
                        trigger: 'change'
                    },
                    {
                        type: 'integer',
                        message: '实收数量必须为数字',
                        trigger: 'change'
                    },
                    {
                        // 正则表达式(大于等于0的正整数)：/^\+?[0-9]\d*$/  或 /^\d\d*$/
                        pattern: /^\d\d*$/,
                        message: '实收数量必须为正整数',
                        trigger: 'change'
                    }
                ]
            }
        }
    },
    methods: {
        tanClickEvent() {
            this.tanbox.isshow = true
            this.tanNumbers.number = this.vmodel
        },
        submitEventText(formName) {
            let getdatas = this.tanNumbers
            this.$refs[formName].validate((valid) => {
                if (valid) {
                    let number = getdatas.number
                    this.$emit('tanPostData', { number: number })
                    this.tanbox.isshow = false
                } else {
                    return false
                }
            })
        },
        cancelEvent(formName) {
            this.$refs[formName].resetFields()
            this.tanbox.isshow = false
        },
        handleClose(done, formName) {
            this.cancelEvent(formName)
            done()
        }
    }
})
</script>

<style scoped></style>
