<template>
  <div>
    <div>
      <!-- button按钮 -->
      <div>
        <el-button
          type="primary"
          @click="resetFormSenior((dialogVisible = true))"
          plain
          size="small"
          >选择微信</el-button
        >
      </div>
      <!-- 微信头像信息显示 -->
      <div v-if="ruleForm.union.length != 0">
        <div style="display: flex; flex-direction: row; flex-wrap: wrap">
          <div
            style="width: 100px; margin: 20px 5px 0 0"
            v-for="(getavarars, i) in ruleForm.union"
            :key="i"
          >
            <div style="display: flex; flex-direction: column">
              <div
                style="
                  display: flex;
                  flex-direction: row;
                  width: 100px;
                  justify-content: center;
                "
              >
                <div>
                  <el-avatar
                    :src="getavarars.avatar"
                    :size="48"
                    style="border: 1px solid #dcdada; vertical-align: middle"
                  ></el-avatar>
                  <i
                    class="el-icon-error"
                    style="
                      color: red;
                      float: right;
                      font-weight: 400;
                      cursor: pointer;
                    "
                    @click="delWechatClient(i)"
                  ></i>
                </div>
              </div>
              <div style="text-align: center">
                <span style="font-size: 12px; color: #333333">{{
                  getavarars.nickname
                }}</span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- 弹出框 -->
    <el-dialog
      v-model="dialogVisible"
      title="选择微信"
      :width="searchwidth"
      :before-close="handleCloseSenior"
    >
      <el-form ref="seniorform" v-model="seniorform">
        <el-form-item>
          <el-input
            v-model="seniorform.search"
            placeholder="请输入微信昵称/手机号"
            style="max-width: 200px; margin-right: 20px"
            clearable
          ></el-input>
          <el-button type="primary" @click="searchMytableData(seniorform)"
            >查询</el-button
          >
        </el-form-item>
      </el-form>
      <el-table
        :data="tableData"
        style="width: 100%; margin-top: 20px"
        min-height="200"
        border
        v-loading="loading"
        element-loading-text="正在飞速加载中..."
        @selection-change="handleSelectionChange"
      >
        <el-table-column type="selection" width="55" />
        <el-table-column label="ID" prop="id" />
        <el-table-column label="头像" prop="avatar">
          <template #header>
            <div>头像</div>
          </template>
          <template #default="scope">
            <el-avatar
              :src="scope.row.avatar"
              style="border: 1px solid #dcdada"
            ></el-avatar>
          </template>
        </el-table-column>
        <el-table-column label="昵称" prop="nickname" />
        <el-table-column label="手机号" prop="mobile" width="150" />
      </el-table>
      <el-row style="margin-top: 10px">
        <el-col :span="24">
          <div style="text-align: right">
            <el-button @click="resetFormSenior((dialogVisible = false))"
              >取消</el-button
            >
            <el-button
              type="primary"
              @click="
                onSubmitSenior(
                  tableseniorForm,
                  ruleForm,
                  (dialogVisible = false)
                )
              "
              >确定</el-button
            >
          </div>
        </el-col>
      </el-row>
    </el-dialog>
  </div>
</template>

<script>
import axios from "axios";
import { ElMessage } from "element-plus";
import { defineComponent, reactive } from "vue";

export default defineComponent({
  name: "Mainweichatmore",
  data() {
    return {
      // form表单
      ruleForm: {
        union: this.unionData,
      },
      //弹出框
      dialogVisible: false,
      searchwidth: "920px",
      //弹出框中-搜索条件
      seniorform: {
        search: "",
      },
      //弹出框中-table 表单
      tableData: [],
      tableseniorForm: {
        union: [],
      },
      loading: false, //弹出框中-table表单-加载动效

      mypowerlimits: JSON.parse(localStorage.getItem("powerlimits")), //获取权限
      mytoken: localStorage.getItem("token"), //获取存储的token
    };
  },

  props: ["uniondata"],
  setup(props) {
    const unionData = reactive(props["uniondata"]);

    return {
      unionData,
    };
  },
  watch: {
    //检测父组件传来的uniondata值变化
    uniondata: function () {
      // console.log('检测到的数据：' + JSON.stringify(this.uniondata))
      if (this.uniondata) {
        this.ruleForm.union = this.uniondata;
      }
    },
  },
  methods: {
    //删除【逻辑】-微信
    delWechatClient(ide) {
      this.ruleForm.union.splice(ide, 1);
      this.$emit("getuniondata", this.ruleForm.union);
    },
    //保存-出入提现提醒
    submitFormSave(formName) {
      var getunionids = [];
      this.$refs[formName].model.union.forEach((element) => {
        getunionids.push(element.unionid);
      });
      console.log("unionids is :" + getunionids);
      //通过Ajax向后台获取数据
      axios
        .post(
          "/manage/plat/warnUser",
          {
            unionid: getunionids,
          },
          {
            headers: {
              Auth: localStorage.getItem("token"),
            },
          }
        )
        .then((response) => {
          if (response.data.code === 0) {
            ElMessage({
              type: "success",
              message: "保存成功！",
            });
          }
        })
        .catch(function (error) {
          console.log(error);
        });
    },
    // 弹窗中-搜索查询
    searchMytableData(datas) {
      //table表单加载动效
      this.loading = true;
      //通过Ajax向后台获取数据
      axios
        .get(
          "/manage/Equipment/wechatUser",
          {
            headers: {
              Auth: localStorage.getItem("token"),
            },
            params: {
              keywords: datas.search,
            },
          }
        )
        .then((response) => {
          if (response.data.code === 0) {
            this.tableData = response.data.result;
            this.loading = false;
          }
        })
        .catch(function (error) {
          console.log(error);
        });
    },
    //弹窗中-table表单-多选按钮
    handleSelectionChange(val) {
      // 存储所选择的设备
      this.tableseniorForm.union = val;
    },
    //弹窗中-确认-提交按钮
    onSubmitSenior(datas, ruledatas) {
      // 判断所选-微信是否重复-并将重复的去除
      for (let i = 0; i < datas.union.length; i++) {
        const getdatas = datas.union[i];
        for (let j = 0; j < ruledatas.union.length; j++) {
          const getunion = ruledatas.union[j];
          if (getdatas.unionid == getunion.unionid) {
            ElMessage({
              type: "warning",
              message: "微信为(" + getunion.nickname + " )已存在，无需重复添加",
              offset: (j + 1) * 60,
            });
            //去除重复-并补位为null
            datas.union.splice(i, 1, null);
          }
        }
      }
      //去除数组中的null值
      datas.union = datas.union.filter((n) => n);
      //将去重之后的数据-存入
      for (let index = 0; index < datas.union.length; index++) {
        const element = datas.union[index];
        this.ruleForm.union.push(element);
      }
      this.$emit("getuniondata", this.ruleForm.union);
    },
    //弹窗中-取消【重置】-提交按钮
    resetFormSenior() {
      this.tableData = [];
      this.seniorform.search = "";
    },
    //获取当前windows页面信息-实时修改【弹窗】的宽度
    getwindowinfo() {
      this.sreenheight = document.documentElement.clientHeight;
      this.sreenwidth = document.documentElement.clientWidth;
      if (this.sreenwidth < 950) {
        this.searchwidth = this.sreenwidth * 0.8 + "px";
      } else {
        this.searchwidth = "920px";
      }
    },
  },
});
</script>

<style scoped>
</style>