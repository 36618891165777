<template>
  <div class="relation-device">
    
    <template v-if="!onlyDialog">
      <div class="container-box" @click="openDialog">
        <div v-if="deviceIdSelected.length" class="val-content">
          <template v-for="(client, t) in deviceIdSelected" :key="t">
            <el-tag closable @close="removeTag(t)">{{ client }}</el-tag>
          </template>
        </div>
        <p v-else class="placeholder">请选择设备</p>
        <i class="el-icon-arrow-down suffix-icon"></i>
      </div>
      
      <div class="text-link">
        <el-link
          type="primary"
          :href="templateUrl"
          download
          target="_blank"
          :underline="false"
        >下载模板</el-link>
        <el-link type="primary" :underline="false" @click="batchImport">批量导入</el-link>
        <input
          ref="uploadInputEle"
          style="display: none"
          type="file"
          :accept="acceptArray.join(',')"
          @change="onUploadChange"
        />
      </div>
    </template>
    
    
    <el-dialog
      title="选择设备"
      v-model="dialogVisible"
      width="840px"
      @close="closeDialog"
    >
      <div class="dialog-content">
        <el-form
          ref="searchFormEle"
          :model="searchForm"
          class="search-form"
          inline
          @keydown.enter="onSearch"
        >
          <el-form-item label="输入搜索" prop="name">
            <el-input v-model="searchForm.name" placeholder="设备编号" />
          </el-form-item>
          <el-form-item label="地址" prop="area_code">
<!--            <el-input placeholder="输入地址名" />-->
<!--            <xw-cascader v-model="searchForm.area_code" />-->
            <AreaSelect ref="areaSelectEle" v-model="searchForm.area_code" />
          </el-form-item>
          <el-form-item>
            <el-button type="primary" @click="onSearch">查询</el-button>
            <el-button @click="onSearchReset">重置</el-button>
          </el-form-item>
        </el-form>
        <el-table
          ref="deviceTableEle"
          class="table-data"
          :data="deviceList"
          border
          height="500px"
          row-key="id"
          v-loading="deviceTableLoading"
          :header-cell-style="{backgroundColor: '#f7f8f7f8'}"
          @selectionChange="selectionChange"
          @select="onSelect"
        >
          <el-table-column
            v-if="!onlyDialog"
            type="selection"
            reserve-selection
          />
          <el-table-column label="ID" prop="id" width="100" />
          <el-table-column label="设备编号" prop="client" width="130" />
          <el-table-column label="代理商名称" prop="shop_code_name" />
          <el-table-column label="设备地址" prop="address" />
        </el-table>
        <div class="page-container">
          <el-pagination
            v-model:current-page="pagination.page"
            v-model:page-size="pagination.page_size"
            :total="pagination.total"
            layout="total, sizes, prev, pager, next, jumper"
            :page-sizes="[10, 20, 50, 100]"
            background
            @sizeChange="getDeviceList"
            @currentChange="getDeviceList"
          />
        </div>
      </div>
      <template #footer>
        <el-button @click="closeDialog">取消</el-button>
        <el-button type="primary" @click="submit">确定</el-button>
      </template>
    </el-dialog>
    
  </div>
</template>

<script setup>
import {ref, nextTick, computed} from 'vue'
import {ElMessage} from 'element-plus'
import {useAxios} from '../useAxios'
import AreaSelect from './areaSelect.vue'

const props = defineProps(['modelValue', 'onlyDialog'])
const emits = defineEmits(['update:modelValue'])

const templateUrl = ref('')

const areaSelectEle = ref()
const acceptArray = [
  'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
]
// 已选项
const selectionArr = ref([])
const deviceIdSelected = computed({
  get(){
    return props.modelValue ? props.modelValue.split(',') : []
  },
  set(val){
    emits('update:modelValue', val.join(','))
  }
})

const searchFormEle = ref()
const searchForm = ref({
  name: '',
  area_code: []
})

// 只显示弹窗时，暂存设备id
const deviceIds = ref('')

const dialogVisible = ref(false)
const deviceTableEle = ref()
const deviceTableLoading = ref(false)
const deviceList = ref([])
const pagination = ref({
  page: 1,
  page_size: 10,
  total: 0
})
const getDeviceList = () => {
  deviceTableLoading.value = true
  const params = {
    ...searchForm.value,
    area_code: searchForm.value.area_code[searchForm.value.area_code.length-1],
    page: pagination.value.page,
    page_size: pagination.value.page_size
  }
  if(props.onlyDialog) {
    params.clientId = deviceIds.value
    params.client = searchForm.value.name
    delete params.name
  }
  
  nextTick(() => {
    // deviceTableEle.value?.clearSelection()
  })
  useAxios(
    props.onlyDialog ? 'manage/Equipment/clientAll' : `manage/Equipment/clientList`,
    props.onlyDialog ? 'post' : 'get',
    {...params}
  ).then(res => {
    const {data, total} = res.data.result
    deviceList.value = data
    pagination.value.total = total
    if(deviceIdSelected.value.length) {
      deviceIdSelected.value.forEach(i => {
        const row = data.find(d => d.client === i)
        if(row) deviceTableEle.value.toggleRowSelection(row, true)
      })
    }
  }).finally(() => {
    deviceTableLoading.value = false
  })
}

// search
const onSearch = () => {
  pagination.value.page_size = 10
  pagination.value.page = 1
  pagination.value.total = 0
  getDeviceList()
}
const onSearchReset = () => {
  searchFormEle.value.resetFields()
  areaSelectEle.value.clear()
  nextTick(() => {
    searchForm.value.area_code = []
    onSearch()
  })
}
const selectionChange = (e) => {
  selectionArr.value = e
}
const onSelect = (selection, row) => {
  const isSelected = selection.find(i => i.id === row.id)
  if(!isSelected) {
    const index = deviceIdSelected.value.findIndex(i => i === row.client)
    deviceIdSelected.value.splice(index, 1)
  }
}

// 移除
const removeTag = (index) => {
  deviceIdSelected.value.splice(index, 1)
  // if(selectionArr.value[index]) deviceTableEle.value?.toggleRowSelection(selectionArr.value[index])
  nextTick(() => {
    emits('update:modelValue', deviceIdSelected.value.join(','))
  })
}

const openDialog = (ids) => {
  if(props.onlyDialog) deviceIds.value = ids
  getDeviceList()
  dialogVisible.value = true
}
const closeDialog = () => {
  dialogVisible.value = false
  pagination.value.page = 1
  pagination.value.page_size = 10
  pagination.value.total = 0
  searchFormEle.value.resetFields()
  areaSelectEle.value.clear()
  searchForm.value.area_code = []
  deviceTableEle.value.clearSelection()
}

const getTemplateUrl = () => {
  useAxios(`manage/WozujiOrder/getTemplatePath`, 'post').then(res => {
    const url = res.data.result
    templateUrl.value = url
    // window.open(url, '_blank')
    // const link = document.createElement('a');
    // link.style.display = 'none';
    // link.href = url;
    // link._target = '_blank'
    // link.download = 'customFileName.xlsx';
    // document.body.appendChild(link);
    // link.click();
    // nextTick(() => {
    //   window.URL.revokeObjectURL(url); // 释放内存
    //   document.body.removeChild(link);
    // })
  })
}
if(!props.onlyDialog) getTemplateUrl()

const uploadInputEle = ref()
const onUploadChange = (e) => {
  const files = e.target.files
  if(!acceptArray.includes(files[0].type)) {
    ElMessage.error('格式错误')
    return null
  }
  
  const formData = new FormData()
  formData.append('file', files[0])
  
  useAxios(`/manage/WozujiOrder/uploadTemplate`, 'post', formData).then(res => {
    const ids = deviceIdSelected.value
    const list = res.data.result.list
    Object.keys(list).forEach(k => {
      ids.push(list[k])
    })
    emits('update:modelValue', ids.join(','))
  })
}
const batchImport = () => {
  uploadInputEle.value.click()
}

const submit = () => {
  const ids = Array.from(selectionArr.value).map(i => i.client)
  const setIds = new Set([...ids, ...deviceIdSelected.value])
  emits('update:modelValue', Array.from(setIds).join(','))
  closeDialog()
}

const setDeviceSelected = (arr) => {
  deviceIdSelected.value = arr
}
defineExpose({
  setDeviceSelected,
  openDialog
})
</script>

<style lang="scss" scoped>
.flex-row{
  display: flex;
  flex-direction: row;
}
.relation-device{
  //@extend .flex-row;
  .container-box{
    cursor: pointer;
    position: relative;
    box-sizing: border-box;
    min-height: 40px;
    width: 100%;
    padding: 0 30px 0 15px;
    border-radius: var(--el-border-radius-base);
    border: 1px solid var(--el-border-color-light);
    .val-content{
      @extend .flex-row;
      flex-wrap: wrap;
      margin-left: -10px;
      :deep(.el-tag){
        margin: 5px 10px 5px 0;
      }
    }
    p.placeholder{
      padding: 0;
      margin: 0;
      color: var(--el-text-color-placeholder);
    }
    .suffix-icon{
      position: absolute;
      top: 0;
      right: 5px;
      width: 25px;
      line-height: 40px;
      height: 100%;
      text-align: center;
      color: var(--el-text-color-placeholder);
      pointer-events: none;
      transition: all var(--el-transition-duration);
    }
  }
  .text-link{
    @extend .flex-row;
    justify-content: space-between;
    width: 150px;
    padding-left: 15px;
    box-sizing: border-box;
    color: var(--el-color-primary);
    .link{
      cursor: pointer;
      &:hover{
        color: var(--el-color-primary-light-3);
      }
    }
  }
}

.table-data{
  margin: 10px 0;
}
.page-container{
  @extend .flex-row;
  justify-content: center;
}
</style>
