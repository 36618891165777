const componentProperties = new Map()

// 数据版本  每次修改组件数据  需要对版本进行修改
// componentProperties.set('componentPropertiesVersion', 'V1.0.0')

// 设备-打印机状态
componentProperties.set('printstatusoption', {
    options: [{
            value: "",
            label: "全部",
        },
        {
            value: "1",
            label: "使用中",
        },
        {
            value: "10",
            label: "打印机异常",
        },
        {
            value: "3",
            label: "扫描仪异常",
        },
        {
            value: "4",
            label: "缺纸",
        },
        {
            value: "5",
            label: "夹纸",
        },
        {
            value: "6",
            label: "打印机脱机",
        },
        {
            value: "0",
            label: "空闲",
        },
        {
            value: "2",
            label: "未知",
        },
        {
            value: "11",
            label: "打印机未连接",
        },
        {
            value: "7",
            label: "异常",
        },
    ],
})

// 平台来源
componentProperties.set('thirdpartyoption', {
    options: [{
            value: "1",
            label: "微信自助版",
        },
        {
            value: "2",
            label: "支付宝自助版",
        },
        {
            value: "3",
            label: "微信企业版",
        },
        {
            value: "4",
            label: "微信家庭版",
        },
        {
            value: "5",
            label: "支付宝企业版",
        },
        {
            value: "6",
            label: "支付宝家庭版",
        },
        {
            value: "7",
            label: "微信公众号",
        },
        {
            value: "8",
            label: "支付宝生活号",
        },
    ],
    optionsall: [{
            value: "",
            label: "全部",
        },
        {
            value: "1",
            label: "微信自助版",
        },
        {
            value: "2",
            label: "支付宝自助版",
        },
        {
            value: "3",
            label: "微信企业版",
        },
        {
            value: "4",
            label: "微信家庭版",
        },
        {
            value: "5",
            label: "支付宝企业版",
        },
        {
            value: "6",
            label: "支付宝家庭版",
        },
        {
            value: "7",
            label: "微信公众号",
        },
        {
            value: "8",
            label: "支付宝生活号",
        },
    ]
})

export default componentProperties