<template>
    <div class="wrap">
        <el-dialog v-model="dialogDisplay" :title="formData.id ? '编辑' : '添加'">
            <el-form ref="ruleFormRef" :model="formData" :rules="rules" label-width="180px" style="padding: 0 20px 0 0">
                <el-form-item label="产品名称：" prop="name">
                    <el-input v-model="formData.name" style="width: 80%" placeholder="请输入产品名称"></el-input>
                </el-form-item>
                <el-form-item label="类别：" prop="pid">
                    <el-cascader v-model="formData.pid" :options="categoryList" :props="{ checkStrictly: true, label: 'name', value: 'id', children: 'child' }" style="width: 80%" placeholder="请输入类别"></el-cascader>
                </el-form-item>
                <el-form-item label="计量单位：" prop="unit">
                    <el-select v-model="formData.unit" style="width: 80%" placeholder="请输入计量单位" size="large">
                        <el-option v-for="item in unitnumList" :key="item.value" :label="item.name" :value="item.id" />
                    </el-select>
                </el-form-item>
                <el-form-item label="描述：" prop="desc">
                    <el-input v-model="formData.desc" type="textarea" style="width: 80%" placeholder="请输入描述"></el-input>
                </el-form-item>
                <el-form-item label="填报方式：" prop="in_type">
                    <el-radio-group v-model="formData.in_type">
                        <el-radio label="1">计数</el-radio>
                        <el-radio label="2">扫码</el-radio>
                    </el-radio-group>
                </el-form-item>
                <el-form-item label="出库是否上传图片：" prop="img">
                    <el-radio-group v-model="formData.img">
                        <el-radio label="1">是</el-radio>
                        <el-radio label="0">否</el-radio>
                    </el-radio-group>
                    <div>
                        如设备编号等信息
                    </div>
                </el-form-item>
                <el-form-item label="状态：" prop="status">
                    <el-radio-group v-model="formData.status">
                        <el-radio label="1">开启</el-radio>
                        <el-radio label="-1">关闭</el-radio>
                    </el-radio-group>
                </el-form-item>
                <div style="text-align: right">
                    <el-button @click="resetEvent">取消 </el-button>
                    <el-button type="primary" @click="onSubmit">确定 </el-button>
                </div>
            </el-form>
        </el-dialog>
    </div>
</template>

<script>
import axios from 'axios';
import { ElMessage } from 'element-plus';

export default {
    name: 'FormDialog',
    props: {
        tagsgroupData: {
            type: Object,
            default: () => {
                return {
                    dialog: false,
                    data: {},
                    api: '',
                };
            },
        },
    },
    data() {
        return {
            dialogDisplay: false,
            formData: {},
            categoryList: [], //类别列表
            unitnumList: [], //计量单位列表

            rules: {
                name: [
                    {
                        required: true,
                        message: '产品名称不能为空',
                        trigger: 'blur',
                    },
                ],
                pid: [
                    {
                        required: true,
                        message: '类别不能为空',
                        trigger: 'change',
                    },
                ],
                unit: [
                    {
                        required: true,
                        message: '计量单位不能为空',
                        trigger: 'change',
                    },
                ],
                in_type: [
                    {
                        required: true,
                        message: '填报方式不能为空',
                        trigger: 'change',
                    },
                ],
                img: [
                    {
                        required: true,
                        message: '是否上传图片不能为空',
                        trigger: 'change',
                    },
                ],
                status: [
                    {
                        required: true,
                        message: '状态不能为空',
                        trigger: 'change',
                    },
                ],
            },

            mypowerlimits: JSON.parse(localStorage.getItem('powerlimits')), //获取权限
            mytoken: localStorage.getItem('token'), //获取存储的token
            myuserinfo: localStorage.getItem('userinfo'), //获取存储的用户信息
        };
    },

    watch: {
        tagsgroupData: {
            handler(newVal) {
                this.getCategoryList()
                this.getUnitnumList()
                this.dialogDisplay = newVal.dialog;
                this.formData = {
                    id: newVal.data.id || undefined,
                    name: newVal.data.name || '',
                    pid: newVal.data.pid || [],
                    unit: newVal.data.unit || '',
                    desc: newVal.data.desc || '',
                    in_type: newVal.data.in_type ? String(newVal.data.in_type) : '1',
                    img: newVal.data.img ? String(newVal.data.img) : '0',
                    status: newVal.data.status ? String(newVal.data.status) : '1',
                };
                this.api = newVal.api;
            },
            deep: true,
            immediate: true,
        },
    },

    methods: {
        //发行量弹框确认
        async onSubmit() {
            try {
                await this.$refs['ruleFormRef'].validate();
            } catch (err) {
                console.log({ err });
                return false;
            }
            
            try {
                const params = { ...this.formData, pid: this.formData.pid[this.formData.pid.length - 1] };
                const { data } = await axios.post(this.tagsgroupData.api, params, { headers: { Auth: localStorage.getItem('token') } });

                if (data.code === 0) {
                    this.dialogDisplay = false;
                    ElMessage({
                        type: 'success',
                        message: '操作成功！',
                        duration: 1000,
                        onClose: () => {
                            this.$emit('success', true);
                        },
                    });
                }
            } catch (error) {
                console.log(error);
            }
        },

        async getCategoryList() {
            try {
                const { data } = await axios.get('/manage/WarehouseConfig/sortGoodsList', { headers: { Auth: localStorage.getItem('token') } });
                if (data.code === 0) {
                    this.categoryList = data.result;
                }
            } catch (error) {
                console.log(error);
            }
        },

        async getUnitnumList() {
            try {
                const { data } = await axios.get('/manage/WarehouseConfig/unitList', { headers: { Auth: localStorage.getItem('token') } });
                if (data.code === 0) {
                    this.unitnumList = data.result.data;
                }
            } catch (error) {
                console.log(error);
            }
        },

        //增加发行量输入框弹出框-取消
        resetEvent() {
            this.$refs['ruleFormRef'].resetFields(); //重置弹窗内容
            this.dialogDisplay = false;
        },
    },
};
</script>

<style scoped>
.tips-box {
    height: 20px;
    line-height: 20px;
}
</style>
