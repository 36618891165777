<template>
    <div>
        <el-form ref="myFormRef" :model="ruleForm" :rules="rules" label-width="140px">
            <slot name="slotUp" />
            <el-form-item label="验收时间：" prop="addtime">
                <el-date-picker v-model="ruleForm.addtime" type="date" placeholder="选择验收时间" :clearable="false" @change="changeEvent" />
            </el-form-item>
            <el-form-item label="周期：" prop="content">
                <div v-if="ruleForm.content.length > 0">
                    <chackEnter
                        ref="myChackEnterRef"
                        v-model="ruleForm.content"
                        :content="ruleForm.content"
                        @delZhouQi="delZhouQiEvent"
                        @addMonth="addMonthEvent"
                        @delMonth="delMonthEvent"
                        @changeAllPriceChackRatio="changeAllPriceChackRatioEvent"
                        @changeMonthPaybackRatio="changeMonthPaybackRatioEvent"
                    />
                    <div style="width: 100%; height: 20px"></div>
                </div>
                <el-button type="primary" plain style="width: 200px" @click="addZhouQiEvent()" :disabled="this.all_advance_ratio < 100 ? false : true"> 新增周期</el-button>
            </el-form-item>
            <el-divider />
            <el-form-item label="预付款比例(%)：">
                <div>
                    <span>{{ this.advance_ratio }}</span>
                </div>
            </el-form-item>
            <el-form-item label="合计比例(%)：">
                <div>
                    <span>{{ this.all_advance_ratio }}</span>
                </div>
            </el-form-item>
            <el-form-item style="margin-bottom: 30px">
                <el-button type="primary" @click="onSubmit('myFormRef')" style="min-width: 200px; width: 20%" :disabled="((this.all_advance_ratio == 100 && this.is_disabled == true) || (this.all_advance_ratio != 100 && this.is_disabled == false)) ? true : false"
                    >提交</el-button
                >
                <el-button @click="onCancel()">取消</el-button>
            </el-form-item>
        </el-form>
    </div>
</template>

<script>
import { defineComponent } from 'vue'
import chackEnter from './chackEnter.vue'
import axios from 'axios'
import { ElMessage } from 'element-plus'

export default defineComponent({
    name: 'riskProvide',
    props: {
        advanceRatio: {
            type: String,
            default: ''
        },
        ruleFormData: {
            type: Object,
            default: () => {
                id: ''
            }
        },
        ruleFormRules: {
            type: Object,
            default: () => {}
        },
        isFromCopy: {
            type: Boolean,
            default: false
        },
        allAdvanceRatio: {
            type: Number,
            default: 0
        }
    },
    watch: {
        advanceRatio: {
            handler(newVal) {
                this.advance_ratio = newVal
            },
            deep: true,
            immediate: true
        },
        ruleFormData: {
            handler(newVal) {
                this.ruleForm = newVal
            },
            deep: true,
            immediate: true
        },
        ruleFormRules: {
            handler(newVal) {
                this.rules = newVal
            },
            deep: true,
            immediate: true
        },
        allAdvanceRatio: {
            handler(newVal) {
                this.all_advance_ratio = newVal
            },
            deep: true,
            immediate: true
        }
    },
    data() {
        return {
            // 预付款比例
            advance_ratio: '',
            // 合计比例
            all_advance_ratio: 0,

            ruleForm: {
                id: '',
                addtime: '',
                content: []
            },
            rules: {
                addtime: [
                    {
                        required: true,
                        message: '验收时间为必选项',
                        trigger: 'blur'
                    }
                ],
                content: [
                    {
                        required: true,
                        message: '周期为不能为空',
                        trigger: 'blur'
                    }
                ]
            },

            is_disabled: false
        }
    },
    methods: {
        // 切换验收时间
        changeEvent(data) {
            this.ruleForm.addtime = data != null ? data : ''

            // 兼容添加周期中的月成功之后，重新切换验收时间的情况
            if (this.ruleForm.addtime != '') {
                // 获取所有的月
                let all_months = this.ruleForm.content.reduce((total, obj) => {
                    return parseInt(total) + parseInt(obj.months.length)
                }, 0)
                if (all_months != 0) {
                    this.updateMonthEvent(all_months)
                }
            }
        },
        // 新增周期
        addZhouQiEvent() {
            let content = {
                all_price_chack_ratio: '',
                months: []
            }
            this.ruleForm.content.push(content)
        },
        // 删除周期
        delZhouQiEvent(index) {
            this.ruleForm.content.splice(index, 1)
            this.allAdvanceRatioEvent(index)

            // 兼容添加周期中的月成功之后，重新切换验收时间的情况
            // 获取所有的月
            let all_months = this.ruleForm.content.reduce((total, obj) => {
                return parseInt(total) + parseInt(obj.months.length)
            }, 0)
            if (all_months != 0) {
                this.updateMonthEvent(all_months)
            }
        },
        // 新增月
        addMonthEvent(index) {
            if (this.ruleForm.addtime == '') {
                ElMessage({
                    message: '验收时间不能为空!',
                    type: 'warning'
                })
                throw new Error('验收时间不能为空')
            }

            let month = {
                month: '',
                month_payback_ratio: '',
                day_payback_ratio: []
            }
            this.ruleForm.content[index].months.push(month)

            // 获取所有的月
            let all_months = this.ruleForm.content.reduce((total, obj) => {
                return parseInt(total) + parseInt(obj.months.length)
            }, 0)

            this.updateMonthEvent(all_months)
        },
        // 删除月
        delMonthEvent({ index, jndex }) {
            if (this.ruleForm.addtime == '') {
                ElMessage({
                    message: '验收时间不能为空!',
                    type: 'warning'
                })
                throw new Error('验收时间不能为空')
            }

            this.ruleForm.content[index].months.splice(jndex, 1)

            // 获取所有的月
            let all_months = this.ruleForm.content.reduce((total, obj) => {
                return parseInt(total) + parseInt(obj.months.length)
            }, 0)

            this.updateMonthEvent(all_months)
        },
        // 更新月的时间范围
        updateMonthEvent(num) {
            axios
                .get('/manage/RiskControlAdvance/riskEdit', {
                    headers: {
                        Auth: localStorage.getItem('token')
                    },
                    params: {
                        checktime: this.ruleForm.addtime.valueOf() / 1000,
                        num: num
                    }
                })
                .then((response) => {
                    let getdatas = response.data.result
                    // console.log('更新月：', getdatas)

                    // 更新月
                    let length = 0
                    this.ruleForm.content.map((n, index) => {
                        n.months.map((m, jndex) => {
                            m.month = getdatas[length + index + jndex]
                        })

                        length = length + n.months.length - 1
                    })
                })
                .catch(function (error) {
                    // 请求失败处理
                    console.log(error)
                })
        },
        // 修改-总价款还款比例
        changeAllPriceChackRatioEvent({ data, index }) {
            this.ruleForm.content[index].all_price_chack_ratio = data
            this.allAdvanceRatioEvent(index)
        },
        // 合计比例
        allAdvanceRatioEvent(index) {
            let all_ratio =
                this.ruleForm.content.reduce((total, obj) => {
                    return parseInt(total) + parseInt(obj.all_price_chack_ratio != '' && obj.all_price_chack_ratio != null ? obj.all_price_chack_ratio : 0)
                }, 0) + parseInt(this.advance_ratio)

            if (all_ratio > 100) {
                ElMessage({
                    message: '合计比例不能超过100',
                    type: 'error'
                })
                this.ruleForm.content[index].all_price_chack_ratio = null
                this.all_advance_ratio = 0
                throw new Error('合计比例不能超过100')
            } else if (!Number(all_ratio)) {
                this.ruleForm.content[index].all_price_chack_ratio = null
                this.all_advance_ratio = 0
            } else {
                this.all_advance_ratio = all_ratio
            }
        },
        // 修改-月还款比例
        changeMonthPaybackRatioEvent({ data, index, jndex }) {
            this.ruleForm.content[index].months[jndex].month_payback_ratio = data
            this.monthPaybackRatioEvent(index, 1)
        },
        // 月还款比例-合计 : flag 1-月input；2-提交Axios最后一下校验
        monthPaybackRatioEvent(index, flag) {
            let all_ratio = this.ruleForm.content[index].months.reduce((total, obj) => {
                return parseInt(total) + parseInt(obj.month_payback_ratio != '' ? obj.month_payback_ratio : 0)
            }, 0)

            if (flag == 1) {
                if (all_ratio > 100) {
                    ElMessage({
                        message: '第 ' + (parseInt(index) + parseInt(1)) + ' 周期中的月还款比例之和，必须等于 100',
                        type: 'error'
                    })
                    throw new Error('月还款比例之和必须等于 100')
                }
            } else {
                if (all_ratio != 100) {
                    ElMessage({
                        message: '第 ' + (parseInt(index) + parseInt(1)) + ' 周期中的月还款比例之和，必须等于 100',
                        type: 'error'
                    })
                    throw new Error('月还款比例之和必须等于 100')
                }
            }
        },
        // 深拷贝
        deepcopy(obj, cache = []) {
            function find(list, f) {
                return list.filter(f)[0]
            }

            // just return if obj is immutable value
            if (obj === null || typeof obj !== 'object') {
                return obj
            }

            // if obj is hit, it is in circular structure
            const hit = find(cache, (c) => c.original === obj)
            if (hit) {
                return hit.copy
            }

            const copy = Array.isArray(obj) ? [] : {}
            // put the copy into cache at first
            // because we want to refer it in recursive deepCopy
            cache.push({
                original: obj,
                copy
            })

            Object.keys(obj).forEach((key) => {
                copy[key] = this.deepcopy(obj[key], cache)
            })

            return copy
        },
        // 提交
        onSubmit(formName) {
            let getdatas = this.$refs[formName].model
            this.$refs[formName].validate((valid) => {
                if (valid) {
                    this.$refs.myChackEnterRef.$refs['myFormChackRef'].validate((valid_1) => {
                        if (valid_1) {
                            // 前端防抖
                            this.is_disabled = true

                            // 判断每个周期中的月还款比例之和是否等于 100
                            this.ruleForm.content.map((n, index) => {
                                this.monthPaybackRatioEvent(index, 2)
                            })

                            let formDatas = {}
                            let url = '/manage/RiskControlAdvance/riskEdit'

                            formDatas['checktime'] = getdatas.addtime.valueOf() / 1000
                            formDatas['check_rule'] = getdatas.content

                            // 来源于[复制]的判断
                            if (this.isFromCopy) {
                                // url切换
                                url = 'manage/RiskControlAdvance/riskCopy'
                                // 将元转换成分
                                let new_getdatas = this.deepcopy(getdatas)
                                let price = new_getdatas['price']

                                formDatas['risk'] = {
                                    price: Number(price) * 100,
                                    name: new_getdatas['name'],
                                    num: new_getdatas['num'],
                                    total: new_getdatas['total'],
                                    advance_ratio: new_getdatas['advance_ratio'],
                                    advance: new_getdatas['advance'],
                                    residue: new_getdatas['residue'],
                                    repay_status: new_getdatas['repay_status']
                                }
                            } else {
                                formDatas['id'] = getdatas.id
                            }

                            axios
                                .post(url, formDatas, {
                                    headers: {
                                        Auth: localStorage.getItem('token')
                                    }
                                })
                                .then((response) => {
                                    if (response.data.code === 0) {
                                        ElMessage({
                                            type: 'success',
                                            message: '提交成功！',
                                            duration: 1000,
                                            onClose: () => {
                                                this.$router.push('/batchmanage').catch((error) => error)
                                                this.is_disabled = false
                                            }
                                        })
                                    } else {
                                        this.is_disabled = false
                                    }
                                })
                                .catch(function (error) {
                                    console.log(error)
                                    this.is_disabled = false
                                })
                        } else {
                            return false
                        }
                    })
                } else {
                    return false
                }
            })
        },
        // 取消
        onCancel() {
            this.ruleForm = {
                addtime: '',
                content: []
            }
            this.$router.push({ path: '/batchmanage' }).catch((error) => error)
        }
    },
    components: {
        chackEnter
    }
})
</script>

<style scoped></style>
