<template>
    <div>
        <el-form ref="addnums" :model="addnum" :rules="rules" label-width="150px" style="padding: 0 20px 0 0">
            <el-form-item label="当前发行量：" prop="number">
                <el-input v-model="addnum.number" :disabled="true" style="width: 80%"></el-input>
            </el-form-item>
            <el-form-item label="新增发行量：" prop="newnum">
                <el-input type="number" v-model.number="addnum.newnum" :min="0" :max="100" maxlength="3" style="width: 80%" clearable @input="addnums">
                    <template #append>张</template>
                </el-input>
                <div class="tips-box">
                    <span style="font-size: 12px; color: #999999">每次新增发行量最大100</span>
                </div>
            </el-form-item>
            <el-divider></el-divider>
            <el-form-item label="增加后发行量：" prop="allnum">
                <el-input v-model="addnum.allnum" :disabled="true" style="width: 80%">
                    <template #append>张</template>
                </el-input>
            </el-form-item>
            <div style="text-align: right">
                <el-button @click="resetEvent('addnums')">取消 </el-button>
                <el-button type="primary" @click="onSubmit('addnums')" :disabled="disabled_btn">确定 </el-button>
            </div>
        </el-form>
    </div>
</template>

<script>
import { ElMessage } from 'element-plus'
import axios from 'axios'

export default {
    name: 'Addnum',
    props: ['Addnum'],
    watch: {
        Addnum: {
            handler(newVal) {
                this.addnum = newVal
            },
            deep: true,
            immediate: true
        }
    },
    data() {
        return {
            addnum: {
                id: '',
                number: '',
                newnum: '',
                allnum: ''
            },

            rules: {
                number: [
                    {
                        required: false,
                        message: '当前发行量不能为空',
                        trigger: 'blur'
                    }
                ],
                newnum: [
                    {
                        required: true,
                        message: '新增发行量不能为空',
                        trigger: 'change'
                    },
                    {
                        type: 'number',
                        min: 0,
                        max: 100,
                        message: '新增发行量区间在0~100之间',
                        trigger: 'change'
                    },
                    {
                        type: 'integer',
                        // 正则表达式(大于等于0的正整数)：/^\+?[0-9]\d*$/  或 /^\d\d*$/
                        pattern: /^\d\d*$/,
                        message: '发行量必须为正整数',
                        trigger: 'change'
                    }
                ],
                allnum: [
                    {
                        required: false,
                        message: '增加后发行量不能为空',
                        trigger: 'blur'
                    }
                ]
            },

            // 防抖
            disabled_btn: false,

            mypowerlimits: JSON.parse(localStorage.getItem('powerlimits')), //获取权限
            mytoken: localStorage.getItem('token'), //获取存储的token
            myuserinfo: localStorage.getItem('userinfo') //获取存储的用户信息
        }
    },
    mounted() {},
    methods: {
        //增加发行量输入框监听
        addnums(newnum) {
            if (newnum) {
                this.addnum.allnum = parseInt(this.addnum.number) + parseInt(newnum)
            } else {
                this.addnum.allnum = ''
            }
        },

        //发行量弹框确认
        onSubmit(formData) {
            let getdatas = this.$refs[formData].model
            this.$refs[formData].validate((valid) => {
                if (valid) {
                    this.disabled_btn = true
                    axios
                        .post(
                            '/manage/coinActivity/addCode',
                            {
                                id: getdatas.id,
                                num: getdatas.newnum
                            },
                            {
                                headers: {
                                    Auth: localStorage.getItem('token')
                                }
                            }
                        )
                        .then((response) => {
                            if (response.data.code === 0) {
                                ElMessage({
                                    type: 'success',
                                    message: '增加成功！',
                                    duration: 1000,
                                    onClose: () => {
                                        this.$emit('success', true)
                                        setTimeout(() => {
                                            this.disabled_btn = false
                                        }, 1000)
                                    }
                                })
                            } else {
                                this.disabled_btn = false
                            }
                        })
                        .catch(function(error) {
                            this.disabled_btn = false
                            console.log(error)
                        })
                } else {
                    return false
                }
            })
        },

        //增加发行量输入框弹出框-取消
        resetEvent(formData) {
            this.$emit('resetevent', true)
            this.$refs[formData].resetFields() //重置弹窗内容
        }
    },
    created() {
        if (localStorage.getItem('token') === null || !localStorage.getItem('token')) {
            this.isshow = false
            this.$router.push('/').catch((error) => error)
        }
    },
    components: {}
}
</script>

<style scoped>
.tips-box {
    height: 20px;
    line-height: 20px;
}
</style>
