<template>
    <div>
        <div @click="tanClickEvent()" style="font-size: 14px">
            <span :style="{ color: span_style.color, cursor: span_style.cursor }">{{ vmodel }}</span>
        </div>
        <!-- 弹窗 -->
        <el-dialog v-model="tanbox.isshow" :title="dialog.title" :width="dialog.width" append-to-body :before-close="(done) => handleClose(done, 'tanNumbersRef')">
            <dpwTable
                v-loading="tanbox.loading"
                element-loading-text="正在飞速加载中..."
                ref="myTableDataRef"
                :tabletitle="table.tabletitle"
                :tabledata="tanbox.tableData"
                :totalcount="tanbox.totalCount"
                :currentpage="tanbox.currentPage"
                :pagesize="tanbox.PageSize"
                :Lastpage="tanbox.lastpage"
            >
                <template #content="{ scope, prop }">
                    <div>
                        <span>{{ scope.row[prop] }}</span>
                    </div>
                </template>
            </dpwTable>
            <div class="block" style="text-align: left; margin: 30px 0">
                <el-pagination
                    background
                    @size-change="tanHandleSizeChange"
                    @current-change="tanHandleCurrentChange"
                    :current-page="tanbox.currentPage"
                    :page-sizes="tanbox.pageSizes"
                    :page-size="tanbox.PageSize"
                    layout="total, sizes, prev, pager, next, jumper"
                    :total="tanbox.totalCount"
                >
                </el-pagination>
            </div>
        </el-dialog>
    </div>
</template>

<script>
import { defineComponent } from 'vue'
import axios from 'axios'
import dpwTable from '../dpw-table.vue'

export default defineComponent({
    name: 'onlyKeysTablePages',
    props: {
        dialog: {
            type: Object,
            default: () => {
                return {
                    title: '实出库量',
                    width: '30%'
                }
            }
        },
        table: {
            type: Object,
            default: () => {
                return {
                    tabletitle: [
                        {
                            fixed: false,
                            prop: 'id',
                            label: 'ID',
                            width: '100'
                        },
                        {
                            fixed: false,
                            prop: 'only_code',
                            label: '唯一标识',
                            width: ''
                        }
                    ]
                }
            }
        },
        vmodel: {
            type: String,
            default: '测试'
        },
        span_style: {
            type: Object,
            default: () => {
                return {
                    color: '',
                    cursor: ''
                }
            }
        },
        axios: {
            type: Object,
            default: () => {
                return {
                    path: '',
                    data: {}
                }
            }
        }
    },
    data() {
        return {
            tanbox: {
                isshow: false,
                tableData: [],
                totalCount: 0,
                currentPage: 1,
                PageSize: 10,
                pageSizes: [10, 20, 50, 100],
                lastpage: 1,
                pagesdatas: []
            }
        }
    },
    methods: {
        //分页器中-当选择条数时触发该函数
        tanHandleSizeChange(val) {
            // console.log(`每页 ${val} 条`)
            this.tanbox.PageSize = val
            this.tanbox.currentPage = 1

            this.tanbox.pagesdatas = {
                ...this.axios.data,
                page: 1,
                page_size: val
            }

            this.tanpostAxiosEvent(this.tanbox.pagesdatas)
        },
        //分页器中-当选择分页时触发该函数
        tanHandleCurrentChange(val) {
            // console.log(`当前页: 第${val}页`)
            this.tanbox.currentPage = val

            this.tanbox.pagesdatas = {
                ...this.axios.data,
                page: val,
                page_size: this.tanbox.PageSize
            }

            this.tanpostAxiosEvent(this.tanbox.pagesdatas)
        },
        tanpostAxiosEvent(data) {
            this.tanbox.loading = true
            axios
                .post(this.axios.path, data, {
                    headers: {
                        Auth: localStorage.getItem('token')
                    }
                })
                .then((response) => {
                    let getdatas = response.data.result
                    this.tanbox.tableData = getdatas.data
                    this.tanbox.totalCount = getdatas.total
                    this.tanbox.currentPage = getdatas.current_page
                    this.tanbox.PageSize = getdatas.per_page
                    this.tanbox.lastpage = getdatas.last_page
                    this.tanbox.loading = false
                })
                .catch(function () {
                    this.tanbox.loading = false
                })
        },

        tanClickEvent() {
            this.tanbox.isshow = true
            this.tanbox.tableData = []
            this.tanbox.pagesdatas = {
                ...this.axios.data,
                page: 1,
                page_size: this.tanbox.PageSize
            }
            this.tanpostAxiosEvent(this.tanbox.pagesdatas)
        },
        cancelEvent() {
            this.tanbox.isshow = false
        },
        handleClose(done) {
            done()
        }
    },
    components: {
        dpwTable
    }
})
</script>

<style scoped>
.tanbox-boxs {
    display: flex;
    flex-direction: row;
    justify-content: left;
    align-items: center;
    flex-wrap: nowrap;
    gap: 20px;
    margin: 10px 0 20px;
}
</style>
