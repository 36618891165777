<template>
    <div>
        <mainone :mainonebread="mainonebread"></mainone>
        <dpwCard title="添加">
            <template #content>
                <div class="form-box">
                    <div class="form-item-box flex-1">
                        <el-form ref="ruleForm" :model="ruleForm" :rules="rules" label-width="120px" class="demo-ruleForm">
                            <el-form-item label="供应商名称：" prop="name">
                                <el-input v-model="ruleForm.name" clearable maxlength="200"></el-input>
                            </el-form-item>
                            <el-form-item label="备注：" prop="remark">
                                <el-input type="textarea" v-model="ruleForm.remark" clearable show-word-limit maxlength="200"></el-input>
                            </el-form-item>
                            <el-form-item label="状态：" prop="status">
                                <el-radio-group v-model="ruleForm.status">
                                    <el-radio label="1">开启</el-radio>
                                    <el-radio label="-1">关闭</el-radio>
                                </el-radio-group>
                            </el-form-item>
                            <el-form-item>
                                <el-button type="primary" @click="submitForm('ruleForm')" style="width: 40%; margin-right: 10px" :disabled="disabled">提交</el-button>
                                <el-button @click="resetForm('ruleForm')">取消</el-button>
                            </el-form-item>
                        </el-form>
                    </div>
                </div>
            </template>
        </dpwCard>
    </div>
</template>

<script>
import { defineComponent } from 'vue'
import mainone from '@/components/Mainone'
import dpwCard from '../../acommon/dpw-card.vue'
import { ElMessage } from 'element-plus'
import axios from 'axios'

export default defineComponent({
    name: 'vgoinmonadinfo',
    data() {
        return {
            mainonebread: [
                {
                    name: '仓库'
                },
                {
                    name: '基础配置'
                },
                {
                    name: '供应商管理'
                },
                {
                    name: '添加'
                }
            ], // 当前页-【面包屑导航】

            ruleForm: {
                name: '',
                remark: '',
                status: '1'
            },
            rules: {
                name: [
                    {
                        required: true,
                        message: '仓库名称为必填项',
                        trigger: 'blur'
                    }
                ],
                remark: [
                    {
                        required: false,
                        message: '作用为必填项',
                        trigger: 'blur'
                    }
                ],
                status: [
                    {
                        required: true,
                        message: '状态为必填项',
                        trigger: 'blur'
                    }
                ]
            },
            // 前端防抖
            disabled: false
        }
    },
    methods: {
        //提交form表单-并判断是否必填
        submitForm(formName) {
            let getdatas = this.$refs[formName].model
            this.$refs[formName].validate((valid) => {
                if (valid) {
                    this.disabled = true
                    axios
                        .post(
                            '/manage/provider/save',
                            {
                                name: getdatas.name,
                                remark: getdatas.remark,
                                status: getdatas.status
                            },
                            {
                                headers: {
                                    Auth: localStorage.getItem('token')
                                }
                            }
                        )
                        .then((response) => {
                            if (response.data.code === 0) {
                                ElMessage({
                                    type: 'success',
                                    message: '提交成功！',
                                    duration: 1000,
                                    onClose: () => {
                                        this.$router.back()
                                        this.disabled = false
                                    }
                                })
                            } else {
                                this.disabled = false
                            }
                        })
                        .catch(function () {
                            this.disabled = false
                        })
                } else {
                    return false
                }
            })
        },
        //重置form表单中填写的数据
        resetForm(formName) {
            this.$refs[formName].resetFields()
            this.$router.back()
        }
    },
    created() {
        if (localStorage.getItem('token') === null || !localStorage.getItem('token')) {
            this.$router.push('/').catch((error) => error)
        }
    },
    components: {
        mainone,
        dpwCard
    }
})
</script>

<style scoped>
.form-box {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    flex-wrap: nowrap;
}
.form-item-box {
    max-width: 800px;
}
.flex-1 {
    flex: 1;
}
</style>
