<template>
    <div>
        <mainone :mainonebread="mainonebread"></mainone>
        <maintwo
            :maintwoiconshow="maintwoiconshow"
            :maintwoforminfo="maintwoforminfo"
            :myinputoneisshow="maintwoforminfo.myinputoneisshow"
            :myinputone="maintwoforminfo.myinputone"
            :myselectleftisshow="maintwoforminfo.selectleftisshow"
            :myselectleft="maintwoforminfo.selectleft"
            :myinputishow="myinputishow"
            :myinput="myinput"
            :myinputonefullisshow="maintwoforminfo.myinputonefullisshow"
            :myinputonefull="maintwoforminfo.myinputonefull"
            :myselectisshow="myselectisshow"
            :myselect="myselect"
            :myselectcityisshow="myselectcityisshow"
            :myselectcity="myselectcity"
            v-on:getsearchinfo="getSeachinfoEvent"
            v-on:searchForm="searchFormEvent"
        ></maintwo>
        <!--table表单-->
        <div>
            <!--主list列表-->
            <div
                style="
          margin-top: 10px;
          background-color: #ffffff;
          border-radius: 4px;
          line-height: 40px;
          padding: 0 20px;
        "
            >
                <div style="line-height: 60px">
                    <!--左侧名称-->
                    <div style="float: left; font-size: 14px">数据列表</div>
                    <!--右侧按钮-->
                    <div style="float: right">
                        <!--自定义按钮-点击出现弹框&点击跳转子类页面@click="routeraddrClick('/XX路由')"-->
                        <!--1、点击出现弹框@click="addTanClick(XX弹框配置信息,dialogVisible = true);resetFormSenior(XXform表单)"-->
                        <!--2、点击跳转子类页面@click="routeraddrClick('/XX路由')-->
                        <el-button type="primary" plain @click="routeraddrClick('/coursewarelistadd')" v-if="mypowerlimits.kjlb_tj"> 添加</el-button>
                    </div>
                </div>

                <div>
                    <!--table表单-->
                    <el-table
                        ref="multipleTable"
                        :row-class-name="tableRowClassName"
                        :data="mainthreetableData.slice((currentPage - lastpage) * PageSize, currentPage * PageSize)"
                        style="width: 100%"
                        min-height="900"
                        border
                        v-loading="loading"
                        element-loading-text="正在飞速加载中..."
                        @selection-change="handleSelectionChange"
                        @cell-click="handleOneData"
                    >
                        <!--多选框-用于用户选择性导出数据-->
                        <el-table-column type="selection" width="55" v-if="mainthreeisshowset" />
                        <el-table-column
                            v-for="(mytabletitle, i) in mainthreetabletitle"
                            :key="i"
                            :fixed="mytabletitle.fixedstatu"
                            :prop="mytabletitle.prop"
                            :label="mytabletitle.label"
                            :width="mytabletitle.width"
                        >
                            <!--这里先通过table列表中的title值进行判断，在进行渲染展示-->
                            <!--例如：status状态字段，判断最终的值是那种表现形式等-->
                            <template #default="scope" v-if="mytabletitle.prop === 'images'">
                                <div v-if="mytabletitle.prop === 'images'">
                                    <div v-if="scope.row.images">
                                        <el-image class="imagesshow" :src="scope.row.images" :preview-src-list="[scope.row.images]" fit="cover"></el-image>
                                    </div>
                                </div>
                            </template>
                        </el-table-column>
                        <!--操作&按钮-->
                        <el-table-column fixed="right" label="操作" :width="mainthreehandle" v-if="mainthreeisshowope" prop="myoperation" id="operation">
                            <template #default="scope">
                                <!--操作：按照页面区分，且每个按钮对应相关函数-->
                                <!--操作-课件列表-->
                                <div v-if="mainthreetableope === 17">
                                    <el-button type="primary" size="mini" @click="routeraddrClick('/coursewarelistedit', scope.row)" v-if="mypowerlimits.kjlb_bj">编辑 </el-button>
                                    <el-button
                                        @click.prevent="deleteRow(scope.$index, mainthreetableData, scope.row)"
                                        type="danger"
                                        size="mini"
                                        icon="el-icon-delete"
                                        v-if="mypowerlimits.kjlb_sc"
                                    >
                                    </el-button>
                                    <el-button type="primary" @click.prevent="preview(scope.row, (dialogVisible1 = true))" size="mini">预览 </el-button>
                                </div>
                            </template>
                        </el-table-column>
                    </el-table>
                    <!--分页器-->
                    <div class="block" style="text-align: center; margin: 30px 0">
                        <el-pagination
                            background
                            @size-change="handleSizeChange"
                            @current-change="handleCurrentChange"
                            :current-page="currentPage"
                            :page-sizes="pageSizes"
                            :page-size="PageSize"
                            layout="total, sizes, prev, pager, next, jumper"
                            :total="totalCount"
                        >
                        </el-pagination>
                    </div>
                </div>
            </div>
        </div>
        <!--弹出框-->
        <el-dialog v-model="dialogVisible1" title="预览">
            <div v-html="dialogContent"></div>
        </el-dialog>
    </div>
</template>

<script>
import mainone from '@/components/Mainone'
import maintwo from '@/components/Maintwo'
import { ElMessage, ElMessageBox } from 'element-plus'
import axios from 'axios'
import { defineComponent, ref } from 'vue'

export default defineComponent({
    name: 'vcoursewarelist',
    inject: ['reload'],
    data() {
        return {
            dialogVisible1: false,
            dialogContent: '',
            mainonebread: [
                {
                    name: '教育'
                },
                {
                    name: '课程管理'
                },
                {
                    name: '课件列表',
                    path: '/coursewarelist'
                }
            ], // 当前页-【面包屑导航】
            maintwoiconshow: false, // 筛选-【高级筛选按钮】
            maintwoforminfo: {
                myinputoneisshow: true, //筛选查询-左侧字+input
                myinputone: {
                    divinterval: '0', //框右侧距离
                    titlewidth: '74px', //标题宽度
                    inputwidth: '200px', //input宽度
                    inputinterval: '20px', //input右侧距离
                    input: [
                        {
                            ismust: false, //是否必填，true表示必填
                            title: '输入搜索：',
                            placeholder: '课件名称',
                            name: 'content',
                            content: ''
                        }
                    ]
                },
                selectleftisshow: true, //筛选查询-左侧字+select单框||select时间
                selectleft: {
                    width: 'none',
                    divinterval: '', //框右侧距离
                    selectwidth: '200px',
                    selectinterval: '20px', //select右侧距离
                    select: [
                        {
                            selectisshow: true,
                            ismust: false,
                            title: '分类：',
                            placeholder: '全部',
                            name: 'category',
                            value: '',
                            options: [
                                {
                                    label: '全部',
                                    value: '0'
                                }
                            ]
                        }
                    ]
                }
            }, // 筛选-控件信息
            mainthreeisshowbut: true, // 数据列表中-【添加按钮】
            mainthreeadddata: '/coursewarelistadd', //点击添加按钮-跳转路由
            mainthreeisshowset: false, // 数据列表中-复选框
            mainthreeisshowope: true, // 数据列表中-操作-根据页面显示模块（整页）
            mainthreetableope: 17, // 数据列表中-操作-根据页面显示控件（整页）
            mainthreehandle: 180, // 数据列表中-操作-宽度
            mainthreetabletitle: [
                {
                    fixedstatu: 'left',
                    prop: 'title',
                    label: '课件名称',
                    width: ''
                },
                {
                    fixedstatu: false,
                    prop: 'category',
                    label: '课件分类',
                    width: '350'
                },
                {
                    fixedstatu: false,
                    prop: 'add_user',
                    label: '创建人',
                    width: '180'
                },
                {
                    fixedstatu: false,
                    prop: 'createtime',
                    label: '创建时间',
                    width: '180'
                }
            ], // 数据列表中-数据表头
            mainthreestatus: true, // 数据列表中-属于search按钮表示状态
            mainthreetableData: [], // 数据列表中-具体数据
            totalCount: 1, //数据列表中-数据总条数
            currentpage: 1, //数据列表中-数据当前页数
            lastpage: 1, //数据列表中-数据总页数（默认以20条一页）
            loading: false, //初始化-loading加载动效，默认是false关闭

            //table表单相关数据
            seniorform: {},
            currentPage: 1,
            pageSizes: [20, 50, 100],
            PageSize: 20,
            multipleSelection: [],
            thispagename: '', //通过路由获取当前页面的path值
            searchwidth: '580px',
            tancontent: {
                title: '弹框标题',
                content: {}
            }, //弹框

            mypowerlimits: JSON.parse(localStorage.getItem('powerlimits')), //获取权限
            mytoken: localStorage.getItem('token'), //获取存储的token
            pagesdatas: [] //存储最近一次的筛选条件，供分页使用
        }
    },
    setup() {
        const dialogVisible = ref(false)
        const handleCloseSenior = (done) => {
            done()
        }
        return {
            dialogVisible,
            handleCloseSenior
        }
    },
    mounted() {
        //获取当前页面路由path值
        this.thispagename = this.$route.path
    },
    methods: {
        preview(row) {
            var file = row.file_url
            var type = row.type
            console.log(file)
            if (type == 1) {
                this.dialogContent = '<iframe width="98%" height="' + (window.innerHeight - 80) * 0.8 + 'px" src="' + file + '"></iframe>'
            } else if (type == 2) {
                this.dialogContent =
                    '<div style="text-align: center;padding:20px;margin:0 auto;width:100%">' + '<audio src="' + file + '" width="100%" height="68px" controls></audio></div>'
            } else if (type == 3) {
                this.dialogContent =
                    '<div style="display:flex;justify-content:center;align-items:center;height:350px "><video autoplay="autoplay" src="' +
                    file +
                    '" width="100%"  height="100%" controls></video></div>'
            }
        },
        //table表-操作中-删除按钮（函数）
        deleteRow(index, rows) {
            //在table表中删除当前行信息，而rows.splice(index, 1)仅属于前端删除，可配合后具体操作
            // rows.splice(index, 1)
            // console.log(index,rows);
            //通过Ajax向后台获取数据
            ElMessageBox.confirm('确定删除：“' + rows[index].title + '”吗?', '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning'
            })
                .then(() => {
                    axios
                        .post(
                            '/manage/Course/deleteCourseware',
                            {
                                id: rows[index].id
                            },
                            {
                                headers: {
                                    Auth: localStorage.getItem('token')
                                }
                            }
                        )
                        .then((response) => {
                            if (response.data.code == 0) {
                                ElMessage({
                                    type: 'success',
                                    message: '删除成功！',
                                    duration: 1000,
                                    onClose: () => {
                                        rows.splice(index, 1)
                                    }
                                })
                            }
                        })
                })
                .catch(() => {
                    ElMessage({
                        type: 'info',
                        message: '取消删除！'
                    })
                })
        },
        //table表中-当选项发生变化是触发该函数
        handleSelectionChange(val) {
            console.log('this is:' + val)
            this.multipleSelection = val
        },
        //分页器中-当选择条数时触发该函数
        handleSizeChange(val) {
            // console.log(`每页 ${val} 条`);
            this.PageSize = val
            this.currentPage = 1

            //加载loading
            this.loading = true
            // 通过Ajax向后台获取数据
            axios
                .post(
                    '/manage/Course/indexCourseware',
                    {
                        category: this.pagesdatas.category,
                        content: this.pagesdatas.content,
                        page: 1,
                        page_size: val
                    },
                    {
                        headers: {
                            Auth: localStorage.getItem('token')
                        }
                    }
                )
                .then((response) => {
                    this.myaxiosfunc(response.data.result)
                })
                .catch(function(error) {
                    // 请求失败处理
                    console.log(error)
                })
        },
        //分页器中-当选择分页时触发该函数
        handleCurrentChange(val) {
            // console.log(`当前页: 第${val}页`);
            this.currentPage = val

            //加载loading
            this.loading = true
            // 通过Ajax向后台获取数据
            axios
                .post(
                    '/manage/Course/indexCourseware',
                    {
                        category: this.pagesdatas.category,
                        content: this.pagesdatas.content,
                        page: val,
                        page_size: this.PageSize
                    },
                    {
                        headers: {
                            Auth: localStorage.getItem('token')
                        }
                    }
                )
                .then((response) => {
                    this.myaxiosfunc(response.data.result)
                })
                .catch(function(error) {
                    // 请求失败处理
                    console.log(error)
                })
        },
        //点击按钮-弹出弹框并赋值弹窗基本配置
        addTanClick(taninfo) {
            // console.log(taninfo);
            this.tancontent.content = taninfo.content
            this.tancontent.title = taninfo.title
        },
        //点击进行路由页面跳转以及携带参数
        routeraddrClick(routeraddr, rowdata) {
            // 携带参数跳转
            if (rowdata) {
                // 跳转-编辑课件
                if (routeraddr === '/coursewarelistedit') {
                    this.$router
                        .push({
                            path: routeraddr,
                            query: { id: rowdata.id }
                        })
                        .catch((error) => error)
                }
            } else {
                //仅跳转
                this.$router.push(routeraddr).catch((error) => error)
            }
        },
        //导出数据
        exportDatas(tableData) {
            console.log(tableData)
        },
        //获取当前windows页面信息-实时修改【弹窗】的宽度

        getwindowinfo() {
            this.sreenheight = document.documentElement.clientHeight
            this.sreenwidth = document.documentElement.clientWidth
            if (this.sreenwidth < 600) {
                this.searchwidth = this.sreenwidth * 0.8 + 'px'
            } else {
                this.searchwidth = '580px'
            }
        },

        //提交等相关event事件函数
        //获取Container-Pagedata-Maintwo-搜索-返回的数据
        getSeachinfoEvent(data) {
            console.log('点击查询获取的data数组：' + JSON.stringify(data))
            this.pagesdatas = data //将筛选条件赋值给本页的pagesdatas存储，供分页使用
            //加载loading
            this.loading = true
            //通过Ajax向后台获取数据
            axios
                .post(
                    '/manage/Course/indexCourseware',
                    {
                        category: data.category,
                        content: data.content,
                        page: 1,
                        page_size: this.PageSize
                    },
                    {
                        headers: {
                            Auth: localStorage.getItem('token')
                        }
                    }
                )
                .then((response) => {
                    this.myaxiosfunc(response.data.result)
                })
                .catch(function(error) {
                    // 请求失败处理
                    console.log(error)
                })
        },
        //数据渲染
        myaxiosfunc(successdata) {
            var newdata = successdata.data
            for (var i = 0; i < newdata.length; i++) {
                //该字段表示search按钮，且status为1表示启用，2表示禁用
                newdata[i].switchstatus = 4
                //转换创建时间
                if (newdata[i].createtime) {
                    if (newdata[i].createtime === 0 || newdata[i].createtime === null) {
                        newdata[i].createtime = '无添加时间'
                    } else {
                        newdata[i].createtime = this.$utils.formatDate(newdata[i].createtime)
                    }
                } else {
                    newdata[i].createtime = '无添加时间'
                }
            }
            // console.log('当前页面的data:' + JSON.stringify(newdata))
            this.mainthreetableData = newdata
            this.totalCount = successdata.total
            this.currentpage = successdata.current_page //当前页面页数
            this.lastpage = successdata.last_page
            this.loading = false
        }
    },
    created() {
        if (localStorage.getItem('token') === null || !localStorage.getItem('token')) {
            this.isshow = false
            this.$router.push('/').catch((error) => error)
        } else {
            window.addEventListener('resize', this.getwindowinfo) // 注册监听器-实时检测浏览器页面宽高
            this.getwindowinfo()

            //加载loading
            this.loading = true //通过Ajax向后台获取数据
            // 分类
            axios
                .post(
                    '/manage/Course/indexCoursewareCate',
                    {},
                    {
                        headers: {
                            Auth: localStorage.getItem('token')
                        }
                    }
                )
                .then((response) => {
                    var alldatas = Array()
                    var num = 0
                    alldatas[num] = { value: '', label: '全部' }
                    for (var key in response.data.result.data) {
                        num = num + 1
                        alldatas[num] = {
                            value: response.data.result.data[key].sort_val,
                            label: response.data.result.data[key].sort_key
                        }
                    }
                    //这里的select[0],是写死的，后续可优化！注意写死的状况下，select的位置不可移动
                    this.maintwoforminfo.selectleft.select[0].options = alldatas
                })
                .catch(function(error) {
                    console.log(error)
                })

            this.pagesdatas = {
                page: 1,
                page_size: this.PageSize
            }
            let routerinfo = this.$route.query
            // 分类
            if (routerinfo.sort_val) {
                this.pagesdatas.category = routerinfo.sort_val
                this.maintwoforminfo.selectleft.select[0].value = parseInt(routerinfo.sort_val)
            }

            //通过Ajax向后台获取数据
            axios
                .post('/manage/Course/indexCourseware', this.pagesdatas, {
                    headers: {
                        Auth: localStorage.getItem('token')
                    }
                })
                .then((response) => {
                    this.myaxiosfunc(response.data.result)
                })
                .catch(function(error) {
                    console.log(error)
                })
        }
    },
    components: {
        mainone,
        maintwo
    }
})
</script>

<style scoped>
/*操作中-按钮样式*/
.el-button--mini {
    min-height: 28px;
    padding: 0 8px;
    font-size: 12px;
    border-radius: calc(var(--el-border-radius-base) - 1px);
}
</style>
