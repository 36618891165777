<template>
    <div>
        <mainone :mainonebread="mainonebread"></mainone>
        <div v-loading="loading" element-loading-text="正在飞速加载中...">
            <!--标题-->
            <div class="box" style="">
                <div class="box_title" style="">
                    <div class="box_title_font">编辑图片</div>
                    <div class="box_title_button">
                        <!--可添加按钮-->
                        <!--<el-button type="primary" class="max_search" @click="exportDatas(mainthreetableData)">导出数据</el-button>-->
                    </div>
                </div>
                <!--内容-->
                <div class="box_content">
                    <el-row style="margin-top: 20px">
                        <!--占位符-->
                        <el-col :span="24" :xs="24" :sm="24" :lg="7">
                            <div style="width: 100%"></div>
                        </el-col>
                        <!--主表样式-->
                        <el-col :span="24" :xs="24" :sm="24" :lg="9">
                            <el-form ref="ruleForm" :model="ruleForm" :rules="rules" label-width="120px" class="demo-ruleForm">
                                <el-form-item label="图片：" prop="path">
                                    <mainimgesup
                                        v-model="ruleForm.path"
                                        :myimgesup="myimgesup"
                                        :filelist="filelist"
                                        :myimgesupisshow="myimgesupisshow"
                                        v-on:getuploadfile="(data) => getUploadFileEvent(data)"
                                        v-on:deluploadfile="deluploadfileEvent"
                                    ></mainimgesup>
                                </el-form-item>
                                <el-form-item label="图片键值：" prop="key">
                                    <el-input v-model="ruleForm.key"></el-input>
                                </el-form-item>
                                <el-form-item label="所属分类：" prop="cid">
                                    <el-select v-model="ruleForm.cid" placeholder="请选择" style="width: 100%">
                                        <el-option :label="getsales.sort_key" :value="getsales.id" v-for="(getsales, i) in ruleForm.sales_people_datas" :key="i"></el-option>
                                    </el-select>
                                </el-form-item>
                                <el-form-item label="排序：" prop="display">
                                    <el-input v-model="ruleForm.display"></el-input>
                                </el-form-item>
                                <el-form-item label="状态：" prop="status">
                                    <el-radio-group v-model="ruleForm.status">
                                        <el-radio label="1">显示</el-radio>
                                        <el-radio label="-1">不显示</el-radio>
                                    </el-radio-group>
                                </el-form-item>
                                <el-form-item>
                                    <el-button type="primary" @click="submitForm('ruleForm')" style="width: 40%; margin-right: 10px">确认 </el-button>
                                    <el-button @click="resetForm('ruleForm')">重置</el-button>
                                </el-form-item>
                            </el-form>
                        </el-col>
                        <!--占位符-->
                        <el-col :span="24" :xs="24" :sm="24" :lg="8">
                            <div style="width: 100%"></div>
                        </el-col>
                    </el-row>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import mainone from '@/components/Mainone'
import mainimgesup from '@/components/Mainimgesup.vue'
import { ref } from 'vue'
import axios from 'axios'
import { ElMessage } from 'element-plus'

export default {
    name: 'resourcesglobaledit',
    data() {
        return {
            mainonebread: [
                {
                    name: '打印'
                },
                {
                    name: '资源管理'
                },
                {
                    name: '全球拍资源',
                    path: '/resourcesglobal'
                },
                {
                    name: '编辑图片',
                    path: '/resourcesglobaledit'
                }
            ], // 当前页-【面包屑导航】
            myimgesupisshow: true, //form表单-imgesuploade上传图片
            myimgesup: {
                uploadsize: 1, //选择上传图片的展现框-1表示小框，2表示中等框，3表示大框
                listtype: 'picture', //list展示类型，text/picture/picture-card；默认不填为text
                ismust: true, //是否必填，true表示必填
                action: '/api/admin/upload/openFile', //upload上传API地址
                headers: {
                    Auth: localStorage.getItem('token')
                }, //upload上传headers
                title: '上传图片', //button-按钮文字
                tips: '(1)建议尺寸：1070*1830像素; (2)图片支持格式：*.jpg、*.jpeg、*.png; (3)最大支持上传20M文件', //提示&建议文字
                limit: '1', //上传数量限制
                target_id: 100, //上传文件target_id ：图片分类，区分存储文件和存储时长，不传默认存储1天后过期；1-二维码； 2-打印文件； 3-文库文件； 4-课程文件； 5-广告主宣传页； 6-广告主产生的pdf文件； 7-证件照文件； 50-设备广告； 100-通用；
                rules: {
                    images: {
                        image_width: 0, //图片宽度
                        image_height: 0, //图片高度
                        size: 10000, //文件大小（单位kb）
                        type: ['image/jpeg', 'image/jpg', 'image/png'], //文件格式
                        typechart: 'jpg/png' //文件格式提示规则
                    }
                }
            },
            filelist: [],
            ruleForm: {
                path: '',
                key: '',
                cid: '',
                display: '',
                status: ref('1'),
                sales_people_datas: []
            }, //form表单
            rules: {
                path: [
                    {
                        required: true,
                        message: '图片为必填项',
                        trigger: 'blur'
                    }
                ],
                key: [
                    {
                        required: true,
                        message: '图片键值为必填项',
                        trigger: 'blur'
                    }
                ],
                cid: [
                    {
                        required: true,
                        message: '请选择所属分类',
                        trigger: 'blur'
                    }
                ],
                display: [
                    {
                        required: true,
                        message: '排序为必填项',
                        trigger: 'blur'
                    }
                ],
                status: [
                    {
                        required: false,
                        message: '请至少选择一个状态',
                        trigger: 'change'
                    }
                ]
            }, //form表单-必填规则

            mypowerlimits: JSON.parse(localStorage.getItem('powerlimits')), //获取权限
            mytoken: localStorage.getItem('token'), //获取存储的token

            loading: false
        }
    },
    methods: {
        //获取upload子组件回传的数据
        getUploadFileEvent(data) {
            console.log('当前获取的回传数据：' + JSON.stringify(data))
            this.ruleForm.path = data.url
            this.ruleForm.key = data.name
        },
        //删除上传的文件-事件
        deluploadfileEvent(data) {
            console.log('当前删除事件-返回的数据：' + JSON.stringify(data))
            this.filelist = data
            this.ruleForm.path = data
        },
        //提交form表单-并判断是否必填
        submitForm(formName) {
            var formdatas = this.$refs[formName].model
            this.$refs[formName].validate((valid) => {
                if (valid) {
                    axios
                        .post(
                            '/manage/Equipment/worldImgEdit',
                            {
                                id: this.$route.query.id,
                                path: formdatas.path,
                                key: formdatas.key,
                                cid: formdatas.cid,
                                display: formdatas.display,
                                status: formdatas.status
                            },
                            {
                                headers: {
                                    Auth: localStorage.getItem('token')
                                }
                            }
                        )
                        .then((response) => {
                            if (response.data.code === 0) {
                                ElMessage({
                                    type: 'success',
                                    message: '提交成功！',
                                    duration: 1000,
                                    onClose: () => {
                                        this.$router.push('/resourcesglobal').catch((error) => error)
                                    }
                                })
                            }
                        })
                        .catch(function(error) {
                            // 请求失败处理
                            console.log(error)
                        })
                } else {
                    console.log('error submit!!')
                    return false
                }
            })
        },
        //重置form表单中填写的数据
        resetForm(formName) {
            this.$refs[formName].resetFields()
            // 重置upload中的filelist列表
            this.filelist = []
        }
    },
    created() {
        if (localStorage.getItem('token') === null || !localStorage.getItem('token')) {
            this.isshow = false
            this.$router.push('/').catch((error) => error)
        } else {
            this.loading = true
            //通过Ajax向后台获取数据
            var p1 = axios
                .get('/manage/Equipment/worldImgDetial', {
                    headers: {
                        Auth: localStorage.getItem('token')
                    },
                    params: {
                        id: this.$route.query.id
                    }
                })
                .then((response) => {
                    console.log(response.data.result)
                    var routedatas = response.data.result
                    var ruleform = this.ruleForm
                    ruleform.path = routedatas.path //通过路由获取的图片
                    ruleform.key = routedatas.key //通过路由获取键值
                    ruleform.cid = routedatas.cid //所属分类
                    ruleform.display = routedatas.display //排序
                    ruleform.status = ref(routedatas.status + '') //状态
                    this.filelist[0] = { name: routedatas.key, url: routedatas.path }
                })
                .catch(function(error) {
                    console.log(error)
                })

            // 获取-所属分类-下拉数据
            var p2 = axios
                .get('/manage/Equipment/worldImgSortSelect', {
                    headers: {
                        Auth: localStorage.getItem('token')
                    }
                })
                .then((response) => {
                    if (response.data.code == 0) {
                        this.ruleForm.sales_people_datas = response.data.result.data
                    }
                })
                .catch(function(error) {
                    console.log(error)
                })

            Promise.all([p1, p2])
                .then(() => {
                    this.loading = false
                })
                .catch((error) => {
                    console.log('error:失败:', error)
                    this.loading = false
                })
        }
    },
    components: {
        mainone,
        mainimgesup
    }
}
</script>

<style scoped>
.box {
    background-color: #ffffff;
    border-radius: 4px;
    color: #333333;
    padding: 10px 20px;
}

.box_title {
    line-height: 40px;
    height: 40px;
}

.box_title_font {
    float: left;
    font-weight: 400;
    font-size: 14px;
    color: #333333;
}

.box_title_button {
    float: right;
    font-weight: 400;
    font-size: 14px;
    color: #94a3d2;
}

.box_title_button:hover {
    cursor: pointer;
}

.box_content {
    border-top: 1px solid #eeeeee;
    margin-top: 10px;
}
</style>
