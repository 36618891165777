<template>
  <div>
    <mainone :mainonebread="mainonebread"></mainone>
    <div>
      <!--标题-->
      <div class="box" style="">
        <div class="box_title" style="">
          <div class="box_title_font">日志文件</div>
          <div class="box_title_button">
            <el-button
              type="primary"
              size="small"
              plain
              @click="refreshEvent(this.reload())"
              >更新日志</el-button
            >
          </div>
        </div>
        <!--内容-->
        <div class="box_content">
          <el-row style="margin: 20px 0 20px 5px">
            <div style="margin-bottom: 10px; width: 100%">
              <iframe
                :srcdoc="this.formhtml"
                scrolling="yes"
                style="width: 100%; min-height: 700px"
                frameborder="0"
              ></iframe>
            </div>
          </el-row>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import mainone from "@/components/Mainone.vue";
import axios from "axios";

export default {
  name: "vagentlistadd",
  inject: ["reload"], //刷新引用
  data() {
    return {
      mainonebread: [
        {
          name: "打印",
        },
        {
          name: "共享打印",
        },
        {
          name: "机器控制",
          path: "/sharedvccontrol",
        },
        {
          name: "查看日志",
          path: "/sharedvccontrolseelog",
        },
      ], // 当前页-【面包屑导航】

      formhtml: "",
      formhtmlurl: "",

      mypowerlimits: JSON.parse(localStorage.getItem("powerlimits")), //获取权限
      mytoken: localStorage.getItem("token"), //获取存储的token
    };
  },
  mounted() {},
  methods: {
    // 解析-html文件转成-string字符串
    readTextFile(filePath, callback) {
      const xhrFile = new XMLHttpRequest();
      xhrFile.open("GET", filePath, true);
      xhrFile.onload = function () {
        const allText = xhrFile.response;
        callback(allText);
      };
      xhrFile.send();
    },
  },
  created() {
    if (localStorage.getItem("token") === null || !localStorage.getItem("token")) {
      this.isshow = false;
      this.$router.push("/").catch((error) => error);
    } else {
      // 获取日志信息
      axios
        .get("/manage/Equipment/clientLog", {
          headers: {
            Auth: localStorage.getItem("token"),
          },
          params: { id: this.$route.query.id },
        })
        .then((response) => {
          if (response.data.code == 0) {
            const filePath = response.data.result;
            this.readTextFile(filePath, (textDetail) => {
              this.formhtml = textDetail;
            });
          } else {
            this.formhtml =
              "<p>日志打印错误：" + response.data.message + "</p>";
          }
        })
        .catch(function (error) {
          console.log(error);
        });
    }
  },
  components: {
    mainone,
  },
};
</script>

<style scoped>
/* 以下是:当前页面整个box */
.box {
  background-color: #ffffff;
  border-radius: 4px;
}

.box_title {
  line-height: 50px;
  height: 50px;
  margin-left: 20px;
}

.box_title_font {
  float: left;
  font-weight: 400;
  font-size: 14px;
  color: #333333;
}

.box_title_button {
  float: right;
  font-weight: 400;
  font-size: 14px;
  margin-right: 20px;
}

.box_title_button:hover {
  cursor: pointer;
}

.box_content {
  border-top: 1px solid #eeeeee;
}
</style>
