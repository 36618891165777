<template>
    <div>
        <mainone :mainonebread="mainonebread"></mainone>
        <dpwCard :title="title" v-if="this.$route.query.copy">
            <template #content>
                <riskProvide :advanceRatio="advance_ratio" :ruleFormData="ruleForm" :ruleFormRules="rules" :isFromCopy="true" :allAdvanceRatio="100">
                    <template #slotUp>
                        <el-form-item label="批次名称：" prop="name">
                            <el-input v-model="ruleForm.name" style="width: 80%" placeholder="请输入批次名称" maxlength="50"></el-input>
                        </el-form-item>
                        <el-form-item label="结算单价：" prop="price">
                            <el-input v-model="ruleForm.price" style="width: 80%" placeholder="请输入结算单价" @input="inputEvent"></el-input>
                        </el-form-item>
                        <el-form-item label="总数量：" prop="num">
                            <el-input v-model="ruleForm.num" style="width: 80%" placeholder="请输入总数量" @input="inputEvent"></el-input>
                        </el-form-item>
                        <el-form-item label="总价款（元）：">
                            <span class="c-red">{{ formatPrice(ruleForm.total) }}</span>
                        </el-form-item>
                        <el-form-item label="预付款比例：" prop="advance_ratio">
                            <el-input v-model="ruleForm.advance_ratio" style="width: 25%" placeholder="请输入预付款比例" @input="inputEvent"></el-input>
                            <span> %</span>
                        </el-form-item>
                        <el-form-item label="预付款（元）：">
                            <span class="c-red">{{ formatPrice(ruleForm.advance) }}</span>
                        </el-form-item>
                        <el-form-item label="余款（元）：">
                            <span class="c-red">{{ formatPrice(ruleForm.residue) }}</span>
                        </el-form-item>
                        <el-form-item label="还款状态：" prop="repay_status">
                            <el-radio-group v-model="ruleForm.repay_status">
                                <el-radio label="1">开启</el-radio>
                                <el-radio label="-1">关闭</el-radio>
                            </el-radio-group>
                        </el-form-item>
                    </template>
                </riskProvide>
            </template>
        </dpwCard>
        <dpwCard :title="title" v-else>
            <template #content>
                <dpwTitle title="详情信息"></dpwTitle>
                <dpwDetails :datas="data_options"></dpwDetails>
                <el-divider />
                <dpwTitle title="验收操作"></dpwTitle>
                <riskProvide :advanceRatio="advance_ratio" :ruleFormData="ruleForm" :ruleFormRules="rules"></riskProvide>
            </template>
        </dpwCard>
    </div>
</template>

<script>
import mainone from '@/components/Mainone'
import dpwDetails from '../../acommon/dpw-details.vue'
import dpwCard from '../../acommon/dpw-card.vue'
import dpwTitle from '../../acommon/dpw-title.vue'
import riskProvide from './models/riskProvide.vue'
import dict from '../../js/dict.js'
import axios from 'axios'
import { ElMessage } from 'element-plus'

export default {
    name: 'vbatchmanagechack',
    data() {
        return {
            mainonebread: [
                {
                    name: '风控'
                },
                {
                    name: '风控管理'
                },
                {
                    name: '批次管理'
                }
            ], // 当前页-【面包屑导航】
            title: '',

            data_options: [],
            // 还款状态
            repay_status_options: dict.batchStatusOptions,
            // 预付款比例
            advance_ratio: '',

            ruleForm: {},
            rules: {}
        }
    },
    methods: {
        roundToDecimal(number, decimalPlaces = 0) {
            const factor = Math.pow(10, decimalPlaces)
            return Math.round(number * factor) / factor
        },
        inputEvent() {
            let price = this.ruleForm.price
            let num = this.ruleForm.num
            let advance_ratio = this.ruleForm.advance_ratio

            this.ruleForm.total = this.roundToDecimal(+price * 100 * +num, 1)
            this.ruleForm.advance = this.roundToDecimal((+this.ruleForm.total * (advance_ratio ? +advance_ratio : 0)) / 100, 1)
            this.ruleForm.residue = +this.ruleForm.total - +this.ruleForm.advance
        },
        formatPrice(data) {
            return data ? (+data / 100).toFixed(3) : data
        },
        // 获取详情信息
        getInfoEvent(data) {
            axios
                .get('/manage/RiskControlAdvance/riskEditInfo', {
                    headers: {
                        Auth: localStorage.getItem('token')
                    },
                    params: data
                })
                .then((response) => {
                    let getdatas = response.data.result

                    if (this.$route.query.copy) {
                        this.ruleForm = {
                            id: this.$route.query.id,
                            name: getdatas.name,
                            price: getdatas.price / 100,
                            num: getdatas.num,
                            total: getdatas.total,
                            advance_ratio: getdatas.advance_ratio,
                            advance: getdatas.advance,
                            residue: getdatas.residue,
                            repay_status: getdatas.repay_status?.toString(),
                            addtime: getdatas.checktime * 1000,
                            content: getdatas.check_rule
                        }
                        // 预付款比例
                        this.advance_ratio = getdatas.advance_ratio
                    } else {
                        this.data_options = [
                            { title: '批次名称', value: getdatas.name },
                            { title: '结算单价(元)', value: (getdatas.price / 100).toFixed(3) },
                            { title: '总数量', value: getdatas.num },
                            { title: '总价款(元)', value: (getdatas.total / 100).toFixed(3) },
                            { title: '预付款(元)', value: (getdatas.advance / 100).toFixed(3) },
                            { title: '余额(元)', value: (getdatas.residue / 100).toFixed(3) },
                            {
                                title: '状态',
                                value: getdatas.status ? this.repay_status_options.filter((n) => n.id == getdatas.status)[0]['label'] : '',
                                color: getdatas.status ? this.repay_status_options.filter((n) => n.id == getdatas.status)[0]['color'] : ''
                            },
                            { title: '验收时间', value: getdatas.checktime != 0 ? this.$utils.formatDate(getdatas.checktime).substr(0, 10) : '' },
                            { title: '还款首日', value: getdatas.repay_first_day != 0 ? this.$utils.formatDate(getdatas.repay_first_day).substr(0, 10) : '' },
                            { title: '还款状态', value: getdatas.repay_status == 1 ? '开启' : '关闭' },
                            { title: '操作人', value: getdatas.adminid_name },
                            { title: '操作时间', value: getdatas.uptime != 0 ? this.$utils.formatDate(getdatas.uptime) : '' }
                        ]
                        // 预付款比例
                        this.advance_ratio = getdatas.advance_ratio
                    }
                })
                .catch(function (error) {
                    // 请求失败处理
                    console.log(error)
                })
        }
    },
    created() {
        if (localStorage.getItem('token') === null || !localStorage.getItem('token')) {
            this.$router.push('/').catch((error) => error)
        } else {
            let routerInfo = this.$route.query

            // 复制
            if (routerInfo.copy) {
                this.title = '复制'
                this.mainonebread.push({ name: this.title })
                this.getInfoEvent({ id: routerInfo.id })

                this.ruleForm = {
                    id: routerInfo.id,
                    name: '',
                    price: '',
                    num: '',
                    total: '',
                    advance_ratio: '',
                    advance: '',
                    residue: '',
                    repay_status: '',
                    addtime: '',
                    content: []
                }
                this.rules = {
                    name: [
                        {
                            required: true,
                            message: '请输入名称',
                            trigger: 'blur'
                        }
                    ],
                    price: [
                        {
                            required: true,
                            message: '请输入结算单价',
                            trigger: 'blur'
                        },
                        {
                            // 正则表达式(大于等于0的正数或保留两位小数的小数)：/^(([1-9]{1}\d*)|(0{1}))(\.\d{1,2})?$/
                            pattern: /^(([1-9]{1}\d*))(\.\d{1,3})?$/,
                            message: '结算单价必须为保留三位小数的非零正数',
                            trigger: ['blur', 'change']
                        }
                    ],
                    num: [
                        {
                            required: true,
                            message: '请输入总数量',
                            trigger: 'blur'
                        },
                        {
                            validator: (rule, value, callback) => {
                                if (value <= 0 || value > 100000) {
                                    callback(new Error('总数量在0-100000之间'))
                                } else {
                                    callback()
                                }
                            },
                            trigger: ['blur', 'change']
                        }
                    ],
                    advance_ratio: [
                        {
                            required: true,
                            message: '请输入预付款比例',
                            trigger: 'blur'
                        },
                        {
                            validator: (rule, value, callback) => {
                                if (value <= 0 || value >= 100) {
                                    callback(new Error('预付款比例须大于0, 小于100'))
                                } else {
                                    callback()
                                }
                            },
                            trigger: ['blur', 'change']
                        }
                    ],
                    repay_status: [
                        {
                            required: true,
                            message: '请选择还款状态',
                            trigger: 'change'
                        }
                    ],
                    addtime: [
                        {
                            required: true,
                            message: '验收时间为必选项',
                            trigger: 'blur'
                        }
                    ],
                    content: [
                        {
                            required: true,
                            message: '周期为不能为空',
                            trigger: 'blur'
                        }
                    ]
                }
            } else {
                // 验收
                this.title = '验收'
                this.mainonebread.push({ name: this.title })
                this.getInfoEvent({ id: routerInfo.id })
                this.ruleForm = {
                    id: routerInfo.id,
                    addtime: '',
                    content: []
                }
                this.rules = {
                    addtime: [
                        {
                            required: true,
                            message: '验收时间为必选项',
                            trigger: 'blur'
                        }
                    ],
                    content: [
                        {
                            required: true,
                            message: '周期为不能为空',
                            trigger: 'blur'
                        }
                    ]
                }
            }
        }
    },
    components: {
        mainone,
        dpwCard,
        dpwDetails,
        dpwTitle,
        riskProvide
    }
}
</script>
