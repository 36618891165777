<template>
    <div>
        <mainone :mainonebread="mainonebread"></mainone>
        <maintwo
            :maintwoiconshow="maintwoiconshow"
            :maintwoforminfo="maintwoforminfo"
            :myinputoneisshow="maintwoforminfo.myinputoneisshow"
            :myinputone="maintwoforminfo.myinputone"
            :myselectleftisshow="maintwoforminfo.selectleftisshow"
            :myselectleft="maintwoforminfo.selectleft"
            :myselecttopisshow="maintwoforminfo.selecttopisshow"
            :myselecttop="maintwoforminfo.selecttop"
            :myinputishow="myinputishow"
            :myinput="myinput"
            :myinputonefullisshow="maintwoforminfo.myinputonefullisshow"
            :myinputonefull="maintwoforminfo.myinputonefull"
            :myinputonefullgaoisshow="myinputonefullgaoisshow"
            :myinputonefullgao="myinputonefullgao"
            :myselectisshow="myselectisshow"
            :myselect="myselect"
            :myselectuptopisshow="myselecttopisshow"
            :myselectuptop="myselecttop"
            :myselectcity="myselectcity"
            v-on:getsearchinfo="getSeachinfoEvent"
        ></maintwo>
        <!--table表单-->
        <div>
            <!--主list列表-->
            <div style="margin-top: 10px; background-color: #ffffff; border-radius: 4px; line-height: 40px; padding: 0 20px">
                <div style="line-height: 60px">
                    <!--左侧名称-->
                    <div style="float: left; font-size: 14px">数据列表</div>
                    <!--右侧按钮-->
                    <div style="float: right">
                        <!--导出按钮-点击导出数据-->
                        <el-button type="success" plain @click="asyncDownClick">同步下载</el-button>
                        <mainimgesup
                            style="display: inline-block; margin: 0 10px;"
                            ref="uploadfile"
                            :myimgesup="myimgesup"
                            :myimgesupisshow="myimgesupisshow"
                            :disabled="disabled_btn"
                            v-on:getuploadfile="(data) => getUploadFileEvent(data)"
                            v-on:beginUpload="beginUploadEvent"
                            v-on:errorUpload="errorUploadEvent"
                        >
                        </mainimgesup>
                        <el-button v-if="mypowerlimits.sbk_tb" type="primary" plain @click="tongbuClick()" :disabled="disabled_tongbu">同步</el-button>
                        <el-button v-if="mypowerlimits.sbk_fp" type="primary" plain @click="addTanClick()">批量分配公司</el-button>
                        <el-button type="warning" plain @click="exportDatas()">导出</el-button>
                    </div>
                </div>

                <div>
                    <dpwTable
                        v-loading="loading"
                        element-loading-text="正在飞速加载中..."
                        ref="myTableDataRef"
                        :tabletitle="mainthreetabletitle"
                        :tabledata="mainthreetableData"
                        :totalcount="totalCount"
                        :currentpage="currentPage"
                        :pagesize="PageSize"
                        :Lastpage="lastpage"
                        :Operation="{ isshow: true }"
                        :selectionIsShow="mypowerlimits.sbk_fp ? true : false"
                        @handleSelection="handleSelectionEvent"
                        @handleSelectionOnline="handleSelectionChangeOnline"
                    >
                        <template #content="{ scope, prop }">
                            <div>
                                <span>{{ scope.row[prop] }}</span>
                            </div>
                        </template>
                        <template #special="{ scope }">
                            <div>
                                <el-button v-if="scope.row.companyid_name != '' && mypowerlimits.sbk_qxfp" type="primary" size="mini" @click="deAllocatedEvent(scope.row)">
                                    取消分配
                                </el-button>
                            </div>
                        </template>
                    </dpwTable>
                    <!--分页器-->
                    <div class="block" style="text-align: center; margin: 30px 0">
                        <el-pagination
                            background
                            @size-change="handleSizeChange"
                            @current-change="handleCurrentChange"
                            :current-page="currentPage"
                            :page-sizes="pageSizes"
                            :page-size="PageSize"
                            layout="total, sizes, prev, pager, next, jumper"
                            :total="totalCount"
                        >
                        </el-pagination>
                    </div>
                </div>
            </div>
        </div>

        <el-dialog
            v-model="tancontent.isshow"
            :title="tancontent.title"
            :width="this.searchwidth"
            :before-close="
                () => {
                    tancontent.isshow = false
                }
            "
        >
            <el-form ref="tanformRef" :model="tancontent.content" :rules="rules" label-width="80px" class="demo-ruleForm">
                <div style="max-height: 450px; overflow: hidden; overflow-y: visible; padding: 0 3%; margin: 20px 0 0 0">
                    <el-form-item label="公司：" prop="companyid">
                        <el-select v-model="tancontent.content.companyid" placeholder="请选择" style="width: 100%">
                            <el-option :label="item" :value="index" v-for="(item, index) in tancontent.company_options" :key="index"></el-option>
                        </el-select>
                    </el-form-item>
                    <el-form-item>
                        <div style="text-align: right">
                            <el-button @click="resetEvent('tanformRef', (tancontent.isshow = false))">取消</el-button>
                            <el-button type="primary" @click="onSubmitEvent('tanformRef')">确定</el-button>
                        </div>
                    </el-form-item>
                </div>
            </el-form>
        </el-dialog>
    </div>
</template>

<script>
import mainone from '@/components/Mainone'
import maintwo from '@/components/Maintwo'
import mainimgesup from '@/components/Mainimgesup'
import axios from 'axios'
import { defineComponent, ref } from 'vue'
import { ElMessageBox, ElMessage, ElNotification } from 'element-plus'
import dpwTable from './acommon/dpw-table.vue'
const equipmentOptions = [
    { value: '0', label: 'MZ-430CVW'},
    { value: '1', label: 'MZ-565VP'},
    { value: '2', label: 'MZ-565VT(A)'},
]

export default defineComponent({
    name: 'vdeviceBank',
    inject: ['reload'], //刷新引用
    data() {
        return {
            mainonebread: [
                {
                    name: '购物'
                },
                {
                    name: '设备库'
                },
                {
                    name: '设备库'
                }
            ], // 当前页-【面包屑导航】
            myimgesupisshow: true,
            myimgesup: {
                uploadsize: 2, // 1阿里 oss ；2前端传后端
                listtype: 'picture', //list展示类型，text/picture/picture-card；默认不填为text
                showfilelist: false, //list展示列表是否显示，显示true，隐藏false
                ismust: true, //是否必填，true表示必填
                action: '/manage/Equipment/syncUpload', //upload上传API地址
                headers: { Auth: localStorage.getItem('token') }, //upload上传headers
                multiple: false, //上传多个文件
                title: '同步上传', //button-按钮文字
                title_type: 'success', //button-按钮显示样式,primary&text
                title_size: 'large', //button-按钮大小，medium / small / mini
                title_plain: true, //button-按钮是否为线性（plain）
                isshow: false, //button-按钮是否显示，注意：false表示显示，true表示不显示
                tips: '', //提示&建议文字
                limit: '', //上传数量限制
                target_id: 100, //上传文件target_id ：图片分类，区分存储文件和存储时长，不传默认存储1天后过期；1-二维码； 2-打印文件； 3-文库文件； 4-课程文件； 5-广告主宣传页； 6-广告主产生的pdf文件； 7-证件照文件； 50-设备广告； 100-通用；
                rules: {
                    files: {
                        size: 200000, //文件大小（单位kb）
                        type: ['application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'], //文件格式
                        typechart: 'Excel' //文件格式提示规则
                    }
                }
            },
            maintwoiconshow: false, // 筛选-【高级筛选按钮】
            maintwoforminfo: {
                myinputoneisshow: true, //筛选查询-左侧字+input
                myinputone: {
                    divinterval: '0', //框右侧距离
                    titlewidth: '105px', //标题宽度
                    inputwidth: '200px', //input宽度
                    inputinterval: '20px', //input右侧距离
                    input: [
                        {
                            ismust: false, //是否必填，true表示必填
                            title: '购物设备编号:',
                            placeholder: '请输入购物设备编号',
                            name: 'relate_id',
                            content: ''
                        },
                        {
                            ismust: false, //是否必填，true表示必填
                            title: '原始设备编号:',
                            placeholder: '请输入原始设备编号',
                            name: 'meizhi_id',
                            content: ''
                        }
                    ]
                },
                selectleftisshow: true, //筛选查询-左侧字+select单框||select时间
                selectleft: {
                    width: 'none',
                    divinterval: '', //框右侧距离
                    selectwidth: '200px',
                    selectinterval: '20px', //select右侧距离
                    select: [
                        {
                            selectisshow: true,
                            ismust: false,
                            title: '公司：',
                            placeholder: '全部',
                            name: 'companyid',
                            value: '',
                            options: []
                        },
                        {
                            selectisshow: true,
                            ismust: false,
                            title: '设备型号：',
                            placeholder: '全部',
                            name: 'mversion',
                            value: '',
                            options: equipmentOptions
                        }
                    ]
                },
            }, // 筛选-控件信息
            mainthreeisshowbut: false, // 数据列表中-【添加按钮】
            mainthreeisshowset: true, // 数据列表中-复选框
            mainthreeisshowope: false, // 数据列表中-操作-根据页面显示模块（整页）
            mainthreetableope: 1, // 数据列表中-操作-根据页面显示控件（整页）
            mainthreehandle: 150, // 数据列表中-操作-宽度
            mainthreetabletitle: [
                {
                    fixedstatu: 'left',
                    prop: 'relate_id',
                    label: '购物设备编号',
                    width: ''
                },
                {
                    fixedstatu: false,
                    prop: 'meizhi_id',
                    label: '原始设备编号',
                    width: ''
                },
                {
                    fixedstatu: false,
                    prop: 'client_type_name',
                    label: '设备类型',
                    width: ''
                },
                {
                    fixedstatu: false,
                    prop: 'mversion',
                    label: '设备型号',
                    width: '150'
                },
                {
                    fixedstatu: false,
                    prop: 'companyid_name',
                    label: '公司',
                    width: ''
                },
                {
                    fixedstatu: false,
                    prop: 'adminid_name',
                    label: '最新操作人',
                    width: ''
                },
                {
                    fixedstatu: false,
                    prop: 'uptimeStr',
                    label: '最新操作时间',
                    width: '200'
                }
            ], // 数据列表中-数据表头
            mainthreestatus: true, // 数据列表中-属于search按钮表示状态
            mainthreetableData: [], // 数据列表中-具体数据
            totalCount: 1, //数据列表中-数据总条数
            currentpage: 1, //数据列表中-数据当前页数
            lastpage: 1, //数据列表中-数据总页数（默认以20条一页）
            loading: false, //初始化-loading加载动效，默认是false关闭

            //table表单相关数据
            seniorform: {},
            currentPage: 1,
            pageSizes: [20, 50, 100],
            PageSize: 20,

            thispagename: '', //通过路由获取当前页面的path值
            searchwidth: '580px',
            tancontent: {
                isshow: false,
                title: '',
                content: {
                    companyid: '',
                    id: []
                },
                company_options: []
            }, //弹框
            rules: {
                companyid: [
                    {
                        required: true,
                        message: '公司为必填项',
                        trigger: 'blur'
                    }
                ]
            },

            multipleSelection: [],
            hasMoreSelection: [],

            disabled_tongbu: false,
            disabled_btn: false,

            mypowerlimits: JSON.parse(localStorage.getItem('powerlimits')), //获取权限
            mytoken: localStorage.getItem('token'), //获取存储的token
            pagesdatas: [], //存储最近一次的筛选条件，供分页使用
            
        }
    },
    methods: {
        // ********* 多选 *********
        // 深拷贝
        deepcopy(obj, cache = []) {
            function find(list, f) {
                return list.filter(f)[0]
            }

            // just return if obj is immutable value
            if (obj === null || typeof obj !== 'object') {
                return obj
            }

            // if obj is hit, it is in circular structure
            const hit = find(cache, (c) => c.original === obj)
            if (hit) {
                return hit.copy
            }

            const copy = Array.isArray(obj) ? [] : {}
            // put the copy into cache at first
            // because we want to refer it in recursive deepCopy
            cache.push({
                original: obj,
                copy
            })

            Object.keys(obj).forEach((key) => {
                copy[key] = this.deepcopy(obj[key], cache)
            })

            return copy
        },
        // 去重
        reduce(datas) {
            let obj = {}
            let reduce = []
            reduce = datas.reduce(function (item, next) {
                //item为没有重复id的数组，next为当前对象
                obj[next.id] ? '' : (obj[next.id] = true && item.push(next))
                return item
            }, [])

            return reduce
        },
        // 多选数据-选中
        handleSelectionEvent(datas) {
            let all_datas = [...this.multipleSelection, ...this.deepcopy(datas)]
            // 去重
            this.multipleSelection = this.reduce(all_datas)

            if (datas.length == 0) {
                this.multipleSelection = []
            }
        },
        // 多选数据-取消
        handleSelectionChangeOnline({ datas, row }) {
            this.multipleSelection = this.multipleSelection.filter((n) => n.id != row.id)
            this.handleSelectionEvent(datas)
        },

        // 多选-检查反显
        moreDataBackShow(datas) {
            if (this.$refs.myTableDataRef) {
                this.$refs.myTableDataRef.backShow(datas)
            }
        },
        removeDuplicateObjects(arr_1, arr_2) {
            // 使用 filter() 方法从 arr1 中过滤掉与 arr2 相同的对象
            let arr1 = arr_1.map((n) => {
                return n.id
            })
            let arr2 = arr_2.map((n) => {
                return n.id
            })

            const filteredArray = arr1.concat(arr2).filter((item) => !arr1.includes(item) || !arr2.includes(item))
            let has_remove = arr_1
                .map((n) => {
                    if (filteredArray.includes(n.id)) {
                        return n
                    }
                })
                .filter((n) => n)

            return has_remove
        },
        // ************ 多选（end） *********************
        //分页器中-当选择条数时触发该函数
        handleSizeChange(val) {
            // console.log(`每页 ${val} 条`)
            this.PageSize = val
            this.currentPage = 1

            this.pagesdatas = {
                relate_id: this.pagesdatas.relate_id,
                meizhi_id: this.pagesdatas.meizhi_id,
                companyid: this.pagesdatas.companyid,
                mversion: this.pagesdatas.mversion,
                page: 1,
                page_size: val
            }

            this.postAxiosEvent(this.pagesdatas)
        },
        //分页器中-当选择分页时触发该函数
        handleCurrentChange(val) {
            // console.log(`当前页: 第${val}页`)
            this.currentPage = val

            this.pagesdatas = {
                relate_id: this.pagesdatas.relate_id,
                meizhi_id: this.pagesdatas.meizhi_id,
                companyid: this.pagesdatas.companyid,
                mversion: this.pagesdatas.mversion,
                page: val,
                page_size: this.PageSize
            }

            this.postAxiosEvent(this.pagesdatas)
        },
        // 批量分配公司-弹出
        addTanClick() {
            if (this.multipleSelection.length == 0) {
                ElMessage({ type: 'warning', message: '至少选中一个，再批量分配公司' })
                return
            }

            this.tancontent.isshow = true
            this.tancontent.title = '批量分配公司'
            this.tancontent.content = {
                companyid: '',
                id: this.multipleSelection.map((n) => n.id)
            }
        },
        // 批量分配公司
        onSubmitEvent(formName) {
            this.$refs[formName].validate((valid) => {
                if (valid) {
                    axios
                        .post('manage/Equipment/mzDistribe', this.tancontent.content, {
                            headers: {
                                Auth: localStorage.getItem('token')
                            }
                        })
                        .then((response) => {
                            if (response.data.code === 0) {
                                ElMessage({
                                    type: 'success',
                                    message: '提交成功！',
                                    duration: 1000,
                                    onClose: () => {
                                        this.handleCurrentChange(this.currentPage)
                                        this.tancontent.isshow = false
                                        this.multipleSelection = []
                                    }
                                })
                            }
                        })
                        .catch(function (error) {
                            console.log(error)
                        })
                } else {
                    return false
                }
            })
        },
        // 开始上传
        beginUploadEvent() {
            this.disabled_btn = true
        },
        // 上传报错
        errorUploadEvent() {
            this.disabled_btn = false
        },
        // 【批量导入出库】-获取upload子组件回传的数据-file文件url等数据
        getUploadFileEvent(data) {
            // 删除upload列队中的数据
            this.$refs.uploadfile.handleRemove(data)
            this.disabled_btn = false
            setTimeout(() => {
                this.reload()
            }, 1000)
        },
        // 同步下载
        asyncDownClick() {
            axios
                .post(
                    'manage/Equipment/syncDownload', null,
                    {
                        headers: {
                            Auth: localStorage.getItem('token')
                        }
                    }
                )
                .then((response) => {
                    if (response.data.code == 0) {
                        let url = response.data.result.download
                        window.open(url, '_blank')
                    }
                })
                .catch(function (error) {
                    console.log(error)
                })
        },

        // 同步
        tongbuClick() {
            this.disabled_tongbu = true
            axios
                .post(
                    'manage/Equipment/mzClientAdd',
                    {},
                    {
                        headers: {
                            Auth: localStorage.getItem('token')
                        }
                    }
                )
                .then((response) => {
                    if (response.data.code === 0) {
                        ElMessage({
                            type: 'success',
                            message: '同步成功！',
                            duration: 1000,
                            onClose: () => {
                                this.handleCurrentChange(1)
                                this.multipleSelection = []

                                this.disabled_tongbu = false
                            }
                        })
                    } else {
                        this.disabled_tongbu = false
                    }
                })
                .catch(function (error) {
                    console.log(error)
                    this.disabled_tongbu = false
                })
        },

        // 取消分配
        deAllocatedEvent(data) {
            ElMessageBox.confirm('是否取消分配', '取消分配', {
                confirmButtonText: '确定',
                cancelButtonText: '取消'
            })
                .then(() => {
                    axios
                        .post(
                            '/manage/Equipment/mzClientUnassign',
                            { id: data.id },
                            {
                                headers: {
                                    Auth: localStorage.getItem('token')
                                }
                            }
                        )
                        .then((response) => {
                            if (response.data.code == 0) {
                                ElMessage({
                                    type: 'success',
                                    message: '操作成功！',
                                    duration: 500,
                                    onClose: () => {
                                        this.handleCurrentChange(this.currentPage)
                                        this.multipleSelection = []
                                    }
                                })
                            }
                        })
                        .catch(function (error) {
                            console.log(error)
                        })
                })
                .catch(() => {
                    ElMessage({ type: 'info', message: '已取消' })
                })
        },

        //导出数据
        exportDatas() {
            let pagesdatas = {
                relate_id: this.pagesdatas.relate_id,
                meizhi_id: this.pagesdatas.meizhi_id,
                companyid: this.pagesdatas.companyid,
                mversion: this.pagesdatas.mversion,
                export: 1
            }

            this.postAxiosEvent(pagesdatas, 1)
        },

        //获取当前windows页面信息-实时修改【弹窗】的宽度
        getwindowinfo() {
            this.sreenheight = document.documentElement.clientHeight
            this.sreenwidth = document.documentElement.clientWidth
            if (this.sreenwidth < 600) {
                this.searchwidth = this.sreenwidth * 0.8 + 'px'
            } else {
                this.searchwidth = '580px'
            }
        },
        //提交等相关event事件函数
        //获取Container-Pagedata-Maintwo-搜索-返回的数据
        getSeachinfoEvent(data) {
            this.multipleSelection = []

            this.pagesdatas = {
                relate_id: data.relate_id,
                meizhi_id: data.meizhi_id,
                companyid: data.companyid,
                mversion: data.mversion,
                page: 1,
                page_size: this.PageSize
            }

            this.postAxiosEvent(this.pagesdatas)
        },
        //数据渲染
        myaxiosfunc(successdata) {
            var newdata = successdata.data
            this.mainthreetableData = this.deepcopy(newdata)
            this.totalCount = successdata.total
            this.currentpage = successdata.current_page //当前页面页数
            this.lastpage = successdata.last_page
            this.loading = false

            this.moreDataBackShow(this.multipleSelection)
        },

        // 获取数据
        postAxiosEvent(data, flag) {
            if (!flag) this.loading = true
            if(data.mversion){
                data.mversion = equipmentOptions[data.mversion].label
            }
            axios
                .get('/manage/Equipment/mzClientList', {
                    headers: {
                        Auth: localStorage.getItem('token')
                    },
                    params: data
                })
                .then((response) => {
                    if (!flag) this.myaxiosfunc(response.data.result)
                })
                .catch(function (error) {
                    console.log(error)
                })
        },
        //get请求配置参数-公司下拉
        getCompanyOptions() {
            axios
                .get('/manage/flowmaster/list', {
                    headers: {
                        Auth: localStorage.getItem('token')
                    },
                    params: {}
                })
                .then((response) => {
                    this.getCompany(response.data.result.companyid)
                })
                .catch(function (error) {
                    console.log(error)
                })
        },
        // 获取-公司-下拉信息
        getCompany(configinfo) {
            //获取api-加载类型信息（根据API返回的json数据结构不同，函数方法不同！）
            var alldatas = Array()
            var num = 0
            alldatas[num] = { value: num, label: '未分配' }
            for (var key in configinfo) {
                num = num + 1
                alldatas[num] = {
                    value: key,
                    label: configinfo[key]
                }
            }
            alldatas.unshift({ value: '', label: '全部' })
            //这里的select[0],是写死的，后续可优化！注意写死的状况下，select的位置不可移动
            this.maintwoforminfo.selectleft.select[0].options = alldatas
            this.tancontent.company_options = configinfo
        }
    },
    created() {
        if (localStorage.getItem('token') === null || !localStorage.getItem('token')) {
            this.isshow = false
            this.$router.push('/').catch((error) => error)
        } else {
            window.addEventListener('resize', this.getwindowinfo) // 注册监听器-实时检测浏览器页面宽高
            this.getwindowinfo()

            this.getCompanyOptions()
            this.pagesdatas = {
                page: 1,
                page_size: this.PageSize
            }
            this.postAxiosEvent(this.pagesdatas)
        }
    },
    components: {
        mainone,
        maintwo,
        dpwTable,
        mainimgesup
    }
})
</script>
<style scoped>
/*操作中-按钮样式*/
.el-button--mini {
    min-height: 28px;
    padding: 0 8px;
    font-size: 12px;
    border-radius: calc(var(--el-border-radius-base) - 1px);
}
</style>
