<template>
    <div>
        <mainone :mainonebread="mainonebread"></mainone>
        <div v-loading="loading" element-loading-text="正在飞速加载中...">
            <!--标题-->
            <div class="box" style="">
                <div class="box_title" style="">
                    <div class="box_title_font">添加专题</div>
                    <div class="box_title_button"></div>
                </div>
                <!--内容-->
                <div class="box_content">
                    <el-row style="margin-top: 20px">
                        <!--占位符-->
                        <el-col :span="24" :xs="24" :sm="24" :lg="5">
                            <div style="width: 100%"></div>
                        </el-col>
                        <!--主表样式-->
                        <el-col :span="24" :xs="24" :sm="24" :lg="11">
                            <el-form ref="ruleForm" :model="ruleForm" :rules="rules" label-width="100px" class="demo-ruleForm">
                                <el-form-item label="添加专题：" prop="">
                                    <div>
                                        <el-button type="primary" plain size="medium" @click="addButtonEvent(this.ruleForm, 6)"
                                            ><i class="el-icon-circle-plus-outline"></i> 添加专题
                                        </el-button>
                                    </div>
                                    <div v-for="(item, index) in this.ruleForm" :key="index" style="display: flex;flex-direction: row;margin-top: 10px;">
                                        <div style="width: 100%;border: 1px dashed #7070707e;padding: 20px 20px 30px;border-radius: 4px;">
                                            <el-form-item label="专题封面：" :prop="index + '.icon_img'" :rules="rules.icon_img">
                                                <mainimgesup
                                                    :model="item.icon_img"
                                                    :myimgesup="myimgesup"
                                                    :myimgesupisshow="myimgesupisshow"
                                                    :filelist="item.icon_img_list"
                                                    v-on:getuploadfile="(data) => getUploadFileEvent(data, index)"
                                                    v-on:deluploadfile="(data) => deluploadfileEvent(data, index)"
                                                >
                                                </mainimgesup>
                                            </el-form-item>
                                            <el-form-item label="跳转分类：" :prop="index + '.link_url'" :rules="rules.link_url" style="margin-top: 20px;">
                                                <el-input v-model="item.link_url" placeholder="请输入跳转分类ID" clearable> </el-input>
                                            </el-form-item>
                                        </div>
                                        <div style="width:100px;margin-left: 10px;">
                                            <el-button type="danger" size="medium" @click="delButtonEvent(index)">删除 </el-button>
                                        </div>
                                    </div>
                                </el-form-item>
                                <el-form-item>
                                    <el-button type="primary" @click="submitForm('ruleForm')" style="width: 40%; margin-right: 10px">提交 </el-button>
                                    <el-button @click="myresetForm">取消</el-button>
                                </el-form-item>
                            </el-form>
                        </el-col>
                        <!--占位符-->
                        <el-col :span="24" :xs="24" :sm="24" :lg="8">
                            <div style="width: 100%"></div>
                        </el-col>
                    </el-row>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import mainone from '@/components/Mainone'
import mainimgesup from '@/components/Mainimgesup.vue'
import { ElMessage } from 'element-plus'
import axios from 'axios'

export default {
    name: 'vrecpositionaddspecial',
    data() {
        return {
            mainonebread: [
                {
                    name: '教育'
                },
                {
                    name: '文档管理'
                },
                {
                    name: '推荐位管理',
                    path: '/recommendposition'
                },
                {
                    name: '添加专题'
                }
            ], // 当前页-【面包屑导航】

            myimgesupisshow: true, //form表单-imgesuploade上传图片
            myimgesup: {
                uploadsize: 1, //选择上传图片的展现框-1表示小框，2表示中等框，3表示大框
                listtype: 'picture', //list展示类型，text/picture/picture-card；默认不填为text
                ismust: true, //是否必填，true表示必填
                action: '/manage/upload/file', //upload上传API地址
                headers: { Auth: localStorage.getItem('token') }, //upload上传headers
                title: '上传封面', //button-按钮文字
                tips: '建议尺寸：244px*345px', //提示&建议文字
                limit: '1', //上传数量限制
                target_id: 100, //上传文件target_id ：图片分类，区分存储文件和存储时长，不传默认存储1天后过期；1-二维码； 2-打印文件； 3-文库文件； 4-课程文件； 5-广告主宣传页； 6-广告主产生的pdf文件； 7-证件照文件； 50-设备广告； 100-通用
                rules: {
                    images: {
                        image_width: 0, //图片宽度，0表示不限宽
                        image_height: 0, //图片高度，0表示不限高
                        size: 20000, //文件大小（单位kb）
                        type: ['image/jpeg', 'image/jpg', 'image/png'], //文件格式
                        typechart: 'png/jpg/jpeg' //文件格式提示规则
                    }
                }
            },

            //form表单
            ruleForm: [],
            //form表单-必填规则
            rules: {
                icon_img: [
                    {
                        required: true,
                        message: '导航图标不能为空',
                        trigger: 'change'
                    }
                ],
                link_url: [
                    {
                        required: true,
                        message: '跳转分类不能为空',
                        trigger: 'blur'
                    }
                ]
            },

            mypowerlimits: JSON.parse(localStorage.getItem('powerlimits')), //获取权限
            mytoken: localStorage.getItem('token'), //获取存储的token

            loading: false
        }
    },
    methods: {
        // 添加专题
        addButtonEvent(fromdata, times) {
            console.log(fromdata, times)
            // 最多添加6条专题
            if (fromdata.length < times) {
                let data = {
                    icon_img: '',
                    link_url: ''
                }
                fromdata.push(data)
            } else {
                ElMessage({
                    type: 'warning',
                    message: '最多增加' + times + '条！'
                })
            }
        },
        // 删除-专题模块
        delButtonEvent(index) {
            this.ruleForm.splice(index, 1)
        },
        // 取消-返回上一页
        myresetForm() {
            this.$router.go(-1) // 返回上一页
        },
        //获取upload子组件回传的数据
        getUploadFileEvent(data, index) {
            console.log('当前获取的回传数据：', data, index)
            this.ruleForm[index].icon_img = data.url
            this.ruleForm[index].icon_img_list = [{ url: data.url, name: data.name }]
            //特殊的内容再次进行了校验，如果符合检验规则，错误提示就是消失了。
            this.$refs.ruleForm.validateField(index + '.icon_img', (valid) => {
                if (!valid) {
                    return
                }
            })
        },
        // 删除上传的文件-事件（upload组件）
        deluploadfileEvent(data, index) {
            console.log('当前删除事件-返回的数据：', data, index)
            this.ruleForm[index].icon_img = ''
            this.ruleForm[index].icon_img_list = []
            //特殊的内容再次进行了校验，如果符合检验规则，错误提示就是消失了。
            this.$refs.ruleForm.validateField(index + '.icon_img', (valid) => {
                if (!valid) {
                    return
                }
            })
        },
        // 提交
        submitForm(formName) {
            var getdatas = this.$refs[formName].model
            console.log('提交的数据集：', getdatas)
            this.$refs[formName].validate((valid) => {
                if (valid) {
                    axios
                        .post(
                            '/manage/Diy/updateSpecial',
                            {
                                id: this.$route.query.id,
                                special_contents: getdatas
                            },
                            {
                                headers: {
                                    Auth: localStorage.getItem('token')
                                }
                            }
                        )
                        .then((response) => {
                            if (response.data.code === 0) {
                                ElMessage({
                                    type: 'success',
                                    message: '提交成功！'
                                })
                                this.$router.push({ path: '/recommendposition' })
                            }
                        })
                        .catch(function(error) {
                            // 请求失败处理
                            console.log(error)
                        })
                } else {
                    console.log('error submit!!')
                    return false
                }
            })
        }
    },
    created() {
        if (localStorage.getItem('token') === null || !localStorage.getItem('token')) {
            this.isshow = false
            this.$router.push('/').catch((error) => error)
        } else {
            this.loading = true
            axios
                .post(
                    '/manage/Diy/viewRecommend',
                    {
                        id: this.$route.query.id
                    },
                    {
                        headers: {
                            Auth: localStorage.getItem('token')
                        }
                    }
                )
                .then((response) => {
                    console.log(response.data)
                    if (response.data.code == 0) {
                        let getdata = response.data.result
                        // 数据处理-special_contents-前端处理
                        if (getdata.special_contents == null || getdata.special_contents == '') {
                            this.ruleForm = []
                        } else {
                            this.ruleForm = getdata.special_contents
                        }
                    }
                    this.loading = false
                })
                .catch(function(error) {
                    // 请求失败处理
                    console.log(error)
                    this.loading = false
                })
        }
    },
    components: {
        mainone,
        mainimgesup
    }
}
</script>

<style scoped>
.box {
    background-color: #ffffff;
    border-radius: 4px;
    color: #333333;
    padding: 10px 20px;
}

.box_title {
    line-height: 40px;
    height: 40px;
}

.box_title_font {
    float: left;
    font-weight: 400;
    font-size: 14px;
    color: #333333;
}

.box_title_button {
    float: right;
    font-weight: 400;
    font-size: 14px;
    color: #94a3d2;
}

.box_title_button:hover {
    cursor: pointer;
}

.box_content {
    border-top: 1px solid #eeeeee;
    margin-top: 10px;
}
</style>
