<template>
    <div>
        <mainone :mainonebread="mainonebread"></mainone>
        <div class="descriptions-box" v-loading="loading" element-loading-text="正在飞速加载中...">
            <el-descriptions title="" direction="vertical" :column="5" border>
                <el-descriptions-item label="活动名称">{{ topdata.name }}</el-descriptions-item>
                <el-descriptions-item label="创建人">
                    <div>
                        <div>
                            <span>姓名：</span><span>{{ topdata.truename }}</span>
                        </div>
                        <div>
                            <span>账户名：</span><span>{{ topdata.username }}</span>
                        </div>
                    </div>
                </el-descriptions-item>
                <el-descriptions-item label="添加时间">
                    <span>{{ this.$utils.formatDate(topdata.addtime) }}</span>
                </el-descriptions-item>
                <el-descriptions-item label="备注">{{ topdata.remark }}</el-descriptions-item>
                <el-descriptions-item label="有效期">{{ this.$utils.formatDate(topdata.starttime) + ' 至 ' + this.$utils.formatDate(topdata.endtime) }}</el-descriptions-item>
                <el-descriptions-item label="发行量">{{ topdata.total }}</el-descriptions-item>
                <el-descriptions-item label="京币面额">{{ topdata.amount }}</el-descriptions-item>
                <el-descriptions-item label="已兑换">{{ topdata.used }}</el-descriptions-item>
                <el-descriptions-item label="使用公司">
                    <div style="white-space: pre-wrap;">
                        <span>{{ topdata.allow_companyids_name.join('\n') }}</span>
                    </div>
                </el-descriptions-item>
            </el-descriptions>
        </div>

        <maintwo
            :maintwoiconshow="maintwoiconshow"
            :maintwoforminfo="maintwoforminfo"
            :myinputoneisshow="maintwoforminfo.myinputoneisshow"
            :myinputone="maintwoforminfo.myinputone"
            :myselectleftisshow="maintwoforminfo.selectleftisshow"
            :myselectleft="maintwoforminfo.selectleft"
            :myselecttopisshow="maintwoforminfo.selecttopisshow"
            :myselecttop="maintwoforminfo.selecttop"
            :myinputishow="myinputishow"
            :myinput="myinput"
            :myinputonefullisshow="maintwoforminfo.myinputonefullisshow"
            :myinputonefull="maintwoforminfo.myinputonefull"
            :myselectisshow="myselectisshow"
            :myselect="myselect"
            :myselectcityisshow="myselectcityisshow"
            :myselectcity="myselectcity"
            v-on:getsearchinfo="getSeachinfoEvent"
            v-on:searchForm="searchFormEvent"
        ></maintwo>
        <!--table表单-->
        <div>
            <!--主list列表-->
            <div class="box-list">
                <div style="line-height: 60px">
                    <!--左侧名称-->
                    <div style="float: left; font-size: 14px">数据列表</div>
                    <!--右侧按钮-->
                    <div style="float: right">
                        <!--自定义按钮-点击出现弹框&点击跳转子类页面@click="routeraddrClick('/XX路由')"-->
                        <!--1、点击出现弹框@click="addTanClick(XX弹框配置信息,dialogVisible = true);resetFormSenior(XXform表单)"-->
                        <!--2、点击跳转子类页面@click="routeraddrClick('/XX路由')-->
                        <!--<el-button ></el-button>-->
                    </div>
                </div>

                <div>
                    <!--table表单-->
                    <el-table
                        :header-cell-style="{ background: '#F5F7FA' }"
                        ref="multipleTable"
                        :row-class-name="tableRowClassName"
                        :data="mainthreetableData.slice((currentPage - lastpage) * PageSize, currentPage * PageSize)"
                        style="width: 100%"
                        min-height="900"
                        border
                        v-loading="loading"
                        element-loading-text="正在飞速加载中..."
                        @selection-change="handleSelectionChange"
                        @cell-click="handleOneData"
                    >
                        <!--多选框-用于用户选择性导出数据-->
                        <el-table-column type="selection" width="55" v-if="mainthreeisshowset" />
                        <el-table-column
                            v-for="(mytabletitle, i) in mainthreetabletitle"
                            :key="i"
                            :fixed="mytabletitle.fixedstatu"
                            :prop="mytabletitle.prop"
                            :label="mytabletitle.label"
                            :width="mytabletitle.width"
                        >
                            <!--这里先通过table列表中的title值进行判断，在进行渲染展示-->
                            <!--例如：status状态字段，判断最终的值是那种表现形式等-->
                            <template #default="scope" v-if="mytabletitle.prop === 'receivetime' || mytabletitle.prop === 'platform'">
                                <!-- 兑换时间 -->
                                <div v-if="mytabletitle.prop === 'receivetime'">
                                    <span>{{ scope.row.receivetime != 0 ? this.$utils.formatDate(scope.row.receivetime) : '' }}</span>
                                </div>
                                <!-- 平台来源 -->
                                <div v-if="mytabletitle.prop === 'platform'">
                                    <span>
                                        {{
                                            scope.row.platform != 0 ? this.maintwoforminfo.selecttop.select[1].options.filter((n) => n.value == scope.row.platform)[0]['label'] : ''
                                        }}
                                    </span>
                                </div>
                            </template>
                        </el-table-column>
                    </el-table>
                    <!--分页器-->
                    <div class="block" style="text-align: center; margin: 30px 0">
                        <el-pagination
                            background
                            @size-change="handleSizeChange"
                            @current-change="handleCurrentChange"
                            :current-page="currentPage"
                            :page-sizes="pageSizes"
                            :page-size="PageSize"
                            layout="total, sizes, prev, pager, next, jumper"
                            :total="totalCount"
                        >
                        </el-pagination>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import mainone from '@/components/Mainone'
import maintwo from '@/components/Maintwo'
import componentProperties from '@/assets/js/componentProperties' // 公共数据
import axios from 'axios'
import { defineComponent } from 'vue'

export default defineComponent({
    name: 'vyingbipresentinfo',
    inject: ['reload'], //刷新引用
    data() {
        return {
            mainonebread: [
                {
                    name: '运营'
                },
                {
                    name: '京币'
                },
                {
                    name: '活动送京币',
                    path: '/yingbipresent'
                },
                {
                    name: '数据详情'
                }
            ], // 当前页-【面包屑导航】
            maintwoiconshow: false, // 筛选-【高级筛选按钮】
            maintwoforminfo: {
                selecttopisshow: true, //筛选查询-左侧字+select单框||select时间
                selecttop: {
                    width: 'none',
                    divinterval: '', //框右侧距离
                    selectwidth: '160px',
                    selectinterval: '20px', //select右侧距离
                    select: [
                        {
                            selectisshow: true,
                            ismust: false,
                            title: '小程序名：',
                            placeholder: '',
                            name: 'companyid',
                            value: '',
                            options: []
                        },
                        {
                            selectisshow: true,
                            ismust: false,
                            title: '平台来源：',
                            placeholder: '',
                            name: 'platform',
                            value: '1',
                            options: componentProperties.get('thirdpartyoption').options
                        }
                        // {
                        //     selectisshow: true,
                        //     ismust: false,
                        //     title: '使用状态：',
                        //     placeholder: '全部',
                        //     name: 'status',
                        //     value: '',
                        //     options: [
                        //         {
                        //             label: '全部',
                        //             value: ''
                        //         },
                        //         {
                        //             label: '未使用',
                        //             value: '-1'
                        //         },
                        //         {
                        //             label: '已使用',
                        //             value: '1'
                        //         },
                        //         {
                        //             label: '已过期',
                        //             value: '2'
                        //         }
                        //     ]
                        // }
                    ]
                },
                myinputoneisshow: true, //筛选查询-左侧字+input
                myinputone: {
                    divinterval: '0', //框右侧距离
                    titlewidth: '74px', //标题宽度
                    inputwidth: '180px', //input宽度
                    inputinterval: '20px', //input右侧距离
                    input: [
                        {
                            ismust: false, //是否必填，true表示必填
                            title: '用户ID：',
                            placeholder: '请输入用户ID',
                            name: 'receive_uid',
                            content: ''
                        },
                        {
                            ismust: false,
                            title: '用户昵称：',
                            placeholder: '请输入用户昵称',
                            name: 'nickname',
                            content: ''
                        },
                        {
                            ismust: false,
                            title: '手机号：',
                            placeholder: '请输入手机号',
                            name: 'mobile',
                            content: ''
                        }
                    ]
                }
            }, // 筛选-控件信息
            mainthreeisshowbut: false, // 数据列表中-【添加按钮】
            mainthreeisshowset: false, // 数据列表中-复选框
            mainthreeisshowope: false, // 数据列表中-操作-根据页面显示模块（整页）
            mainthreetableope: 26, // 数据列表中-操作-根据页面显示控件（整页）
            mainthreehandle: 0, // 数据列表中-操作-宽度
            mainthreetabletitle: [
                {
                    fixedstatu: false,
                    prop: 'mini_name',
                    label: '小程序名',
                    width: ''
                },
                {
                    fixedstatu: false,
                    prop: 'receive_uid',
                    label: '用户ID',
                    width: '140'
                },
                {
                    fixedstatu: false,
                    prop: 'nickname',
                    label: '用户昵称',
                    width: ''
                },
                {
                    fixedstatu: false,
                    prop: 'mobile',
                    label: '手机号',
                    width: '180'
                },
                {
                    fixedstatu: false,
                    prop: 'code',
                    label: '兑换码',
                    width: '260'
                },
                {
                    fixedstatu: false,
                    prop: 'platform',
                    label: '平台来源',
                    width: '180'
                },
                {
                    fixedstatu: false,
                    prop: 'receivetime',
                    label: '兑换时间',
                    width: '180'
                }
                // {
                //     fixedstatu: false,
                //     prop: 'status_name',
                //     label: '当前状态',
                //     width: '250'
                // },
            ], // 数据列表中-数据表头
            mainthreestatus: true, // 数据列表中-属于search按钮表示状态
            mainthreetableData: [], // 数据列表中-具体数据
            totalCount: 1, //数据列表中-数据总条数
            currentpage: 1, //数据列表中-数据当前页数
            lastpage: 1, //数据列表中-数据总页数（默认以20条一页）
            loading: false, //初始化-loading加载动效，默认是false关闭

            //table表单相关数据
            seniorform: {},
            currentPage: 1,
            pageSizes: [20, 50, 100],
            PageSize: 20,
            multipleSelection: [],

            // 顶部明细
            topdata: {
                used: '',
                amount: '',
                total: '',
                addtime: '',
                username: '',
                truename: '',
                endtime: '',
                starttime: '',
                remark: '',
                name: '',
                allow_companyids_name: []
            },

            mypowerlimits: JSON.parse(localStorage.getItem('powerlimits')), //获取权限
            mytoken: localStorage.getItem('token'), //获取存储的token
            pagesdatas: [] //存储最近一次的筛选条件，供分页使用
        }
    },
    methods: {
        //分页器中-当选择条数时触发该函数
        handleSizeChange(val) {
            // console.log(`每页 ${val} 条`)
            this.PageSize = val
            this.currentPage = 1

            this.pagesdatas = {
                id: this.$route.query.id,
                platform: this.pagesdatas.platform,
                receive_uid: this.pagesdatas.receive_uid,
                nickname: this.pagesdatas.nickname,
                mobile: this.pagesdatas.mobile,
                companyid: this.pagesdatas.companyid,
                page: 1,
                page_size: val
            }

            this.postAxiosEvent(this.pagesdatas)
        },
        //分页器中-当选择分页时触发该函数
        handleCurrentChange(val) {
            // console.log(`当前页: 第${val}页`)
            this.currentPage = val

            this.pagesdatas = {
                id: this.$route.query.id,
                platform: this.pagesdatas.platform,
                receive_uid: this.pagesdatas.receive_uid,
                nickname: this.pagesdatas.nickname,
                mobile: this.pagesdatas.mobile,
                companyid: this.pagesdatas.companyid,
                page: val,
                page_size: this.PageSize
            }

            this.postAxiosEvent(this.pagesdatas)
        },

        //提交等相关event事件函数
        //获取Container-Pagedata-Maintwo-搜索-返回的数据
        getSeachinfoEvent(data) {
            // console.log('点击查询获取的data数组：' + JSON.stringify(data))
            this.pagesdatas = {
                id: this.$route.query.id,
                platform: data.platform,
                receive_uid: data.receive_uid,
                nickname: data.nickname,
                mobile: data.mobile,
                companyid: data.companyid,
                page: 1,
                page_size: this.PageSize
            }

            this.postAxiosEvent(this.pagesdatas)
        },
        //数据渲染
        myaxiosfunc(successdata) {
            var newdata = successdata.data
            // console.log('当前页面的data:' + JSON.stringify(newdata))
            this.mainthreetableData = newdata
            this.totalCount = successdata.total
            this.currentpage = successdata.current_page //当前页面页数
            this.lastpage = successdata.last_page
            this.loading = false
        },
        // 接口
        postAxiosEvent(data) {
            this.loading = true
            axios
                .post('/manage/coinActivity/dataList', data, {
                    headers: {
                        Auth: localStorage.getItem('token')
                    }
                })
                .then((response) => {
                    this.myaxiosfunc(response.data.result.list)

                    this.topdata = response.data.result.info
                })
                .catch(function(error) {
                    console.log(error)
                })
        },
        // 获取小程序名-下拉
        getCompanyOptions() {
            axios
                .get('/manage/Manage/comList', {
                    headers: {
                        Auth: localStorage.getItem('token')
                    },
                    params: {
                        name: '',
                        status: '',
                        page: 1,
                        page_size: 10000
                    }
                })
                .then((response) => {
                    this.maintwoforminfo.selecttop.select[0].options = response.data.result.data.map((n) => {
                        return { label: n.mini_name, value: n.id }
                    })
                })
                .catch(function(error) {
                    console.log(error)
                })
        }
    },
    created() {
        if (localStorage.getItem('token') === null || !localStorage.getItem('token')) {
            this.isshow = false
            this.$router.push('/').catch((error) => error)
        } else {
            this.getCompanyOptions()

            this.pagesdatas = {
                id: this.$route.query.id,
                platform: '1', // 默认进入查询 微信自助版下的数据
                page: 1,
                page_size: this.PageSize
            }

            this.postAxiosEvent(this.pagesdatas)
        }
    },
    components: {
        mainone,
        maintwo
    }
})
</script>

<style scoped>
/*操作中-按钮样式*/
.el-button--mini {
    min-height: 28px;
    padding: 0 8px;
    font-size: 12px;
    border-radius: calc(var(--el-border-radius-base) - 1px);
}

.descriptions-box {
    padding: 20px;
    background-color: #ffffff;
    margin-bottom: 10px;
    border-radius: 4px;
}

.box-list {
    margin-top: 10px;
    background-color: #ffffff;
    border-radius: 4px;
    line-height: 40px;
    padding: 0 20px;
}
</style>
