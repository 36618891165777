<template>
    <div>
        <el-form ref="myFormChackRef" :model="ruleForm_content" :rules="rules_content" label-width="0">
            <div style="display: flex; flex-direction: column; justify-content: left; gap: 20px">
                <div v-for="(item, index) in ruleForm_content.content" :key="index">
                    <div class="chack-box-das flex-box-das border-box-das">
                        <div class="flex-1">
                            <div class="flex-box-das">
                                <div class="min-width-das">
                                    <span>周期：</span><span class="span-box-das">第&nbsp;&nbsp;{{ index + 1 }}&nbsp;&nbsp;周期</span>
                                </div>
                                <div class="flex-1 flex-box-das" style="gap: 10px">
                                    <div><span>总价款还款比例(%)：</span></div>
                                    <div style="width: 240px">
                                        <el-form-item :prop="'content.' + index + '.all_price_chack_ratio'" :rules="rules_content.all_price_chack_ratio">
                                            <el-input
                                                v-model="item.all_price_chack_ratio"
                                                placeholder="请输入总价款还款比例"
                                                clearable
                                                @change="(data) => changeInputEvent(data, index)"
                                            />
                                        </el-form-item>
                                    </div>
                                </div>
                            </div>
                            <div>
                                <el-button type="primary" size="mini" style="width: 100px; margin-bottom: 10px" @click="addMonthEvent(index)"> 新增月</el-button>
                            </div>
                            <el-form-item :prop="'content.' + index + '.months'" :rules="rules_content.months">
                                <el-input v-model="item.months" v-show="show_input" />
                                <div class="flex-box-das" style="flex-wrap: wrap">
                                    <div v-for="(jtem, jndex) in item.months" :key="jndex" class="flex-box-das border-box-das month-box-das flex-1">
                                        <div class="flex-1">
                                            <div>
                                                <span>名称：</span><span class="span-box-das-month">第&nbsp;&nbsp;{{ jndex + 1 }}&nbsp;&nbsp;个月</span>
                                            </div>
                                            <div>
                                                <span>月份：</span><span>{{ jtem.month }}</span>
                                            </div>
                                            <div class="flex-1 flex-box-das margin-bt-das" style="flex-wrap: nowrap; gap: 5px">
                                                <div style="width: 110px"><span>月还款比例(%):</span></div>
                                                <div style="width: 220px">
                                                    <el-form-item
                                                        :prop="'content.' + index + '.months.' + jndex + '.month_payback_ratio'"
                                                        :rules="rules_content.month_payback_ratio"
                                                    >
                                                        <el-input
                                                            v-model="jtem.month_payback_ratio"
                                                            placeholder="请输入月还款比例"
                                                            clearable
                                                            @change="(data) => changeMonthPaybackRatioEvent(data, index, jndex)"
                                                        />
                                                    </el-form-item>
                                                </div>
                                            </div>
                                            <div class="flex-1 flex-box-das" style="flex-wrap: nowrap; gap: 5px">
                                                <div style="width: 110px"><span>日阶段比例(%):</span></div>
                                                <div style="width: 220px;border: 1px dashed var(--el-color-primary);border-radius: 4px;">
                                                    <el-form-item
                                                        label-width="120px"
                                                        label="1~20天(含)："
                                                        :prop="'content.' + index + '.months.' + jndex + '.day_payback_ratio[0]'"
                                                        :rules="rules_content.day_payback_ratio"
                                                        style="padding:10px 10px 10px 0;"
                                                    >
                                                        <el-input
                                                            v-model="jtem.day_payback_ratio[0]"
                                                            placeholder=""
                                                            clearable
                                                            @change="(data) => dayPaybackRatioEvent(data, 0, index, jndex, jtem)"
                                                        />
                                                    </el-form-item>
                                                    <el-form-item
                                                        label-width="120px"
                                                        label="21~25天(含)："
                                                        :prop="'content.' + index + '.months.' + jndex + '.day_payback_ratio[1]'"
                                                        :rules="rules_content.day_payback_ratio"
                                                        style="padding:10px 10px 10px 0;"
                                                    >
                                                        <el-input
                                                            v-model="jtem.day_payback_ratio[1]"
                                                            placeholder=""
                                                            clearable
                                                            @change="(data) => dayPaybackRatioEvent(data, 1, index, jndex, jtem)"
                                                        />
                                                    </el-form-item>
                                                    <el-form-item
                                                        label-width="120px"
                                                        label="25天后："
                                                        :prop="'content.' + index + '.months.' + jndex + '.day_payback_ratio[2]'"
                                                        :rules="rules_content.day_payback_ratio"
                                                        style="padding:10px 10px 20px 0;"
                                                    >
                                                        <el-input
                                                            v-model="jtem.day_payback_ratio[2]"
                                                            placeholder=""
                                                            clearable
                                                            @change="(data) => dayPaybackRatioEvent(data, 2, index, jndex, jtem)"
                                                        />
                                                    </el-form-item>
                                                </div>
                                            </div>
                                            <!-- <div class="flex-1 flex-box-das" style="flex-wrap: nowrap; gap: 10px">
                                                <div style="width: 130px"><span>日还款增长率(%)：</span></div>
                                                <div class="flex-box-das" style="flex-wrap: nowrap; gap: 5px; width: 170px">
                                                    <div>
                                                        <el-form-item
                                                            :prop="'content.' + index + '.months.' + jndex + '.day_payback_ratio[0]'"
                                                            :rules="rules_content.day_payback_ratio"
                                                        >
                                                            <el-input
                                                                v-model="jtem.day_payback_ratio[0]"
                                                                placeholder=""
                                                                clearable
                                                                @change="(data) => dayPaybackRatioEvent(data, 0, index, jndex, jtem)"
                                                            />
                                                        </el-form-item>
                                                    </div>
                                                    <div><span>-</span></div>
                                                    <div>
                                                        <el-form-item
                                                            :prop="'content.' + index + '.months.' + jndex + '.day_payback_ratio[1]'"
                                                            :rules="rules_content.day_payback_ratio"
                                                        >
                                                            <el-input
                                                                v-model="jtem.day_payback_ratio[1]"
                                                                placeholder=""
                                                                clearable
                                                                @change="(data) => dayPaybackRatioEvent(data, 1, index, jndex, jtem)"
                                                            />
                                                        </el-form-item>
                                                    </div>
                                                </div>
                                            </div> -->
                                        </div>
                                        <div>
                                            <el-button type="danger" size="mini" icon="el-icon-close" @click="delMonthEvent(index, jndex)"></el-button>
                                        </div>
                                    </div>
                                </div>
                            </el-form-item>
                        </div>
                        <div>
                            <el-button type="danger" size="mini" icon="el-icon-delete" @click="delZhouQiEvent(index)"></el-button>
                        </div>
                    </div>
                </div>
            </div>
        </el-form>
    </div>
</template>

<script>
import { defineComponent } from 'vue'
import { ElMessage } from 'element-plus'

export default defineComponent({
    name: 'chackEnter',
    props: {
        content: {
            type: Array,
            default: () => {
                return []
            }
        },
        isDisabled: {
            type: Boolean,
            default: false
        }
    },
    watch: {
        content: {
            handler(newVal) {
                this.ruleForm_content.content = newVal
            },
            deep: true,
            immediate: true
        }
    },
    data() {
        return {
            show_input: false,
            ruleForm_content: {
                content: []
            },
            rules_content: {
                all_price_chack_ratio: [
                    {
                        required: true,
                        message: '总价款还款比例不能为空',
                        trigger: 'blur'
                    },
                    {
                        // 正则表达式(大于等于0的正整数)：/^\+?[0-9]\d*$/  或 /^\d\d*$/
                        pattern: /^\d\d*$/,
                        message: '总价款还款必须为正整数',
                        trigger: 'change'
                    },
                    {
                        // 正则表达式(小于等于 100的正整数，含 0)：/^(?:(?:[1-9]\d?|100)|0)$/g
                        // 正则表达式(小于等于 100的正整数，不含 0)：/^(?:[1-9]\d?|100)$/g
                        pattern: /^(?:(?:[1-9]\d?|100)|0)$/g,
                        message: '必须为小于等于 100 的正整数',
                        trigger: 'change'
                    }
                ],
                months: [
                    {
                        required: true,
                        message: '月份不能为空',
                        trigger: 'blur'
                    }
                ],
                month_payback_ratio: [
                    {
                        required: true,
                        message: '月还款比例不能为空',
                        trigger: 'blur'
                    },
                    {
                        // 正则表达式(大于等于0的正整数)：/^\+?[0-9]\d*$/  或 /^\d\d*$/
                        pattern: /^\d\d*$/,
                        message: '必须为正整数',
                        trigger: 'change'
                    },
                    {
                        // 正则表达式(小于等于 100的正整数，含 0)：/^(?:(?:[1-9]\d?|100)|0)$/g
                        // 正则表达式(小于等于 100的正整数，不含 0)：/^(?:[1-9]\d?|100)$/g
                        pattern: /^(?:(?:[1-9]\d?|100)|0)$/g,
                        message: '必须为小于等于 100 的正整数',
                        trigger: 'change'
                    }
                ],
                day_payback_ratio: [
                    {
                        required: true,
                        message: '不能为空',
                        trigger: 'blur'
                    },
                    {
                        // 正则表达式(大于等于0的正整数)：/^\+?[0-9]\d*$/  或 /^\d\d*$/
                        pattern: /^\d\d*$/,
                        message: '必须为正整数',
                        trigger: 'change'
                    },
                    {
                        // 正则表达式(小于等于 100的正整数，含 0)：/^(?:(?:[1-9]\d?|100)|0)$/g
                        // 正则表达式(小于等于 100的正整数，不含 0)：/^(?:[1-9]\d?|100)$/g
                        pattern: /^(?:[1-9]\d?|100)$/g,
                        message: '0<当前值<=100',
                        trigger: 'change'
                    }
                ]
            }
        }
    },
    methods: {
        // 删除周期
        delZhouQiEvent(index) {
            this.$emit('delZhouQi', index)
        },
        // 新增月
        addMonthEvent(index) {
            this.$emit('addMonth', index)
        },
        // 删除月
        delMonthEvent(index, jndex) {
            this.$emit('delMonth', { index: index, jndex: jndex })
        },
        // 修改-周期中-总价款还款比例
        changeInputEvent(data, index) {
            this.$emit('changeAllPriceChackRatio', { data: data, index: index })
        },
        // 修改-周期中-月还款比例
        changeMonthPaybackRatioEvent(data, index, jndex) {
            this.$emit('changeMonthPaybackRatio', { data: data, index: index, jndex: jndex })
        },
        // 修改-日还款增长率
        dayPaybackRatioEvent(data, flag, index, jndex, jtem) {
            let [a, b, c] = jtem.day_payback_ratio

            if (a && b && c) {
                if (parseInt(a) + parseInt(b) + parseInt(c) != 100) {
                    ElMessage({
                        message: '第 ' + (parseInt(index) + parseInt(1)) + ' 周期中，第 ' + (parseInt(jndex) + parseInt(1)) + ' 个月的日阶段比例之和必须等于100',
                        type: 'error'
                    })
                    jtem.day_payback_ratio[flag] = null
                    throw new Error('日还款增长率必须递增')
                }
            }
        }
    }
})
</script>

<style scoped>
.chack-box-das {
    min-width: 600px;
}

.month-box-das {
    min-width: 380px;
    max-width: 380px;
}
.border-box-das {
    border-radius: 4px;
    border: 1px dashed var(--el-color-primary);
    padding: 14px 14px 20px 14px;
}

.flex-box-das {
    display: flex;
    flex-direction: rwo;
    justify-content: left;
    align-items: flex-start;
    flex-wrap: nowrap;
    gap: 20px;
}
.flex-1 {
    flex: 1;
}

.span-box-das {
    font-size: 20px;
    font-weight: bold;
    color: #333;
}

.span-box-das-month {
    font-size: 20px;
    font-weight: bold;
    color: #333;
}

.min-width-das {
    min-width: 200px;
}

.margin-bt-das {
    margin-bottom: 20px;
}
</style>
