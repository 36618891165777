<template>
    <div>
        <!-- 远程搜索 -->
        <el-select
            v-model="modelValue"
            :placeholder="placeholder"
            :multiple="myformitem.multiple"
            :filterable="myformitem.filterable"
            :remote="myformitem.remote"
            :reserve-keyword="myformitem.reserve_keyword"
            :remote-method="remoteMethod"
            :loading="loading"
        >
            <el-option v-for="(item, index) in options" :key="index" :label="item.label" :value="item.value" />
        </el-select>
    </div>
</template>

<script>
import http from '@/assets/js/http'
export default {
    name: 'xwSelectRemote',
    props: {
        model_value: {
            default: ''
        },
        model_props: {
            type: Object,
            default() {
                return {
                    multiple: true,
                    filterable: true,
                    remote: true,
                    reserve_keyword: true
                }
            }
        },
        placeholder: {
            type: String,
            default: ''
        },
        axios_type: {
            type: String,
            default: 'post'
        },
        axios_url: {
            type: String,
            default: ''
        },
        axios_key: {
            type: String,
            default: ''
        },
        axios_exprops: {
            type: Object,
            default() {
                return {
                    label: 'label',
                    value: 'value'
                }
            }
        }
    },
    watch: {
        model_value: {
            handler(newVal) {
                this.modelValue = newVal
            },
            deep: true,
            immediate: true
        },
        model_props: {
            handler(newVal) {
                this.myformitem = newVal
            },
            deep: true,
            immediate: true
        },
        axios_type: {
            handler(newVal) {
                this.axiosType = newVal
            },
            deep: true,
            immediate: true
        },
        axios_url: {
            handler(newVal) {
                this.axiosUrl = newVal
            },
            deep: true,
            immediate: true
        },
        axios_key: {
            handler(newVal) {
                this.axiosKey = newVal
            },
            deep: true,
            immediate: true
        },
        axios_exprops: {
            handler(newVal) {
                this.axiosExprops = newVal
            },
            deep: true,
            immediate: true
        }
    },
    data() {
        return {
            // 加载
            loading: false,
            // value
            modelValue: '',
            // 属性
            myformitem: {},
            // 远程搜索到的options
            options: [],
            // 远程搜索接口类型
            axiosType: '',
            // 远程搜索url
            axiosUrl: '',
            // 远程搜索key
            axiosKey: '',
            // 远程搜索的替换字段
            axiosExprops: {}
        }
    },
    methods: {
        async remoteMethod(data) {
            console.log(data)
            let post_datas = {}
            post_datas[this.axiosKey] = data
            if (this.axiosType == 'get') post_datas = { params: post_datas }
            let result = await http[this.axiosType](this.axiosUrl, post_datas)
            console.log('result:', result)
            this.options = result.data.map((n) => {
                return {
                    label: n[this.axiosExprops['label']],
                    value: n[this.axiosExprops['value']]
                }
            })
            this.$emit('submit', true)
            this.$emit('modelValue', data)
        }
    }
}
</script>
