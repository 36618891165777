<template>
    <div>
        <div @click="tanClickEvent()" style="font-size: 14px">
            <span :style="{ color: span_style.color, cursor: span_style.cursor }">{{ vmodel }}</span>
        </div>
        <!-- 弹窗 -->
        <el-dialog v-model="tanbox.isshow" :title="dialog.title" :width="dialog.width" append-to-body :before-close="handleClose">
            <div class="tanbox-boxs">
                <div style="flex: 1"><el-input v-model="search_value" clearable placeholder="请输入唯一标识" @clear="(value) => clickSearchEvent(value)" /></div>
                <div>
                    <el-button type="primary" plain @click="clickSearchEvent(search_value)">查询</el-button>
                    <el-button type="info" plain @click="clickClearEvent()">重置</el-button>
                </div>
            </div>
            <dpwTable
                v-loading="tanbox.loading"
                element-loading-text="正在飞速加载中..."
                ref="myTableDataRef"
                :tabletitle="table.tabletitle"
                :tabledata="tanbox.tableData"
                :totalcount="tanbox.totalCount"
                :currentpage="tanbox.currentPage"
                :pagesize="tanbox.PageSize"
                :Lastpage="tanbox.lastpage"
                :Operation="table.Operation"
            >
                <template #content="{ scope, prop }">
                    <div>
                        <span>{{ scope.row[prop] }}</span>
                    </div>
                </template>
                <template #special="{ scope }">
                    <div>
                        <el-button type="danger" size="small" @click="tanDelEvent(scope.$index)">删除</el-button>
                    </div>
                </template>
            </dpwTable>
            <div style="text-align: right; margin: 20px 0">
                <el-button @click="cancelEvent()">取消</el-button>
                <el-button type="primary" @click="submitEventText(tanbox.tableData)">确定</el-button>
            </div>
        </el-dialog>
    </div>
</template>

<script>
import { defineComponent } from 'vue'
import dpwTable from '../dpw-table.vue'

export default defineComponent({
    name: 'numberEnter',
    props: {
        dialog: {
            type: Object,
            default: () => {
                return {
                    title: '数量',
                    width: '30%'
                }
            }
        },
        table: {
            type: Object,
            default: () => {
                return {
                    tabletitle: [],
                    tableData: [],
                    Operation: { isshow: true, width: 120 }
                }
            }
        },
        vmodel: {
            type: String,
            default: ''
        },
        span_style: {
            type: Object,
            default: () => {
                return {
                    color: '',
                    cursor: ''
                }
            }
        }
    },
    data() {
        return {
            tanbox: {
                isshow: false,
                tableData: [],
                totalCount: 0,
                currentPage: 1,
                PageSize: 10000,
                pageSizes: [10, 20, 50, 100],
                lastpage: 1
            },
            // 实收数量-弹窗显示
            search_value: '',
            search_datas: []
        }
    },
    methods: {
        // 深拷贝
        deepcopy(obj, cache = []) {
            function find(list, f) {
                return list.filter(f)[0]
            }
            // just return if obj is immutable value
            if (obj === null || typeof obj !== 'object') {
                return obj
            }
            // if obj is hit, it is in circular structure
            const hit = find(cache, (c) => c.original === obj)
            if (hit) {
                return hit.copy
            }
            const copy = Array.isArray(obj) ? [] : {}
            // put the copy into cache at first
            // because we want to refer it in recursive deepCopy
            cache.push({
                original: obj,
                copy
            })
            Object.keys(obj).forEach((key) => {
                copy[key] = this.deepcopy(obj[key], cache)
            })
            return copy
        },
        tanClickEvent() {
            console.log('this.table.tableData:', this.table.tableData)
            this.search_datas = this.deepcopy(this.table.tableData)
            this.tanbox.tableData = this.deepcopy(this.table.tableData)
            this.tanbox.isshow = true
        },
        clickSearchEvent(data) {
            if (data && data != '') {
                this.tanbox.tableData = this.search_datas
                    .map((n) => {
                        if (n.only_key.includes(data)) {
                            return n
                        }
                    })
                    .filter((n) => n)
            } else {
                this.tanbox.tableData = this.search_datas
            }
        },
        clickClearEvent() {
            this.search_value = ''
            this.tanbox.tableData = this.search_datas
        },
        tanDelEvent(index) {
            this.tanbox.tableData.splice(index, 1)
            this.search_datas = this.tanbox.tableData
            console.log('this.table.tableData:', this.table.tableData)
        },
        submitEventText(tableData) {
            this.$emit('tanPostData', { goods_num: tableData.length, goods_info: tableData })
            this.tanbox.isshow = false
        },
        cancelEvent() {
            this.tanbox.isshow = false
        },
        handleClose(done) {
            this.cancelEvent()
            done()
        }
    },
    components: {
        dpwTable
    }
})
</script>

<style scoped>
.tanbox-boxs {
    display: flex;
    flex-direction: row;
    justify-content: left;
    align-items: center;
    flex-wrap: nowrap;
    gap: 20px;
    margin: 10px 0 20px;
}
</style>
