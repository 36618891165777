<template>
    <div>
        <div @click="tanClickEvent()" style="font-size: 14px">
            <span :style="{ color: span_style.color, cursor: span_style.cursor }">{{ vmodel.length }}</span>
        </div>
        <!-- 弹窗 -->
        <el-dialog v-model="tanbox.isshow" :title="dialog.title" :width="dialog.width" append-to-body :before-close="handleClose">
            <div style="min-height: 300px; max-height: 600px; overflow-y: auto; padding: 20px 0">
                <div style="line-height: 32px; font-size: 14px">
                    <div>
                        <span>产品名称：</span><span>{{ span_info.name }}</span>
                    </div>
                    <div>
                        <span>类别：</span><span>{{ span_info.type }}</span>
                    </div>
                </div>
                <el-divider />
                <dwpImages :images="ruleForm.imgs_list" />
            </div>
        </el-dialog>
    </div>
</template>

<script>
import { defineComponent } from 'vue'
import dwpImages from '../dpw-images.vue'

export default defineComponent({
    name: 'showImgsEnter',
    props: {
        dialog: {
            type: Object,
            default: () => {
                return {
                    title: '上传图片',
                    width: '30%'
                }
            }
        },
        vmodel: {
            type: Array,
            default: () => {
                return []
            }
        },
        span_style: {
            type: Object,
            default: () => {
                return {
                    color: '',
                    cursor: ''
                }
            }
        },
        span_info: {
            type: Object,
            default: () => {
                return {
                    name: '',
                    type: ''
                }
            }
        }
    },
    data() {
        return {
            tanbox: {
                isshow: false
            },

            ruleForm: {
                imgs_list: []
            },

            // 照片
            myimgesupisshow: true, //form表单-imgesuploade上传图片
            myimgesup: {
                uploadsize: 1, // 1-前端直传 oss ； 2-前端传后端
                listtype: 'picture', //list展示类型，text/picture/picture-card；默认不填为text
                ismust: true, //是否必填，true表示必填
                action: '/manage/upload/file11', //upload上传API地址
                headers: { Auth: localStorage.getItem('token') }, //upload上传headers
                title: '上传图片', //button-按钮文字
                tips: '最多上传 10 张，支持扩展名：*.jpeg、*.jpg、*.png', //提示&建议文字
                limit: '10', //上传数量限制
                multiple: true,
                target_id: 100, //上传文件target_id ：图片分类，区分存储文件和存储时长，不传默认存储1天后过期；1-二维码； 2-打印文件； 3-文库文件； 4-课程文件； 5-广告主宣传页； 6-广告主产生的pdf文件； 7-证件照文件； 50-设备广告； 100-通用
                rules: {
                    images: {
                        image_width: 0, //图片宽度，0表示不限宽
                        image_height: 0, //图片高度，0表示不限高
                        size: 20000, //文件大小（单位kb）
                        type: ['image/jpeg', 'image/jpg', 'image/png'], //文件格式
                        typechart: 'png/jpg/jpeg' //文件格式提示规则
                    }
                }
            }
        }
    },
    methods: {
        // 深拷贝
        deepcopy(obj, cache = []) {
            function find(list, f) {
                return list.filter(f)[0]
            }
            // just return if obj is immutable value
            if (obj === null || typeof obj !== 'object') {
                return obj
            }
            // if obj is hit, it is in circular structure
            const hit = find(cache, (c) => c.original === obj)
            if (hit) {
                return hit.copy
            }
            const copy = Array.isArray(obj) ? [] : {}
            // put the copy into cache at first
            // because we want to refer it in recursive deepCopy
            cache.push({
                original: obj,
                copy
            })
            Object.keys(obj).forEach((key) => {
                copy[key] = this.deepcopy(obj[key], cache)
            })
            return copy
        },
        tanClickEvent() {
            this.tanbox.isshow = true
            this.ruleForm.imgs_list = this.vmodel.map((n) => {
                return n.url
            })
        },
        handleClose(done) {
            done()
        }
    },
    components: {
        dwpImages
    }
})
</script>

<style scoped></style>
