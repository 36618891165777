<template>
    <div class="page-container">
        <div class="container-table">
            <div class="table-header">
                <div>数据列表</div>
                <div>
                    <el-button type="primary" @click="exportRecord">导出</el-button>
                </div>
            </div>
            <el-table :border="true" :data="table.data" v-loading="loading" >
                <el-table-column prop="" label="序号" fixed="left">
                    <template #default="{ $index }">
                        <span>{{ $index + 1 }}</span>
                    </template>
                </el-table-column>
                <el-table-column prop="order" label="打印机" fixed="left" />
                <el-table-column prop="out_total" label="工厂库存"/>
            </el-table>
            <div class="tabler-pagination">
                <el-pagination
                    @size-change="getTableData()"
                    @current-change="getTableData"
                    :current-page="params.page"
                    :page-sizes="[20, 50, 100, 1000]"
                    v-model:page-size="params.page_size"
                    :total="table.total"
                    layout="total, sizes, prev, pager, next, jumper"
                />
            </div>
        </div>
    </div>
</template>

<script setup>
import { ref, reactive } from 'vue'
import http from '@/assets/js/http'

// 获取权限
const mypowerlimits = reactive(JSON.parse(localStorage.getItem('powerlimits')))

// 过滤
const params = reactive({
    page: 1,
    page_size: 20
})

// 表格
const loading = ref(false)
const table = reactive({
    data: [],
    columns: [],
    total: 0
})

const getTableData = async (page = 1) => {
    params.page = page
    loading.value = true
    try {
        const res = await http.get('WarehouseConfig/totalList', { params })
		Object.assign(table, res)
    } catch (e) {
        console.log(e)
    }
    loading.value = false
}

// 操作
// 导出
const exportRecord = async () => {
    await http.get('WarehouseConfig/totalList', { params: { ...params, export: 1 }, sMsg: true })
}

getTableData()
</script>

<style lang="scss" scoped>
.page-container {
    background-color: #e9eef3;

    .container-table {
        margin-top: 4px;
        padding: 24px;
        background-color: #fff;
        .table-header {
            display: flex;
            justify-content: space-between;
            margin-bottom: 12px;
        }

        .skip {
            cursor: pointer;
            color: var(--el-color-primary);
        }
        .tabler-pagination {
            display: flex;
            justify-content: space-between;
            margin-top: 12px;
        }
    }
}

.container-drawer {
    .filter-tree {
        margin-top: 24px;
    }
    .drawer-footer {
        margin-top: 24px;
        display: flex;
        justify-content: flex-end;
    }
}
</style>
