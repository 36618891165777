<template>
    <div>
        <mainone :mainonebread="mainonebread"></mainone>
        <maintwo
            :maintwoiconshow="maintwoiconshow"
            :maintwoforminfo="maintwoforminfo"
            :myinputoneisshow="maintwoforminfo.myinputoneisshow"
            :myinputone="maintwoforminfo.myinputone"
            :myselectleftisshow="maintwoforminfo.selectleftisshow"
            :myselectleft="maintwoforminfo.selectleft"
            :myselecttopisshow="maintwoforminfo.selecttopisshow"
            :myselecttop="maintwoforminfo.selecttop"
            :myinputishow="myinputishow"
            :myinput="myinput"
            :myinputonefullisshow="maintwoforminfo.myinputonefullisshow"
            :myinputonefull="maintwoforminfo.myinputonefull"
            :myinputonefullgaoisshow="myinputonefullgaoisshow"
            :myinputonefullgao="myinputonefullgao"
            :myselectisshow="myselectisshow"
            :myselect="myselect"
            :myselectuptopisshow="myselecttopisshow"
            :myselectuptop="myselecttop"
            :myselectcityisshow="myselectcityisshow"
            :myselectcity="myselectcity"
            v-on:getsearchinfo="getSeachinfoEvent"
            v-on:searchForm="searchFormEvent"
        ></maintwo>
        <!--table表单-->
        <div>
            <!--主list列表-->
            <div style="margin-top: 10px; background-color: #ffffff; border-radius: 4px; line-height: 40px; padding: 0 20px">
                <div style="line-height: 60px">
                    <!--左侧名称-->
                    <div style="float: left; font-size: 14px">数据列表</div>
                    <!--右侧按钮-->
                    <div style="float: right">
                        <!--自定义按钮-点击出现弹框&点击跳转子类页面@click="routeraddrClick('/XX路由')"-->
                        <!--1、点击出现弹框@click="addTanClick(XX弹框配置信息,dialogVisible = true);resetFormSenior(XXform表单)"-->
                        <!--2、点击跳转子类页面@click="routeraddrClick('/XX路由')-->
                        <!--<el-button ></el-button>-->
                        <!--导出按钮-点击导出数据-->
                        <!-- 临时任务：20230217：隐藏资金方导出&table列表隐藏安装时间 -->
                        <el-button type="primary" plain @click="tanEvent(1, this.multipleSelection), (tan_title = '批量开启')">批量开启</el-button>
                        <el-button type="danger" plain @click="tanEvent(-1, this.multipleSelection), (tan_title = '批量关闭')">批量关闭</el-button>
                        <!-- <el-button type="warning" plain @click="exportDatas()">导出</el-button> -->
                    </div>
                </div>

                <div>
                    <commTable
                        :loading="loading"
                        element-loading-text="正在飞速加载中..."
                        ref="mymultipleTableRef"
                        :tabletitle="mainthreetabletitle"
                        :tabledata="mainthreetableData"
                        :totalcount="totalCount"
                        :currentpage="currentPage"
                        :pagesize="PageSize"
                        :Lastpage="lastpage"
                        :Operation="{ width: 140 }"
                        @handleSelection="handleSelectionEvent"
                        @handleSelectionOnline="handleSelectionChangeOnline"
                    >
                        <template #content="{ scope, prop }">
                            <div v-if="prop == 'on_state_name'">
                                <span :style="{ color: scope.row[prop] == '开启' ? 'var(--el-color-success)' : 'var(--el-color-danger)' }">{{ scope.row[prop] }}</span>
                            </div>
                            <!-- 其他 -->
                            <div v-else>
                                <span>{{ scope.row[prop] }}</span>
                            </div>
                        </template>
                        <template #special="{ scope }">
                            <div>
                                <el-button type="primary" size="mini" @click="tanEvent(1, [scope.row]), (tan_title = '单个开启')">开启</el-button>
                                <el-button type="danger" size="mini" @click="tanEvent(-1, [scope.row]), (tan_title = '单个关闭')">关闭</el-button>
                            </div>
                        </template>
                    </commTable>
                    <!--分页器-->
                    <div class="block" style="text-align: center; margin: 30px 0">
                        <el-pagination
                            background
                            @size-change="handleSizeChange"
                            @current-change="handleCurrentChange"
                            :current-page="currentPage"
                            :page-sizes="pageSizes"
                            :page-size="PageSize"
                            layout="total, sizes, prev, pager, next, jumper"
                            :total="totalCount"
                        >
                        </el-pagination>
                    </div>
                </div>

                <!--弹出框-->
                <el-dialog v-model="dialogVisible" :title="tan_title" :width="this.searchwidth" :before-close="handleCloseSenior">
                    <el-form ref="seniorform" :model="seniorform" :rules="rules" label-width="100px" class="demo-ruleForm">
                        <div class="tan-box">
                            <el-form-item label="手机号：">
                                <span>{{ seniorform.mobile }}</span>
                            </el-form-item>
                            <el-form-item label="验证码：" prop="code">
                                <div class="code-box">
                                    <div style="flex:1">
                                        <el-input v-model="seniorform.code" maxlength="6" show-word-limit clearable></el-input>
                                    </div>
                                    <div>
                                        <el-button
                                            id="secondid"
                                            type="success"
                                            style="min-width: 120px;"
                                            @click="is_disabled ? '' : getIdeCodeEvent(seniorform.mobile)"
                                            :disabled="is_disabled"
                                            >{{ code_btn }}</el-button
                                        >
                                    </div>
                                </div>
                            </el-form-item>
                        </div>
                        <el-form-item>
                            <div style="text-align: right; margin-right: 15px">
                                <el-button @click="handleCloseSenior">取消 </el-button>
                                <el-button type="primary" @click="onSubmitSenior('seniorform')" class="main_two_search" :disabled="this.disabled_tan_btn">确定 </el-button>
                            </div>
                        </el-form-item>
                    </el-form>
                </el-dialog>
            </div>
        </div>
    </div>
</template>

<script>
import mainone from '@/components/Mainone'
import maintwo from '@/components/Maintwo'
import axios from 'axios'
import { defineComponent, ref } from 'vue'
import commTable from '../../tags/tagslist/components/commTable.vue'
import { ElMessage } from 'element-plus'

export default defineComponent({
    name: 'vsharedvceopenclose',
    data() {
        return {
            mainonebread: [
                {
                    name: '打印'
                },
                {
                    name: '共享打印'
                },
                {
                    name: '设备开关'
                }
            ], // 当前页-【面包屑导航】
            maintwoiconshow: false, // 筛选-【高级筛选按钮】
            maintwoforminfo: {
                myinputoneisshow: true, //筛选查询-左侧字+input
                myinputone: {
                    divinterval: '0', //框右侧距离
                    titlewidth: '74px', //标题宽度
                    inputwidth: '200px', //input宽度
                    inputinterval: '20px', //input右侧距离
                    input: [
                        {
                            ismust: false, //是否必填，true表示必填
                            title: '设备编号：',
                            placeholder: '请输入设备编号',
                            name: 'name',
                            content: ''
                        }
                    ]
                },
                selectleftisshow: true, //筛选查询-左侧字+select单框||select时间
                selectleft: {
                    width: 'none',
                    divinterval: '20px', //框右侧距离
                    selectwidth: '200px',
                    selectinterval: '', //select右侧距离
                    select: [
                        {
                            selecttimeduanisshow: true, //该字段表示时间段模块状态：且已写死（字段名不可修改!）
                            ismust: false,
                            title: '投放时间：',
                            placeholder: {
                                placeholderstart: '请选择开始时间',
                                placeholderend: '请选择结束时间'
                            },
                            name: 'putintimez', // 【临时任务-20230605-linshuai】资金方使用 putintimez字段表示时间
                            value: {
                                valuestart: ref(''),
                                valueend: ref(''),
                                endstatus: true
                            },
                            rangeseparator: '至', //分隔符
                            divinterval: '3%', //上方字中-框右侧距离
                            selectwidth: '100%', //上方字中-总框长度
                            selectmaxwidth: '97%', //时间段-最大宽度
                            marginright: '0' //与右侧距离
                        },
                        {
                            selectisshow: true,
                            ismust: false,
                            title: '设备状态：',
                            placeholder: '全部',
                            name: 'on_state',
                            value: '',
                            options: [
                                {
                                    value: '',
                                    label: '全部'
                                },
                                {
                                    value: '1',
                                    label: '开启'
                                },
                                {
                                    value: '-1',
                                    label: '关闭'
                                }
                            ]
                        }
                    ]
                }
            }, // 筛选-【筛选查询】-控件信息
            mainthreeisshowbut: false, // 数据列表中-【添加按钮】
            mainthreeisshowset: false, // 数据列表中-复选框
            mainthreeisshowope: false, // 数据列表中-操作-根据页面显示模块（整页）
            mainthreetableope: 1, // 数据列表中-操作-根据页面显示控件（整页）
            mainthreehandle: 150, // 数据列表中-操作-宽度
            mainthreetabletitle: [
                {
                    fixedstatu: 'left',
                    prop: 'client',
                    label: '设备编号',
                    width: '100'
                },
                {
                    fixedstatu: false,
                    prop: 'shop_code_end',
                    label: '代理商家',
                    width: '200'
                },
                {
                    fixedstatu: false,
                    prop: 'shop_user_end',
                    label: '手机号',
                    width: '160'
                },
                {
                    fixedstatu: false,
                    prop: 'prov',
                    label: '省级',
                    width: ''
                },
                {
                    fixedstatu: false,
                    prop: 'city',
                    label: '市级',
                    width: ''
                },
                {
                    fixedstatu: false,
                    prop: 'area',
                    label: '县/区级',
                    width: ''
                },
                {
                    fixedstatu: false,
                    prop: 'addressx',
                    label: '详细地址',
                    width: ''
                },
                {
                    fixedstatu: false,
                    prop: 'putintimezStr',
                    label: '投放时间',
                    width: '200'
                },
                {
                    fixedstatu: false,
                    prop: 'on_state_name',
                    label: '设备状态',
                    width: '120'
                }
            ], // 数据列表中-数据表头
            mainthreestatus: true, // 数据列表中-属于search按钮表示状态
            mainthreetableData: [], // 数据列表中-具体数据
            totalCount: 1, //数据列表中-数据总条数
            lastpage: 1, //数据列表中-数据总页数（默认以20条一页）

            //table表单相关数据
            currentPage: 1,
            pageSizes: [20, 50, 100],
            PageSize: 20,
            multipleSelection: [],
            thispagename: '', //通过路由获取当前页面的path值

            //弹框
            dialogVisible: false,
            tan_title: '',
            searchwidth: '580px',
            seniorform: {
                id: [],
                mobile: JSON.parse(localStorage.getItem('userinfo')).mobile,
                // mobile: '18209092833',
                code: '',
                on_state: ''
            },
            rules: {
                code: [
                    {
                        required: true,
                        message: '验证码不能为空',
                        trigger: 'blur'
                    }
                ]
            },

            code_btn: '获取验证码',
            is_disabled: false,
            disabled_tan_btn: false,
            timer: [],

            mypowerlimits: JSON.parse(localStorage.getItem('powerlimits')), //获取权限
            mytoken: localStorage.getItem('token'), //获取存储的token
            myuserinfo: JSON.parse(localStorage.getItem('userinfo')), //当前用户基本信息
            pagesdatas: [], //存储最近一次的筛选条件，供分页使用

            loading: false
        }
    },
    mounted() {
        //获取当前页面路由path值
        this.thispagename = this.$route.path
    },
    methods: {
        // 弹窗
        tanEvent(flag, has_multipleSelection) {
            if (!this.seniorform.mobile || this.seniorform.mobile == '') {
                this.dialogVisible = false
                ElMessage({
                    type: 'error',
                    message: '手机号为空，无法获取验证码，请先联系管理员补充手机号！',
                    duration: 3000
                })
            } else {
                this.dialogVisible = true

                this.seniorform.id = has_multipleSelection.map((n) => {
                    return n.id
                })
                this.seniorform.on_state = flag
            }
        },
        // 弹窗-关闭
        handleCloseSenior() {
            this.dialogVisible = false
            this.$refs['seniorform'].resetFields()
        },
        // 弹窗-获取验证码
        getIdeCodeEvent(data) {
            // 倒计时
            this.is_disabled = true
            let second = 60
            this.timer = setInterval(() => {
                second--
                this.code_btn = second + '秒'
                if (second <= 0) {
                    this.code_btn = '重新获取'
                    this.is_disabled = false
                    clearInterval(this.timer)
                }
            }, 1000)

            axios
                .post(
                    '/manage/Equipment/sendSms',
                    {
                        mobile: data
                    },
                    {
                        headers: {
                            Auth: localStorage.getItem('token')
                        }
                    }
                )
                .then((response) => {
                    console.log(response.data.message)
                    if (response.data.code == 0) {
                        ElMessage({
                            type: 'success',
                            message: '验证码已发送，请注意查收',
                            duration: 1000
                        })
                    } else {
                        this.code_btn = '重新获取'
                        this.is_disabled = false
                        clearInterval(this.timer)
                    }
                })
                .catch(function(error) {
                    // 请求失败处理
                    console.log(error)

                    this.code_btn = '重新获取'
                    this.is_disabled = false
                    clearInterval(this.timer)
                })
        },
        // 弹窗-提交
        onSubmitSenior(formName) {
            let getdatas = this.seniorform
            this.$refs[formName].validate((valid) => {
                if (valid) {
                    this.disabled_tan_btn = true

                    axios
                        .post(
                            '/manage/Equipment/onState',
                            {
                                id: getdatas.id,
                                on_state: getdatas.on_state,
                                code: getdatas.code
                            },
                            {
                                headers: {
                                    Auth: localStorage.getItem('token')
                                }
                            }
                        )
                        .then((response) => {
                            if (response.data.code === 0) {
                                ElMessage({
                                    type: 'success',
                                    message: response.data.message,
                                    duration: 1000,
                                    onClose: () => {
                                        this.code_btn = '获取验证码'
                                        this.is_disabled = false
                                        clearInterval(this.timer)

                                        this.disabled_tan_btn = false
                                        this.handleCurrentChange(this.currentPage)
                                        this.handleCloseSenior()
                                    }
                                })
                            } else {
                                this.disabled_tan_btn = false
                            }
                        })
                        .catch(function(error) {
                            console.log(error)
                            this.disabled_tan_btn = false
                        })
                } else {
                    console.log('error submit!!')
                    return false
                }
            })
        },

        // **************多选**************
        // 深拷贝
        deepcopy(obj, cache = []) {
            function find(list, f) {
                return list.filter(f)[0]
            }

            // just return if obj is immutable value
            if (obj === null || typeof obj !== 'object') {
                return obj
            }

            // if obj is hit, it is in circular structure
            const hit = find(cache, (c) => c.original === obj)
            if (hit) {
                return hit.copy
            }

            const copy = Array.isArray(obj) ? [] : {}
            // put the copy into cache at first
            // because we want to refer it in recursive deepCopy
            cache.push({
                original: obj,
                copy
            })

            Object.keys(obj).forEach((key) => {
                copy[key] = this.deepcopy(obj[key], cache)
            })

            return copy
        },
        // 去重
        reduce(datas) {
            let obj = {}
            let reduce = []
            reduce = datas.reduce(function(item, next) {
                //item为没有重复id的数组，next为当前对象
                obj[next.id] ? '' : (obj[next.id] = true && item.push(next))
                return item
            }, [])

            return reduce
        },
        // 多选数据-全选
        handleSelectionEvent(datas) {
            var all_datas = []
            let { data, rowdatas } = datas
            if (data.length > 0) {
                // 全选
                all_datas = [...this.multipleSelection, ...this.deepcopy(data)]
            } else {
                // 取消全选
                let ids = rowdatas.map((n) => {
                    return n.id
                })
                this.multipleSelection = this.multipleSelection.filter((n) => ids.indexOf(n.id) == -1)
                all_datas = [...this.multipleSelection]
            }
            // 去重
            this.multipleSelection = this.reduce(all_datas)
        },
        // 多选数据-单选
        handleSelectionChangeOnline({ datas, row }) {
            this.multipleSelection = this.multipleSelection.filter((n) => n.id != row.id)
            this.handleSelectionEvent({ data: datas, rowdatas: this.mainthreetableData })
        },
        // 多选-检查反显
        moreDataBackShow(datas) {
            // console.log(this.$refs.mymultipleTableRef)
            if (this.$refs.mymultipleTableRef) {
                // console.log('fanxian:', datas)
                this.$refs.mymultipleTableRef.backShow(datas, this.mainthreetableData)
            }
        },
        // **************************************************

        //分页器中-当选择条数时触发该函数
        handleSizeChange(val) {
            // console.log(`每页 ${val} 条`)
            this.PageSize = val
            this.currentPage = 1

            this.pagesdatas = {
                name: this.pagesdatas.name,
                putintimez: this.pagesdatas.putintimez,
                on_state: this.pagesdatas.on_state,
                page: 1,
                page_size: val
            }

            this.postAxiosEvent(this.pagesdatas)
        },
        //分页器中-当选择分页时触发该函数
        handleCurrentChange(val) {
            // console.log(`当前页: 第${val}页`)
            this.currentPage = val

            this.pagesdatas = {
                name: this.pagesdatas.name,
                putintimez: this.pagesdatas.putintimez,
                on_state: this.pagesdatas.on_state,
                page: val,
                page_size: this.PageSize
            }

            this.postAxiosEvent(this.pagesdatas)
        },
        //点击按钮-弹出弹框并赋值弹窗基本配置
        addTanClick(taninfo) {
            console.log(taninfo)
            this.tancontent.content = taninfo.content
            this.tancontent.title = taninfo.title
        },
        //点击进行路由页面跳转以及携带参数
        routeraddrClick(routeraddr, rowdata) {
            if (rowdata) {
                // 携带参数跳转
                if (routeraddr == '/sharedvccontrol') {
                    this.$router
                        .push({
                            path: routeraddr,
                            query: { client: rowdata.client }
                        })
                        .catch((error) => error)
                } else {
                    this.$router
                        .push({
                            path: routeraddr,
                            query: { id: rowdata.id }
                        })
                        .catch((error) => error)
                }
            } else {
                //仅跳转
                this.$router.push(routeraddr).catch((error) => error)
            }
        },
        //导出数据
        exportDatas() {
            axios
                .get('/manage/Equipment/equipmentList', {
                    headers: {
                        Auth: localStorage.getItem('token')
                    },
                    params: {
                        name: this.pagesdatas.name,
                        putintimez: this.pagesdatas.putintimez,
                        on_state: this.pagesdatas.on_state,
                        export: 10
                    }
                })
                .then((response) => {
                    console.log(response.data.message)
                })
                .catch(function(error) {
                    console.log(error)
                })
        },

        //获取当前windows页面信息-实时修改【弹窗】的宽度
        getwindowinfo() {
            this.sreenheight = document.documentElement.clientHeight
            this.sreenwidth = document.documentElement.clientWidth
            if (this.sreenwidth < 600) {
                this.searchwidth = this.sreenwidth * 0.8 + 'px'
            } else {
                this.searchwidth = '580px'
            }
        },

        //获取Container-Pagedata-Maintwo-搜索-返回的数据
        getSeachinfoEvent(data) {
            // console.log('点击查询获取的data数组:', data)
            //判断-投放-时间段状态
            var getputintimez = null
            if (data.putintimez.start == '') {
                getputintimez = ''
            } else {
                if (data.putintimez.end == '') {
                    getputintimez = data.putintimez.start + ' ~ ' + (data.putintimez.start + 86400 - 1)
                } else {
                    getputintimez = data.putintimez.start + ' ~ ' + (data.putintimez.end + 86400 - 1)
                }
            }
            this.pagesdatas.putintimez = getputintimez

            this.pagesdatas = {
                name: data.name,
                putintimez: getputintimez,
                on_state: data.on_state,
                page: 1,
                page_size: this.PageSize
            }

            this.postAxiosEvent(this.pagesdatas)
        },

        // 请求接口函数
        postAxiosEvent(datas) {
            this.loading = true

            axios
                .get('/manage/Equipment/equipmentList', {
                    headers: {
                        Auth: localStorage.getItem('token')
                    },
                    params: datas
                })
                .then((response) => {
                    this.myaxiosfunc(response.data.result)
                })
                .catch(function(error) {
                    console.log(error)
                })
        },
        //数据渲染
        myaxiosfunc(successdata) {
            var newdata = successdata.data
            // console.log('当前页面的data:' + JSON.stringify(newdata))
            this.mainthreetableData = newdata
            this.totalCount = successdata.total
            this.currentpage = successdata.current_page //当前页面页数
            this.lastpage = successdata.last_page
            this.loading = false

            this.moreDataBackShow(this.multipleSelection)
        }
    },
    created() {
        if (localStorage.getItem('token') === null || !localStorage.getItem('token')) {
            this.isshow = false
            this.$router.push('/').catch((error) => error)
        } else {
            window.addEventListener('resize', this.getwindowinfo) // 注册监听器-实时检测浏览器页面宽高
            this.getwindowinfo()

            this.pagesdatas = {
                page: 1,
                page_size: this.PageSize
            }

            this.postAxiosEvent(this.pagesdatas)
        }
    },
    components: {
        mainone,
        maintwo,
        commTable
    }
})
</script>
<style scoped>
.mytabletext {
    color: var(--el-color-primary);
    cursor: pointer;
}

.tan-box {
    max-height: 450px;
    overflow: hidden;
    overflow-y: visible;
    padding: 0 3%;
    margin: 20px 0;
}

.code-box {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    flex-wrap: nowrap;
    gap: 10px;
}
</style>
