<template>
  <!--本页流程使用说明：-->
  <!--1、在template中添加以子页面命名的过度组件，eg、usertest文件，引用组件的文件名也为usertest,便于后期维护！！！-->
  <!--2、在setup中，添加组件判断-->
  <!--3、对应的getData.myisshow为该组件的显示条件-->
  <!--4、getspecialinfo为子页面传回的数据，并通过getSpecialinfoEvent函数接收！-->
  <div>
    <vsharedvccontrol v-if="getData.myisshow === 2" :mainpagedata="mainpagedata" v-on:getspecialinfo="getSpecialinfoEvent"></vsharedvccontrol> 
    <vresourcesclassifyedit v-if="getData.myisshow === 27" :mainpagedata="mainpagedata" v-on:getspecialinfo="getSpecialinfoEvent"></vresourcesclassifyedit>
    <vsharedvccontrolseelog v-if="getData.myisshow === 217" :mainpagedata="mainpagedata" v-on:getspecialinfo="getSpecialinfoEvent"></vsharedvccontrolseelog>
    <vresourcesglobaledit v-if="getData.myisshow === 25" :mainpagedata="mainpagedata" v-on:getspecialinfo="getSpecialinfoEvent"></vresourcesglobaledit>
    <vdvcisonline v-if="getData.myisshow === 218" :mainpagedata="mainpagedata" v-on:getspecialinfo="getSpecialinfoEvent"></vdvcisonline> 
    <vcompanylist v-if="getData.myisshow === 105" :mainpagedata="mainpagedata" v-on:getspecialinfo="getSpecialinfoEvent"></vcompanylist>
    <vsharedvcmap v-if="getData.myisshow === 113" :mainpagedata="mainpagedata" v-on:getspecialinfo="getSpecialinfoEvent"></vsharedvcmap>
    <vsharedvcexfactory v-if="getData.myisshow === 114" :mainpagedata="mainpagedata" v-on:getspecialinfo="getSpecialinfoEvent"></vsharedvcexfactory>
    <vsharedvcback v-if="getData.myisshow === 115" :mainpagedata="mainpagedata" v-on:getspecialinfo="getSpecialinfoEvent"></vsharedvcback>
    <vsharedvcinstall v-if="getData.myisshow === 116" :mainpagedata="mainpagedata" v-on:getspecialinfo="getSpecialinfoEvent"></vsharedvcinstall>
    <vphotoid v-if="getData.myisshow === 117" :mainpagedata="mainpagedata" v-on:getspecialinfo="getSpecialinfoEvent"></vphotoid>
    <vresourcesglobal v-if="getData.myisshow === 118" :mainpagedata="mainpagedata" v-on:getspecialinfo="getSpecialinfoEvent"></vresourcesglobal>
    <vresourcesclassify v-if="getData.myisshow === 119" :mainpagedata="mainpagedata" v-on:getspecialinfo="getSpecialinfoEvent"></vresourcesclassify>
    <vprintresources v-if="getData.myisshow === 120" :mainpagedata="mainpagedata" v-on:getspecialinfo="getSpecialinfoEvent"></vprintresources>
    <vprintreSourcesMarklist v-if="getData.myisshow === 210" :mainpagedata="mainpagedata" v-on:getspecialinfo="getSpecialinfoEvent"></vprintreSourcesMarklist>
    <vsharedvccontrolfrp v-if="getData.myisshow === 211" :mainpagedata="mainpagedata" v-on:getspecialinfo="getSpecialinfoEvent"></vsharedvccontrolfrp>
    <vaccountset v-if="getData.myisshow === 212" :mainpagedata="mainpagedata" v-on:getspecialinfo="getSpecialinfoEvent"></vaccountset>
    <vflowermanlist v-if="getData.myisshow === 214" :mainpagedata="mainpagedata" v-on:getspecialinfo="getSpecialinfoEvent"></vflowermanlist>
    <vrecposition v-if="getData.myisshow === 3" :mainpagedata="mainpagedata" v-on:getspecialinfo="getSpecialinfoEvent"></vrecposition>
    <vrecpositionadd v-if="getData.myisshow === 215" :mainpagedata="mainpagedata" v-on:getspecialinfo="getSpecialinfoEvent"></vrecpositionadd>
    <vrecpositionedit v-if="getData.myisshow === 216" :mainpagedata="mainpagedata" v-on:getspecialinfo="getSpecialinfoEvent"></vrecpositionedit>
    <vrecpositionaddspecial v-if="getData.myisshow === 219" :mainpagedata="mainpagedata" v-on:getspecialinfo="getSpecialinfoEvent"></vrecpositionaddspecial>
    <vpersonalinfo v-if="getData.myisshow === 220" :mainpagedata="mainpagedata" v-on:getspecialinfo="getSpecialinfoEvent"></vpersonalinfo>
    <vhome v-if="getData.myisshow === 221" :mainpagedata="mainpagedata" v-on:getspecialinfo="getSpecialinfoEvent"></vhome>
    <vmenuadd v-if="getData.myisshow === 222" :mainpagedata="mainpagedata" v-on:getspecialinfo="getSpecialinfoEvent"></vmenuadd>
    <vtoolsedit v-if="getData.myisshow === 223" :mainpagedata="mainpagedata" v-on:getspecialinfo="getSpecialinfoEvent"></vtoolsedit>
    <vhomeadd v-if="getData.myisshow === 224" :mainpagedata="mainpagedata" v-on:getspecialinfo="getSpecialinfoEvent"></vhomeadd>
    <vmenuedit v-if="getData.myisshow === 225" :mainpagedata="mainpagedata" v-on:getspecialinfo="getSpecialinfoEvent"></vmenuedit>
    <vhomeedit v-if="getData.myisshow === 226" :mainpagedata="mainpagedata" v-on:getspecialinfo="getSpecialinfoEvent"></vhomeedit>
    <vcompanymoneydetails v-if="getData.myisshow === 227" :mainpagedata="mainpagedata" v-on:getspecialinfo="getSpecialinfoEvent"></vcompanymoneydetails>
    <vsetphotoexamine v-if="getData.myisshow === 228" :mainpagedata="mainpagedata" v-on:getspecialinfo="getSpecialinfoEvent"></vsetphotoexamine>
    <vcompanypowerset v-if="getData.myisshow === 229" :mainpagedata="mainpagedata" v-on:getspecialinfo="getSpecialinfoEvent"></vcompanypowerset>
    <vsharedvcexfactoryZijinfang v-if="getData.myisshow === 230" :mainpagedata="mainpagedata" v-on:getspecialinfo="getSpecialinfoEvent"></vsharedvcexfactoryZijinfang>
	<vsharedvcexfactoryZijinfangols v-if="getData.myisshow === 237" :mainpagedata="mainpagedata" v-on:getspecialinfo="getSpecialinfoEvent"></vsharedvcexfactoryZijinfangols>
    <vyingbipresent v-if="getData.myisshow === 231" :mainpagedata="mainpagedata" v-on:getspecialinfo="getSpecialinfoEvent"></vyingbipresent>
    <vyingbipresentadd v-if="getData.myisshow === 232" :mainpagedata="mainpagedata" v-on:getspecialinfo="getSpecialinfoEvent"></vyingbipresentadd>
    <vyingbipresentinfo v-if="getData.myisshow === 233" :mainpagedata="mainpagedata" v-on:getspecialinfo="getSpecialinfoEvent"></vyingbipresentinfo>
    <vtagsgroup v-if="getData.myisshow === 234" :mainpagedata="mainpagedata" v-on:getspecialinfo="getSpecialinfoEvent"></vtagsgroup>
    <vtagslist v-if="getData.myisshow === 235" :mainpagedata="mainpagedata" v-on:getspecialinfo="getSpecialinfoEvent"></vtagslist>
    <vsharedvceopenclose v-if="getData.myisshow === 236" :mainpagedata="mainpagedata" v-on:getspecialinfo="getSpecialinfoEvent"></vsharedvceopenclose>
    
    <!-- 仓库-出入库管理相关页面 -->
    <vgoinmonad v-if="getData.myisshow === 239" :mainpagedata="mainpagedata" v-on:getspecialinfo="getSpecialinfoEvent"></vgoinmonad>
    <vgoinmonadinfo v-if="getData.myisshow === 240" :mainpagedata="mainpagedata" v-on:getspecialinfo="getSpecialinfoEvent"></vgoinmonadinfo>
    <vgooutmonad v-if="getData.myisshow === 241" :mainpagedata="mainpagedata" v-on:getspecialinfo="getSpecialinfoEvent"></vgooutmonad>
    <vgooutmonadinfo v-if="getData.myisshow === 242" :mainpagedata="mainpagedata" v-on:getspecialinfo="getSpecialinfoEvent"></vgooutmonadinfo>
    <vgooutreportmonad v-if="getData.myisshow === 243" :mainpagedata="mainpagedata" v-on:getspecialinfo="getSpecialinfoEvent"></vgooutreportmonad>
    <vgooutreportmonadinfo v-if="getData.myisshow === 244" :mainpagedata="mainpagedata" v-on:getspecialinfo="getSpecialinfoEvent"></vgooutreportmonadinfo>
    <vreservenums v-if="getData.myisshow === 245" :mainpagedata="mainpagedata" v-on:getspecialinfo="getSpecialinfoEvent"></vreservenums>
    <!-- 仓库-基础配置相关页面 -->
    <vgodown v-if="getData.myisshow === 246" :mainpagedata="mainpagedata" v-on:getspecialinfo="getSpecialinfoEvent"></vgodown>
    <vgodownedit v-if="getData.myisshow === 247" :mainpagedata="mainpagedata" v-on:getspecialinfo="getSpecialinfoEvent"></vgodownedit>
    <vgodownadd v-if="getData.myisshow === 248" :mainpagedata="mainpagedata" v-on:getspecialinfo="getSpecialinfoEvent"></vgodownadd>
    <vgointype v-if="getData.myisshow === 249" :mainpagedata="mainpagedata" v-on:getspecialinfo="getSpecialinfoEvent"></vgointype>
    <vgointypeadd v-if="getData.myisshow === 250" :mainpagedata="mainpagedata" v-on:getspecialinfo="getSpecialinfoEvent"></vgointypeadd>
    <vgoouttype v-if="getData.myisshow === 251" :mainpagedata="mainpagedata" v-on:getspecialinfo="getSpecialinfoEvent"></vgoouttype>
    <vgoouttypeadd v-if="getData.myisshow === 252" :mainpagedata="mainpagedata" v-on:getspecialinfo="getSpecialinfoEvent"></vgoouttypeadd>
    <vproductclass v-if="getData.myisshow === 253" :mainpagedata="mainpagedata" v-on:getspecialinfo="getSpecialinfoEvent"></vproductclass>
    <vproductmanage v-if="getData.myisshow === 254" :mainpagedata="mainpagedata" v-on:getspecialinfo="getSpecialinfoEvent"></vproductmanage>
    <vproductmanageadd v-if="getData.myisshow === 255" :mainpagedata="mainpagedata" v-on:getspecialinfo="getSpecialinfoEvent"></vproductmanageadd>
    <vproductmanageedit v-if="getData.myisshow === 256" :mainpagedata="mainpagedata" v-on:getspecialinfo="getSpecialinfoEvent"></vproductmanageedit>
    <vsupplier v-if="getData.myisshow === 257" :mainpagedata="mainpagedata" v-on:getspecialinfo="getSpecialinfoEvent"></vsupplier>
    <vsupplieredit v-if="getData.myisshow === 258" :mainpagedata="mainpagedata" v-on:getspecialinfo="getSpecialinfoEvent"></vsupplieredit>
    <vsupplieradd v-if="getData.myisshow === 259" :mainpagedata="mainpagedata" v-on:getspecialinfo="getSpecialinfoEvent"></vsupplieradd>
    <vunitnums v-if="getData.myisshow === 260" :mainpagedata="mainpagedata" v-on:getspecialinfo="getSpecialinfoEvent"></vunitnums>
    <vunitnumsadd v-if="getData.myisshow === 261" :mainpagedata="mainpagedata" v-on:getspecialinfo="getSpecialinfoEvent"></vunitnumsadd>
    <!-- 仓库-出入库管理-添加入库单等 -->
    <vphoneloginempower v-if="getData.myisshow === 262" :mainpagedata="mainpagedata" v-on:getspecialinfo="getSpecialinfoEvent"></vphoneloginempower>
    <vdvcgoinoutlog v-if="getData.myisshow === 263" :mainpagedata="mainpagedata" v-on:getspecialinfo="getSpecialinfoEvent"></vdvcgoinoutlog>
    <vdvclist v-if="getData.myisshow === 264" :mainpagedata="mainpagedata" v-on:getspecialinfo="getSpecialinfoEvent"></vdvclist>
    <vgooutreceivemonad v-if="getData.myisshow === 265" :mainpagedata="mainpagedata" v-on:getspecialinfo="getSpecialinfoEvent"></vgooutreceivemonad>
    <vgooutreceivemonadinfo v-if="getData.myisshow === 266" :mainpagedata="mainpagedata" v-on:getspecialinfo="getSpecialinfoEvent"></vgooutreceivemonadinfo>
    <vgoinmonadadd v-if="getData.myisshow === 267" :mainpagedata="mainpagedata" v-on:getspecialinfo="getSpecialinfoEvent"></vgoinmonadadd>
    <vwarehousemanifest v-if="getData.myisshow === 280" :mainpagedata="mainpagedata" v-on:getspecialinfo="getSpecialinfoEvent"></vwarehousemanifest>
    <vexportmanifest v-if="getData.myisshow === 281" :mainpagedata="mainpagedata" v-on:getspecialinfo="getSpecialinfoEvent"></vexportmanifest>
    <vpresentstock v-if="getData.myisshow === 282" :mainpagedata="mainpagedata" v-on:getspecialinfo="getSpecialinfoEvent"></vpresentstock>
    <!-- 风控-风控管理 -->
    <vbatchmanage v-if="getData.myisshow === 268" :mainpagedata="mainpagedata" v-on:getspecialinfo="getSpecialinfoEvent"></vbatchmanage>
    <vbatchmanageadd v-if="getData.myisshow === 269" :mainpagedata="mainpagedata" v-on:getspecialinfo="getSpecialinfoEvent"></vbatchmanageadd>
    <vbatchmanagechack v-if="getData.myisshow === 270" :mainpagedata="mainpagedata" v-on:getspecialinfo="getSpecialinfoEvent"></vbatchmanagechack>
    <vbatchmanageedit v-if="getData.myisshow === 271" :mainpagedata="mainpagedata" v-on:getspecialinfo="getSpecialinfoEvent"></vbatchmanageedit>
    <vbatchmanageinfo v-if="getData.myisshow === 272" :mainpagedata="mainpagedata" v-on:getspecialinfo="getSpecialinfoEvent"></vbatchmanageinfo>
    <vbatchmanagefunder v-if="getData.myisshow === 273" :mainpagedata="mainpagedata" v-on:getspecialinfo="getSpecialinfoEvent"></vbatchmanagefunder>
    <vbatchmanagefunderinfo v-if="getData.myisshow === 274" :mainpagedata="mainpagedata" v-on:getspecialinfo="getSpecialinfoEvent"></vbatchmanagefunderinfo>
    <vbatchpayment v-if="getData.myisshow === 275" :mainpagedata="mainpagedata" v-on:getspecialinfo="getSpecialinfoEvent"></vbatchpayment>
    <vbatchpaymentfunder v-if="getData.myisshow === 276" :mainpagedata="mainpagedata" v-on:getspecialinfo="getSpecialinfoEvent"></vbatchpaymentfunder>
    <!-- 购物 -->
    <vdeviceBank  v-if="getData.myisshow === 277" :mainpagedata="mainpagedata" v-on:getspecialinfo="getSpecialinfoEvent"></vdeviceBank>
    <!-- 第三方租期数据 -->
    <vthirdBillList  v-if="getData.myisshow === 278" :mainpagedata="mainpagedata" v-on:getspecialinfo="getSpecialinfoEvent"></vthirdBillList>
    <vthirdOrderList  v-if="getData.myisshow === 279" :mainpagedata="mainpagedata" v-on:getspecialinfo="getSpecialinfoEvent"></vthirdOrderList>

    <vfileclassify v-if="getData.myisshow === 121" :mainpagedata="mainpagedata" v-on:getspecialinfo="getSpecialinfoEvent"></vfileclassify>
    <vlabelmanage v-if="getData.myisshow === 122" :mainpagedata="mainpagedata" v-on:getspecialinfo="getSpecialinfoEvent"></vlabelmanage>
    <vcurriculumclassify v-if="getData.myisshow === 123" :mainpagedata="mainpagedata" v-on:getspecialinfo="getSpecialinfoEvent"></vcurriculumclassify>
    <vcoursewareclassify v-if="getData.myisshow === 124" :mainpagedata="mainpagedata" v-on:getspecialinfo="getSpecialinfoEvent"></vcoursewareclassify>
    <vcoursewarelist v-if="getData.myisshow === 125" :mainpagedata="mainpagedata" v-on:getspecialinfo="getSpecialinfoEvent"></vcoursewarelist>
    <vcurriculumlist v-if="getData.myisshow === 126" :mainpagedata="mainpagedata" v-on:getspecialinfo="getSpecialinfoEvent"></vcurriculumlist>
    <vcurriculumorder v-if="getData.myisshow === 127" :mainpagedata="mainpagedata" v-on:getspecialinfo="getSpecialinfoEvent"></vcurriculumorder>
    <vdepartment v-if="getData.myisshow === 152" :mainpagedata="mainpagedata" v-on:getspecialinfo="getSpecialinfoEvent"></vdepartment>
    <vpower v-if="getData.myisshow === 153" :mainpagedata="mainpagedata" v-on:getspecialinfo="getSpecialinfoEvent"></vpower>
    <vuser v-if="getData.myisshow === 154" :mainpagedata="mainpagedata" v-on:getspecialinfo="getSpecialinfoEvent"></vuser>
    <vloginlog v-if="getData.myisshow === 156" :mainpagedata="mainpagedata" v-on:getspecialinfo="getSpecialinfoEvent"></vloginlog>
    <vexportlist v-if="getData.myisshow === 213" :mainpagedata="mainpagedata" v-on:getspecialinfo="getSpecialinfoEvent"></vexportlist>  
    <vcompanyadd v-if="getData.myisshow === 172" :mainpagedata="mainpagedata" v-on:getspecialinfo="getSpecialinfoEvent"></vcompanyadd>
    <vcompanyedit v-if="getData.myisshow === 174" :mainpagedata="mainpagedata" v-on:getspecialinfo="getSpecialinfoEvent"></vcompanyedit>
    <vphotoidadd v-if="getData.myisshow === 180" :mainpagedata="mainpagedata" v-on:getspecialinfo="getSpecialinfoEvent"></vphotoidadd>
    <vresourcesexamine v-if="getData.myisshow === 181" :mainpagedata="mainpagedata" v-on:getspecialinfo="getSpecialinfoEvent"></vresourcesexamine>
    <vresourcesedit v-if="getData.myisshow === 182" :mainpagedata="mainpagedata" v-on:getspecialinfo="getSpecialinfoEvent"></vresourcesedit>
    <vprintresourcesaddmore v-if="getData.myisshow === 183" :mainpagedata="mainpagedata" v-on:getspecialinfo="getSpecialinfoEvent"></vprintresourcesaddmore>
    <vfileclassifyaddlast v-if="getData.myisshow === 184" :mainpagedata="mainpagedata" v-on:getspecialinfo="getSpecialinfoEvent"></vfileclassifyaddlast>
    <vcompanyaliset v-if="getData.myisshow === 185" :mainpagedata="mainpagedata" v-on:getspecialinfo="getSpecialinfoEvent"></vcompanyaliset>
    <vcompanywxset v-if="getData.myisshow === 186" :mainpagedata="mainpagedata" v-on:getspecialinfo="getSpecialinfoEvent"></vcompanywxset>
    <!-- <voverview v-if="getData.myisshow === 202" :mainpagedata="mainpagedata" v-on:getspecialinfo="getSpecialinfoEvent"></voverview>  -->
    <vsharedvccontroledit v-if="getData.myisshow === 203" :mainpagedata="mainpagedata" v-on:getspecialinfo="getSpecialinfoEvent"></vsharedvccontroledit> 
    <vpowerset v-if="getData.myisshow === 208" :mainpagedata="mainpagedata" v-on:getspecialinfo="getSpecialinfoEvent"></vpowerset>
    <vphotoidedit v-if="getData.myisshow === 209" :mainpagedata="mainpagedata" v-on:getspecialinfo="getSpecialinfoEvent"></vphotoidedit> 
    <vcurriculumlistadd v-if="getData.myisshow === 5" :mainpagedata="mainpagedata" v-on:getsearchinfo="getSeachinfoEvent"></vcurriculumlistadd>
    <vcoursewarelistadd v-if="getData.myisshow === 6" :mainpagedata="mainpagedata" v-on:getsearchinfo="getSeachinfoEvent"></vcoursewarelistadd>
    <!-- <basic v-if="getData.myisshow === 18" :mainpagedata="mainpagedata" v-on:getsearchinfo="getSeachinfoEvent"></basic> -->
    <vresourcesclassifyadd v-if="getData.myisshow === 26" :mainpagedata="mainpagedata" v-on:getspecialinfo="getSpecialinfoEvent"></vresourcesclassifyadd>
    <vresourcesglobaladd v-if="getData.myisshow === 28" :mainpagedata="mainpagedata" v-on:getspecialinfo="getSpecialinfoEvent"></vresourcesglobaladd>
    <vuseradd v-if="getData.myisshow === 29" :mainpagedata="mainpagedata" v-on:getspecialinfo="getSpecialinfoEvent"></vuseradd>
    <vuseredit v-if="getData.myisshow === 30" :mainpagedata="mainpagedata" v-on:getspecialinfo="getSpecialinfoEvent"></vuseredit>
    <vprintresourcesadd v-if="getData.myisshow === 33" :mainpagedata="mainpagedata" v-on:getspecialinfo="getSpecialinfoEvent"></vprintresourcesadd>
    <vfileclassifyadd v-if="getData.myisshow === 34" :mainpagedata="mainpagedata" v-on:getspecialinfo="getSpecialinfoEvent"></vfileclassifyadd>
    <vfileclassifyedit v-if="getData.myisshow === 35" :mainpagedata="mainpagedata" v-on:getspecialinfo="getSpecialinfoEvent"></vfileclassifyedit>
    <vlabelmanageadd v-if="getData.myisshow === 36" :mainpagedata="mainpagedata" v-on:getspecialinfo="getSpecialinfoEvent"></vlabelmanageadd>
    <vlabelmanageedit v-if="getData.myisshow === 37" :mainpagedata="mainpagedata" v-on:getspecialinfo="getSpecialinfoEvent"></vlabelmanageedit>
    <vcurriculumclassifyadd v-if="getData.myisshow === 38" :mainpagedata="mainpagedata" v-on:getspecialinfo="getSpecialinfoEvent"></vcurriculumclassifyadd>
    <vcurriculumclassifyedit v-if="getData.myisshow === 39" :mainpagedata="mainpagedata" v-on:getspecialinfo="getSpecialinfoEvent"></vcurriculumclassifyedit>
    <vcoursewareclassifyadd v-if="getData.myisshow === 40" :mainpagedata="mainpagedata" v-on:getspecialinfo="getSpecialinfoEvent"></vcoursewareclassifyadd>
    <vcoursewareclassifyedit v-if="getData.myisshow === 41" :mainpagedata="mainpagedata" v-on:getspecialinfo="getSpecialinfoEvent"></vcoursewareclassifyedit>
    <vcoursewarelistedit v-if="getData.myisshow === 42" :mainpagedata="mainpagedata" v-on:getsearchinfo="getSeachinfoEvent"></vcoursewarelistedit>
    <vcurriculumlistedit v-if="getData.myisshow === 43" :mainpagedata="mainpagedata" v-on:getsearchinfo="getSeachinfoEvent"></vcurriculumlistedit>
    <vsharedvcmap2 v-if="getData.myisshow === 56" :mainpagedata="mainpagedata" v-on:getspecialinfo="getSpecialinfoEvent"></vsharedvcmap2>
  </div>
</template>

<script>
import {reactive} from "vue";
import vcompanylist from './collaborate/company/vcompanylist' // 合作伙伴-公司管理-公司列表
import vsharedvcmap from './print/sharedvc/sharedvcmap/vsharedvcmap' // 打印-共享打印-设备地图
import vsharedvccontrolfrp from './print/sharedvc/sharedvccontrol/vsharedvccontrolfrp' // 打印-共享打印-编辑设备-远程控制
import vsharedvcexfactory from './print/sharedvc/sharedvcexfactory/vsharedvcexfactory' // 打印-共享打印-设备出厂记录
import vsharedvcback from './print/sharedvc/sharedvcback/vsharedvcback' // 打印-共享打印-设备退货记录
import vsharedvcinstall from './print/sharedvc/sharedvcinstall/vsharedvcinstall' // 打印-共享打印-安装记录
import vphotoid from './print/photo/photoid/vphotoid' // 打印-证件照管理-证件照
import vresourcesglobal from './print/resources/resourcesglobal/vresourcesglobal' // 打印-资源管理-全球拍资源
import vresourcesclassify from './print/resources/resourcesclassify/vresourcesclassify' // 打印-资源管理-图片分类
import vprintresources from './education/files/printresources/vprintresources' // 教育-文档管理-打印资源库
import vprintreSourcesMarklist from './education/files/printresources/vprintreSourcesMarklist' // 教育-文档管理-打印资源库
import vfileclassify from './education/files/fileclassify/vfileclassify' // 教育-文档管理-文档分类
import vlabelmanage from './education/files/labelmanage/vlabelmanage' // 教育-文档管理-标签管理
import vcurriculumclassify from './education/curriculum/curriculumclassify/vcurriculumclassify' // 教育-课程管理-课程分类
import vcoursewareclassify from './education/curriculum/coursewareclassify/vcoursewareclassify' // 教育-课程管理-课件分类
import vcoursewarelist from './education/curriculum/coursewarelist/vcoursewarelist' // 教育-课程管理-课件列表
import vcurriculumlist from './education/curriculum/curriculumlist/vcurriculumlist' // 教育-课程管理-课程列表
import vcurriculumorder from './education/curriculum/curriculumlist/vcurriculumorder' // 教育-课程管理-课程列表-课程订单列表
import vdepartment from './power/power/department/vdepartment' // 权限-权限管理-部门管理
import vpower from './power/power/power/vpower' // 权限-权限管理-权限管理
import vuser from './power/power/user/vuser' // 权限-权限管理-用户管理
import vaccountset from './power/power/accountset/vaccountset' // 权限-权限管理-账户设置
import vloginlog from './power/menu/loginlog/vloginlog' // 权限-菜单-登录日志
import vexportlist from './power/menu/exportlist/vexportlist' // 权限-菜单-导出管理-我的导出
import vcompanyadd from './collaborate/company/vcompanyadd' // 合作伙伴-公司管理-公司列表-添加公司
import vcompanyedit from './collaborate/company/vcompanyedit' // 合作伙伴-公司管理-公司列表-编辑公司
import vcompanypowerset from './collaborate/company/vcompanypowerset' // 合作伙伴-公司管理-公司列表-编辑公司
import vphotoidadd from './print/photo/photoid/vphotoidadd' //设置-会员设置-设备会员系统默认值
import vresourcesexamine from './education/files/printresources/vresourcesexamine' //教育-文档管理-打印资源库-审核文档
import vresourcesedit from './education/files/printresources/vresourcesedit' //教育-文档管理-打印资源库-编辑文档
import vprintresourcesaddmore from './education/files/printresources/vprintresourcesaddmore' //教育-文档管理-打印资源库-编辑文档
import vfileclassifyaddlast from './education/files/fileclassify/vfileclassifyaddlast' //教育-文档管理-文档分类-新增下级文档
import vcompanyaliset from './collaborate/company/vcompany_aliset' 
import vcompanywxset from './collaborate/company/vcompany_wxset' 
import vsharedvccontrol from "./print/sharedvc/sharedvccontrol/vsharedvccontrol";  //打印-共享打印-机器控制
import vsharedvccontroledit from "./print/sharedvc/sharedvccontrol/vsharedvccontroledit";  //打印-共享打印-机器控制-编辑
import vsharedvccontrolseelog from './print/sharedvc/sharedvccontrol/vsharedvccontrolseelog' // //打印-共享打印-机器控制-查看日志
import vdvcisonline from './print/sharedvc/sharedvccontrol/vdvcisonline' // //打印-共享打印-机器控制-在线时长
import vresourcesglobaledit from './print/resources/resourcesglobal/vresourcesglobaledit' // //打印-共享打印-机器控制-在线时长
import vresourcesclassifyedit from './print/resources/resourcesclassify/vresourcesclassifyedit' //打印-资源管理-分类列表-编辑分类
import vpowerset from "./power/power/power/vpowerset"; //权限设置
import vphotoidedit from './print/photo/photoid/vphotoidedit' //设置-会员设置-设备会员系统默认值
import vcurriculumlistadd from './education/curriculum/curriculumlist/vcurriculumlistadd'; // 教育-课程管理-课程列表-添加课程
import vcoursewarelistadd from "./education/curriculum/coursewarelist/vcoursewarelistadd";// 教育-课程管理-课件列表-添加课件
import vresourcesglobaladd from './print/resources/resourcesglobal/vresourcesglobaladd' //打印-资源管理-全球拍资源-添加图片
import vresourcesclassifyadd from './print/resources/resourcesclassify/vresourcesclassifyadd' //打印-资源管理-图片分类-添加分类
import vuseradd from './power/power/user/vuseradd.vue' //权限-权限管理-用户管理-添加员工
import vuseredit from './power/power/user/vuseredit.vue' //权限-权限管理-用户管理-编辑员工
import vprintresourcesadd from './education/files/printresources/vprintresourcesadd' //教育-文档管理-打印资源库-添加文档
import vfileclassifyadd from './education/files/fileclassify/vfileclassifyadd' //教育-文档管理-文档分类-添加分类
import vfileclassifyedit from './education/files/fileclassify/vfileclassifyedit' //教育-文档管理-文档分类-编辑分类
import vlabelmanageadd from './education/files/labelmanage/vlabelmanageadd.vue'; //教育-文档管理-标签管理-添加标签
import vlabelmanageedit from './education/files/labelmanage/vlabelmanageedit.vue'; //教育-文档管理-标签管理-编辑标签
import vcurriculumclassifyadd from './education/curriculum/curriculumclassify/vcurriculumclassifyadd' // 教育-课程管理-课程分类-添加分类
import vcurriculumclassifyedit from './education/curriculum/curriculumclassify/vcurriculumclassifyedit' // 教育-课程管理-课程分类-编辑分类
import vcoursewareclassifyadd from './education/curriculum/coursewareclassify/vcoursewareclassifyadd' // 教育-课程管理-课件分类-添加分类
import vcoursewareclassifyedit from './education/curriculum/coursewareclassify/vcoursewareclassifyedit' // 教育-课程管理-课件分类-编辑分类
import vcoursewarelistedit from "./education/curriculum/coursewarelist/vcoursewarelistedit";// 教育-课程管理-课件列表-编辑课件
import vcurriculumlistedit from "./education/curriculum/curriculumlist/vcurriculumlistedit"; //教育-课程管理-课程列表-编辑课程
import vsharedvcmap2 from './print/sharedvc/sharedvcmap/vsharedvcmap2' // 打印-共享打印-设备地图
import vrecposition from './education/files/recposition/vrecposition' // 教育-文档管理-推荐位管理
import vrecpositionadd from './education/files/recposition/vrecpositionadd' // 教育-文档管理-推荐位管理-添加导航
import vrecpositionedit from './education/files/recposition/vrecpositionedit'; // 教育-文档管理-推荐位管理-编辑导航
import vrecpositionaddspecial from './education/files/recposition/vrecpositionaddspecial'; // 教育-文档管理-推荐位管理-添加专题
import vpersonalinfo from './set/shop/personalinfo/vpersonalinfo'; // 设置-店铺-个人中心
import vhome from './set/shop/home/vhome'; // 设置-店铺-首页导航
import vmenuadd from './set/shop/personalinfo/vmenuadd'; // 设置-店铺-个人中心-添加菜单
import vtoolsedit from './set/shop/personalinfo/vtoolsedit.vue'; // 设置-店铺-个人中心-编辑工具栏
import vhomeadd from './set/shop/home/vhomeadd.vue'; // 设置-店铺-首页导航-添加导航
import vmenuedit from './set/shop/personalinfo/vmenuedit'; // 设置-店铺-个人中心-添加导航
import vhomeedit from './set/shop/home/vhomeedit'; // 设置-店铺-首页导航-编辑导航
import vcompanymoneydetails from './collaborate/company/vcompanymoneydetails'; // 租户管理-公司列表-资金明细
import vflowermanlist from './flowerman/flowerman/vflowermanlist'; // 流量主-流量主管理-流量主列表
import vsetphotoexamine from './set/examine/setphotoexamine/vsetphotoexamine'; //设置-审核设置-设置小程序审核图文配置
import vsharedvcexfactoryZijinfang from './print/sharedvc/sharedvcexfactory/vsharedvcexfactoryZijinfang'; //打印-共享打印-设备出厂记录（资金方使用）
import vsharedvcexfactoryZijinfangols from './print/sharedvc/sharedvcexfactory/vsharedvcexfactoryZijinfangols'; 

import vyingbipresent from './operate/yingbi/vyingbipresent'; //运营-京币-活动送京币
import vyingbipresentadd from './operate/yingbi/vyingbipresentadd'; //运营-京币-活动送京币-添加
import vyingbipresentinfo from './operate/yingbi/vyingbipresentinfo'; //运营-京币-活动送京币-详情
import vtagsgroup from './print/tags/tagsgroup/vtagsgroup'; //打印-标签管理-标签组管理
import vtagslist from './print/tags/tagslist/vtagslist'; //打印-标签管理-标签管理
import vsharedvceopenclose from './print/sharedvc/sharedvceopenclose/vsharedvceopenclose'; //打印-设备开关

// 仓库-出入库管理相关页面
import vgoinmonad from './wmsystem/goinout/goinmonad/vgoinmonad'; //仓库-出入库管理-入库单
import vgoinmonadinfo from './wmsystem/goinout/goinmonad/vgoinmonadinfo'; //仓库-出入库管理-入库单-详情
import vgoinmonadadd from './wmsystem/goinout/goinmonad/vgoinmonadadd';
import vgooutmonad from './wmsystem/goinout/gooutmonad/vgooutmonad';
import vgooutmonadinfo from './wmsystem/goinout/gooutmonad/vgooutmonadinfo';
import vgooutreportmonad from './wmsystem/goinout/reportmonad/vgooutreportmonad';
import vgooutreportmonadinfo from './wmsystem/goinout/reportmonad/vgooutreportmonadinfo';
import vreservenums from './wmsystem/goinout/reservenums/vreservenums';
import vdvcgoinoutlog from './wmsystem/goinout/dvcgoinoutlog/vdvcgoinoutlog';
import vdvclist from './wmsystem/goinout/dvclist/vdvclist';
import vgooutreceivemonad from './wmsystem/goinout/receivemonad/vgooutreceivemonad';
import vgooutreceivemonadinfo from './wmsystem/goinout/receivemonad/vgooutreceivemonadinfo';
// 仓库-基础配置相关页面
import vgodown from './wmsystem/baseset/godown/vgodown';
import vgodownedit from './wmsystem/baseset/godown/vgodownedit';
import vgodownadd from './wmsystem/baseset/godown/vgodownadd';
import vgointype from './wmsystem/baseset/gointype/vgointype';
import vgointypeadd from './wmsystem/baseset/gointype/vgointypeadd';
import vgoouttype from './wmsystem/baseset/goouttype/vgoouttype';
import vgoouttypeadd from './wmsystem/baseset/goouttype/vgoouttypeadd';
import vproductclass from './wmsystem/baseset/productclass/vproductclass';
import vproductmanage from './wmsystem/baseset/productmanage/vproductmanage';
import vproductmanageadd from './wmsystem/baseset/productmanage/vproductmanageadd';
import vproductmanageedit from './wmsystem/baseset/productmanage/vproductmanageedit';
import vsupplier from './wmsystem/baseset/supplier/vsupplier';
import vsupplieredit from './wmsystem/baseset/supplier/vsupplieredit';
import vsupplieradd from './wmsystem/baseset/supplier/vsupplieradd';
import vunitnums from './wmsystem/baseset/unitnums/vunitnums';
import vunitnumsadd from './wmsystem/baseset/unitnums/vunitnumsadd';
import vphoneloginempower from './wmsystem/baseset/phoneloginempower/vphoneloginempower';
import vwarehousemanifest from './wmsystem/baseset/warehousemanifest/vwarehousemanifest';
import vexportmanifest from './wmsystem/baseset/exportmanifest/vexportmanifest';
import vpresentstock from './wmsystem/baseset/presentstock/vpresentstock';
// 风控-风控管理相关页面
import vbatchmanage from './riskcontrol/riskmanage/batchmanage/vbatchmanage';
import vbatchmanageadd from './riskcontrol/riskmanage/batchmanage/vbatchmanageadd';
import vbatchmanagechack from './riskcontrol/riskmanage/batchmanage/vbatchmanagechack';
import vbatchmanageedit from './riskcontrol/riskmanage/batchmanage/vbatchmanageedit';
import vbatchmanageinfo from './riskcontrol/riskmanage/batchmanage/vbatchmanageinfo';
import vbatchmanagefunder from './riskcontrol/riskmanage/batchmanagefunder/vbatchmanagefunder';
import vbatchmanagefunderinfo from './riskcontrol/riskmanage/batchmanagefunder/vbatchmanagefunderinfo';
import vbatchpayment from './riskcontrol/riskmanage/batchpayment/vbatchpayment';
import vbatchpaymentfunder from './riskcontrol/riskmanage/batchpaymentfunder/vbatchpaymentfunder';

// 购物
import vdeviceBank from './shop/deviceBank/vdeviceBank'
// 第三方租期数据
import vthirdBillList from './riskcontrol/thirdPartyTenancy/thirdBillList/vthirdBillList'
import vthirdOrderList from './riskcontrol/thirdPartyTenancy/thirdOrderList/vthirdOrderList'

export default {
  name: "special",
  props: ['mainpagedata'],
  setup(props) {
    const mainPagedata = reactive(props['mainpagedata']);

    //定义数据数组等
    let getData = reactive({
      myisshow: 0
    })
    //判断mycontrol是否定义
    if (mainPagedata.showpages.mycontrol) {
      //将mainPagedata.showpages.mycontrol的值赋予getData.myisshow控制页面走向
      getData.myisshow = mainPagedata.showpages.mycontrol
    }

    return {
      mainPagedata,
      getData
    }
  },
  methods: {
    getSpecialinfoEvent(data) { //获取XX特殊页面返回的数据
      console.log('获取XX特殊页面返回的数据:' + JSON.stringify(data))
      //将页数传向父组件
      this.$emit('getspecialinfo', data)
    },
    searchFormEvent(data) { //获取Maintwo-高级筛选-开始检索返回的数据
      console.log('高级筛选-开始检索返回数据(pagedata):' + JSON.stringify(data))
      //将数据传向父组件
      this.$emit('searchForm', data)
    },
    getSeachinfoEvent(data) { //获取Maintwo-搜索-返回的数据
      console.log('搜索-返回的数据:' + JSON.stringify(data))
      //将数据传向父组件
      this.$emit('getsearchinfo', data)
    },
    getPageinfoEvent(data) { //获取Mainthree-数据列表-分页选择的数据
      console.log('页面-分页选择的数据:' + JSON.stringify(data))
      //将页数传向父组件
      this.$emit('getpageinfo', data)
    },
    searchFormtanEvent(data) { //获取弹框-返回的数据
      console.log('special-获取单框提交的参数与数据等：' + JSON.stringify(data))
      //将数据传向父组件
      this.$emit('searchFormtan', data)
    }
  },
  components: {
    vcurriculumorder,
    vprintreSourcesMarklist,
    vrecposition,
    vrecpositionadd,
    vcompanylist,
    vsharedvccontrolseelog,
    vresourcesclassifyedit,
    vresourcesglobaledit,
    vdvcisonline,
    vsharedvccontrolfrp,
    vsharedvcmap,
    vsharedvcexfactory,
    vsharedvcback,
    vsharedvcinstall,
    vphotoid,
    vflowermanlist,
    vresourcesglobal,
    vresourcesclassify,
    vprintresources,
    vfileclassify,
    vlabelmanage,
    vcurriculumclassify,
    vcoursewareclassify,
    vcoursewarelist,
    vcurriculumlist,
    vcompanyaliset,
    vcompanywxset,
    vdepartment,
    vpower,
    vuser,
    vloginlog,
    vexportlist,
    vcompanyadd,
    vcompanyedit,
    vphotoidadd,
    vresourcesexamine,
    vresourcesedit,
    vprintresourcesaddmore,
    vfileclassifyaddlast,
    vsharedvccontrol,
    vsharedvccontroledit,
    vpowerset,
    vaccountset,
    vphotoidedit,
    vcurriculumlistadd,
    vcoursewarelistadd,
    vresourcesglobaladd,
    vresourcesclassifyadd,
    vuseradd,
    vuseredit,
    vprintresourcesadd,
    vfileclassifyadd,
    vfileclassifyedit,
    vlabelmanageadd,
    vlabelmanageedit,
    vcurriculumclassifyadd,
    vcurriculumclassifyedit,
    vcoursewareclassifyadd,
    vcoursewareclassifyedit,
    vcoursewarelistedit,
    vcurriculumlistedit,
    vsharedvcmap2,
    vrecpositionedit,
    vrecpositionaddspecial,
    vpersonalinfo,
    vhome,
    vmenuadd,
    vtoolsedit,
    vhomeadd,
    vmenuedit,
    vhomeedit,
    vcompanymoneydetails,
    vsetphotoexamine,
    vcompanypowerset,
    vsharedvcexfactoryZijinfang,
	 vsharedvcexfactoryZijinfangols,
    vyingbipresent,
    vyingbipresentadd,
    vyingbipresentinfo,
    vtagsgroup,
    vtagslist,
    vsharedvceopenclose,
    vgoinmonad,
    vgoinmonadinfo,
    vgoinmonadadd,
    vgooutmonad,
    vgooutmonadinfo,
    vgooutreportmonad,
    vgooutreportmonadinfo,
    vreservenums,
    vgodown,
    vgodownedit,
    vgodownadd,
    vgointype,
    vgointypeadd,
    vgoouttype,
    vgoouttypeadd,
    vproductclass,
    vproductmanage,
    vproductmanageadd,
    vproductmanageedit,
    vsupplier,
    vsupplieredit,
    vsupplieradd,
    vunitnums,
    vunitnumsadd,
    vphoneloginempower,
    vwarehousemanifest,
    vexportmanifest,
    vpresentstock,
    vdvcgoinoutlog,
    vdvclist,
    vgooutreceivemonad,
    vgooutreceivemonadinfo,
    vbatchmanage,
    vbatchmanageadd,
    vbatchmanagechack,
    vbatchmanageedit,
    vbatchmanageinfo,
    vbatchmanagefunder, 
    vbatchmanagefunderinfo,
    vbatchpayment,
    vbatchpaymentfunder,
    vdeviceBank,
    vthirdBillList,
    vthirdOrderList
  },
}
</script>

<style scoped>
</style>