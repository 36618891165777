<template>
  <div class="order-list">
    <el-breadcrumb>
      <el-breadcrumb-item>第三方租期数据</el-breadcrumb-item>
      <el-breadcrumb-item>订单列表</el-breadcrumb-item>
    </el-breadcrumb>
    <el-form
      ref="searchFormEle"
      :model="searchForm"
      class="search-form"
      inline
      label-suffix=":"
      @keydown.enter="onSearch"
    >
      <el-form-item label="订单编号" prop="orderNo">
        <el-input v-model="searchForm.orderNo" placeholder="请输入" clearable />
      </el-form-item>
      <el-form-item label="签约用户" prop="user_type">
        <el-select v-model="searchForm.user_type" placeholder="请选择" clearable>
          <el-option
            v-for="opt in userTypeOpt"
            :label="opt.label"
            :value="opt.value"
            :key="opt.value"
          />
        </el-select>
      </el-form-item>
      <el-form-item label="支付宝账号" prop="pay_account">
        <el-input v-model="searchForm.pay_account" placeholder="请输入" clearable />
      </el-form-item>
      <el-form-item label="归属代理商" prop="shop_name">
        <el-input v-model="searchForm.shop_name" placeholder="请输入" clearable />
      </el-form-item>
      <el-form-item label="订单状态" prop="orderStatus">
        <el-select v-model="searchForm.orderStatus" placeholder="请选择" clearable>
          <el-option
            v-for="opt in orderStatusOpt"
            :key="opt.value"
            :label="opt.label"
            :value="opt.value"
          />
        </el-select>
      </el-form-item>
      <el-form-item>
        <el-button type="primary" @click="onSearch">查询</el-button>
        <el-button @click="resetSearchForm">重置</el-button>
      </el-form-item>
    </el-form>
    
    <div class="table-data">
      <div class="export">
        <el-button :loading="tableLoading" type="primary" @click="getList(1)">导出</el-button>
      </div>
      <el-table :data="tableData" v-loading="tableLoading" border>
        <template v-for="column in tableItems" :key="column">
          <el-table-column
            :label="column.label"
            :prop="column.prop"
            :min-width="column.minWidth"
            :width="column.width"
            :fixed="column.fixed"
            :formatter="column.formatter"
          >
            <template v-if="column.prop === 'no'" #default="{$index}">
              {{ (page.page - 1) * page.page_size + $index + 1 }}
            </template>
            <template v-else-if="column.prop === 'orderNo'" #default="{row}">
              <span class="span-link" @click="toBillList(row.orderNo)">{{ row.orderNo }}</span>
            </template>
            <template v-else-if="column.prop === 'extend_order'" #default="{row}">
              <span class="span-link" @click="toBillList(row.extend_order)">{{ row.extend_order }}</span>
            </template>
            <template v-else-if="column.prop === 'client_num'" #default="{row}">
              <span class="span-link" @click="showRelationDevice(row.clientid)">{{ row.client_num }}</span>
            </template>
<!--            <template v-else-if="column.prop === 'remark'" #default="{row}">-->
<!--              <span class="span-link" @click="toBillList(row)">{{ row.remark }}</span>-->
<!--            </template>-->
            <template v-else-if="column.prop === 'singleStageAmount'" #default="{row}">
              <div class="col-line">
                <span>{{ row.rentAmount }}</span>
                <span>{{ row.singleStageAmount }}</span>
              </div>
            </template>
            <template v-else-if="column.prop === 'rentStartDate'" #default="{row}">
              <div class="col-line">
                <span>{{row.rentStartDate}}</span>
                <span>{{row.rentEndDate}}</span>
              </div>
            </template>
            <template v-else-if="column.prop === 'action'" #default="{row}">
              <el-button v-if="usePermission('wzj_order_bj')" type="primary" @click="handlerEdit(row)">编辑</el-button>
            </template>
          </el-table-column>
        </template>
      </el-table>
      <div class="page">
        <el-pagination
          v-model:current-page="page.page"
          v-model:page-size="page.page_size"
          :total="page.total"
          layout="total, sizes, prev, pager, next, jumper"
          :page-sizes="[10, 20, 50, 100]"
          background
          @sizeChange="getList(0)"
          @currentChange="getList(0)"
        />
      </div>
    </div>
    
    <el-drawer v-if="usePermission('wzj_order_bj')" v-model="drawerVisible" size="70%" title="订单配置" @close="closeDrawer">
      <el-tabs v-loading="drawerLoading" v-model="tabsCurrent" type="card">
        <el-tab-pane name="config" label="订单配置">
          
          <el-form
            ref="configFormEle"
            class="config-form"
            :model="configForm"
            label-suffix=":"
            label-position="right"
            label-width="120px"
          >
            <el-scrollbar :max-height="computedHeight" style="padding-right: 15px">
              <el-form-item label="订单编号" prop="orderNo" required>{{ configForm.orderNo }}</el-form-item>
              <el-form-item label="支付宝账号" prop="pay_account">
                <el-input v-model="configForm.pay_account" placeholder="请输入" />
              </el-form-item>
              <el-form-item label="签约用户" prop="user_type" :rules="[{ required: true, message: '请选择签约用户' }]">
                <el-radio-group v-model="configForm.user_type">
                  <el-radio :label="1">代理商</el-radio>
                  <el-radio :label="2">商户</el-radio>
                </el-radio-group>
              </el-form-item>
              <el-form-item label="关联设备" prop="clientid">
                <RelationDevice v-model="configForm.clientid" />
              </el-form-item>
              <el-form-item label="归属公司" prop="companyid">
                <el-select
                  style="width: 100%"
                  v-model="configForm.companyid"
                  placeholder="请选择"
                  clearable
                  filterable
                  @change="companyChange"
                >
                  <template v-for="(com, c) in companyOpt" :key="c">
                    <el-option
                      :label="com.name"
                      :value="com.id"
                    />
                  </template>
                </el-select>
              </el-form-item>
              <el-form-item
                v-if="configForm.companyid"
                label="归属代理商"
                prop="shopid"
                :rules="[{ required: true, message: '请选择代理商' }]"
              >
                <agent-level
                  ref="agentLevelEle"
                  v-model="configForm.shopid"
                />
              </el-form-item>
              <el-form-item label="合作政策" prop="rule">
                <el-select
                  style="width: 100%"
                  v-model="configForm.rule"
                  placeholder="请选择"
                  clearable
                >
                  <template v-for="(opt, o) in ruleOpt" :key="o">
                    <el-option :label="opt.lab" :value="opt.val" />
                  </template>
                </el-select>
              </el-form-item>
              <el-form-item label="辅订单号" prop="extend_order">
                <el-input v-model="configForm.extend_order" placeholder="请输入" />
              </el-form-item>
              <el-form-item label="备注" prop="remark">
                <el-input
                  v-model="configForm.remark"
                  placeholder="请输入"
                  type="textarea"
                  rows="5"
                  maxlength="200"
                  show-word-limit
                />
              </el-form-item>
            </el-scrollbar>
            
            <el-form-item style="margin-top: 50px">
              <el-button type="primary" :loading="drawerSubmit" style="width: 200px" @click="storeConfig">确认</el-button>
              <el-button @click="closeDrawer">取消</el-button>
            </el-form-item>
          </el-form>
        </el-tab-pane>
        <el-tab-pane name="history" label="操作记录">
          <el-table
            class-name="history"
            :data="history"
            border
            :max-height="computedHeight + 100"
          >
            <el-table-column label="操作人" prop="admin_info" width="120" />
            <el-table-column label="操作时间" prop="addtime" width="200">
              <template #default="{row}">
                {{ $utils.formatDate(row.addtime) }}
              </template>
            </el-table-column>
            <el-table-column label="操作内容" prop="contents"></el-table-column>
          </el-table>
        </el-tab-pane>
      </el-tabs>
    </el-drawer>
    
    <!--关联设备弹窗-->
    <RelationDevice ref="relationDeviceEle" :onlyDialog="true" />
    
  </div>
</template>

<script setup>
import {ref, nextTick, computed} from 'vue'
import AgentLevel from './components/agentLevel.vue'
import RelationDevice from './components/relationDevice.vue'
import {usePermission} from './usePermission'
import {useAxios} from './useAxios'
import router from '@/router'
import {ElMessage} from "element-plus";

const userTypeOpt = [
  {
    label: '代理商',
    value: 1,
  },
  {
    label: '商户',
    value: 2
  }
]
const orderStatusOpt = [
  {
    label: '进行中',
    value: 1
  },
  {
    label: '已取消',
    value: 2
  },
  {
    label: '已完结',
    value: 3
  },
]
const searchFormEle = ref()
const searchForm = ref({
  orderNo: '',
  user_type: '', // 1代理商2商户
  pay_account: '',
  orderStatus: '', // 1进行中 2已取消 3订单完结
  shop_name: ''
})

const page = ref({
  page: 1,
  page_size: 10,
  total: 0
})
const tableData = ref([{client_num: 3}])
const tableLoading = ref(false)
const tableItems = ref([
  {
    label: '序号',
    prop: 'no',
    width: 70,
    fixed: 'left'
  },
  {
    label: '订单编号',
    prop: 'orderNo',
    minWidth: 200
  },
  {
    label: '下单时间',
    prop: 'orderCreateTime',
    minWidth: 200
  },
  {
    label: '签约用户',
    prop: 'user_type_name',
    minWidth: 120,
    // formatter: (row) => {
    //   const type = +row.user_type
    //   return type === 1 ? '代理商' : type === 2 ? '商户' : '未分配'
    // }
  },
  {
    label: '支付宝账号',
    prop: 'pay_account',
    minWidth: 120
  },
  {
    label: '关联设备',
    prop: 'client_num',
    minWidth: 120
  },
  {
    label: '归属公司',
    prop: 'company_name',
    minWidth: 180
  },
  {
    label: '归属代理商',
    prop: 'shop_name',
    minWidth: 180
  },
  {
    label: '合作政策',
    prop: 'rule_name',
    minWidth: 180
  },
  {
    label: '租金',
    prop: 'singleStageAmount',
    minWidth: 180
  },
  {
    label: '数量',
    prop: 'number',
    minWidth: 180
  },
  {
    label: '租期',
    prop: 'rentStartDate',
    minWidth: 180,
  },
  {
    label: '已交租',
    prop: 'company',
    minWidth: 180
  },
  {
    label: '订单状态',
    prop: 'orderStatusName',
    minWidth: 180,
    // formatter: (row) => {
    //   const s = {
    //     1: '进行中',
    //     2: '已取消',
    //     3: '已完结'
    //   }
    //   return s[+row.orderStatus]
    // }
  },
  {
    label: '实付款',
    prop: 'company',
    minWidth: 180
  },
  {
    label: '辅助单号',
    prop: 'extend_order',
    minWidth: 180
  },
  {
    label: '备注',
    prop: 'remark',
    minWidth: 180
  },
  {
    label: '操作',
    prop: 'action',
    width: 100,
    fixed: 'right'
  },
])

const getList = (isExport = 0) => {
  tableLoading.value = true
  useAxios(`manage/WozujiOrder/index`, 'post', {
    ...searchForm.value,
    export: isExport,
    page: page.value.page,
    page_size: page.value.page_size
  }).then(res => {
    if(res.data.code === 0 && !isExport) {
      const {data, total} = res.data.result
      tableData.value = data
      page.value.total = total
    }
  }).finally(() => {
    tableLoading.value = false
  })
}
getList(0)

const onSearch = () => {
  page.value.page = 1
  page.value.page_size = 10
  page.value.total = 0
  getList(0)
}
const resetSearchForm = () => {
  searchFormEle.value.resetFields()
  onSearch()
}

// 列表-关联设备弹窗
const relationDeviceEle = ref()
const showRelationDevice = (ids) => {
  if(ids) relationDeviceEle.value.openDialog(ids || '')
}

const drawerLoading = ref(false)
const drawerVisible = ref(false)
const tabsCurrent = ref('config')

const configFormEle = ref()
const configForm = ref({
  id: '',
  orderNo: '',
  pay_account: '',
  user_type: '1',
  clientid: '',
  companyid: '',
  shopid: [], // 提交时只传最后一项
  rule: '',
  extend_order: '',
  remark: ''
})

// 获取公司选项
const companyOpt = ref([])
const getCompanyOpt = () => {
  return new Promise((resolve) => {
    useAxios(`manage/Equipment/getComSelect`, 'get',).then(res => {
      companyOpt.value = res.data.result
      resolve(1)
    })
  })
}
const agentLevelEle = ref()
const companyChange = (companyid) => {
  configForm.value.shopid = []
  if(companyid) {
    setTimeout(() => {
      configFormEle.value.clearValidate('shopid')
      agentLevelEle.value.getAgentOpt(companyid)
    }, 10)
  }
}

// 获取合作政策
const ruleOpt = ref([])
const getRuleOpt = () => {
  return new Promise((resolve) => {
    useAxios(`/manage/config/info`, 'post', {
      key_name: 'wozuji_with'
    }).then(res => {
      Object.keys(res.data.result).forEach(k => {
        ruleOpt.value.push({
          val: +k,
          lab: res.data.result[k]
        })
      })
      resolve(1)
    })
  })
}

// 操作记录
const history = ref([])
const getHistory = (id) => {
  return new Promise((resolve) => {
    useAxios(`manage/WozujiOrder/editLogIndex`, 'post', {id}).then(res => {
      history.value = res.data.result
      resolve(1)
    })
  })
}

// 详情
const getDetail = (id) => {
  return new Promise((resolve) => {
    useAxios(`manage/WozujiOrder/view`, 'post', {id}).then(res => {
      const result = res.data.result
      if(result.companyid) companyChange(result.companyid)
      Object.keys(result).forEach(k => {
        configForm.value[k] = result[k] === 0 ? '' : result[k]
      })
      configForm.value.user_type = configForm.value.user_type === -1 ? '' : configForm.value.user_type
      configForm.value.shopid = result.shops.split(',')
      resolve(1)
    })
  })
}

const isLoaded = ref(false)
const handlerEdit = async (row) => {
  drawerVisible.value = true
  drawerLoading.value = true
  if(row.id) {
    await getHistory(row.id)
    await getDetail(row.id)
  }
  if(!isLoaded.value) {
    await getCompanyOpt()
    await getRuleOpt()
    await nextTick(() => {
      isLoaded.value = true
    })
  }
  drawerLoading.value = false
}
const closeDrawer = () => {
  configFormEle.value.resetFields()
  drawerVisible.value = false
}
const drawerSubmit = ref(false)
const storeConfig = () => {
  configFormEle.value.validate((valid) => {
    if(valid) {
      drawerSubmit.value = true
      delete configForm.value.shops
      const formData = {
        ...configForm.value,
        shopid: JSON.parse(JSON.stringify(configForm.value.shopid)).pop()
      }
      useAxios(`manage/WozujiOrder/edit`, 'post', formData).then(res => {
        if(res.status === 200 && res.data.code === 0) {
          ElMessage.success(res.data.message)
          closeDrawer()
          getList()
        }
      }).finally(() => {
        drawerSubmit.value = false
      })
    }
  })
}

const toBillList = (orderNo) => {
  if(usePermission('wzj_order_ck')) {
    router.push({
      path: `/thirdBillList`,
      query: {
        orderNo: orderNo
      }
    })
  }
}

const computedHeight = computed(() => {
  if(!window) return 0
  else return window.innerHeight - 280
})
</script>

<style lang="scss" scoped>
.order-list{
  .search-form{
    margin-top: 15px;
    padding: 15px;
    background-color: #fff;
    :deep(.el-form-item){
      width: 270px;
    }
  }
  .table-data{
    margin-top: 15px;
    padding: 15px;
    background-color: #fff;
    .span-link{
      cursor: pointer;
      color: var(--el-color-primary);
      &:hover{
        color: var(--el-color-primary-light-4);
      }
    }
    .col-line{
      display: flex;
      flex-direction: column;
    }
    .export{
      display: flex;
      flex-direction: row;
      justify-content: flex-end;
      margin-bottom: 15px;
    }
    .page{
      display: flex;
      flex-direction: row;
      justify-content: center;
      margin-top: 15px;
    }
  }
  
  .config-form{
    width: 80%;
    margin: 30px auto;
    .agent-select{
      display: flex;
      flex-direction: row;
      width: 100%;
    }
  }
}
</style>
