<template>
  <div>
    <mainone :mainonebread="mainonebread"></mainone>
    <!--table表单-->
    <div>
      <!--主list列表-->
      <div style="
          margin-top: 10px;
          background-color: #ffffff;
          border-radius: 4px;
          line-height: 40px;
          padding: 0 20px;
        ">
        <div style="line-height: 60px">
          <!--左侧名称-->
          <div style="float: left; font-size: 14px">数据列表</div>
          <!--右侧按钮-->
          <div style="float: right">
            <!--自定义按钮-点击出现弹框&点击跳转子类页面@click="routeraddrClick('/XX路由')"-->
            <!--1、点击出现弹框@click="addTanClick(XX弹框配置信息,dialogVisible = true);resetFormSenior(XXform表单)"-->
            <!--2、点击跳转子类页面@click="routeraddrClick('/XX路由')-->
            <el-button type="primary" plain @click="routeraddrClick('/coursewareclassifyadd')"
              v-if="mypowerlimits.kjfl_tj">添加</el-button>
          </div>
        </div>

        <div>
          <!--table表单-->
          <el-table ref="multipleTable" :row-class-name="tableRowClassName" :data="
            mainthreetableData.slice(
              (currentPage - lastpage) * PageSize,
              currentPage * PageSize
            )
          " style="width: 100%" min-height="900" border v-loading="loading" element-loading-text="正在飞速加载中..."
            @selection-change="handleSelectionChange" @cell-click="handleOneData">
            <!--多选框-用于用户选择性导出数据-->
            <el-table-column type="selection" width="55" v-if="mainthreeisshowset" />
            <el-table-column v-for="(mytabletitle, i) in mainthreetabletitle" :key="i" :fixed="mytabletitle.fixedstatu"
              :prop="mytabletitle.prop" :label="mytabletitle.label" :width="mytabletitle.width">
              <!--这里先通过table列表中的title值进行判断，在进行渲染展示-->
              <!--例如：status状态字段，判断最终的值是那种表现形式等-->
              <template #default="scope" v-if="
                mytabletitle.prop === 'status' ||
                mytabletitle.prop === 'use_num'
              ">
                <!-- 课件数量-渲染数据 -->
                <div v-if="mytabletitle.prop === 'use_num'">
                  <div>
                    <div :class="mypowerlimits.kjlb ? 'mytabletext' : ''"
                      @click="mypowerlimits.kjlb ? routeraddrClick('/coursewarelist', scope.row) : ''">
                      <span>{{ scope.row.use_num }}</span>
                    </div>
                  </div>
                </div>
                <!-- 是否显示-渲染数据 -->
                <div v-if="mytabletitle.prop === 'status'">
                  <div v-if="scope.row.switchstatus === 4" id="switch">
                    <el-switch v-model="scope.row.status" active-text="开启" inactive-text="关闭" :active-value="1"
                      :inactive-value="2" active-color="#13ce66"
                      @click="mypowerlimits.kjfl_qy ? changeStatus(scope.row) : ''" :disabled="!mypowerlimits.kjfl_qy"
                      inactive-color="#cbcbcb">
                    </el-switch>
                  </div>
                </div>
              </template>
            </el-table-column>
            <!--操作&按钮-->
            <el-table-column fixed="right" label="操作" :width="mainthreehandle" v-if="mainthreeisshowope"
              prop="myoperation" id="operation">
              <template #default="scope">
                <!--操作：按照页面区分，且每个按钮对应相关函数-->
                <!--操作-课件分类-->
                <div v-if="mainthreetableope === 18">
                  <el-button type="primary" size="mini" @click="routeraddrClick('/coursewareclassifyedit', scope.row)"
                    v-if="mypowerlimits.kjfl_bj">
                    编辑</el-button>
                  <el-button @click="deleteRow(scope.$index, mainthreetableData, scope.row)" type="danger" size="mini"
                    icon="el-icon-delete" v-if="mypowerlimits.kjfl_sc"></el-button>
                  <el-button type="primary" size="mini" v-if="mypowerlimits.kjfl_zykj"
                    @click="addTanClick(tancontent, scope.row, (dialogVisible = true))">转移课件</el-button>
                </div>
              </template>
            </el-table-column>
          </el-table>
          <!--分页器-->
          <div class="block" style="text-align: center; margin: 30px 0">
            <el-pagination background @size-change="handleSizeChange" @current-change="handleCurrentChange"
              :current-page="currentPage" :page-sizes="pageSizes" :page-size="PageSize"
              layout="total, sizes, prev, pager, next, jumper" :total="totalCount">
            </el-pagination>
          </div>
        </div>
      </div>

      <!--弹出框-->
      <el-dialog v-model="dialogVisible" :title="tancontent.title" :width="this.searchwidth"
        :before-close="handleCloseSenior">
        <el-form ref="seniorform" :model="seniorform" :rules="seniorform_rules" label-width="150px"
          class="demo-ruleForm">
          <div style="max-height: 450px; overflow: hidden; overflow-y: visible; padding: 0 3%; margin: 20px 0 0 0;">
            <el-form-item label="原课件分类：" prop="sort_key">
              <el-input v-model="seniorform.sort_key" disabled></el-input>
            </el-form-item>
            <el-form-item label="目标课件分类：" prop="new_id">
              <el-select v-model="seniorform.new_id" placeholder="请选择目标课件分类" style="width: 100%"
                @visible-change="finduserSaleList()">
                <el-option :label="getsales.sort_key" :value="getsales.id"
                  v-for="(getsales, i) in seniorform.new_id_data" :key="i"></el-option>
              </el-select>
            </el-form-item>
            <el-form-item>
              <div style="text-align: right">
                <el-button @click="resetFormSenior((dialogVisible = false))">取消</el-button>
                <el-button type="primary" @click="onSubmitSenior('seniorform')">开始转移</el-button>
              </div>
            </el-form-item>
          </div>
        </el-form>
      </el-dialog>
    </div>
  </div>
</template>

<script>
import mainone from "@/components/Mainone";
import { ElMessageBox, ElMessage } from "element-plus";
import axios from "axios";
import { defineComponent, ref } from "vue";

export default defineComponent({
  name: "vcoursewareclassify",
  inject: ["reload"],
  data() {
    return {
      mainonebread: [
        {
          name: "教育",
        },
        {
          name: "课程管理",
        },
        {
          name: "课件分类",
          path: "/coursewareclassify",
        },
      ], // 当前页-【面包屑导航】
      mainthreeisshowset: false, // 数据列表中-复选框
      mainthreeisshowope: true, // 数据列表中-操作-根据页面显示模块（整页）
      mainthreetableope: 18, // 数据列表中-操作-根据页面显示控件（整页）
      mainthreehandle: 200, // 数据列表中-操作-宽度
      mainthreetabletitle: [
        {
          fixedstatu: "left",
          prop: "id",
          label: "ID",
          width: "200",
        },
        {
          fixedstatu: false,
          prop: "sort_key",
          label: "分类名称",
          width: "",
        },
        {
          fixedstatu: false,
          prop: "use_num",
          label: "课件数量",
          width: "",
        },
        {
          fixedstatu: false,
          prop: "status",
          label: "是否显示",
          width: "",
        },
        {
          fixedstatu: false,
          prop: "order",
          label: "排序",
          width: "",
        },
      ], // 数据列表中-数据表头
      mainthreestatus: true, // 数据列表中-属于search按钮表示状态
      mainthreetableData: [], // 数据列表中-具体数据
      totalCount: 1, //数据列表中-数据总条数
      currentpage: 1, //数据列表中-数据当前页数
      lastpage: 1, //数据列表中-数据总页数（默认以20条一页）
      loading: false, //初始化-loading加载动效，默认是false关闭

      //table表单相关数据
      seniorform: {
        sort_key: "",
        old_id: '',
        new_id: '',
        new_id_data: []
      },
      seniorform_rules: {
        sort_key: [
          {
            required: true,
            message: '原课件分类为必填项',
            trigger: 'blur',
          }
        ],
        new_id: [
          {
            required: true,
            message: '目标课件分类为必选项',
            trigger: 'change',
          },
        ],
      },
      currentPage: 1,
      pageSizes: [20, 50, 100],
      PageSize: 20,
      multipleSelection: [],
      thispagename: "", //通过路由获取当前页面的path值
      searchwidth: "580px",
      tancontent: {
        title: "转移课件",
        content: {},
      }, //弹框
      dialogVisible: ref(false), //弹窗控制状态
      editId: "", //获取标签id

      mypowerlimits: JSON.parse(localStorage.getItem("powerlimits")), //获取权限
      mytoken: localStorage.getItem("token"), //获取存储的token
      pagesdatas: [], //存储最近一次的筛选条件，供分页使用
    };
  },
  mounted() {
    //获取当前页面路由path值
    this.thispagename = this.$route.path;
  },
  methods: {
    // table表单-状态改变
    changeStatus(data) {
      axios
        .post(
          "/manage/Course/editCoursewareCate",
          {
            id: data.id,
            sort_key: data.sort_key,
            order: data.sort_val,
            status: data.status,
          },
          {
            headers: {
              Auth: localStorage.getItem("token"),
            },
          }
        )
        .then((response) => {
          if (response.data.code === 0) {
            ElMessage({
              type: "success",
              message: response.data.message,
            });
          } else {
            // 切换状态失败-复原状态
            if (data.status == 1) {
              data.status = 2;
            } else {
              data.status = 1;
            }
          }
        })
        .catch(function (error) {
          console.log(error);
        });
    },
    //table表-操作中-删除按钮（函数）
    deleteRow(index, rows) {
      ElMessageBox.confirm('确定删除:“' + rows[index].sort_key + '”吗？', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: "取消",
        type: "warning",
      }).then(() => {
        axios
          .post(
            "/manage/Course/deleteCoursewareCate",
            {
              id: rows[index].id,
            },
            {
              headers: {
                Auth: localStorage.getItem("token"),
              },
            }
          )
          .then((response) => {
            if (response.data.code == 0) {
              ElMessage({
                type: "success",
                message: "删除成功！",
                duration: 1000,
                onClose: () => {
                  rows.splice(index, 1);
                },
              });
            }
          })
      }).catch(() => {
        ElMessage({
          type: "info",
          message: "取消删除",
        });
      })
    },
    // 【转移课件】-获取下拉框数据
    finduserSaleList() {
      axios
        .post(
          "/manage/Course/indexCoursewareCate",
          {},
          {
            headers: {
              Auth: localStorage.getItem("token"),
            },
          }
        )
        .then((response) => {
          this.seniorform.new_id_data = response.data.result.data;
        })
        .catch(function (error) {
          console.log(error);
        });
    },
    //table表中-当选项发生变化是触发该函数
    handleSelectionChange(val) {
      console.log("this is:" + val);
      this.multipleSelection = val;
    },
    //分页器中-当选择条数时触发该函数
    handleSizeChange(val) {
      // console.log(`每页 ${val} 条`);
      this.PageSize = val;
      this.currentPage = 1;

      //加载loading
      this.loading = true;
      //通过Ajax向后台获取数据
      axios
        .post(
          "/manage/Course/indexCoursewareCate",
          {
            page: 1,
            page_size: val,
          },
          {
            headers: {
              Auth: localStorage.getItem("token"),
            },
          }
        )
        .then((response) => {
          this.myaxiosfunc(response.data.result);
        })
        .catch(function (error) {
          // 请求失败处理
          console.log(error);
        });
    },
    //分页器中-当选择分页时触发该函数
    handleCurrentChange(val) {
      console.log(`当前页: 第${val}页`);
      this.currentPage = val;

      //加载loading
      this.loading = true;
      //通过Ajax向后台获取数据
      axios
        .post(
          "/manage/Course/indexCoursewareCate",
          {
            page: val,
            page_size: this.PageSize,
          },
          {
            headers: {
              Auth: localStorage.getItem("token"),
            },
          }
        )
        .then((response) => {
          this.myaxiosfunc(response.data.result);
        })
        .catch(function (error) {
          // 请求失败处理
          console.log(error);
        });
    },
    //点击按钮-弹出弹框并赋值弹窗基本配置
    addTanClick(taninfo, data) {
      console.log(data);
      // 详情
      this.seniorform.old_id = data.id;
      this.seniorform.sort_key = data.sort_key;
    },
    //点击进行路由页面跳转以及携带参数
    routeraddrClick(routeraddr, rowdata) {
      // 携带参数跳转
      if (rowdata) {
        // 跳转-课件列表
        if (routeraddr === "/coursewarelist") {
          this.$router
            .push({
              path: routeraddr,
              query: { sort_val: rowdata.sort_val },
            })
            .catch((error) => error);
        }
        // 跳转-编辑分类
        if (routeraddr === "/coursewareclassifyedit") {
          this.$router
            .push({
              path: routeraddr,
              query: { id: rowdata.id, order: rowdata.order, sort_key: rowdata.sort_key, sort_val: rowdata.sort_val, status: rowdata.status },
            })
            .catch((error) => error);
        }
      } else {
        //仅跳转
        this.$router.push(routeraddr).catch((error) => error);
      }
    },
    //导出数据
    exportDatas(tableData) {
      console.log(tableData);
    },
    //弹出框-提交表单
    onSubmitSenior(formName) {
      let getdatas = this.$refs[formName].model
      this.$refs[formName].validate((valid) => {
        if (valid) {
          axios.post("/manage/Course/transferCoursewareCate",
            {
              old_id: getdatas.old_id,
              new_id: getdatas.new_id
            },
            {
              headers: {
                Auth: localStorage.getItem("token"),
              },
            })
            .then((response) => {
              if (response.data.code === 0) {
                this.dialogVisible = false;
                ElMessage({
                  type: "success",
                  message: response.data.message,
                  duration: 1000,
                  onClose: () => {
                    this.reload(); //刷新
                  },
                });
              }
            })
            .catch(function (error) {
              console.log(error);
            });
        } else {
          console.log("error submit!!");
          return false;
        }
      });
    },
    //弹出框-重置
    resetFormSenior(formName) {
      this.$refs[formName].resetFields();
      this.dialogVisible = false;
    },
    //获取当前windows页面信息-实时修改【弹窗】的宽度
    getwindowinfo() {
      this.sreenheight = document.documentElement.clientHeight;
      this.sreenwidth = document.documentElement.clientWidth;
      if (this.sreenwidth < 600) {
        this.searchwidth = this.sreenwidth * 0.8 + "px";
      } else {
        this.searchwidth = "580px";
      }
    },

    //提交等相关event事件函数
    //数据渲染
    myaxiosfunc(successdata) {
      var newdata = successdata.data;
      for (var i = 0; i < newdata.length; i++) {
        //该字段表示search按钮，且status为1表示启用，2表示禁用
        newdata[i].switchstatus = 4;
      }
      // console.log('当前页面的data:' + JSON.stringify(newdata))
      this.mainthreetableData = newdata;
      this.totalCount = successdata.total;
      this.currentpage = successdata.current_page; //当前页面页数
      this.lastpage = successdata.last_page;
      this.loading = false;
    },
  },
  created() {
    if (localStorage.getItem("token") === null || !localStorage.getItem("token")) {
      this.isshow = false;
      this.$router.push("/").catch((error) => error);
    } else {
      window.addEventListener("resize", this.getwindowinfo); // 注册监听器-实时检测浏览器页面宽高
      this.getwindowinfo();

      //加载loading
      this.loading = true; //通过Ajax向后台获取数据
      //通过Ajax向后台获取数据
      axios
        .post(
          "/manage/Course/indexCoursewareCate",
          {
            page: 1,
            page_size: this.PageSize,
          },
          {
            headers: {
              Auth: localStorage.getItem("token"),
            },
          }
        )
        .then((response) => {
          this.myaxiosfunc(response.data.result);
        })
        .catch(function (error) {
          console.log(error);
        });
    }
  },
  components: {
    mainone,
  },
});
</script>

<style scoped>
/*局部控制-Switch按钮-按钮中显示状态文字*/
/* switch按钮款式 */
#switch :deep(.el-switch__label) {
  position: absolute;
  display: none;
  color: #fff !important;
}

/*关上时文字地位设置*/
#switch :deep(.el-switch__label--right) {
  z-index: 1;
}

/* 调整关上时文字的显示位子 */
#switch :deep(.el-switch__label--right) span {
  margin-left: 3px;
  font-size: 12px;
}

/*敞开时文字地位设置*/
#switch :deep(.el-switch__label--left) {
  z-index: 1;
}

/* 调整敞开时文字的显示位子 */
#switch :deep(.el-switch__label--left) span {
  margin-left: 15px;
  font-size: 12px;
}

/*显示文字*/
#switch :deep(.el-switch__label.is-active) {
  display: block;
}

/* 调整按钮的宽度 */
#switch :deep(.el-switch) .el-switch__core,
#switch :deep(.el-switch) .el-switch__label {
  width: 55px !important;
  margin: 0;
  padding: 5px;
}

/*操作中-按钮样式*/
.el-button--mini {
  min-height: 28px;
  padding: 0 8px;
  font-size: 12px;
  border-radius: calc(var(--el-border-radius-base) - 1px);
}

.mytabletext {
  color: var(--el-color-primary);
  cursor: pointer;
}
</style>