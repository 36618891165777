<template>
    <div>
        <mainone :mainonebread="mainonebread"></mainone>
        <maintwo
            :maintwoiconshow="maintwoiconshow"
            :maintwoforminfo="maintwoforminfo"
            :myinputoneisshow="maintwoforminfo.myinputoneisshow"
            :myinputone="maintwoforminfo.myinputone"
            :myselectleftisshow="maintwoforminfo.selectleftisshow"
            :myselectleft="maintwoforminfo.selectleft"
            :myinputishow="myinputishow"
            :myinput="myinput"
            :myinputonefullisshow="maintwoforminfo.myinputonefullisshow"
            :myinputonefull="maintwoforminfo.myinputonefull"
            :myselectisshow="myselectisshow"
            :myselect="myselect"
            :myselectcityisshow="myselectcityisshow"
            :myselectcity="myselectcity"
            v-on:getsearchinfo="getSeachinfoEvent"
            v-on:searchForm="searchFormEvent"
        ></maintwo>
        <!--table表单-->
        <div>
            <!--主list列表-->
            <div class="box-list">
                <div style="line-height: 60px">
                    <!--左侧名称-->
                    <div style="float: left; font-size: 14px">数据列表</div>
                    <!--右侧按钮-->
                    <div style="float: right">
                        <!--自定义按钮-点击出现弹框&点击跳转子类页面@click="routeraddrClick('/XX路由')"-->
                        <!--1、点击出现弹框@click="addTanClick(XX弹框配置信息,dialogVisible = true);resetFormSenior(XXform表单)"-->
                        <!--2、点击跳转子类页面@click="routeraddrClick('/XX路由')-->
                        <!--<el-button ></el-button>-->
                        <!--导出按钮-点击导出数据-->
                        <el-button type="primary" plain @click="routeraddrClick('/yingbipresentadd')">添加</el-button>
                    </div>
                </div>

                <div>
                    <!--table表单-->
                    <el-table
                        :header-cell-style="{ background: '#F5F7FA' }"
                        ref="multipleTable"
                        :row-class-name="tableRowClassName"
                        :data="mainthreetableData.slice((currentPage - lastpage) * PageSize, currentPage * PageSize)"
                        style="width: 100%"
                        min-height="900"
                        border
                        v-loading="loading"
                        element-loading-text="正在飞速加载中..."
                        @selection-change="handleSelectionChange"
                        @cell-click="handleOneData"
                    >
                        <!--多选框-用于用户选择性导出数据-->
                        <el-table-column type="selection" width="55" v-if="mainthreeisshowset" />
                        <el-table-column
                            v-for="(mytabletitle, i) in mainthreetabletitle"
                            :key="i"
                            :fixed="mytabletitle.fixedstatu"
                            :prop="mytabletitle.prop"
                            :label="mytabletitle.label"
                            :width="mytabletitle.width"
                        >
                            <!--这里先通过table列表中的title值进行判断，在进行渲染展示-->
                            <!--例如：status状态字段，判断最终的值是那种表现形式等-->
                            <template
                                #default="scope"
                                v-if="
                                    mytabletitle.prop === 'use_time' ||
                                        mytabletitle.prop === 'admin_user' ||
                                        mytabletitle.prop === 'addtime' ||
                                        mytabletitle.prop === 'status' ||
                                        mytabletitle.prop === 'allow_companyids_name'
                                "
                            >
                                <!-- 有效期 -->
                                <div v-if="mytabletitle.prop === 'use_time'">
                                    <span>{{ scope.row.starttime != 0 ? this.$utils.formatDate(scope.row.starttime) : '' }}</span>
                                    <span>至</span>
                                    <span>{{ scope.row.endtime != 0 ? this.$utils.formatDate(scope.row.endtime) : '' }}</span>
                                </div>
                                <!-- 创建人 -->
                                <div v-if="mytabletitle.prop === 'admin_user'">
                                    <div>
                                        <span>姓名：</span><span>{{ scope.row.truename }}</span>
                                    </div>
                                    <div>
                                        <span>账户名：</span><span>{{ scope.row.username }}</span>
                                    </div>
                                </div>
                                <!-- 创建时间 -->
                                <div v-if="mytabletitle.prop === 'addtime'">
                                    <span>{{ scope.row.addtime != 0 ? this.$utils.formatDate(scope.row.addtime) : '' }}</span>
                                </div>
                                <!-- 状态 -->
                                <div v-if="mytabletitle.prop === 'status'">
                                    <span>
                                        {{
                                            !scope.row.status && scope.row.status == ''
                                                ? ''
                                                : this.maintwoforminfo.selectleft.select[0].options.filter((n) => n.value == scope.row.status)[0]['label']
                                        }}
                                    </span>
                                </div>
                                <!-- 兑换公司 -->
                                <div v-if="mytabletitle.prop === 'allow_companyids_name'">
                                    <span>{{ scope.row.allow_companyids_name.length > 0 ? scope.row.allow_companyids_name.join('\n') : '' }}</span>
                                </div>
                            </template>
                        </el-table-column>
                        <!--操作&按钮-->
                        <el-table-column fixed="right" label="操作" :width="mainthreehandle" v-if="mainthreeisshowope" prop="myoperation" id="operation">
                            <template #default="scope">
                                <div>
                                    <el-button v-if="scope.row.status != -1" type="primary" size="mini" @click="routeraddrClick('/yingbipresentinfo', scope.row)">数据</el-button>
                                    <el-button v-if="scope.row.status == 1" type="primary" size="mini" @click="tanExchangeCode(scope.row)">兑换码</el-button>
                                    <el-button v-if="scope.row.status != 2" type="primary" size="mini" @click="add(scope.row)">增加发行量 </el-button>
                                    <el-button v-if="scope.row.status != 2" type="danger" size="mini" @click="loseRow(scope.row)" :disabled="disabled_end">结束</el-button>
                                </div>
                            </template>
                        </el-table-column>
                    </el-table>
                    <!--分页器-->
                    <div class="block" style="text-align: center; margin: 30px 0">
                        <el-pagination
                            background
                            @size-change="handleSizeChange"
                            @current-change="handleCurrentChange"
                            :current-page="currentPage"
                            :page-sizes="pageSizes"
                            :page-size="PageSize"
                            layout="total, sizes, prev, pager, next, jumper"
                            :total="totalCount"
                        >
                        </el-pagination>
                    </div>
                </div>
            </div>

            <!--增加发行量-弹出框-->
            <el-dialog v-model="dialogVisible" title="增加发行量" :width="searchwidth" :before-close="handleCloseSenior">
                <Addnum ref="addnumvue" :Addnum="addnum" @resetevent="resetEvent" @success="successEvent" />
            </el-dialog>

            <!-- 兑换码-弹出框 -->
            <el-dialog v-model="dialogVisible_1" title="兑换码" :width="searchwidth_1" :before-close="handleCloseSeniorOne">
                <ExchangeCode ref="exchangeRef" :Exchange="exchange" />
            </el-dialog>
        </div>
    </div>
</template>

<script>
import mainone from '@/components/Mainone'
import maintwo from '@/components/Maintwo'
import { ElMessage } from 'element-plus' // ElMessageBox,
import axios from 'axios'
import { defineComponent, ref } from 'vue'
import Addnum from './Addnum.vue'
import ExchangeCode from './ExchangeCode.vue'

export default defineComponent({
    name: 'vyingbipresent',
    inject: ['reload'], //刷新引用
    data() {
        return {
            mainonebread: [
                {
                    name: '运营'
                },
                {
                    name: '京币'
                },
                {
                    name: '活动送京币'
                }
            ], // 当前页-【面包屑导航】
            maintwoiconshow: false, // 筛选-【高级筛选按钮】
            maintwoforminfo: {
                myinputoneisshow: true, //筛选查询-左侧字+input
                myinputone: {
                    divinterval: '0', //框右侧距离
                    titlewidth: '74px', //标题宽度
                    inputwidth: '200px', //input宽度
                    inputinterval: '20px', //input右侧距离
                    input: [
                        {
                            ismust: false, //是否必填，true表示必填
                            title: '活动名称：',
                            placeholder: '请输入活动名称',
                            name: 'name',
                            content: ''
                        },
                        {
                            ismust: false, //是否必填，true表示必填
                            title: '创建人：',
                            placeholder: '请输入创建人姓名',
                            name: 'truename',
                            content: ''
                        }
                    ]
                },
                selectleftisshow: true, //筛选查询-左侧字+select单框||select时间
                selectleft: {
                    width: 'none',
                    divinterval: '', //框右侧距离
                    selectwidth: '200px',
                    selectinterval: '20px', //select右侧距离
                    select: [
                        {
                            selectisshow: true,
                            ismust: false,
                            title: '状态：',
                            placeholder: '全部',
                            name: 'status',
                            value: '',
                            options: [
                                {
                                    label: '全部',
                                    value: ''
                                },
                                {
                                    label: '未开始',
                                    value: '-1'
                                },
                                {
                                    label: '进行中',
                                    value: '1'
                                },
                                {
                                    label: '已结束',
                                    value: '2'
                                }
                            ]
                        }
                    ]
                }
            }, // 筛选-控件信息
            mainthreeisshowbut: false, // 数据列表中-【添加按钮】
            mainthreeisshowset: false, // 数据列表中-复选框
            mainthreeisshowope: true, // 数据列表中-操作-根据页面显示模块（整页）
            mainthreetableope: 26, // 数据列表中-操作-根据页面显示控件（整页）
            mainthreehandle: 320, // 数据列表中-操作-宽度
            mainthreetabletitle: [
                {
                    fixedstatu: 'left',
                    prop: 'name',
                    label: '活动名称',
                    width: '150'
                },
                {
                    fixedstatu: false,
                    prop: 'remark',
                    label: '备注',
                    width: ''
                },
                {
                    fixedstatu: false,
                    prop: 'total',
                    label: '发行量',
                    width: '100'
                },
                {
                    fixedstatu: false,
                    prop: 'amount',
                    label: '京币面额',
                    width: '120'
                },
                {
                    fixedstatu: false,
                    prop: 'use_time',
                    label: '有效时间',
                    width: '340'
                },
                {
                    fixedstatu: false,
                    prop: 'admin_user',
                    label: '创建人',
                    width: '180'
                },
                {
                    fixedstatu: false,
                    prop: 'addtime',
                    label: '添加时间',
                    width: '180'
                },
                {
                    fixedstatu: false,
                    prop: 'status',
                    label: '状态',
                    width: '100'
                },
                {
                    fixedstatu: false,
                    prop: 'allow_companyids_name',
                    label: '兑换公司',
                    width: '300'
                }
            ], // 数据列表中-数据表头
            mainthreestatus: true, // 数据列表中-属于search按钮表示状态
            mainthreetableData: [], // 数据列表中-具体数据
            totalCount: 1, //数据列表中-数据总条数
            currentpage: 1, //数据列表中-数据当前页数
            lastpage: 1, //数据列表中-数据总页数（默认以20条一页）
            loading: false, //初始化-loading加载动效，默认是false关闭

            //table表单相关数据
            seniorform: {},
            currentPage: 1,
            pageSizes: [20, 50, 100],
            PageSize: 20,
            multipleSelection: [],
            searchwidth: '580px',
            searchwidth_1: '900px',

            // 领券码
            dialogVisible_1: ref(false), //弹窗显示&隐藏
            exchange: {
                id: ''
            },

            // 增加发行量
            addnum: {
                id: '',
                number: '',
                newnum: '',
                allnum: ''
            },
            dialogVisible: false,

            // 防抖-结束按钮
            disabled_end: false,

            mypowerlimits: JSON.parse(localStorage.getItem('powerlimits')), //获取权限
            mytoken: localStorage.getItem('token'), //获取存储的token
            pagesdatas: [] //存储最近一次的筛选条件，供分页使用
        }
    },
    methods: {
        //点击进行路由页面跳转以及携带参数
        routeraddrClick(routeraddr, data) {
            if (data) {
                this.$router.push({ path: routeraddr, query: { id: data.id } }).catch((error) => error)
            } else {
                this.$router.push(routeraddr).catch((error) => error)
            }
        },

        // *************************** 增加发行量 ***************************
        // 增加发行量-弹窗
        add(data) {
            this.addnum.id = data.id
            this.addnum.number = data.total
            this.dialogVisible = ref(true) //增加发行量弹窗
        },
        // 增加发行量-取消
        resetEvent() {
            this.dialogVisible = ref(false)
        },
        // 增加发行量-关闭
        handleCloseSenior() {
            this.dialogVisible = ref(false)
            this.$refs['addnumvue'].resetEvent('addnums')
        },
        // 增加发行量-成功
        successEvent() {
            this.handleCurrentChange(this.pagesdatas.page)
            this.handleCloseSenior()
        },

        // *************************** 兑换码 ***************************
        // 兑换码-弹窗
        tanExchangeCode(data) {
            this.dialogVisible_1 = ref(true)
            this.exchange.id = data.id
            // 兼容监听不触发情况下获取数据事件
            if (this.$refs['exchangeRef']) this.$refs['exchangeRef'].outInfoRefEvent(this.exchange)
        },
        handleCloseSeniorOne() {
            this.dialogVisible_1 = ref(false)
        },

        // *************************** 结束 ***************************
        // 结束-按钮事件
        loseRow(rowdata) {
            this.disabled_end = true
            axios
                .post(
                    '/manage/coinActivity/disable',
                    {
                        id: rowdata.id
                    },
                    {
                        headers: {
                            Auth: localStorage.getItem('token')
                        }
                    }
                )
                .then((response) => {
                    if (response.data.code === 0) {
                        ElMessage({
                            type: 'success',
                            message: '结束成功',
                            duration: 1000,
                            onClose: () => {
                                this.handleCurrentChange(this.pagesdatas.page)
                                this.disabled_end = false
                            }
                        })
                    }else{
                        this.disabled_end = false
                    }
                })
                .catch(function(error) {
                    // 请求失败处理
                    ElMessage({
                        type: 'error',
                        message: '结束失败！' + error
                    })
                    this.disabled_end = false
                })

            // ElMessageBox.confirm('该活动结束后，用户将无法再兑换该二维码，但之前已领取的二维码，在有效期内仍可继续使用。', '提示：', {
            //     confirmButtonText: '确定',
            //     cancelButtonText: '取消',
            //     type: 'warning'
            // })
            //     .then(() => {
            //         axios
            //             .post(
            //                 '/manage/coinActivity/disable',
            //                 {
            //                     id: rowdata.id
            //                 },
            //                 {
            //                     headers: {
            //                         Auth: localStorage.getItem('token')
            //                     }
            //                 }
            //             )
            //             .then((response) => {
            //                 if (response.data.code === 0) {
            //                     ElMessage({
            //                         type: 'success',
            //                         message: '结束成功',
            //                         duration: 1000,
            //                         onClose: () => {
            //                             this.handleCurrentChange(this.pagesdatas.page)
            //                         }
            //                     })
            //                 }
            //             })
            //             .catch(function(error) {
            //                 // 请求失败处理
            //                 ElMessage({
            //                     type: 'error',
            //                     message: '结束失败！' + error
            //                 })
            //             })
            //     })
            //     .catch(() => {
            //         ElMessage({
            //             type: 'info',
            //             message: '取消'
            //         })
            //     })
        },

        //获取当前windows页面信息-实时修改【弹窗】的宽度
        getwindowinfo() {
            this.sreenheight = document.documentElement.clientHeight
            this.sreenwidth = document.documentElement.clientWidth
            if (this.sreenwidth < 600) {
                this.searchwidth = this.sreenwidth * 0.8 + 'px'
            } else {
                this.searchwidth = '580px'
            }

            if (this.sreenwidth < 920) {
                this.searchwidth_1 = this.sreenwidth * 0.8 + 'px'
            } else {
                this.searchwidth_1 = '900px'
            }
        },

        //table表中-当选项发生变化是触发该函数
        handleSelectionChange(val) {
            this.multipleSelection = val
        },
        //分页器中-当选择条数时触发该函数
        handleSizeChange(val) {
            // console.log(`每页 ${val} 条`)
            this.PageSize = val
            this.currentPage = 1

            this.pagesdatas = {
                name: this.pagesdatas.name,
                truename: this.pagesdatas.truename,
                status: this.pagesdatas.status,
                page: 1,
                page_size: val
            }

            this.postAxiosEvent(this.pagesdatas)
        },
        //分页器中-当选择分页时触发该函数
        handleCurrentChange(val) {
            // console.log(`当前页: 第${val}页`)
            this.currentPage = val

            this.pagesdatas = {
                name: this.pagesdatas.name,
                truename: this.pagesdatas.truename,
                status: this.pagesdatas.status,
                page: val,
                page_size: this.PageSize
            }

            this.postAxiosEvent(this.pagesdatas)
        },
        //提交等相关event事件函数
        //获取Container-Pagedata-Maintwo-搜索-返回的数据
        getSeachinfoEvent(data) {
            // console.log('点击查询获取的data数组：' + JSON.stringify(data))
            this.pagesdatas = {
                name: data.name,
                truename: data.truename,
                status: data.status,
                page: 1,
                page_size: this.PageSize
            }

            this.postAxiosEvent(this.pagesdatas)
        },
        //数据渲染
        myaxiosfunc(successdata) {
            var newdata = successdata.data
            // console.log('当前页面的data:' + JSON.stringify(newdata))
            this.mainthreetableData = newdata
            this.totalCount = successdata.total
            this.currentpage = successdata.current_page //当前页面页数
            this.lastpage = successdata.last_page
            this.loading = false
        },
        // 接口
        postAxiosEvent(data) {
            this.loading = true

            axios
                .post('/manage/coinActivity/index', data, {
                    headers: {
                        Auth: localStorage.getItem('token')
                    }
                })
                .then((response) => {
                    this.myaxiosfunc(response.data.result)
                })
                .catch(function(error) {
                    // 请求失败处理
                    console.log(error)
                })
        }
    },
    created() {
        if (localStorage.getItem('token') === null || !localStorage.getItem('token')) {
            this.isshow = false
            this.$router.push('/').catch((error) => error)
        } else {
            window.addEventListener('resize', this.getwindowinfo) // 注册监听器-实时检测浏览器页面宽高
            this.getwindowinfo()

            this.pagesdatas = {
                page: 1,
                page_size: 20
            }
            this.postAxiosEvent(this.pagesdatas)
        }
    },
    components: {
        mainone,
        maintwo,
        Addnum,
        ExchangeCode
    }
})
</script>

<style scoped>
/*操作中-按钮样式*/
.el-button--mini {
    min-height: 28px;
    padding: 0 8px;
    font-size: 12px;
    border-radius: calc(var(--el-border-radius-base) - 1px);
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
    -webkit-appearance: none;
}

/* input[type="number"] {
    -moz-appearance: textfield;
  } */

.box-list {
    margin-top: 10px;
    background-color: #ffffff;
    border-radius: 4px;
    line-height: 40px;
    padding: 0 20px;
}
</style>
