<template>
  <div>
    <mainone :mainonebread="mainonebread"></mainone>
    <maintwo :maintwoiconshow="maintwoiconshow" :maintwoforminfo="maintwoforminfo"
      :myinputoneisshow="maintwoforminfo.myinputoneisshow" :myinputone="maintwoforminfo.myinputone"
      :myselectleftisshow="maintwoforminfo.selectleftisshow" :myselectleft="maintwoforminfo.selectleft"
      :myselecttopisshow="maintwoforminfo.selecttopisshow" :myselecttop="maintwoforminfo.selecttop"
      :myinputishow="myinputishow" :myinput="myinput" :myinputonefullisshow="maintwoforminfo.myinputonefullisshow"
      :myinputonefull="maintwoforminfo.myinputonefull" :myinputonefullgaoisshow="myinputonefullgaoisshow"
      :myinputonefullgao="myinputonefullgao" :myselectisshow="myselectisshow" :myselect="myselect"
      :myselectuptopisshow="myselecttopisshow" :myselectuptop="myselecttop" :myselectcityisshow="myselectcityisshow"
      :myselectcity="myselectcity" v-on:getsearchinfo="getSeachinfoEvent" v-on:searchForm="searchFormEvent"></maintwo>
    <!--table表单-->
    <div>
      <!--主list列表-->
      <div style="
          margin-top: 10px;
          background-color: #ffffff;
          border-radius: 4px;
          line-height: 40px;
          padding: 0 20px;
        ">
        <div style="line-height: 60px">
          <!--左侧名称-->
          <div style="float: left; font-size: 14px">数据列表</div>
          <!--右侧按钮-->
          <div style="float: right">
            <!--自定义按钮-点击出现弹框&点击跳转子类页面@click="routeraddrClick('/XX路由')"-->
            <!--1、点击出现弹框@click="addTanClick(XX弹框配置信息,dialogVisible = true);resetFormSenior(XXform表单)"-->
            <!--2、点击跳转子类页面@click="routeraddrClick('/XX路由')-->
            <!--<el-button ></el-button>-->
            <div class="mybutton_box">
              <div class="mybutton_box_btn" v-if="mypowerlimits.sbthjl_plthmb">
                <el-button type="primary" plain @click="changeDomeEvent()">下载批量退货模板
                </el-button>
              </div>
              <!-- Excel批量退货 -->
              <div style="margin: 0 10px 0 10px">
                <mainimgesup ref="uploadfile" :myimgesup="myimgesup" :myimgesupisshow="myimgesupisshow"
                  v-on:getuploadfile="(data) => getUploadFileEvent(data)"
                  v-on:deluploadfile="(data) => deluploadfileEvent(data)" v-if="mypowerlimits.sbthjl_plth">
                </mainimgesup>
              </div>
              <div class="mybutton_box_btn">
                <!--导出按钮-点击导出数据-->
                <el-button type="warning" plain @click="exportDatas()">导出</el-button>
              </div>
            </div>
          </div>
        </div>

        <div>
          <!--table表单-->
          <el-table ref="multipleTable" :row-class-name="tableRowClassName" :data="
            mainthreetableData.slice(
              (currentPage - lastpage) * PageSize,
              currentPage * PageSize
            )
          " style="width: 100%" min-height="900" border v-loading="loading" element-loading-text="正在飞速加载中..."
            @selection-change="handleSelectionChange" @cell-click="handleOneData">
            <!--多选框-用于用户选择性导出数据-->
            <el-table-column type="selection" width="55" v-if="mainthreeisshowset" />
            <el-table-column v-for="(mytabletitle, i) in mainthreetabletitle" :key="i" :fixed="mytabletitle.fixedstatu"
              :prop="mytabletitle.prop" :label="mytabletitle.label" :width="mytabletitle.width">
              <!--这里先通过table列表中的title值进行判断，在进行渲染展示-->
              <!--例如：status状态字段，判断最终的值是那种表现形式等-->
              <template #default="scope" v-if="mytabletitle.prop === 'client'">
                <div v-if="mytabletitle.prop === 'client'">
                  <div :class="this.mypowerlimits.jqkz == true ? 'mytabletext' : ''"
                    @click="this.mypowerlimits.jqkz == true ? routeraddrClick('/sharedvccontrol', scope.row) : ''">
                    <span>{{ scope.row.client }}</span>
                  </div>
                </div>
              </template>
            </el-table-column>
          </el-table>
          <!--分页器-->
          <div class="block" style="text-align: center; margin: 30px 0">
            <el-pagination background @size-change="handleSizeChange" @current-change="handleCurrentChange"
              :current-page="currentPage" :page-sizes="pageSizes" :page-size="PageSize"
              layout="total, sizes, prev, pager, next, jumper" :total="totalCount">
            </el-pagination>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import mainone from "@/components/Mainone";
import maintwo from "@/components/Maintwo";
import mainimgesup from "@/components/Mainimgesup.vue";
import {  ElNotification } from "element-plus";
import axios from "axios";
import { defineComponent, ref } from "vue";

export default defineComponent({
  name: "vsharedvcback",
  data() {
    return {
      mainonebread: [
        {
          name: "打印",
        },
        {
          name: "共享打印",
        },
        {
          name: "设备退货记录",
          path: "/sharedvcback",
        },
      ], // 当前页-【面包屑导航】
      maintwoiconshow: true, // 筛选-【高级筛选按钮】
      maintwoforminfo: {
        myinputoneisshow: true, //筛选查询-左侧字+input
        myinputone: {
          divinterval: "0", //框右侧距离
          titlewidth: "74px", //标题宽度
          inputwidth: "200px", //input宽度
          inputinterval: "20px", //input右侧距离
          input: [
            {
              ismust: false, //是否必填，true表示必填
              title: "设备编号：",
              placeholder: "请输入设备编号",
              name: "client",
              content: "",
            },
          ],
        },
        // myinputonefullisshow: true, //筛选查询-自动建议下拉框
        // myinputonefull: {
        //   divinterval: "0", //框右侧距离
        //   titlewidth: "74px", //标题宽度
        //   inputwidth: "200px", //input宽度
        //   inputinterval: "20px", //input右侧距离
        //   input: [
        //     {
        //       ismust: false, //是否必填，true表示必填
        //       title: "设备编号：",
        //       placeholder: "请输入设备编号", //此name为键值
        //       name: "client",
        //       content: "",
        //       id: "", //此id为给后台的id值
        //       url: "/manage/Equipment/searchClient",
        //       posttype: 'get',
        //       inputval: { client: "" }, //这里记录需要传的参数
        //     },
        //   ],
        // },
        selectleftisshow: true, //筛选查询-左侧字+select单框||select时间
        selectleft: {
          width: "none",
          divinterval: "", //框右侧距离
          selectwidth: "200px",
          selectinterval: "20px", //select右侧距离
          select: [
            {
              selectisshow: true,
              ismust: false,
              title: "公司：",
              placeholder: "全部",
              name: "companyid",
              value: "",
              options: [
                {
                  value: "0",
                  label: "全部",
                },
                {
                  value: "1",
                  label: "公司01",
                },
                {
                  value: "2",
                  label: "公司02",
                },
              ],
            },
            {
              selecttimeduanisshow: true, //该字段表示时间段模块状态：且已写死（字段名不可修改!）
              ismust: false,
              title: "退货时间：",
              placeholder: {
                placeholderstart: "请选择开始时间",
                placeholderend: "请选择结束时间",
              },
              name: "addtime",
              value: {
                valuestart: ref(""),
                valueend: ref(""),
                endstatus: true,
              },
              rangeseparator: "至", //分隔符
              divinterval: "3%", //上方字中-框右侧距离
              selectwidth: "100%", //上方字中-总框长度
              selectmaxwidth: "97%", //时间段-最大宽度
              marginright: "0", //与右侧距离
            },
          ],
        },
      }, // 筛选-控件信息
      mainthreeisshowbut: false, // 数据列表中-【添加按钮】
      mainthreeisshowset: false, // 数据列表中-复选框
      mainthreeisshowope: false, // 数据列表中-操作-根据页面显示模块（整页）
      mainthreetableope: 1, // 数据列表中-操作-根据页面显示控件（整页）
      mainthreehandle: 150, // 数据列表中-操作-宽度
      mainthreetabletitle: [
        {
          fixedstatu: "left",
          prop: "client",
          label: "设备编号",
          width: "120",
        },
        {
          fixedstatu: false,
          prop: "shop_code_name",
          label: "代理商家",
          width: "200",
        },
        {
          fixedstatu: false,
          prop: "prov",
          label: "省级",
          width: "",
        },
        {
          fixedstatu: false,
          prop: "city",
          label: "市级",
          width: "",
        },
        {
          fixedstatu: false,
          prop: "area",
          label: "县/区级",
          width: "",
        },
        {
          fixedstatu: false,
          prop: "admin_id_name",
          label: "操作人",
          width: "",
        },
        {
          fixedstatu: false,
          prop: "addtime",
          label: "退货时间",
          width: "200",
        },
        {
          fixedstatu: false,
          prop: "putintime",
          label: "投放时间",
          width: "200",
        },
        {
          fixedstatu: false,
          prop: "installtime",
          label: "安装时间",
          width: "200",
        },
        {
          fixedstatu: false,
          prop: "remark",
          label: "备注",
          width: "",
        },
      ], // 数据列表中-数据表头
      mainthreestatus: true, // 数据列表中-属于search按钮表示状态
      mainthreetableData: [], // 数据列表中-具体数据
      totalCount: 1, //数据列表中-数据总条数
      lastpage: 1, //数据列表中-数据总页数（默认以20条一页）

      //高级筛选
      myinputishow: true, //高级筛选-input单框
      myinput: {
        divinterval: "3%", //框右侧距离
        inputwidth: "47%",
        inputmaxwidth: "100%",
        input: [
          {
            ismust: false, //是否必填，true表示必填
            title: "设备编号：",
            placeholder: "请输入设备编号",
            name: "client",
            content: "",
          },
          {
            ismust: false, //是否必填，true表示必填
            title: "代理商名称：",
            placeholder: "请输入代理商名",
            name: "shop_code",
            content: "",
          },
        ],
      },
      // myinputonefullgaoisshow: true, //高级筛选-自动建议下拉框
      // myinputonefullgao: {
      //   divinterval: "3%", //框右侧距离
      //   inputwidth: "47%", //input宽度
      //   inputmaxwidth: "100%",
      //   input: [
      //     {
      //       ismust: false, //是否必填，true表示必填
      //       title: "设备编号：",
      //       placeholder: "请输入设备编号", //此name为键值
      //       name: "client",
      //       content: "",
      //       id: "", //此id为给后台的id值
      //       url: "/manage/Equipment/searchClient",
      //       posttype: 'get',
      //       inputval: { client: "" }, //这里记录需要传的参数
      //     },
      //   ],
      // },
      myselectisshow: true, //高级筛选-select单框
      myselect: {
        divinterval: "3%", //框右侧距离
        selectwidth: "47%",
        selectmaxwidth: "100%",
        select: [
          {
            selectisshow: true,
            ismust: false,
            title: "公司：",
            placeholder: "全部",
            name: "companyid",
            value: "",
            options: [
              {
                value: "0",
                label: "全部",
              },
              {
                value: "1",
                label: "公司01",
              },
              {
                value: "2",
                label: "公司02",
              },
            ],
          },
          {
            selecttimeduanisshow: true, //该字段表示时间段模块状态：且已写死（字段名不可修改!）
            ismust: false,
            title: "退货时间区间范围：",
            placeholder: {
              placeholderstart: "请选择开始时间",
              placeholderend: "请选择结束时间",
            },
            name: "addtime",
            value: {
              valuestart: ref(""),
              valueend: ref(""),
              endstatus: true,
            },
            rangeseparator: "至", //分隔符
            divinterval: "3%", //上方字中-框右侧距离
            selectwidth: "100%", //上方字中-总框长度
            selectmaxwidth: "97%", //时间段-最大宽度
            marginright: "0", //与右侧距离
          },
          {
            selecttimeduanisshow: true, //该字段表示时间段模块状态：且已写死（字段名不可修改!）
            ismust: false,
            title: "投放时间区间范围：",
            placeholder: {
              placeholderstart: "请选择开始时间",
              placeholderend: "请选择结束时间",
            },
            name: "putintime",
            value: {
              valuestart: ref(""),
              valueend: ref(""),
              endstatus: true,
            },
            rangeseparator: "至", //分隔符
            divinterval: "3%", //上方字中-框右侧距离
            selectwidth: "100%", //上方字中-总框长度
            selectmaxwidth: "97%", //时间段-最大宽度
            marginright: "0", //与右侧距离
          },
          {
            selecttimeduanisshow: true, //该字段表示时间段模块状态：且已写死（字段名不可修改!）
            ismust: false,
            title: "安装时间区间范围：",
            placeholder: {
              placeholderstart: "请选择开始时间",
              placeholderend: "请选择结束时间",
            },
            name: "installtime",
            value: {
              valuestart: ref(""),
              valueend: ref(""),
              endstatus: true,
            },
            rangeseparator: "至", //分隔符
            divinterval: "3%", //上方字中-框右侧距离
            selectwidth: "100%", //上方字中-总框长度
            selectmaxwidth: "97%", //时间段-最大宽度
            marginright: "0", //与右侧距离
          },
        ],
      },
      myselectcityisshow: true, //高级筛选-selectcity选框
      myselectcity: {
        selectwidth: "100%",
        select: {
          ismust: false,
          name: "area_code",
          valuesheng: "",
          valueshi: "",
          valuequ: "",
          disshi: true,
          disqu: true,
        },
      },

      //上传-【Excel批量退货】-imgesuploade上传图片
      myimgesupisshow: true,
      //上传-【Excel批量退货】-Excel批量退货-文件
      myimgesup: {
        uploadsize: 2, //选择上传图片的展现框-1表示小框，2表示中等框，3表示大框
        listtype: "picture", //list展示类型，text/picture/picture-card；默认不填为text
        showfilelist: false, //list展示列表是否显示，显示true，隐藏false
        ismust: true, //是否必填，true表示必填
        action: "/manage/Equipment/uploadTemplate", //upload上传API地址
        headers: { Auth: localStorage.getItem("token") }, //upload上传headers
        multiple: false, //上传多个文件
        title: "Excel批量退货", //button-按钮文字
        title_type: "primary", //button-按钮显示样式,primary&text
        title_size: "medium", //button-按钮大小，medium / small / mini
        title_plain: true, //button-按钮是否为线性（plain）
        isshow: false, //button-按钮是否显示，注意：false表示显示，true表示不显示
        tips: "", //提示&建议文字
        limit: "", //上传数量限制
        target_id: 100, //上传文件target_id ：图片分类，区分存储文件和存储时长，不传默认存储1天后过期；1-二维码； 2-打印文件； 3-文库文件； 4-课程文件； 5-广告主宣传页； 6-广告主产生的pdf文件； 7-证件照文件； 50-设备广告； 100-通用；
        rules: {
          files: {
            size: 10000, //文件大小（单位kb）
            type: [
              "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
            ], //文件格式
            typechart: "Excel", //文件格式提示规则
          },
        },
      },

      //table表单相关数据
      seniorform: {},
      currentPage: 1,
      pageSizes: [20, 50, 100, 1000],
      PageSize: 20,
      multipleSelection: [],
      thispagename: "", //通过路由获取当前页面的path值
      searchwidth: "580px",
      tancontent: {
        title: "弹框标题",
        content: {},
      }, //弹框

      mypowerlimits: JSON.parse(localStorage.getItem("powerlimits")), //获取权限
      mytoken: localStorage.getItem("token"), //获取存储的token
      pagesdatas: [], //存储最近一次的筛选条件，供分页使用
    };
  },
  mounted() {
    //获取当前页面路由path值
    this.thispagename = this.$route.path;
  },
  methods: {
    // 【下载批量退货模板】
    changeDomeEvent() {
      axios
        .get("/manage/Equipment/downTemplate", {
          headers: {
            Auth: localStorage.getItem("token"),
          },
          params: {
            templateName: "batch_return_template",
          },
        })
        .then((response) => {
          if (response.data.code == 0) {
            let url = response.data.result.download;
            window.open(url, "_blank");
          }
        })
        .catch(function (error) {
          console.log(error);
        });
    },
    // 【Excel批量退货】-获取upload子组件回传的数据-file文件url等数据
    getUploadFileEvent(data) {
      console.log("当前获取的回传数据：" + JSON.stringify(data));
      let messages = '';
      if (data.msg) {
        messages = '<div><div>导入条数：' + data.total + '</div><div>成功条数：' + data.succ + '</div><div>失败条数：' + data.fail + '</div><div>失败原因：' + data.msg + '</div></div>'
      } else {
        messages = '<div><div>导入条数：' + data.total + '</div><div>成功条数：' + data.succ + '</div><div>失败条数：' + data.fail + '</div></div>'
      }
      // 通知
      ElNotification({
        title: 'Excel批量退货-反馈',
        dangerouslyUseHTMLString: true,
        duration: 8000,
        message: messages,
        type: 'success',
      })
      // 删除upload列队中的数据
      this.$refs.uploadfile.handleRemove(data)
    },

    //table表单-点击单位格-带参跳转等
    handleOneData(row, column, cell, event) {
      // console.log(row); //获取当前行数据
      // console.log(column); //获取当前单位格信息 ，通过column.property可获取当前单元的title得name值
      // console.log(event); //获取当前指针事件
      console.log(event.path[1].id); //获取当前指针所指向的单元格id值，配合标签的id使用
    },
    //table表-操作中-删除按钮（函数）
    deleteRow(index, rows) {
      //在table表中删除当前行信息，而rows.splice(index, 1)仅属于前端删除，可配合后具体操作
      rows.splice(index, 1);
    },
    //table表中-当选项发生变化是触发该函数
    handleSelectionChange(val) {
      console.log("this is:" + val);
      this.multipleSelection = val;
    },
    //分页器中-当选择条数时触发该函数
    handleSizeChange(val) {
      console.log(`每页 ${val} 条`);
      this.PageSize = val;
      this.currentPage = 1;

      //加载loading
      this.loading = true;
      //通过Ajax向后台获取数据
      axios
        .get("/manage/Equipment/rejectedRecord", {
          headers: {
            Auth: localStorage.getItem("token"),
          },
          params: {
            client: this.pagesdatas.client,
            shop_code: this.pagesdatas.shop_code,
            addtime: this.pagesdatas.addtime,
            putintime: this.pagesdatas.putintime,
            installtime: this.pagesdatas.installtime,
            companyid: this.pagesdatas.companyid,
            area_code: this.pagesdatas.area_code,
            page: 1,
            page_size: val,
          },
        })
        .then((response) => {
          this.myaxiosfunc(response.data.result);
        })
        .catch(function (error) {
          // 请求失败处理
          console.log(error);
        });
    },
    //分页器中-当选择分页时触发该函数
    handleCurrentChange(val) {
      console.log(`当前页: 第${val}页`);
      this.currentPage = val;

      //加载loading
      this.loading = true;
      //通过Ajax向后台获取数据
      axios
        .get("/manage/Equipment/rejectedRecord", {
          headers: {
            Auth: localStorage.getItem("token"),
          },
          params: {
            client: this.pagesdatas.client,
            shop_code: this.pagesdatas.shop_code,
            addtime: this.pagesdatas.addtime,
            putintime: this.pagesdatas.putintime,
            installtime: this.pagesdatas.installtime,
            companyid: this.pagesdatas.companyid,
            area_code: this.pagesdatas.area_code,
            page: val,
            page_size: this.PageSize,
          },
        })
        .then((response) => {
          this.myaxiosfunc(response.data.result);
        })
        .catch(function (error) {
          // 请求失败处理
          console.log(error);
        });
    },
    //table表中-表格带状态-可扩展
    tableRowClassName({ rowIndex }) {
      //隔行显示
      if ((rowIndex + 1) % 2 === 0) {
        return "warning-row";
      }
      return "";
    },
    //点击按钮-弹出弹框并赋值弹窗基本配置
    addTanClick(taninfo) {
      console.log(taninfo);
      this.tancontent.content = taninfo.content;
      this.tancontent.title = taninfo.title;
    },
    //点击进行路由页面跳转以及携带参数
    routeraddrClick(routeraddr, rowdata) {
      if (rowdata) {
        // 携带参数跳转
        if (routeraddr == "/sharedvccontrol") {
          this.$router
            .push({
              path: routeraddr,
              query: { client: rowdata.client },
            })
            .catch((error) => error);
        } else {
          this.$router
            .push({
              path: routeraddr,
              query: { id: rowdata.id },
            })
            .catch((error) => error);
        }
      } else {
        //仅跳转
        this.$router.push(routeraddr).catch((error) => error);
      }
    },
    //导出数据
    exportDatas() {
      axios
        .get("/manage/Equipment/rejectedRecord", {
          headers: {
            Auth: localStorage.getItem("token"),
          },
          params: {
            client: this.pagesdatas.client,
            shop_code: this.pagesdatas.shop_code,
            addtime: this.pagesdatas.addtime,
            putintime: this.pagesdatas.putintime,
            installtime: this.pagesdatas.installtime,
            companyid: this.pagesdatas.companyid,
            area_code: this.pagesdatas.area_code,
            export: 1,
          },
        })
        .then((response) => {
          console.log(response.data.message);
        //   ElMessage({ type: "success", message: response.data.message });
        })
        .catch(function (error) {
          // 请求失败处理
          console.log(error);
        });
    },

    //获取当前windows页面信息-实时修改【弹窗】的宽度
    getwindowinfo() {
      this.sreenheight = document.documentElement.clientHeight;
      this.sreenwidth = document.documentElement.clientWidth;
      if (this.sreenwidth < 600) {
        this.searchwidth = this.sreenwidth * 0.8 + "px";
      } else {
        this.searchwidth = "580px";
      }
    },
    //提交等相关event事件函数
    //获取Container-Pagedata-Maintwo-高级筛选-开始检索返回的数据
    searchFormEvent(data) {
      console.log("高级筛选-开始检索返回数据(页面):" + JSON.stringify(data));
      //加载loading
      this.pagesdatas = data; //将筛选条件赋值给本页的pagesdatas存储，供分页使用
      //加载loading
      this.loading = true;
      //判断-退货-时间段状态
      var getaddtime = null;
      if (data.addtime.start == "") {
        getaddtime = "";
      } else {
        if (data.addtime.end == "") {
          getaddtime = data.addtime.start + " ~ " + (data.addtime.start + 86400 - 1);
        } else {
          getaddtime = data.addtime.start + " ~ " + (data.addtime.end + 86400 - 1);
        }
      }
      this.pagesdatas.addtime = getaddtime;
      //判断-投放-时间段状态
      var getputintime = null;
      if (data.putintime.start == "") {
        getputintime = "";
      } else {
        if (data.putintime.end == "") {
          getputintime = data.putintime.start + " ~ " + (data.putintime.start + 86400 - 1);
        } else {
          getputintime = data.putintime.start + " ~ " + (data.putintime.end + 86400 - 1);
        }
      }
      this.pagesdatas.putintime = getputintime;
      //判断-安装-时间段状态
      var getinstalltime = null;
      if (data.installtime.start == "") {
        getinstalltime = "";
      } else {
        if (data.installtime.end == "") {
          getinstalltime =
            data.installtime.start + " ~ " + (data.installtime.start + 86400 - 1);
        } else {
          getinstalltime =
            data.installtime.start + " ~ " + (data.installtime.end + 86400 - 1);
        }
      }
      //通过Ajax向后台获取数据
      axios
        .get("/manage/Equipment/rejectedRecord", {
          headers: {
            Auth: localStorage.getItem("token"),
          },
          params: {
            client: data.client,
            shop_code: data.shop_code,
            addtime: data.getaddtime,
            putintime: getputintime,
            installtime: getinstalltime,
            companyid: data.companyid,
            area_code: data.area_code,
            page_size: this.PageSize,
          },
        })
        .then((response) => {
          this.myaxiosfunc(response.data.result);
        })
        .catch(function (error) {
          // 请求失败处理
          console.log(error);
        });
    },
    //获取Container-Pagedata-Maintwo-搜索-返回的数据
    getSeachinfoEvent(data) {
      console.log("点击查询获取的data数组：" + JSON.stringify(data));
      this.pagesdatas = data; //将筛选条件赋值给本页的pagesdatas存储，供分页使用
      //加载loading
      this.loading = true;
      //判断-退货-时间段状态
      var getaddtime = null;
      if (data.addtime.start == "") {
        getaddtime = "";
      } else {
        if (data.addtime.end == "") {
          getaddtime = data.addtime.start + " ~ " + (data.addtime.start + 86400 - 1);
        } else {
          getaddtime = data.addtime.start + " ~ " + (data.addtime.end + 86400 - 1);
        }
      }
      this.pagesdatas.addtime = getaddtime;
      //通过Ajax向后台获取数据
      axios
        .get("/manage/Equipment/rejectedRecord", {
          headers: {
            Auth: localStorage.getItem("token"),
          },
          params: {
            client: data.client,
            companyid: data.companyid,
            addtime: getaddtime,
            page_size: this.PageSize,
          },
        })
        .then((response) => {
          this.myaxiosfunc(response.data.result);
        })
        .catch(function (error) {
          // 请求失败处理
          console.log(error);
        });
    },
    //数据渲染
    myaxiosfunc(successdata) {
      var newdata = successdata.data;
      for (var i = 0; i < newdata.length; i++) {
        //转换退货时间
        if (newdata[i].addtime === 0 || newdata[i].addtime === null) {
          newdata[i].addtime = "无退货时间";
        } else {
          newdata[i].addtime = this.$utils.formatDate(newdata[i].addtime).substr(0, 10);
        }
        //转换投放时间
        if (newdata[i].putintime === 0 || newdata[i].putintime === null) {
          newdata[i].putintime = "无投放时间";
        } else {
          newdata[i].putintime = this.$utils.formatDate(newdata[i].putintime).substr(0, 10);
        }
        //转换安装时间
        if (newdata[i].installtime === 0 || newdata[i].installtime === null) {
          newdata[i].installtime = "无安装时间";
        } else {
          newdata[i].installtime = this.$utils.formatDate(
            newdata[i].installtime
          ).substr(0, 10);
        }
      }
      // console.log('当前页面的data:' + JSON.stringify(newdata))
      this.mainthreetableData = newdata;
      this.totalCount = successdata.total;
      this.currentpage = successdata.current_page; //当前页面页数
      this.lastpage = successdata.last_page;
      this.loading = false;
    },
    // 获取-公司-下拉信息
    getCompany(configinfo) {
      //获取api-加载类型信息（根据API返回的json数据结构不同，函数方法不同！）
      var alldatas = Array();
      var num = 0;
      alldatas[num] = { value: num, label: "全部" };
      for (var key in configinfo) {
        num = num + 1;
        alldatas[num] = {
          value: configinfo[key].id,
          label: configinfo[key].name,
        };
      }
      //这里的select[0],是写死的，后续可优化！注意写死的状况下，select的位置不可移动
      this.maintwoforminfo.selectleft.select[0].options = alldatas;
      this.myselect.select[0].options = alldatas;
    },
  },
  created() {
    if (localStorage.getItem("token") === null || !localStorage.getItem("token")) {
      this.isshow = false;
      this.$router.push("/").catch((error) => error);
    } else {
      window.addEventListener("resize", this.getwindowinfo); // 注册监听器-实时检测浏览器页面宽高
      this.getwindowinfo();

      // 公司-下拉-数据
      axios
        .get("/manage/Equipment/getComSelect", {
          headers: {
            Auth: localStorage.getItem("token"),
          },
          params: this.queryParam,
        })
        .then((response) => {
          console.log(response.data.result);
          this.getCompany(response.data.result);
        })
        .catch(function (error) {
          console.log(error);
        });

      //加载loading
      this.loading = true; //通过Ajax向后台获取数据
      //通过Ajax向后台获取数据
      axios
        .get("/manage/Equipment/rejectedRecord", {
          headers: {
            Auth: localStorage.getItem("token"),
          },
          params: {
            page_size: this.PageSize,
          },
        })
        .then((response) => {
          this.myaxiosfunc(response.data.result);
        })
        .catch(function (error) {
          console.log(error);
        });
    }
  },
  components: {
    mainone,
    maintwo,
    mainimgesup,
  },
});
</script>
<style scoped>
/*操作中-按钮样式*/
.el-button--mini {
  min-height: 28px;
  padding: 0 8px;
  font-size: 12px;
  border-radius: calc(var(--el-border-radius-base) - 1px);
}

.mytabletext {
  color: var(--el-color-primary);
  cursor: pointer;
}

.mybutton_box {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.mybutton_box_btn :deep(.el-button) {
  padding: 8px 20px;
  height: 36px;
  min-height: 36px;
}
</style>