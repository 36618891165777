<template>
    <div>
        <mainone :mainonebread="mainonebread"></mainone>
        <dpwCard title="详情">
            <template #content>
                <dpwTitle title="详情信息"></dpwTitle>
                <dpwDetails :datas="data_options"></dpwDetails>
                <el-divider />
                <dpwTitle title="验收详情"></dpwTitle>
                <div style="margin-bottom: 10px">
                    <el-table :header-cell-style="{ background: '#F5F7FA' }" style="width: 100%" min-height="900" border :row-style="tableRowStyleEvent" :data="ruleForm.content">
                        <el-table-column label="周期" min-width="140">
                            <template #default="scope">
                                <div>
                                    第&nbsp;&nbsp;
                                    <span style="font-size: 16px; font-weight: bold">{{ scope.$index + 1 }}</span>
                                    &nbsp;&nbsp;周期
                                </div>
                            </template>
                        </el-table-column>
                        <el-table-column prop="all_price_chack_ratio" label="总价款还款比例(%)" min-width="180" />
                        <el-table-column label="名称" min-width="180">
                            <template #default="scope">
                                <div v-for="(item, index) in scope.row.months" :key="index">
                                    <div class="line-box-hang" :class="index != scope.row.months.length - 1 ? 'line-box-boder' : ''">
                                        第&nbsp;&nbsp;
                                        <span style="font-size: 16px; font-weight: bold">{{ index + 1 }}</span>
                                        &nbsp;&nbsp;个月
                                    </div>
                                </div>
                            </template>
                        </el-table-column>
                        <el-table-column label="月份" min-width="240">
                            <template #default="scope">
                                <div v-for="(item, index) in scope.row.months" :key="index">
                                    <div class="line-box-hang" :class="index != scope.row.months.length - 1 ? 'line-box-boder' : ''">
                                        <span>{{ item.month }}</span>
                                    </div>
                                </div>
                            </template>
                        </el-table-column>
                        <el-table-column label="月还款比例(%)" min-width="160" v-if="isshow">
                            <template #default="scope">
                                <div v-for="(item, index) in scope.row.months" :key="index">
                                    <div class="line-box-hang" :class="index != scope.row.months.length - 1 ? 'line-box-boder' : ''">
                                        <span>{{ item.month_payback_ratio }}</span>
                                    </div>
                                </div>
                            </template>
                        </el-table-column>
                        <el-table-column label="日阶段比例(%)" min-width="160"  v-if="isshow">
                            <template #default="scope">
                                <div v-for="(item, index) in scope.row.months" :key="index">
                                    <div :class="index != scope.row.months.length - 1 ? 'line-box-boder' : ''">
                                        <div class="line-box-hang-h">
                                            <span>1～20天(含)：</span><span>{{ item.day_payback_ratio[0] }}</span>
                                        </div>
                                        <div class="line-box-hang-h">
                                            <span>21～25天(含)：</span><span>{{ item.day_payback_ratio[1] }}</span>
                                        </div>
                                        <div class="line-box-hang-h">
                                            <span>25天后：</span><span>{{ item.day_payback_ratio[2] }}</span>
                                        </div>
                                    </div>
                                </div>
                            </template>
                        </el-table-column>
                    </el-table>
                </div>
                <div class="other-box">
                    <span>预付款比例(%)：</span><span>{{ this.advance_ratio }}</span>
                </div>
                <div class="other-box"><span>合计比例(%)：</span><span>100</span></div>
            </template>
        </dpwCard>
    </div>
</template>

<script>
import mainone from '@/components/Mainone'
import dpwDetails from '../../acommon/dpw-details.vue'
import dpwCard from '../../acommon/dpw-card.vue'
import dpwTitle from '../../acommon/dpw-title.vue'
import axios from 'axios'
import dict from '../../js/dict.js'
import { ElMessage } from 'element-plus'

export default {
    name: 'vbatchmanageinfo',
    props: {
        mainonebread: {
            default: [
                {
                    name: '风控'
                },
                {
                    name: '风控管理'
                },
                {
                    name: '批次管理'
                },
                {
                    name: '详情'
                }
            ]
        },
        isshow: {
            typeof: Boolean,
            default: true
        }
    },
    data() {
        return {
            // mainonebread: [
            //     {
            //         name: '风控'
            //     },
            //     {
            //         name: '风控管理'
            //     },
            //     {
            //         name: '批次管理'
            //     },
            //     {
            //         name: '详情'
            //     }
            // ], // 当前页-【面包屑导航】

            data_options: [],
            // 预付款比例
            advance_ratio: '',
            // 还款状态
            repay_status_options: dict.batchStatusOptions,

            ruleForm: {
                id: '',
                addtime: '',
                content: []
            },
            rules: {
                addtime: [
                    {
                        required: true,
                        message: '验收时间为必选项',
                        trigger: 'blur'
                    }
                ],
                content: [
                    {
                        required: true,
                        message: '周期为不能为空',
                        trigger: 'blur'
                    }
                ]
            }
        }
    },
    methods: {
        // table 列表-行状态表格
        tableRowStyleEvent({ rowIndex }) {
            if (rowIndex % 2 == 0) {
                return { 'background-color': '#67C23A09' }
            } else {
                return { 'background-color': '#409EFF09' }
            }
            return {}
        },
        // 获取详情信息
        getInfoEvent(data) {
            axios
                .get('/manage/RiskControlAdvance/riskEditInfo', {
                    headers: {
                        Auth: localStorage.getItem('token')
                    },
                    params: data
                })
                .then((response) => {
                    let getdatas = response.data.result

                    this.data_options = [
                        { title: '批次名称', value: getdatas.name },
                        { title: '结算单价(元)', value: (getdatas.price / 100).toFixed(3) },
                        { title: '总数量', value: getdatas.num },
                        { title: '总价款(元)', value: (getdatas.total / 100).toFixed(3) },
                        { title: '预付款(元)', value: (getdatas.advance / 100).toFixed(3) },
                        { title: '余额(元)', value: (getdatas.residue / 100).toFixed(3) },
                        {
                            title: '状态',
                            value: getdatas.status ? this.repay_status_options.filter((n) => n.id == getdatas.status)[0]['label'] : '',
                            color: getdatas.status ? this.repay_status_options.filter((n) => n.id == getdatas.status)[0]['color'] : ''
                        },
                        { title: '验收时间', value: getdatas.checktime != 0 ? this.$utils.formatDate(getdatas.checktime).substr(0, 10) : '' },
                        { title: '还款首日', value: getdatas.repay_first_day != 0 ? this.$utils.formatDate(getdatas.repay_first_day).substr(0, 10) : '' },
                        { title: '还款状态', value: getdatas.repay_status == 1 ? '开启' : '关闭' },
                        { title: '操作人', value: getdatas.adminid_name },
                        { title: '操作时间', value: getdatas.uptime != 0 ? this.$utils.formatDate(getdatas.uptime) : '' }
                    ]
                    // 预付款比例
                    this.advance_ratio = getdatas.advance_ratio

                    // 验收 form
                    this.ruleForm.content = getdatas.check_rule
                })
                .catch(function (error) {
                    // 请求失败处理
                    console.log(error)
                })
        }
    },
    created() {
        if (localStorage.getItem('token') === null || !localStorage.getItem('token')) {
            this.$router.push('/').catch((error) => error)
        } else {
            let routerInfo = this.$route.query
            this.getInfoEvent({ id: routerInfo.id })
        }
    },
    components: {
        mainone,
        dpwCard,
        dpwDetails,
        dpwTitle
    }
}
</script>
<style scoped>
.line-box-hang-h {
    line-height: 30px;
    height: 30px;
}
.line-box-hang {
    line-height: 90px;
    height: 90px;
}
.line-box-boder {
    border-bottom: 1px solid var(--el-border-color-lighter);
}
.other-box {
    line-height: 32px;
    height: 32px;
    font-size: 14px;
}
</style>
