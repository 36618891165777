<template>
  <div>
    <mainone :mainonebread="mainonebread"></mainone>
    <div>
      <!--标题-->
      <div class="box" style="">
        <!--内容-->
        <div class="box_content" id="switch">
          <!-- 远程控制 -->
          <div class="frp_box" v-if="frpisshow == 1">
            <div class="frp_box_title" >
              <div class="frp_box_title_info">远程链接地址：</div>
              <div class="frp_box_title_ip">{{this.ipdata}}</div>
            </div>
            <div class="frp_box_button">
              <el-button type="danger" size="small" @click="closeFrpEvent(this.id,1)">关闭远程链接</el-button>
            </div>
          </div>
          <div class="frp_box" v-if="frpisshow == 2">
            <div class="frp_box_title">
              <div class="frp_box_title_info">出错啦：</div>
              <div class="frp_box_title_info" style="color:#EE4738">{{this.ipdataerror}}</div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import mainone from "@/components/Mainone";
import { defineComponent } from "vue";
import { ElMessage } from "element-plus";
import axios from "axios";

export default defineComponent({
  name: "vsharedvccontrolfrp",
  inject: ["reload"], //刷新引用
  data() {
    return {
      mainonebread: [
        {
          name: "打印",
        },
        {
          name: "共享打印",
        },
        {
          name: "机器控制",
          path: "/sharedvccontrol",
        },
        {
          name: "编辑设备",
          path: "/sharedvccontroledit",
          parameter: {}, // 头部-点击传参方式
        },
        {
          name: "远程控制",
          path: "/sharedvccontrolfrp",
          
        },
      ], // 当前页-【面包屑导航】

      // ip地址+端口号
      ipdata: "",
      // ip报错信息
      ipdataerror:'',

      // 由路由-获取的id
      id:'',

      // 维护字段，判断是否显示
      frpisshow:1,

      mypowerlimits: JSON.parse(localStorage.getItem("powerlimits")), //获取权限
      mytoken: localStorage.getItem("token"), //获取存储的token[此页面中的该字段，可有可无，具体看该页面是否需要使用token]
      userinfo: JSON.parse(localStorage.getItem("userinfo")), //获取用户信息
    };
  },
  mounted() {},
  methods: {
    // 关闭链接
    closeFrpEvent(getid,close){
        axios
          .post(
            "/manage/Equipment/frp",
            {
              id: getid,
              isclose: close,
            },
            {
              headers: {
                Auth: localStorage.getItem("token"),
              },
            }
          )
          .then((response) => {
            if (response.data.code === 0) {
              ElMessage({
                  type: "success",
                  message: response.data.message,
                  duration: 1000,
                  onClose: () => {
                    // this.$router.go(-1); // 返回上一页
                    window.close(); // 关闭当前窗口
                  },
                });
            }
          })
          .catch(function (error) {
            console.log(error);
          });
    }
  },
  created() {
    if (localStorage.getItem("token") === null || !localStorage.getItem("token")) {
      this.isshow = false;
      this.$router.push("/").catch((error) => error);
    } else {
      // 存储传输id-用户返回路由-后退一步-传参
      this.mainonebread[3].parameter = { id: this.$route.query.id}
      
      this.id = this.$route.query.id;
      let getid = this.$route.query.id;
      if (getid) {
        axios
          .post(
            "/manage/Equipment/frp",
            {
              id: getid,
            },
            {
              headers: {
                Auth: localStorage.getItem("token"),
              },
            }
          )
          .then((response) => {
            if (response.data.code === 0) {
              this.frpisshow = 1
              this.ipdata =
                response.data.result.ip + ":" + response.data.result.port;
            } else {
              this.frpisshow = 2
              this.ipdataerror = response.data.message
            }
          })
          .catch(function (error) {
            console.log(error);
            this.frpisshow = 2
            this.ipdataerror = error
          });
      }
    }
  },
  components: {
    mainone,
  },
});
</script>
<style scoped>
.dialog-box-flex {
  display: flex;
  flex-direction: column;
  justify-self: center;
}
#price_css :deep(.el-table .cell) {
  line-height: 50px;
}
.box {
  background-color: #ffffff;
  border-radius: 4px;
  color: #333333;
  padding: 10px 20px;
}

.box_title {
  line-height: 40px;
  height: 40px;
}

.box_title_font {
  float: left;
  font-weight: 400;
  font-size: 14px;
  color: #333333;
}

.box_title_button {
  float: right;
  font-weight: 400;
  font-size: 14px;
  color: #94a3d2;
}

.box_title_button:hover {
  cursor: pointer;
}

/* 设备详情-远程控制 */
.frp_box {
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  padding: 5px 20px;
  border: 1px solid rgb(220, 223, 230);
  border-radius: 4px;
  align-items: center;
  justify-content: center;
}

.frp_box_title {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  margin: 50px;
  min-height: 50px;
  line-height: 50px;
}

.frp_box_title_info{
  font-size: 30px;
}
.frp_box_title_ip{
  font-size: 50px;
}
.frp_box_button{
  margin: 20px 0;
}
</style>