<template>
    <div>
        <mainone :mainonebread="mainonebread"></mainone>
        <div v-loading="loading" element-loading-text="正在飞速加载中...">
            <!--标题-->
            <div class="box" style="">
                <div class="box_content">
                    <el-tabs>
                        <!-- 小程序配置 -->
                        <el-tab-pane label="小程序配置">
                            <div>
                                <el-row style="margin-top: 20px">
                                    <el-col :span="24" :xs="24" :sm="24" :lg="5">
                                        <div style="width: 100%"></div>
                                    </el-col>
                                    <el-col :span="24" :xs="24" :sm="24" :lg="11">
                                        <el-form ref="ruleForm" :model="ruleForm" :rules="rules" label-width="180px" class="demo-ruleForm">
                                            <el-form-item label="支付宝appid：" prop="appid">
                                                <el-input v-model="ruleForm.appid" clearable></el-input>
                                            </el-form-item>
                                            <el-form-item label="AES密钥：" prop="encryptKey">
                                                <el-input v-model="ruleForm.encryptKey" clearable></el-input>
                                            </el-form-item>
                                            <el-form-item label="支付宝根证书：" prop="alipayRootCertPath">
                                                <el-input type="textarea" rows="5" v-model="ruleForm.alipayRootCertPath"></el-input>
                                            </el-form-item>
                                            <el-form-item label="支付宝公钥证书：" prop="alipayCertPath">
                                                <el-input type="textarea" rows="5" v-model="ruleForm.alipayCertPath"></el-input>
                                            </el-form-item>
                                            <el-form-item label="应用私钥：" prop="merchantPrivateKey">
                                                <el-input type="textarea" rows="5" v-model="ruleForm.merchantPrivateKey"></el-input>
                                            </el-form-item>
                                            <el-form-item label="应用公钥证书：" prop="merchantCertPath">
                                                <el-input type="textarea" rows="5" v-model="ruleForm.merchantCertPath"></el-input>
                                            </el-form-item>
                                            <el-form-item label="转账到支付宝账户：" prop="autoapply">
                                                <el-radio-group v-model="ruleForm.autoapply">
                                                    <el-radio label="1">开通</el-radio>
                                                    <el-radio label="2">不开通</el-radio>
                                                </el-radio-group>
                                            </el-form-item>
                                            <el-form-item label="转账线路：" prop="transfer_line">
                                                <el-radio-group v-model="ruleForm.transfer_line">
                                                    <el-radio label="3">支付宝支付</el-radio>
                                                    <el-radio label="8">易宝支付</el-radio>
                                                </el-radio-group>
                                            </el-form-item>
                                            <el-form-item label="银行卡转账线路：" prop="transfer_pank_line">
                                                <el-radio-group v-model="ruleForm.transfer_pank_line">
                                                    <el-radio label="3">支付宝支付</el-radio>
                                                    <el-radio label="8">易宝支付</el-radio>
                                                </el-radio-group>
                                            </el-form-item>
                                            <el-form-item label="收单线路：" prop="order_line">
                                                <el-radio-group v-model="ruleForm.order_line">
                                                    <el-radio label="3">支付宝支付</el-radio>
                                                    <el-radio label="8">易宝支付</el-radio>
                                                </el-radio-group>
                                            </el-form-item>
                                            <el-form-item label="打款配置：" prop="collect_type">
                                                <el-radio-group v-model="ruleForm.collect_type">
                                                    <el-radio label="1">通过京硕账户打款</el-radio>
                                                    <el-radio label="2">通过租户公司打款</el-radio>
                                                </el-radio-group>
                                            </el-form-item>
                                            <el-form-item>
                                                <el-button type="primary" @click="submitForm('ruleForm')" style="width: 40%; margin-right: 10px">确认 </el-button>
                                                <el-button @click="myresetForm">取消</el-button>
                                            </el-form-item>
                                        </el-form>
                                    </el-col>
                                    <el-col :span="24" :xs="24" :sm="24" :lg="8">
                                        <div style="width: 100%"></div>
                                    </el-col>
                                </el-row>
                            </div>
                        </el-tab-pane>
                        <!-- 生活号配置 -->
                        <el-tab-pane label="生活号配置">
                            <div>
                                <el-row style="margin-top: 20px">
                                    <el-col :span="24" :xs="24" :sm="24" :lg="5">
                                        <div style="width: 100%"></div>
                                    </el-col>
                                    <el-col :span="24" :xs="24" :sm="24" :lg="11">
                                        <el-form ref="ruleForm1" :model="ruleForm1" :rules="rules1" label-width="180px" class="demo-ruleForm">
                                            <el-form-item label="生活号appid：" prop="appid">
                                                <el-input v-model="ruleForm1.appid" clearable></el-input>
                                            </el-form-item>
                                            <el-form-item label="AES密钥：" prop="encryptKey">
                                                <el-input v-model="ruleForm1.encryptKey" clearable></el-input>
                                            </el-form-item>
                                            <el-form-item label="支付宝根证书：" prop="alipayRootCertPath">
                                                <el-input type="textarea" rows="5" v-model="ruleForm1.alipayRootCertPath"></el-input>
                                            </el-form-item>
                                            <el-form-item label="支付宝公钥证书：" prop="alipayCertPath">
                                                <el-input type="textarea" rows="5" v-model="ruleForm1.alipayCertPath"></el-input>
                                            </el-form-item>
                                            <el-form-item label="应用私钥：" prop="merchantPrivateKey">
                                                <el-input type="textarea" rows="5" v-model="ruleForm1.merchantPrivateKey"></el-input>
                                            </el-form-item>
                                            <el-form-item label="应用公钥证书：" prop="merchantCertPath">
                                                <el-input type="textarea" rows="5" v-model="ruleForm1.merchantCertPath"></el-input>
                                            </el-form-item>
                                            <!-- <el-form-item label="关注生活号回复内容：" prop="submsg" required="true">
                        <el-input type="textarea" rows="5" v-model="ruleForm1.submsg"></el-input>
                      </el-form-item> -->
                                            <el-form-item>
                                                <el-button type="primary" @click="submitForm1('ruleForm1')" style="width: 40%; margin-right: 10px">确认 </el-button>
                                                <el-button @click="myresetForm">取消</el-button>
                                            </el-form-item>
                                        </el-form>
                                    </el-col>

                                    <el-col :span="24" :xs="24" :sm="24" :lg="8">
                                        <div style="width: 100%"></div>
                                    </el-col>
                                </el-row>
                            </div>
                        </el-tab-pane>
                        <!-- 小程序消息模板配置 -->
                        <el-tab-pane label="小程序消息模板配置">
                            <div class="mini-model-setbox">
                                <div class="mini-model-setbox-addbtn">
                                    <el-button type="primary" style="min-width: 150px" @click="addChatEvent()" plain>新增 </el-button>
                                </div>
                                <div>
                                    <el-form
                                        ref="tableDatamini"
                                        :model="tableData_mini"
                                        :rules="ruleDatamini"
                                        class="demo-ruleForm"
                                        v-loading="loading"
                                        element-loading-text="正在飞速加载中..."
                                    >
                                        <el-form-item>
                                            <el-table :header-cell-style="{ background: '#F5F7FA' }" :data="tableData_mini" style="width: 100%" border>
                                                <!-- 字段名 -->
                                                <el-table-column label="字段名" min-width="180">
                                                    <template #default="scope">
                                                        <el-form-item :prop="scope.$index + '.chatname'" :rules="ruleDatamini.chatname" class="table-line-box">
                                                            <el-input v-model="scope.row.chatname" clearable />
                                                        </el-form-item>
                                                    </template>
                                                </el-table-column>
                                                <!-- 标题 -->
                                                <el-table-column label="标题" min-width="180">
                                                    <template #default="scope">
                                                        <el-form-item :prop="scope.$index + '.title'" :rules="ruleDatamini.title" class="table-line-box">
                                                            <el-input v-model="scope.row.title" clearable />
                                                        </el-form-item>
                                                    </template>
                                                </el-table-column>
                                                <!-- 模板ID -->
                                                <el-table-column label="模板ID" min-width="180">
                                                    <template #default="scope">
                                                        <el-form-item :prop="scope.$index + '.modelid'" :rules="ruleDatamini.modelid" class="table-line-box">
                                                            <el-input v-model="scope.row.modelid" clearable />
                                                        </el-form-item>
                                                    </template>
                                                </el-table-column>
                                                <!-- 操作 -->
                                                <!-- <el-table-column prop="caozuo" label="操作">
                          <template #default="scope">
                            <el-button type="text" v-if="scope.row.addisshow" @click="addChatEvent()">增加
                            </el-button>
                            <el-button type="text" v-if="!scope.row.addisshow"
                              @click="delChatEvent(scope.$index, tableData_mini)">删除</el-button>
                          </template>
                        </el-table-column> -->
                                            </el-table>
                                        </el-form-item>
                                        <el-form-item style="text-align: center">
                                            <el-button type="primary" @click="submitFormMini('tableDatamini')" style="width: 150px"> 保存</el-button>
                                            <el-button @click="myresetForm">取消</el-button>
                                        </el-form-item>
                                    </el-form>
                                </div>
                            </div>

                            <!-- 
              <el-button @click="addItem('1')" style="margin-left:300px">新增 </el-button>
              <form id="form3">
                <table id="table1">
                  <th>
                  <td style="width:100%;">字段名</td>
                  <td style="width:100%;"> 标题</td>
                  <td style="width:100%;">模板ID</td>
                  </th>

                  <tr v-for="(item, key) in this.result1" :key="key">
                    <td style="wdith:100%"><input type="text" style="width:100%;height:30px" :value="key"
                        name="keyname" /></td>
                    <td style="wdith:100%"><input type="text" style="width:100%;height:30px" :value="item['desc']"
                        name="desc" /></td>
                    <td style="wdith:100%"><input type="text" style="width:100%;height:30px"
                        :value="item['template_id']" name="template_id" /></td>
                  </tr>
                </table>
              </form>
              <el-button @click="submitForm3()" style="margin-left:300px">提交 </el-button> -->
                        </el-tab-pane>
                        <!-- 生活号消息模板配置 -->
                        <el-tab-pane label="生活号消息模板配置">
                            <div class="mini-model-setbox">
                                <div class="mini-model-setbox-addbtn">
                                    <el-button type="primary" style="min-width: 150px" @click="addPublicChatEvent()" plain>新增 </el-button>
                                </div>
                                <div>
                                    <el-form
                                        ref="tableDatapublic"
                                        :model="tableData_public"
                                        :rules="ruleDatapublic"
                                        class="demo-ruleForm"
                                        v-loading="loading"
                                        element-loading-text="正在飞速加载中..."
                                    >
                                        <el-form-item>
                                            <el-table :header-cell-style="{ background: '#F5F7FA' }" :data="tableData_public" style="width: 100%" border>
                                                <!-- 字段名 -->
                                                <el-table-column label="字段名" min-width="180">
                                                    <template #default="scope">
                                                        <el-form-item :prop="scope.$index + '.chatname'" :rules="ruleDatapublic.chatname" class="table-line-box">
                                                            <el-input v-model="scope.row.chatname" clearable />
                                                        </el-form-item>
                                                    </template>
                                                </el-table-column>
                                                <!-- 标题 -->
                                                <el-table-column label="标题" min-width="180">
                                                    <template #default="scope">
                                                        <el-form-item :prop="scope.$index + '.title'" :rules="ruleDatapublic.title" class="table-line-box">
                                                            <el-input v-model="scope.row.title" clearable />
                                                        </el-form-item>
                                                    </template>
                                                </el-table-column>
                                                <!-- 模板ID -->
                                                <el-table-column label="模板ID" min-width="180">
                                                    <template #default="scope">
                                                        <el-form-item :prop="scope.$index + '.modelid'" :rules="ruleDatapublic.modelid" class="table-line-box">
                                                            <el-input v-model="scope.row.modelid" clearable />
                                                        </el-form-item>
                                                    </template>
                                                </el-table-column>
                                                <!-- 操作 -->
                                                <!-- <el-table-column prop="caozuo" label="操作">
                          <template #default="scope">
                            <el-button type="text" v-if="scope.row.addisshow" @click="addChatEvent()">增加
                            </el-button>
                            <el-button type="text" v-if="!scope.row.addisshow"
                              @click="delPublicChatEvent(scope.$index, tableData_public)">删除</el-button>
                          </template>
                        </el-table-column> -->
                                            </el-table>
                                        </el-form-item>
                                        <el-form-item style="text-align: center">
                                            <el-button type="primary" @click="submitFormPublic('tableDatapublic')" style="width: 150px"> 保存</el-button>
                                            <el-button @click="myresetForm">取消</el-button>
                                        </el-form-item>
                                    </el-form>
                                </div>
                            </div>

                            <!-- <el-button @click="addItem('2')" style="margin-left:300px">新增 </el-button>
              <form id="form4">
                <table id="table2">
                  <th>
                  <td style="width: 100%;">字段名</td>
                  <td style="width: 100%;"> 标题</td>
                  <td style="width: 100%;">模板ID</td>
                  </th>
                  <tr v-for="(item, key) in this.result2" :key="key">
                    <td style="wdith:100%"><input type="text" style="width:100%;height:30px" :value="key"
                        name="keyname2" /></td>
                    <td style="wdith:100%"><input type="text" style="width:100%;height:30px" :value="item['desc']"
                        name="desc2" /></td>
                    <td style="wdith:100%"><input type="text" style="width:100%;height:30px"
                        :value="item['template_id']" name="template_id2" /></td>
                  </tr>
                </table>
              </form>
              <el-button @click="submitForm4()" style="margin-left:300px">提交 </el-button> -->
                        </el-tab-pane>

                        <!-- 易宝支付配置 -->
                        <el-tab-pane label="易宝支付配置">
                            <div>
                                <el-row style="margin-top: 20px">
                                    <el-col :span="24" :xs="24" :sm="24" :lg="5">
                                        <div style="width: 100%"></div>
                                    </el-col>
                                    <el-col :span="24" :xs="24" :sm="24" :lg="11">
                                        <el-form ref="ruleForm_yibao" :model="ruleForm_yibao" :rules="ruleForm_yibao_rules" label-width="180px" class="demo-ruleForm">
                                            <el-form-item label="Appid：" prop="appid" required="true">
                                                <el-input v-model="ruleForm_yibao.appid" clearable></el-input>
                                            </el-form-item>
                                            <el-form-item label="应用id：" prop="app_key" required="true">
                                                <el-input v-model="ruleForm_yibao.app_key" clearable></el-input>
                                            </el-form-item>
                                            <el-form-item label="易宝公钥：" prop="yop_public_key" required="true">
                                                <el-input type="textarea" rows="5" v-model="ruleForm_yibao.yop_public_key" clearable></el-input>
                                            </el-form-item>
                                            <el-form-item label="商户私钥：" prop="isv_private_key" required="true">
                                                <el-input type="textarea" rows="5" v-model="ruleForm_yibao.isv_private_key" clearable></el-input>
                                            </el-form-item>
                                            <el-form-item label="商户编号：" prop="parent_merchant_no" required="true">
                                                <el-input v-model="ruleForm_yibao.parent_merchant_no" clearable></el-input>
                                            </el-form-item>
                                            <el-form-item label="子商户编号：" prop="merchant_no" required="true">
                                                <el-input v-model="ruleForm_yibao.merchant_no" clearable></el-input>
                                            </el-form-item>
                                            <el-form-item>
                                                <el-button type="primary" @click="submitFormYibaoPay('ruleForm_yibao')" style="width: 40%; margin-right: 10px">确认 </el-button>
                                                <el-button @click="myresetForm">取消</el-button>
                                            </el-form-item>
                                        </el-form>
                                    </el-col>
                                    <el-col :span="24" :xs="24" :sm="24" :lg="8">
                                        <div style="width: 100%"></div>
                                    </el-col>
                                </el-row>
                            </div>
                        </el-tab-pane>

                       
                    </el-tabs>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import mainone from '@/components/Mainone'

import axios from 'axios'
import { ElMessage } from 'element-plus'
import { defineComponent, ref } from 'vue'

export default defineComponent({
    name: 'vcompanyaliset',
    data() {
        return {
            mainonebread: [
                {
                    name: '租户管理'
                },
                {
                    name: '租户管理'
                },
                {
                    name: '公司列表',
                    path: '/companylist'
                },
                {
                    name: '支付宝设置'
                }
            ], // 当前页-【面包屑导航】

            // 小程序配置-from表单
            ruleForm: {
                appid: '',
                merchantPrivateKey: '',
                alipayCertPath: '',
                alipayRootCertPath: '',
                merchantCertPath: '',
                encryptKey: '',
                autoapply: ref('1'),
                collect_type: ref('1'),
                transfer_line: ref('3'),
                transfer_pank_line: ref('3'),
                order_line: ref('3')
            },
            // 小程序配置-规则校验
            rules: {
                appid: [
                    {
                        required: true,
                        message: '支付宝appid为必填项',
                        trigger: 'blur'
                    }
                ],
                encryptKey: [
                    {
                        required: true,
                        message: 'AES密钥为必填项',
                        trigger: 'blur'
                    }
                ],
                alipayRootCertPath: [
                    {
                        required: true,
                        message: '支付宝根证书为必填项',
                        trigger: 'blur'
                    }
                ],
                alipayCertPath: [
                    {
                        required: true,
                        message: '支付宝公钥证书为必填项',
                        trigger: 'blur'
                    }
                ],
                merchantPrivateKey: [
                    {
                        required: true,
                        message: '应用私钥为必填项',
                        trigger: 'blur'
                    }
                ],
                merchantCertPath: [
                    {
                        required: true,
                        message: '应用公钥证书为必填项',
                        trigger: 'blur'
                    }
                ],
                autoapply: [
                    {
                        required: true,
                        message: '转账到支付宝账户为必选项',
                        trigger: 'change'
                    }
                ],
                collect_type: [
                    {
                        required: true,
                        message: '打款配置为必选项',
                        trigger: 'change'
                    }
                ],
                transfer_line: [
                    {
                        required: true,
                        message: '转账线路为必选项',
                        trigger: 'change'
                    }
                ],
                transfer_pank_line: [
                    {
                        required: true,
                        message: '银行卡转账线路为必选项',
                        trigger: 'change'
                    }
                ],
                order_line: [
                    {
                        required: true,
                        message: '收单线路为必选项',
                        trigger: 'change'
                    }
                ]
            },

            // 生活号配置-form表单
            ruleForm1: {
                appid: '',
                merchantPrivateKey: '',
                alipayCertPath: '',
                alipayRootCertPath: '',
                merchantCertPath: '',
                encryptKey: '',
                submsg: ''
            },
            // 生活号配置-规则校验
            rules1: {
                appid: [
                    {
                        required: true,
                        message: '生活号appid为必填项',
                        trigger: 'blur'
                    }
                ],
                encryptKey: [
                    {
                        required: true,
                        message: 'AES密钥为必填项',
                        trigger: 'blur'
                    }
                ],
                alipayRootCertPath: [
                    {
                        required: true,
                        message: '支付宝根证书为必填项',
                        trigger: 'blur'
                    }
                ],
                alipayCertPath: [
                    {
                        required: true,
                        message: '支付宝公钥证书为必填项',
                        trigger: 'blur'
                    }
                ],
                merchantPrivateKey: [
                    {
                        required: true,
                        message: '应用私钥为必填项',
                        trigger: 'blur'
                    }
                ],
                merchantCertPath: [
                    {
                        required: true,
                        message: '应用公钥证书为必填项',
                        trigger: 'blur'
                    }
                ],
                submsg: [
                    {
                        required: true,
                        message: '关注生活号回复内容为必填项',
                        trigger: 'blur'
                    }
                ]
            }, //form表单-必填规则

            // 小程序模板消息配置-formdata
            tableData_mini: [
                // {
                //   chatname: '',
                //   title: '',
                //   modelid: '',
                //   // addisshow: true
                // }
            ],
            // 小程序模板消息配置-校验
            ruleDatamini: {
                chatname: [
                    {
                        required: true,
                        message: '字段名为必填项',
                        trigger: 'blur'
                    }
                ],
                title: [
                    {
                        required: true,
                        message: '标题为必填项',
                        trigger: 'blur'
                    }
                ],
                modelid: [
                    {
                        required: true,
                        message: '模板ID为必填项',
                        trigger: 'blur'
                    }
                ]
            },

            // 公众号模板消息配置-formdata
            tableData_public: [],
            // 小程序模板消息配置-校验
            ruleDatapublic: {
                chatname: [
                    {
                        required: true,
                        message: '字段名为必填项',
                        trigger: 'blur'
                    }
                ],
                title: [
                    {
                        required: true,
                        message: '标题为必填项',
                        trigger: 'blur'
                    }
                ],
                modelid: [
                    {
                        required: true,
                        message: '模板ID为必填项',
                        trigger: 'blur'
                    }
                ]
            },

            // 易宝支付配置
            ruleForm_yibao: {
                appid: '',
                app_key: '',
                yop_public_key: '',
                isv_private_key: '',
                parent_merchant_no: '',
                merchant_no: ''
            },
            ruleForm_yibao_rules: {
                appid: [
                    {
                        required: true,
                        message: 'Appid为必填项',
                        trigger: 'blur'
                    }
                ],
                app_key: [
                    {
                        required: true,
                        message: '应用id为必填项',
                        trigger: 'blur'
                    }
                ],
                yop_public_key: [
                    {
                        required: true,
                        message: '易宝公钥为必填项',
                        trigger: 'blur'
                    }
                ],
                isv_private_key: [
                    {
                        required: true,
                        message: '商户私钥为必填项',
                        trigger: 'blur'
                    }
                ],
                parent_merchant_no: [
                    {
                        required: true,
                        message: '商户编号为必填项',
                        trigger: 'blur'
                    }
                ],
                merchant_no: [
                    {
                        required: true,
                        message: '子商户编号为必填项',
                        trigger: 'blur'
                    }
                ]
            },

            mypowerlimits: JSON.parse(localStorage.getItem('powerlimits')), //获取权限
            mytoken: localStorage.getItem('token'), //获取存储的token

            loading: false
        }
    },

    methods: {
        // 取消-返回上一页
        myresetForm() {
            this.$router.go(-1) // 返回上一页
        },
        // 小程序模板消息配置-添加
        addChatEvent() {
            let data = {
                chatname: '',
                title: '',
                modelid: ''
            }
            this.tableData_mini.push(data)
        },
        //小程序模板消息配置-操作-删除事件
        delChatEvent(ide, formdata) {
            console.log('获取的删除id为：' + JSON.stringify(ide))
            // js中通过splice删除指定的元素
            formdata.splice(ide, 1)
        },
        // 小程序模板消息配置-提交按钮
        submitFormMini(formName) {
            // console.log(this.$refs[formName].model);
            this.$refs[formName].validate((valid) => {
                if (valid) {
                    let getdatas = this.$refs[formName].model
                    // 转义-数据结构
                    let sub_result = {}
                    getdatas.forEach((element) => {
                        let data = {
                            desc: element.title,
                            template_id: element.modelid
                        }
                        sub_result[element.chatname] = data
                    })
                    console.log(sub_result)

                    axios
                        .post('/manage/Manage/aliTemplate&id=' + this.$route.query.id, sub_result, {
                            headers: {
                                Auth: localStorage.getItem('token')
                            }
                        })
                        .then((response) => {
                            if (response.data.code === 0) {
                                ElMessage({
                                    type: 'success',
                                    message: response.data.message
                                })
                            }
                        })
                        .catch(function (error) {
                            console.log(error)
                        })
                } else {
                    console.log('error submit!!')
                    return false
                }
            })
        },

        // 生活号模板消息配置-添加
        addPublicChatEvent() {
            let data = {
                chatname: '',
                title: '',
                modelid: ''
            }
            this.tableData_public.push(data)
        },
        // 生活号模板消息配置-操作-删除事件
        delPublicChatEvent(ide, formdata) {
            console.log('获取的删除id为：' + JSON.stringify(ide))
            // js中通过splice删除指定的元素
            formdata.splice(ide, 1)
        },
        // 生活号模板消息配置-提交按钮
        submitFormPublic(formName) {
            // console.log(this.$refs[formName].model);
            this.$refs[formName].validate((valid) => {
                if (valid) {
                    let getdatas = this.$refs[formName].model
                    // 转义-数据结构
                    let sub_result = {}
                    getdatas.forEach((element) => {
                        let data = {
                            desc: element.title,
                            template_id: element.modelid
                        }
                        sub_result[element.chatname] = data
                    })
                    console.log(sub_result)

                    axios
                        .post('/manage/Manage/lifestyleTemplate&id=' + this.$route.query.id, sub_result, {
                            headers: {
                                Auth: localStorage.getItem('token')
                            }
                        })
                        .then((response) => {
                            if (response.data.code === 0) {
                                ElMessage({
                                    type: 'success',
                                    message: response.data.message
                                })
                            }
                        })
                        .catch(function (error) {
                            console.log(error)
                        })
                } else {
                    console.log('error submit!!')
                    return false
                }
            })
        },

        // 小程序配置-from表单-提交表单
        submitForm(formName) {
            var getdata = this.$refs[formName].model
            let Base64 = require('js-base64').Base64
            this.$refs[formName].validate((valid) => {
                if (valid) {
                    axios
                        .post(
                            '/manage/Manage/alipay&id=' + this.$route.query.id,
                            {
                                appid: getdata.appid,
                                encryptKey: getdata.encryptKey,
                                alipayRootCertPath: Base64.encode(getdata.alipayRootCertPath),
                                alipayCertPath: Base64.encode(getdata.alipayCertPath),
                                merchantPrivateKey: Base64.encode(getdata.merchantPrivateKey),
                                merchantCertPath: Base64.encode(getdata.merchantCertPath),
                                autoapply: getdata.autoapply,
                                collect_type: getdata.collect_type,
                                transfer_line: getdata.transfer_line, //转账线路
                                transfer_pank_line: getdata.transfer_pank_line, //银行卡转账线路
                                order_line: getdata.order_line //收单线路
                            },
                            {
                                headers: {
                                    Auth: localStorage.getItem('token')
                                }
                            }
                        )
                        .then((response) => {
                            if (response.data.code === 0) {
                                ElMessage({
                                    type: 'success',
                                    message: response.data.message
                                })
                            }
                        })
                        .catch(function (error) {
                            console.log(error)
                        })
                } else {
                    console.log('error submit!!')
                    return false
                }
            })
        },
        // 生活号配置-form表单-提交表单
        submitForm1(formName) {
            var getdata = this.$refs[formName].model
            let Base64 = require('js-base64').Base64
            this.$refs[formName].validate((valid) => {
                if (valid) {
                    axios
                        .post(
                            '/manage/Manage/lifestyle&id=' + this.$route.query.id,
                            {
                                appid: getdata.appid,
                                encryptKey: getdata.encryptKey,
                                alipayRootCertPath: Base64.encode(getdata.alipayRootCertPath),
                                alipayCertPath: Base64.encode(getdata.alipayCertPath),
                                merchantPrivateKey: Base64.encode(getdata.merchantPrivateKey),
                                merchantCertPath: Base64.encode(getdata.merchantCertPath)
                                // submsg: getdata.submsg,
                            },
                            {
                                headers: {
                                    Auth: localStorage.getItem('token')
                                }
                            }
                        )
                        .then((response) => {
                            if (response.data.code === 0) {
                                ElMessage({
                                    type: 'success',
                                    message: response.data.message
                                })
                            }
                        })
                        .catch(function (error) {
                            console.log(error)
                        })
                } else {
                    console.log('error submit!!')
                    return false
                }
            })
        },

        // 易宝支付配置表单-提交表单
        submitFormYibaoPay(formName) {
            var getdata = this.$refs[formName].model
            this.$refs[formName].validate((valid) => {
                if (valid) {
                    axios
                        .post(
                            '/manage/Manage/yeepay&id=' + this.$route.query.id + '&thirdparty=2',
                            {
                                appid: getdata.appid,
                                app_key: getdata.app_key,
                                yop_public_key: getdata.yop_public_key,
                                isv_private_key: getdata.isv_private_key,
                                parent_merchant_no: getdata.parent_merchant_no,
                                merchant_no: getdata.merchant_no
                            },
                            {
                                headers: {
                                    Auth: localStorage.getItem('token')
                                }
                            }
                        )
                        .then((response) => {
                            if (response.data.code === 0) {
                                ElMessage({
                                    type: 'success',
                                    message: '提交成功！'
                                })
                            }
                        })
                        .catch(function (error) {
                            console.log(error)
                        })
                } else {
                    console.log('error submit!!')
                    return false
                }
            })
        }
    },
    created() {
        if (localStorage.getItem('token') === null || !localStorage.getItem('token')) {
            this.isshow = false
            this.$router.push('/').catch((error) => error)
        } else {
            this.loading = true
            //获取小程序配置-信息
            var p1 = axios
                .get('/manage/Manage/alipay', {
                    headers: {
                        Auth: localStorage.getItem('token')
                    },
                    params: {
                        id: this.$route.query.id
                    }
                })
                .then((response) => {
                    var getdata = response.data.result

                    this.ruleForm.appid = getdata.appid
                    this.ruleForm.merchantPrivateKey = getdata.merchantPrivateKey
                    this.ruleForm.alipayCertPath = getdata.alipayCertPath
                    this.ruleForm.alipayRootCertPath = getdata.alipayRootCertPath
                    this.ruleForm.merchantCertPath = getdata.merchantCertPath
                    this.ruleForm.encryptKey = getdata.encryptKey
                    this.ruleForm.autoapply = getdata.autoapply ? getdata.autoapply : ref('1')
                    this.ruleForm.collect_type = getdata.collect_type ? getdata.collect_type.toString() : ''
                    this.ruleForm.transfer_line = getdata.transfer_line ? getdata.transfer_line.toString() : ref('3')
                    this.ruleForm.transfer_pank_line = getdata.transfer_pank_line ? getdata.transfer_pank_line.toString() : ref('3')
                    this.ruleForm.order_line = getdata.order_line ? getdata.order_line.toString() : ref('3')
                })
                .catch(function (error) {
                    console.log(error)
                })

            //获取生活号配置-信息
            var p2 = axios
                .get('/manage/Manage/lifestyle', {
                    headers: {
                        Auth: localStorage.getItem('token')
                    },
                    params: {
                        id: this.$route.query.id
                    }
                })
                .then((response) => {
                    var getdata = response.data.result

                    this.ruleForm1.appid = getdata.appid
                    this.ruleForm1.merchantPrivateKey = getdata.merchantPrivateKey
                    this.ruleForm1.alipayCertPath = getdata.alipayCertPath
                    this.ruleForm1.alipayRootCertPath = getdata.alipayRootCertPath
                    this.ruleForm1.merchantCertPath = getdata.merchantCertPath
                    this.ruleForm1.encryptKey = getdata.encryptKey
                })
                .catch(function (error) {
                    console.log(error)
                })

            //获取小程序消息模板配置-信息
            var p3 = axios
                .get('/manage/Manage/aliTemplate', {
                    headers: {
                        Auth: localStorage.getItem('token')
                    },
                    params: {
                        id: this.$route.query.id
                    }
                })
                .then((response) => {
                    // this.result1 = response.data.result

                    let getdatas = response.data.result
                    let tabledata = []
                    if (Object.values(getdatas).length > 0) {
                        for (let key in getdatas) {
                            let data = {
                                chatname: key,
                                title: getdatas[key].desc,
                                modelid: getdatas[key].template_id
                            }
                            tabledata.push(data)
                        }
                    }
                    this.tableData_mini = tabledata
                })
                .catch(function (error) {
                    console.log(error)
                })

            //获取生活号消息模板配置-信息
            var p4 = axios
                .get('/manage/Manage/lifestyleTemplate', {
                    headers: {
                        Auth: localStorage.getItem('token')
                    },
                    params: {
                        id: this.$route.query.id
                    }
                })
                .then((response) => {
                    // this.result2 = response.data.result
                    let getdatas = response.data.result
                    let tabledata = []
                    if (Object.values(getdatas).length > 0) {
                        for (let key in getdatas) {
                            let data = {
                                chatname: key,
                                title: getdatas[key].desc,
                                modelid: getdatas[key].template_id
                            }
                            tabledata.push(data)
                        }
                    }
                    this.tableData_public = tabledata
                })
                .catch(function (error) {
                    console.log(error)
                })

            // 获取易宝支付配置-反显数据
            var p5 = axios
                .get('/manage/Manage/yeepay', {
                    headers: {
                        Auth: localStorage.getItem('token')
                    },
                    params: {
                        id: this.$route.query.id,
                        thirdparty: 2
                    }
                })
                .then((response) => {
                    var getdata = response.data.result
                    this.ruleForm_yibao.appid = getdata.appid
                    this.ruleForm_yibao.app_key = getdata.app_key
                    this.ruleForm_yibao.yop_public_key = getdata.yop_public_key
                    this.ruleForm_yibao.isv_private_key = getdata.isv_private_key
                    this.ruleForm_yibao.parent_merchant_no = getdata.parent_merchant_no
                    this.ruleForm_yibao.merchant_no = getdata.merchant_no
                })
                .catch(function (error) {
                    console.log(error)
                })

            Promise.all([p1, p2, p3, p4, p5])
                .then(() => {
                    this.loading = false
                })
                .catch((error) => {
                    console.log('error:失败:', error)
                    this.loading = false
                })
        }
    },
    components: {
        mainone,
    }
})
</script>
<style scoped>
.box {
    background-color: #ffffff;
    border-radius: 4px;
    color: #333333;
    padding: 10px 20px;
}

.box_title {
    line-height: 40px;
    height: 40px;
}

.box_title_font {
    float: left;
    font-weight: 400;
    font-size: 14px;
    color: #333333;
}

.box_title_button {
    float: right;
    font-weight: 400;
    font-size: 14px;
    color: #94a3d2;
}

.box_title_button:hover {
    cursor: pointer;
}

.box_content {
    margin-top: 10px;
}

/* 小程序模版消息配置 */
.mini-model-setbox {
    display: flex;
    flex-direction: column;
}

.mini-model-setbox-addbtn {
    margin-bottom: 20px;
    display: flex;
    justify-content: left;
}

/* table框-高度 */
.table-line-box {
    height: 80px;
    width: 100%;
    display: flex;
    align-items: center;
}
</style>
