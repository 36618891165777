<template>
    <div>
        <mainone :mainonebread="mainonebread"></mainone>
        <div v-loading="loading" element-loading-text="正在飞速加载中...">
            <!--标题-->
            <div class="box">
                <div class="box_content">
                    <el-tabs>
                        <!-- 小程序配置 -->
                        <el-tab-pane label="小程序配置">
                            <div>
                                <el-row style="margin-top: 20px">
                                    <el-col :span="24" :xs="24" :sm="24" :lg="5">
                                        <div style="width: 100%"></div>
                                    </el-col>
                                    <el-col :span="24" :xs="24" :sm="24" :lg="11">
                                        <el-form ref="ruleForm" :model="ruleForm" :rules="rules" label-width="180px" class="demo-ruleForm">
                                            <el-form-item label="appid：" prop="appid">
                                                <el-input v-model="ruleForm.appid" clearable></el-input>
                                            </el-form-item>
                                            <el-form-item label="appSecret：" prop="appsecret">
                                                <el-input v-model="ruleForm.appsecret" clearable></el-input>
                                            </el-form-item>
                                            <el-form-item label="原始id：" prop="originalid">
                                                <el-input v-model="ruleForm.originalid" clearable></el-input>
                                            </el-form-item>
                                            <el-form-item label="商户号：" prop="mchid">
                                                <el-input v-model="ruleForm.mchid" clearable></el-input>
                                            </el-form-item>
                                            <el-form-item label="微信支付密钥：" prop="secret">
                                                <el-input v-model="ruleForm.secret" clearable></el-input>
                                            </el-form-item>
                                            <el-form-item label="证书序列号：" prop="serial">
                                                <el-input v-model="ruleForm.serial" clearable></el-input>
                                            </el-form-item>
                                            <el-form-item label=" v3密钥：" prop="v3key">
                                                <el-input v-model="ruleForm.v3key" clearable></el-input>
                                            </el-form-item>
                                            <el-form-item label="  商户API私钥：" prop="privatekey">
                                                <el-input type="textarea" rows="5" v-model="ruleForm.privatekey"></el-input>
                                            </el-form-item>
                                            <el-form-item label="企业付款设置(apiclient_cert.pem)：" prop="merchantcert">
                                                <el-input type="textarea" rows="5" v-model="ruleForm.merchantcert"></el-input>
                                            </el-form-item>
                                            <el-form-item label="企业付款设置(apiclient_key.pem)：" prop="merchantkey">
                                                <el-input type="textarea" rows="5" v-model="ruleForm.merchantkey"></el-input>
                                            </el-form-item>
                                            <el-form-item label="转账到用户余额权限：" prop="autoapply">
                                                <el-radio-group v-model="ruleForm.autoapply">
                                                    <el-radio label="1">开通</el-radio>
                                                    <el-radio label="2">不开通</el-radio>
                                                </el-radio-group>
                                            </el-form-item>
                                            <el-form-item label="转账线路：" prop="transfer_line">
                                                <el-radio-group v-model="ruleForm.transfer_line">
                                                    <el-radio label="2">微信支付</el-radio>
                                                    <el-radio label="8">易宝支付</el-radio>
                                                </el-radio-group>
                                            </el-form-item>
                                            <el-form-item label="银行卡转账线路：" prop="transfer_pank_line">
                                                <el-radio-group v-model="ruleForm.transfer_pank_line">
                                                    <el-radio label="2">微信支付</el-radio>
                                                    <el-radio label="8">易宝支付</el-radio>
                                                </el-radio-group>
                                            </el-form-item>
                                            <el-form-item label="收单线路：" prop="order_line">
                                                <el-radio-group v-model="ruleForm.order_line">
                                                    <el-radio label="2">微信支付</el-radio>
                                                    <el-radio label="8">易宝支付</el-radio>
                                                </el-radio-group>
                                            </el-form-item>
                                            <el-form-item label="打款配置：" prop="collect_type">
                                                <el-radio-group v-model="ruleForm.collect_type">
                                                    <el-radio label="1">通过京硕账户打款</el-radio>
                                                    <el-radio label="2">通过租户公司打款</el-radio>
                                                </el-radio-group>
                                            </el-form-item>
                                            <el-form-item>
                                                <el-button type="primary" @click="submitForm('ruleForm')" style="width: 40%; margin-right: 10px">确认 </el-button>
                                                <el-button @click="myresetForm">取消</el-button>
                                            </el-form-item>
                                        </el-form>
                                    </el-col>
                                    <el-col :span="24" :xs="24" :sm="24" :lg="8">
                                        <div style="width: 100%"></div>
                                    </el-col>
                                </el-row>
                            </div>
                        </el-tab-pane>
                        <!-- 公众号配置 -->
                        <el-tab-pane label="公众号配置">
                            <div>
                                <el-row style="margin-top: 20px">
                                    <el-col :span="24" :xs="24" :sm="24" :lg="5">
                                        <div style="width: 100%"></div>
                                    </el-col>
                                    <el-col :span="24" :xs="24" :sm="24" :lg="11">
                                        <el-form ref="ruleForm1" :model="ruleForm1" :rules="rules1" label-width="180px" class="demo-ruleForm">
                                            <el-form-item label="appid：" prop="appid" required="true">
                                                <el-input v-model="ruleForm1.appid" clearable></el-input>
                                            </el-form-item>
                                            <el-form-item label="appSecret：" prop="appsecret" required="true">
                                                <el-input v-model="ruleForm1.appsecret" clearable></el-input>
                                            </el-form-item>
                                            <el-form-item label="原始id：" prop="originalid" required="true">
                                                <el-input v-model="ruleForm1.originalid" clearable></el-input>
                                            </el-form-item>
                                            <el-form-item label="关注公众号回复内容：" prop="submsg" required="true">
                                                <el-input type="textarea" rows="5" v-model="ruleForm1.submsg" clearable></el-input>
                                            </el-form-item>
                                            <el-form-item>
                                                <el-button type="primary" @click="submitForm1('ruleForm1')" style="width: 40%; margin-right: 10px">确认 </el-button>
                                                <el-button @click="myresetForm">取消</el-button>
                                            </el-form-item>
                                        </el-form>
                                    </el-col>
                                    <el-col :span="24" :xs="24" :sm="24" :lg="8">
                                        <div style="width: 100%"></div>
                                    </el-col>
                                </el-row>
                            </div>
                        </el-tab-pane>
                        <!-- 小程序模板消息配置 -->
                        <el-tab-pane label="小程序模板消息配置">
                            <div class="mini-model-setbox">
                                <div class="mini-model-setbox-addbtn">
                                    <el-button type="primary" style="min-width: 150px" @click="addChatEvent()" plain>新增 </el-button>
                                </div>
                                <div>
                                    <el-form
                                        ref="tableDatamini"
                                        :model="tableData_mini"
                                        :rules="ruleDatamini"
                                        class="demo-ruleForm"
                                        v-loading="loading"
                                        element-loading-text="正在飞速加载中..."
                                    >
                                        <el-form-item>
                                            <el-table :header-cell-style="{ background: '#F5F7FA' }" :data="tableData_mini" style="width: 100%" border>
                                                <!-- 字段名 -->
                                                <el-table-column label="字段名" min-width="180">
                                                    <template #default="scope">
                                                        <el-form-item :prop="scope.$index + '.chatname'" :rules="ruleDatamini.chatname" class="table-line-box">
                                                            <el-input v-model="scope.row.chatname" clearable />
                                                        </el-form-item>
                                                    </template>
                                                </el-table-column>
                                                <!-- 标题 -->
                                                <el-table-column label="标题" min-width="180">
                                                    <template #default="scope">
                                                        <el-form-item :prop="scope.$index + '.title'" :rules="ruleDatamini.title" class="table-line-box">
                                                            <el-input v-model="scope.row.title" clearable />
                                                        </el-form-item>
                                                    </template>
                                                </el-table-column>
                                                <!-- 模板ID -->
                                                <el-table-column label="模板ID" min-width="180">
                                                    <template #default="scope">
                                                        <el-form-item :prop="scope.$index + '.modelid'" :rules="ruleDatamini.modelid" class="table-line-box">
                                                            <el-input v-model="scope.row.modelid" clearable />
                                                        </el-form-item>
                                                    </template>
                                                </el-table-column>
                                                <!-- 操作 -->
                                                <!-- <el-table-column prop="caozuo" label="操作">
                          <template #default="scope">
                            <el-button type="text" v-if="scope.row.addisshow" @click="addChatEvent()">增加
                            </el-button>
                            <el-button type="text" v-if="!scope.row.addisshow"
                              @click="delChatEvent(scope.$index, tableData_mini)">删除</el-button>
                          </template>
                        </el-table-column> -->
                                            </el-table>
                                        </el-form-item>
                                        <el-form-item style="text-align: center">
                                            <el-button type="primary" @click="submitFormMini('tableDatamini')" style="width: 150px"> 保存</el-button>
                                            <el-button @click="myresetForm">取消</el-button>
                                        </el-form-item>
                                    </el-form>
                                </div>
                            </div>
                        </el-tab-pane>
                        <!-- 公众号模板消息配置 -->
                        <el-tab-pane label="公众号模板消息配置">
                            <div class="mini-model-setbox">
                                <div class="mini-model-setbox-addbtn">
                                    <el-button type="primary" style="min-width: 150px" @click="addPublicChatEvent()" plain>新增 </el-button>
                                </div>
                                <div>
                                    <el-form
                                        ref="tableDatapublic"
                                        :model="tableData_public"
                                        :rules="ruleDatapublic"
                                        class="demo-ruleForm"
                                        v-loading="loading"
                                        element-loading-text="正在飞速加载中..."
                                    >
                                        <el-form-item>
                                            <el-table :header-cell-style="{ background: '#F5F7FA' }" :data="tableData_public" style="width: 100%" border>
                                                <!-- 字段名 -->
                                                <el-table-column label="字段名" min-width="180">
                                                    <template #default="scope">
                                                        <el-form-item :prop="scope.$index + '.chatname'" :rules="ruleDatapublic.chatname" class="table-line-box">
                                                            <el-input v-model="scope.row.chatname" clearable />
                                                        </el-form-item>
                                                    </template>
                                                </el-table-column>
                                                <!-- 标题 -->
                                                <el-table-column label="标题" min-width="180">
                                                    <template #default="scope">
                                                        <el-form-item :prop="scope.$index + '.title'" :rules="ruleDatapublic.title" class="table-line-box">
                                                            <el-input v-model="scope.row.title" clearable />
                                                        </el-form-item>
                                                    </template>
                                                </el-table-column>
                                                <!-- 模板ID -->
                                                <el-table-column label="模板ID" min-width="180">
                                                    <template #default="scope">
                                                        <el-form-item :prop="scope.$index + '.modelid'" :rules="ruleDatapublic.modelid" class="table-line-box">
                                                            <el-input v-model="scope.row.modelid" clearable />
                                                        </el-form-item>
                                                    </template>
                                                </el-table-column>
                                                <!-- 操作 -->
                                                <!-- <el-table-column prop="caozuo" label="操作">
                          <template #default="scope">
                            <el-button type="text" v-if="scope.row.addisshow" @click="addChatEvent()">增加
                            </el-button>
                            <el-button type="text" v-if="!scope.row.addisshow"
                              @click="delPublicChatEvent(scope.$index, tableData_public)">删除</el-button>
                          </template>
                        </el-table-column> -->
                                            </el-table>
                                        </el-form-item>
                                        <el-form-item style="text-align: center">
                                            <el-button type="primary" @click="submitFormPublic('tableDatapublic')" style="width: 150px"> 保存</el-button>
                                            <el-button @click="myresetForm">取消</el-button>
                                        </el-form-item>
                                    </el-form>
                                </div>
                            </div>
                        </el-tab-pane>
                        <!-- 易宝支付配置 -->
                        <el-tab-pane label="易宝支付配置">
                            <div>
                                <el-row style="margin-top: 20px">
                                    <el-col :span="24" :xs="24" :sm="24" :lg="5">
                                        <div style="width: 100%"></div>
                                    </el-col>
                                    <el-col :span="24" :xs="24" :sm="24" :lg="11">
                                        <el-form ref="ruleForm_yibao" :model="ruleForm_yibao" :rules="ruleForm_yibao_rules" label-width="180px" class="demo-ruleForm">
                                            <el-form-item label="Appid：" prop="appid" required="true">
                                                <el-input v-model="ruleForm_yibao.appid" clearable></el-input>
                                            </el-form-item>
                                            <el-form-item label="应用id：" prop="app_key" required="true">
                                                <el-input v-model="ruleForm_yibao.app_key" clearable></el-input>
                                            </el-form-item>
                                            <el-form-item label="易宝公钥：" prop="yop_public_key" required="true">
                                                <el-input type="textarea" rows="5" v-model="ruleForm_yibao.yop_public_key" clearable></el-input>
                                            </el-form-item>
                                            <el-form-item label="商户私钥：" prop="isv_private_key" required="true">
                                                <el-input type="textarea" rows="5" v-model="ruleForm_yibao.isv_private_key" clearable></el-input>
                                            </el-form-item>
                                            <el-form-item label="商户编号：" prop="parent_merchant_no" required="true">
                                                <el-input v-model="ruleForm_yibao.parent_merchant_no" clearable></el-input>
                                            </el-form-item>
                                            <el-form-item label="子商户编号：" prop="merchant_no" required="true">
                                                <el-input v-model="ruleForm_yibao.merchant_no" clearable></el-input>
                                            </el-form-item>
                                            <el-form-item>
                                                <el-button type="primary" @click="submitFormYibaoPay('ruleForm_yibao')" style="width: 40%; margin-right: 10px">确认 </el-button>
                                                <el-button @click="myresetForm">取消</el-button>
                                            </el-form-item>
                                        </el-form>
                                    </el-col>
                                    <el-col :span="24" :xs="24" :sm="24" :lg="8">
                                        <div style="width: 100%"></div>
                                    </el-col>
                                </el-row>
                            </div>
                        </el-tab-pane>
                        <!-- 企业微信配置 -->
                        <el-tab-pane label="企业微信配置">
                            <vcompany_qiwei />
                        </el-tab-pane>
                        <!-- 腾讯文档配置 -->
                        <el-tab-pane label="腾讯文档配置">
                            <formDefault :Form="tencent.ruleForm" :FormRules="tencent.ruleForm_rules" :GetAxios="tencent.my_axios" />
                        </el-tab-pane>
                        <!-- 金山文档配置 -->
                        <el-tab-pane label="金山文档配置">
                            <formDefault :Form="goldenHill.ruleForm" :FormRules="goldenHill.ruleForm_rules" :GetAxios="goldenHill.my_axios" />
                        </el-tab-pane>
                        <!-- 百度网盘配置 -->
                        <el-tab-pane label="百度网盘配置">
                            <formBaiduSet :Form="baidu.ruleForm" :FormRules="baidu.ruleForm_rules" :GetAxios="baidu.my_axios" />
                        </el-tab-pane>
                        <!-- 扫码关注公众号 -->
                        <!-- <el-tab-pane label="扫码关注公众号">
                            <formQrcodeGetPubnumber />
                        </el-tab-pane> -->
                    </el-tabs>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import mainone from '@/components/Mainone'
import vcompany_qiwei from './vcompany_qiwei'
import formDefault from './formDefault'
import formBaiduSet from './formBaiduSet'
// import formQrcodeGetPubnumber from './formQrcodeGetPubnumber'
import axios from 'axios'
import { ElMessage } from 'element-plus'
import { defineComponent, ref } from 'vue'

export default defineComponent({
    name: 'vcompanywxset',
    data() {
        return {
            mainonebread: [
                {
                    name: '租户管理'
                },
                {
                    name: '租户管理'
                },
                {
                    name: '公司列表',
                    path: '/companylist'
                },
                {
                    name: '微信设置'
                }
            ], // 当前页-【面包屑导航】

            // 小程序配置-form表单
            ruleForm: {
                appid: '',
                appsecret: '',
                originalid: '',
                mchid: '',
                secret: '',
                merchantcert: '',
                merchantkey: '',
                v3key: '',
                privatekey: '',
                serial: '',
                autoapply: ref('1'),
                collect_type: ref('1'),
                transfer_line: ref('2'),
                transfer_pank_line: ref('2'),
                order_line: ref('2')
            },
            // 小程序配置-规则校验
            rules: {
                appid: [
                    {
                        required: true,
                        message: 'appid为必填项',
                        trigger: 'blur'
                    }
                ],
                appsecret: [
                    {
                        required: true,
                        message: 'appsecret为必填项',
                        trigger: 'blur'
                    }
                ],
                originalid: [
                    {
                        required: true,
                        message: '原始id为必填项',
                        trigger: 'blur'
                    }
                ],
                mchid: [
                    {
                        required: true,
                        message: '商户号为必填项',
                        trigger: 'blur'
                    }
                ],
                secret: [
                    {
                        required: true,
                        message: '微信支付密钥为必填项',
                        trigger: 'blur'
                    }
                ],
                serial: [
                    {
                        required: true,
                        message: '证书序列号为必填项',
                        trigger: 'blur'
                    }
                ],
                v3key: [
                    {
                        required: true,
                        message: 'v3密钥为必填项',
                        trigger: 'blur'
                    }
                ],
                privatekey: [
                    {
                        required: true,
                        message: '商户API私钥为必填项',
                        trigger: 'blur'
                    }
                ],
                autoapply: [
                    {
                        required: true,
                        message: '转账到用户余额权限为必选项',
                        trigger: 'change'
                    }
                ],
                collect_type: [
                    {
                        required: true,
                        message: '打款配置为必选项',
                        trigger: 'change'
                    }
                ],
                transfer_line: [
                    {
                        required: true,
                        message: '转账线路为必选项',
                        trigger: 'change'
                    }
                ],
                transfer_pank_line: [
                    {
                        required: true,
                        message: '银行卡转账线路为必选项',
                        trigger: 'change'
                    }
                ],
                order_line: [
                    {
                        required: true,
                        message: '收单线路为必选项',
                        trigger: 'change'
                    }
                ]
            },

            // 公众号配置-form表单
            ruleForm1: {
                appid: '',
                appsecret: '',
                originalid: '',
                submsg: ''
            },
            // 公众号配置-规则校验
            rules1: {
                appid: [
                    {
                        required: true,
                        message: 'appid为必填项',
                        trigger: 'blur'
                    }
                ],
                appsecret: [
                    {
                        required: true,
                        message: 'appsecret为必填项',
                        trigger: 'blur'
                    }
                ],
                originalid: [
                    {
                        required: true,
                        message: '原始id为必填项',
                        trigger: 'blur'
                    }
                ],
                submsg: [
                    {
                        required: true,
                        message: '关注公众号回复内容为必填项',
                        trigger: 'blur'
                    }
                ]
            },

            // 小程序模板消息配置-formdata
            tableData_mini: [
                // {
                //   chatname: '',
                //   title: '',
                //   modelid: '',
                //   // addisshow: true
                // }
            ],
            // 小程序模板消息配置-校验
            ruleDatamini: {
                chatname: [
                    {
                        required: true,
                        message: '字段名为必填项',
                        trigger: 'blur'
                    }
                ],
                title: [
                    {
                        required: true,
                        message: '标题为必填项',
                        trigger: 'blur'
                    }
                ],
                modelid: [
                    {
                        required: true,
                        message: '模板ID为必填项',
                        trigger: 'blur'
                    }
                ]
            },

            // 公众号模板消息配置-formdata
            tableData_public: [],
            // 小程序模板消息配置-校验
            ruleDatapublic: {
                chatname: [
                    {
                        required: true,
                        message: '字段名为必填项',
                        trigger: 'blur'
                    }
                ],
                title: [
                    {
                        required: true,
                        message: '标题为必填项',
                        trigger: 'blur'
                    }
                ],
                modelid: [
                    {
                        required: true,
                        message: '模板ID为必填项',
                        trigger: 'blur'
                    }
                ]
            },

            // 易宝支付配置
            ruleForm_yibao: {
                appid: '',
                app_key: '',
                yop_public_key: '',
                isv_private_key: '',
                parent_merchant_no: '',
                merchant_no: ''
            },
            ruleForm_yibao_rules: {
                appid: [
                    {
                        required: true,
                        message: 'Appid为必填项',
                        trigger: 'blur'
                    }
                ],
                app_key: [
                    {
                        required: true,
                        message: '应用id为必填项',
                        trigger: 'blur'
                    }
                ],
                yop_public_key: [
                    {
                        required: true,
                        message: '易宝公钥为必填项',
                        trigger: 'blur'
                    }
                ],
                isv_private_key: [
                    {
                        required: true,
                        message: '商户私钥为必填项',
                        trigger: 'blur'
                    }
                ],
                parent_merchant_no: [
                    {
                        required: true,
                        message: '商户编号为必填项',
                        trigger: 'blur'
                    }
                ],
                merchant_no: [
                    {
                        required: true,
                        message: '子商户编号为必填项',
                        trigger: 'blur'
                    }
                ]
            },

            // 腾讯文档配置
            tencent: {
                ruleForm: {
                    client_id: '',
                    client_secret: ''
                },
                ruleForm_rules: {
                    client_id: [
                        {
                            required: true,
                            message: 'client_id为必填项',
                            trigger: 'blur'
                        }
                    ],
                    client_secret: [
                        {
                            required: true,
                            message: 'client_secret为必填项',
                            trigger: 'blur'
                        }
                    ]
                },
                my_axios: {
                    get_axios: '/manage/Manage/document',
                    key_name: 'document_tencent'
                }
            },

            // 金山文档配置
            goldenHill: {
                ruleForm: {
                    app_id: '',
                    app_key: ''
                },
                ruleForm_rules: {
                    app_id: [
                        {
                            required: true,
                            message: 'app_id为必填项',
                            trigger: 'blur'
                        }
                    ],
                    app_key: [
                        {
                            required: true,
                            message: 'app_key为必填项',
                            trigger: 'blur'
                        }
                    ]
                },
                my_axios: {
                    get_axios: '/manage/Manage/document',
                    key_name: 'document_goldenHill'
                }
            },

            // 百度文档配置
            baidu: {
                ruleForm: {
                    AppID: '',
                    AppKey: '',
                    SecretKey: '',
                    SignKey: '',
                    display: '',
                    config: {
                        a: {
                            paste: '',
                            other: ''
                        },
                        b: {
                            paste: '',
                            other: ''
                        }
                    }
                },
                ruleForm_rules: {
                    AppID: [
                        {
                            required: true,
                            message: 'AppID为必填项',
                            trigger: 'blur'
                        }
                    ],
                    AppKey: [
                        {
                            required: true,
                            message: 'AppKey为必填项',
                            trigger: 'blur'
                        }
                    ],
                    SecretKey: [
                        {
                            required: true,
                            message: 'SecretKey为必填项',
                            trigger: 'blur'
                        }
                    ],
                    SignKey: [
                        {
                            required: true,
                            message: 'SignKey为必填项',
                            trigger: 'blur'
                        }
                    ],
                    display: [
                        {
                            required: true,
                            message: 'display为必填项',
                            trigger: 'blur'
                        }
                    ],
                    'config.a.paste': [
                        {
                            required: true,
                            message: '粘贴消息内容为必填项',
                            trigger: 'blur'
                        }
                    ],
                    'config.a.other': [
                        {
                            required: true,
                            message: '公众号二维码为必填项',
                            trigger: 'blur'
                        }
                    ],
                    'config.b.paste': [
                        {
                            required: true,
                            message: '粘贴消息内容为必填项',
                            trigger: 'blur'
                        }
                    ],
                    'config.b.other': [
                        {
                            required: true,
                            message: '粘贴搜索内容为必填项',
                            trigger: 'blur'
                        }
                    ]
                },
                my_axios: {
                    get_axios: '/manage/Manage/document',
                    key_name: 'document_baidu'
                }
            },

            mypowerlimits: JSON.parse(localStorage.getItem('powerlimits')), //获取权限
            mytoken: localStorage.getItem('token'), //获取存储的token

            loading: false
        }
    },

    methods: {
        // 取消-返回上一页
        myresetForm() {
            this.$router.go(-1) // 返回上一页
        },
        // 小程序模板消息配置-添加
        addChatEvent() {
            let data = {
                chatname: '',
                title: '',
                modelid: ''
            }
            this.tableData_mini.push(data)
        },
        //小程序模板消息配置-操作-删除事件
        delChatEvent(ide, formdata) {
            console.log('获取的删除id为：' + JSON.stringify(ide))
            // js中通过splice删除指定的元素
            formdata.splice(ide, 1)
        },
        // 小程序模板消息配置-提交按钮
        submitFormMini(formName) {
            // console.log(this.$refs[formName].model);
            this.$refs[formName].validate((valid) => {
                if (valid) {
                    let getdatas = this.$refs[formName].model
                    // 转义-数据结构
                    let sub_result = {}
                    getdatas.forEach((element) => {
                        let data = {
                            desc: element.title,
                            template_id: element.modelid
                        }
                        sub_result[element.chatname] = data
                    })
                    console.log(sub_result)

                    axios
                        .post('/manage/Manage/wechatTemplate&id=' + this.$route.query.id, sub_result, {
                            headers: {
                                Auth: localStorage.getItem('token')
                            }
                        })
                        .then((response) => {
                            if (response.data.code === 0) {
                                ElMessage({
                                    type: 'success',
                                    message: response.data.message
                                })
                            }
                        })
                        .catch(function (error) {
                            console.log(error)
                        })
                } else {
                    console.log('error submit!!')
                    return false
                }
            })
        },

        // 公众号模板消息配置-添加
        addPublicChatEvent() {
            let data = {
                chatname: '',
                title: '',
                modelid: ''
            }
            this.tableData_public.push(data)
        },
        // 公众号模板消息配置-操作-删除事件
        delPublicChatEvent(ide, formdata) {
            console.log('获取的删除id为：' + JSON.stringify(ide))
            // js中通过splice删除指定的元素
            formdata.splice(ide, 1)
        },
        // 公众号模板消息配置-提交按钮
        submitFormPublic(formName) {
            // console.log(this.$refs[formName].model);
            this.$refs[formName].validate((valid) => {
                if (valid) {
                    let getdatas = this.$refs[formName].model
                    // 转义-数据结构
                    let sub_result = {}
                    getdatas.forEach((element) => {
                        let data = {
                            desc: element.title,
                            template_id: element.modelid
                        }
                        sub_result[element.chatname] = data
                    })
                    console.log(sub_result)

                    axios
                        .post('/manage/Manage/officalTemplate&id=' + this.$route.query.id, sub_result, {
                            headers: {
                                Auth: localStorage.getItem('token')
                            }
                        })
                        .then((response) => {
                            if (response.data.code === 0) {
                                ElMessage({
                                    type: 'success',
                                    message: response.data.message
                                })
                            }
                        })
                        .catch(function (error) {
                            console.log(error)
                        })
                } else {
                    console.log('error submit!!')
                    return false
                }
            })
        },

        // 小程序配置-from表单-提交表单
        submitForm(formName) {
            var getdata = this.$refs[formName].model
            let Base64 = require('js-base64').Base64
            this.$refs[formName].validate((valid) => {
                if (valid) {
                    axios
                        .post(
                            '/manage/Manage/wechat&id=' + this.$route.query.id,
                            {
                                appid: getdata.appid,
                                appsecret: getdata.appsecret,
                                originalid: getdata.originalid,
                                mchid: getdata.mchid,
                                secret: getdata.secret,
                                merchantcert: Base64.encode(getdata.merchantcert),
                                merchantkey: Base64.encode(getdata.merchantkey),
                                v3key: getdata.v3key,
                                privatekey: Base64.encode(getdata.privatekey),
                                serial: getdata.serial,
                                autoapply: getdata.autoapply,
                                collect_type: getdata.collect_type,
                                transfer_line: getdata.transfer_line, //转账线路
                                transfer_pank_line: getdata.transfer_pank_line, //银行卡转账线路
                                order_line: getdata.order_line //收单线路
                            },
                            {
                                headers: {
                                    Auth: localStorage.getItem('token')
                                }
                            }
                        )
                        .then((response) => {
                            if (response.data.code === 0) {
                                this.$router.push({ path: 'companylist' })
                                ElMessage({
                                    type: 'success',
                                    message: '提交成功！'
                                })
                            }
                        })
                        .catch(function (error) {
                            console.log(error)
                        })
                } else {
                    console.log('error submit!!')
                    return false
                }
            })
        },
        // 公众号配置-form表单-提交表单
        submitForm1(formName) {
            var getdata = this.$refs[formName].model
            this.$refs[formName].validate((valid) => {
                if (valid) {
                    axios
                        .post(
                            '/manage/Manage/official&id=' + this.$route.query.id,
                            {
                                appid: getdata.appid,
                                appsecret: getdata.appsecret,
                                originalid: getdata.originalid,
                                submsg: getdata.submsg
                            },
                            {
                                headers: {
                                    Auth: localStorage.getItem('token')
                                }
                            }
                        )
                        .then((response) => {
                            if (response.data.code === 0) {
                                ElMessage({
                                    type: 'success',
                                    message: '提交成功！'
                                })
                            }
                        })
                        .catch(function (error) {
                            console.log(error)
                        })
                } else {
                    console.log('error submit!!')
                    return false
                }
            })
        },

        // 易宝支付配置表单-提交表单
        submitFormYibaoPay(formName) {
            var getdata = this.$refs[formName].model
            this.$refs[formName].validate((valid) => {
                if (valid) {
                    axios
                        .post(
                            '/manage/Manage/yeepay&id=' + this.$route.query.id + '&thirdparty=1',
                            {
                                appid: getdata.appid,
                                app_key: getdata.app_key,
                                yop_public_key: getdata.yop_public_key,
                                isv_private_key: getdata.isv_private_key,
                                parent_merchant_no: getdata.parent_merchant_no,
                                merchant_no: getdata.merchant_no
                            },
                            {
                                headers: {
                                    Auth: localStorage.getItem('token')
                                }
                            }
                        )
                        .then((response) => {
                            if (response.data.code === 0) {
                                ElMessage({
                                    type: 'success',
                                    message: '提交成功！'
                                })
                            }
                        })
                        .catch(function (error) {
                            console.log(error)
                        })
                } else {
                    console.log('error submit!!')
                    return false
                }
            })
        }
    },
    created() {
        if (localStorage.getItem('token') === null || !localStorage.getItem('token')) {
            this.isshow = false
            this.$router.push('/').catch((error) => error)
        } else {
            this.loading = true
            //获取小程序配置-信息
            var p1 = axios
                .get('/manage/Manage/wechat', {
                    headers: {
                        Auth: localStorage.getItem('token')
                    },
                    params: {
                        id: this.$route.query.id
                    }
                })
                .then((response) => {
                    var getdata = response.data.result

                    this.ruleForm.appid = getdata.appid
                    this.ruleForm.appsecret = getdata.appsecret
                    this.ruleForm.originalid = getdata.originalid
                    this.ruleForm.mchid = getdata.mchid
                    this.ruleForm.secret = getdata.secret
                    this.ruleForm.merchantcert = getdata.merchantcert
                    this.ruleForm.merchantkey = getdata.merchantkey
                    this.ruleForm.v3key = getdata.v3key
                    this.ruleForm.privatekey = getdata.privatekey
                    this.ruleForm.serial = getdata.serial
                    this.ruleForm.autoapply = getdata.autoapply ? getdata.autoapply : ref('1')
                    this.ruleForm.collect_type = getdata.collect_type ? getdata.collect_type.toString() : ''
                    this.ruleForm.transfer_line = getdata.transfer_line ? getdata.transfer_line.toString() : ref('2')
                    this.ruleForm.transfer_pank_line = getdata.transfer_pank_line ? getdata.transfer_pank_line.toString() : ref('2')
                    this.ruleForm.order_line = getdata.order_line ? getdata.order_line.toString() : ref('2')
                })
                .catch(function (error) {
                    console.log(error)
                })

            //获取公众号配置-信息
            var p2 = axios
                .get('/manage/Manage/official', {
                    headers: {
                        Auth: localStorage.getItem('token')
                    },
                    params: {
                        id: this.$route.query.id
                    }
                })
                .then((response) => {
                    var getdata = response.data.result
                    this.ruleForm1.appid = getdata.appid
                    this.ruleForm1.appsecret = getdata.appsecret
                    this.ruleForm1.originalid = getdata.originalid
                    this.ruleForm1.submsg = getdata.submsg
                })
                .catch(function (error) {
                    console.log(error)
                })

            //获取-小程序消息模版配置-信息
            var p3 = axios
                .get('/manage/Manage/wechatTemplate', {
                    headers: {
                        Auth: localStorage.getItem('token')
                    },
                    params: {
                        id: this.$route.query.id
                    }
                })
                .then((response) => {
                    let getdatas = response.data.result
                    let tabledata = []
                    if (Object.values(getdatas).length > 0) {
                        for (let key in getdatas) {
                            let data = {
                                chatname: key,
                                title: getdatas[key].desc,
                                modelid: getdatas[key].template_id
                            }
                            tabledata.push(data)
                        }
                    }
                    this.tableData_mini = tabledata
                })
                .catch(function (error) {
                    console.log(error)
                })

            //获取公众号订阅消息配置-信息
            var p4 = axios
                .get('/manage/Manage/officalTemplate', {
                    headers: {
                        Auth: localStorage.getItem('token')
                    },
                    params: {
                        id: this.$route.query.id
                    }
                })
                .then((response) => {
                    let getdatas = response.data.result
                    let tabledata = []
                    if (Object.values(getdatas).length > 0) {
                        for (let key in getdatas) {
                            let data = {
                                chatname: key,
                                title: getdatas[key].desc,
                                modelid: getdatas[key].template_id
                            }
                            tabledata.push(data)
                        }
                    }
                    this.tableData_public = tabledata
                })
                .catch(function (error) {
                    console.log(error)
                })

            // 获取易宝支付配置-反显数据
            var p5 = axios
                .get('/manage/Manage/yeepay', {
                    headers: {
                        Auth: localStorage.getItem('token')
                    },
                    params: {
                        id: this.$route.query.id,
                        thirdparty: 1
                    }
                })
                .then((response) => {
                    var getdata = response.data.result
                    this.ruleForm_yibao.appid = getdata.appid
                    this.ruleForm_yibao.app_key = getdata.app_key
                    this.ruleForm_yibao.yop_public_key = getdata.yop_public_key
                    this.ruleForm_yibao.isv_private_key = getdata.isv_private_key
                    this.ruleForm_yibao.parent_merchant_no = getdata.parent_merchant_no
                    this.ruleForm_yibao.merchant_no = getdata.merchant_no
                })
                .catch(function (error) {
                    console.log(error)
                })

            // // 获取 扫码关注公众号-返显数据
            // var p6 = axios
            //     .get('/manage/Manage/yeepay', {
            //         headers: {
            //             Auth: localStorage.getItem('token')
            //         },
            //         params: {
            //             id: this.$route.query.id,
            //             thirdparty: 1
            //         }
            //     })
            //     .then((response) => {
            //         var getdata = response.data.result
            //         this.ruleForm_yibao.appid = getdata.appid
            //         this.ruleForm_yibao.app_key = getdata.app_key
            //         this.ruleForm_yibao.yop_public_key = getdata.yop_public_key
            //         this.ruleForm_yibao.isv_private_key = getdata.isv_private_key
            //         this.ruleForm_yibao.parent_merchant_no = getdata.parent_merchant_no
            //         this.ruleForm_yibao.merchant_no = getdata.merchant_no
            //     })
            //     .catch(function(error) {
            //         console.log(error)
            //     })

            Promise.all([p1, p2, p3, p4, p5])
                .then(() => {
                    this.loading = false
                })
                .catch((error) => {
                    console.log('error:失败:', error)
                    this.loading = false
                })
        }
    },
    components: {
        mainone,
        vcompany_qiwei,
        formDefault,
        formBaiduSet,
        // formQrcodeGetPubnumber
    }
})
</script>
<style scoped>
.box {
    background-color: #ffffff;
    border-radius: 4px;
    color: #333333;
    padding: 10px 20px;
}

.box_title {
    line-height: 40px;
    height: 40px;
}

.box_title_font {
    float: left;
    font-weight: 400;
    font-size: 14px;
    color: #333333;
}

.box_title_button {
    float: right;
    font-weight: 400;
    font-size: 14px;
    color: #94a3d2;
}

.box_title_button:hover {
    cursor: pointer;
}

.box_content {
    margin-top: 10px;
}

/* 小程序模版消息配置 */
.mini-model-setbox {
    display: flex;
    flex-direction: column;
}

.mini-model-setbox-addbtn {
    margin-bottom: 20px;
    display: flex;
    justify-content: left;
}

/* table框-高度 */
.table-line-box {
    height: 80px;
    width: 100%;
    display: flex;
    align-items: center;
}
</style>
