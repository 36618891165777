<template>
    <div>
        <mainone :mainonebread="mainonebread"></mainone>
        <maintwo
            :maintwoiconshow="maintwoiconshow"
            :maintwoforminfo="maintwoforminfo"
            :myinputoneisshow="maintwoforminfo.myinputoneisshow"
            :myinputone="maintwoforminfo.myinputone"
            :myselectleftisshow="maintwoforminfo.selectleftisshow"
            :myselectleft="maintwoforminfo.selectleft"
            :myinputishow="myinputishow"
            :myinput="myinput"
            :myinputonefullisshow="maintwoforminfo.myinputonefullisshow"
            :myinputonefull="maintwoforminfo.myinputonefull"
            :myinputonefullgaoisshow="myinputonefullgaoisshow"
            :myinputonefullgao="myinputonefullgao"
            :myselectisshow="myselectisshow"
            :myselect="myselect"
            :myselectcityisshow="myselectcityisshow"
            :myselectcity="myselectcity"
            v-on:getsearchinfo="getSeachinfoEvent"
            v-on:searchForm="searchFormEvent"
        ></maintwo>
        <!--table表单-->
        <div>
            <!--主list列表-->
            <div class="box-list">
                <div style="padding: 10px 0 0 0; display: flex; flex-direction: row; flex-wrap: nowrap; justify-content: space-between; align-items: flex-start; gap: 20px">
                    <!--左侧名称-->
                    <div style="flex: 1">
                        <slot name="tabs"><span style="font-size: 14px; line-height: 60px">数据列表</span></slot>
                    </div>
                    <!--右侧按钮-->
                    <div>
                        <!--自定义按钮-点击出现弹框&点击跳转子类页面@click="routeraddrClick('/XX路由')"-->
                        <!--1、点击出现弹框@click="addTanClick(XX弹框配置信息,dialogVisible = true);resetFormSenior(XXform表单)"-->
                        <!--2、点击跳转子类页面@click="routeraddrClick('/XX路由')-->
                        <!--<el-button ></el-button>-->
                        <!--导出按钮-点击导出数据-->
                        <div style="display: flex; flex-direction: row; flex-wrap: nowrap; justify-content: right; align-items: center; gap: 0px">
                            <div>
                                <slot name="buttons"></slot>
                            </div>
                            <div><el-button type="warning" style="line-height: 16px; padding: 10px 20px; min-height: 20px" plain @click="exportDatas()">导出</el-button></div>
                        </div>
                    </div>
                </div>

                <div>
                    <!--table表单-->
                    <el-table
                        :header-cell-style="{ background: '#F5F7FA' }"
                        ref="multipleTable"
                        :row-class-name="tableRowClassName"
                        :data="mainthreetableData.slice((currentPage - lastpage) * PageSize, currentPage * PageSize)"
                        style="width: 100%"
                        min-height="900"
                        border
                        v-loading="loading"
                        element-loading-text="正在飞速加载中..."
                        @selection-change="handleSelectionChange"
                        @cell-click="handleOneData"
                    >
                        <!--多选框-用于用户选择性导出数据-->
                        <el-table-column type="selection" width="55" v-if="mainthreeisshowset" />
                        <el-table-column
                            v-for="(mytabletitle, i) in mainthreetabletitle"
                            :key="i"
                            :fixed="mytabletitle.fixedstatu"
                            :prop="mytabletitle.prop"
                            :label="mytabletitle.label"
                            :width="mytabletitle.width"
                        >
                            <!--这里先通过table列表中的title值进行判断，在进行渲染展示-->
                            <!--例如：status状态字段，判断最终的值是那种表现形式等-->
                            <template
                                #default="scope"
                                v-if="mytabletitle.prop === 'status' || mytabletitle.prop === 'outtime' || mytabletitle.prop === 'addtime' || mytabletitle.prop === 'express_type'"
                            >
                                <!-- 状态 -->
                                <div v-if="mytabletitle.prop === 'status'">
                                    <span :style="{ color: this.status_options[scope.row.status].color }">{{ this.status_options[scope.row.status].text }}</span>
                                </div>
                                <!--  出库时间-->
                                <div v-if="mytabletitle.prop === 'outtime'">
                                    <span>{{ scope.row.outtime != 0 ? this.$utils.formatDate(scope.row.outtime) : '' }}</span>
                                </div>
                                <!-- 创建时间 -->
                                <div v-if="mytabletitle.prop === 'addtime'">
                                    <span>{{ scope.row.addtime != 0 ? this.$utils.formatDate(scope.row.addtime) : '' }}</span>
                                </div>
                                <!-- 快递公司 -->
                                <div v-if="mytabletitle.prop === 'express_type'">
                                    <span>{{
                                        scope.row.express_type && scope.row.express_type != null && scope.row.express_type != 0
                                            ? this.express_type_options[scope.row.express_type]
                                            : ''
                                    }}</span>
                                </div>
                            </template>
                        </el-table-column>
                        <!--操作&按钮-->
                        <el-table-column fixed="right" label="操作" :width="mainthreehandle" prop="myoperation" id="operation">
                            <template #default="scope">
                                <div>
                                    <el-button
                                        type="primary"
                                        size="mini"
                                        @click="routeraddrClick(null, scope.row, is_all)"
                                        v-if="
                                            (mypowerlimits.ckd_my_xq && is_all == 0) ||
                                            (mypowerlimits.ckd_xq && is_all == 1) ||
                                            (mypowerlimits.ckd_jd_xq && is_all == 2) ||
                                            (mypowerlimits.ckd_jd_xq && is_all == 3)
                                        "
                                    >
                                        详情
                                    </el-button>
                                    <!-- v-if="" -->
                                    <el-button
                                        type="warning"
                                        size="mini"
                                        @click="tanBoxEvent(scope.row)"
                                        v-if="
                                            (scope.row.status == -1 || scope.row.status == 1) && ((mypowerlimits.ckd_my_cd && is_all == 0) || (mypowerlimits.ckd_cd && is_all == 1))
                                        "
                                    >
                                        撤单
                                    </el-button>
                                </div>
                            </template>
                        </el-table-column>
                    </el-table>
                    <!--分页器-->
                    <div class="block" style="text-align: center; margin: 30px 0">
                        <el-pagination
                            background
                            @size-change="handleSizeChange"
                            @current-change="handleCurrentChange"
                            :current-page="currentPage"
                            :page-sizes="pageSizes"
                            :page-size="PageSize"
                            layout="total, sizes, prev, pager, next, jumper"
                            :total="totalCount"
                        >
                        </el-pagination>
                    </div>
                </div>
            </div>
        </div>

        <!-- 弹窗 -->
        <div>
            <el-dialog v-model="tanbox.isshow" :title="tanbox.title" :width="tanbox.width" :before-close="handleClose">
                <div>
                    <div style="margin: 10px 0 20px 28px; color: rgb(255, 25, 0)"><span> 注：撤单后，产品库存数量，将原库返还</span></div>
                    <div>
                        <el-form ref="ruleForm" :model="ruleForm" :rules="ruleForm_rules" label-width="100px">
                            <el-form-item label="撤单原因" prop="reason">
                                <el-select v-model="ruleForm.reason" placeholder="请选择" style="width: 100%">
                                    <el-option v-for="(item, index) in reason_options" :key="index" :label="item.label" :value="item.value"></el-option>
                                </el-select>
                            </el-form-item>
                            <el-form-item label="撤单备注" prop="remark">
                                <el-input v-model="ruleForm.remark" type="textarea" maxlength="200" clearable></el-input>
                            </el-form-item>
                            <el-form-item style="text-align: right">
                                <el-button @click="resetEvent('ruleForm')">取消</el-button>
                                <el-button type="primary" @click="submitEvent('ruleForm')">确定</el-button>
                            </el-form-item>
                        </el-form>
                    </div>
                </div>
            </el-dialog>
        </div>
    </div>
</template>

<script>
import mainone from '@/components/Mainone'
import maintwo from '@/components/Maintwo'
import axios from 'axios'
import { defineComponent, ref } from 'vue'
import dict from '../../assets/js/dict.js'
import { ElMessage } from 'element-plus'

export default defineComponent({
    name: 'vgooutmonad',
    inject: ['reload'], //刷新引用
    props: {
        mainonebread: {
            default: [
                {
                    name: '仓库'
                },
                {
                    name: '出入库管理'
                },
                {
                    name: '出库单'
                }
            ]
        },
        is_all: {
            type: Number,
            default: 1
        }
    },
    watch: {
        is_all: {
            handler(newVal) {
                this.pagesdatas = {
                    page: 1,
                    page_size: 20
                }
                this.postAxiosEvent(this.pagesdatas)
            },
            deep: true,
            immediate: true
        }
    },
    data() {
        return {
            status_options: dict.wmsystem.status_options,
            maintwoiconshow: true, // 筛选-【高级筛选按钮】
            maintwoforminfo: {
                myinputoneisshow: true, //筛选查询-左侧字+input
                myinputone: {
                    divinterval: '0', //框右侧距离
                    titlewidth: '74px', //标题宽度
                    inputwidth: '200px', //input宽度
                    inputinterval: '20px', //input右侧距离
                    input: [
                        {
                            ismust: false, //是否必填，true表示必填
                            title: '出库编号：',
                            placeholder: '请输入出库编号',
                            name: 'trade_no',
                            content: ''
                        }
                    ]
                },
                selectleftisshow: true, //筛选查询-左侧字+select单框||select时间
                selectleft: {
                    width: 'none',
                    divinterval: '20px', //框右侧距离
                    selectwidth: '200px',
                    selectinterval: '', //select右侧距离
                    select: [
                        {
                            selecttimeduanisshow: true, //该字段表示时间段模块状态：且已写死（字段名不可修改!）
                            ismust: false,
                            title: '出库日期：',
                            placeholder: {
                                placeholderstart: '请选择开始时间',
                                placeholderend: '请选择结束时间'
                            },
                            name: 'date',
                            value: {
                                valuestart: ref(''),
                                valueend: ref(''),
                                endstatus: true
                            },
                            rangeseparator: '至', //分隔符
                            divinterval: '3%', //上方字中-框右侧距离
                            selectwidth: '100%', //上方字中-总框长度
                            selectmaxwidth: '97%', //时间段-最大宽度
                            marginright: '0' //与右侧距离
                        },
                        {
                            selectisshow: true,
                            ismust: false,
                            title: '出库类型：',
                            placeholder: '',
                            name: 'out_type',
                            value: '',
                            options: []
                        },
                        {
                            selectisshow: true,
                            ismust: false,
                            title: '仓库：',
                            placeholder: '',
                            name: 'pack_id',
                            value: '',
                            options: [
                                {
                                    value: '',
                                    label: '全部'
                                }
                            ]
                        }
                    ]
                }
            }, // 筛选-控件信息
            mainthreeisshowbut: false, // 数据列表中-【添加按钮】
            mainthreeisshowset: false, // 数据列表中-复选框
            mainthreeisshowope: true, // 数据列表中-操作-根据页面显示模块（整页）
            mainthreetableope: 26, // 数据列表中-操作-根据页面显示控件（整页）
            mainthreehandle: 120, // 数据列表中-操作-宽度
            mainthreetabletitle: [
                {
                    fixedstatu: 'left',
                    prop: 'trade_no',
                    label: '出库编号',
                    width: '240'
                },
                {
                    fixedstatu: false,
                    prop: 'out_type_name',
                    label: '出库类型',
                    width: '100'
                },
                {
                    fixedstatu: false,
                    prop: 'outdate',
                    label: '出库日期',
                    width: '120'
                },
                {
                    fixedstatu: false,
                    prop: 'pack_name',
                    label: '仓库',
                    width: '100'
                },
                {
                    fixedstatu: false,
                    prop: 'receive_man',
                    label: '收货人',
                    width: '100'
                },
                {
                    fixedstatu: false,
                    prop: 'receive_mobile',
                    label: '收货人电话',
                    width: '150'
                },
                {
                    fixedstatu: false,
                    prop: 'receive_address',
                    label: '收货人地址',
                    width: '200'
                },
                {
                    fixedstatu: false,
                    prop: 'expect_num',
                    label: '预出库量',
                    width: '100'
                },
                {
                    fixedstatu: false,
                    prop: 'real_num',
                    label: '实出库量',
                    width: '100'
                },
                {
                    fixedstatu: false,
                    prop: 'express_type',
                    label: '快递公司',
                    width: '180'
                },
                {
                    fixedstatu: false,
                    prop: 'express_number',
                    label: '快递单号',
                    width: '240'
                },
                {
                    fixedstatu: false,
                    prop: 'status',
                    label: '状态',
                    width: '80'
                },
                {
                    fixedstatu: false,
                    prop: 'get_truename',
                    label: '接单人',
                    width: '100'
                },
                {
                    fixedstatu: false,
                    prop: 'outtime',
                    label: '出库时间',
                    width: '180'
                },
                {
                    fixedstatu: false,
                    prop: 'create_truename',
                    label: '创建人',
                    width: '100'
                },
                {
                    fixedstatu: false,
                    prop: 'addtime',
                    label: '创建时间',
                    width: '180'
                }
            ], // 数据列表中-数据表头
            mainthreestatus: true, // 数据列表中-属于search按钮表示状态
            mainthreetableData: [], // 数据列表中-具体数据
            totalCount: 1, //数据列表中-数据总条数
            currentpage: 1, //数据列表中-数据当前页数
            lastpage: 1, //数据列表中-数据总页数（默认以20条一页）
            loading: false, //初始化-loading加载动效，默认是false关闭

            //table表单相关数据
            seniorform: {},
            currentPage: 1,
            pageSizes: [20, 50, 100],
            PageSize: 20,
            multipleSelection: [],

            //高级筛选
            myinputishow: true, //高级筛选-input单框
            myinput: {
                divinterval: '3%', //框右侧距离
                inputwidth: '47%',
                inputmaxwidth: '100%',
                input: [
                    {
                        ismust: false, //是否必填，true表示必填
                        title: '出库编号：',
                        placeholder: '请输入出库编号',
                        name: 'trade_no',
                        content: ''
                    },
                    {
                        ismust: false, //是否必填，true表示必填
                        title: '收货人：',
                        placeholder: '请输入收货人',
                        name: 'receive_man',
                        content: ''
                    },
                    {
                        ismust: false, //是否必填，true表示必填
                        title: '收货人电话：',
                        placeholder: '请输入收货人电话',
                        name: 'receive_mobile',
                        content: ''
                    },
                    {
                        ismust: false, //是否必填，true表示必填
                        title: '收货人地址：',
                        placeholder: '请输入收货人地址',
                        name: 'receive_address',
                        content: ''
                    },
                    {
                        ismust: false, //是否必填，true表示必填
                        title: '快递运单号：',
                        placeholder: '请输入快递运单号',
                        name: 'express_number',
                        content: ''
                    }
                ]
            },
            myselectisshow: true, //高级筛选-select单框
            myselect: {
                divinterval: '3%', //框右侧距离
                selectwidth: '47%',
                selectmaxwidth: '100%',
                select: [
                    {
                        selectisshow: true,
                        ismust: false,
                        title: '快递公司：',
                        placeholder: '全部',
                        name: 'express_type',
                        value: '',
                        options: []
                    },
                    {
                        selectisshow: true,
                        ismust: false,
                        onchange: 2,
                        title: '出库类型:',
                        placeholder: '请选择平台',
                        name: 'out_type',
                        value: '',
                        options: []
                    },
                    {
                        selectisshow: true,
                        ismust: false,
                        title: '仓库:',
                        placeholder: '全部',
                        name: 'pack_id',
                        value: '',
                        options: []
                    },
                    {
                        selectisshow: true,
                        ismust: false,
                        onchange: 3,
                        title: '状态:',
                        placeholder: '全部',
                        name: 'status',
                        value: '',
                        options: []
                    },
                    {
                        selecttimeduanisshow: true, //该字段表示时间段模块状态：且已写死（字段名不可修改!）
                        ismust: false,
                        title: '出库日期：',
                        placeholder: {
                            placeholderstart: '请选择开始时间',
                            placeholderend: '请选择结束时间'
                        },
                        name: 'date', //参数名
                        value: {
                            valuestart: ref(''),
                            valueend: ref(''),
                            endstatus: true
                        },
                        rangeseparator: '至', //分隔符
                        divinterval: '3%', //上方字中-框右侧距离
                        selectwidth: '100%', //上方字中-总框长度
                        selectmaxwidth: '97%', //时间段-最大宽度
                        marginright: '0' //与右侧距离
                    },
                    {
                        selecttimeduanisshow: true, //该字段表示时间段模块状态：且已写死（字段名不可修改!）
                        ismust: false,
                        title: '出库时间：',
                        placeholder: {
                            placeholderstart: '请选择开始时间',
                            placeholderend: '请选择结束时间'
                        },
                        name: 'outtime', //参数名
                        value: {
                            valuestart: ref(''),
                            valueend: ref(''),
                            endstatus: true
                        },
                        rangeseparator: '至', //分隔符
                        divinterval: '3%', //上方字中-框右侧距离
                        selectwidth: '100%', //上方字中-总框长度
                        selectmaxwidth: '97%', //时间段-最大宽度
                        marginright: '0' //与右侧距离
                    },
                    {
                        selecttimeduanisshow: true, //该字段表示时间段模块状态：且已写死（字段名不可修改!）
                        ismust: false,
                        title: '创建时间：',
                        placeholder: {
                            placeholderstart: '请选择开始时间',
                            placeholderend: '请选择结束时间'
                        },
                        name: 'addtime', //参数名
                        value: {
                            valuestart: ref(''),
                            valueend: ref(''),
                            endstatus: true
                        },
                        rangeseparator: '至', //分隔符
                        divinterval: '3%', //上方字中-框右侧距离
                        selectwidth: '100%', //上方字中-总框长度
                        selectmaxwidth: '97%', //时间段-最大宽度
                        marginright: '0' //与右侧距离
                    }
                ]
            },
            myinputonefullgaoisshow: true, //高级筛选-自动建议下拉框
            myinputonefullgao: {
                divinterval: '3%', //框右侧距离
                inputwidth: '47%', //input宽度
                inputmaxwidth: '100%',
                input: [
                    {
                        ismust: false, //是否必填，true表示必填
                        title: '接单人：',
                        placeholder: '请输入接单人', //此name为键值
                        name: 'get_admin_id',
                        content: '',
                        id: '', //此id为给后台的id值
                        url: '/manage/Manage/userList',
                        posttype: 'get',
                        inputval: { truename: '' } //这里记录需要传的参数
                    },
                    {
                        ismust: false, //是否必填，true表示必填
                        title: '创建人：',
                        placeholder: '请输入创建人', //此name为键值
                        name: 'create_admin_id',
                        content: '',
                        id: '', //此id为给后台的id值
                        url: '/manage/Manage/userList',
                        posttype: 'get',
                        inputval: { truename: '' } //这里记录需要传的参数
                    }
                ]
            },

            // 弹窗
            tanbox: {
                isshow: false,
                title: '撤单',
                width: '30%'
            },
            ruleForm: {
                id: '',
                reason: '',
                remark: ''
            },
            ruleForm_rules: {
                id: [
                    {
                        required: true,
                        message: 'id为必须项',
                        trigger: 'blur'
                    }
                ],
                reason: [
                    {
                        required: true,
                        message: '撤单原因为必选项',
                        trigger: 'blur'
                    }
                ],
                remark: [
                    {
                        required: false,
                        message: '撤单备注为必填项',
                        trigger: 'blur'
                    }
                ]
            },
            reason_options: [],
            express_type_options: {},

            mypowerlimits: JSON.parse(localStorage.getItem('powerlimits')), //获取权限
            mytoken: localStorage.getItem('token'), //获取存储的token
            pagesdatas: [] //存储最近一次的筛选条件，供分页使用
        }
    },
    methods: {
        // 弹窗
        tanBoxEvent(data) {
            this.ruleForm.id = data.id
            this.tanbox.isshow = true
        },
        submitEvent(formName) {
            let getdatas = this.$refs[formName].model
            this.$refs[formName].validate((valid) => {
                if (valid) {
                    axios
                        .post(
                            '/manage/packout/cancel' + this.is_all,
                            {
                                id: getdatas.id,
                                reason: getdatas.reason,
                                remark: getdatas.remark
                            },
                            {
                                headers: {
                                    Auth: localStorage.getItem('token')
                                }
                            }
                        )
                        .then((response) => {
                            if (response.data.code === 0) {
                                ElMessage({
                                    type: 'success',
                                    message: '操作成功！',
                                    duration: 1000,
                                    onClose: () => {
                                        this.tanbox.isshow = false
                                        this.resetEvent('ruleForm')
                                        this.handleCurrentChange(this.currentPage)
                                    }
                                })
                            }
                        })
                        .catch(function () {})
                } else {
                    return false
                }
            })
        },
        resetEvent(formName) {
            this.$refs[formName].resetFields()
            this.tanbox.isshow = false
        },
        handleClose(done) {
            done()
            this.resetEvent('ruleForm')
        },
        //点击进行路由页面跳转以及携带参数
        routeraddrClick(routeraddr, data, flag) {
            if (data) {
                if (flag == 1) {
                    routeraddr = '/gooutmonadinfo'
                } else if (flag == 0) {
                    routeraddr = '/gooutreportmonadinfo'
                } else if (flag == 2 || flag == 3) {
                    routeraddr = '/gooutreceivemonadinfo'
                }
                this.$router.push({ path: routeraddr, query: { id: data.id, is_all: this.is_all } }).catch((error) => error)
            } else {
                this.$router.push(routeraddr).catch((error) => error)
            }
        },
        //table表中-当选项发生变化是触发该函数
        handleSelectionChange(val) {
            this.multipleSelection = val
        },
        //分页器中-当选择条数时触发该函数
        handleSizeChange(val) {
            // console.log(`每页 ${val} 条`)
            this.PageSize = val
            this.currentPage = 1

            this.pagesdatas = {
                trade_no: this.pagesdatas.trade_no,
                receive_man: this.pagesdatas.receive_man,
                receive_mobile: this.pagesdatas.receive_mobile,
                receive_address: this.pagesdatas.receive_address,
                get_admin_id: this.pagesdatas.get_admin_id,
                create_admin_id: this.pagesdatas.create_admin_id,
                express_number: this.pagesdatas.express_number,
                express_type: this.pagesdatas.express_type,
                pack_id: this.pagesdatas.pack_id,
                out_type: this.pagesdatas.out_type,
                status: this.pagesdatas.status,
                date: this.pagesdatas.date,
                outtime: this.pagesdatas.outtime,
                addtime: this.pagesdatas.addtime,
                page: 1,
                page_size: val
            }

            this.postAxiosEvent(this.pagesdatas)
        },
        //分页器中-当选择分页时触发该函数
        handleCurrentChange(val) {
            // console.log(`当前页: 第${val}页`)
            this.currentPage = val

            this.pagesdatas = {
                trade_no: this.pagesdatas.trade_no,
                receive_man: this.pagesdatas.receive_man,
                receive_mobile: this.pagesdatas.receive_mobile,
                receive_address: this.pagesdatas.receive_address,
                get_admin_id: this.pagesdatas.get_admin_id,
                create_admin_id: this.pagesdatas.create_admin_id,
                express_number: this.pagesdatas.express_number,
                express_type: this.pagesdatas.express_type,
                pack_id: this.pagesdatas.pack_id,
                out_type: this.pagesdatas.out_type,
                status: this.pagesdatas.status,
                date: this.pagesdatas.date,
                outtime: this.pagesdatas.outtime,
                addtime: this.pagesdatas.addtime,
                page: val,
                page_size: this.PageSize
            }

            this.postAxiosEvent(this.pagesdatas)
        },
        //导出数据
        exportDatas() {
            axios
                .post(
                    '/manage/packout/index' + this.is_all,
                    {
                        trade_no: this.pagesdatas.trade_no,
                        receive_man: this.pagesdatas.receive_man,
                        receive_mobile: this.pagesdatas.receive_mobile,
                        receive_address: this.pagesdatas.receive_address,
                        get_admin_id: this.pagesdatas.get_admin_id,
                        create_admin_id: this.pagesdatas.create_admin_id,
                        express_number: this.pagesdatas.express_number,
                        express_type: this.pagesdatas.express_type,
                        pack_id: this.pagesdatas.pack_id,
                        out_type: this.pagesdatas.out_type,
                        status: this.pagesdatas.status,
                        date: this.pagesdatas.date,
                        outtime: this.pagesdatas.outtime,
                        addtime: this.pagesdatas.addtime,
                        export: 1
                    },
                    {
                        headers: {
                            Auth: localStorage.getItem('token')
                        }
                    }
                )
                .then((response) => {
                    this.myaxiosfunc(response.data.result)
                })
                .catch(function (error) {
                    // 请求失败处理
                    console.log(error)
                })
        },

        //提交等相关event事件函数
        //获取Container-Pagedata-Maintwo-高级筛选-开始检索返回的数据
        searchFormEvent(data) {
            // console.log('高级筛选-开始检索返回数据(页面):', data)
            var getdate = null
            if (data.date.start == '') {
                getdate = ''
            } else {
                if (data.date.end == '') {
                    getdate = data.date.start + ' ~ ' + (data.date.start + 86400 - 1)
                } else {
                    getdate = data.date.start + ' ~ ' + (data.date.end + 86400 - 1)
                }
            }
            var getouttime = null
            if (data.outtime.start == '') {
                getouttime = ''
            } else {
                if (data.outtime.end == '') {
                    getouttime = data.outtime.start + ' ~ ' + (data.outtime.start + 86400 - 1)
                } else {
                    getouttime = data.outtime.start + ' ~ ' + (data.outtime.end + 86400 - 1)
                }
            }
            var getaddtime = null
            if (data.addtime.start == '') {
                getaddtime = ''
            } else {
                if (data.addtime.end == '') {
                    getaddtime = data.addtime.start + ' ~ ' + (data.addtime.start + 86400 - 1)
                } else {
                    getaddtime = data.addtime.start + ' ~ ' + (data.addtime.end + 86400 - 1)
                }
            }

            this.pagesdatas = {
                trade_no: data.trade_no,
                receive_man: data.receive_man,
                receive_mobile: data.receive_mobile,
                receive_address: data.receive_address,
                get_admin_id: data.get_admin_id,
                create_admin_id: data.create_admin_id,
                express_number: data.express_number,
                express_type: data.express_type,
                pack_id: data.pack_id,
                out_type: data.out_type,
                status: data.status,
                date: getdate,
                outtime: getouttime,
                addtime: getaddtime,
                page: 1,
                page_size: this.PageSize
            }

            this.postAxiosEvent(this.pagesdatas)
        },
        //获取Container-Pagedata-Maintwo-搜索-返回的数据
        getSeachinfoEvent(data) {
            // console.log('点击查询获取的data数组：' + JSON.stringify(data))
            var getdate = null
            if (data.date.start == '') {
                getdate = ''
            } else {
                if (data.date.end == '') {
                    getdate = data.date.start + ' ~ ' + (data.date.start + 86400 - 1)
                } else {
                    getdate = data.date.start + ' ~ ' + (data.date.end + 86400 - 1)
                }
            }

            this.pagesdatas = {
                date: getdate,
                trade_no: data.trade_no,
                out_type: data.out_type,
                pack_id: data.pack_id,
                page: 1,
                page_size: this.PageSize
            }

            this.postAxiosEvent(this.pagesdatas)
        },
        //数据渲染
        myaxiosfunc(successdata) {
            var newdata = successdata.data
            // console.log('当前页面的data:' + JSON.stringify(newdata))
            this.mainthreetableData = newdata
            this.totalCount = successdata.total
            this.currentpage = successdata.current_page //当前页面页数
            this.lastpage = successdata.last_page
            this.loading = false
        },
        // 接口
        postAxiosEvent(data) {
            this.loading = true

            axios
                .post('/manage/packout/index' + this.is_all, data, {
                    headers: {
                        Auth: localStorage.getItem('token')
                    }
                })
                .then((response) => {
                    this.myaxiosfunc(response.data.result)
                })
                .catch(function (error) {
                    // 请求失败处理
                    console.log(error)
                })
        },
        // 获取撤单原因
        getChedanReasonEvent() {
            axios
                .post(
                    '/manage/config/info',
                    {
                        key_name: 'pack_cancel_reason'
                    },
                    {
                        headers: {
                            Auth: localStorage.getItem('token')
                        }
                    }
                )
                .then((response) => {
                    let getdatas = response.data.result
                    this.reason_options = getdatas.map((n) => {
                        return { label: n, value: n }
                    })
                })
                .catch(function () {})
        },
        // 获取各种下拉
        getAllDownLoading() {
            var options = [{ value: '', label: '全部' }]
            // 获取状态
            let get_status_options = Object.keys(this.status_options)
            let status_array = get_status_options.map((n) => {
                return { label: this.status_options[n].text, value: n }
            })
            this.myselect.select[3].options = [...options, ...status_array]
            // 获取快递公司
            axios
                .post(
                    '/manage/config/info',
                    {
                        key_name: 'express_set'
                    },
                    {
                        headers: {
                            Auth: localStorage.getItem('token')
                        }
                    }
                )
                .then((response) => {
                    let getdatas = response.data.result
                    let new_datas = Object.keys(getdatas).map((n) => {
                        return { label: getdatas[n], value: n }
                    })
                    this.myselect.select[0].options = [...options, ...new_datas]
                    this.express_type_options = response.data.result
                })
            // 出库类型
            axios
                .get('/manage/WarehouseConfig/outInTypeList', {
                    headers: {
                        Auth: localStorage.getItem('token')
                    },
                    params: {
                        page: 1,
                        page_size: 10000
                    }
                })
                .then((response) => {
                    let getdatas = response.data.result.data
                    let new_datas = getdatas.map((n) => {
                        return { label: n.name, value: n.id }
                    })
                    this.maintwoforminfo.selectleft.select[1].options = [...options, ...new_datas]
                    this.myselect.select[1].options = [...options, ...new_datas]
                })
                .catch(function (error) {
                    console.log(error)
                })

            // 仓库下拉
            axios
                .post(
                    '/manage/pack/packIndex',
                    { page: 1, page_size: 10000 },
                    {
                        headers: {
                            Auth: localStorage.getItem('token')
                        }
                    }
                )
                .then((response) => {
                    let getdatas = response.data.result.data
                    let new_datas = getdatas.map((n) => {
                        return { label: n.name, value: n.id }
                    })
                    this.maintwoforminfo.selectleft.select[2].options = [...options, ...new_datas]
                    this.myselect.select[2].options = [...options, ...new_datas]
                })
        }
    },
    created() {
        if (localStorage.getItem('token') === null || !localStorage.getItem('token')) {
            this.isshow = false
            this.$router.push('/').catch((error) => error)
        } else {
            // 获取撤单原因
            this.getChedanReasonEvent()
            this.getAllDownLoading()

            this.pagesdatas = {
                page: 1,
                page_size: 20
            }
            this.postAxiosEvent(this.pagesdatas)
        }
    },
    components: {
        mainone,
        maintwo
    }
})
</script>

<style scoped>
/*操作中-按钮样式*/
.el-button--mini {
    min-height: 28px;
    padding: 0 8px;
    font-size: 12px;
    border-radius: calc(var(--el-border-radius-base) - 1px);
}

.box-list {
    margin-top: 10px;
    background-color: #ffffff;
    border-radius: 4px;
    line-height: 40px;
    padding: 0 20px;
}
</style>
