<template>
    <div class="details-box">
        <div v-for="(item, index) in datas" :key="index" class="data-box">
            <div class="title-box">
                <span>{{ item.title + ':' }}</span>
            </div>
            <div class="content-box">
                <div v-if="item.imgs">
                    <dwpImages :images="item.value" />
                </div>
                <div v-else style="text-align: left">
                    <span>{{ item.value }}</span>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { defineComponent } from 'vue'
import dwpImages from './dpw-images.vue'

export default defineComponent({
    name: 'dpw-details',
    props: {
        datas: {
            default: []
        }
    },
    data() {
        return {}
    },
    components: {
        dwpImages
    }
})
</script>

<style scoped>
.details-box {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: flex-start;
    flex-wrap: wrap;
    gap: 20px;
    font-size: 14px;
    color: #333;
}

.flex-1 {
    flex: 1;
}

.data-box {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: flex-start;
    flex-wrap: nowrap;
    gap: 10px;
}

.title-box {
    min-width: 80px;
    text-align: right;
}
.content-box {
    min-width: 240px;
}
</style>
