<template>
    <div class="wrap">
        <el-dialog v-model="dialogDisplay" :title="formData.id ? '添加' : '编辑'">
            <el-form ref="ruleFormRef" :model="formData" :rules="rules" label-width="150px" style="padding: 0 20px 0 0">
                <el-form-item label="标签组名称：" prop="pid">
                    <el-select v-model="formData.pid" style="width: 80%">
                        <el-option v-for="item in tagGrounpList" :key="item.value" :label="item.name" :value="item.id"/>
                    </el-select>
                </el-form-item>
                <el-form-item label="标签名称：" prop="name">
                    <el-input v-model="formData.name" maxlength="15" style="width: 80%"></el-input>
                </el-form-item>
                <el-form-item label="对应关键词：" prop="keyword">
                    <div>每个关键词最多15个字。同时新建多个关键词时，请用“；”隔开</div>
                    <el-input v-model="formData.keyword" type="textarea" style="width: 80%"></el-input>                        
                </el-form-item>
                <el-form-item label="触发值：" prop="trigger">
                    <div>关键词的权重达到对应次数时，打相应标签</div>
                    <el-input v-model.number="formData.trigger" style="width: 80%"></el-input>
                    <div class="title">权重的计算方法：</div>
                    <div class="text">搜索内容：5/每次，打印文档：4/每次，收藏文档：3/每次，上传文档：2/每次</div>
                </el-form-item>
                <el-form-item label="有效期：" prop="valid">
                    <el-select v-model="formData.valid" style="width: 80%">
                        <el-option v-for="item in validOptions" :key="item.value" :label="item.label" :value="item.value"/>
                    </el-select>
                </el-form-item>
                <div style="text-align: right">
                    <el-button @click="resetEvent">取消 </el-button>
                    <el-button type="primary" @click="onSubmit">确定 </el-button>
                </div>
            </el-form>
        </el-dialog>
    </div>
</template>

<script>
import axios from 'axios'
import { ElMessage } from 'element-plus'

export default {
    name: 'TagsgroupFrom',
    props: {
        tagsData: {
            type: Object,
            default: () => {
                return {
                    dialog: false,
                    data: {},
                    api: '',
                }
            }
        },

        tagGrounpList: {
            type: Array,
            default: () => {
                return []
            }
        }
    },
    data() {
        return {
            dialogDisplay: false,
            formData: {
                id: null,
                pid: '',
                name: '',
                keyword: '',
                trigger: 4,
                valid: '',
            },

            rules: {
                pid: [
                    {
                        required: true,
                        message: '标签组名称不能为空',
                        trigger: 'blur'
                    }
                ],
                name: [
                    {
                        required: true,
                        message: '标签名称不能为空',
                        trigger: 'blur'
                    }
                ],
                keyword: [
                    {
                        required: true,
                        message: '关键词不能为空',
                        trigger: 'blur'
                    }
                ],
                trigger: [
                    {
                        required: true,
                        message: '触发值不能为空',
                        trigger: 'blur'
                    },
                    {
                        type: 'number',
                        message: '触发值必须为数字',
                    },
                    {
                        validator: (rule, value, callback) => {
                            if (value < 1) {
                                callback(new Error('触发值不能小于1'))
                            } else if (value > 100) {
                                callback(new Error('触发值不能大于100'))
                            } else {
                                callback()
                            }
                        },
                        trigger: 'blur'
                    }
                ],
                valid: [
                    {
                        required: true,
                        message: '有效期不能为空',
                        trigger: 'blur'
                    }
                ],
            },

            validOptions: [
                {
                    value: '1',
                    label: '一个月',
                },
                {
                    value: '2',
                    label: '两个月',
                },
                {
                    value: '3',
                    label: '三个月',
                },
                {
                    value: '4',
                    label: '半年',
                },
                {
                    value: '5',
                    label: '一年',
                },
                {
                    value: '0',
                    label: '永久',
                },
            ],

            mypowerlimits: JSON.parse(localStorage.getItem('powerlimits')), //获取权限
            mytoken: localStorage.getItem('token'), //获取存储的token
            myuserinfo: localStorage.getItem('userinfo') //获取存储的用户信息
        }
    },

    watch: {
        tagsData: {
            handler(newVal) {
                this.dialogDisplay = newVal.dialog
                this.formData = {
                    id: newVal.data.id || null,
                    pid: newVal.data.pid || '',
                    name: newVal.data.name || '',
                    keyword: newVal.data.keyword || '',
                    trigger: newVal.data.trigger || 4,
                    valid: newVal.data.valid || '0',
                }
                this.api = newVal.api
            },
            deep: true,
            immediate: true
        }
    },

    methods: {
        //发行量弹框确认
        async onSubmit() {
            
            try{
                await this.$refs['ruleFormRef'].validate()
            }catch(err){
                console.log({err})
                return false
            }

            let { keyword } = this.formData
            keyword = keyword.replace(/;/g, '；')
            const keywordArr = keyword.split('；')
            const keywordArrFilter = keywordArr.filter(ele => ele.length > 15)
            if(keywordArrFilter.length > 0){
                ElMessage({
                    type: 'error',
                    message: `每个关键词最多15个字！“${keywordArrFilter.join('；')}” 超过15个字！`,
                    duration: 1000,
                })
                return false
            }

            try {
                const { data } = await axios.post(this.tagsData.api, { ...this.formData }, { headers: { Auth: localStorage.getItem('token')} })

                if (data.code === 0) {
                    this.dialogDisplay = false
                    ElMessage({
                        type: 'success',
                        message: '操作成功！',
                        duration: 1000,
                        onClose: () => {
                            this.$emit('success', true)
                        }
                    })
                }

            } catch (error) {
                console.log(error)
            }
        },

        //增加发行量输入框弹出框-取消
        resetEvent() {
            this.$refs['ruleFormRef'].resetFields() //重置弹窗内容
            this.dialogDisplay = false
        }
    },
}
</script>

<style scoped>
.tips-box {
    height: 20px;
    line-height: 20px;
}

.title {
    margin-top: 10px;
    line-height: 25px;
}
.text {
    line-height: 25px;
}
</style>
