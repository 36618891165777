<template>
    <div class="page-container">
        <div class="header">
            <div class="tip">注意:删除后，租户端已存在的规则列表，关注规则不生效，[企业微信] 字段为已删除,</div>
            <div>
                <el-button @click="getList">刷新</el-button>
                <el-button type="primary" @click="table.list.push({ edit: true, status: 1 })">新增</el-button>
            </div>
        </div>
        <el-table :data="table.data" border v-loading="table.loading">
            <el-table-column :label="item.title" :column-key="item.key" :width="item.width" v-for="(item, i) in table.cols" :key="i">
                <template #default="record">
                    <template v-if="item.key === 'action'">
                        <el-button type="primary" size="mini" @click="record.row.edit = !record.row.edit"> {{ record.row.edit ? '取消' : '编辑' }} </el-button>
                        <el-button type="warning" size="mini" @click="remove(record.row)"> 删除 </el-button>
                    </template>
                    <template v-else-if="record.row.edit && item.key != 'id'">
                        <el-input v-model="record.row[item.key]" :placeholder="item.title"></el-input>
                    </template>
                    <template v-else>{{ record.row[item.key] }}</template>
                </template>
            </el-table-column>
        </el-table>
        <div class="footer">
            <el-button type="primary" @click="submit">保存</el-button>
        </div>
    </div>
</template>
<script>
import { reactive, watchEffect } from 'vue'
import axios from 'axios'
import { useRoute } from 'vue-router'
import { ElMessage } from 'element-plus'
export default {
    setup() {
        const cols = [
            { key: 'corp_name', title: '企业名称' },
            { key: 'corpid', title: '企业ID' },
            { key: 'yy_secret', title: '应用Secret' },
            { key: 'yy_token', title: '应用token' },
            { key: 'all_token', title: '企业token' },
            { key: 'all_secret', title: '企业Secret' },
            { key: 'encoding_aes_key', title: '企业密钥' },
            { key: 'yy_encoding_aes_key', title: '应用密钥' },
            { key: 'agentid', title: '应用id' }
        ]

        const route = useRoute()
        const table = reactive({
            loading: false,
            data: [],
            list: [],
            cols: [{ key: 'id', title: 'ID', width: 50 }, ...cols, { key: 'action', title: '操作', width: 140 }]
        })
        const option = { headers: { Auth: localStorage.getItem('token') } }

        const getList = async () => {
            table.loading = true
            try {
                const { data } = await axios.post('/manage/Manage/corpIndex', { companyid: route.query.id, page_size: 10000 }, option)
                const { code, result } = data
                if (code == 0) table.list = result.data
            } catch (e) {
                console.error(e)
            }
            table.loading = false
        }

        watchEffect(() => {
            table.data = table.list.filter((item) => item.status !== -2)
        })

        const remove = async (row) => {
            if (row.id != null) {
                row.status = -2
            } else {
                const index = table.list.findIndex((item) => item === row)
                table.list.splice(index, 1)
            }
        }

        const submit = async () => {
            const list = JSON.parse(JSON.stringify(table.list))
            if (list.length) {
                for (let i = 0; i < list.length; i++) {
                    delete list[i].edit
                    const node = cols.find((item) => !list[i][item.key])
                    if (node) return ElMessage.error(`第 ${i + 1} 行中，${node.title}不能为空！`)
                }
                const payload = await axios.post('/manage/Manage/corpSave', { companyid: route.query.id, data: list }, option)
                if (payload.data.code == 0) getList()
            } else {
                ElMessage.error(`请新增企业微信配置！`)
            }
        }

        getList()

        return {
            table,
            getList,
            remove,
            submit
        }
    }
}
</script>
<style scoped>
.header {
    padding: 10px;
    display: flex;
    align-items: center;
    justify-content: space-between;
}
.header .tip {
    color: #ff0000ae;
    font-size: 14px;
}

.footer {
    padding: 10px;
}
</style>
