<template>
    <div class="title-box">
        <div class="title-box-icon"></div>
        <div>
            <span>{{ title }}</span>
        </div>
    </div>
</template>

<script>
import { defineComponent } from 'vue'

export default defineComponent({
    name: 'dpw-title',
    props: {
        title: {
            type: String,
            default: '基本信息'
        }
    },
    data() {
        return {}
    }
})
</script>

<style scoped>
.title-box {
    color: #333;
    font-size: 16px;
    font-weight: bold;
    margin-bottom: 20px;
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: left;
    align-items: center;
    gap: 10px;
}

.title-box-icon {
    width: 4px;
    height: 20px;
    background-color: var(--el-color-primary);
}
</style>
