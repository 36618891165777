<template>
    <div>
        <mainone :mainonebread="mainonebread"></mainone>
        <maintwo
            :maintwoiconshow="maintwoiconshow"
            :maintwoforminfo="maintwoforminfo"
            :myinputoneisshow="maintwoforminfo.myinputoneisshow"
            :myinputone="maintwoforminfo.myinputone"
            :myselectleftisshow="maintwoforminfo.selectleftisshow"
            :myselectleft="maintwoforminfo.selectleft"
            :myinputishow="myinputishow"
            :myinput="myinput"
            :myinputonefullisshow="maintwoforminfo.myinputonefullisshow"
            :myinputonefull="maintwoforminfo.myinputonefull"
            :myselectisshow="myselectisshow"
            :myselect="myselect"
            :myselectcityisshow="myselectcityisshow"
            :myselectcity="myselectcity"
            v-on:getsearchinfo="getSeachinfoEvent"
            v-on:searchForm="searchFormEvent"
        ></maintwo>
        <!--table表单-->
        <div>
            <!--主list列表-->
            <div class="box-list">
                <div style="line-height: 60px">
                    <!--左侧名称-->
                    <div style="float: left; font-size: 14px">数据列表</div>
                    <!--右侧按钮-->
                    <div style="float: right">
                        <!--自定义按钮-点击出现弹框&点击跳转子类页面@click="routeraddrClick('/XX路由')"-->
                        <!--1、点击出现弹框@click="addTanClick(XX弹框配置信息,dialogVisible = true);resetFormSenior(XXform表单)"-->
                        <!--2、点击跳转子类页面@click="routeraddrClick('/XX路由')-->
                        <!--<el-button ></el-button>-->
                        <!--导出按钮-点击导出数据-->
                        <el-button type="primary" plain @click="routeraddrClick('/goinmonadadd')" v-if="mypowerlimits.rkd_tjrkd">添加入库单</el-button>
                        <el-button type="warning" plain @click="exportDatas()">导出</el-button>
                    </div>
                </div>

                <div>
                    <!--table表单-->
                    <el-table
                        :header-cell-style="{ background: '#F5F7FA' }"
                        ref="multipleTable"
                        :row-class-name="tableRowClassName"
                        :data="mainthreetableData.slice((currentPage - lastpage) * PageSize, currentPage * PageSize)"
                        style="width: 100%"
                        min-height="900"
                        border
                        v-loading="loading"
                        element-loading-text="正在飞速加载中..."
                        @selection-change="handleSelectionChange"
                        @cell-click="handleOneData"
                    >
                        <!--多选框-用于用户选择性导出数据-->
                        <el-table-column type="selection" width="55" v-if="mainthreeisshowset" />
                        <el-table-column
                            v-for="(mytabletitle, i) in mainthreetabletitle"
                            :key="i"
                            :fixed="mytabletitle.fixedstatu"
                            :prop="mytabletitle.prop"
                            :label="mytabletitle.label"
                            :width="mytabletitle.width"
                        >
                            <!--这里先通过table列表中的title值进行判断，在进行渲染展示-->
                            <!--例如：status状态字段，判断最终的值是那种表现形式等-->
                            <template #default="scope" v-if="mytabletitle.prop === 'in_time' || mytabletitle.prop === 'remark' || mytabletitle.prop === 'addtime'">
                                <!-- 入库时间 -->
                                <div v-if="mytabletitle.prop === 'in_time'">
                                    <span>{{ scope.row.in_time != 0 ? this.$utils.formatDate(scope.row.in_time).substr(0, 10) : '' }}</span>
                                </div>
                                <!-- 创建时间 -->
                                <div v-if="mytabletitle.prop === 'addtime'">
                                    <span>{{ scope.row.addtime != 0 ? this.$utils.formatDate(scope.row.addtime) : '' }}</span>
                                </div>
                                <!-- 备注 -->
                                <div v-if="mytabletitle.prop === 'remark'">
                                    <el-popover placement="top" :width="600" trigger="hover" :content="scope.row.remark">
                                        <template #reference>
                                            <div class="no-warp-4" style="cursor: pointer">
                                                <span>{{ scope.row.remark }}</span>
                                            </div>
                                        </template>
                                    </el-popover>
                                </div>
                            </template>
                        </el-table-column>
                        <!--操作&按钮-->
                        <el-table-column fixed="right" label="操作" :width="mainthreehandle" prop="myoperation" id="operation">
                            <template #default="scope">
                                <div>
                                    <el-button type="primary" size="mini" @click="routeraddrClick('/goinmonadinfo', scope.row)" v-if="mypowerlimits.rkd_xq">详情</el-button>
                                </div>
                            </template>
                        </el-table-column>
                    </el-table>
                    <!--分页器-->
                    <div class="block" style="text-align: center; margin: 30px 0">
                        <el-pagination
                            background
                            @size-change="handleSizeChange"
                            @current-change="handleCurrentChange"
                            :current-page="currentPage"
                            :page-sizes="pageSizes"
                            :page-size="PageSize"
                            layout="total, sizes, prev, pager, next, jumper"
                            :total="totalCount"
                        >
                        </el-pagination>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import mainone from '@/components/Mainone'
import maintwo from '@/components/Maintwo'
import axios from 'axios'
import { defineComponent, ref } from 'vue'

export default defineComponent({
    name: 'vgoinmonad',
    inject: ['reload'], //刷新引用
    data() {
        return {
            mainonebread: [
                {
                    name: '仓库'
                },
                {
                    name: '出入库管理'
                },
                {
                    name: '入库单'
                }
            ], // 当前页-【面包屑导航】
            maintwoiconshow: false, // 筛选-【高级筛选按钮】
            maintwoforminfo: {
                myinputoneisshow: true, //筛选查询-左侧字+input
                myinputone: {
                    divinterval: '0', //框右侧距离
                    titlewidth: '74px', //标题宽度
                    inputwidth: '160px', //input宽度
                    inputinterval: '20px', //input右侧距离
                    input: [
                        {
                            ismust: false, //是否必填，true表示必填
                            title: '入库编号：',
                            placeholder: '请输入入库编号',
                            name: 'in_no',
                            content: ''
                        },
                        {
                            ismust: false, //是否必填，true表示必填
                            title: '入库人：',
                            placeholder: '请输入入库人',
                            name: 'truename',
                            content: ''
                        }
                    ]
                },
                selectleftisshow: true, //筛选查询-左侧字+select单框||select时间
                selectleft: {
                    width: 'none',
                    divinterval: '10px', //框右侧距离
                    selectwidth: '160px',
                    selectinterval: '', //select右侧距离
                    select: [
                        {
                            selecttimeduanisshow: true, //该字段表示时间段模块状态：且已写死（字段名不可修改!）
                            ismust: false,
                            title: '入库日期：',
                            placeholder: {
                                placeholderstart: '请选择开始时间',
                                placeholderend: '请选择结束时间'
                            },
                            name: 'addtime',
                            value: {
                                valuestart: ref(''),
                                valueend: ref(''),
                                endstatus: true
                            },
                            rangeseparator: '至', //分隔符
                            divinterval: '3%', //上方字中-框右侧距离
                            selectwidth: '100%', //上方字中-总框长度
                            selectmaxwidth: '360px', //时间段-最大宽度
                            marginright: '0' //与右侧距离
                        },
                        {
                            selectisshow: true,
                            ismust: false,
                            title: '入库类型：',
                            placeholder: '全部',
                            name: 'in_type',
                            value: '',
                            options: []
                        },
                        {
                            selectisshow: true,
                            ismust: false,
                            title: '供应商：',
                            placeholder: '全部',
                            name: 'supplier_id',
                            value: '',
                            options: []
                        },
                        {
                            selectisshow: true,
                            ismust: false,
                            title: '仓库：',
                            placeholder: '全部',
                            name: 'pack_id',
                            value: '',
                            options: []
                        }
                    ]
                }
            }, // 筛选-控件信息
            mainthreeisshowbut: false, // 数据列表中-【添加按钮】
            mainthreeisshowset: false, // 数据列表中-复选框
            mainthreeisshowope: true, // 数据列表中-操作-根据页面显示模块（整页）
            mainthreetableope: 26, // 数据列表中-操作-根据页面显示控件（整页）
            mainthreehandle: 100, // 数据列表中-操作-宽度
            mainthreetabletitle: [
                {
                    fixedstatu: 'left',
                    prop: 'in_no',
                    label: '入库编号',
                    width: '240'
                },
                {
                    fixedstatu: false,
                    prop: 'in_type',
                    label: '入库类型',
                    width: ''
                },
                {
                    fixedstatu: false,
                    prop: 'in_time',
                    label: '入库日期',
                    width: '120'
                },
                {
                    fixedstatu: false,
                    prop: 'supplier_name',
                    label: '供应商',
                    width: '200'
                },
                {
                    fixedstatu: false,
                    prop: 'pack_name',
                    label: '仓库',
                    width: '180'
                },
                {
                    fixedstatu: false,
                    prop: 'remark',
                    label: '备注',
                    width: '180'
                },
                {
                    fixedstatu: false,
                    prop: 'real_num',
                    label: '数量',
                    width: '100'
                },
                {
                    fixedstatu: false,
                    prop: 'truename',
                    label: '入库人',
                    width: '160'
                },
                {
                    fixedstatu: false,
                    prop: 'addtime',
                    label: '创建时间',
                    width: '180'
                }
            ], // 数据列表中-数据表头
            mainthreestatus: true, // 数据列表中-属于search按钮表示状态
            mainthreetableData: [], // 数据列表中-具体数据
            totalCount: 1, //数据列表中-数据总条数
            currentpage: 1, //数据列表中-数据当前页数
            lastpage: 1, //数据列表中-数据总页数（默认以20条一页）
            loading: false, //初始化-loading加载动效，默认是false关闭

            //table表单相关数据
            seniorform: {},
            currentPage: 1,
            pageSizes: [20, 50, 100],
            PageSize: 20,
            multipleSelection: [],

            mypowerlimits: JSON.parse(localStorage.getItem('powerlimits')), //获取权限
            mytoken: localStorage.getItem('token'), //获取存储的token
            pagesdatas: [] //存储最近一次的筛选条件，供分页使用
        }
    },
    methods: {
        //点击进行路由页面跳转以及携带参数
        routeraddrClick(routeraddr, data) {
            if (data) {
                this.$router.push({ path: routeraddr, query: { id: data.id } }).catch((error) => error)
            } else {
                this.$router.push(routeraddr).catch((error) => error)
            }
        },

        //table表中-当选项发生变化是触发该函数
        handleSelectionChange(val) {
            this.multipleSelection = val
        },
        //分页器中-当选择条数时触发该函数
        handleSizeChange(val) {
            // console.log(`每页 ${val} 条`)
            this.PageSize = val
            this.currentPage = 1

            this.pagesdatas = {
                addtime: this.pagesdatas.addtime,
                in_type: this.pagesdatas.in_type,
                supplier_id: this.pagesdatas.supplier_id,
                pack_id: this.pagesdatas.pack_id,
                in_no: this.pagesdatas.in_no,
                truename: this.pagesdatas.truename,
                page: 1,
                page_size: val
            }

            this.postAxiosEvent(this.pagesdatas)
        },
        //分页器中-当选择分页时触发该函数
        handleCurrentChange(val) {
            // console.log(`当前页: 第${val}页`)
            this.currentPage = val

            this.pagesdatas = {
                addtime: this.pagesdatas.addtime,
                in_type: this.pagesdatas.in_type,
                supplier_id: this.pagesdatas.supplier_id,
                pack_id: this.pagesdatas.pack_id,
                in_no: this.pagesdatas.in_no,
                truename: this.pagesdatas.truename,
                page: val,
                page_size: this.PageSize
            }

            this.postAxiosEvent(this.pagesdatas)
        },
        //导出数据
        exportDatas() {
            axios
                .post(
                    '/manage/pack/packInIndex',
                    {
                        addtime: this.pagesdatas.addtime,
                        in_type: this.pagesdatas.in_type,
                        supplier_id: this.pagesdatas.supplier_id,
                        pack_id: this.pagesdatas.pack_id,
                        in_no: this.pagesdatas.in_no,
                        truename: this.pagesdatas.truename,
                        export: 1
                    },
                    {
                        headers: {
                            Auth: localStorage.getItem('token')
                        }
                    }
                )
                .then((response) => {
                    this.myaxiosfunc(response.data.result)
                })
                .catch(function (error) {
                    // 请求失败处理
                    console.log(error)
                })
        },
        //提交等相关event事件函数
        //获取Container-Pagedata-Maintwo-搜索-返回的数据
        getSeachinfoEvent(data) {
            // console.log('点击查询获取的data数组：' + JSON.stringify(data))

            var getaddtime = null
            if (data.addtime.start == '') {
                getaddtime = ''
            } else {
                if (data.addtime.end == '') {
                    getaddtime = data.addtime.start + ' ~ ' + (data.addtime.start + 86400 - 1)
                } else {
                    getaddtime = data.addtime.start + ' ~ ' + (data.addtime.end + 86400 - 1)
                }
            }

            this.pagesdatas = {
                addtime: getaddtime,
                in_type: data.in_type,
                supplier_id: data.supplier_id,
                pack_id: data.pack_id,
                in_no: data.in_no,
                truename: data.truename,
                page: 1,
                page_size: this.PageSize
            }

            this.postAxiosEvent(this.pagesdatas)
        },
        //数据渲染
        myaxiosfunc(successdata) {
            var newdata = successdata.data
            // console.log('当前页面的data:' + JSON.stringify(newdata))
            this.mainthreetableData = newdata
            this.totalCount = successdata.total
            this.currentpage = successdata.current_page //当前页面页数
            this.lastpage = successdata.last_page
            this.loading = false
        },
        // 接口
        postAxiosEvent(data) {
            this.loading = true

            axios
                .post('/manage/pack/packInIndex', data, {
                    headers: {
                        Auth: localStorage.getItem('token')
                    }
                })
                .then((response) => {
                    this.myaxiosfunc(response.data.result)
                })
                .catch(function (error) {
                    // 请求失败处理
                    console.log(error)
                })
        },
        getAllOptions() {
            axios
                .post(
                    '/manage/pack/packInConfig',
                    {
                        is_check: -1
                    },
                    {
                        headers: {
                            Auth: localStorage.getItem('token')
                        }
                    }
                )
                .then((response) => {
                    let all_data = [{ label: '全部', value: '' }]
                    let getdatas = response.data.result

                    this.maintwoforminfo.selectleft.select[1].options = [
                        ...all_data,
                        ...getdatas.in_type.map((n) => {
                            return { label: n.name, value: n.id }
                        })
                    ]
                    this.maintwoforminfo.selectleft.select[2].options = [
                        ...all_data,
                        ...getdatas.supplier.map((n) => {
                            return { label: n.name, value: n.id }
                        })
                    ]
                    this.maintwoforminfo.selectleft.select[3].options = [
                        ...all_data,
                        ...getdatas.pack.map((n) => {
                            return { label: n.name, value: n.id }
                        })
                    ]
                })
                .catch(function (error) {
                    // 请求失败处理
                    console.log(error)
                })
        }
    },
    created() {
        if (localStorage.getItem('token') === null || !localStorage.getItem('token')) {
            this.isshow = false
            this.$router.push('/').catch((error) => error)
        } else {
            // 获取更多配置
            this.getAllOptions()

            this.pagesdatas = {
                page: 1,
                page_size: 20
            }
            this.postAxiosEvent(this.pagesdatas)
        }
    },
    components: {
        mainone,
        maintwo
    }
})
</script>

<style scoped>
/*操作中-按钮样式*/
.el-button--mini {
    min-height: 28px;
    padding: 0 8px;
    font-size: 12px;
    border-radius: calc(var(--el-border-radius-base) - 1px);
}

.box-list {
    margin-top: 10px;
    background-color: #ffffff;
    border-radius: 4px;
    line-height: 40px;
    padding: 0 20px;
}

.no-warp-4 {
    overflow: hidden;
    text-overflow: ellipsis;
    text-overflow: -o-ellipsis-lastline;
    display: -webkit-box;
    line-clamp: 4;
    -webkit-line-clamp: 4;
    -webkit-box-orient: vertical;
}
</style>
