<template>
    <div>vbatchmanageedit</div>
</template>

<script>
export default {
    name: 'vbatchmanageedit',
    data() {
        return {}
    },
}
</script>