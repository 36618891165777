<template>
    <div>
        <gooutMonad :mainonebread="mainonebread" :is_all="is_all">
            <template #tabs>
                <div>
                    <el-tabs v-model="is_all" class="demo-tabs" @tab-change="handleChangeEvent" style="width: 100%">
                        <el-tab-pane label="全部待接单" :name="2"></el-tab-pane>
                        <el-tab-pane label="我的接单" :name="3"></el-tab-pane>
                    </el-tabs>
                </div>
            </template>
        </gooutMonad>
    </div>
</template>

<script>
import gooutMonad from '../gooutmonad/vgooutmonad.vue'
import { ElNotification } from 'element-plus'
import { defineComponent } from 'vue'
import axios from 'axios'

export default defineComponent({
    name: 'vgooutreceivemonad',
    inject: ['reload'], //刷新引用
    data() {
        return {
            mainonebread: [
                {
                    name: '仓库'
                },
                {
                    name: '出入库管理'
                },
                {
                    name: '我的出库接单'
                }
            ],

            is_all: 2,

            mypowerlimits: JSON.parse(localStorage.getItem('powerlimits')), //获取权限
            mytoken: localStorage.getItem('token') //获取存储的token
        }
    },
    methods: {},
    components: {
        gooutMonad
    }
})
</script>

<style scoped>
.mybutton_box {
    display: flex;
    flex-direction: row;
    align-items: center;
}

.mybutton_box_btn :deep(.el-button) {
    padding: 8px 20px;
    height: 36px;
    min-height: 36px;
}
</style>
