<template>
    <div>
        <mainone :mainonebread="mainonebread"></mainone>
        <div v-loading="loading" element-loading-text="正在飞速加载中...">
            <!--标题-->
            <div class="box" style="">
                <div class="box_title" style="">
                    <div class="box_title_font">编辑导航</div>
                    <div class="box_title_button"></div>
                </div>
                <!--内容-->
                <div class="box_content">
                    <el-row style="margin-top: 20px">
                        <!--占位符-->
                        <el-col :span="24" :xs="24" :sm="24" :lg="4">
                            <div style="width: 100%"></div>
                        </el-col>
                        <!--主表样式-->
                        <el-col :span="24" :xs="24" :sm="24" :lg="14">
                            <el-form ref="formdata" :model="formdata" :rules="formdatarules" label-width="170px" class="demo-ruleForm">
                                <el-form-item label="平台导航名称：" prop="title">
                                    <el-input v-model="formdata.title" clearable placeholder="请输入平台导航名称" maxlength="5" show-word-limit> </el-input>
                                </el-form-item>
                                <el-form-item label="平台默认图标：" prop="icon">
                                    <mainimgesup
                                        :myimgesup="myimgesup"
                                        :myimgesupisshow="myimgesupisshow"
                                        :filelist="formdata.icon != '' ? [{ url: formdata.icon, name: '图片icon' }] : []"
                                        v-on:getuploadfile="(data) => getUploadFileEvent(data)"
                                        v-on:deluploadfile="(data) => deluploadfileEvent(data)"
                                    >
                                    </mainimgesup>
                                </el-form-item>
                                <el-form-item label="跳转链接：" prop="jump_way">
                                    <el-radio-group v-model="formdata.jump_way">
                                        <el-radio :label="1">站内链接</el-radio>
                                        <el-radio :label="2">站外小程序</el-radio>
                                        <el-radio :label="3">h5链接/站外网页</el-radio>
                                    </el-radio-group>
                                </el-form-item>
                                <div>
                                    <!-- 站内链接 -->
                                    <div v-if="formdata.jump_way == 1">
                                        <el-form-item label="站内链接：" prop="mini_path">
                                            <el-select v-model="formdata.mini_path" placeholder="请选择" style="width: 100%" @change="changeSelectEvent">
                                                <el-option :label="gettypeid.title" :value="gettypeid.path" v-for="(gettypeid, index) in mywebview" :key="index"></el-option>
                                            </el-select>
                                        </el-form-item>
                                    </div>
                                    <!-- 站外小程序 -->
                                    <div v-if="formdata.jump_way == 2">
                                        <el-form-item label="小程序APPID：" prop="outer_path">
                                            <el-select v-model="formdata.outer_path" placeholder="请选择" style="width: 100%">
                                                <el-option :label="gettypeid.title" :value="gettypeid.appid" v-for="(gettypeid, index) in myouter" :key="index"></el-option>
                                            </el-select>
                                        </el-form-item>
                                        <el-form-item label="小程序路径：" style="margin-top: 20px;">
                                            <el-input v-model="formdata.outer_url" clearable placeholder="请填写小程序路径"></el-input>
                                            <div style="height: 20px; line-height: 20px">
                                                <span style="font-size: 12px; color: #999999">小程序地址为空表示默认跳转小程序首页</span>
                                            </div>
                                        </el-form-item>
                                    </div>
                                    <!-- h5链接/站外网页 -->
                                    <div v-if="formdata.jump_way == 3">
                                        <el-form-item label="跳转路径：" prop="wechat_path">
                                            <el-input v-model="formdata.wechat_path" clearable placeholder="请填写以http://或https://开头的跳转路径"> </el-input>
                                        </el-form-item>
                                    </div>
                                </div>
                                <el-form-item label="有该导航的租户：" prop="companyids">
                                    <el-checkbox-group v-model="formdata.companyids">
                                        <el-checkbox :label="parseInt(item.id)" v-for="(item, index) in companydatas" :key="index">
                                            {{ item.name }}
                                        </el-checkbox>
                                    </el-checkbox-group>
                                </el-form-item>
                                <el-form-item label="平台默认排序：" prop="sort">
                                    <el-input v-model="formdata.sort" clearable placeholder="数字越大排序越靠前"></el-input>
                                </el-form-item>
                                <el-form-item label="显示平台：" prop="platform">
                                    <el-checkbox-group v-model="formdata.platform">
                                        <el-checkbox :label="parseInt(item.value)" v-for="(item, index) in platformdatas" :key="index">{{ item.label }}</el-checkbox>
                                    </el-checkbox-group>
                                </el-form-item>
                                <el-form-item label="状态：" prop="status">
                                    <el-radio-group v-model="formdata.status" class="ml-4">
                                        <el-radio label="1">显示</el-radio>
                                        <el-radio label="-1">隐藏</el-radio>
                                    </el-radio-group>
                                </el-form-item>
                                <!-- 提交&取消 -->
                                <el-form-item>
                                    <el-button type="primary" @click="submitForm('formdata')" style="width: 40%; min-width: 80px; margin-right: 10px">提交 </el-button>
                                    <el-button @click="resetForm()">取消</el-button>
                                </el-form-item>
                            </el-form>
                        </el-col>
                        <!--占位符-->
                        <el-col :span="24" :xs="24" :sm="24" :lg="6">
                            <div style="width: 100%"></div>
                        </el-col>
                    </el-row>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import mainone from '@/components/Mainone'
import mainimgesup from '@/components/Mainimgesup.vue'
import componentProperties from '@/assets/js/componentProperties'
import { ElMessage } from 'element-plus'
import axios from 'axios'

export default {
    name: 'vtoolseidt',
    data() {
        // 文本input框-校验（文本-值中不能有空格）【常用工具||】
        var checkInputEventWord = (rule, value, callback) => {
            if (!value) {
                return callback(new Error('必填项不能为空'))
            }
            if (value.toString().indexOf(' ') != -1) {
                return callback(new Error('值中不能有空格'))
            }
            setTimeout(() => {
                callback()
            }, 500)
        }
        // 数字input框-校验-(仅整数)【排序||】
        var checkInputEventInt = (rule, value, callback) => {
            if (!value && value != 0) {
                return callback(new Error('必填项不能为空'))
            }
            if (value.toString().indexOf(' ') != -1) {
                return callback(new Error('值中不能有空格'))
            }
            setTimeout(() => {
                // 判断字符串是否存在小数点
                let result = value.toString().indexOf('.')
                if (result != -1) {
                    callback(new Error('请输入整数'))
                } else {
                    // 判断字符串是否为文字
                    if (isNaN(value)) {
                        callback(new Error('请输入整数'))
                    } else {
                        // 判断字符串是否为数字
                        if (!Number.parseInt(value) && Number.parseInt(value) != 0) {
                            callback(new Error('请输入整数'))
                        } else {
                            if (value < 0) {
                                callback(new Error('值需大于0'))
                            } else {
                                callback()
                            }
                        }
                    }
                }
            }, 500)
        }
        return {
            mainonebread: [
                {
                    name: '设置'
                },
                {
                    name: '店铺'
                },
                {
                    name: '首页导航',
                    path: '/home'
                },
                {
                    name: '编辑导航',
                    path: '/homeedit'
                }
            ], // 当前页-【面包屑导航】

            // form表单数据
            formdata: {
                title: '',
                icon: '',
                jump_way: '',
                mini_path: '',
                outer_path: '',
                outer_url: '',
                wechat_path: '',
                companyids: [],
                sort: '',
                platform: [],
                status: ''
            },
            // form表单-校验
            formdatarules: {
                title: [
                    {
                        required: true,
                        validator: checkInputEventWord,
                        trigger: 'blur'
                    }
                ],
                icon: [
                    {
                        required: true,
                        message: '图片icon不能为空！',
                        trigger: 'blur'
                    }
                ],
                jump_way: [
                    {
                        required: true,
                        validator: checkInputEventWord,
                        trigger: 'blur'
                    }
                ],
                mini_path: [
                    {
                        required: true,
                        validator: checkInputEventWord,
                        trigger: 'blur'
                    }
                ],
                outer_path: [
                    {
                        required: true,
                        validator: checkInputEventWord,
                        trigger: 'blur'
                    }
                ],
                outer_url: [
                    {
                        required: false,
                        validator: checkInputEventWord,
                        trigger: 'blur'
                    }
                ],
                wechat_path: [
                    {
                        required: true,
                        validator: checkInputEventWord,
                        trigger: 'blur'
                    }
                ],
                companyids: [
                    {
                        required: true,
                        message: '有该导航的租户选项至少选择一个！',
                        trigger: 'blur'
                    }
                ],
                sort: [
                    {
                        required: true,
                        validator: checkInputEventInt,
                        trigger: 'blur'
                    }
                ],
                platform: [
                    {
                        required: true,
                        message: '显示平台选项不能至少选择一个！',
                        trigger: 'blur'
                    }
                ],
                status: [
                    {
                        required: true,
                        message: '状态选项不能至少选择一个！',
                        trigger: 'blur'
                    }
                ]
            },

            // 租户平台-多选数据
            companydatas: [],

            // 显示平台-多选数据
            platformdatas: componentProperties.get('thirdpartyoption').options,

            //webview跳转链接-配置
            mywebview: [],
            //小程序APPID-配置
            myouter: [],

            // 上传icon
            myimgesupisshow: true,
            myimgesup: {
                uploadsize: 1, //选择上传图片的展现框-1表示小框，2表示中等框，3表示大框
                listtype: 'picture', //list展示类型，text/picture/picture-card；默认不填为text
                ismust: true, //是否必填，true表示必填
                action: '/admin/upload/openFile', //upload上传API地址
                headers: { Auth: localStorage.getItem('token') }, //upload上传headers
                title: '上传图片', //button-按钮文字
                tips: '建议尺寸：74*74px像素，png、jpg、jpeg格式', //提示&建议文字
                limit: '1', //上传数量限制
                target_id: 100, //上传文件target_id ：图片分类，区分存储文件和存储时长，不传默认存储1天后过期；1-二维码； 2-打印文件； 3-文库文件； 4-课程文件； 5-广告主宣传页； 6-广告主产生的pdf文件； 7-证件照文件； 50-设备广告； 100-通用；
                rules: {
                    images: {
                        image_width: 0, //图片宽度
                        image_height: 0, //图片高度
                        size: 20000, //文件大小（单位kb）
                        type: ['image/jpeg', 'image/jpg', 'image/png'], //文件格式
                        typechart: 'jpg/jpeg/png' //文件格式提示规则
                    }
                }
            },

            mypowerlimits: JSON.parse(localStorage.getItem('powerlimits')), //获取权限
            mytoken: localStorage.getItem('token'), //获取存储的token

            loading: false
        }
    },
    created() {
        if (localStorage.getItem('token') === null || !localStorage.getItem('token')) {
            this.isshow = false
            this.$router.push('/').catch((error) => error)
        } else {
            this.loading = true
            // 获取相关配置配置-有该菜单的租户
            var p1 = axios
                .get('/manage/Manage/comList', {
                    headers: {
                        Auth: localStorage.getItem('token')
                    },
                    params: {
                        page: 1,
                        page_size: 100
                    }
                })
                .then((response) => {
                    if (response.data.code == 0) {
                        this.companydatas = response.data.result.data
                    }
                })
                .catch(function(error) {
                    console.log(error)
                })

            // 获取相关配置配置-webview跳转链接
            var p2 = axios
                .post(
                    '/manage/config/info',
                    {
                        key_name: 'mini_path'
                    },
                    {
                        headers: {
                            Auth: localStorage.getItem('token')
                        }
                    }
                )
                .then((response) => {
                    this.mywebview = response.data.result
                })
                .catch(function(error) {
                    console.log(error)
                })
            // 获取相关配置配置-小程序APPID
            var p3 = axios
                .post(
                    '/manage/config/info',
                    {
                        key_name: 'outer_app'
                    },
                    {
                        headers: {
                            Auth: localStorage.getItem('token')
                        }
                    }
                )
                .then((response) => {
                    this.myouter = response.data.result
                })
                .catch(function(error) {
                    console.log(error)
                })

            // 获取相关配置配置-编辑数据-反显
            var p4 = axios
                .post(
                    '/manage/nav/info',
                    {
                        id: this.$route.query.id
                    },
                    {
                        headers: {
                            Auth: localStorage.getItem('token')
                        }
                    }
                )
                .then((response) => {
                    if (response.data.code == 0) {
                        var getdatas = response.data.result
                        this.formdata = {
                            id: this.$route.query.id,
                            title: getdatas.title,
                            icon: getdatas.icon,
                            jump_way: getdatas.jump_way,
                            mini_path: getdatas.mini_path,
                            outer_path: getdatas.outer_path,
                            outer_url: getdatas.mini_path,
                            wechat_path: getdatas.wechat_path,
                            companyids: getdatas.companyids.map((i) => parseInt(i, 0)),
                            sort: getdatas.sort,
                            platform: getdatas.platform.map((i) => parseInt(i, 0)),
                            status: getdatas.status.toString()
                        }
                    }
                })
                .catch(function(error) {
                    console.log(error)
                })

            Promise.all([p1, p2, p3, p4])
                .then(() => {
                    this.loading = false
                })
                .catch((error) => {
                    console.log('error:失败:', error)
                    this.loading = false
                })
        }
    },
    methods: {
        // 获取上传图片-icon
        getUploadFileEvent(data) {
            this.formdata.icon = data.url
            this.$refs['formdata'].validate()
        },
        // 删除上传图片-icon
        deluploadfileEvent(data) {
            console.log('当前删除事件-返回的数据：' + JSON.stringify(data))
            this.formdata.icon = ''
        },

        //提交form表单-并判断是否必填
        submitForm(formName) {
            let getdatas = this.$refs[formName].model
            this.$refs[formName].validate((valid) => {
                if (valid) {
                    var minipath = ''
                    if (getdatas.jump_way == 1) {
                        minipath = getdatas.mini_path
                    } else {
                        minipath = getdatas.outer_url
                    }

                    axios
                        .post(
                            '/manage/nav/homeUpdate',
                            {
                                id: this.$route.query.id,
                                title: getdatas.title,
                                icon: getdatas.icon,
                                jump_way: getdatas.jump_way,
                                mini_path: minipath,
                                outer_path: getdatas.outer_path,
                                wechat_path: getdatas.wechat_path,
                                companyids: getdatas.companyids,
                                sort: getdatas.sort,
                                platform: getdatas.platform,
                                status: getdatas.status
                            },
                            {
                                headers: {
                                    Auth: localStorage.getItem('token')
                                }
                            }
                        )
                        .then((response) => {
                            if (response.data.code === 0) {
                                ElMessage({
                                    type: 'success',
                                    message: '提交成功！',
                                    duration: 1000,
                                    onClose: () => {
                                        this.$router.push('/home').catch((error) => error)
                                    }
                                })
                            }
                        })
                        .catch(function(error) {
                            console.log(error)
                        })
                } else {
                    console.log('error submit!!')
                    return false
                }
            })
        },
        //重置form表单中填写的数据
        resetForm() {
            this.$router.go(-1) // 返回上一页
        }
    },
    components: {
        mainone,
        mainimgesup
    }
}
</script>

<style scoped>
.box {
    background-color: #ffffff;
    border-radius: 4px;
    color: #333333;
    padding: 10px 20px;
}

.box_title {
    line-height: 40px;
    height: 40px;
}

.box_title_font {
    float: left;
    font-weight: 400;
    font-size: 14px;
    color: #333333;
}

.box_title_button {
    float: right;
    font-weight: 400;
    font-size: 14px;
    color: #94a3d2;
}

.box_title_button:hover {
    cursor: pointer;
}

.box_content {
    border-top: 1px solid #eeeeee;
    margin-top: 10px;
}
</style>
