<template>
    <div>
        <mainone :mainonebread="mainonebread"></mainone>
        <maintwo
            :maintwoiconshow="maintwoiconshow"
            :maintwoforminfo="maintwoforminfo"
            :myinputoneisshow="maintwoforminfo.myinputoneisshow"
            :myinputone="maintwoforminfo.myinputone"
            :myselectleftisshow="maintwoforminfo.selectleftisshow"
            :myselectleft="maintwoforminfo.selectleft"
            :myinputishow="myinputishow"
            :myinput="myinput"
            :myinputonefullisshow="maintwoforminfo.myinputonefullisshow"
            :myinputonefull="maintwoforminfo.myinputonefull"
            :myselectisshow="myselectisshow"
            :myselect="myselect"
            :myselectcityisshow="myselectcityisshow"
            :myselectcity="myselectcity"
            v-on:getsearchinfo="getSeachinfoEvent"
            v-on:searchForm="searchFormEvent"
        ></maintwo>
        <!--table表单-->
        <div>
            <!--主list列表-->
            <div class="box-list">
                <div style="line-height: 60px">
                    <!--左侧名称-->
                    <div style="float: left; font-size: 14px">数据列表</div>
                    <!--右侧按钮-->
                    <div style="float: right">
                        <!--自定义按钮-点击出现弹框&点击跳转子类页面@click="routeraddrClick('/XX路由')"-->
                        <!--1、点击出现弹框@click="addTanClick(XX弹框配置信息,dialogVisible = true);resetFormSenior(XXform表单)"-->
                        <!--2、点击跳转子类页面@click="routeraddrClick('/XX路由')-->
                        <!--<el-button ></el-button>-->
                        <!--添加XX按钮-点击跳转添加类页面-->
                        <el-button type="primary" plain class="max_search" @click="routeraddrClick(mainthreeadddata)" v-if="mypowerlimits.gsll_tj">添加</el-button>
                    </div>
                </div>

                <div>
                    <!--table表单-->
                    <el-table
                        ref="multipleTable"
                        :row-class-name="tableRowClassName"
                        :data="mainthreetableData.slice((currentPage - lastpage) * PageSize, currentPage * PageSize)"
                        style="width: 100%"
                        min-height="900"
                        border
                        v-loading="loading"
                        element-loading-text="正在飞速加载中..."
                        @selection-change="handleSelectionChange"
                        @cell-click="handleOneData"
                    >
                        <!--多选框-用于用户选择性导出数据-->
                        <el-table-column type="selection" width="55" v-if="mainthreeisshowset" />
                        <el-table-column
                            v-for="(mytabletitle, i) in mainthreetabletitle"
                            :key="i"
                            :fixed="mytabletitle.fixedstatu"
                            :prop="mytabletitle.prop"
                            :label="mytabletitle.label"
                            :width="mytabletitle.width"
                        >
                            <!--这里先通过table列表中的title值进行判断，在进行渲染展示-->
                            <!--例如：status状态字段，判断最终的值是那种表现形式等-->
                            <template #default="scope" v-if="mytabletitle.prop === 'status' || mytabletitle.prop == 'addtime'">
                                <div v-if="mytabletitle.prop === 'status'">
                                    <div v-if="scope.row.switchstatus === 4" id="switch">
                                        <el-switch
                                            v-model="scope.row.status"
                                            active-text="开启"
                                            inactive-text="关闭"
                                            :active-value="1"
                                            :inactive-value="-1"
                                            active-color="#13ce66"
                                            @click="mypowerlimits.gsll_qy ? changeStatus(scope.row) : ''"
                                            :disabled="!mypowerlimits.gsll_qy"
                                            inactive-color="#cbcbcb"
                                        ></el-switch>
                                    </div>
                                </div>
                                <div v-if="mytabletitle.prop === 'addtime'">
                                    <div v-if="scope.row.addtime">
                                        <span>{{ this.$utils.formatDate(scope.row.addtime).substr(0, 10) }}</span>
                                    </div>
                                </div>
                            </template>
                        </el-table-column>
                        <!--操作&按钮-->
                        <el-table-column fixed="right" label="操作" :width="mainthreehandle" v-if="mainthreeisshowope" prop="myoperation" id="operation">
                            <template #default="scope">
                                <!--操作：按照页面区分，且每个按钮对应相关函数-->
                                <!--操作-公司列表-->
                                <div v-if="mainthreetableope === 2">
                                    <el-button type="primary" size="mini" @click="doLogin(scope.row)" v-if="mypowerlimits.gsll_dl">登录 </el-button>
                                    <el-button type="primary" size="mini" @click="routeraddrClick('/companyedit', scope.row)" v-if="mypowerlimits.gsll_bj">编辑 </el-button>
                                    <el-button type="primary" size="mini" @click="routeraddrClick('/companywxset', scope.row)" v-if="mypowerlimits.gsll_wx">微信 </el-button>
                                    <el-button type="primary" size="mini" @click="routeraddrClick('/companyaliset', scope.row)" v-if="mypowerlimits.gsll_zfb">支付宝 </el-button>
                                    <el-button type="primary" size="mini" @click="routeraddrClick('/companypowerset', scope.row)" v-if="mypowerlimits.gsll_cdqx"
                                        >功能控制
                                    </el-button>
                                    <el-button type="primary" size="mini" @click="routeraddrClick('/companymoneydetails', scope.row)" v-if="mypowerlimits.gsll_zjmx_ck"
                                        >资金明细</el-button
                                    >
                                </div>
                            </template>
                        </el-table-column>
                    </el-table>
                    <!--分页器-->
                    <div class="block" style="text-align: center; margin: 30px 0">
                        <el-pagination
                            background
                            @size-change="handleSizeChange"
                            @current-change="handleCurrentChange"
                            :current-page="currentPage"
                            :page-sizes="pageSizes"
                            :page-size="PageSize"
                            layout="total, sizes, prev, pager, next, jumper"
                            :total="totalCount"
                        ></el-pagination>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import mainone from '@/components/Mainone'
import maintwo from '@/components/Maintwo'
import { ElMessage } from 'element-plus'
import axios from 'axios'
import { defineComponent } from 'vue'

export default defineComponent({
    name: 'vcompanylist',
    data() {
        return {
            mainonebread: [
                {
                    name: '租户管理'
                },
                {
                    name: '租户管理'
                },
                {
                    name: '公司列表',
                    path: '/companylist'
                }
            ], // 当前页-【面包屑导航】
            maintwoiconshow: false, // 筛选-【高级筛选按钮】
            maintwoforminfo: {
                myinputoneisshow: true, //筛选查询-左侧字+input
                myinputone: {
                    divinterval: '0', //框右侧距离
                    titlewidth: '74px', //标题宽度
                    inputwidth: '200px', //input宽度
                    inputinterval: '20px', //input右侧距离
                    input: [
                        {
                            ismust: false, //是否必填，true表示必填
                            title: '公司名称：',
                            placeholder: '请输入公司名称',
                            name: 'name',
                            content: ''
                        }
                    ]
                },
                selectleftisshow: true, //筛选查询-左侧字+select单框||select时间
                selectleft: {
                    width: 'none',
                    divinterval: '', //框右侧距离
                    selectwidth: '200px',
                    selectinterval: '20px', //select右侧距离
                    select: [
                        {
                            selectisshow: true,
                            ismust: false,
                            title: '状态：',
                            placeholder: '全部',
                            name: 'status',
                            value: '',
                            options: [
                                {
                                    label: '全部',
                                    value: '0'
                                },
                                {
                                    label: '启用',
                                    value: '1'
                                },
                                {
                                    label: '禁止',
                                    value: '-1'
                                }
                            ]
                        }
                    ]
                }
            }, // 筛选-控件信息
            mainthreeisshowbut: true, // 数据列表中-【添加按钮】
            mainthreeadddata: '/companyadd', //点击添加按钮-跳转路由
            mainthreeisshowset: false, // 数据列表中-复选框
            mainthreeisshowope: true, // 数据列表中-操作-根据页面显示模块（整页）
            mainthreetableope: 2, // 数据列表中-操作-根据页面显示控件（整页）
            mainthreehandle: 390,
            mainthreetabletitle: [
                {
                    fixedstatu: 'left',
                    prop: 'id',
                    label: 'ID',
                    width: ''
                },
                {
                    fixedstatu: false,
                    prop: 'name',
                    label: '公司名称',
                    width: ''
                },
                {
                    fixedstatu: false,
                    prop: 'username',
                    label: '手机号/账号',
                    width: ''
                },
                {
                    fixedstatu: false,
                    prop: 'client_pre',
                    label: '设备编号前缀',
                    width: ''
                },
                {
                    fixedstatu: false,
                    prop: 'mini_name',
                    label: '小程序名',
                    width: ''
                },
                {
                    fixedstatu: false,
                    prop: 'brand_name',
                    label: '品牌名',
                    width: ''
                },
                {
                    fixedstatu: false,
                    prop: 'status',
                    label: '状态',
                    width: ''
                },
                {
                    fixedstatu: false,
                    prop: 'addtime',
                    label: '添加时间',
                    width: ''
                }
            ], // 数据列表中-数据表头
            mainthreestatus: true, // 数据列表中-属于search按钮表示状态
            mainthreetableData: [], // 数据列表中-具体数据
            totalCount: 1, //数据列表中-数据总条数
            currentpage: 1, //数据列表中-数据当前页数
            lastpage: 1, //数据列表中-数据总页数（默认以20条一页）
            loading: false, //初始化-loading加载动效，默认是false关闭

            //table表单相关数据
            seniorform: {},
            currentPage: 1,
            pageSizes: [20, 50, 100],
            PageSize: 20,
            multipleSelection: [],
            thispagename: '', //通过路由获取当前页面的path值
            searchwidth: '580px',
            tancontent: {
                title: '弹框标题',
                content: {}
            }, //弹框

            mypowerlimits: JSON.parse(localStorage.getItem('powerlimits')), //获取权限
            mytoken: localStorage.getItem('token'), //获取存储的token
            pagesdatas: [] //存储最近一次的筛选条件，供分页使用
        }
    },
    mounted() {
        //获取当前页面路由path值
        this.thispagename = this.$route.path
    },
    methods: {
        // 登录-按钮事件
        doLogin(data) {
            axios
                .post(
                    '/manage/Manage/goLogin',
                    {
                        id: data.id
                    },
                    {
                        headers: {
                            Auth: localStorage.getItem('token')
                        }
                    }
                )
                .then((response) => {
                    if (response.data.code === 0) {
                        let Authentication = response.data.result.Authentication
                        let username = response.data.result.username
                        let url = this.$suburl + '?username=' + username + '&passwd=' + Authentication + '&model=modelLogin'
                        window.open(url, '_blank')
                    }
                })
                .catch(function(error) {
                    console.log(error)
                })
        },
        // table表单-状态改变
        changeStatus(data) {
            // console.log("切换状态的data值：" + data.status);
            axios
                .post(
                    '/manage/manage/comSwitch',
                    {
                        id: data.id
                    },
                    {
                        headers: {
                            Auth: localStorage.getItem('token')
                        }
                    }
                )
                .then((response) => {
                    if (response.data.code === 0) {
                        ElMessage({
                            type: 'success',
                            message: response.data.message
                        })
                    } else {
                        // 切换状态失败-复原状态
                        if (data.status == 1) {
                            data.status = -1
                        } else {
                            data.status = 1
                        }
                    }
                })
                .catch(function(error) {
                    console.log(error)
                })
        },

        //table表中-当选项发生变化是触发该函数
        handleSelectionChange(val) {
            console.log('this is:' + JSON.stringify(val))
            this.multipleSelection = val
        },
        //分页器中-当选择条数时触发该函数
        handleSizeChange(val) {
            console.log(`每页 ${val} 条`)
            this.PageSize = val
            this.currentPage = 1

            //加载loading
            this.loading = true
            //通过Ajax向后台获取数据
            axios
                .get('/manage/Manage/comList', {
                    headers: {
                        Auth: localStorage.getItem('token')
                    },
                    params: {
                        name: this.pagesdatas.name,
                        status: this.pagesdatas.status,
                        page: 1,
                        page_size: val
                    }
                })
                .then((response) => {
                    this.myaxiosfunc(response.data.result)
                })
                .catch(function(error) {
                    console.log(error)
                })
        },
        //分页器中-当选择分页时触发该函数
        handleCurrentChange(val) {
            // console.log(`当前页: 第${val}页`);
            this.currentPage = val

            //加载loading
            this.loading = true
            //通过Ajax向后台获取数据
            axios
                .get('/manage/Manage/comList', {
                    headers: {
                        Auth: localStorage.getItem('token')
                    },
                    params: {
                        name: this.pagesdatas.name,
                        status: this.pagesdatas.status,
                        page: val,
                        page_size: this.PageSize
                    }
                })
                .then((response) => {
                    this.myaxiosfunc(response.data.result)
                })
                .catch(function(error) {
                    console.log(error)
                })
        },
        //点击进行路由页面跳转以及携带参数
        routeraddrClick(routeraddr, rowdata) {
            if (rowdata) {
                // 携带参数跳转   
                if (routeraddr === '/companyedit') {
                    rowdata.password = ''
                    this.$router
                        .push({
                            path: routeraddr,
                            query: rowdata
                        })
                        .catch((error) => error)
                } else {
                    this.$router
                        .push({
                            path: routeraddr,
                            query: { id: rowdata.id, name: rowdata.name }
                        })
                        .catch((error) => error)
                }
            } else {
                //仅跳转
                this.$router.push(routeraddr).catch((error) => error)
            }
        },

        //提交等相关event事件函数
        //获取Container-Pagedata-Maintwo-搜索-返回的数据
        getSeachinfoEvent(data) {
            console.log('点击查询获取的data数组：' + JSON.stringify(data))
            this.pagesdatas = data //将筛选条件赋值给本页的pagesdatas存储，供分页使用
            //加载loading
            this.loading = true
            //通过Ajax向后台获取数据
            axios
                .get('/manage/Manage/comList', {
                    headers: {
                        Auth: localStorage.getItem('token')
                    },
                    params: {
                        name: data.name,
                        status: data.status,
                        page: 1,
                        page_size: this.PageSize
                    }
                })
                .then((response) => {
                    this.myaxiosfunc(response.data.result)
                })
                .catch(function(error) {
                    console.log(error)
                })
        },
        //数据渲染
        myaxiosfunc(successdata) {
            var newdata = successdata.data
            for (var i = 0; i < newdata.length; i++) {
                //该字段表示search按钮，且status为1表示启用，-1表示禁用
                newdata[i].switchstatus = 4
                //转换-添加时间
                // if (newdata[i].addtime) {
                //   if (newdata[i].addtime === 0 || newdata[i].addtime === null) {
                //     newdata[i].addtime = "--";
                //   } else {
                //     newdata[i].addtime = this.$utils.formatDate(newdata[i].addtime);
                //   }
                // } else {
                //   newdata[i].addtime = "--";
                // }
            }
            // console.log('当前页面的data:' + JSON.stringify(newdata))
            this.mainthreetableData = newdata
            this.totalCount = successdata.total
            this.currentpage = successdata.current_page //当前页面页数
            this.lastpage = successdata.last_page
            this.loading = false
        }
    },
    created() {
        if (localStorage.getItem('token') === null || !localStorage.getItem('token')) {
            this.isshow = false
            this.$router.push('/').catch((error) => error)
        } else {
            //加载loading
            this.loading = true //通过Ajax向后台获取数据
            //通过Ajax向后台获取数据
            axios
                .get('/manage/Manage/comList', {
                    headers: {
                        Auth: localStorage.getItem('token')
                    },
                    params: {
                        name: '',
                        status: '',
                        page: 1,
                        page_size: this.PageSize
                    }
                })
                .then((response) => {
                    this.myaxiosfunc(response.data.result)
                })
                .catch(function(error) {
                    console.log(error)
                })
        }
    },
    components: {
        mainone,
        maintwo
    }
})
</script>
<style scoped>
/*局部控制-Switch按钮-按钮中显示状态文字*/
/* switch按钮款式 */
#switch :deep(.el-switch__label) {
    position: absolute;
    display: none;
    color: #fff !important;
}

/*关上时文字地位设置*/
#switch :deep(.el-switch__label--right) {
    z-index: 1;
}

/* 调整关上时文字的显示位子 */
#switch :deep(.el-switch__label--right) span {
    margin-left: 3px;
    font-size: 12px;
}

/*敞开时文字地位设置*/
#switch :deep(.el-switch__label--left) {
    z-index: 1;
}

/* 调整敞开时文字的显示位子 */
#switch :deep(.el-switch__label--left) span {
    margin-left: 15px;
    font-size: 12px;
}

/*显示文字*/
#switch :deep(.el-switch__label.is-active) {
    display: block;
}

/* 调整按钮的宽度 */
#switch :deep(.el-switch) .el-switch__core,
#switch :deep(.el-switch) .el-switch__label {
    width: 55px !important;
    margin: 0;
    padding: 5px;
}

/*操作中-按钮样式*/
.el-button--mini {
    min-height: 28px;
    padding: 0 8px;
    font-size: 12px;
    border-radius: calc(var(--el-border-radius-base) - 1px);
}

.box-list {
    margin-top: 10px;
    background-color: #ffffff;
    border-radius: 4px;
    line-height: 40px;
    padding: 0 20px;
}
</style>
