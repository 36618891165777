<template>
    <div class="card-box">
        <div class="title-box">
            <div class="flex-1">
                <span class="title-box-span">{{ title }}</span>
                <span> <slot name="span"></slot> </span>
            </div>
            <div>
                <slot name="buttons"></slot>
            </div>
        </div>
        <el-divider />
        <slot name="content"></slot>
    </div>
</template>

<script>
import { defineComponent } from 'vue'

export default defineComponent({
    name: 'dpw-card',
    props: {
        title: {
            type: String,
            default: '数据列表'
        }
    },
    data() {
        return {}
    }
})
</script>

<style scoped>
.card-box {
    padding: 20px;
    border-radius: 4px;
    background-color: #fff;
    color: #333;
}
.title-box {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    flex-wrap: nowrap;
    gap: 20px;
}
.flex-1 {
    flex: 1;
}
.title-box-span {
    font-size: 14px;
}
</style>
