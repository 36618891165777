<template>
  <div>
    <mainone :mainonebread="mainonebread"></mainone>
    <maintwo :maintwoiconshow="maintwoiconshow" :maintwoforminfo="maintwoforminfo"
      :myinputoneisshow="maintwoforminfo.myinputoneisshow" :myinputone="maintwoforminfo.myinputone"
      :myselectleftisshow="maintwoforminfo.selectleftisshow" :myselectleft="maintwoforminfo.selectleft"
      :myinputishow="myinputishow" :myinput="myinput" :myinputonefullisshow="maintwoforminfo.myinputonefullisshow"
      :myinputonefull="maintwoforminfo.myinputonefull" :myselectisshow="myselectisshow" :myselect="myselect"
      :myselectcityisshow="myselectcityisshow" :myselectcity="myselectcity" v-on:getsearchinfo="getSeachinfoEvent"
      v-on:searchForm="searchFormEvent"></maintwo>
    <!--table表单-->
    <div>
      <!--主list列表-->
      <div style="margin-top: 10px;background-color: #ffffff;border-radius: 4px;line-height: 40px;padding: 0 20px;">
        <div style="line-height: 60px">
          <!--左侧名称-->
          <div style="float: left;font-size: 14px;"> 数据列表</div>
          <!--右侧按钮-->
          <div style="float: right">
            <!--自定义按钮-点击出现弹框&点击跳转子类页面@click="routeraddrClick('/XX路由')"-->
            <!--1、点击出现弹框@click="addTanClick(XX弹框配置信息,dialogVisible = true);resetFormSenior(XXform表单)"-->
            <!--2、点击跳转子类页面@click="routeraddrClick('/XX路由')-->
            <el-button type="primary" plain @click="routeraddrClick('/curriculumlistadd')" v-if="mypowerlimits.kclb_tj">
              添加</el-button>
            <!--导出按钮-点击导出数据-->
            <el-button type="warning" plain @click="exportDatas()">导出</el-button>
          </div>
        </div>

        <div>
          <!--table表单-->
          <el-table ref="multipleTable" :row-class-name="tableRowClassName"
            :data="mainthreetableData.slice((currentPage - lastpage) * PageSize, currentPage * PageSize)"
            style="width: 100%" min-height="900" border v-loading="loading" element-loading-text="正在飞速加载中..."
            @selection-change="handleSelectionChange" @cell-click="handleOneData">
            <!--多选框-用于用户选择性导出数据-->
            <el-table-column type="selection" width="55" v-if="mainthreeisshowset" />
            <el-table-column v-for="(mytabletitle, i) in mainthreetabletitle" :key="i" :fixed="mytabletitle.fixedstatu"
              :prop="mytabletitle.prop" :label="mytabletitle.label" :width="mytabletitle.width">
              <!--这里先通过table列表中的title值进行判断，在进行渲染展示-->
              <!--例如：status状态字段，判断最终的值是那种表现形式等-->
              <template #default="scope" v-if="mytabletitle.prop === 'is_up' || mytabletitle.prop === 'pay_count'">
                <!-- 购买人数-数据渲染 class="mytabletext" @click="routeraddrClick('/curriculumorder', scope.row)"-->
                <div v-if="mytabletitle.prop === 'pay_count'">
                  <div>
                    <span>{{ scope.row.pay_count }}</span>
                  </div>
                </div>
                <!-- 上架-数据渲染 -->
                <div v-if="mytabletitle.prop === 'is_up'">
                  <div id="switch">
                    <el-switch v-model="scope.row.is_up" active-text="上架" inactive-text="下架" :active-value='1'
                      :inactive-value='2' active-color="#13ce66"
                      @click="mypowerlimits.kclb_qy ? changeStatus(scope.row) : ''" :disabled="!mypowerlimits.kclb_qy"
                      inactive-color="#cbcbcb">
                    </el-switch>
                  </div>
                </div>
              </template>
            </el-table-column>
            <!--操作&按钮-->
            <el-table-column fixed="right" label="操作" :width="mainthreehandle" v-if="mainthreeisshowope"
              prop="myoperation" id="operation">
              <template #default="scope">
                <!--操作：按照页面区分，且每个按钮对应相关函数-->
                <!--操作-课程列表-->
                <div v-if="mainthreetableope === 15">
                  <el-button type="primary" size="mini" @click="routeraddrClick('/curriculumlistedit', scope.row, 1)">查看
                  </el-button>
                  <el-button type="primary" size="mini" @click="routeraddrClick('/curriculumlistedit', scope.row, 2)"
                    v-if="mypowerlimits.kclb_bj">编辑
                  </el-button>
                </div>
              </template>
            </el-table-column>
          </el-table>
          <!--分页器-->
          <div class="block" style="text-align: center;margin: 30px 0">
            <el-pagination background @size-change="handleSizeChange" @current-change="handleCurrentChange"
              :current-page="currentPage" :page-sizes="pageSizes" :page-size="PageSize"
              layout="total, sizes, prev, pager, next, jumper" :total="totalCount">
            </el-pagination>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import mainone from '@/components/Mainone'
import maintwo from '@/components/Maintwo'
import { ElMessage } from "element-plus";
import axios from "axios";
import { defineComponent } from "vue";

export default defineComponent({
  name: "vcurriculumlist",
  inject: ['reload'],
  data() {
    return {
      mainonebread: [
        {
          name: '教育',

        },
        {
          name: '课程管理',

        },
        {
          name: '课程列表',
          path: '/curriculumlist'
        }
      ], // 当前页-【面包屑导航】
      maintwoiconshow: false, // 筛选-【高级筛选按钮】
      maintwoforminfo: {
        myinputoneisshow: true, //筛选查询-左侧字+input
        myinputone: {
          divinterval: '0', //框右侧距离
          titlewidth: '74px',  //标题宽度
          inputwidth: '200px',  //input宽度
          inputinterval: '20px', //input右侧距离
          input: [
            {
              ismust: false, //是否必填，true表示必填
              title: '输入搜索：',
              placeholder: '课程名称',
              name: 'name',
              content: '',
            }
          ],
        },
        selectleftisshow: true, //筛选查询-左侧字+select单框||select时间
        selectleft: {
          width: 'none',
          divinterval: '', //框右侧距离
          selectwidth: '200px',
          selectinterval: '20px', //select右侧距离
          select: [
            {
              selectisshow: true,
              ismust: false,
              title: '分类：',
              placeholder: '全部',
              name: 'classify_id',
              value: '',
              options: [
                {
                  label: '全部',
                  value: ''
                },
              ],
            },
            {
              selectisshow: true,
              ismust: false,
              title: '上架：',
              placeholder: '全部',
              name: 'is_up',
              value: '',
              options: [
                {
                  label: '全部',
                  value: ''
                },
                {
                  label: '上架',
                  value: '1'
                },
                {
                  label: '下架',
                  value: '2'
                }
              ],
            },
          ]
        }
      }, // 筛选-控件信息
      mainthreeisshowset: false, // 数据列表中-复选框
      mainthreeisshowope: true, // 数据列表中-操作-根据页面显示模块（整页）
      mainthreetableope: 15, // 数据列表中-操作-根据页面显示控件（整页）
      mainthreehandle: 150, // 数据列表中-操作-宽度
      mainthreetabletitle: [
        {
          fixedstatu: 'left',
          prop: 'name',
          label: '课程名称',
          width: ''
        },
        {
          fixedstatu: false,
          prop: 'classify_name',
          label: '课程分类',
          width: '150'
        },
        {
          fixedstatu: false,
          prop: 'price',
          label: '课程价格',
          width: '150'
        },
        {
          fixedstatu: false,
          prop: 'vip_price',
          label: 'PLUS课程价格',
          width: '150'
        },
        {
          fixedstatu: false,
          prop: 'pay_count',
          label: '购买人数',
          width: '120'
        },
        {
          fixedstatu: false,
          prop: 'admin_name',
          label: '创建人',
          width: '150'
        },
        {
          fixedstatu: false,
          prop: 'is_up',
          label: '上架',
          width: '100'
        },
        {
          fixedstatu: false,
          prop: 'addtime',
          label: '创建时间',
          width: '180'
        },

      ], // 数据列表中-数据表头
      mainthreestatus: true, // 数据列表中-属于search按钮表示状态
      mainthreetableData: [],// 数据列表中-具体数据
      totalCount: 1, //数据列表中-数据总条数
      currentpage: 1, //数据列表中-数据当前页数
      lastpage: 1, //数据列表中-数据总页数（默认以20条一页）
      loading: false, //初始化-loading加载动效，默认是false关闭

      //table表单相关数据
      seniorform: {},
      currentPage: 1,
      pageSizes: [20, 50, 100],
      PageSize: 20,
      multipleSelection: [],
      thispagename: '', //通过路由获取当前页面的path值
      searchwidth: '580px',
      tancontent: {
        title: '弹框标题',
        content: {},
      },//弹框

      mypowerlimits: JSON.parse(localStorage.getItem("powerlimits")), //获取权限
      mytoken: localStorage.getItem('token'),  //获取存储的token
      pagesdatas: [], //存储最近一次的筛选条件，供分页使用
    }
  },

  mounted() {
    //获取当前页面路由path值
    this.thispagename = this.$route.path;
  },
  methods: {
    // 切换-上架/下架
    changeStatus(data) {
      console.log(data)
      axios
        .post(
          "/manage/Course/changeCourseStatus",
          {
            id: data.id,
            is_up: data.is_up
          },
          {
            headers: {
              Auth: localStorage.getItem("token"),
            },
          }
        )
        .then((response) => {
          if (response.data.code === 0) {
            ElMessage({
              type: "success",
              message: response.data.message,
            });
          } else {
            // 切换状态失败-复原状态
            if (data.is_up == 1) {
              data.is_up = 2
            } else {
              data.is_up = 1
            }
          }
        })
        .catch(function (error) {
          console.log(error);
        });
    },
    //table表-操作中-删除按钮（函数）
    deleteRow(index, rows) {
      //在table表中删除当前行信息，而rows.splice(index, 1)仅属于前端删除，可配合后具体操作
      rows.splice(index, 1)
    },
    //table表中-当选项发生变化是触发该函数
    handleSelectionChange(val) {
      console.log('this is:' + val)
      this.multipleSelection = val
    },
    //分页器中-当选择条数时触发该函数
    handleSizeChange(val) {
      console.log(`每页 ${val} 条`)
      this.PageSize = val
      this.currentPage = 1

      //加载loading
      this.loading = true
      //通过Ajax向后台获取数据
      axios.post('/manage/Course/indexCourse',
        {
          name: this.pagesdatas.name,
          classify_id: this.pagesdatas.classify_id,
          is_up: this.pagesdatas.is_up,
          page: 1,
          page_size: val,
        },
        {
          headers: {
            Auth: localStorage.getItem("token")
          }
        })
        .then(response => {
          this.myaxiosfunc(response.data.result)
        })
        .catch(function (error) { // 请求失败处理
          console.log(error)
        })
    },
    //分页器中-当选择分页时触发该函数
    handleCurrentChange(val) {
      console.log(`当前页: 第${val}页`)
      this.currentPage = val

      //加载loading
      this.loading = true
      //通过Ajax向后台获取数据
      axios.post('/manage/Course/indexCourse',
        {
          name: this.pagesdatas.name,
          classify_id: this.pagesdatas.classify_id,
          is_up: this.pagesdatas.is_up,
          page: val,
          page_size: this.PageSize,
        },
        {
          headers: {
            Auth: localStorage.getItem("token")
          }
        })
        .then(response => {
          this.myaxiosfunc(response.data.result)
        })
        .catch(function (error) { // 请求失败处理
          console.log(error)
        })
    },
    //点击进行路由页面跳转以及携带参数
    routeraddrClick(routeraddr, rowdata, flag) {
      if (rowdata) {
        // 跳转-课程订单列表
        if (routeraddr === "/curriculumorder") {
          this.$router
            .push({
              path: routeraddr,
              query: rowdata,
            })
            .catch((error) => error);
        }
        // 跳转-课程详情
        if (routeraddr === "/curriculumlistedit" && flag == 1) {
          this.$router
            .push({
              path: routeraddr,
              query: { id: rowdata.id, flag: 1 },
            })
            .catch((error) => error);
        }
        // 跳转-课程编辑
        if (routeraddr === "/curriculumlistedit" && flag == 2) {
          this.$router
            .push({
              path: routeraddr,
              query: { id: rowdata.id },
            })
            .catch((error) => error);
        }
      } else {
        //仅跳转
        this.$router.push(routeraddr).catch((error) => error);
      }
    },
    //导出数据
    exportDatas() {
      //通过Ajax向后台获取数据
      axios.post('/manage/Course/indexCourse',
        {
          name: this.pagesdatas.name,
          classify_id: this.pagesdatas.classify_id,
          is_up: this.pagesdatas.is_up,
          extend: 1
        },
        {
          headers: {
            Auth: localStorage.getItem("token")
          }
        })
        .then(response => {
          ElMessage({ type: "success", message: response.data.message });
        })
        .catch(function (error) { // 请求失败处理
          console.log(error)
        })
    },

    //获取当前windows页面信息-实时修改【弹窗】的宽度
    getwindowinfo() {
      this.sreenheight = document.documentElement.clientHeight;
      this.sreenwidth = document.documentElement.clientWidth;
      if (this.sreenwidth < 600) {
        this.searchwidth = (this.sreenwidth * 0.8) + 'px';
      } else {
        this.searchwidth = '580px'
      }
    },


    //提交等相关event事件函数
    //获取Container-Pagedata-Maintwo-搜索-返回的数据
    getSeachinfoEvent(data) {
      console.log('点击查询获取的data数组：' + JSON.stringify(data))
      this.pagesdatas = data;//将筛选条件赋值给本页的pagesdatas存储，供分页使用
      //加载loading
      this.loading = true
      //通过Ajax向后台获取数据
      axios.post('/manage/Course/indexCourse',
        {
          name: data.name,
          classify_id: data.classify_id,
          is_up: data.is_up,
          page: 1,
          page_size: this.PageSize,
        },
        {
          headers: {
            Auth: localStorage.getItem("token")
          }
        })
        .then(response => {
          this.myaxiosfunc(response.data.result)
        })
        .catch(function (error) { // 请求失败处理
          console.log(error)
        })
    },
    //数据渲染
    myaxiosfunc(successdata) {
      var newdata = successdata.data
      for (var i = 0; i < newdata.length; i++) {
        //该字段表示search按钮，且status为1表示启用，2表示禁用
        newdata[i].switchstatus = 4;
        //转换创建时间
        if (newdata[i].addtime) {
          if (newdata[i].addtime === 0 || newdata[i].addtime === null) {
            newdata[i].addtime = '无创建时间'
          } else {
            newdata[i].addtime = this.$utils.formatDate(newdata[i].addtime)
          }
        } else {
          newdata[i].addtime = '无创建时间'
        }
      }
      // console.log('当前页面的data:' + JSON.stringify(newdata))
      this.mainthreetableData = newdata;
      this.totalCount = successdata.total
      this.currentpage = successdata.current_page; //当前页面页数
      this.lastpage = successdata.last_page;
      this.loading = false;
    }
  },
  created() {
    if (localStorage.getItem("token") === null || !localStorage.getItem("token")) {
      this.isshow = false
      this.$router.push('/').catch(error => error)
    } else {
      window.addEventListener('resize', this.getwindowinfo); // 注册监听器-实时检测浏览器页面宽高
      this.getwindowinfo()

      // 分类-数据下拉获取
      axios.post('/manage/Course/indexCourseCate',
        {},
        {
          headers: {
            Auth: localStorage.getItem("token")
          }
        })
        .then(response => {
          // 分类-下拉获取
          var catalog = [{ value: '', label: '全部' }]
          for (var key in response.data.result.data) {
            catalog.push({ label: response.data.result.data[key].sort_key, value: response.data.result.data[key].sort_val })
          }
          this.maintwoforminfo.selectleft.select[0].options = catalog;
        })
        .catch(function (error) {
          console.log(error)
        })

      //加载loading
      this.loading = true

      this.pagesdatas = {
        page: 1,
        page_size: this.PageSize,
      }
      let routerinfo = this.$route.query;
      // 分类
      if (routerinfo.sort_val) {
        this.pagesdatas.classify_id = routerinfo.sort_val;
        this.maintwoforminfo.selectleft.select[0].value = parseInt(routerinfo.sort_val)
      }

      //通过Ajax向后台获取数据
      axios.post('/manage/Course/indexCourse',
        this.pagesdatas,
        {
          headers: {
            Auth: localStorage.getItem("token")
          }
        })
        .then(response => {
          this.myaxiosfunc(response.data.result)
        })
        .catch(function (error) {
          console.log(error)
        })


    }
  },
  components: {
    mainone,
    maintwo,
  }
});
</script>

<style scoped>
/*局部控制-Switch按钮-按钮中显示状态文字*/
/* switch按钮款式 */
#switch :deep(.el-switch__label) {
  position: absolute;
  display: none;
  color: #fff !important;
}

/*关上时文字地位设置*/
#switch :deep(.el-switch__label--right) {
  z-index: 1;
}

/* 调整关上时文字的显示位子 */
#switch :deep(.el-switch__label--right) span {
  margin-left: 3px;
  font-size: 12px;
}

/*敞开时文字地位设置*/
#switch :deep(.el-switch__label--left) {
  z-index: 1;
}

/* 调整敞开时文字的显示位子 */
#switch :deep(.el-switch__label--left) span {
  margin-left: 15px;
  font-size: 12px;
}

/*显示文字*/
#switch :deep(.el-switch__label.is-active) {
  display: block;
}

/* 调整按钮的宽度 */
#switch :deep(.el-switch) .el-switch__core,
#switch :deep(.el-switch) .el-switch__label {
  width: 55px !important;
  margin: 0;
  padding: 5px;
}

/*操作中-按钮样式*/
.el-button--mini {
  min-height: 28px;
  padding: 0 8px;
  font-size: 12px;
  border-radius: calc(var(--el-border-radius-base) - 1px);
}

.mytabletext {
  color: var(--el-color-primary);
  cursor: pointer;
}
</style>