<template>
    <div v-loading="loading" element-loading-text="正在飞速加载中...">
        <mainone :mainonebread="mainonebread"></mainone>
        <dpwCard title="详情">
            <template #content>
                <dpwTitle title="基本信息"></dpwTitle>
                <dpwDetails :datas="data_options"></dpwDetails>
                <el-divider />
                <dpwTitle title="详情列表"></dpwTitle>
                <dpwTable
                    v-loading="loading"
                    element-loading-text="正在飞速加载中..."
                    ref="myTableRef"
                    :tabletitle="tableTitle"
                    :tabledata="tableData"
                    :totalcount="totalCount"
                    :currentpage="currentPage"
                    :pagesize="PageSize"
                    :Lastpage="lastpage"
                >
                    <template #content="{ scope, prop }">
                        <div v-if="prop === 'price' && mypowerlimits.rkd_cwbc">
                            <el-input
                                v-model="tableData[scope.$index].price"
                                @keyup="(event) => onkeyupEvent(event, scope.$index)"
                                maxlength="20"
                                clearable
                                @input="inputEvent"
                            ></el-input>
                        </div>
                        <div v-else-if="prop === 'goods_num'">
                            <div
                                :class="scope.row['goods_info']['in_type'] == 2 ? 'mytabletext' : ''"
                                @click="scope.row['goods_info']['in_type'] == 2 ? tanClickEvent(scope.row) : ''"
                            >
                                <span>{{ scope.row[prop] }}</span>
                            </div>
                        </div>
                        <div v-else>
                            <span v-if="prop.split('.')[0] == 'goods_info'">{{ scope.row[prop.split('.')[0]][prop.split('.')[1]] }}</span>
                            <span v-else>{{ scope.row[prop] }}</span>
                        </div>
                    </template>
                </dpwTable>
                <div v-if="mypowerlimits.rkd_cwbc" style="text-align: right; margin: 20px 0">
                    <el-button type="primary" @click="saveEvent({ id: formdata.id, list: tableData })" :disabled="disabled" style="width: 10%; min-width: 100px">保存</el-button>
                </div>
            </template>
        </dpwCard>

        <!-- 弹窗 -->
        <div>
            <el-dialog v-model="tanbox.isshow" :title="tanbox.title" width="40%" :before-close="handleClose">
                <dpwTable
                    v-loading="tanbox.loading"
                    element-loading-text="正在飞速加载中..."
                    ref="myTableDataRef"
                    :tabletitle="tanbox.tabletitle"
                    :tabledata="tanbox.tableData"
                    :totalcount="tanbox.totalCount"
                    :currentpage="tanbox.currentPage"
                    :pagesize="tanbox.PageSize"
                    :Lastpage="tanbox.lastpage"
                >
                    <template #content="{ scope, prop }">
                        <div>
                            <span>{{ scope.row[prop] }}</span>
                        </div>
                    </template>
                </dpwTable>
                <div class="block" style="text-align: left; margin: 30px 0">
                    <el-pagination
                        background
                        @size-change="tanHandleSizeChange"
                        @current-change="tanHandleCurrentChange"
                        :current-page="tanbox.currentPage"
                        :page-sizes="tanbox.pageSizes"
                        :page-size="tanbox.PageSize"
                        layout="total, sizes, prev, pager, next, jumper"
                        :total="tanbox.totalCount"
                    >
                    </el-pagination>
                </div>
            </el-dialog>
        </div>
    </div>
</template>

<script>
import { defineComponent } from 'vue'
import mainone from '@/components/Mainone'
import dpwDetails from '../../acommon/dpw-details.vue'
import dpwCard from '../../acommon/dpw-card.vue'
import dpwTitle from '../../acommon/dpw-title.vue'
import dpwTable from '../../acommon/dpw-table.vue'
import { ElMessage } from 'element-plus'
import axios from 'axios'

export default defineComponent({
    name: 'vgoinmonadinfo',
    data() {
        return {
            mainonebread: [
                {
                    name: '仓库'
                },
                {
                    name: '出入库管理'
                },
                {
                    name: '入库单'
                },
                {
                    name: '详情'
                }
            ], // 当前页-【面包屑导航】

            bgcolor: {
                1: 'var(--el-color-primary)',
                2: 'var(--el-color-warning)',
                3: 'var(--el-color-success)'
            },

            data_options: [],
            tableTitle: [
                {
                    fixed: 'left',
                    prop: 'goods_id',
                    label: 'ID',
                    width: '150'
                },
                {
                    fixed: false,
                    prop: 'goods_info.name',
                    label: '产品名称',
                    width: ''
                },
                {
                    fixed: false,
                    prop: 'goods_info.goods_cate',
                    label: '类别',
                    width: ''
                },
                {
                    fixed: false,
                    prop: 'goods_num',
                    label: '实收数量',
                    width: ''
                },
                {
                    fixed: false,
                    prop: 'goods_info.unit',
                    label: '单位',
                    width: ''
                }
            ],
            tableData: [],
            totalCount: 0, //数据列表中-数据总条数
            currentpage: 1, //数据列表中-数据当前页数
            lastpage: 1, //数据列表中-数据总页数（默认以20条一页）
            loading: false, //初始化-loading加载动效，默认是false关闭
            currentPage: 1,
            pageSizes: [10, 20, 50, 100],
            PageSize: 1000,

            tanbox: {
                isshow: false,
                title: '',
                width: '',
                tabletitle: [
                    {
                        fixed: false,
                        prop: 'id',
                        label: 'ID',
                        width: '100'
                    },
                    {
                        fixed: false,
                        prop: 'only_code',
                        label: '唯一标识',
                        width: ''
                    }
                ],
                tableData: [],
                totalCount: 0,
                currentPage: 1,
                PageSize: 10,
                pageSizes: [10, 20, 50, 100],
                lastpage: 1
            },

            disabled: false,
            formdata: {},

            mypowerlimits: JSON.parse(localStorage.getItem('powerlimits')), //获取权限
            mytoken: localStorage.getItem('token') //获取存储的token
        }
    },
    methods: {
        //分页器中-当选择条数时触发该函数
        tanHandleSizeChange(val) {
            // console.log(`每页 ${val} 条`)
            this.tanbox.PageSize = val
            this.tanbox.currentPage = 1

            this.tanbox.pagesdatas = {
                id: this.tanbox.pagesdatas.id,
                goods_id: this.tanbox.pagesdatas.goods_id,
                type: this.tanbox.pagesdatas.type,
                page: 1,
                page_size: val
            }

            this.tanpostAxiosEvent(this.tanbox.pagesdatas)
        },
        //分页器中-当选择分页时触发该函数
        tanHandleCurrentChange(val) {
            // console.log(`当前页: 第${val}页`)
            this.tanbox.currentPage = val

            this.tanbox.pagesdatas = {
                id: this.tanbox.pagesdatas.id,
                goods_id: this.tanbox.pagesdatas.goods_id,
                type: this.tanbox.pagesdatas.type,
                page: val,
                page_size: this.tanbox.PageSize
            }

            this.tanpostAxiosEvent(this.tanbox.pagesdatas)
        },
        tanpostAxiosEvent(data) {
            this.tanbox.loading = true
            axios
                .post('/manage/pack/packInGoodsInfo', data, {
                    headers: {
                        Auth: localStorage.getItem('token')
                    }
                })
                .then((response) => {
                    let getdatas = response.data.result
                    this.tanbox.tableData = getdatas.data
                    this.tanbox.totalCount = getdatas.total
                    this.tanbox.currentPage = getdatas.current_page
                    this.tanbox.PageSize = getdatas.per_page
                    this.tanbox.lastpage = getdatas.last_page
                    this.tanbox.loading = false
                })
                .catch(function () {
                    this.tanbox.loading = false
                })
        },
        tanClickEvent(data) {
            this.tanbox.isshow = true
            this.tanbox.title = '实收数量-详情【' + data['goods_info'].name + '】'

            this.tanbox.pagesdatas = {
                id: this.formdata.id,
                goods_id: data.goods_id,
                type: 1,
                page: this.tanbox.currentPage,
                page_size: this.tanbox.PageSize
            }
            this.tanpostAxiosEvent(this.tanbox.pagesdatas)
        },
        handleClose(done) {
            done()
            this.tanbox.isshow = false
        },
        onkeyupEvent(event, index) {
            const inputValue = event.target.value
            // 修改输入框的值
            event.target.value = inputValue
                .toUpperCase()
                .replace(/^(0|[1-9]\d*)(\.\d{3,})?$/, '$1')
                .replace(/^0[0-9].*$/, '')
                .replace(/^-[1-9]\d*$/, '')
                .replace(/^\d*\.(\d*\.)$/, '')
                .replace(/^[^0-9\.]+$/, '')
            if (isNaN(event.target.value)) event.target.value = ''
            this.tableData[index].price = event.target.value
        },
        // 价格数字截取&转分
        numberChangeEvent(price) {
            if (price < 0 || isNaN(price)) return ''
            let str_price = price.toString()
            let num = str_price.split('.')
            let new_price = ''
            if (num.length > 1) {
                if (num[1].toString().length > 2) num[1] = '00'
                let datas = num[1].substr(0, 2)
                if (datas.length < 2) {
                    datas += '0'
                    num[1] = datas
                }
                new_price = (parseInt(num[0]) + parseInt(num[1].substr(0, 2)) / 100) * 100
            } else {
                new_price = num[0] != '' ? parseInt(num[0]) * 100 : ''
            }

            return new_price
        },
        // 保存
        saveEvent({ id, list }) {
            this.disabled = true
            let getdatas = {
                id: id,
                list: list.map((n) => {
                    return { goods_id: n.goods_id, price: this.numberChangeEvent(n.price) }
                })
            }

            axios
                .post('/manage/pack/savePackGoodsPrice', getdatas, {
                    headers: {
                        Auth: localStorage.getItem('token')
                    }
                })
                .then((response) => {
                    if (response.data.code === 0) {
                        ElMessage({
                            type: 'success',
                            message: '保存成功！',
                            duration: 1000,
                            onClose: () => {
                                this.$router.back()
                                this.disabled = false
                            }
                        })
                    } else {
                        this.disabled = false
                    }
                })
                .catch(function () {
                    this.disabled = false
                })
        },
        // 获取详情
        getInfoEvent(data) {
            this.loading = true

            axios
                .post('/manage/pack/packInView', data, {
                    headers: {
                        Auth: localStorage.getItem('token')
                    }
                })
                .then((response) => {
                    let getdatas = response.data.result
                    this.formdata = getdatas

                    // 基本信息
                    this.data_options = [
                        { title: '入库编号', value: getdatas.in_no },
                        { title: '入库类型', value: getdatas.in_type },
                        { title: '入库日期', value: getdatas.in_time != 0 ? this.$utils.formatDate(getdatas.in_time).substr(0, 10) : '' },
                        { title: '供应商', value: getdatas.supplier_name },
                        { title: '仓库', value: getdatas.pack_name },
                        { title: '备注', value: getdatas.remark },
                        { title: '数量', value: getdatas.total_num },
                        { title: '入库人', value: getdatas.truename },
                        { title: '创建时间', value: getdatas.addtime != 0 ? this.$utils.formatDate(getdatas.addtime) : '' },
                        { title: '入库单照片', value: getdatas.imgs != '' ? getdatas.imgs.split(',') : '', imgs: true }
                    ]
                    // 详情列表
                    if (this.mypowerlimits.rkd_cwbc) {
                        this.tableTitle.push({
                            fixed: false,
                            prop: 'price',
                            label: '单价',
                            width: ''
                        })
                        this.tableData = getdatas.goods.map((n) => {
                            return {
                                goods_id: n.goods_id,
                                goods_info: n.goods_info,
                                goods_num: n.goods_num,
                                price: n.price !== '' ? parseInt(n.price) / 100 : ''
                            }
                        })
                    } else {
                        this.tableData = getdatas.goods.map((n) => {
                            return {
                                goods_id: n.goods_id,
                                goods_info: n.goods_info,
                                goods_num: n.goods_num
                            }
                        })
                    }

                    this.loading = false
                })
                .catch(function (error) {
                    // 请求失败处理
                    console.log(error)
                })
        }
    },
    created() {
        if (localStorage.getItem('token') === null || !localStorage.getItem('token')) {
            this.$router.push('/').catch((error) => error)
        } else {
            let routerInfo = this.$route.query
            this.getInfoEvent({ id: routerInfo.id })
        }
    },
    components: {
        mainone,
        dpwCard,
        dpwDetails,
        dpwTitle,
        dpwTable
    }
})
</script>

<style scoped>
.span-box {
    color: #fff;
    font-weight: bold;
    padding: 4px 8px;
    margin-left: 20px;
    border: 0px solid #dedede;
    border-radius: 4px;
}

.mytabletext {
    color: var(--el-color-primary);
    cursor: pointer;
}
</style>
