<template>
    <div>
        <mainone :mainonebread="mainonebread"></mainone>
        <div v-loading="loading" element-loading-text="正在飞速加载中...">
            <!--标题-->
            <div class="box">
                <!--内容-->
                <div class="box_content" id="switch">
                    <el-tabs
                        v-model="activeName"
                        @tab-click="
                            (tab, event) => {
                                handleClick(tab, event)
                            }
                        "
                    >
                        <!-- 设备详情 -->
                        <el-tab-pane label="设备详情" name="one" v-if="mypowerlimits.jqkz_bjsbxq">
                            <div style="width: 100%; margin-top: 10px">
                                <el-row>
                                    <!-- 占位符 -->
                                    <el-col :span="24" :xs="24" :sm="24" :md="24" :lg="4" :xl="4">
                                        <div style="width: 100%"></div>
                                    </el-col>
                                    <!-- 内容 -->
                                    <el-col :span="24" :xs="24" :sm="24" :md="24" :lg="13" :xl="13">
                                        <el-form
                                            ref="dvcinfoformdata"
                                            :rules="set_dvcinfo_rules"
                                            :model="this.set_dvcinfo.formdata"
                                            class="demo-ruleForm"
                                            label-width="auto"
                                            :label-position="right"
                                        >
                                            <el-form-item label="设备编号：">
                                                <div style=" display: inline-flex; flex-direction: row; flex-wrap: wrap; height: 32px;">
                                                    <span style="margin-right: 20px; font-size: 13px">{{ this.set_dvcinfo.formdata.dvcnum }}</span>
                                                    <el-button
                                                        type="text"
                                                        size="small"
                                                        @click="addTanClick(this.set_dvcinfo, 'downcode', null, (this.set_dvcinfo.look_qrcode.dialogVisible = true))"
                                                        >查看二维码</el-button
                                                    >
                                                    <!-- <el-button
                                                        type="text"
                                                        size="small"
                                                        @click="addTanClick(this.set_dvcinfo, 'conductcode', null, (this.set_dvcinfo.look_conduct.dialogVisible = true))"
                                                        >查看宣传码</el-button
                                                    > -->
                                                    <el-button type="primary" plain size="small" @click="addTanClick(this.set_dvcinfo, 'downcode', 'printQrcode')"
                                                        >打印二维码</el-button
                                                    >
                                                    <!-- <el-button type="primary" plain size="small" @click="addTanClick(this.set_dvcinfo, 'conductcode', 'printPub')"
                                                        >打印宣传码</el-button
                                                    > -->
                                                </div>
                                            </el-form-item>
                                            <el-form-item label="ID：">
                                                <span style="margin-right: 20px; font-size: 13px"> {{ this.set_dvcinfo.formdata.id }}</span>
                                            </el-form-item>
                                            <el-form-item label="打印机序列号：">
                                                <span style="margin-right: 20px; font-size: 13px">{{ this.set_dvcinfo.formdata.serialnumber }}</span>
                                            </el-form-item>
                                            <el-form-item label="Teamviewer远程账号/密码：">
                                                <span style="margin-right: 20px; font-size: 13px">{{ this.set_dvcinfo.formdata.teamviewer }}</span>
                                            </el-form-item>
                                            <el-form-item label="远程控制：">
                                                <el-button type="text" size="small" @click="routeraddrClick('/sharedvccontrolfrp', this.set_dvcinfo.formdata)"
                                                    >进入远程控制</el-button
                                                >
                                            </el-form-item>
                                            <el-form-item label="版本/版本号：">
                                                <span style="margin-right: 20px; font-size: 13px">{{ this.set_dvcinfo.formdata.vernumber }}</span>
                                            </el-form-item>
                                            <el-form-item label="状态：">
                                                <div class="tan-table-status">
                                                    <div style="margin-right: 10px">
                                                        <el-button type="primary" size="mini" v-if="this.set_dvcinfo.formdata.status.printstatus == 0">空闲</el-button>
                                                        <el-button type="success" size="mini" v-if="this.set_dvcinfo.formdata.status.printstatus == 1">使用</el-button>
                                                    </div>
                                                    <div>
                                                        <el-button type="success" size="mini" v-if="this.set_dvcinfo.formdata.status.is_online == 1">在线</el-button>
                                                        <el-button type="danger" size="mini" v-if="this.set_dvcinfo.formdata.status.is_online == 0">离线</el-button>
                                                    </div>
                                                </div>
                                            </el-form-item>
                                            <el-form-item label="店铺名称：" prop="shopname">
                                                <el-input v-model="this.set_dvcinfo.formdata.shopname" placeholder="" clearable> </el-input>
                                            </el-form-item>
                                            <el-form-item label="店铺门头：" prop="shopimage">
                                                <mainimgesup
                                                    v-model="this.set_dvcinfo.formdata.shopimage"
                                                    :myimgesup="myimgesup"
                                                    :myimgesupisshow="myimgesupisshow"
                                                    :filelist="this.set_dvcinfo.formdata.shopimage_list"
                                                    v-on:getuploadfile="(data) => getUploadFileEvent(data, 1)"
                                                    v-on:deluploadfile="(data) => deluploadfileEvent(data, 1)"
                                                ></mainimgesup>
                                            </el-form-item>
                                            <el-form-item label="设备图片：" prop="dvcimage">
                                                <mainimgesup
                                                    v-model="this.set_dvcinfo.formdata.dvcimage"
                                                    :myimgesup="myimgesup2"
                                                    :myimgesupisshow="myimgesupisshow"
                                                    :filelist="this.set_dvcinfo.formdata.dvcimage_list"
                                                    v-on:getuploadfile="(data) => getUploadFileEvent(data, 2)"
                                                    v-on:deluploadfile="(data) => deluploadfileEvent(data, 2)"
                                                ></mainimgesup>
                                            </el-form-item>
                                            <el-form-item label="联系方式：" prop="mobile">
                                                <el-input v-model="this.set_dvcinfo.formdata.mobile" placeholder="" clearable step="1" maxlength="11"> </el-input>
                                            </el-form-item>
                                            <el-form-item>
                                                <el-button
                                                    type="primary"
                                                    style="width: 150px; margin-right: 10px"
                                                    @click="dvcinfoSubmit('dvcinfoformdata')"
                                                    :disabled="mydisabled && this.button_display == 1"
                                                    >保存
                                                </el-button>
                                                <el-button @click="cancelEvent()">取消</el-button>
                                            </el-form-item>
                                        </el-form>
                                    </el-col>
                                    <!-- 占位符 -->
                                    <el-col :span="24" :xs="24" :sm="24" :md="24" :lg="4" :xl="4">
                                        <div style="width: 100%"></div>
                                    </el-col>
                                </el-row>
                            </div>
                        </el-tab-pane>
                        <!-- 出厂安装设置 [注意：该设置比较特殊，同一个页面，标识控制，因此当都为false的时候才不显示]-->
                        <el-tab-pane label="出厂安装设置" name="four" v-if="(mypowerlimits.jqkz_bjccazsz || mypowerlimits.jqkz_cc) && this.runtype == 2">
                            <div style="width: 100%; margin-top: 10px">
                                <el-row>
                                    <!-- 占位符 -->
                                    <el-col :span="24" :xs="24" :sm="24" :md="24" :lg="2" :xl="3">
                                        <div style="width: 100%"></div>
                                    </el-col>
                                    <!-- 内容 -->
                                    <el-col :span="24" :xs="24" :sm="24" :md="24" :lg="15" :xl="15">
                                        <el-form
                                            ref="gooutfactory"
                                            :model="this.set_gooutfactory.formdata"
                                            :rules="this.set_gooutfactory_rules"
                                            class="demo-ruleForm"
                                            label-width="auto"
                                            :label-position="right"
                                        >
                                            <el-form-item label="公司：" prop="companyid">
                                                <div>
                                                    <el-select
                                                        v-model="this.set_gooutfactory.formdata.companyid"
                                                        filterable
                                                        placeholder="直接选择或搜索选择"
                                                        @change="
                                                            judgeIsDisabledAgent(),
                                                            getAfterSaleSelectEvent(this.set_gooutfactory.formdata.companyid, 1),
                                                                getShopCodeSelectEvent(null, 1, this.set_gooutfactory.formdata.companyid, 1, 1, 1)
                                                        "
                                                    >
                                                        <el-option v-for="(item, index) in this.set_gooutfactory.company_option" 
                                                                :key="index" 
                                                                :label="item.name" 
                                                                :value="item.id" 
                                                                :disabled="Object.keys(this.set_gooutfactory.set_disabled_fzr).length > 0 &&  this.set_gooutfactory.set_disabled_fzr.companyid == item.id ? true : false "/>
                                                    </el-select>
                                                    <!-- 备注：应 0731 版本-产品需求：刚进入页面时，禁用当前公司选项与一级代理商选项框【已与产品核对】 -->
                                                </div>
                                            </el-form-item>
                                            <el-form-item label="代理商：" prop="shop_code">
                                                <div>
                                                    <el-select
                                                        v-model="this.set_gooutfactory.formdata.shop_code[0]"
                                                        filterable
                                                        placeholder="直接选择或搜索选择"
                                                        @change="
                                                            getShopCodeSelectEvent(
                                                                this.set_gooutfactory.formdata.shop_code[0],
                                                                1,
                                                                this.set_gooutfactory.formdata.companyid,
                                                                2,
                                                                null,
                                                                1
                                                            )
                                                        "
                                                        :disabled="Object.keys(this.set_gooutfactory.set_disabled_fzr).length > 0 &&  this.set_gooutfactory.set_disabled_fzr.agentid && this.set_gooutfactory.is_disabled_agent == true ? true : false "
                                                    >
                                                        <el-option v-for="(item, index) in this.set_gooutfactory.agent_option" :key="index" :label="item.name" :value="item.id" />
                                                    </el-select>
                                                </div>
                                            </el-form-item>
                                            <el-form-item label="商户：">
                                                <div class="goout_shopman_box">
                                                    <div class="goout_shopman_box_one">
                                                        <el-select
                                                            v-model="this.set_gooutfactory.formdata.shop_code[1]"
                                                            filterable
                                                            placeholder="直接选择或搜索选择"
                                                            @change="
                                                                getShopCodeSelectEvent(
                                                                    this.set_gooutfactory.formdata.shop_code[0],
                                                                    1,
                                                                    this.set_gooutfactory.formdata.companyid,
                                                                    2,
                                                                    null,
                                                                    2
                                                                ),
                                                                    getShopCodeSelectEvent(
                                                                        this.set_gooutfactory.formdata.shop_code[1],
                                                                        2,
                                                                        this.set_gooutfactory.formdata.companyid,
                                                                        2
                                                                    )
                                                            "
                                                            :disabled="this.set_gooutfactory.formdata.shop_code[0] ? false : true"
                                                        >
                                                            <el-option
                                                                v-for="(item, index) in this.set_gooutfactory.shopman_option_one"
                                                                :key="index"
                                                                :label="item.name"
                                                                :value="item.id"
                                                            />
                                                        </el-select>
                                                    </div>
                                                    <div class="goout_shopman_box_one">
                                                        <el-select
                                                            v-model="this.set_gooutfactory.formdata.shop_code[2]"
                                                            filterable
                                                            placeholder="直接选择或搜索选择"
                                                            @change="
                                                                getShopCodeSelectEvent(this.set_gooutfactory.formdata.shop_code[1], 2, this.set_gooutfactory.formdata.companyid, 2)
                                                            "
                                                            :disabled="this.set_gooutfactory.formdata.shop_code[1] ? false : true"
                                                        >
                                                            <el-option
                                                                v-for="(item, index) in this.set_gooutfactory.shopman_option_two"
                                                                :key="index"
                                                                :label="item.name"
                                                                :value="item.id"
                                                            />
                                                        </el-select>
                                                    </div>
                                                </div>
                                            </el-form-item>
                                            <el-form-item label="投放方式：" prop="is_buy">
                                                <el-radio-group v-model="this.set_gooutfactory.formdata.is_buy">
                                                    <el-radio :label="3">租赁</el-radio>
                                                    <el-radio :label="2">用户自购</el-radio>
                                                    <el-radio :label="1">公司自投</el-radio>
                                                </el-radio-group>
                                            </el-form-item>
                                            <el-form-item label="投放时间：" prop="putintime">
                                                <el-date-picker v-model="this.set_gooutfactory.formdata.putintime" type="date" placeholder="请选择日期" />
                                            </el-form-item>
                                            <!--【临时任务-20230605-linshuai】资金方使用-投放时间使用putintimez字段表示，且非必填 -->
                                            <el-form-item label="投放时间-资金方：" >
                                                <el-date-picker v-model="this.set_gooutfactory.formdata.putintimez" type="date" placeholder="请选择日期" />
                                            </el-form-item>

                                            <el-form-item label="投放场景：" prop="scene" required="true">
                                                <el-select v-model="this.set_gooutfactory.formdata.scene" placeholder="请选择投放场景">
                                                    <el-option v-for="(item, index) in this.set_gooutfactory.scene_option" :key="index" :label="item.label" :value="item.value">
                                                    </el-option>
                                                </el-select>
                                            </el-form-item>
                                            <el-form-item label="设备地址：" :required="this.set_gooutfactory_rules.dvcaddr[0].required">
                                                <el-form-item label-width="0">
                                                    <el-radio-group v-model="this.set_gooutfactory.formdata.resource" @change="changeRadioEvent">
                                                        <el-radio :label="-1">手动填写地址</el-radio>
                                                        <el-radio :label="1">系统自动更新地址</el-radio>
                                                    </el-radio-group>
                                                </el-form-item>
                                                <el-form-item label-width="0" v-if="this.set_gooutfactory.formdata.resource == -1">
                                                    <!-- <el-form-item label-width="0" prop="dvcaddr" style="height:60px">
                            <mainselect
                              ref="citycode"
                              v-model="this.set_gooutfactory.formdata.dvcaddr"
                              :myselectcityleftisshow="myselectcityisshow"
                              :myselectcity="myselectcity"
                              v-on:getcitycode="getCityCodeEvent"
                            ></mainselect>
                            </el-form-item> -->
                                                    <el-form-item label-width="0" prop="dvcaddrdetail">
                                                        <el-input v-model="this.set_gooutfactory.formdata.dvcaddrdetail" placeholder="详细地址" clearable> </el-input>
                                                    </el-form-item>
                                                </el-form-item>
                                            </el-form-item>
                                            <!-- 暂时关闭-停用地图选择按钮 -->
                                            <!-- <el-form-item>
                      <div
                        style="
                          display: flex;
                          flex-direction: row;
                          flex-wrap: wrap;
                        "
                      >
                        <el-button
                          type="primary"
                          plain
                          size="small"
                          style="margin-right: 20px"
                          @click="
                            addTanClick(
                              this.set_dvcinfo,
                              'addrmap',
                              (this.set_dvcinfo.addr_map.dialogVisible = true)
                            )
                          "
                          >地图选址</el-button
                        >
                        <span style="margin-right: 20px; font-size: 13px">{{
                          this.set_dvcinfo.formdata.dvcaddr
                        }}</span>
                      </div>
                      </el-form-item> -->
                                            <el-form-item label="是否启用广告主：" prop="is_use_advertiser">
                                                <el-radio-group v-model="this.set_gooutfactory.formdata.is_use_advertiser">
                                                    <el-radio :label="2">禁用</el-radio>
                                                    <el-radio :label="1">启用</el-radio>
                                                </el-radio-group>
                                            </el-form-item>
                                            <el-form-item label="扫码广告："  :required="true">
                                                <el-radio-group v-model="this.set_gooutfactory.formdata.is_flow_cash" :disabled="true">
                                                    <el-radio :label="-1">禁用</el-radio>
                                                    <el-radio :label="1">启用</el-radio>
                                                </el-radio-group>
                                            </el-form-item>
                                            <el-form-item label="资金方：" prop="capital_person">
                                                <el-select v-model="this.set_gooutfactory.formdata.capital_person" multiple placeholder="请选择资金方">
                                                    <el-option
                                                        v-for="(item, index) in this.set_gooutfactory.capital_person"
                                                        :key="index"
                                                        :label="item.sort_key"
                                                        :value="item.sort_val"
                                                    >
                                                    </el-option>
                                                </el-select>
                                            </el-form-item>
                                            <el-form-item label="售后人员：" prop="people">
                                                <el-select v-model="this.set_gooutfactory.formdata.people">
                                                    <el-option
                                                        v-for="(item, index) in this.set_gooutfactory.aftersale_option"
                                                        :key="index"
                                                        :label="item.username"
                                                        :value="item.id"
                                                    />
                                                </el-select>
                                            </el-form-item>
                                            <el-form-item label="安装人员：">
                                                <el-input v-model="this.set_gooutfactory.formdata.personnel" clearable style="max-width: 222px"></el-input>
                                            </el-form-item>
                                            <el-form-item label="打印功能:">
                                                <template v-if="this.set_gooutfactory.printNameType == 2500">
                                                    <el-checkbox-group v-model="this.set_gooutfactory.formdata.facility">
                                                        <el-checkbox label="1" :disabled="this.set_gooutfactory.isnotcheck == 1" v-if="this.set_gooutfactory.isscan == 1"
                                                            >平稿台复印</el-checkbox
                                                        >
                                                        <el-checkbox label="2" :disabled="this.set_gooutfactory.isnotcheck == 1" v-if="this.set_gooutfactory.isscan == 1"
                                                            >ADF复印</el-checkbox
                                                        >
                                                        <el-checkbox label="3" >A4黑白</el-checkbox>
                                                        <el-checkbox label="5" >A3黑白</el-checkbox>
                                                    </el-checkbox-group>
                                                </template>
                                                <template v-else>
                                                    <el-checkbox-group v-model="this.set_gooutfactory.formdata.facility">
                                                        <el-checkbox label="0">照片打印</el-checkbox>
                                                        <el-checkbox label="1" :disabled="this.set_gooutfactory.isnotcheck == 1" v-if="this.set_gooutfactory.isscan == 1"
                                                            >平稿台复印</el-checkbox
                                                        >
                                                        <el-checkbox label="2" :disabled="this.set_gooutfactory.isnotcheck == 1" v-if="this.set_gooutfactory.isscan == 1"
                                                            >ADF复印</el-checkbox
                                                        >
                                                        <el-checkbox label="3" @change="(data) => facilityCheckboxA4(data, '3')">A4黑白</el-checkbox>
                                                        <el-checkbox label="4" @change="(data) => facilityCheckboxA4(data, '4')">A4彩色</el-checkbox>
                                                        <el-checkbox label="5" @change="facilityCheckboxA3" v-if="this.set_gooutfactory.a3support == 1">A3黑白</el-checkbox>
                                                        <el-checkbox label="6" @change="facilityCheckboxA3" v-if="this.set_gooutfactory.a3support == 1">A3彩色</el-checkbox>
                                                    </el-checkbox-group>
                                                </template>
                                            </el-form-item>
                                            <el-form-item label="备注：">
                                                <el-input v-model="this.set_gooutfactory.formdata.config" type="textarea"></el-input>
                                            </el-form-item>
                                            <el-form-item>
                                                <el-button
                                                    type="primary"
                                                    style="width: 150px; margin-right: 10px"
                                                    @click="gooutfactorySubmit('gooutfactory')"
                                                    :disabled="mydisabled && this.button_display == 1"
                                                    >保存
                                                </el-button>
                                                <el-button @click="cancelEvent()">取消</el-button>
                                            </el-form-item>
                                        </el-form>
                                    </el-col>
                                    <!-- 占位符 -->
                                    <el-col :span="24" :xs="24" :sm="24" :md="24" :lg="4" :xl="6">
                                        <div style="width: 100%"></div>
                                    </el-col>
                                </el-row>
                            </div>
                        </el-tab-pane>
                        <!-- 广告设置 -->
                        <el-tab-pane label="广告设置" name="three" v-if="mypowerlimits.jqkz_bjggsz && this.mytype != 'C2' && this.myver < 3">
                            <div style="width: 100%; margin-top: 10px">
                                <el-form ref="advertabledata" :model="this.set_advert" class="demo-ruleForm">
                                    <el-form-item label="批量设备广告投放展示该设备：">
                                        <div style="display: flex; flex-direction: row; flex-wrap: wrap;">
                                            <div style="margin-right: 30px">
                                                <el-radio-group v-model="this.set_advert.isshowdvc">
                                                    <el-radio :label="1">是</el-radio>
                                                    <el-radio :label="-1">否</el-radio>
                                                </el-radio-group>
                                            </div>
                                            <div>
                                                <span style="font-size: 13px">（3.0.0.0>版本号>=2.60.2.0）</span>
                                            </div>
                                        </div>
                                    </el-form-item>
                                    <el-form-item label="显示代理商广告：">
                                        <div class="myadvert_video">
                                            <div class="myadvert_video_button">
                                                <mainimgesup
                                                    ref="myadvertimges"
                                                    v-model="this.set_advert.agent_advert"
                                                    :myimgesup="agentimgesup"
                                                    :myimgesupisshow="agentimgesupisshow"
                                                    v-on:getuploadfile="(data) => getAdvertUploadFileEvent(data, 1)"
                                                    v-on:deluploadfile="(data) => delAdvertuploadfileEvent(data, 1)"
                                                    v-on:getfiletype="(data) => getfiletypeEvent(data, 1)"
                                                ></mainimgesup>
                                            </div>
                                            <div class="myadvert_video_loading" v-if="this.set_advert.videoloading">
                                                <span>正在飞速上传视频中，请耐心等待 <i class="el-icon-loading"></i></span>
                                            </div>
                                        </div>
                                        <!-- 代理商广告 -->
                                        <div>
                                            <el-table :data="set_advert.agent_advert" style="width: 100%">
                                                <el-table-column label="代理商广告（6条）">
                                                    <el-table-column prop="imageurl" label="文件资源" width="100px">
                                                        <template #default="scope">
                                                            <!-- 图片显示 -->
                                                            <div v-if="scope.row.files.file_type !== 'video/mp4'">
                                                                <el-image
                                                                    class="imagesshow"
                                                                    :src="scope.row.files.file_url"
                                                                    :preview-src-list="[scope.row.files.file_url]"
                                                                    fit="cover"
                                                                ></el-image>
                                                            </div>
                                                            <!-- 视频显示 -->
                                                            <div v-else>
                                                                <video
                                                                    :src="scope.row.files.file_url"
                                                                    controls
                                                                    width="80"
                                                                    poster="https://www.jingshuoprint.com/Static/ShopMange/static/h-ui.admin/images/video.png"
                                                                ></video>
                                                            </div>
                                                        </template>
                                                    </el-table-column>
                                                    <el-table-column prop="playlangtime" label="播放时长" min-width="100px">
                                                        <template #default="scope">
                                                            <div v-if="scope.row.status == '等待上传'">
                                                                {{ scope.row.playlangtime }}
                                                            </div>
                                                            <div v-else>
                                                                <div v-if="scope.row.files.file_type !== 'video/mp4'">
                                                                    <el-select v-model="scope.row.playlangtime" placeholder="">
                                                                        <el-option label="15s" value="15"></el-option>
                                                                        <el-option label="30s" value="30"></el-option>
                                                                    </el-select>
                                                                </div>
                                                                <div v-else>{{ scope.row.playlangtime }}s</div>
                                                            </div>
                                                        </template>
                                                    </el-table-column>
                                                    <el-table-column prop="status" label="状态">
                                                        <template #default="scope">
                                                            <div v-if="scope.row.status == 0">
                                                                <span style="color: #f56c6c">上传失败</span>
                                                            </div>
                                                            <div v-else-if="scope.row.status == -1">
                                                                <span style="color: #465eff">等待上传</span>
                                                            </div>
                                                            <div v-else-if="scope.row.status == 1">
                                                                <span style="color: #465eff">上传成功</span>
                                                            </div>
                                                            <div v-else>
                                                                <span>已播放</span>
                                                            </div>
                                                        </template>
                                                    </el-table-column>
                                                    <el-table-column prop="findmen" label="投放人">
                                                        <template #default="scope">
                                                            {{ scope.row.findmen }}
                                                        </template>
                                                    </el-table-column>
                                                    <el-table-column prop="findtime" label="投放时间" min-width="180px">
                                                        <template #default="scope">
                                                            <div v-if="scope.row.findtime == 0 || scope.row.findtime == ''">
                                                                <span>永久</span>
                                                            </div>
                                                            <div v-else>
                                                                {{ this.$utils.formatDate(scope.row.findtime) }}
                                                            </div>
                                                        </template>
                                                    </el-table-column>
                                                    <el-table-column prop="stoptime" label="到期时间" min-width="180px">
                                                        <template #default="scope">
                                                            <div v-if="scope.row.stoptime == 0 || scope.row.stoptime == ''">
                                                                <span>永久</span>
                                                            </div>
                                                            <div v-else>
                                                                {{ this.$utils.formatDate(scope.row.stoptime) }}
                                                            </div>
                                                        </template>
                                                    </el-table-column>
                                                    <!-- 操作 -->
                                                    <el-table-column prop="caozuo" label="操作">
                                                        <template #default="scope">
                                                            <el-button type="danger" size="small" @click="delAdvertEvent(scope.$index, this.set_advert.agent_advert, 1)"
                                                                >删除</el-button
                                                            >
                                                        </template>
                                                    </el-table-column>
                                                </el-table-column>
                                            </el-table>
                                        </div>
                                    </el-form-item>
                                    <el-form-item label="公司及用户广告：">
                                        <div class="myadvert_video">
                                            <div class="myadvert_video_button">
                                                <mainimgesup
                                                    ref="myadvertimges"
                                                    v-model="this.set_advert.company_users_advert"
                                                    :myimgesup="agentimgesup2"
                                                    :myimgesupisshow="agentimgesupisshow"
                                                    v-on:getuploadfile="(data) => getAdvertUploadFileEvent(data, 2)"
                                                    v-on:deluploadfile="(data) => delAdvertuploadfileEvent(data, 2)"
                                                    v-on:getfiletype="(data) => getfiletypeEvent(data, 2)"
                                                ></mainimgesup>
                                            </div>
                                            <div class="myadvert_video_loading" v-if="this.set_advert.videoloading">
                                                <span>正在飞速上传视频中，请耐心等待 <i class="el-icon-loading"></i></span>
                                            </div>
                                        </div>
                                        <!-- 公司及用户广告 -->
                                        <div style="margin-top: 10px">
                                            <el-table :data="set_advert.company_users_advert" style="width: 100%">
                                                <el-table-column label="公司及用户广告（10条）">
                                                    <el-table-column prop="imageurl" label="文件资源" width="100px">
                                                        <template #default="scope">
                                                            <!-- 图片显示 -->
                                                            <div v-if="scope.row.files.file_type !== 'video/mp4'">
                                                                <el-image
                                                                    class="imagesshow"
                                                                    :src="scope.row.files.file_url"
                                                                    :preview-src-list="[scope.row.files.file_url]"
                                                                    fit="cover"
                                                                ></el-image>
                                                            </div>
                                                            <!-- 视频显示 -->
                                                            <div v-else>
                                                                <video
                                                                    :src="scope.row.files.file_url"
                                                                    controls
                                                                    width="80"
                                                                    poster="https://www.jingshuoprint.com/Static/ShopMange/static/h-ui.admin/images/video.png"
                                                                ></video>
                                                            </div>
                                                        </template>
                                                    </el-table-column>
                                                    <el-table-column prop="playlangtime" label="播放时长" min-width="100px">
                                                        <template #default="scope">
                                                            <div v-if="scope.row.status == -1">
                                                                {{ scope.row.playlangtime }}
                                                            </div>
                                                            <div v-else>
                                                                <div v-if="scope.row.files.file_type !== 'video/mp4'">
                                                                    <el-select v-model="scope.row.playlangtime" placeholder="">
                                                                        <el-option label="15s" value="15"></el-option>
                                                                        <el-option label="30s" value="30"></el-option>
                                                                    </el-select>
                                                                </div>
                                                                <div v-else>{{ scope.row.playlangtime }}s</div>
                                                            </div>
                                                        </template>
                                                    </el-table-column>
                                                    <el-table-column prop="status" label="状态">
                                                        <template #default="scope">
                                                            <div v-if="scope.row.status == 1">
                                                                <span>已上传</span>
                                                            </div>
                                                            <div v-else>
                                                                <span>已播放</span>
                                                            </div>
                                                        </template>
                                                    </el-table-column>
                                                    <el-table-column prop="findmen" label="投放人">
                                                        <template #default="scope">
                                                            {{ scope.row.findmen }}
                                                        </template>
                                                        <!-- <template #default="scope">
                              <div v-if="scope.row.is_default == 1">
                                <span>系统默认</span>
                              </div>
                              <div v-else>
                                {{ scope.row.findmen }}
                              </div>
                            </template> -->
                                                    </el-table-column>
                                                    <el-table-column prop="findtime" label="投放时间" min-width="180px">
                                                        <template #default="scope">
                                                            <div v-if="scope.row.is_default == 1 || scope.row.findtime == 0 || scope.row.findtime == ''">
                                                                <span>永久</span>
                                                            </div>
                                                            <div v-else>
                                                                {{ this.$utils.formatDate(scope.row.findtime) }}
                                                            </div>
                                                        </template>
                                                    </el-table-column>
                                                    <el-table-column prop="stoptime" label="到期时间" min-width="180px">
                                                        <template #default="scope">
                                                            <div v-if="scope.row.is_default == 1 || scope.row.stoptime == 0 || scope.row.stoptime == ''">
                                                                <span>永久</span>
                                                            </div>
                                                            <div v-else>
                                                                {{ this.$utils.formatDate(scope.row.stoptime) }}
                                                            </div>
                                                        </template>
                                                    </el-table-column>
                                                    <!-- 操作 -->
                                                    <el-table-column prop="caozuo" label="操作">
                                                        <template #default="scope">
                                                            <div v-if="scope.row.is_default == 1">
                                                                <el-button type="danger" size="small" @click="delAdvertEvent(scope.$index, this.set_advert.company_users_advert, 2)"
                                                                    >删除</el-button
                                                                >
                                                            </div>
                                                        </template>
                                                    </el-table-column>
                                                </el-table-column>
                                            </el-table>
                                        </div>
                                    </el-form-item>
                                    <el-form-item style="text-align: center">
                                        <el-button
                                            type="primary"
                                            @click="advertSubmit('advertabledata')"
                                            style="width: 150px; margin-right: 10px"
                                            :disabled="mydisabled && this.button_display == 1"
                                            >保存</el-button
                                        >
                                        <el-button @click="cancelEvent()">取消</el-button>
                                    </el-form-item>
                                </el-form>
                            </div>
                        </el-tab-pane>
                        <!-- 打印机配置项 -->
                        <el-tab-pane label="打印机配置项" name="sex" v-if="mypowerlimits.jqkz_bjdyjpzx && this.mytype != 'C2'">
                            <div style="width: 100%; margin-top: 10px">
                                <el-row>
                                    <!-- 占位符 -->
                                    <el-col :span="24" :xs="24" :sm="24" :md="24" :lg="2" :xl="3">
                                        <div style="width: 100%"></div>
                                    </el-col>
                                    <!-- 内容 -->
                                    <el-col :span="24" :xs="24" :sm="24" :md="24" :lg="15" :xl="15">
                                        <el-form ref="printdvcformdata" :model="this.set_printdvc.formdata" class="demo-ruleForm" label-width="auto" :label-position="right">
                                            <!-- <el-form-item label="Word打印方式：">
                        <el-radio-group
                          v-model="this.set_printdvc.formdata.source"
                        >
                          <el-radio :label="1">SDK</el-radio>
                          <el-radio :label="2">OFFICE</el-radio>
                          <el-radio :label="3">WPS</el-radio>
                        </el-radio-group>
                      </el-form-item>
                      <el-form-item label="PDF打印方式：">
                        <el-radio-group
                          v-model="this.set_printdvc.formdata.pdftype"
                        >
                          <el-radio :label="1">SDK</el-radio>
                          <el-radio :label="2">PDF_TRON</el-radio>
                        </el-radio-group>
                      </el-form-item>
                      <el-form-item label="前置纸器放置纸张类型：">
                        <el-radio-group
                          v-model="this.set_printdvc.formdata.beforepaper"
                        >
                          <el-radio :label="1">照片纸</el-radio>
                          <el-radio :label="2">A4纸</el-radio>
                        </el-radio-group>
                      </el-form-item> -->
                                            <el-form-item label="打印机类型：" v-if="this.set_printdvc.formdata.printname != ''">
                                                <el-input v-model="this.set_printdvc.formdata.printname" clearable disabled style="max-width: 222px"></el-input>
                                                <!-- <el-select
                          v-model="this.set_printdvc.formdata.printname"
                          placeholder="请选择打印机类型"
                          width="100%"
                        >
                          <el-option
                            v-for="(item, index) in this.set_printdvc
                              .dvctype_option"
                            :key="index"
                            :label="item"
                            :value="item"
                          />
                        </el-select> -->
                                            </el-form-item>
                                            <el-form-item label="A4打印配置：" v-if="this.set_printdvc.formdata.printname != ''">
                                                <div class="print_box">
                                                    <div class="print_box_info">
                                                        <el-select v-model="this.set_printdvc.formdata.paperfeeder0" placeholder="纸盒" width="100%">
                                                            <el-option
                                                                v-for="(item, index) in this.set_printdvc.print_option[0]"
                                                                :key="index"
                                                                :label="item.label"
                                                                :value="item.value"
                                                            />
                                                        </el-select>
                                                    </div>
                                                    <div class="print_box_info">
                                                        <el-select v-model="this.set_printdvc.formdata.papertype0" placeholder="普通纸" width="100%">
                                                            <el-option
                                                                v-for="(item, index) in this.set_printdvc.print_option[1]"
                                                                :key="index"
                                                                :label="item.label"
                                                                :value="item.value"
                                                            />
                                                        </el-select>
                                                    </div>
                                                    <div class="print_box_info">
                                                        <el-select v-model="this.set_printdvc.formdata.papersize0" placeholder="A4" width="100%">
                                                            <el-option
                                                                v-for="(item, index) in this.set_printdvc.print_option[2]"
                                                                :key="index"
                                                                :label="item.label"
                                                                :value="item.value"
                                                            />
                                                        </el-select>
                                                    </div>
                                                    <div class="print_box_info">
                                                        <el-select v-model="this.set_printdvc.formdata.paperquality0" placeholder="标准" width="100%">
                                                            <el-option
                                                                v-for="(item, index) in this.set_printdvc.print_option[3]"
                                                                :key="index"
                                                                :label="item.label"
                                                                :value="item.value"
                                                            />
                                                        </el-select>
                                                    </div>
                                                </div>
                                            </el-form-item>
                                            <el-form-item label="6寸打印配置：" v-if="this.set_printdvc.formdata.printname != ''">
                                                <div class="print_box">
                                                    <div class="print_box_info">
                                                        <el-select v-model="this.set_printdvc.formdata.paperfeeder1" placeholder="纸盒" width="100%">
                                                            <el-option
                                                                v-for="(item, index) in this.set_printdvc.print_option[0]"
                                                                :key="index"
                                                                :label="item.label"
                                                                :value="item.value"
                                                            />
                                                        </el-select>
                                                    </div>
                                                    <div class="print_box_info">
                                                        <el-select v-model="this.set_printdvc.formdata.papertype1" placeholder="普通纸" width="100%">
                                                            <el-option
                                                                v-for="(item, index) in this.set_printdvc.print_option[1]"
                                                                :key="index"
                                                                :label="item.label"
                                                                :value="item.value"
                                                            />
                                                        </el-select>
                                                    </div>
                                                    <div class="print_box_info">
                                                        <el-select v-model="this.set_printdvc.formdata.papersize1" placeholder="A4" width="100%">
                                                            <el-option
                                                                v-for="(item, index) in this.set_printdvc.print_option[2]"
                                                                :key="index"
                                                                :label="item.label"
                                                                :value="item.value"
                                                            />
                                                        </el-select>
                                                    </div>
                                                    <div class="print_box_info">
                                                        <el-select v-model="this.set_printdvc.formdata.paperquality1" placeholder="标准" width="100%">
                                                            <el-option
                                                                v-for="(item, index) in this.set_printdvc.print_option[3]"
                                                                :key="index"
                                                                :label="item.label"
                                                                :value="item.value"
                                                            />
                                                        </el-select>
                                                    </div>
                                                </div>
                                            </el-form-item>
                                            <el-form-item label="墨量信息：" v-if="this.set_printdvc.formdata.iscolorlevel == 1">
                                                <div>
                                                    <el-table :data="this.set_printdvc.formdata.colorlevel" border style="width: 400px">
                                                        <el-table-column prop="colorname" label="颜色" />
                                                        <el-table-column prop="allowance" label="余量" />
                                                    </el-table>
                                                </div>
                                            </el-form-item>
                                            <el-form-item label="设备序列号：">
                                                <el-input v-model="this.set_printdvc.formdata.serial_number" clearable style="max-width: 222px"></el-input>
                                            </el-form-item>
                                            <el-form-item label="设备密码：">
                                                <el-input v-model="this.set_printdvc.formdata.password" clearable style="max-width: 222px"></el-input>
                                            </el-form-item>
                                            <!-- 根据20230830需求将button的判断去除 v-if="this.set_printdvc.formdata.printname != ''" -->
                                            <el-form-item >
                                                <el-button
                                                    type="primary"
                                                    style="width: 150px; margin-right: 10px"
                                                    @click="printdvcSubmit('printdvcformdata')"
                                                    :disabled="mydisabled && this.button_display == 1"
                                                    >保存
                                                </el-button>
                                                <el-button @click="cancelEvent()">取消</el-button>
                                            </el-form-item>
                                        </el-form>
                                    </el-col>
                                    <!-- 占位符 -->
                                    <el-col :span="24" :xs="24" :sm="24" :md="24" :lg="4" :xl="6">
                                        <div style="width: 100%"></div>
                                    </el-col>
                                </el-row>
                            </div>
                        </el-tab-pane>
                        <!-- 推送及报警配置 -->
                        <el-tab-pane label="推送及报警配置" name="five" v-if="mypowerlimits.jqkz_bjtsjbjpz">
                            <div style="width: 100%; margin-top: 10px">
                                <el-row>
                                    <!-- 占位符 -->
                                    <el-col :span="24" :xs="24" :sm="24" :md="24" :lg="2" :xl="3">
                                        <div style="width: 100%"></div>
                                    </el-col>
                                    <!-- 内容 -->
                                    <el-col :span="24" :xs="24" :sm="24" :md="24" :lg="15" :xl="15">
                                        <el-form ref="alramformdata" :model="this.set_pushalarm.formdata" class="demo-ruleForm" label-width="150px">
                                            <!-- 打印异常推送微信 -->
                                            <el-form-item label="打印异常推送微信：">
                                                <mainwechatmore :uniondata="this.set_pushalarm.formdata.avatar" v-on:getuniondata="getuniondataEvent"></mainwechatmore>
                                            </el-form-item>
                                            <!-- 推送间隔 -->
                                            <el-form-item label="推送间隔：">
                                                <div style="display: flex; flex-direction: column">
                                                    <el-input v-model="this.set_pushalarm.formdata.times" placeholder="" style="max-width: 300px" clearable>
                                                        <template #append>分钟</template>
                                                    </el-input>
                                                    <span style="font-size: 12px; color: #999999">( 不填则只提示一次 )</span>
                                                </div>
                                            </el-form-item>
                                            <el-form-item>
                                                <el-button
                                                    type="primary"
                                                    style="width: 150px; margin-right: 10px"
                                                    @click="savealramSubmit('alramformdata')"
                                                    :disabled="mydisabled && this.button_display == 1"
                                                    >保存
                                                </el-button>
                                                <el-button @click="cancelEvent()">取消</el-button>
                                            </el-form-item>
                                        </el-form>
                                    </el-col>
                                    <!-- 占位符 -->
                                    <el-col :span="24" :xs="24" :sm="24" :md="24" :lg="4" :xl="6">
                                        <div style="width: 100%"></div>
                                    </el-col>
                                </el-row>
                            </div>
                        </el-tab-pane>
                        <!-- 价格设置 -->
                        <el-tab-pane label="价格设置项" name="two" v-if="mypowerlimits.jqkz_bjjgszx">
                            <div style="width: 100%; margin-top: 10px" id="price_css">
                                <el-form ref="pricetabledata" :model="this.set_price" class="demo-ruleForm" label-width="140px">
                                    <el-form-item label="价格设置：">
                                        <div style="margin-bottom: 10px" v-if="mypowerlimits.jqkz_hfccjg">
                                            <el-button type="warning" plain size="small" @click="recoveryPriceEvent(this.set_price.id)">恢复出厂价格</el-button>
                                        </div>
                                        <div>
                                            <el-table :data="this.set_price.formdata" style="width: 100%" border>
                                                <el-table-column prop="medium" label="打印介质" fixed="left" width="120px">
                                                    <template #default="scope">
                                                        {{ scope.row.medium }}
                                                    </template>
                                                </el-table-column>
                                                <el-table-column prop="price_range" label="价格区间" width="320px">
                                                    <template #default="scope">
                                                        最低
                                                        <el-input v-model="scope.row.price_range.low" placeholder="" style="width: 100px" clearable></el-input>
                                                        ~
                                                        <el-input v-model="scope.row.price_range.height" placeholder="" style="width: 100px" clearable></el-input>
                                                        最高
                                                    </template>
                                                </el-table-column>
                                                <el-table-column
                                                    v-for="(mytabletitle, index) in this.set_price.titledata"
                                                    :key="index"
                                                    :prop="mytabletitle.prop"
                                                    :label="mytabletitle.label"
                                                    :width="mytabletitle.width"
                                                >
                                                    <template #default="scope">
                                                        <div v-for="(childrendata, ide) in scope.row.children" :key="ide">
                                                            <div v-if="mytabletitle.prop === 'page_range'">
                                                                <div style="margin: 5px 0">
                                                                    >=
                                                                    <el-input
                                                                        style="width: 100px"
                                                                        v-model="childrendata.page_range"
                                                                        placeholder=""
                                                                        clearable
                                                                        :disabled="childrendata.addisshow ? true : false"
                                                                    ></el-input>
                                                                    页
                                                                </div>
                                                            </div>
                                                            <div v-if="mytabletitle.prop === 'bw_price_along'">
                                                                <div style="margin: 5px 0">
                                                                    <el-input
                                                                        v-if="scope.row.ide != 2 && scope.row.ide != 3 && scope.row.ide != 4"
                                                                        v-model="childrendata.bw_price_along"
                                                                        placeholder=""
                                                                        clearable
                                                                    ></el-input>
                                                                </div>
                                                            </div>
                                                            <div v-if="mytabletitle.prop === 'bw_price_double'">
                                                                <div style="margin: 5px 0">
                                                                    <el-input
                                                                        v-if="
                                                                            scope.row.ide != 2 &&
                                                                                scope.row.ide != 3 &&
                                                                                scope.row.ide != 4 &&
                                                                                scope.row.ide != 5 &&
                                                                                scope.row.ide != 6 &&
                                                                                scope.row.ide != 7
                                                                        "
                                                                        v-model="childrendata.bw_price_double"
                                                                        placeholder=""
                                                                        clearable
                                                                    ></el-input>
                                                                </div>
                                                            </div>
                                                            <div v-if="mytabletitle.prop === 'colour_price_along'">
                                                                <div style="margin: 5px 0">
                                                                    <el-input v-model="childrendata.colour_price_along" placeholder="" clearable></el-input>
                                                                </div>
                                                            </div>
                                                            <div v-if="mytabletitle.prop === 'colour_price_double'">
                                                                <div style="margin: 5px 0">
                                                                    <el-input
                                                                        v-if="
                                                                            scope.row.ide != 2 &&
                                                                                scope.row.ide != 3 &&
                                                                                scope.row.ide != 4 &&
                                                                                scope.row.ide != 5 &&
                                                                                scope.row.ide != 6 &&
                                                                                scope.row.ide != 7
                                                                        "
                                                                        v-model="childrendata.colour_price_double"
                                                                        placeholder=""
                                                                        clearable
                                                                    ></el-input>
                                                                </div>
                                                            </div>
                                                            <div v-if="mytabletitle.prop === 'caozuo'">
                                                                <div style="margin: 5px 0">
                                                                    <el-button type="text" v-if="childrendata.addisshow" @click="addPriceEvent(scope.$index, scope.row.children, 5)"
                                                                        >增加</el-button
                                                                    >
                                                                    <el-button type="text" v-if="!childrendata.addisshow" @click="delPriceEvent(scope.$index, ide)">删除</el-button>
                                                                    <!-- @click="delPriceEvent(scope.$index,scope.row.children)" -->
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </template>
                                                </el-table-column>
                                            </el-table>
                                        </div>
                                    </el-form-item>
                                    <el-form-item label="系统外：">
                                        <el-radio-group v-model="this.set_price.charge">
                                            <el-radio :label="1">收费</el-radio>
                                            <el-radio :label="-1">不收费</el-radio>
                                        </el-radio-group>
                                    </el-form-item>
                                    <el-form-item label="支持的支付方式：">
                                        <el-checkbox-group v-model="this.set_price.paytype">
                                            <el-checkbox label="2" border>微信支付</el-checkbox>
                                            <el-checkbox label="1" border>余额支付</el-checkbox>
                                            <el-checkbox label="5" border>商家代付</el-checkbox>
                                            <el-checkbox label="3" border>支付宝支付</el-checkbox>
                                        </el-checkbox-group>
                                    </el-form-item>
                                    <el-form-item label="设备会员：">
                                        <el-radio-group v-model="this.set_price.dvcplus">
                                            <el-radio :label="1">启用</el-radio>
                                            <el-radio :label="0">禁用</el-radio>
                                        </el-radio-group>
                                    </el-form-item>
                                    <el-form-item style="text-align: center">
                                        <el-button
                                            type="primary"
                                            @click="priceSubmit('pricetabledata')"
                                            style="width: 150px; margin-right: 10px"
                                            :disabled="mydisabled && this.button_display == 1"
                                            >保存</el-button
                                        >
                                        <el-button @click="cancelEvent()">取消</el-button>
                                    </el-form-item>
                                </el-form>
                            </div>
                        </el-tab-pane>
                        <!-- 出厂退货记录 -->
                        <el-tab-pane label="出厂退货记录" name="seven" v-if="mypowerlimits.jqkz_ccthjl">
                            <div>
                                <!--table表单-->
                                <el-table
                                    ref="multipleTable"
                                    :row-class-name="tableRowClassName"
                                    :data="this.set_gooutgoods.mainthreetableData"
                                    style="width: 100%"
                                    min-height="900"
                                    border
                                    v-loading="loading"
                                    element-loading-text="正在飞速加载中..."
                                    @selection-change="handleSelectionChange"
                                >
                                    <el-table-column
                                        v-for="(mytabletitle, i) in this.set_gooutgoods.mainthreetabletitle"
                                        :key="i"
                                        :fixed="mytabletitle.fixedstatu"
                                        :prop="mytabletitle.prop"
                                        :label="mytabletitle.label"
                                        :width="mytabletitle.width"
                                    >
                                    </el-table-column>
                                </el-table>
                            </div>
                        </el-tab-pane>
                        <!-- 操作记录 -->
                        <el-tab-pane label="操作记录" name="eight" v-if="mypowerlimits.jqkz_czjl">
                            <div>
                                <!--table表单-->
                                <el-table
                                    ref="multipleTable1"
                                    :row-class-name="tableRowClassName"
                                    :data="this.set_handle.mainthreetableData"
                                    style="width: 100%"
                                    min-height="900"
                                    border
                                    v-loading="loading"
                                    element-loading-text="正在飞速加载中..."
                                    @selection-change="handleSelectionChange"
                                >
                                    <el-table-column
                                        v-for="(mytabletitle, i) in this.set_handle.mainthreetabletitle"
                                        :key="i"
                                        :fixed="mytabletitle.fixedstatu"
                                        :prop="mytabletitle.prop"
                                        :label="mytabletitle.label"
                                        :width="mytabletitle.width"
                                    >
                                    </el-table-column>
                                </el-table>
                            </div>
                        </el-tab-pane>
                    </el-tabs>
                </div>
            </div>
        </div>

        <!--弹出框-[设备详情]-设备地址选择-地图-->
        <el-dialog
            v-model="this.set_gooutfactory.addr_map.dialogVisible"
            title="选择设备地址"
            :width="this.set_gooutfactory.addr_map.searchwidth"
            :before-close="handleCloseSenior"
        >
            <div class="dialog-box-flex">
                <div id="container" class="map"></div>
            </div>
        </el-dialog>

        <!--弹出框-[设备详情]-查看二维码-->
        <el-dialog v-model="this.set_dvcinfo.look_qrcode.dialogVisible" title="查看二维码" :width="this.set_dvcinfo.look_qrcode.searchwidth" :before-close="handleCloseSenior">
            <div class="dialog-box-flex">
                <div v-if="this.set_dvcinfo.look_qrcode.downcodeurl == ''" style="text-align: center">
                    <i class="el-icon-loading"></i>
                </div>
                <img :src="this.set_dvcinfo.look_qrcode.downcodeurl" alt="" style="width: 100%; margin-top: 10px; border: 1px solid #ececec" />
            </div>
            <div style="margin-top: 20px">
                <el-button type="primary" style="width: 100%" @click="addTanClick(this.set_dvcinfo, 'downcode', 'refresh')">刷新二维码</el-button>
            </div>
        </el-dialog>

        <!--弹出框-[设备详情]-查看宣传码-->
        <el-dialog v-model="this.set_dvcinfo.look_conduct.dialogVisible" title="查看宣传码" :width="this.set_dvcinfo.look_conduct.searchwidth" :before-close="handleCloseSenior">
            <div class="dialog-box-flex">
                <div v-if="this.set_dvcinfo.look_conduct.downcodeurl == ''" style="text-align: center">
                    <i class="el-icon-loading"></i>
                </div>
                <img :src="this.set_dvcinfo.look_conduct.downcodeurl" alt="" style="width: 100%; margin-top: 10px; border: 1px solid #ececec" />
            </div>
        </el-dialog>
    </div>
</template>

<script>
import mainone from '@/components/Mainone'
// import mainselect from "@/components/Mainselect.vue";
import mainwechatmore from '@/components/Mainwechatmore'
import mainimgesup from '@/components/Mainimgesup.vue'
import AMapLoader from '@amap/amap-jsapi-loader'
import { defineComponent, ref } from 'vue'
import { ElMessage } from 'element-plus'
import axios from 'axios'

export default defineComponent({
    name: 'vsharedvccontroledit',
    inject: ['reload'], //刷新引用
    data() {
        // 联系方式-校验
        var checkPhone = (rule, value, callback) => {
            // console.log(rule, value);
            // if (!value) {
            //   return callback(new Error("联系方式不能为空"));
            // }
            if (!value) {
                callback()
            } else {
                setTimeout(() => {
                    // 判断字符串是否存在小数点
                    let result = value.toString().indexOf('.')
                    if (result != -1) {
                        callback(new Error('请输入数字'))
                    } else {
                        // 判断字符串是否为文字
                        if (isNaN(value)) {
                            callback(new Error('请输入数字'))
                        } else {
                            // 判断字符串是否为数字
                            if (!Number.parseInt(value)) {
                                callback(new Error('请输入数字'))
                            } else {
                                callback()
                            }
                        }
                    }
                }, 500)
            }
        }
        // 文本input框-校验（文本-值中不能有空格）【更新程序包||】
        var checkInputEventWord = (rule, value, callback) => {
            if (!value) {
                return callback(new Error('详细地址不能为空'))
            }
            if (value.toString().indexOf(' ') != -1) {
                return callback(new Error('详细地址中不能有空格'))
            }
            setTimeout(() => {
                callback()
            }, 500)
        }
        return {
            mainonebread: [
                {
                    name: '打印'
                },
                {
                    name: '共享打印'
                },
                {
                    name: '机器控制',
                    path: '/sharedvccontrol'
                },
                {
                    name: '编辑设备'
                    // path: "/sharedvccontroledit",
                }
            ], // 当前页-【面包屑导航】
            activeName: 'one', //tabs标签-默认显示页

            runtype: 2, // 特殊判断-路由中是否携带runtype，默认为2 ！！！
            mytype: '', // 特殊判断-当type为C2时，不显示【广告设置】与【打印机配置项】
            myver: '', // 特殊判断-当ver为大于3.0.0.0时，不显示【广告设置】

            //上传-【设备详情】-imgesuploade上传图片
            myimgesupisshow: true,
            //上传-【设备详情】-店铺门头-图片
            myimgesup: {
                uploadsize: 1, //选择上传图片的展现框-1表示小框，2表示中等框，3表示大框
                listtype: 'picture', //list展示类型，text/picture/picture-card；默认不填为text
                ismust: true, //是否必填，true表示必填
                action: '/api/admin/upload/openFile', //upload上传API地址
                headers: { Auth: localStorage.getItem('token') }, //upload上传headers
                title: '上传门头图片', //button-按钮文字
                title_type: 'primary', //button-按钮显示样式,primary&text
                title_plain: true, //button-按钮是否为线性（plain）
                tips: '', //提示&建议文字 建议尺寸46px*46px
                limit: '1', //上传数量限制
                target_id: 100, //上传文件target_id ：图片分类，区分存储文件和存储时长，不传默认存储1天后过期；1-二维码； 2-打印文件； 3-文库文件； 4-课程文件； 5-广告主宣传页； 6-广告主产生的pdf文件； 7-证件照文件； 50-设备广告； 100-通用；
                rules: {
                    images: {
                        image_width: 0, //图片宽度
                        image_height: 0, //图片高度
                        size: 10000, //文件大小（单位kb）
                        type: ['image/jpeg', 'image/jpg', 'image/gif', 'image/bmp', 'image/png'] //文件格式
                    }
                }
            },
            //上传-【设备详情】-设备图片-图片
            myimgesup2: {
                uploadsize: 1, //选择上传图片的展现框-1表示小框，2表示中等框，3表示大框
                listtype: 'picture', //list展示类型，text/picture/picture-card；默认不填为text
                ismust: true, //是否必填，true表示必填
                action: '/api/admin/upload/openFile', //upload上传API地址
                headers: { Auth: localStorage.getItem('token') }, //upload上传headers
                title: '上传设备图片', //button-按钮文字
                title_type: 'primary', //button-按钮显示样式,primary&text
                title_plain: true, //button-按钮是否为线性（plain）
                tips: '', //提示&建议文字 建议尺寸690px*440px
                limit: '1', //上传数量限制
                target_id: 100, //上传文件target_id ：图片分类，区分存储文件和存储时长，不传默认存储1天后过期；1-二维码； 2-打印文件； 3-文库文件； 4-课程文件； 5-广告主宣传页； 6-广告主产生的pdf文件； 7-证件照文件； 50-设备广告； 100-通用；
                rules: {
                    images: {
                        image_width: 0, //图片宽度
                        image_height: 0, //图片高度
                        size: 10000, //文件大小（单位kb）
                        type: ['image/jpeg', 'image/jpg', 'image/gif', 'image/bmp', 'image/png'] //文件格式
                    }
                }
            },

            // 设备详情
            set_dvcinfo: {
                // 【设备详情】formdata提交数据
                formdata: {
                    dvcnum: '',
                    id: '',
                    serialnumber: '',
                    teamviewer: '',
                    vernumber: '',
                    status: {
                        printstatus: 0,
                        is_online: 1
                    },
                    shopname: '',
                    shopimage: '',
                    shopimage_list: [],
                    dvcimage: '',
                    dvcimage_list: [],
                    mobile: ''
                },

                // 【设备详情】- 查看二维码-弹出框
                look_qrcode: {
                    dialogVisible: ref(false), //弹窗显示&隐藏
                    searchwidth: '380px',
                    downcodeurl: '' // 下载二维码-当前行信息
                },
                // 【设备详情】- 查看宣传码-弹出框
                look_conduct: {
                    dialogVisible: ref(false), //弹窗显示&隐藏
                    searchwidth: '380px',
                    downcodeurl: '' // 下载二维码-当前行信息
                }
            },
            // 设备详情-校验
            set_dvcinfo_rules: {
                shopname: [
                    {
                        required: true,
                        message: '店铺名称为必填项',
                        trigger: 'blur'
                    }
                ],
                shopimage: [
                    {
                        required: false,
                        message: '店铺门头不能为空',
                        trigger: 'change'
                    }
                ],
                dvcimage: [
                    {
                        required: false,
                        message: '设备图片不能为空',
                        trigger: 'change'
                    }
                ],
                mobile: [
                    {
                        validator: checkPhone,
                        trigger: 'change'
                    }
                ]
            },

            // 出厂安装设置
            set_gooutfactory: {
                // 【出厂安装设置】formdata提交数据
                formdata: {
                    id: '',
                    companyid: '',
                    shop_code: [],
                    is_buy: '',
                    putintime: '',
                    putintimez: '', //【临时任务-20230605-linshuai】资金方使用-投放时间使用putintimez字段表示，且非必填
                    scene: '1',
                    resource: '1',
                    // lnglat: {},
                    // dvcaddr: "", // 选中地址
                    dvcaddrdetail: '', // 详细地址
                    // flower: "",
                    is_use_advertiser: '',
                    is_flow_cash:'',
                    capital_person: [], //资金方
                    people: '',
                    personnel: '',
                    facility: [0, 1, 2, 3, 4],
                    config: '备注1'
                },
                // 【出厂安装设置】投放场景-下拉选项
                scene_option: [
                    {
                        value: '1',
                        label: '校园文具店'
                    },
                    {
                        value: '2',
                        label: '社区便利店'
                    },
                    {
                        value: '3',
                        label: '风景游览区'
                    },
                    {
                        value: '4',
                        label: '菜鸟驿站'
                    },
                    {
                        value: '5',
                        label: '照相馆'
                    },
                    {
                        value: '6',
                        label: '图文店'
                    },
                    {
                        value: '7',
                        label: '大中小校园'
                    },
                    {
                        value: '8',
                        label: '便民中心'
                    },
                    {
                        value: '9',
                        label: '办事大厅'
                    },
                    {
                        value: '10',
                        label: '培训机构'
                    },
                    {
                        value: '11',
                        label: '酒店'
                    },
                    {
                        value: '12',
                        label: '其他'
                    }
                ],
                // 【出厂安装设置】- 设备地址-地图
                addr_map: {
                    dialogVisible: ref(false), //弹窗显示&隐藏
                    searchwidth: '1020px'
                },
                // 【出厂安装设置】控制-打印功能-A3的1显示&0隐藏
                a3support: 0,
                // 【出厂安装设置】控制-打印功能-平稿台复印&ADF复印 0隐藏 1显示
                isscan: 0,
                // 【出厂安装设置】控制-打印功能-平稿台复印&ADF复印 0可选 1 禁止选择
                isnotcheck: 0,
                // 【出厂安装设置】公司-自动搜索-获取的数据
                company_option: [],
                // 【出厂安装设置】代理商-自动搜索-获取的数据
                agent_option: [],
                // 【出厂安装设置】商户-自动搜索-获取的数据
                shopman_option_one: [],
                shopman_option_two: [],
                // 【出厂安装设置】资金方-下拉选项
                capital_person: [],
                // 【出厂安装设置】售后人员-下拉选项
                aftersale_option: [],
                // 【出厂安装设置】打印功能-多选条件
                print_change: [],
                // 【出厂安装设置】公司or代理商禁用
                set_disabled_fzr: {},
                is_disabled_agent: false,
                // 出厂安装设置】打印功能-2500特殊判断
                printNameType: -1,
            },
            // 出厂安装设置-校验
            set_gooutfactory_rules: {
                companyid: [
                    {
                        required: true,
                        message: '公司为必填项',
                        trigger: 'blur'
                    }
                ],
                shop_code: [
                    {
                        required: true,
                        message: '代理商为必填项',
                        trigger: 'blur'
                    }
                ],
                is_buy: [
                    {
                        required: true,
                        message: '投放方式为必选项',
                        trigger: 'blur'
                    }
                ],
                putintime: [
                    {
                        required: true,
                        message: '投放时间为必选项',
                        trigger: 'blur'
                    }
                ],
                scene: [
                    {
                        required: true,
                        message: '投放场景不能为空',
                        trigger: 'change'
                    }
                ],
                dvcaddr: [
                    {
                        required: true,
                        message: '设备地址不能为空',
                        trigger: 'blur'
                    }
                ],
                dvcaddrdetail: [
                    {
                        required: true,
                        message: '详细地址不能为空',
                        trigger: 'blur'
                    },
                    {
                        validator: checkInputEventWord,
                        trigger: 'change'
                    }
                ],
                // flower: [
                //   {
                //     required: true,
                //     message: "流量变现为必选项",
                //     trigger: "blur",
                //   },
                // ],
                is_use_advertiser: [
                    {
                        required: true,
                        message: '广告主为必选项',
                        trigger: 'blur'
                    }
                ],
                // capital_person: [
                //   {
                //     required: true,
                //     message: "资金方为必选项",
                //     trigger: "blur",
                //   },
                // ],
                people: [
                    {
                        required: true,
                        message: '售后人员为必选项',
                        trigger: 'blur'
                    }
                ]
            },
            // 出厂安装设置-form表单-selectcity选框
            myselectcityisshow: true,
            myselectcity: {
                selectwidth: '100%',
                maxwidth: '100%',
                marginright: '0',
                select: {
                    ismust: true,
                    name: 'area_code',
                    area_code: '',
                    valuesheng: '',
                    valueshi: '',
                    valuequ: '',
                    disshi: true,
                    disqu: true
                }
            },

            //上传-【广告设置】-imgesuploade上传图片
            agentimgesupisshow: true,
            // 上传-【广告设置】-上传代理商广告
            agentimgesup: {
                uploadsize: 1, //选择上传图片的展现框-1表示小框，2表示中等框，3表示大框
                listtype: 'text', //list展示类型，text/picture/picture-card；默认不填为text
                showfilelist: false, //list展示列表是否显示，显示true，隐藏false
                ismust: true, //是否必填，true表示必填
                action: '/api/admin/upload/openFile', //upload上传API地址
                headers: { Auth: localStorage.getItem('token') }, //upload上传headers
                multiple: true, //上传多个文件
                title: '上传代理商广告', //button-按钮文字
                title_type: 'primary', //button-按钮显示样式,primary&text
                title_plain: true, //button-按钮是否为线性（plain）
                tips: '', //提示&建议文字
                limit: '0', //上传数量限制
                target_id: 50, //上传文件target_id ：图片分类，区分存储文件和存储时长，不传默认存储1天后过期；1-二维码； 2-打印文件； 3-文库文件； 4-课程文件； 5-广告主宣传页； 6-广告主产生的pdf文件； 7-证件照文件； 50-设备广告； 100-通用；
                rules: {
                    images: {
                        image_width: 0, //图片宽度
                        image_height: 0, //图片高度
                        size: 10000, //文件大小（单位kb）
                        type: ['image/jpeg', 'image/jpg', 'image/png'], //文件格式
                        suffix: ['jpg', 'png'], //文件后缀-用于判断
                        typechart: 'jpg/png' //文件格式提示规则
                    },
                    video: {
                        size: 20000, //文件大小（单位kb）
                        type: ['video/mp4'], //文件格式
                        typechart: 'mp4' //文件格式提示规则
                    }
                }
            },
            // 上传-【广告设置】-上传公司及用户广告
            agentimgesup2: {
                uploadsize: 1, //选择上传图片的展现框-1表示小框，2表示中等框，3表示大框
                listtype: 'text', //list展示类型，text/picture/picture-card；默认不填为text
                showfilelist: false, //list展示列表是否显示，显示true，隐藏false
                ismust: true, //是否必填，true表示必填
                action: '/api/admin/upload/openFile', //upload上传API地址
                headers: { Auth: localStorage.getItem('token') }, //upload上传headers
                multiple: true, //上传多个文件
                title: '上传公司广告', //button-按钮文字
                title_type: 'primary', //button-按钮显示样式,primary&text
                title_plain: true, //button-按钮是否为线性（plain）
                tips: '', //提示&建议文字
                limit: '0', //上传数量限制
                target_id: 50, //上传文件target_id ：图片分类，区分存储文件和存储时长，不传默认存储1天后过期；1-二维码； 2-打印文件； 3-文库文件； 4-课程文件； 5-广告主宣传页； 6-广告主产生的pdf文件； 7-证件照文件； 50-设备广告； 100-通用；
                rules: {
                    images: {
                        image_width: 0, //图片宽度
                        image_height: 0, //图片高度
                        size: 10000, //文件大小（单位kb）
                        type: ['image/jpeg', 'image/jpg', 'image/png'], //文件格式
                        suffix: ['jpg', 'png'], //文件后缀-用于判断
                        typechart: 'jpg/png' //文件格式提示规则
                    },
                    video: {
                        size: 20000, //文件大小（单位kb）
                        type: ['video/mp4'], //文件格式
                        typechart: 'mp4' //文件格式提示规则
                    }
                }
            },
            // 广告设置-[注意：从后端获取的广告详情具有id字段，自主最新上传的广告是无id字段，以便在提交保存时，将最新上传的做标识0]
            set_advert: {
                id: '5370',
                isshowdvc: '1',
                agent_advert: [
                    // {
                    //   files: {
                    //     file_type: "image/jpeg",
                    //     file_url:
                    //       "https://fuss10.elemecdn.com/e/5d/4a731a90594a4af544c0c25941171jpeg.jpeg",
                    //   },
                    //   playlangtime: "15",
                    //   status: 0,
                    //   findmen: "代理商",
                    //   findtime: "永久",
                    //   stoptime: "永久",
                    // },
                    // {
                    //   files: {
                    //     file_type: "image/png",
                    //     file_url:
                    //       "http://yingdao.jsshareprint.com/Upload/adv/20211119/6197514376c7b.png",
                    //   },
                    //   playlangtime: "15",
                    //   status: 1,
                    //   findmen: "代理商",
                    //   findtime: "永久",
                    //   stoptime: "永久",
                    // },
                    // {
                    //   files: {
                    //     file_type: "video/mp4",
                    //     file_url:
                    //       "https://js-share-currency.oss-cn-beijing.aliyuncs.com/general/fc/0262a1e582023847f72dd91eabf994.mp4",
                    //   },
                    //   playlangtime: "以视频时长为准",
                    //   status: -1,
                    //   findmen: "代理商",
                    //   findtime: "永久",
                    //   stoptime: "永久",
                    // },
                    // {
                    //   files: {
                    //     file_type: "video/mp4",
                    //     file_url:
                    //       "https://js-share-currency.oss-cn-beijing.aliyuncs.com/general/fc/0262a1e582023847f72dd91eabf994.mp4",
                    //   },
                    //   playlangtime: "23",
                    //   status: 1,
                    //   findmen: "代理商",
                    //   findtime: "永久",
                    //   stoptime: "永久",
                    // },
                ],
                company_users_advert: [
                    // {
                    //   files: {
                    //     file_type: "image/png",
                    //     file_url:
                    //       "http://yingdao.jsshareprint.com/Upload/adv/20211119/6197514376c7b.png",
                    //   },
                    //   playlangtime: "15",
                    //   status: 1,
                    //   findmen: "系统默认",
                    //   findtime: "永久",
                    //   stoptime: "永久",
                    // },
                    // {
                    //   files: {
                    //     file_type: "video/mp4",
                    //     file_url:
                    //       "https://js-share-currency.oss-cn-beijing.aliyuncs.com/general/fc/0262a1e582023847f72dd91eabf994.mp4",
                    //   },
                    //   playlangtime: "15",
                    //   status: 2,
                    //   findmen: "张三",
                    //   findtime: "2020-10-30 10:08:23",
                    //   stoptime: "2021-10-30 10:08:23",
                    // },
                ],
                ads: '', //返给后端的必传参
                videoloading: false //loading缓冲
            },

            // 打印机配置项
            set_printdvc: {
                // 【打印机配置项】formdata表单数据
                formdata: {
                    id: '5370',
                    source: 1,
                    pdftype: 1,
                    beforepaper: 1,
                    printname: '',
                    paperfeeder0: '',
                    papersize0: '',
                    papertype0: '',
                    paperquality0: '',
                    paperfeeder1: '',
                    papersize1: '',
                    papertype1: '',
                    paperquality1: '',
                    iscolorlevel: 0,
                    colorlevel: [
                        // {
                        //   colorname: "black",
                        //   allowance: 10,
                        // },
                        // {
                        //   colorname: "cyan",
                        //   allowance: 40,
                        // },
                        // {
                        //   colorname: "magenta",
                        //   allowance: 20,
                        // },
                        // {
                        //   colorname: "yellow",
                        //   allowance: 30,
                        // },
                    ],
                    serial_number:'',
                    password:''
                },
                // 【打印机配置项】打印机类型-下拉选项
                dvctype_option: [],
                // 【打印机配置项】A4/6寸打印配置-下拉选项
                print_option: {}
            },

            // 推送及报警配置
            set_pushalarm: {
                // 推送及报警配置-data数据
                formdata: {
                    id: '5370',
                    avatar: [
                        // {
                        //   id:1,
                        //   unionid: 1,
                        //   avatar:
                        //     "https://fuss10.elemecdn.com/e/5d/4a731a90594a4af544c0c25941171jpeg.jpeg",
                        //   nickname: "张三1092312",
                        // },
                    ],
                    times: ''
                }
            },

            // 价格设置
            set_price: {
                id: '',
                charge: '',
                paytype: '',
                dvcplus: '',
                formdata: [
                    {
                        ide: '',
                        medium: '',
                        price_range: {
                            low: '',
                            height: ''
                        },
                        children: [
                            {
                                page_range: '',
                                bw_price_along: '',
                                bw_price_double: '',
                                colour_price_along: '',
                                colour_price_double: '',
                                addisshow: true
                            }
                        ]
                    }
                ],
                // 表单title
                titledata: [
                    {
                        prop: 'page_range',
                        label: '纸张范围',
                        width: '180px'
                    },
                    {
                        prop: 'bw_price_along',
                        label: '黑白价格-单面/页',
                        width: '180px'
                    },
                    {
                        prop: 'bw_price_double',
                        label: '黑白价格-双面/页',
                        width: '180px'
                    },
                    {
                        prop: 'colour_price_along',
                        label: '彩色价格-单面/页',
                        width: '180px'
                    },
                    {
                        prop: 'colour_price_double',
                        label: '彩色价格-双面/页',
                        width: '180px'
                    },
                    {
                        prop: 'caozuo',
                        label: '操作',
                        width: '80px'
                    }
                ]
            },

            // 出厂退货记录
            set_gooutgoods: {
                // 数据列表中-数据表头
                mainthreetabletitle: [
                    {
                        fixedstatu: false,
                        prop: 'addtime',
                        label: '投放/返厂时间',
                        width: '200'
                    },
                    {
                        fixedstatu: false,
                        prop: 'uptime',
                        label: '操作时间',
                        width: '200'
                    },
                    {
                        fixedstatu: false,
                        prop: 'type',
                        label: '操作',
                        width: '200'
                    },
                    {
                        fixedstatu: false,
                        prop: 'username',
                        label: '操作人',
                        width: '200'
                    },
                    {
                        fixedstatu: false,
                        prop: 'address',
                        label: '投放地址',
                        width: ''
                    },
                    {
                        fixedstatu: false,
                        prop: 'remark',
                        label: '备注',
                        width: ''
                    }
                ],
                // 数据列表中-具体数据
                mainthreetableData: []
            },

            // 操作记录
            set_handle: {
                // 数据列表中-数据表头
                mainthreetabletitle: [
                    {
                        fixedstatu: false,
                        prop: 'source_type_name',
                        label: '平台',
                        width: '200'
                    },
                    {
                        fixedstatu: false,
                        prop: 'name',
                        label: '操作者',
                        width: '200'
                    },
                    {
                        fixedstatu: false,
                        prop: 'addtime',
                        label: '操作时间',
                        width: '200'
                    },
                    {
                        fixedstatu: false,
                        prop: 'remark',
                        label: '操作类型',
                        width: ''
                    }
                ],
                // 数据列表中-具体数据
                mainthreetableData: []
            },

            loading: false, //初始化-loading加载动效，默认是false关闭
            mypowerlimits: JSON.parse(localStorage.getItem('powerlimits')), //获取权限
            mytoken: localStorage.getItem('token'), //获取存储的token[此页面中的该字段，可有可无，具体看该页面是否需要使用token]
            userinfo: JSON.parse(localStorage.getItem('userinfo')), //获取用户信息
            isshow: true, //用于判断页面模块是否存在

            mydisabled: false, //前端防抖

            button_display: 1, // 按钮点击状态： 1禁用 2启用

            pagesdatas:'', //兼容保持返回机器控制-保持筛选条件不变
        }
    },
    mounted() {
        window['closes'] = () => {
            this.closes()
        }
    },
    methods: {
        // 点击取消
        cancelEvent() {
            // this.$router.go(-1) // 返回上一页
            this.routeraddrClick('/sharedvccontrol', this.pagesdatas)
        },
        //点击进行路由页面跳转以及携带参数
        routeraddrClick(routeraddr, rowdata) {
            if (rowdata) {
                // 携带参数跳转
                if (routeraddr === '/dvcisonline') {
                    this.$router
                        .push({
                            path: routeraddr,
                            query: {
                                ids: rowdata.id
                            }
                        })
                        .catch((error) => error)
                }
                // 跳转-远程控制页面
                if (routeraddr === '/sharedvccontrolfrp') {
                    // // 跳转到-本窗口下的-远程控制页面【方式1】
                    // this.$router
                    //   .push({
                    //     path: routeraddr,
                    //     query: {
                    //       id: rowdata.id,
                    //     },
                    //   })
                    //   .catch((error) => error);

                    // 跳转到-新增窗口下的-远程控制页面【方式2】
                    const { href } = this.$router.resolve({
                        path: routeraddr,
                        query: {
                            id: rowdata.id
                        }
                    })
                    window.open(href)
                }
                // 跳转机器控制页面-用于保持筛选条件不变
                if (routeraddr === '/sharedvccontrol') {
                    this.$router
                        .push({
                            path: routeraddr,
                            query: {
                                pagesdatas: rowdata
                            }
                        })
                        .catch((error) => error)
                }
            } else {
                //仅跳转
                this.$router.push(routeraddr).catch((error) => error)
            }
        },
        // 切换tabs标签页面，点击触发事件
        handleClick(tab) {
            // 设备信息
            if (tab.props.name === 'one') {
                console.log('设备信息')
                axios
                .get('/manage/Equipment/clientEditInfo', {
                    headers: {
                        Auth: localStorage.getItem('token')
                    },
                    params: {
                        id: this.$route.query.id
                    }
                })
                .then((response) => {
                    if (response.data.code == 0) {
                        // 存储所有信息到alldvcinfo
                        this.alldvcinfo = response.data.result.client
                        var getdata = response.data.result.client

                        // 特殊判断-当type为C2时，不显示【广告设置】与【打印机配置项】
                        this.mytype = getdata.type
                        // 特殊判断-当ver为大于3.0.0.0时，不显示【广告设置】
                        if (getdata.ver && getdata.ver != '' && getdata.ver != null) {
                            this.myver = parseInt(getdata.ver.substr(0, 1))
                        }

                        // 前端自组分类form表单
                        // 设备详情
                        this.set_dvcinfo.formdata = {
                            dvcnum: getdata.client,
                            id: getdata.id,
                            serialnumber: getdata.serial_number,
                            teamviewer: getdata.timeview,
                            vernumber: getdata.ver,
                            status: {
                                printstatus: getdata.printstatus,
                                is_online: getdata.is_online
                            },
                            shopname: getdata.storeinfo.storename,
                            shopimage: getdata.storeinfo.storedoor,
                            shopimage_list:
                                getdata.storeinfo.storedoor.length > 0
                                    ? [
                                          {
                                              name: '',
                                              url: getdata.storeinfo.storedoor
                                          }
                                      ]
                                    : [],
                            dvcimage: getdata.storeinfo.clientimg,
                            dvcimage_list:
                                getdata.storeinfo.clientimg.length > 0
                                    ? [
                                          {
                                              name: '',
                                              url: getdata.storeinfo.clientimg
                                          }
                                      ]
                                    : [],
                            mobile: getdata.storeinfo.storemobile
                        }
                        this.changeRadioEvent(getdata.auto_up)

                        console.log(response.data.result.client)
                    }
                })
                .catch(function(error) {
                    console.log(error)
                })
            }
            //价格设置
            if (tab.props.name === 'two') {
                console.log('价格设置')
            }
            //广告设置
            if (tab.props.name === 'three') {
                console.log('广告设置')
            }
            //
            if (tab.props.name === 'four') {
                console.log('出厂安装设置')
            }
            // 推送及报警配置
            if (tab.props.name === 'five') {
                console.log('推送及报警配置')
            }
        },

        // ------------------------------【设备信息】-------------------------
        // 【设备信息】- 获取当前windows页面信息-实时修改【弹窗】的宽度
        getwindowinfo() {
            this.sreenheight = document.documentElement.clientHeight
            this.sreenwidth = document.documentElement.clientWidth
            if (this.sreenwidth < 400) {
                this.set_dvcinfo.look_qrcode.searchwidth = this.sreenwidth * 0.8 + 'px'
            } else {
                this.set_dvcinfo.look_qrcode.searchwidth = '380px'
            }
            if (this.sreenwidth < 1040) {
                this.set_gooutfactory.addr_map.searchwidth = this.sreenwidth * 0.8 + 'px'
            } else {
                this.set_gooutfactory.addr_map.searchwidth = '1020px'
            }
        },
        // 【设备信息】-点击按钮-弹出弹框并赋值弹窗基本配置
        addTanClick(taninfo, ide, action) {
            console.log(taninfo)
            if (ide == 'addrmap') {
                this.getmapEvent(taninfo.formdata.lnglat, taninfo.formdata.dvcaddr)
            } else if (ide == 'downcode') {
                let paramsdata = {
                    client: taninfo.formdata.dvcnum,
                    id: taninfo.formdata.id
                }
                if (action != null) {
                    paramsdata['action'] = action
                }
                axios
                    .get('/manage/Equipment/clientQrcode', {
                        headers: {
                            Auth: localStorage.getItem('token')
                        },
                        params: paramsdata
                    })
                    .then((response) => {
                        if (action == 'printQrcode') {
                            if (response.data.code == 0) {
                                ElMessage({
                                    type: 'success',
                                    message: '操作成功!'
                                })
                            }
                        } else {
                            this.set_dvcinfo.look_qrcode.downcodeurl = response.data.result.src
                        }
                    })
                    .catch(function(error) {
                        console.log(error)
                    })
            } else if (ide == 'conductcode') {
                let paramsdata = {
                    client: taninfo.formdata.dvcnum,
                    id: taninfo.formdata.id
                }
                if (action != null) {
                    paramsdata['action'] = action
                }
                axios
                    .get('/manage/Equipment/clientPub', {
                        headers: {
                            Auth: localStorage.getItem('token')
                        },
                        params: paramsdata
                    })
                    .then((response) => {
                        if (action == 'printPub') {
                            if (response.data.code == 0) {
                                ElMessage({
                                    type: 'success',
                                    message: '操作成功!'
                                })
                            }
                        } else {
                            this.set_dvcinfo.look_conduct.downcodeurl = response.data.result.src
                        }
                    })
                    .catch(function(error) {
                        console.log(error)
                    })
            }
        },
        // 【设备信息】-获取upload子组件回传的数据-file文件url等数据
        getUploadFileEvent(data, ide) {
            console.log('当前获取的回传数据：' + JSON.stringify(data))
            if (ide == 1) {
                this.set_dvcinfo.formdata.shopimage = data.url
                this.set_dvcinfo.formdata.shopimage_list = [
                    {
                        name: data.name,
                        url: data.url
                    }
                ]
            } else {
                this.set_dvcinfo.formdata.dvcimage = data.url
                this.set_dvcinfo.formdata.dvcimage_list = [
                    {
                        name: data.name,
                        url: data.url
                    }
                ]
            }
        },
        //【设备信息】-删除上传的文件-事件（upload组件）
        deluploadfileEvent(data, ide) {
            console.log('当前删除事件-返回的数据：' + JSON.stringify(data))
            if (ide == 1) {
                if (data.length > 0) {
                    this.set_dvcinfo.formdata.shopimage = data
                    this.set_dvcinfo.formdata.shopimage_list = data
                } else {
                    this.set_dvcinfo.formdata.shopimage = ''
                    this.set_dvcinfo.formdata.shopimage_list = data
                }
            } else {
                if (data.length > 0) {
                    this.set_dvcinfo.formdata.dvcimage = data
                    this.set_dvcinfo.formdata.dvcimage_list = data
                } else {
                    this.set_dvcinfo.formdata.dvcimage = ''
                    this.set_dvcinfo.formdata.dvcimage_list = data
                }
            }
        },
        // 【设备信息】-地图-closes
        closes() {
            console.log('关闭')
            document.getElementsByClassName('amap-marker-label')[0].remove()
        },
        // 【设备信息】 -地图-获取地图函数
        getmapEvent(mylnglat, mydvcaddr) {
            var that = this
            AMapLoader.load({
                key: 'd45b94eb15c85da28f5e2e377d61546d', // 申请好的Web端开发者Key，首次调用 load 时必填
                version: '1.4.15', // 指定要加载的 JSAPI 的版本，缺省时默认为 1.4.15
                plugins: ['AMap.Scale', 'AMap.Geocoder'], // 需要使用的的插件列表，如比例尺'AMap.Scale'等
                AMapUI: {
                    // 是否加载 AMapUI，缺省不加载
                    version: '1.1', // AMapUI 缺省 1.1
                    plugins: [] // 需要加载的 AMapUI ui插件
                },
                Loca: {
                    // 是否加载 Loca， 缺省不加载
                    version: '1.3.2' // Loca 版本，缺省 1.3.2
                }
            })
                .then((AMap) => {
                    var map = new AMap.Map('container', {
                        zoom: 4, //级别
                        resizeEnable: true
                    })
                    // ------------返显-当前数据库中设备地址-----------------------------
                    var marker = new AMap.Marker()
                    map.add(marker)
                    marker.setPosition(mylnglat)
                    new AMap.InfoWindow({
                        content: mydvcaddr
                    }).open(map, mylnglat)

                    // ----------地图-点击之后-出现新地址信息--------------------------
                    var clickHandler = function(e) {
                        regeoCode(e.lnglat)
                        console.log(e.lnglat)
                    }
                    // var marker = new AMap.Marker();
                    function regeoCode(lnglat) {
                        // 坐标点-定位
                        map.add(marker)
                        marker.setPosition(lnglat)
                        // 通过经纬度-获取中文地址
                        var geocoder = new AMap.Geocoder()
                        geocoder.getAddress(lnglat, function(status, result) {
                            if (status === 'complete' && result.regeocode) {
                                var address = result.regeocode.formattedAddress
                                // 显示-中文地址-在地图上
                                new AMap.InfoWindow({
                                    content: address
                                }).open(map, lnglat)
                                // 赋值-中文地址
                                that.set_dvcinfo.formdata.dvcaddr = address
                                // 赋值-经纬度
                                that.set_dvcinfo.formdata.lnglat = lnglat
                            } else {
                                // 赋值-中文地址
                                that.set_dvcinfo.formdata.dvcaddr = ''
                                // 赋值-经纬度
                                that.set_dvcinfo.formdata.lnglat = {}
                            }
                        })
                    }
                    map.on('click', clickHandler)
                    // ----------------------------------------------------------
                })
                .catch((e) => {
                    console.log(e)
                })
        },
        // 【设备信息】-提交表单-保存设置
        dvcinfoSubmit(formName) {
            //前端防抖
            this.mydisabled = true

            var getdatas = this.$refs[formName].model
            console.log(getdatas)
            this.$refs[formName].validate((valid) => {
                if (valid) {
                    console.log(valid)
                    axios
                        .post(
                            '/manage/Equipment/clientEditBase',
                            {
                                id: getdatas.id,
                                storename: getdatas.shopname,
                                storedoor: getdatas.shopimage,
                                clientimg: getdatas.dvcimage,
                                storemobile: getdatas.mobile
                            },
                            {
                                headers: {
                                    Auth: localStorage.getItem('token')
                                }
                            }
                        )
                        .then((response) => {
                            if (response.data.code === 0) {
                                ElMessage({
                                    type: 'success',
                                    message: response.data.message,
                                    duration: 1000,
                                    onClose: () => {
                                        // this.$router.go(-1); // 返回上一页
                                        this.mydisabled = false
                                    }
                                })
                            } else {
                                this.mydisabled = false
                            }
                        })
                        .catch(function(error) {
                            console.log(error)
                            this.mydisabled = false
                        })
                } else {
                    console.log('error submit!!')
                    this.mydisabled = false
                    return false
                }
            })
        },

        // ------------------------------【出厂安装设置】-------------------------
        // 【出厂安装设置】-判断改变公司是否禁用代理商
        judgeIsDisabledAgent(){
            this.set_gooutfactory.is_disabled_agent = false
        },
        // 【出厂安装设置】-售后人员-下拉加载
        getAfterSaleSelectEvent(mycompanyid, flag) {
            // 当flag为1时，清空售后人员数据
            if (flag == 1) {
                this.set_gooutfactory.formdata.people = ''
            }
            axios
                .get('/manage/Equipment/getAfterSaleSelect', {
                    headers: {
                        Auth: localStorage.getItem('token')
                    },
                    params: {
                        companyid: mycompanyid
                    }
                })
                .then((response) => {
                    this.set_gooutfactory.aftersale_option = response.data.result
                })
                .catch(function(error) {
                    console.log(error)
                })
        },
        // 【出厂安装设置】- 代理商||商户-下拉加载
        getShopCodeSelectEvent(myagentid, flag, companyid, ftype, flex, agentflex) {
            // ftype = 1代表代理商select框，2商户select框
            if (ftype == 1) {
                // 当flex为1时，清空代理商&商户
                if (flex == 1) {
                    this.set_gooutfactory.formdata.shop_code = []
                }
                axios
                    .get('/manage/Equipment/getAgentSelect', {
                        headers: {
                            Auth: localStorage.getItem('token')
                        },
                        params: {
                            companyid: companyid
                        }
                    })
                    .then((response) => {
                        this.set_gooutfactory.agent_option = response.data.result
                    })
                    .catch(function(error) {
                        console.log(error)
                    })
            } else {
                // 当agentflex为1时，清空商户1;为2时，清空商户2
                if (agentflex == 1) {
                    this.set_gooutfactory.formdata.shop_code[1] = ''
                    this.set_gooutfactory.formdata.shop_code[2] = ''
                }
                if (agentflex == 2) {
                    this.set_gooutfactory.formdata.shop_code[2] = ''
                }
                axios
                    .get('/manage/Equipment/getAgentSelect', {
                        headers: {
                            Auth: localStorage.getItem('token')
                        },
                        params: {
                            pid: myagentid,
                            companyid: companyid
                        }
                    })
                    .then((response) => {
                        if (flag == 1) {
                            let pushdata = {
                                id: '',
                                name: ''
                            }
                            response.data.result.unshift(pushdata)
                            this.set_gooutfactory.shopman_option_one = response.data.result
                        }
                        if (flag == 2) {
                            let pushdata = {
                                id: '',
                                name: ''
                            }
                            response.data.result.unshift(pushdata)
                            this.set_gooutfactory.shopman_option_two = response.data.result
                        }
                    })
                    .catch(function(error) {
                        console.log(error)
                    })
            }
        },
        // 【出厂安装设置】-打印功能-多选事件
        facilityCheckboxA4(data, flag) {
            let getdatas = this.set_gooutfactory.formdata.facility
            if (data == false) {
                if (getdatas.indexOf('5') != -1) {
                    ElMessage({
                        type: 'warning',
                        message: '当A3选中时，A4为必选'
                    })
                    getdatas.push(flag)
                }
                if (getdatas.indexOf('6') != -1) {
                    ElMessage({
                        type: 'warning',
                        message: '当A3选中时，A4为必选'
                    })
                    getdatas.push(flag)
                }
            }
            // 数组数据去重
            this.set_gooutfactory.formdata.facility = Array.from(new Set(getdatas))
        },
        facilityCheckboxA3(data) {
            let getdatas = this.set_gooutfactory.formdata.facility
            if (data == true) {
                if (getdatas.indexOf('3') == -1) {
                    getdatas.push('3')
                }
                if (getdatas.indexOf('4') == -1) {
                    getdatas.push('4')
                }
            }
            // 数组数据去重
            this.set_gooutfactory.formdata.facility = Array.from(new Set(getdatas))
        },
        // 【出厂安装设置】-切换地址是否更新事件
        changeRadioEvent(data) {
            if (data == 1) {
                // this.set_gooutfactory_rules.dvcaddr[0].required = false;
                this.set_gooutfactory_rules.dvcaddrdetail[0].required = false
            }
            if (data == -1) {
                // this.set_gooutfactory_rules.dvcaddr[0].required = true;
                this.set_gooutfactory_rules.dvcaddrdetail[0].required = true
            }
        },
        // 【出厂安装设置】-设备地址code
        getCityCodeEvent(data) {
            console.log('当前获取的citycode值为：' + data)
            this.set_gooutfactory.formdata.dvcaddr = data
        },
        // 【出厂安装设置】-查询地区的函数-请求省市区接口，并返回相关值
        findAreaEvent(areacode) {
            axios
                .get('/admin/getArea', {
                    headers: {
                        Auth: localStorage.getItem('token')
                    },
                    params: {
                        code: areacode
                    }
                })
                .then((response) => {
                    var getcityname = response.data.result.list.show_name
                    var getarray = getcityname.split(',')
                    if (getarray.length === 3) {
                        this.myselectcity.select.valuesheng = getarray[0]
                        this.myselectcity.select.valueshi = getarray[1]
                        this.myselectcity.select.valuequ = getarray[2]
                    } else {
                        if (getarray.length === 2) {
                            this.myselectcity.select.valuesheng = getarray[0]
                            this.myselectcity.select.valueshi = getarray[1]
                            this.myselectcity.select.valuequ = ''
                        } else {
                            if (getarray.length === 1) {
                                this.myselectcity.select.valuesheng = getarray[0]
                                this.myselectcity.select.valueshi = ''
                                this.myselectcity.select.valuequ = ''
                            }
                        }
                    }
                })
                .catch(function(error) {
                    // 请求失败处理
                    console.log(error)
                })
        },

        // 【出厂安装设置】-提交表单-保存设置
        gooutfactorySubmit(formName) {
            //前端防抖
            this.mydisabled = true

            var potapiurl = ''
            // 判断-机器控制-出厂按钮进入||编辑按钮进入
            if (this.$route.query.goout == 1) {
                potapiurl = '/manage/Equipment/clientLeaveFactory'
            } else {
                potapiurl = '/manage/Equipment/clientEditInstall'
            }
            var getdatas = this.$refs[formName].model
            // console.log(getdatas)
            this.$refs[formName].validate((valid) => {
                if (valid) {
                    axios
                        .post(
                            potapiurl,
                            {
                                id: getdatas.id,
                                source: getdatas.source,
                                companyid: getdatas.companyid,
                                shop_code: getdatas.shop_code.filter((n) => n),
                                is_buy: getdatas.is_buy,
                                putintime: getdatas.putintime.valueOf() / 1000,
                                putintimez: getdatas.putintimez != null ? getdatas.putintimez.valueOf() / 1000 : '', // 【临时任务-20230605-linshuai】资金方使用-投放时间使用putintimez字段表示，且非必填
                                push_type: getdatas.scene,
                                auto_up: getdatas.resource,
                                // area_code: getdatas.dvcaddr,
                                address: getdatas.dvcaddrdetail,
                                // lng: getdatas.lnglat.lng,
                                // lat: getdatas.lnglat.lat,
                                is_use_advertiser: getdatas.is_use_advertiser,
                                is_flow_cash: getdatas.is_flow_cash,
                                // is_flow_cash: getdatas.flower,
                                fund: getdatas.capital_person,
                                people: getdatas.people,
                                personnel: getdatas.personnel,
                                facility: getdatas.facility,
                                config: getdatas.config
                            },
                            {
                                headers: {
                                    Auth: localStorage.getItem('token')
                                }
                            }
                        )
                        .then((response) => {
                            if (response.data.code === 0) {
                                ElMessage({
                                    type: 'success',
                                    message: response.data.message,
                                    duration: 1000,
                                    onClose: () => {
                                        // this.$router.go(-1); // 返回上一页
                                        this.mydisabled = false
                                    }
                                })
                            } else {
                                this.mydisabled = false
                            }
                        })
                        .catch(function(error) {
                            console.log(error)
                            this.mydisabled = false
                        })
                } else {
                    console.log('error submit!!')
                    this.mydisabled = false
                    return false
                }
            })
        },

        // ------------------------------【广告设置】-------------------------
        // 【广告设置】-上传前-获取upload子组件回传的数据-filetype数据
        getfiletypeEvent(data, flag) {
            console.log('当前上传前获取的文件类型数据：' + JSON.stringify(data))
            if (data == 'video/mp4' && flag == 1) {
                this.set_advert.videoloading = true
            }
            if (data == 'video/mp4' && flag == 2) {
                this.set_advert.videoloading2 = true
            }
        },
        // 【广告设置】-上传成功之后-获取upload子组件回传的数据-file文件url等数据
        getAdvertUploadFileEvent(data, flag) {
            console.log('当前获取Advert的回传数据：' + JSON.stringify(data))
            let geturl = data.url
            let filetype = ''
            let playlangtime = 15
            if (data.type == 'mp4') {
                filetype = 'video/mp4'
                if (flag == 1) {
                    this.set_advert.videoloading = false
                }
                if (flag == 2) {
                    this.set_advert.videoloading2 = false
                }
                playlangtime = data.fileLen
                geturl = data.res.requestUrls[0]
            } else {
                if (data.type == 'png') {
                    filetype = 'image/png'
                } else {
                    filetype = 'image/jpeg'
                }
            }
            let datas = {
                files: {
                    file_type: filetype,
                    file_url: geturl
                },
                playlangtime: playlangtime,
                status: data.status,
                findmen: this.userinfo.username,
                findtime: Math.round(new Date() / 1000),
                stoptime: 0
            }
            if (flag == 1) {
                this.set_advert.agent_advert.push(datas)
            } else {
                datas['is_default'] = 1
                this.set_advert.company_users_advert.push(datas)
            }
        },
        //【广告设置】-删除上传的文件-事件（upload组件）
        delAdvertuploadfileEvent(data, flag) {
            console.log('当前删除Advert事件-返回的数据：' + JSON.stringify(data), flag)
        },
        // 【广告设置】-删除-list列表中的行数据
        delAdvertEvent(ide, data, flag) {
            if (flag == 1) {
                // 由于无法控制最近上传的总文件数量，因此limit限制再删除一个之后自动+1，以保证删除时候能正常上传图片/video,此处为投机取巧，待后续优化
                this.agentimgesup.limit = (parseInt(this.agentimgesup.limit) + 1).toString()
                data.splice(ide, 1)
            } else {
                // 由于无法控制最近上传的总文件数量，因此limit限制再删除一个之后自动+1，以保证删除时候能正常上传图片/video,此处为投机取巧，待后续优化
                this.agentimgesup2.limit = (parseInt(this.agentimgesup2.limit) + 1).toString()
                data.splice(ide, 1)
            }
        },
        // 【广告设置】-提交表单-保存设置
        advertSubmit(formName) {
            //前端防抖
            this.mydisabled = true

            var getdatas = this.$refs[formName].model
            console.log(getdatas)
            this.$refs[formName].validate((valid) => {
                if (valid) {
                    console.log(valid)
                    var getimages = []
                    // 数据拼接-代理商广告
                    if (getdatas.agent_advert.length > 0) {
                        getdatas.agent_advert.forEach((element) => {
                            let data = ''
                            if (element.id) {
                                data = '1--' + element.id + '--' + element.files.file_url + '--' + element.playlangtime + '--'
                            } else {
                                data = '1--0--' + element.files.file_url + '--' + element.playlangtime + '--'
                            }
                            getimages.push(data)
                        })
                    }
                    // 数据拼接-公司及用户广告
                    if (getdatas.company_users_advert.length > 0) {
                        getdatas.company_users_advert.forEach((element) => {
                            let data = ''
                            if (element.id) {
                                data = '2--' + element.id + '--' + element.files.file_url + '--' + element.playlangtime + '--'
                            } else {
                                data = '2--0--' + element.files.file_url + '--' + element.playlangtime + '--'
                            }
                            getimages.push(data)
                        })
                    }
                    axios
                        .post(
                            '/manage/Equipment/clientEditAdv',
                            {
                                id: getdatas.id,
                                ads: getdatas.ads,
                                is_mass_advert: getdatas.isshowdvc,
                                images: getimages
                            },
                            {
                                headers: {
                                    Auth: localStorage.getItem('token')
                                }
                            }
                        )
                        .then((response) => {
                            if (response.data.code === 0) {
                                ElMessage({
                                    type: 'success',
                                    message: response.data.message,
                                    duration: 1000,
                                    onClose: () => {
                                        // this.$router.go(-1); // 返回上一页
                                        this.mydisabled = false
                                    }
                                })
                            } else {
                                this.mydisabled = false
                            }
                        })
                        .catch(function(error) {
                            console.log(error)
                            this.mydisabled = false
                        })
                } else {
                    console.log('error submit!!')
                    this.mydisabled = false
                    return false
                }
            })
        },

        // ------------------------------【打印机配置项】-------------------------
        // 【打印机配置项】-提交表单-保存设置
        printdvcSubmit(formName) {
            //前端防抖
            this.mydisabled = true

            var getdatas = this.$refs[formName].model
            console.log(getdatas)
            this.$refs[formName].validate((valid) => {
                if (valid) {
                    axios
                        .post(
                            '/manage/Equipment/clientEditClientSetting',
                            {
                                id: getdatas.id,
                                // source: getdatas.source, // Word打印方式
                                // pdftype: getdatas.pdftype, // PDF打印方式
                                // beforepaper: getdatas.beforepaper, // 前置纸器放置纸张类型
                                source: 3, // Word打印方式-WPS
                                pdftype: 2, // PDF打印方式-PDF_TRON
                                beforepaper: 2, // 前置纸器放置纸张类型-A4纸
                                printname: getdatas.printname,
                                paperfeeder0: getdatas.paperfeeder0,
                                papertype0: getdatas.papertype0,
                                papersize0: getdatas.papersize0,
                                paperquality0: getdatas.paperquality0,
                                paperfeeder1: getdatas.paperfeeder1,
                                papertype1: getdatas.papertype1,
                                papersize1: getdatas.papersize1,
                                paperquality1: getdatas.paperquality1,
                                serial_number: getdatas.serial_number,
                                password: getdatas.password
                            },
                            {
                                headers: {
                                    Auth: localStorage.getItem('token')
                                }
                            }
                        )
                        .then((response) => {
                            if (response.data.code === 0) {
                                ElMessage({
                                    type: 'success',
                                    message: response.data.message,
                                    duration: 1000,
                                    onClose: () => {
                                        // this.$router.go(-1); // 返回上一页
                                        this.mydisabled = false
                                    }
                                })
                            } else {
                                this.mydisabled = false
                            }
                        })
                        .catch(function(error) {
                            console.log(error)
                            this.mydisabled = false
                        })
                } else {
                    console.log('error submit!!')
                    this.mydisabled = false
                    return false
                }
            })
        },

        // ------------------------------【推送及报警配置】-------------------------
        // 【推送及报警配置】-获取（选择微信）返回的数据
        getuniondataEvent(data) {
            console.log(data)
            this.set_pushalarm.formdata.avatar = data
        },
        // 【推送及报警配置】-提交表单-保存设置
        savealramSubmit(formName) {
            //前端防抖
            this.mydisabled = true

            var getdatas = this.$refs[formName].model
            console.log(getdatas)
            this.$refs[formName].validate((valid) => {
                if (valid) {
                    let uids = []
                    getdatas.avatar.forEach((element) => {
                        uids.push(element.id)
                    })
                    axios
                        .post(
                            '/manage/Equipment/clientEditShop',
                            {
                                id: getdatas.id,
                                uids: uids,
                                space: getdatas.times
                            },
                            {
                                headers: {
                                    Auth: localStorage.getItem('token')
                                }
                            }
                        )
                        .then((response) => {
                            if (response.data.code === 0) {
                                ElMessage({
                                    type: 'success',
                                    message: response.data.message,
                                    duration: 1000,
                                    onClose: () => {
                                        // this.$router.go(-1); // 返回上一页
                                        this.mydisabled = false
                                    }
                                })
                            } else {
                                this.mydisabled = false
                            }
                        })
                        .catch(function(error) {
                            console.log(error)
                            this.mydisabled = false
                        })
                } else {
                    console.log('error submit!!')
                    this.mydisabled = false
                    return false
                }
            })
        },

        // ------------------------------【价格设置】-------------------------
        // 【价格设置】-增加行
        addPriceEvent(ide, data, times) {
            let pricerange = this.set_price.formdata[ide].price_range
            // ide: 0 A4文档；1 A3文档；2 照片；3 普通证件照；4 智能证件照；5 证件合成复印；6 扫描；7系统外
            let bwpricealong = ''
            let bwpricedouble = ''
            let colourpricedouble = ''
            if (ide == 2 || ide == 3 || ide == 4) {
                console.log(pricerange)
                bwpricealong = pricerange.low
                bwpricedouble = pricerange.low
                colourpricedouble = pricerange.low
            }
            if (ide == 5 || ide == 6 || ide == 7) {
                bwpricedouble = pricerange.low
                colourpricedouble = pricerange.low
            }

            if (times) {
                if (this.set_price.formdata[ide].children.length < times) {
                    let add = {
                        page_range: data.page_range,
                        bw_price_along: bwpricealong,
                        bw_price_double: bwpricedouble,
                        colour_price_along: '',
                        colour_price_double: colourpricedouble,
                        addisshow: false
                    }
                    // js中通过push向数组最后新增指定的元素
                    this.set_price.formdata[ide].children.push(add)
                } else {
                    ElMessage({
                        type: 'warning',
                        message: '最多增加' + (times - 1) + '组！'
                    })
                }
            } else {
                let add = {
                    page_range: data.page_range,
                    bw_price_along: bwpricealong,
                    bw_price_double: bwpricedouble,
                    colour_price_along: '',
                    colour_price_double: colourpricedouble,
                    addisshow: false
                }
                // js中通过push向数组最后新增指定的元素
                this.set_price.formdata[ide].children.push(add)
            }
        },
        // 【价格设置】-删除行
        delPriceEvent(ide, cide) {
            this.set_price.formdata[ide].children.splice(cide, 1)
        },
        // 【价格设置】-恢复出厂价格
        recoveryPriceEvent(id) {
            console.log(id)
            axios
                .post(
                    '/manage/Equipment/clientEditInitPrice',
                    {
                        id: id
                    },
                    {
                        headers: {
                            Auth: localStorage.getItem('token')
                        }
                    }
                )
                .then((response) => {
                    if (response.data.code === 0) {
                        ElMessage({
                            type: 'success',
                            message: '设置成功！',
                            duration: 1000,
                            onClose: () => {
                                this.reload() // 刷新当前页面
                            }
                        })
                    }
                })
                .catch(function(error) {
                    console.log(error)
                })
        },
        // 【价格设置】-提交表单-保存设置
        priceSubmit(formName) {
            //前端防抖
            this.mydisabled = true

            var getdatas = this.$refs[formName].model
            // console.log(getdatas);
            this.$refs[formName].validate((valid) => {
                if (valid) {
                    let codes = {}
                    getdatas.formdata.forEach(function(element) {
                        let codedata = []
                        element.children.forEach((cdata) => {
                            let getdata = []
                            let bw = []
                            bw[0] = cdata.bw_price_along * 100
                            bw[1] = cdata.bw_price_double * 100
                            let colour = []
                            colour[0] = cdata.colour_price_along * 100
                            colour[1] = cdata.colour_price_double * 100

                            getdata[0] = bw
                            getdata[1] = colour
                            let data = {
                                p: cdata.page_range,
                                min_price: element.price_range.low * 100,
                                max_price: element.price_range.height * 100,
                                data: getdata
                            }
                            codedata.push(data)
                            if (element.odernum == 1) {
                                codes['1'] = codedata
                            } else {
                                if (element.odernum == 2) {
                                    codes['2'] = codedata
                                } else {
                                    if (element.odernum == 3) {
                                        codes['3'] = codedata
                                    } else {
                                        if (element.odernum == 4) {
                                            codes['4'] = codedata
                                        } else {
                                            if (element.odernum == 5) {
                                                codes['5'] = codedata
                                            } else {
                                                if (element.odernum == 6) {
                                                    codes['6'] = codedata
                                                } else {
                                                    if (element.odernum == 7) {
                                                        codes['7'] = codedata
                                                    } else {
                                                        if (element.odernum == 8) {
                                                            codes['8'] = codedata
                                                        }
                                                    }
                                                }
                                            }
                                        }
                                    }
                                }
                            }
                        })
                    })
                    // console.log(codes);
                    axios
                        .post(
                            '/manage/Equipment/clientEditPrice',
                            {
                                id: getdatas.id,
                                charge: getdatas.charge,
                                codes: codes,
                                paytype: getdatas.paytype,
                                monthly: getdatas.dvcplus
                            },
                            {
                                headers: {
                                    Auth: localStorage.getItem('token')
                                }
                            }
                        )
                        .then((response) => {
                            if (response.data.code === 0) {
                                ElMessage({
                                    type: 'success',
                                    message: response.data.message,
                                    duration: 1000,
                                    onClose: () => {
                                        // this.$router.go(-1); // 返回上一页
                                        this.mydisabled = false
                                    }
                                })
                            } else {
                                this.mydisabled = false
                            }
                        })
                        .catch(function(error) {
                            console.log(error)
                            this.mydisabled = false
                        })
                } else {
                    console.log('error submit!!')
                    this.mydisabled = false
                    return false
                }
            })
        },

        // ---------------------------------数据渲染-----------------------------
        // 【出厂退货记录】数据渲染
        gooutMyaxiosfunc(clientid) {
            axios
                .get('/manage/Equipment/runLog', {
                    headers: {
                        Auth: localStorage.getItem('token')
                    },
                    params: {
                        id: clientid
                    }
                })
                .then((response) => {
                    console.log(response)
                    var newdata = response.data.result
                    for (var i = 0; i < newdata.length; i++) {
                        // 转换-操作类型
                        if (newdata[i].type) {
                            if (newdata[i].type == 1) {
                                newdata[i].type = '出厂'
                            } else {
                                newdata[i].type = '退货'
                            }
                        }
                        //转换-投放/返厂时间
                        if (newdata[i].addtime) {
                            if (newdata[i].addtime === 0 || newdata[i].addtime === null) {
                                newdata[i].addtime = '无添加时间'
                            } else {
                                newdata[i].addtime = this.$utils.formatDate(newdata[i].addtime)
                            }
                        } else {
                            newdata[i].addtime = '无添加时间'
                        }
                        //转换-操作时间
                        if (newdata[i].uptime) {
                            if (newdata[i].uptime === 0 || newdata[i].uptime === null) {
                                newdata[i].uptime = '无添加时间'
                            } else {
                                newdata[i].uptime = this.$utils.formatDate(newdata[i].uptime)
                            }
                        } else {
                            newdata[i].uptime = '无添加时间'
                        }
                    }
                    this.set_gooutgoods.mainthreetableData = newdata
                })
                .catch(function(error) {
                    console.log(error)
                })
        },
        // 【操作记录】数据渲染
        caozuoMyaxiosfunc(clientid) {
            axios
                .get('/manage/Equipment/changeLog', {
                    headers: {
                        Auth: localStorage.getItem('token')
                    },
                    params: {
                        id: clientid
                    }
                })
                .then((response) => {
                    console.log(response)
                    var newdata = response.data.result
                    for (var i = 0; i < newdata.length; i++) {
                        // 转换-平台来源- 1租户平台 2商户平台 3总平台
                        if (newdata[i].source_type == 1) {
                            newdata[i].source_type_name = '租户平台'
                        } else {
                            if (newdata[i].source_type == 2) {
                                newdata[i].source_type_name = '商户平台'
                            } else {
                                newdata[i].source_type_name = '总平台'
                            }
                        }
                        // 转换-操作时间
                        if (newdata[i].addtime) {
                            if (newdata[i].addtime === 0 || newdata[i].addtime === null) {
                                newdata[i].addtime = '无添加时间'
                            } else {
                                newdata[i].addtime = this.$utils.formatDate(newdata[i].addtime)
                            }
                        } else {
                            newdata[i].addtime = '无添加时间'
                        }
                    }
                    this.set_handle.mainthreetableData = newdata
                })
                .catch(function(error) {
                    console.log(error)
                })
        }
    },
    created() {
        this.button_display = 1
        if (localStorage.getItem('token') === null || !localStorage.getItem('token')) {
            this.isshow = false
            this.$router.push('/').catch((error) => error)
        } else {
            window.addEventListener('resize', this.getwindowinfo) // 注册监听器-实时检测浏览器页面宽高
            this.getwindowinfo()

            this.loading = true

            // 判断-机器控制-出厂按钮进入||编辑按钮进入
            if (this.$route.query.goout == 1) {
                this.activeName = 'four'
            } else {
                // 权限控制-判断-tabs标签-默认显示页
                if (this.mypowerlimits.jqkz_bjsbxq == true) {
                    this.activeName = 'one'
                } else {
                    if (this.mypowerlimits.jqkz_bjccazsz == true || this.mypowerlimits.jqkz_cc == true) {
                        this.activeName = 'four'
                    } else {
                        if (this.mypowerlimits.jqkz_bjggsz == true) {
                            this.activeName = 'three'
                        } else {
                            if (this.mypowerlimits.jqkz_bjdyjpzx == true) {
                                this.activeName = 'sex'
                            } else {
                                if (this.mypowerlimits.jqkz_bjtsjbjpz == true) {
                                    this.activeName = 'five'
                                } else {
                                    if (this.mypowerlimits.jqkz_bjjgszx == true) {
                                        this.activeName = 'two'
                                    } else {
                                        if (this.mypowerlimits.jqkz_ccthjl == true) {
                                            this.activeName = 'seven'
                                        } else {
                                            if (this.mypowerlimits.jqkz_czjl == true) {
                                                this.activeName = 'eight'
                                            }
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }

            // 特殊判断-路由中是否携带runtype,且runtype!=2时，tab不能切换且提示'设备未出厂，请先出厂'或者直接隐藏tab
            if (this.$route.query.runtype) {
                this.runtype = this.$route.query.runtype
            }

            // 兼容机器控制页面-筛选条件保持
            if(this.$route.query.pagesdatas){
                this.pagesdatas = this.$route.query.pagesdatas
            }

            // 获取所有的详情信息-并分类
            var p1 = axios
                .get('/manage/Equipment/clientEditInfo', {
                    headers: {
                        Auth: localStorage.getItem('token')
                    },
                    params: {
                        id: this.$route.query.id
                    }
                })
                .then((response) => {
                    if (response.data.code == 0) {
                        // 存储所有信息到alldvcinfo
                        this.alldvcinfo = response.data.result.client
                        var getdata = response.data.result.client

                        // 特殊判断-当type为C2时，不显示【广告设置】与【打印机配置项】
                        this.mytype = getdata.type
                        // 特殊判断-当ver为大于3.0.0.0时，不显示【广告设置】
                        if (getdata.ver && getdata.ver != '' && getdata.ver != null) {
                            this.myver = parseInt(getdata.ver.substr(0, 1))
                        }

                        // 前端自组分类form表单
                        // 设备详情
                        this.set_dvcinfo.formdata = {
                            dvcnum: getdata.client,
                            id: getdata.id,
                            serialnumber: getdata.serial_number,
                            teamviewer: getdata.timeview,
                            vernumber: getdata.ver,
                            status: {
                                printstatus: getdata.printstatus,
                                is_online: getdata.is_online
                            },
                            shopname: getdata.storeinfo.storename,
                            shopimage: getdata.storeinfo.storedoor,
                            shopimage_list:
                                getdata.storeinfo.storedoor.length > 0
                                    ? [
                                          {
                                              name: '',
                                              url: getdata.storeinfo.storedoor
                                          }
                                      ]
                                    : [],
                            dvcimage: getdata.storeinfo.clientimg,
                            dvcimage_list:
                                getdata.storeinfo.clientimg.length > 0
                                    ? [
                                          {
                                              name: '',
                                              url: getdata.storeinfo.clientimg
                                          }
                                      ]
                                    : [],
                            mobile: getdata.storeinfo.storemobile
                        }
                        this.changeRadioEvent(getdata.auto_up)

                        // 出厂安装设置
                        // 前端兼容老数据-facility-【1-判断是否为数组，若是对象需转数组；2-判断key为非0的对象值：若值为0，则在对象中移除】
                        let newfacility = []
                        let transit = []
                        // Array.isArray的值为false表示对象，true表示数组
                        if (Array.isArray(getdata.facility) == false) {
                            // Object.keys判断对象是否有值
                            if (Object.keys(getdata.facility).length > 0) {
                                for (let i = 0; i < Object.keys(getdata.facility).length; i++) {
                                    if (Object.keys(getdata.facility)[i] != '0') {
                                        if (getdata.facility[i] == 0) {
                                            getdata.facility[Object.keys(getdata.facility)[i]] = null
                                        }
                                    }
                                }
                            }
                            // 将对象转换成数组
                            transit = Object.keys(getdata.facility.map(String))
                                .map(function(i) {
                                    return getdata.facility.map(String)[i]
                                })
                                .filter((n) => n)
                        } else {
                            transit = getdata.facility.map(String)
                        }

                        // 前端过滤-当is_scan为0时，数组中过滤掉1和2
                        if (getdata.is_scan == 0) {
                            newfacility = transit.filter((n) => n != 1 && n != 2)
                        } else {
                            newfacility = transit
                        }

                        // 出厂安装设置-返显数据
                        // 兼容老数据-companyid,当为0时，将companyid置空
                        if (getdata.companyid == 0) {
                            getdata.companyid = ''
                        }
                        this.set_gooutfactory.formdata = {
                            id: getdata.id,
                            source: getdata.source,
                            companyid: getdata.companyid,
                            shop_code: [],
                            is_buy: getdata.is_buy,
                            putintime: getdata.putintime == 0 || getdata.putintime == null ? new Date(new Date().toLocaleDateString()).getTime() : getdata.putintime * 1000,
                            putintimez: getdata.putintimez == 0 || getdata.putintimez == null ? '' : getdata.putintimez * 1000, // 【临时任务-20230605-linshuai】资金方使用-投放时间使用putintimez字段表示，且非必填
                            scene: typeof getdata.push_type == 'number' ? (getdata.push_type != 0 ? getdata.push_type.toString() : '') : getdata.push_type,
                            // dvcaddr: getdata.area_code, // 地址-暂时去除-地址下拉
                            dvcaddrdetail: getdata.address,
                            resource: getdata.auto_up,
                            // lnglat: {
                            //   Q: getdata.lat,
                            //   R: getdata.lng,
                            //   lng: getdata.lng, //经度
                            //   lat: getdata.lat, //纬度
                            // },
                            is_use_advertiser: getdata.is_use_advertiser,
                            is_flow_cash: getdata.is_flow_cash,
                            capital_person: getdata.fund != 0 && getdata.fund != [] ? getdata.fund.map((i) => parseInt(i, 0)) : [], //资金方
                            people: getdata.people != '' && getdata.people != null ? parseInt(getdata.people) : '',
                            personnel: getdata.personnel,
                            facility: newfacility,
                            config: getdata.config
                        }
                        // 出厂安装设置-控制-打印功能-A3的显示&隐藏
                        this.set_gooutfactory.a3support = getdata.a3support
                        // 出厂安装设置-控制-打印功能-平稿台复印&ADF复印-是否显示
                        this.set_gooutfactory.isscan = getdata.is_scan
                        // 出厂安装设置-控制-打印功能-平稿台复印&ADF复印-是否禁选
                        this.set_gooutfactory.isnotcheck = getdata.not_check
                        // 出厂安装设置-控制-打印功能-2500特殊判断
                        this.set_gooutfactory.printNameType = getdata.printNameType
                        // 出厂安装设置-判断companyid不为空请求下拉
                        if (getdata.companyid != null && getdata.companyid != '') {
                            // 出厂安装设置-加载-代理商下拉数据
                            this.getShopCodeSelectEvent(null, 1, getdata.companyid, 1)
                            // 出厂安装设置-返显代理商&商户
                            if (getdata.shop_code != '') {
                                let getshopcode = getdata.shop_code.split(',')
                                let datas = []
                                getshopcode.forEach((element) => {
                                    datas.push(parseInt(element))
                                })
                                this.set_gooutfactory.formdata.shop_code = datas
                                if (datas.length > 0) {
                                    this.getShopCodeSelectEvent(datas[0], 1, getdata.companyid, 2)
                                    if (datas.length > 1) {
                                        this.getShopCodeSelectEvent(datas[1], 2, getdata.companyid, 2)
                                    }
                                }
                            }
                            // 出厂安装设置-加载-售后下拉数据
                            this.getAfterSaleSelectEvent(getdata.companyid)
                        }
                        // 出厂安装设置-省市区的返显
                        // this.findAreaEvent(getdata.area_code);
                        // 出厂安装设置-公司&代理商-禁用
                        this.set_gooutfactory.set_disabled_fzr = getdata.fzr ? getdata.fzr : {}
                        if(getdata.fzr && getdata.fzr.agentid && getdata.fzr.agentid){
                            this.set_gooutfactory.is_disabled_agent = true
                            this.set_gooutfactory.formdata.companyid = getdata.fzr.companyid
                            this.set_gooutfactory.formdata.shop_code[0] = getdata.fzr.agentid
                            this.getShopCodeSelectEvent(getdata.fzr.agentid, 1, getdata.fzr.companyid, 2)
                        }

                        // 广告设置
                        var agent_advert_images = []
                        var company_users_advert_images = []
                        var agentimgeslimit = 0
                        var companyimgeslimit = 0
                        getdata.image.forEach((element) => {
                            let cutout = element.file.split('.')
                            let suffix = cutout[cutout.length - 1]
                            var filetype = ''
                            if (suffix == 'mp4') {
                                filetype = 'video/mp4'
                            } else {
                                filetype = 'image/jpeg || image/png'
                            }
                            if (element.intype == 1) {
                                // 已有代理商广告条数
                                agentimgeslimit += 1
                                let datas = {
                                    id: element.id,
                                    files: {
                                        file_type: filetype,
                                        file_url: element.file
                                    },
                                    playlangtime: element.play_unit_times,
                                    status: element.statusname,
                                    findmen: element.user,
                                    findtime: element.begin_time,
                                    stoptime: element.end_time
                                }
                                agent_advert_images.push(datas)
                            } else {
                                companyimgeslimit += 1
                                let mydatas = {
                                    id: element.id,
                                    files: {
                                        file_type: filetype,
                                        file_url: element.file
                                    },
                                    playlangtime: element.play_unit_times,
                                    status: element.statusname,
                                    findmen: element.user,
                                    findtime: element.begin_time,
                                    stoptime: element.end_time,
                                    is_default: element.is_default //is_default判断投放人是否为系统默认
                                }
                                company_users_advert_images.push(mydatas)
                            }
                        })
                        this.agentimgesup.limit = (6 - agentimgeslimit).toString() //代理商广告剩余上传数量【注：upload中的limit只有是string类型才不会出现bug】
                        this.agentimgesup2.limit = (10 - companyimgeslimit).toString() //公司&用户广告剩余上传数量【注：upload中的limit只有是string类型才不会出现bug】
                        this.set_advert = {
                            id: getdata.id,
                            isshowdvc: getdata.is_mass_advert,
                            agent_advert: agent_advert_images,
                            company_users_advert: company_users_advert_images,
                            ads: getdata.ads //存储ads值
                        }

                        // 打印机配置项-配置下拉
                        let printoption = response.data.result.cate
                        if (printoption.length > 0) {
                            for (let i = 0; i < printoption.length; i++) {
                                let arrdata = []
                                for (let j = 0; j < printoption[i].child.length; j++) {
                                    let data = {
                                        value: printoption[i].child[j].id.toString(),
                                        label: printoption[i].child[j].name
                                    }
                                    arrdata.push(data)
                                }
                                this.set_printdvc.print_option[i] = arrdata
                            }
                        }
                        // 打印机配置项-数据
                        let colorlevel_info = []
                        if (getdata.colorlevel) {
                            // 转化colorlevel的数据结构
                            let getkeys = Object.keys(getdata.colorlevel)
                            getkeys.forEach((element, key) => {
                                colorlevel_info[key] = {
                                    colorname: element,
                                    allowance: getdata.colorlevel[element]
                                }
                            })
                        }
                        if (getdata.printinfo != '' && getdata.printinfo) {
                            this.set_printdvc.formdata = {
                                id: getdata.id,
                                // source: getdata.source, // Word打印方式
                                // pdftype: getdata.pdftype, // PDF打印方式
                                // beforepaper: getdata.beforepaper, // 前置纸器放置纸张类型
                                source: 3, // Word打印方式-WPS
                                pdftype: 2, // PDF打印方式-PDF_TRON
                                beforepaper: 2, // 前置纸器放置纸张类型-A4纸
                                printname: getdata.printname,
                                paperfeeder0: getdata.printinfo[0][0],
                                papertype0: getdata.printinfo[0][1],
                                papersize0: getdata.printinfo[0][2],
                                paperquality0: getdata.printinfo[0][3],
                                paperfeeder1: getdata.printinfo[1][0],
                                papertype1: getdata.printinfo[1][1],
                                papersize1: getdata.printinfo[1][2],
                                paperquality1: getdata.printinfo[1][3],
                                iscolorlevel: getdata.iscolorlevel,
                                colorlevel: colorlevel_info,
                                serial_number: getdata.serial_number,
                                password:getdata.password
                            }
                        } else {
                            this.set_printdvc.formdata = {
                                id: getdata.id,
                                // source: getdata.source, // Word打印方式
                                // pdftype: getdata.pdftype, // PDF打印方式
                                // beforepaper: getdata.beforepaper, // 前置纸器放置纸张类型
                                source: 3, // Word打印方式-WPS
                                pdftype: 2, // PDF打印方式-PDF_TRON
                                beforepaper: 2, // 前置纸器放置纸张类型-A4纸
                                printname: getdata.printname,
                                iscolorlevel: getdata.iscolorlevel,
                                colorlevel: colorlevel_info,
                                serial_number: getdata.serial_number,
                                password:getdata.password
                            }
                        }

                        // 推送及报警配置
                        this.set_pushalarm.formdata = {
                            id: getdata.id,
                            avatar: getdata.avatar,
                            times: getdata.space
                        }

                        // 价格设置项
                        let price_set_info = []
                        if (getdata.price) {
                            let getkeys = Object.keys(getdata.price)
                            getkeys.forEach((element) => {
                                let nowdata = getdata.price[element]
                                let getmedium = ''
                                let getide = ''
                                if (element == '1') {
                                    getmedium = 'A4文档'
                                    getide = '0'
                                } else {
                                    if (element == '2') {
                                        getmedium = 'A3文档'
                                        getide = '1'
                                    } else {
                                        if (element == '3') {
                                            getmedium = '照片'
                                            getide = '2'
                                        } else {
                                            if (element == '4') {
                                                getmedium = '普通证件照'
                                                getide = '3'
                                            } else {
                                                if (element == '5') {
                                                    getmedium = '智能证件照'
                                                    getide = '4'
                                                } else {
                                                    if (element == '6') {
                                                        getmedium = '证件合成复印'
                                                        getide = '5'
                                                    } else {
                                                        if (element == '7') {
                                                            getmedium = '扫描'
                                                            getide = '6'
                                                        } else {
                                                            if (element == '8') {
                                                                getmedium = '系统外'
                                                                getide = '7'
                                                            }
                                                        }
                                                    }
                                                }
                                            }
                                        }
                                    }
                                }
                                // 定义data
                                let data = {}
                                // 标识
                                data.odernum = element
                                // 打印介质名&打印介质标识
                                data.medium = getmedium
                                data.ide = getide
                                // 区间价格
                                data.price_range = {
                                    low: nowdata[0].min_price / 100,
                                    height: nowdata[0].max_price / 100
                                }
                                // 纸张范围&&黑白&&彩色价格等children
                                let children = []
                                nowdata.forEach((priceinfo, keys) => {
                                    let newchildren = {
                                        page_range: priceinfo.p,
                                        bw_price_along: priceinfo.data[0][0] / 100,
                                        bw_price_double: priceinfo.data[0][1] / 100,
                                        colour_price_along: priceinfo.data[1][0] / 100,
                                        colour_price_double: priceinfo.data[1][1] / 100,
                                        addisshow: keys == 0 ? true : false
                                    }
                                    children.push(newchildren)
                                })
                                data.children = children
                                // 将数据push入数组
                                price_set_info.push(data)
                            })
                        }
                        this.set_price.id = getdata.id
                        this.set_price.charge = getdata.charge
                        this.set_price.paytype = getdata.paytype
                        this.set_price.dvcplus = getdata.monthly
                        this.set_price.formdata = price_set_info

                        // 出厂退货记录
                        this.gooutMyaxiosfunc(getdata.clientid)

                        // 操作记录
                        this.caozuoMyaxiosfunc(getdata.clientid)

                        console.log(response.data.result.client)
                    }
                })
                .catch(function(error) {
                    console.log(error)
                })

            // 【出厂安装设置】- 公司-下拉选项加载
            var p2 = axios
                .get('/manage/Equipment/getComSelect', {
                    headers: {
                        Auth: localStorage.getItem('token')
                    },
                    params: {}
                })
                .then((response) => {
                    this.set_gooutfactory.company_option = response.data.result
                })
                .catch(function(error) {
                    console.log(error)
                })

            // 【出厂安装设置】-资金方-下拉选项加载
            var p3 = axios
                .get('/manage/Equipment/fundSelect', {
                    headers: {
                        Auth: localStorage.getItem('token')
                    },
                    params: {}
                })
                .then((response) => {
                    this.set_gooutfactory.capital_person = response.data.result
                })
                .catch(function(error) {
                    console.log(error)
                })

            //【打印机配置项】打印机类型-下拉选项-加载
            var p4 = axios
                .get('/manage/Equipment/getPrintName', {
                    headers: {
                        Auth: localStorage.getItem('token')
                    },
                    params: {}
                })
                .then((response) => {
                    this.set_printdvc.dvctype_option = response.data.result
                })
                .catch(function(error) {
                    console.log(error)
                })

            Promise.all([p1, p2, p3, p4])
                .then(() => {
                    setTimeout(() => {
                        this.button_display = 2
                    }, 500)
                    this.loading = false
                })
                .catch((error) => {
                    console.log('error:失败:', error)
                    this.loading = false
                })
        }
    },
    components: {
        mainone,
        mainwechatmore,
        mainimgesup
        // mainselect,
    }
})
</script>
<style scoped>
.dialog-box-flex {
    display: flex;
    flex-direction: column;
    justify-self: center;
}
#price_css :deep(.el-table .cell) {
    line-height: 50px;
}
.box {
    background-color: #ffffff;
    border-radius: 4px;
    color: #333333;
    padding: 10px 20px;
}

.box_title {
    line-height: 40px;
    height: 40px;
}

.box_title_font {
    float: left;
    font-weight: 400;
    font-size: 14px;
    color: #333333;
}

.box_title_button {
    float: right;
    font-weight: 400;
    font-size: 14px;
    color: #94a3d2;
}

.box_title_button:hover {
    cursor: pointer;
}

/*局部控制-Switch按钮-按钮中显示状态文字*/
/* switch按钮款式 */
#switch :deep(.el-switch__label) {
    position: absolute;
    display: none;
    color: #fff !important;
}

/*关上时文字地位设置*/
#switch :deep(.el-switch__label--right) {
    z-index: 1;
}

/* 调整关上时文字的显示位子 */
#switch :deep(.el-switch__label--right) span {
    margin-left: 3px;
    font-size: 12px;
}

/*敞开时文字地位设置*/
#switch :deep(.el-switch__label--left) {
    z-index: 1;
}

/* 调整敞开时文字的显示位子 */
#switch :deep(.el-switch__label--left) span {
    margin-left: 15px;
    font-size: 12px;
}

/*显示文字*/
#switch :deep(.el-switch__label.is-active) {
    display: block;
}

/* 调整按钮的宽度 */
#switch :deep(.el-switch) .el-switch__core,
#switch :deep(.el-switch) .el-switch__label {
    width: 55px !important;
    margin: 0;
    padding: 5px;
}

.box_content :deep(.el-form-item__error) {
    position: static !important;
}

.tan-table-status {
    display: flex;
    flex-direction: row;
}

.print_box {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
}
.print_box_info {
    max-width: 140px;
    margin: 0 20px 5px 0;
}

/* 广告设置-上传video视频-缓冲提示 */
.myadvert_video {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
}
.myadvert_video_button {
    margin-right: 20px;
    margin-bottom: 10px;
}
.myadvert_video_loading {
    margin-right: 20px;
    margin-bottom: 10px;
    color: red;
}

/* 地图 */
.map {
    width: 100%;
    height: 500px;
    margin-top: 10px;
    border-radius: 4px;
}

/* 出厂安装设置-商户 */
.goout_shopman_box {
    width: 100%;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
}
.goout_shopman_box_one {
    margin-bottom: 10px;
    margin-right: 10px;
}
</style>
