<template>
    <div>vbatchmanageadd</div>
</template>

<script>
export default {
    name: 'vbatchmanageadd',
    data() {
        return {}
    },
}
</script>