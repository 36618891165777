<template>
  <div>
    <mainone :mainonebread="this.mainonebread"></mainone>
    <div>
      <!--标题-->
      <div class="box">
        <div class="box_title">
          <div class="box_title_font">添加课程</div>
          <div class="box_title_button"></div>
        </div>
        <!--内容-->
        <div class="box_content">
          <el-row style="margin-top: 20px">
            <!--占位符-->
            <el-col :span="24" :xs="24" :sm="24" :lg="4">
              <div style="width: 100%"></div>
            </el-col>
            <!--主表样式-->
            <el-col :span="24" :xs="24" :sm="24" :lg="12">
              <el-form ref="ruleForm" :model="ruleForm" :rules="rules" label-width="140px" class="demo-ruleForm">
                <el-form-item label="课程名称：" prop="name">
                  <el-input v-model="ruleForm.name" placeholder="请输入课程名称" clearable></el-input>
                </el-form-item>
                <el-form-item label="课程分类：" prop="classify_id">
                  <el-select v-model="ruleForm.classify_id" placeholder="请选择" style="width: 100%">
                    <el-option :label="getsales.sort_key" :value="getsales.sort_val"
                      v-for="(getsales, i) in this.catalog" :key="i"></el-option>
                  </el-select>
                </el-form-item>
                <el-form-item label="课程封面：" prop="cover_img">
                  <mainimgesup v-model="ruleForm.cover_img" :myimgesup="this.myimgesup"
                    :myimgesupisshow="this.myimgesupisshow" v-on:getuploadfile="(data) => getUploadFileEvent(data)"
                    v-on:deluploadfile="(data) => deluploadfileEvent(data)">
                  </mainimgesup>
                </el-form-item>
                <el-form-item label="课程价格：" prop="price">
                  <el-input v-model="ruleForm.price" placeholder="请输入课程价格" clearable></el-input>
                </el-form-item>
                <el-form-item label="PLUS课程价格：" prop="vip_price">
                  <el-input v-model="ruleForm.vip_price" placeholder="请输入PLUS课程价格" clearable></el-input>
                </el-form-item>
                <el-form-item label="虚拟学习人数：" prop="empty_count">
                  <el-input v-model="ruleForm.empty_count" placeholder="请输入虚拟学习人数" clearable></el-input>
                </el-form-item>
                <el-form-item label="排序：" prop="sort">
                  <el-input v-model="ruleForm.sort" placeholder="数字越大排序越靠前" clearable></el-input>
                </el-form-item>
                <el-form-item label="课程介绍：" prop="content">
                  <TEditor ref="editor" v-model="ruleForm.content" v-on:geteditordatas="getEditorDatasEvent" />
                </el-form-item>
                <el-form-item label="课程内容：" prop="course_chapter">
                  <el-input v-model="ruleForm.course_chapter" style="display:none"></el-input>
                  <el-table row-key="id" :data="tableData" border style="width: 100%; border-radius: 4px;">
                    <el-table-column prop="name" label="章节名称">
                      <template #header>
                        <div class="myaddbutton">
                          <span style="line-height: 40px; margin-right: 20px;">章节目录</span>
                          <el-button type="primary" size="mini" @click="addCapter()">新增章节
                          </el-button>
                        </div>
                      </template>
                    </el-table-column>
                    <el-table-column label="操作" width="200">
                      <template #default="scope">
                        <el-button size="mini" type="danger"
                          @click="handleDelete(scope.$index, tableData, scope.row.id)" v-if="scope.row.showdel">删除
                        </el-button>
                        <el-button size="mini" type="primary" @click="handleEdit(scope.$index, tableData, scope.row.id)"
                          v-if="scope.row.showedit">编辑
                        </el-button>
                        <el-button size="mini" type="warning" v-if="scope.row.showadd"
                          @click="addCourseEvent(scope.row.id, (dialogVisible = true))">
                          添加课件
                        </el-button>
                      </template>
                    </el-table-column>
                  </el-table>
                </el-form-item>
                <el-form-item label="师资介绍：" prop="teacher_content">
                  <TEditor ref="editorTeacher" v-model="ruleForm.teacher_content" v-on:geteditordatas="getTeacherEditorDatasEvent" />
                </el-form-item>
                <el-form-item label="上架：" prop="is_up">
                  <el-radio-group v-model="ruleForm.is_up">
                    <el-radio label="1">是</el-radio>
                    <el-radio label="2">否</el-radio>
                  </el-radio-group>
                </el-form-item>
                <el-form-item label="推荐：" prop="is_push">
                  <el-radio-group v-model="ruleForm.is_push">
                    <el-radio label="1">是</el-radio>
                    <el-radio label="2">否</el-radio>
                  </el-radio-group>
                </el-form-item>
                <el-form-item>
                  <el-button type="primary" @click="submitForm('ruleForm')" style="width: 40%; margin-right: 10px">提交
                  </el-button>
                  <el-button @click="resetForm('ruleForm')">取消</el-button>
                </el-form-item>
              </el-form>
            </el-col>
            <!--占位符-->
            <el-col :span="24" :xs="24" :sm="24" :lg="8">
              <div style="width: 100%"></div>
            </el-col>
          </el-row>
        </div>
      </div>
    </div>

    <!-- 弹窗-添加课件 -->
    <el-dialog v-model="dialogVisible" title="选择课件" :width="this.searchwidth" :before-close="handleCloseSenior">
      <el-form ref="seniorForm" :model="seniorForm" :rules="rules">
        <!--查询条件-->
        <el-form-item>
          <div class="tan-search-box">
            <div class="tan-search-box-info">
              <div class="tan-search-box-info-title">课件名称：</div>
              <div>
                <el-input v-model="seniorForm.courseware_name" placeholder="请输入课件名称" clearable></el-input>
              </div>
            </div>
            <div class="tan-search-box-info">
              <div class="tan-search-box-info-title">分类：</div>
              <div>
                <el-select v-model="seniorForm.courseware_cat" placeholder="请选择">
                  <el-option :label="getsales.sort_key" :value="getsales.sort_val"
                    v-for="(getsales, i) in this.courseware_cat_list" :key="i"></el-option>
                </el-select>
              </div>
            </div>
            <div class="tan-search-box-button">
              <el-button type="primary" @click="onSubmitTan('seniorForm')">查询</el-button>
            </div>
          </div>
        </el-form-item>
        <!-- table表单 -->
        <el-form-item>
          <el-table :data="
        courseData.slice(
          (tanpages.currentPage - tanpages.lastpage) * tanpages.PageSize,
          tanpages.currentPage * tanpages.PageSize)" @select-all="handleSelectionChange"
            @select="handleSelectionChange" ref="multipleTable" style="width: 100%" max-height="500" border
            v-loading="tanpages.loading" element-loading-text="正在飞速加载中...">
            <el-table-column type="selection" width="40"> </el-table-column>
            <el-table-column label="课件名称" prop="title" min-width="180"></el-table-column>
            <el-table-column label="课程" prop="category" min-width="180"></el-table-column>
            <el-table-column label="作者" prop="add_user" width="150"></el-table-column>
            <el-table-column label="创建时间" prop="createtime" width="180">
              <template #default="scope">
                <div>
                  {{ this.$utils.formatDate(scope.row.createtime) }} </div>
              </template>
            </el-table-column>
          </el-table>
        </el-form-item>
        <div style="display: flex; flex-direction: row; flex-wrap: wrap; justify-content: space-between;">
          <div>
            <el-pagination background @size-change="handleSizeChangetan" @current-change="handleCurrentChangetan"
              pager-count="4" :current-page="tanpages.currentPage" :page-sizes="tanpages.pageSizes"
              :page-size="tanpages.PageSize" layout="total, sizes, prev, pager, next, jumper"
              :total="tanpages.totalCount">
            </el-pagination>
          </div>
          <div>
            <el-button @click="resetFormCourse((dialogVisible = false))">取消</el-button>
            <el-button type="primary" @click="onSubmitCourse(this.multipleSelection, this.tableData, this.changeindex)">
              确定
            </el-button>
          </div>
        </div>
      </el-form>
    </el-dialog>
  </div>
</template>

<script>
import mainone from "@/components/Mainone.vue";
import mainimgesup from "@/components/Mainimgesup.vue";
import TEditor from '@/components/Maineditor.vue'
import { ref } from "vue";
import axios from "axios";
import { ElMessage, ElMessageBox } from "element-plus";

export default {
  name: "vcurriculumlistadd",

  data() {
    return {
      mainonebread: [
        {
          name: "教育",
        },
        {
          name: "课程管理",
        },
        {
          name: "课程列表",
          path: "/curriculumlist",
        },
        {
          name: "添加课程",
        },
      ], // 当前页-【面包屑导航】

      myimgesupisshow: true, //form表单-imgesuploade上传图片
      myimgesup: {
        uploadsize: 1, //选择上传图片的展现框-1表示小框，2表示中等框，3表示大框
        listtype: "picture", //list展示类型，text/picture/picture-card；默认不填为text
        ismust: true, //是否必填，true表示必填
        action: "/api/manage/upload/file", //upload上传API地址
        headers: { 'Auth': localStorage.getItem("token"), }, //upload上传headers
        title: "上传图片", //button-按钮文字
        tips: "(1)建议尺寸：750*422px像素; (2)图片支持格式：*.jpg，*.jpeg，*.png", //提示&建议文字
        limit: "1", //上传数量限制
        target_id: 4, //上传文件target_id ：图片分类，区分存储文件和存储时长，不传默认存储1天后过期；1-二维码； 2-打印文件； 3-文库文件； 4-课程文件； 5-广告主宣传页； 6-广告主产生的pdf文件； 7-证件照文件； 50-设备广告； 100-通用；
        rules: {
          images: {
            image_width: 0, //图片宽度
            image_height: 0, //图片高度
            size: 10000, //文件大小（单位kb）
            type: ["image/jpeg", "image/jpg", "image/png"], //文件格式
            typechart: "jpg/jpeg/png", //文件格式提示规则
          },
        },
      },

      // 课程分类下拉数据
      catalog: [],

      // 弹窗-弹窗显示与否
      dialogVisible: false,
      //弹框-查询设备-form表单数据
      seniorForm: {
        courseware_name: "",
        courseware_cat: "",
      },
      searchwidth: "1024px",
      //弹窗中-table表单
      courseData: [],

      // 弹窗中-选中的课件
      multipleSelection: [],
      tanpages: {
        //弹窗中-table表单-分页器
        totalCount: 0, //数据列表中-数据总条数
        currentpage: 1, //数据列表中-数据当前页数
        lastpage: 1, //数据列表中-数据总页数（默认以20条一页）
        currentPage: 1,
        pageSizes: [10, 20, 50, 100, 1000],
        PageSize: 10,
        loading: false, //初始化-loading加载动效，默认是false关闭

        sign_type: "", //弹出框中签约类型
        pagesdatas: [], //存储(弹窗中)最近一次的筛选条件，供分页使用

        tandisabled: false, //控制弹窗中的确定按钮
      },

      // form表单-提交数据
      ruleForm: {
        name: "",
        classify_id: "",
        cover_img: "",
        content: "",
        vip_price: "",
        price: "",
        teacher_content: "",
        empty_count: "",
        course_chapter: [],
        sort: "",
        is_up: ref("1"),
        is_push: ref("1"),
      },
      //form表单-规则校验
      rules: {
        name: [
          {
            required: true,
            message: "课程名称为必填项",
            trigger: "blur",
          },
        ],
        classify_id: [
          {
            required: true,
            message: "课程分类为必填项",
            trigger: "blur",
          },
        ],
        cover_img: [
          {
            required: true,
            message: "课程封面为必填项",
            trigger: "blur",
          },
        ],
        content: [
          {
            required: true,
            message: "课程介绍为必填项",
            trigger: "blur",
          },
        ],
        vip_price: [
          {
            required: true,
            message: "PLUS课程价格为必填项",
            trigger: "blur",
          },
        ],
        price: [
          {
            required: true,
            message: "课程价格为必填项",
            trigger: "blur",
          },
        ],
        teacher_content: [
          {
            required: true,
            message: "师资介绍为必填项",
            trigger: "blur",
          },
        ],
        empty_count: [
          {
            required: true,
            message: "虚拟学习人数为必填项",
            trigger: "blur",
          },
        ],
        sort: [
          {
            required: true,
            message: "排序为必填项",
            trigger: "blur",
          },
        ],
        course_chapter: [
          {
            required: true,
            message: "课程内容为必填项",
            trigger: "blur",
          },
        ],
        is_up: [
          {
            required: true,
            message: "请选择选项",
            trigger: "change",
          },
        ],
        is_push: [
          {
            required: true,
            message: "请选择选项",
            trigger: "change",
          },
        ],
      },
      // 课程内容-table表单
      tableData: [
        // {
        //   id: 1,
        //   showedit: true,
        //   showadd: true,
        //   showdel: true,
        //   name: "章节01",
        //   children: [
        //     {
        //       id: 11,
        //       showdel: true,
        //       name: "初级项目管理课程【视频】",
        //     },
        //     {
        //       id: 12,
        //       showdel: true,
        //       name: "初级项目管理课程【视频】",
        //     },
        //   ],
        // },
      ],
      changeindex: '', // 点击添加课件时，选中的行index

      mypowerlimits: JSON.parse(localStorage.getItem("powerlimits")), //获取权限
      mytoken: localStorage.getItem("token"), //获取存储的token
    };
  },
  methods: {
    // 【课程封面】上传图片--upload返回数据
    getUploadFileEvent(data) {
      this.ruleForm.cover_img = data.url;
      //特殊的内容再次进行了校验，如果符合检验规则，错误提示就是消失了。
      this.$refs.ruleForm.validateField('cover_img', (valid) => {
        if (!valid) { return }
      })
    },
    // 【课程封面】删除上传的文件-事件（upload组件）
    deluploadfileEvent(data) {
      console.log("当前删除事件-返回的数据：", data);
      this.ruleForm.cover_img = '';
    },
    // 【课程内容】-添加章节目录
    addCapter() {
      ElMessageBox.prompt("章节名称", "新增章节", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        inputPattern: /\S/,
        inputErrorMessage: "请填写章节名称！",
      })
        .then(({ value }) => {
          let datas = {
            id: this.tableData.length + 1,
            showedit: true,
            showadd: true,
            showdel: true,
            name: value,
            children: []
          }
          this.tableData.push(datas);
        })
        .catch(() => {
          ElMessage({
            type: "info",
            message: "取消新增",
          });
        });
    },
    // 【课程内容】-删除章节
    handleDelete(index, rows, ide) {
      for (let key = 0; key < rows.length; key++) {
        {
          if (ide == rows[key].id) {
            rows.splice(key, 1);
            this.tableData = rows
          } else {
            if (rows[key]["children"] && rows[key]["children"].length > 0) {
              for (let p = 0; p < rows[key]["children"].length; p++) {
                if (!rows[key]["children"][p]["children"]) {
                  if (ide == rows[key]["children"][p].id) {
                    rows[key]["children"].splice(p, 1);
                    this.tableData = rows
                  }
                }
              }
            }
          }
        }
      }
    },
    // 【课程内容】-编辑章节
    handleEdit(index, rows, ide) {
      ElMessageBox.prompt("章节名称", "编辑章节:", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        inputPattern: /\S/,
        inputErrorMessage: "请填写章节名称！",
      })
        .then(({ value }) => {
          for (let key = 0; key < rows.length; key++) {
            if (ide == rows[key].id) {
              rows[key].name = value;
            }
          }
        })
        .catch(() => {
          ElMessage({
            type: "info",
            message: "取消编辑",
          });
        });
    },
    // 【课程内容】-点击-添加课件按钮
    addCourseEvent(index) {
      let pagesdatas = {
        courseware_name: '',
        courseware_cat: '',
        page: 1,
        page_size: this.tanpages.PageSize
      }
      this.tanGetInfoEvent(pagesdatas)

      // 选中的行号
      this.changeindex = index;
    },
    // 【课程内容】-添加课件-查询按钮-提交
    onSubmitTan(formName) {
      var getdatas = this.$refs[formName].model;
      this.$refs[formName].validate((valid) => {
        if (valid) {
          this.tanpages.pagesdatas = getdatas;
          this.tanpages.pagesdatas.page = 1;
          this.tanpages.pagesdatas.page_size = this.tanpages.PageSize;
          this.tanGetInfoEvent(this.tanpages.pagesdatas);
        } else {
          console.log("error submit!!");
          return false;
        }
      });
    },
    // 【课程内容】-添加课件-获取table表单数据
    tanGetInfoEvent(pagesdatas) {
      //点击进入弹窗-加载tabledata
      this.tanpages.loading = true;
      axios
        .post(
          "/manage/Course/indexCourseware",
          {
            content: pagesdatas.courseware_name,
            category: pagesdatas.courseware_cat,
            page: pagesdatas.page,
            page_size: pagesdatas.page_size,
          },
          {
            headers: {
              Auth: localStorage.getItem("token"),
            },
          }
        )
        .then((response) => {
          var successdata = response.data.result;
          var newdata = successdata.data;
          this.courseData = newdata;
          this.tanpages.totalCount = successdata.total;
          this.tanpages.currentpage = successdata.current_page; //当前页面页数
          this.tanpages.lastpage = successdata.last_page;
          this.tanpages.loading = false;
        })
        .catch(function (error) {
          console.log(error);
        });
    },
    // 【课程内容】-添加课件-弹窗中-分页器中-当选择条数时触发该函数
    handleSizeChangetan(val) {
      console.log(`每页 ${val} 条`);
      this.tanpages.PageSize = val;
      this.tanpages.currentPage = 1;

      this.tanpages.pagesdatas.page = 1;
      this.tanpages.pagesdatas.page_size = val;
      this.tanGetInfoEvent(this.tanpages.pagesdatas);
    },
    // 【课程内容】-添加课件-弹窗中-分页器中-当选择分页时触发该函数
    handleCurrentChangetan(val) {
      console.log(`当前页: 第${val}页`);
      this.tanpages.currentPage = val;

      this.tanpages.pagesdatas.page = val;
      this.tanpages.pagesdatas.page_size = this.tanpages.PageSize;
      this.tanGetInfoEvent(this.tanpages.pagesdatas);
    },
    //【课程内容】-添加课件-点击关闭-弹窗
    handleCloseSenior(done) {
      done();
      // 重置-查询条件
      this.seniorForm = {
        courseware_name: '',
        courseware_cat: ''
      }
      this.tanpages.pagesdatas = {
        courseware_name: '',
        courseware_cat: '',
        page: 1,
        page_size: this.tanpages.PageSize,
      }
      this.tanpages.currentPage = 1
      this.multipleSelection = []
    },
    //【课程内容】-添加课件-点击取消-关闭弹窗
    resetFormCourse() {
      // 重置-查询条件
      this.seniorForm = {
        courseware_name: '',
        courseware_cat: ''
      }
      this.tanpages.pagesdatas = {
        courseware_name: '',
        courseware_cat: '',
        page: 1,
        page_size: this.tanpages.PageSize,
      }
      this.tanpages.currentPage = 1
      this.multipleSelection = []
    },
    //【课程内容】-添加课件-table表中-当选项发生变化是触发该函数
    handleSelectionChange(val) {
      this.multipleSelection = val;
    },
    // 【课程内容】-添加课件-确认选中的数据
    onSubmitCourse(datas, tabledata, index) {
      if (datas.length > 0) {
        let getdatas = []
        datas.forEach(element => {
          let data = {
            id: element.id,
            name: element.title,
            showdel: true,
          }
          getdatas.push(data)
        });

        if (tabledata[index - 1].children.length > 0) {
          // 获取getdatas数组中的id组成的数组
          let getindex = getdatas.map((item) => { return item.id })
          // 去重
          let res = tabledata[index - 1].children.filter((num) => {
            let res = !(getindex.indexOf(num.id) > -1); //添加的数组对象id是否已经存在
            return res; //false表示不符合条件
          });
          tabledata[index - 1].children = getdatas.concat(res);
        } else {
          this.tableData[index - 1].children = getdatas
        }
        // 最后-关闭弹窗
        this.dialogVisible = false;
      }
    },

    // 课程介绍
    getEditorDatasEvent(datas){
      // console.log('课程介绍：',datas)
      this.ruleForm.content = datas;
    },
    // 师资介绍
    getTeacherEditorDatasEvent(datas){
      // console.log('师资介绍：',datas)
      this.ruleForm.teacher_content = datas;
    },

    // 提交保存
    submitForm(formName) {
      var formdatas = this.$refs[formName].model;
      // 转换-课程内容的数据结构
      if (this.tableData.length > 0) {
        let coursechapter = []
        this.tableData.forEach(element => {
          let child = ''
          if (element.children.length > 0) {
            child = element.children.map((item) => { return item.id }).join(',')
          }
          let datas = {
            name: element.name,
            ids: child
          }
          coursechapter.push(datas)
        });
        formdatas.course_chapter = coursechapter;
      } else {
        formdatas.course_chapter = ''
      }
      this.$refs[formName].validate((valid) => {
        if (valid) {
          axios
            .post(
              "/manage/Course/addCourse",
              {
                name: formdatas.name,
                classify_id: formdatas.classify_id,
                cover_img: formdatas.cover_img,
                content: formdatas.content,
                vip_price: formdatas.vip_price,
                price: formdatas.price,
                teacher_content: formdatas.teacher_content,
                empty_count: formdatas.empty_count,
                sort: formdatas.sort,
                course_chapter: formdatas.course_chapter,
                is_up: formdatas.is_up,
                is_push: formdatas.is_push,
              },
              {
                headers: {
                  Auth: localStorage.getItem("token"),
                },
              }
            )
            .then((response) => {
              if (response.data.code == 0) {
                ElMessage({
                  type: "success",
                  message: "提交成功！",
                  duration: 1000,
                  onClose: () => {
                    this.$router.back()
                  },
                });
              }
            })
            .catch(function (error) {
              // 请求失败处理
              console.log(error);
            });
        } else {
          console.log("error submit!!");
          return false;
        }
      });
    },
    //from表单-重置表单-取消返回上一页
    resetForm(formName) {
      this.$refs[formName].resetFields();
      this.$router.back()
    },

    //获取当前windows页面信息-实时修改【弹窗】的宽度
    getwindowinfo() {
      this.sreenheight = document.documentElement.clientHeight;
      this.sreenwidth = document.documentElement.clientWidth;
      if (this.sreenwidth < 1100) {
        this.searchwidth = this.sreenwidth * 0.8 + "px";
      } else {
        this.searchwidth = "1024px";
      }
    },

  },
  created() {
    if (localStorage.getItem("token") === null || !localStorage.getItem("token")) {
      this.isshow = false;
      this.$router.push("/").catch((error) => error);
    } else {
      window.addEventListener("resize", this.getwindowinfo); // 注册监听器-实时检测浏览器页面宽高
      this.getwindowinfo();

      // 加载-课程分类列表
      axios
        .post(
          "/manage/Course/indexCourseCate",
          {

          },
          {
            headers: {
              Auth: localStorage.getItem("token"),
            },
          }
        )
        .then((response) => {
          this.catalog = response.data.result.data;
        })
        .catch(function (error) {
          console.log(error);
        });

      //加载-课件分类
      axios
        .post(
          "/manage/Course/indexCoursewareCate",
          {},
          {
            headers: {
              Auth: localStorage.getItem("token"),
            },
          }
        )
        .then((response) => {
          this.courseware_cat_list = response.data.result.data;
          this.courseware_cat_list.unshift({ id: 0, sort_key: "全部" });
        })
        .catch(function (error) {
          // 请求失败处理
          console.log(error);
        });
    }
  },
  components: {
    mainone,
    mainimgesup,
    TEditor
  },
};
</script>

<style scoped>
.box {
  background-color: #ffffff;
  border-radius: 4px;
  color: #333333;
  padding: 10px 20px;
}

.box_title {
  line-height: 40px;
  height: 40px;
}

.box_title_font {
  float: left;
  font-weight: 400;
  font-size: 14px;
  color: #333333;
}

.box_title_button {
  float: right;
  font-weight: 400;
  font-size: 14px;
}

.box_title_button:hover {
  cursor: pointer;
}

.box_content {
  border-top: 1px solid #eeeeee;
  margin-top: 10px;
}

.el-table {
  --el-table-border-color: var(--el-border-color-base);
}

/* 弹出框-搜索栏 */
.tan-search-box {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  width: 100%;
}

.tan-search-box-info {
  display: flex;
  flex-direction: row;
  margin: 0 10px 10px 0;
}

.tan-search-box-info-title {
  min-width: 40px;
}

.tan-table-status {
  display: flex;
  flex-direction: row;
}
</style>