<template>
    <div>
        <gooutMonad :mainonebread="mainonebread" :is_all="0">
            <template #buttons>
                <div class="mybutton_box">
                    <div class="mybutton_box_btn">
                        <el-button type="primary" plain @click="downloadExcelEvent()">下载批量导入出库模板Excel </el-button>
                    </div>
                    <div style="margin: 0 10px 0 10px">
                        <mainimgesup
                            ref="uploadfile"
                            :myimgesup="myimgesup"
                            :myimgesupisshow="myimgesupisshow"
                            :disabled="disabled"
                            v-on:getuploadfile="(data) => getUploadFileEvent(data)"
                            v-on:beginUpload="beginUploadEvent"
                            v-on:errorUpload="errorUploadEvent"
                            v-if="mypowerlimits.ckd_my_import"
                        >
                        </mainimgesup>
                    </div>
                </div>
            </template>
        </gooutMonad>
    </div>
</template>

<script>
import gooutMonad from '../gooutmonad/vgooutmonad.vue'
import mainimgesup from '@/components/Mainimgesup.vue'
import { ElNotification } from 'element-plus'
import { defineComponent } from 'vue'
import axios from 'axios'

export default defineComponent({
    name: 'vgooutreportmonad',
    inject: ['reload'], //刷新引用
    data() {
        return {
            mainonebread: [
                {
                    name: '仓库'
                },
                {
                    name: '出入库管理'
                },
                {
                    name: '我的出库报单'
                }
            ],

            myimgesupisshow: true,
            myimgesup: {
                uploadsize: 2, // 1阿里 oss ；2前端传后端
                listtype: 'picture', //list展示类型，text/picture/picture-card；默认不填为text
                showfilelist: false, //list展示列表是否显示，显示true，隐藏false
                ismust: true, //是否必填，true表示必填
                action: '/manage/packout/import', //upload上传API地址
                headers: { Auth: localStorage.getItem('token') }, //upload上传headers
                multiple: false, //上传多个文件
                title: '批量导入出库', //button-按钮文字
                title_type: 'primary', //button-按钮显示样式,primary&text
                title_size: 'medium', //button-按钮大小，medium / small / mini
                title_plain: true, //button-按钮是否为线性（plain）
                isshow: false, //button-按钮是否显示，注意：false表示显示，true表示不显示
                tips: '', //提示&建议文字
                limit: '', //上传数量限制
                target_id: 100, //上传文件target_id ：图片分类，区分存储文件和存储时长，不传默认存储1天后过期；1-二维码； 2-打印文件； 3-文库文件； 4-课程文件； 5-广告主宣传页； 6-广告主产生的pdf文件； 7-证件照文件； 50-设备广告； 100-通用；
                rules: {
                    files: {
                        size: 200000, //文件大小（单位kb）
                        type: ['application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'], //文件格式
                        typechart: 'Excel' //文件格式提示规则
                    }
                }
            },
            disabled: false,

            mypowerlimits: JSON.parse(localStorage.getItem('powerlimits')), //获取权限
            mytoken: localStorage.getItem('token') //获取存储的token
        }
    },
    methods: {
        // 【下载批量导入出库模板Excel】
        downloadExcelEvent() {
            axios
                .get('/manage/Equipment/downTemplate', {
                    headers: {
                        Auth: localStorage.getItem('token')
                    },
                    params: {
                        templateName: 'wmsystem_myckd_return_template'
                    }
                })
                .then((response) => {
                    if (response.data.code == 0) {
                        let url = response.data.result.download
                        window.open(url, '_blank')
                    }
                })
                .catch(function (error) {
                    console.log(error)
                })
        },
        // 开始上传
        beginUploadEvent() {
            this.disabled = true
        },
        // 上传报错
        errorUploadEvent() {
            this.disabled = false
        },
        // 【批量导入出库】-获取upload子组件回传的数据-file文件url等数据
        getUploadFileEvent(data) {
            // 删除upload列队中的数据
            this.$refs.uploadfile.handleRemove(data)
            this.disabled = false
            setTimeout(() => {
                this.reload()
            }, 1000)
        }
    },
    components: {
        gooutMonad,
        mainimgesup
    }
})
</script>

<style scoped>
.mybutton_box {
    display: flex;
    flex-direction: row;
    align-items: center;
}

.mybutton_box_btn :deep(.el-button) {
    padding: 8px 20px;
    height: 36px;
    min-height: 36px;
}
</style>
