<template>
    <div>
        <div>
            <el-button :type="button.type" :plain="button.plain" :size="button.size" @click="revocation()">撤回</el-button>
        </div>
        <el-dialog v-model="dialog.show" :title="dialog.title" :width="dialog.width" append-to-body @close="close">
            <div>
                <el-form ref="ruleFormRef" :model="ruleForm" :rules="rules">
                    <el-form-item label="实出库量：">
						{{ info.real_num }}
					</el-form-item>
                    <el-form-item prop="only_code" label="撤回数量：">
                        <el-input v-model.number="ruleForm.only_code" clearable placeholder="请输入撤回数量" maxlength="20"></el-input>
                    </el-form-item>
                </el-form>
            </div>
            <div style="text-align: right; margin: 20px 0">
                <el-button @click="cancel(ruleFormRef)">取消</el-button>
                <el-button type="primary" :disabled="disabledBtn" @click="submit(ruleFormRef)">确定</el-button>
            </div>
        </el-dialog>
    </div>
</template>

<script setup>
import { reactive, ref, defineProps, defineEmits } from 'vue'
import http from '@/assets/js/http.js'

const props = defineProps(['info', 'id'])
const emit = defineEmits(['success'])

const button = ref({
	type: 'primary',
    plain: false,
    size: 'small'
})

const dialog = reactive({
	show: false,
    title: '撤回',
    width: '600px'
})

const ruleFormRef = ref()
const ruleForm = reactive({
	id: props.id,
	goods_id: props.info.id,
	only_code: ''
})
const rules = reactive({
	only_code: [
		{
			validator: (rule, value, callback)=> {
				if(value === '') {
					callback(new Error('请输入撤回数量'))
				} else {
					if(value > props.info.real_num) {
						callback(new Error('撤回数量不能大于实出库量'))
					} else {
						callback()
					}
				}
			},
			trigger: 'blur'
		}
	]
})


const revocation = ()=> {
	dialog.show = true
}

const disabledBtn = ref(false)

const submit = async (formEl)=> {
	if(!formEl) return
	await formEl.validate(async (valid, fields) => {
		if(valid) {
			disabledBtn.value = true
			await http.post('packout/revoked', ruleForm)
			dialog.show = false
			disabledBtn.value = false
			emit('success')
		} else {
			console.log('error submit!', fields)
		}
	})
}

const cancel = (formEl)=> {
    dialog.show = false
}

const close = ()=> {
	if(!ruleFormRef.value) return
	ruleFormRef.value.resetFields()
}

</script>

<style lang="scss" scoped></style>
