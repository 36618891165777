<template>
    <div>
        <mainone :mainonebread="mainonebread"></mainone>
        <div>
            <!--标题-->
            <div class="box" style="">
                <div class="box_title" style="">
                    <div class="box_title_font">添加导航</div>
                    <div class="box_title_button"></div>
                </div>
                <!--内容-->
                <div class="box_content">
                    <el-row style="margin-top: 20px">
                        <!--占位符-->
                        <el-col :span="24" :xs="24" :sm="24" :lg="7">
                            <div style="width: 100%"></div>
                        </el-col>
                        <!--主表样式-->
                        <el-col :span="24" :xs="24" :sm="24" :lg="9">
                            <el-form ref="ruleForm" :model="ruleForm" :rules="rules" label-width="140px"
                                class="demo-ruleForm">
                                <!-- 导航图标 -->
                                <el-form-item label="导航图标：" required="true">
                                    <el-form-item prop="icon_img" :rules="rules.icon_img">
                                        <mainimgesup :model="ruleForm.icon_img" :myimgesup="myimgesup"
                                            :myimgesupisshow="myimgesupisshow" :filelist="ruleForm.icon_img_list"
                                            v-on:getuploadfile="(data) => getUploadFileEvent(data, 'icon_img')"
                                            v-on:deluploadfile="(data) => deluploadfileEvent(data, 'icon_img')">
                                        </mainimgesup>
                                    </el-form-item>
                                    <el-form-item prop="back_img" :rules="rules.back_img" style="margin-top: 20px;">
                                        <mainimgesup :model="ruleForm.back_img" :myimgesup="myimgesup1"
                                            :myimgesupisshow="myimgesupisshow" :filelist="ruleForm.back_img_list"
                                            v-on:getuploadfile="(data) => getUploadFileEvent(data, 'back_img')"
                                            v-on:deluploadfile="(data) => deluploadfileEvent(data, 'back_img')">
                                        </mainimgesup>
                                    </el-form-item>
                                </el-form-item>
                                <el-form-item label="导航标题：" prop="title">
                                    <el-input v-model="ruleForm.title" placeholder="请输入标题" clearable show-word-limit
                                        maxlength="12"></el-input>
                                </el-form-item>
                                <el-form-item label="导航标题颜色：" prop="titlecolor">
                                    <el-input v-model="ruleForm.titlecolor" placeholder="请输入标题颜色，eg: #000000" clearable>
                                    </el-input>
                                </el-form-item>
                                <el-form-item label="类型：" prop="type">
                                    <el-radio-group v-model="ruleForm.type">
                                        <el-radio label="1">分类</el-radio>
                                        <el-radio label="2">专题</el-radio>
                                    </el-radio-group>
                                </el-form-item>
                                <el-form-item label="更多跳转分类：" v-if="ruleForm.type === '1'">
                                    <el-input v-model="ruleForm.link_url" placeholder="请输入跳转分类ID"></el-input>
                                </el-form-item>
                                <el-form-item label="排序：" prop="sort">
                                    <el-input v-model="ruleForm.sort" placeholder="数字越大排序越高" clearable></el-input>
                                </el-form-item>
                                <el-form-item label="是否显示：" prop="is_show">
                                    <el-radio-group v-model="ruleForm.is_show">
                                        <el-radio label="1">是</el-radio>
                                        <el-radio label="2">否</el-radio>
                                    </el-radio-group>
                                </el-form-item>
                                <el-form-item>
                                    <el-button type="primary" @click="submitForm('ruleForm')"
                                        style="width: 40%; margin-right: 10px">提交
                                    </el-button>
                                    <el-button @click="myresetForm">取消</el-button>
                                </el-form-item>
                            </el-form>
                        </el-col>
                        <!--占位符-->
                        <el-col :span="24" :xs="24" :sm="24" :lg="8">
                            <div style="width: 100%"></div>
                        </el-col>
                    </el-row>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import mainone from "@/components/Mainone";
import mainimgesup from "@/components/Mainimgesup.vue";
import { ElMessage } from "element-plus";
import { ref } from "vue";
import axios from "axios";

export default {
    name: "vrecpositionadd",
    data() {
        return {
            mainonebread: [
                {
                    name: "教育",
                },
                {
                    name: "文档管理",
                },
                {
                    name: "推荐位管理",
                    path: "/recommendposition",
                },
                {
                    name: "添加导航",
                },
            ], // 当前页-【面包屑导航】

            myimgesupisshow: true, //form表单-imgesuploade上传图片
            myimgesup: {
                uploadsize: 1, //选择上传图片的展现框-1表示小框，2表示中等框，3表示大框
                listtype: "picture", //list展示类型，text/picture/picture-card；默认不填为text
                ismust: true, //是否必填，true表示必填
                action: "/manage/upload/file11", //upload上传API地址
                headers: { 'Auth': localStorage.getItem("token") }, //upload上传headers
                title: "上传图标", //button-按钮文字
                tips: "建议尺寸：46px * 46px", //提示&建议文字
                limit: "1", //上传数量限制
                target_id: 100, //上传文件target_id ：图片分类，区分存储文件和存储时长，不传默认存储1天后过期；1-二维码； 2-打印文件； 3-文库文件； 4-课程文件； 5-广告主宣传页； 6-广告主产生的pdf文件； 7-证件照文件； 50-设备广告； 100-通用
                rules: {
                    images: {
                        image_width: 0, //图片宽度，0表示不限宽
                        image_height: 0, //图片高度，0表示不限高
                        size: 20000, //文件大小（单位kb）
                        type: [
                            "image/jpeg",
                            "image/jpg",
                            "image/png",
                        ], //文件格式
                        typechart: "png/jpg/jpeg", //文件格式提示规则
                    },
                },
            },
            myimgesup1: {
                uploadsize: 1, //选择上传图片的展现框-1表示小框，2表示中等框，3表示大框
                listtype: "picture", //list展示类型，text/picture/picture-card；默认不填为text
                ismust: true, //是否必填，true表示必填
                action: "/manage/upload/file11", //upload上传API地址
                headers: { 'Auth': localStorage.getItem("token") }, //upload上传headers
                title: "上传背景图", //button-按钮文字
                tips: "建议尺寸：690px * 454px", //提示&建议文字
                limit: "1", //上传数量限制
                target_id: 100, //上传文件target_id ：图片分类，区分存储文件和存储时长，不传默认存储1天后过期；1-二维码； 2-打印文件； 3-文库文件； 4-课程文件； 5-广告主宣传页； 6-广告主产生的pdf文件； 7-证件照文件； 50-设备广告； 100-通用
                rules: {
                    images: {
                        image_width: 0, //图片宽度，0表示不限宽
                        image_height: 0, //图片高度，0表示不限高
                        size: 20000, //文件大小（单位kb）
                        type: [
                            "image/jpeg",
                            "image/jpg",
                            "image/png",
                        ], //文件格式
                        typechart: "png/jpg/jpeg", //文件格式提示规则
                    },
                },
            },
            
            ruleForm: {
                icon_img: ref(""),
                icon_img_list: [],
                back_img: ref(""),
                back_img_list: [],
                title: "",
                titlecolor: "",
                type: ref("1"),
                link_url: '',
                sort: "",
                is_show: ref("1"),
            }, //form表单
            rules: {
                icon_img: [
                    {
                        required: true,
                        message: "导航图标不能为空",
                        trigger: "change"
                    },
                ],
                back_img: [
                    {
                        required: true,
                        message: "导航背景图不能为空",
                        trigger: "change"
                    },
                ],
                title: [
                    {
                        required: true,
                        message: "导航标题不能为空",
                        trigger: "change",
                    },
                ],
                titlecolor: [
                    {
                        required: true,
                        message: "导航标题颜色不能为空",
                        trigger: "blur",
                    },
                ],
                type: [
                    {
                        required: true,
                        message: "类型不能为空",
                        trigger: "blur",
                    },
                ],
                sort: [
                    {
                        required: true,
                        message: "排序不能为空",
                        trigger: "blur",
                    },
                ],
                is_show: [
                    {
                        required: true,
                        message: "是否显示为必选项",
                        trigger: "blur",
                    },
                ],
            }, //form表单-必填规则

            mypowerlimits: JSON.parse(localStorage.getItem("powerlimits")), //获取权限
            mytoken: localStorage.getItem("token"), //获取存储的token
        };
    },
    methods: {
        // 取消-返回上一页
        myresetForm() {
            this.$router.go(-1); // 返回上一页
        },
        //获取upload子组件回传的数据
        getUploadFileEvent(data, flag) {
            console.log("当前获取的回传数据：", data, flag);

            if (flag == 'icon_img') {
                this.ruleForm.icon_img = data.url;
                this.ruleForm.icon_img_list = [{ url: data.url, name: data.name }];
                //特殊的内容再次进行了校验，如果符合检验规则，错误提示就是消失了。
                this.$refs.ruleForm.validateField('icon_img', (valid) => {
                    if (!valid) { return }
                })
            }
            if (flag == 'back_img') {
                this.ruleForm.back_img = data.url;
                this.ruleForm.back_img_list = [{ url: data.url, name: data.name }];
                //特殊的内容再次进行了校验，如果符合检验规则，错误提示就是消失了。
                this.$refs.ruleForm.validateField('back_img', (valid) => {
                    if (!valid) { return }
                })
            }
        },
        // 删除上传的文件-事件（upload组件）
        deluploadfileEvent(data, flag) {
            console.log("当前删除事件-返回的数据：", data, flag);
            if (flag == 'icon_img') {
                this.ruleForm.icon_img = '';
                this.ruleForm.icon_img_list = [];
                //特殊的内容再次进行了校验，如果符合检验规则，错误提示就是消失了。
                this.$refs.ruleForm.validateField('icon_img', (valid) => {
                    if (!valid) { return }
                })
            }
            if (flag == 'back_img') {
                this.ruleForm.back_img = '';
                this.ruleForm.back_img_list = [];
                //特殊的内容再次进行了校验，如果符合检验规则，错误提示就是消失了。
                this.$refs.ruleForm.validateField('back_img', (valid) => {
                    if (!valid) { return }
                })
            }
        },
        // 提交
        submitForm(formName) {
            var getdatas = this.$refs[formName].model;
            console.log("提交的数据集：", getdatas);
            this.$refs[formName].validate((valid) => {
                if (valid) {
                    axios
                        .post(
                            "/manage/Diy/addRecommend",
                            {
                                icon_img: getdatas.icon_img,
                                icon_img_list: getdatas.icon_img_list,
                                back_img: getdatas.back_img,
                                back_img_list: getdatas.back_img_list,
                                title: getdatas.title,
                                titlecolor: getdatas.titlecolor,
                                type: getdatas.type,
                                link_url: getdatas.link_url,
                                sort: getdatas.sort,
                                is_show: getdatas.is_show,
                            },
                            {
                                headers: {
                                    Auth: localStorage.getItem("token"),
                                },
                            }
                        )
                        .then((response) => {
                            if (response.data.code === 0) {
                                ElMessage({
                                    type: "success",
                                    message: "提交成功！",
                                });
                                this.$router.push({ path: "/recommendposition" })
                            }
                        })
                        .catch(function (error) {
                            // 请求失败处理
                            console.log(error);
                            // loading.close();
                        });
                } else {
                    console.log("error submit!!");
                    return false;
                }
            });
        },
    },
    created() {
        if (localStorage.getItem("token") === null || !localStorage.getItem("token")) {
            this.isshow = false;
            this.$router.push("/").catch((error) => error);
        }
    },
    components: {
        mainone,
        mainimgesup,
    },
};
</script>

<style scoped>
.box {
    background-color: #ffffff;
    border-radius: 4px;
    color: #333333;
    padding: 10px 20px;
}

.box_title {
    line-height: 40px;
    height: 40px;
}

.box_title_font {
    float: left;
    font-weight: 400;
    font-size: 14px;
    color: #333333;
}

.box_title_button {
    float: right;
    font-weight: 400;
    font-size: 14px;
    color: #94a3d2;
}

.box_title_button:hover {
    cursor: pointer;
}

.box_content {
    border-top: 1px solid #eeeeee;
    margin-top: 10px;
}
</style>