<template>
    <div>
        <mainone :mainonebread="mainonebread"></mainone>

        <el-tabs v-model="activetname" class="demo-tabs" @tab-click="handleTabClick">
            <el-tab-pane :label="myitem.title" v-for="(myitem,myindex) in this.mytabsinfo" :key="myindex"
                :name="myitem.actname">
                <!-- 金额统计 -->
                <div class="money-box">
                    <div class="money-box-content" v-for="(item,index) in this.moneydatas" :key="index">
                        <div class="money-box-content-padding">
                            <div class="content-padding-title">
                                <span class="content-padding-title-left">{{item.title}}</span>
                                <span class="content-padding-title-right" v-if="item.until">{{item.until}}</span>
                            </div>
                            <div class="content-padding-money">
                                <span>¥ {{item.value}}</span>
                            </div>
                        </div>
                    </div>
                </div>
                <!-- 搜索框 -->
                <maintwo :myselectleftisshow="maintwoforminfo.selectleftisshow"
                    :myselectleft="maintwoforminfo.selectleft"
                    v-on:getsearchinfo="(data)=>getSeachinfoEvent(data,this.activetname)"></maintwo>
                <!--table表单-->
                <div>
                    <!--主list列表-->
                    <div class="table-list">
                        <div style="line-height: 60px">
                            <!--左侧名称-->
                            <div style="float: left; font-size: 14px">数据列表</div>
                            <!--右侧按钮-->
                            <div style="float: right">
                                <!--自定义按钮-点击出现弹框&点击跳转子类页面@click="routeraddrClick('/XX路由')"-->
                                <!--1、点击出现弹框@click="addTanClick(XX弹框配置信息,dialogVisible = true);resetFormSenior(XXform表单)"-->
                                <!--2、点击跳转子类页面@click="routeraddrClick('/XX路由')-->
                                <!--<el-button ></el-button>-->
                                <!--添加XX按钮-点击跳转添加类页面-->
                                <el-button type="primary" plain class="max_search" v-if="mypowerlimits.gsll_zjmx_xzzrzc"
                                    @click="addTanClick((dialogVisible = true))">
                                    新增转出/转入
                                </el-button>
                            </div>
                        </div>

                        <div>
                            <!--table表单-->
                            <el-table ref="multipleTable" :row-class-name="tableRowClassName" :data=" this.activetname == 'wechat' ?
                              this.mainthreetableData.slice(
                                (currentPage_wechat - lastpage_wechat) * PageSize_wechat,
                                currentPage_wechat * PageSize_wechat
                              ) :
                              this.mainthreetableData.slice(
                                (currentPage_alipay - lastpage_alipay) * PageSize_alipay,
                                currentPage_alipay * PageSize_alipay
                              )
                            " style="width: 100%" min-height="900" border v-loading="loading"
                                element-loading-text="正在飞速加载中..." @selection-change="handleSelectionChange"
                                @cell-click="handleOneData">
                                <!--多选框-用于用户选择性导出数据-->
                                <el-table-column type="selection" width="55" v-if="mainthreeisshowset" />
                                <el-table-column v-for="(mytabletitle, i) in mainthreetabletitle" :key="i"
                                    :fixed="mytabletitle.fixedstatu" :prop="mytabletitle.prop"
                                    :label="mytabletitle.label" :width="mytabletitle.width">
                                    <!--这里先通过table列表中的title值进行判断，在进行渲染展示-->
                                    <!--例如：status状态字段，判断最终的值是那种表现形式等-->
                                    <template #default="scope" v-if="
                                    mytabletitle.prop === 'balance' || 
                                    mytabletitle.prop === 'money' || 
                                    mytabletitle.prop === 'type' || 
                                    mytabletitle.prop == 'addtime'">
                                        <!-- 账户余额 -->
                                        <div v-if="mytabletitle.prop === 'balance'">
                                            {{(scope.row.balance / 100).toFixed(2)}}
                                        </div>
                                        <!-- 出/入账金额 -->
                                        <div v-if="mytabletitle.prop === 'money'"
                                            :style="scope.row.type == 1 ? {'color':'var(--el-color-warning)'} : ''">
                                            {{ scope.row.type == 1 ? '+'+(scope.row.money / 100).toFixed(2) :
                                            (scope.row.money / 100).toFixed(2)}}
                                        </div>
                                        <!-- 出/入账类型-->
                                        <div v-if="mytabletitle.prop === 'type'"
                                            :style="scope.row.type == 1 ? {'color':'var(--el-color-warning)'} : ''">
                                            {{scope.row.type == 1 ? '入账' : '出账'}}
                                        </div>
                                        <!-- 操作时间 -->
                                        <div v-if="mytabletitle.prop === 'addtime'">
                                            <div v-if="scope.row.addtime">
                                                <span>{{ this.$utils.formatDate(scope.row.addtime)}}</span>
                                            </div>
                                        </div>
                                    </template>
                                </el-table-column>
                            </el-table>
                            <!--分页器-->
                            <div class="block" style="text-align: center; margin: 30px 0">
                                <el-pagination background
                                    @size-change="(data) => handleSizeChange(data,this.activetname)"
                                    @current-change="(data)=>handleCurrentChange(data,this.activetname)"
                                    :current-page="this.activetname =='wechat' ? currentPage_wechat: currentPage_alipay"
                                    :page-sizes="pageSizes"
                                    :page-size="this.activetname =='wechat' ? PageSize_wechat : PageSize_alipay"
                                    layout="total, sizes, prev, pager, next, jumper"
                                    :total="this.activetname =='wechat' ? totalCount_wechat : totalCount_alipay ">
                                </el-pagination>
                            </div>
                        </div>
                    </div>
                </div>
            </el-tab-pane>
        </el-tabs>

        <!-- 弹窗 -->
        <el-dialog v-model="dialogVisible" title="新增转出/入账" :width="this.searchwidth" :before-close="handleCloseSenior">
            <el-form ref="ruleForm" :model="ruleForm" :rules="rules" label-width="120px" class="demo-ruleForm">
                <div class="tan-form-type">
                    <el-form-item label="选择类型：" prop="type">
                        <el-radio-group v-model="ruleForm.type">
                            <el-radio label="1">转入</el-radio>
                            <el-radio label="2">转出</el-radio>
                        </el-radio-group>
                    </el-form-item>
                    <el-form-item label="输入金额：" prop="money">
                        <el-input v-model="ruleForm.money" placeholder="请输入金额" clearable>
                        </el-input>
                    </el-form-item>
                    <el-form-item label="备注：" prop="remark">
                        <el-input v-model="ruleForm.remark" :autosize="{ minRows: 2 }" maxlength="100" show-word-limit
                            type="textarea" placeholder="请填写备注" clearable>
                        </el-input>
                    </el-form-item>
                    <el-form-item>
                        <div style="text-align: right">
                            <el-button @click="resetFormSenior((dialogVisible = false))">取消</el-button>
                            <el-button type="primary" @click="onSubmitSenior('ruleForm')">确定</el-button>
                        </div>
                    </el-form-item>
                </div>
            </el-form>
        </el-dialog>


    </div>
</template>
  
<script>
import mainone from "@/components/Mainone";
import maintwo from "@/components/Maintwo";
import { ElMessage } from "element-plus";
import axios from "axios";
import { defineComponent, ref } from "vue";

export default defineComponent({
    name: "vcompanymoneydetails",
    inject: ["reload"],
    data() {
        // 数字input框-校验（可小数）【金额相关配置】
        var checkInputEvent = (rule, value, callback) => {
            if (!value) {
                return callback(new Error("必填项不能为空"));
            }
            if (value.toString().indexOf(" ") != -1) {
                return callback(new Error("值中不能有空格"));
            }
            if (value.toString().length > 1 && value.toString().slice(0, 1) == 0) {
                return callback(new Error("首位数字不能为0"));
            }
            setTimeout(() => {
                // 判断字符串是否为文字
                if (isNaN(value)) {
                    callback(new Error("请输入数字"));
                } else {
                    let result = value.toString().indexOf(".");
                    if (result != -1) {
                        let getdata = value.toString().split(".");
                        if (getdata[getdata.length - 1].length > 2) {
                            callback(new Error("请保留小数点后两位"));
                        }
                    }
                    if (value < 0) {
                        callback(new Error("值需大于0"));
                    } else {
                        callback();
                    }
                }
            }, 500);
        };
        return {
            mainonebread: [
                {
                    name: "租户管理",
                },
                {
                    name: "租户管理",
                },
                {
                    name: "公司列表",
                    path: "/companylist",
                },
                {
                    name: "资金明细",
                    // path: "/companymoneydetails",
                },
            ], // 当前页-【面包屑导航】

            // 选中-tabs
            activetname: 'wechat',
            // tabs
            mytabsinfo: [
                {
                    title: '微信',
                    actname: 'wechat'
                },
                {
                    title: '支付宝',
                    actname: 'alipay'
                }
            ],

            // money-统计信息
            moneydatas: [
                {
                    title: '账户余额',
                    value: '0.00'
                },
                {
                    title: '昨日转出',
                    value: '0.00'
                },
                {
                    title: '累计转出',
                    until: '全部',
                    value: '0.00'
                },
                {
                    title: '累计转入',
                    until: '全部',
                    value: '0.00'
                },
            ],

            maintwoiconshow: false, // 筛选-【高级筛选按钮】
            maintwoforminfo: {
                selectleftisshow: true, //筛选查询-左侧字+select单框||select时间
                selectleft: {
                    width: "none",
                    divinterval: "", //框右侧距离
                    selectwidth: "200px",
                    selectinterval: "20px", //select右侧距离
                    select: [
                        {
                            selectisshow: true,
                            ismust: false,
                            title: "出/入账类型：",
                            placeholder: "全部",
                            name: "type",
                            value: "",
                            options: [
                                {
                                    label: "全部",
                                    value: "0",
                                },
                                {
                                    label: "入账",
                                    value: "1",
                                },
                                {
                                    label: "出账",
                                    value: "-1",
                                },
                            ],
                        },
                        {
                            selecttimeduanisshow: true, //该字段表示时间段模块状态：且已写死（字段名不可修改!）
                            ismust: false,
                            title: "统计时间：",
                            placeholder: {
                                placeholderstart: "请选择开始时间",
                                placeholderend: "请选择结束时间",
                            },
                            name: "addtime",
                            value: {
                                valuestart: ref(""),
                                valueend: ref(""),
                                endstatus: true,
                            },
                            rangeseparator: "至", //分隔符
                            divinterval: "3%", //上方字中-框右侧距离
                            selectwidth: "100%", //上方字中-总框长度
                            selectmaxwidth: "97%", //时间段-最大宽度
                            marginright: "0", //与右侧距离
                        },
                    ],
                },
            }, // 筛选-控件信息
            mainthreeisshowset: false, // 数据列表中-复选框
            mainthreeisshowope: true, // 数据列表中-操作-根据页面显示模块（整页）
            mainthreetableope: 2, // 数据列表中-操作-根据页面显示控件（整页）
            mainthreehandle: 320,
            mainthreetabletitle: [
                {
                    fixedstatu: "left",
                    prop: "time",
                    label: "日期",
                    width: "",
                },
                {
                    fixedstatu: false,
                    prop: "balance",
                    label: "账户余额",
                    width: "",
                },
                {
                    fixedstatu: false,
                    prop: "money",
                    label: "出/入账金额",
                    width: "",
                },
                {
                    fixedstatu: false,
                    prop: "type",
                    label: "出/入账类型",
                    width: "",
                },
                {
                    fixedstatu: false,
                    prop: "truename",
                    label: "操作人",
                    width: "",
                },
                {
                    fixedstatu: false,
                    prop: "addtime",
                    label: "操作时间",
                    width: "",
                },
                {
                    fixedstatu: false,
                    prop: "remark",
                    label: "备注",
                    width: "",
                },
            ], // 数据列表中-数据表头
            mainthreestatus: true, // 数据列表中-属于search按钮表示状态
            mainthreetableData: [], // 数据列表中-具体数据
            totalCount_wechat: 1, //数据列表中-数据总条数
            currentpage_wechat: 1, //数据列表中-数据当前页数
            lastpage_wechat: 1, //数据列表中-数据总页数（默认以20条一页）
            totalCount_alipay: 1, //数据列表中-数据总条数
            currentpage_alipay: 1, //数据列表中-数据当前页数
            lastpage_alipay: 1, //数据列表中-数据总页数（默认以20条一页）
            loading: false, //初始化-loading加载动效，默认是false关闭


            //table表单相关数据
            seniorform: {},
            currentPage_wechat: 1,
            currentPage_alipay: 1,
            pageSizes: [20, 50, 100],
            PageSize_wechat: 20,
            PageSize_alipay: 20,
            multipleSelection: [],
            thispagename: "", //通过路由获取当前页面的path值
            searchwidth: "580px",
            dialogVisible: ref(false), //弹窗控制状态
            ruleForm: {
                changetabs: 'wechat',
                type: '1',
                money: '',
                remark: '',
            },
            rules: {
                type: [
                    {
                        required: true,
                        message: '类型为必选项',
                        trigger: 'blur',
                    },
                ],
                money: [
                    {
                        required: true,
                        validator: checkInputEvent,
                        trigger: 'blur',
                    },
                ],
                remark: [
                    {
                        required: false,
                        message: '备注不能为空',
                        trigger: 'blur',
                    },
                ],
            },

            // 租户id
            companyid: '',

            mypowerlimits: JSON.parse(localStorage.getItem("powerlimits")), //获取权限
            mytoken: localStorage.getItem("token"), //获取存储的token
            pagesdatas: [], //存储最近一次的筛选条件，供分页使用


            pagesdatas_wechat: {},
            pagesdatas_alipay: {},
        };
    },
    mounted() {
        //获取当前页面路由path值
        this.thispagename = this.$route.path;
    },
    methods: {
        // 点击tabs-切换
        handleTabClick(data) {
            // 更新选中的tab状态
            this.activetname = data.props.name;
            // 更新金额
            this.getmoneydataEvent();
            // 搜索框置空
            this.maintwoforminfo.selectleft.select[0].value = '';
            this.maintwoforminfo.selectleft.select[1].value = {
                valuestart: ref(""),
                valueend: ref(""),
                endstatus: true,
            };
            // 加载数据
            this.getdatasEvent();

        },

        //点击按钮-弹出弹框并赋值弹窗基本配置
        addTanClick() {
            // 重置弹窗form表单数据
            this.ruleForm = {
                changetabs: this.activetname,
                type: ref('1'),
                money: '',
                remark: '',
            };
        },
        //获取当前windows页面信息-实时修改【弹窗】的宽度
        getwindowinfo() {
            this.sreenheight = document.documentElement.clientHeight;
            this.sreenwidth = document.documentElement.clientWidth;
            if (this.sreenwidth < 600) {
                this.searchwidth = this.sreenwidth * 0.8 + "px";
            } else {
                this.searchwidth = "580px";
            }
        },
        // 弹窗-提交form表单
        onSubmitSenior(formName) {
            var getdatas = this.$refs[formName].model;
            this.$refs[formName].validate((valid) => {
                if (valid) {
                    // 提交form表单
                    axios
                        .post("/manage/Manage/balanceAction",
                            {
                                thirdparty: getdatas.changetabs == 'wechat' ? 1 : 2,
                                companyid: this.companyid,
                                type: getdatas.type,
                                money: getdatas.money,
                                remark: getdatas.remark,
                            },
                            {
                                headers: {
                                    Auth: localStorage.getItem("token"),
                                },
                            }
                        )
                        .then((response) => {
                            if (response.data.code === 0) {
                                this.dialogVisible = ref(false);
                                ElMessage({
                                    type: "success",
                                    message: '提交成功',
                                    duration: 1000,
                                    onClose: () => {
                                        // 加载金额汇总数据
                                        this.getmoneydataEvent();
                                        // 加载初始页面数据
                                        this.getdatasEvent();
                                    },
                                });
                            }
                        })
                        .catch(function (error) {
                            console.log(error);
                        });
                } else {
                    return false;
                }
            });
        },

        //table表中-当选项发生变化是触发该函数
        handleSelectionChange(val) {
            console.log("this is:" + JSON.stringify(val));
            this.multipleSelection = val;
        },
        //分页器中-当选择条数时触发该函数
        handleSizeChange(val, flag) {
            console.log(`每页 ${val} 条`);
            if (flag == 'wechat') {
                this.PageSize_wechat = val;
                this.currentPage_wechat = 1;
            } else {
                this.PageSize_alipay = val;
                this.currentPage_alipay = 1;
            }

            //加载loading
            this.loading = true;
            //通过Ajax向后台获取数据
            axios
                .get("/manage/Manage/balanceLog", {
                    headers: {
                        Auth: localStorage.getItem("token"),
                    },
                    params: {
                        thirdparty: this.activetname == 'wechat' ? 1 : 2,
                        companyid: this.companyid,
                        type: this.activetname == 'wechat' ? this.pagesdatas_wechat.type : this.pagesdatas_alipay.type,
                        addtime: this.activetname == 'wechat' ? this.pagesdatas_wechat.addtime : this.pagesdatas_alipay.addtime,
                        page: 1,
                        page_size: val,
                    },
                })
                .then((response) => {
                    this.myaxiosfunc(response.data.result, flag);
                })
                .catch(function (error) {
                    console.log(error);
                });
        },
        //分页器中-当选择分页时触发该函数
        handleCurrentChange(val, flag) {
            // console.log(`当前页: 第${val}页`);
            if (flag == 'wechat') {
                this.currentPage_wechat = val;
            } else {
                this.currentPage_alipay = val;
            }

            //加载loading
            this.loading = true;
            //通过Ajax向后台获取数据
            axios
                .get("/manage/Manage/balanceLog", {
                    headers: {
                        Auth: localStorage.getItem("token"),
                    },
                    params: {
                        thirdparty: this.activetname == 'wechat' ? 1 : 2,
                        companyid: this.companyid,
                        type: this.activetname == 'wechat' ? this.pagesdatas_wechat.type : this.pagesdatas_alipay.type,
                        addtime: this.activetname == 'wechat' ? this.pagesdatas_wechat.addtime : this.pagesdatas_alipay.addtime,
                        page: val,
                        page_size: this.activetname == 'wechat' ? this.PageSize_wechat : this.PageSize_alipay,
                    },
                })
                .then((response) => {
                    this.myaxiosfunc(response.data.result, flag);
                })
                .catch(function (error) {
                    console.log(error);
                });
        },

        //提交等相关event事件函数
        //获取Container-Pagedata-Maintwo-搜索-返回的数据
        getSeachinfoEvent(data, flag) {
            console.log("点击查询获取的data数组：" + JSON.stringify(data));

            //判断-版本更新时间
            var getaddtime = null;
            if (data.addtime.start == "") {
                getaddtime = "";
            } else {
                if (data.addtime.end == "") {
                    getaddtime = data.addtime.start + " ~ " + (data.addtime.start + 86400 - 1);
                } else {
                    getaddtime = data.addtime.start + " ~ " + (data.addtime.end + 86400 - 1);
                }
            }

            if (flag == 'wechat') {
                this.pagesdatas_wechat = data; //将筛选条件赋值给本页的pagesdatas存储，供分页使用
                this.pagesdatas_wechat.addtime = getaddtime;
            } else {
                this.pagesdatas_alipay = data; //将筛选条件赋值给本页的pagesdatas存储，供分页使用
                this.pagesdatas_alipay.addtime = getaddtime;
            }

            //加载loading
            this.loading = true;
            //通过Ajax向后台获取数据
            axios
                .get("/manage/Manage/balanceLog", {
                    headers: {
                        Auth: localStorage.getItem("token"),
                    },
                    params: {
                        thirdparty: this.activetname == 'wechat' ? 1 : 2,
                        companyid: this.companyid,
                        type: data.type,
                        addtime: getaddtime,
                        page: 1,
                        page_size: this.activetname == 'wechat' ? this.PageSize_wechat : this.PageSize_alipay,
                    },
                })
                .then((response) => {
                    this.myaxiosfunc(response.data.result);
                })
                .catch(function (error) {
                    console.log(error);
                });
        },
        //数据渲染
        myaxiosfunc(successdata, flag) {
            var newdata = successdata.data;
            // console.log('当前页面的data:' + JSON.stringify(newdata))
            this.mainthreetableData = newdata;

            if (flag == 'wechat') {
                this.totalCount_wechat = successdata.total;
                this.currentpage_wechat = successdata.current_page; //当前页面页数
                this.lastpage_wechat = successdata.last_page;
            } else {
                this.totalCount_alipay = successdata.total;
                this.currentpage_alipay = successdata.current_page; //当前页面页数
                this.lastpage_alipay = successdata.last_page;
            }
            this.loading = false;
        },

        // 加载
        getdatasEvent() {
            //加载loading
            this.loading = true; //通过Ajax向后台获取数据
            //通过Ajax向后台获取数据
            axios
                .get("/manage/Manage/balanceLog", {
                    headers: {
                        Auth: localStorage.getItem("token"),
                    },
                    params: {
                        thirdparty: this.activetname == 'wechat' ? 1 : 2,
                        companyid: this.companyid,
                        page: 1,
                        page_size: this.activetname == 'wechat' ? this.PageSize_wechat : this.PageSize_alipay,
                    },
                })
                .then((response) => {
                    this.myaxiosfunc(response.data.result, this.activetname);
                })
                .catch(function (error) {
                    console.log(error);
                });
        },

        // 余额的金额汇总
        getmoneydataEvent() {
            //通过Ajax向后台获取数据
            axios
                .get("/manage/Manage/balance", {
                    headers: {
                        Auth: localStorage.getItem("token"),
                    },
                    params: {
                        thirdparty: this.activetname == 'wechat' ? 1 : 2,
                        companyid: this.companyid,
                    },
                })
                .then((response) => {
                    if (response.data.code == 0) {
                        var getdatas = response.data.result;
                        this.moneydatas[0].value = (getdatas.balance / 100).toFixed(2);
                        this.moneydatas[1].value = (getdatas.yesterday_money / 100).toFixed(2);
                        this.moneydatas[2].value = (getdatas.out_total_money / 100).toFixed(2);
                        this.moneydatas[3].value = (getdatas.in_total_money / 100).toFixed(2);
                    } else {
                        this.moneydatas[0].value = (0).toFixed(2);
                        this.moneydatas[1].value = (0).toFixed(2);
                        this.moneydatas[2].value = (0).toFixed(2);
                        this.moneydatas[3].value = (0).toFixed(2);
                    }
                })
                .catch(function (error) {
                    console.log(error);
                });
        }
    },
    created() {
        if (localStorage.getItem("token") === null || !localStorage.getItem("token")) {
            this.isshow = false;
            this.$router.push("/").catch((error) => error);
        } else {
            window.addEventListener("resize", this.getwindowinfo); // 注册监听器-实时检测浏览器页面宽高
            this.getwindowinfo();

            // 获取租户id
            this.companyid = this.$route.query.id;

            // 加载金额汇总数据
            this.getmoneydataEvent();
            // 加载初始页面数据
            this.getdatasEvent();

        }
    },
    components: {
        mainone,
        maintwo,
    },
});
</script>
<style scoped>
/* box */
.money-box {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    background-color: #ffffff;
    margin-bottom: 10px;
    border-radius: 4px;
}

.money-box-content {
    min-height: 130px;
    padding: 20px;
    flex-grow: 2;
    min-width: 300px;
}

.money-box-content-padding {
    padding: 20px;
    background-color: #f2f2f2;
    border-radius: 10px;
}

.content-padding-title {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

.content-padding-title-left {
    font-size: 16px;
}

.content-padding-title-right {
    font-size: 12px;
}

.content-padding-money {
    line-height: 40px;
    font-size: 28px;
    font-weight: bold;
    margin: 15px 0;
}

/* table */
.table-list {
    margin-top: 10px;
    background-color: #ffffff;
    border-radius: 4px;
    line-height: 40px;
    padding: 0 20px;
}

/* tabs */
.demo-tabs {
    background-color: #f2f2f2;
    padding: 10px;
    border-radius: 4px;
}

/* 弹窗 */
.tan-form-type {
    max-height: 450px;
    overflow: hidden;
    overflow-y: visible;
    margin: 20px 5% 0 0
}
</style>