<template>
    <div>
        <el-button :type="button.type" :plain="button.plain" :size="button.size" @click="tanClickEvent()">{{ button.text }}</el-button>
        <!-- 弹窗 -->
        <el-dialog v-model="tanbox.isshow" :title="dialog.title" :width="dialog.width" append-to-body :before-close="(done) => handleClose(done, 'tanTextRef')">
            <div>
                <el-form ref="tanTextRef" :model="tanText" :rules="tanText_rules">
                    <el-form-item prop="only_keys" label="">
                        <div style="margin: 10px 0; line-height: 32px">
                            <span>{{ dialog.note }}</span>
                            <br />
                            <span>{{ dialog.title }}:</span>
                        </div>
                        <el-input v-model="tanText.only_keys" type="textarea" :autosize="{ minRows: 10, maxRows: 60 }" clearable placeholder="请输入设备唯一标识码"></el-input>
                    </el-form-item>
                </el-form>
            </div>
            <div style="text-align: right; margin: 20px 0">
                <el-button @click="cancelEvent('tanTextRef')">取消</el-button>
                <el-button type="primary" @click="submitEventText('tanTextRef')">确定</el-button>
            </div>
        </el-dialog>
    </div>
</template>

<script>
import { defineComponent } from 'vue'

export default defineComponent({
    name: 'textEnter',
    props: {
        button: {
            type: Object,
            default: () => {
                return {
                    text: '文本录入',
                    size: 'small',
                    type: 'primary',
                    plain: false
                }
            }
        },
        dialog: {
            type: Object,
            default: () => {
                return {
                    title: '文本录入',
                    width: '30%',
                    note: '注：使用扫码枪，鼠标点击下方区域，输入法切换成英文，换行是下一台设备。'
                }
            }
        },
        vmodel: {
            type: Object,
            default: () => {
                return {}
            }
        }
    },
    data() {
        return {
            tanbox: {
                isshow: false
            },
            tanText: {
                only_keys: ''
            },
            tanText_rules: {
                only_keys: [
                    {
                        required: true,
                        message: '内容不能为空',
                        trigger: 'blur'
                    }
                ]
            }
        }
    },
    methods: {
        tanClickEvent() {
            this.tanbox.isshow = true
            if (Object.keys(this.vmodel).length != 0) {
                this.tanText.only_keys = this.vmodel
                    .map((item) => {
                        return `${item.only_key}\n`
                    })
                    .join('')
            } else {
                this.tanText.only_keys = ''
            }
        },
        submitEventText(formName) {
            let getdatas = this.tanText
            this.$refs[formName].validate((valid) => {
                if (valid) {
                    let new_array = getdatas.only_keys.split('\n')
                    new_array = new_array.filter((value) => value !== '' && value !== null && value !== undefined)
                    let uniqueArray = [...new Set(new_array)]
                    let goods_num = uniqueArray.length
                    let goods_info = uniqueArray.map((n) => {
                        n = n.replace(/[^a-zA-Z0-9\n]/g, '').replace(/^\s*\n/gm, '')
                        return { only_key: n }
                    })
                    this.$emit('tanPostData', { goods_num: goods_num, goods_info: goods_info })
                    this.tanbox.isshow = false
                } else {
                    return false
                }
            })
        },
        cancelEvent(formName) {
            this.$refs[formName].resetFields()
            this.tanbox.isshow = false
        },
        handleClose(done, formName) {
            this.cancelEvent(formName)
            done()
        }
    }
})
</script>

<style scoped></style>
