<template>
  <div>
    <mainone :mainonebread="mainonebread"></mainone>
    <maintwo
      :maintwoiconshow="maintwoiconshow"
      :maintwoforminfo="maintwoforminfo"
      :myinputoneisshow="maintwoforminfo.myinputoneisshow"
      :myinputone="maintwoforminfo.myinputone"
      :myselectleftisshow="maintwoforminfo.selectleftisshow"
      :myselectleft="maintwoforminfo.selectleft"
      :myinputishow="myinputishow"
      :myinput="myinput"
      :myinputonefullisshow="maintwoforminfo.myinputonefullisshow"
      :myinputonefull="maintwoforminfo.myinputonefull"
      :myinputonefullgaoisshow="myinputonefullgaoisshow"
      :myinputonefullgao="myinputonefullgao"
      :myselectisshow="myselectisshow"
      :myselect="myselect"
      :myselectcityisshow="myselectcityisshow"
      :myselectcity="myselectcity"
      v-on:getsearchinfo="getSeachinfoEvent"
      v-on:searchForm="searchFormEvent"
    ></maintwo>
    <!--table表单-->
    <div>
      <!--主list列表-->
      <div
        style="
          margin-top: 10px;
          background-color: #ffffff;
          border-radius: 4px;
          line-height: 40px;
          padding: 0 20px;
        "
      >
        <div style="line-height: 60px">
          <!--左侧名称-->
          <div style="float: left; font-size: 14px">数据列表</div>
          <!--右侧按钮-->
          <div style="float: right">
            <!--自定义按钮-点击出现弹框&点击跳转子类页面@click="routeraddrClick('/XX路由')"-->
            <!--1、点击出现弹框@click="addTanClick(XX弹框配置信息,dialogVisible = true);resetFormSenior(XXform表单)"-->
            <!--2、点击跳转子类页面@click="routeraddrClick('/XX路由')-->
            <!--<el-button ></el-button>-->

            <el-button type="primary" plain @click="refreshEvent(this.reload())"
              >刷新列表</el-button
            >
            <!-- <el-button type="primary" @click="routeraddrClick('/dvcisonline')">批量选择下载</el-button> -->
          </div>
        </div>

        <div>
          <!--table表单-->
          <el-table
            ref="multipleTable"
            :row-class-name="tableRowClassName"
            :data="
              mainthreetableData.slice(
                (currentPage - lastpage) * PageSize,
                currentPage * PageSize
              )
            "
            style="width: 100%"
            min-height="900"
            border
            v-loading="loading"
            element-loading-text="正在飞速加载中..."
            @selection-change="handleSelectionChange"
            @cell-click="handleOneData"
          >
            <!--多选框-用于用户选择性导出数据-->
            <el-table-column
              type="selection"
              width="55"
              v-if="mainthreeisshowset"
            />
            <el-table-column
              v-for="(mytabletitle, i) in mainthreetabletitle"
              :key="i"
              :fixed="mytabletitle.fixedstatu"
              :prop="mytabletitle.prop"
              :label="mytabletitle.label"
              :width="mytabletitle.width"
            >
              <!--这里先通过table列表中的title值进行判断，在进行渲染展示-->
              <!--例如：status状态字段，判断最终的值是那种表现形式等-->
              <template
                #default="scope"
                v-if="
                  mytabletitle.prop === 'status' ||
                  mytabletitle.prop === 'addtime' ||
                  mytabletitle.prop === 'endtime'
                "
              >
                <!-- 状态-样式等渲染 -->
                <div v-if="mytabletitle.prop === 'status'">
                  <span
                    v-if="scope.row.status == 3"
                    style="color: var(--el-color-primary)"
                    >{{ scope.row.extstatus }}</span
                  >
                  <span
                    v-else-if="scope.row.status == 4"
                    style="color: var(--el-color-danger)"
                    >{{ scope.row.extstatus }}</span
                  >
                  <span v-else>{{ scope.row.extstatus }}</span>
                </div>
                <!-- 发起导出时间-样式等渲染 -->
                <div v-if="mytabletitle.prop == 'addtime'">
                  <span>{{ this.$utils.formatDate(scope.row.addtime) }}</span>
                </div>
                <!-- 完成导出时间-样式等渲染 -->
                <div v-if="mytabletitle.prop === 'endtime'">
                  <span>{{ scope.row.endtime != 0 ? this.$utils.formatDate(scope.row.endtime) : ''}}</span>
                </div>
              </template>
            </el-table-column>
            <!--操作&按钮-->
            <el-table-column
              :fixed="false"
              label="操作"
              :width="mainthreehandle"
              v-if="mainthreeisshowope"
              prop="myoperation"
              id="operation"
            >
              <template #default="scope">
                <!--操作：按照页面区分，且每个按钮对应相关函数-->
                <!--操作-代理商列表-->
                <div v-if="mainthreetableope === 2">
                  <el-button
                    v-if="scope.row.status == 3"
                    type="primary"
                    size="mini"
                    @click="open_win(scope.row)"
                    >下载</el-button
                  >
                </div>
              </template>
            </el-table-column>
          </el-table>
          <!--分页器-->
          <div class="block" style="text-align: center; margin: 30px 0">
            <el-pagination
              background
              @size-change="handleSizeChange"
              @current-change="handleCurrentChange"
              :current-page="currentPage"
              :page-sizes="pageSizes"
              :page-size="PageSize"
              layout="total, sizes, prev, pager, next, jumper"
              :total="totalCount"
            >
            </el-pagination>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import mainone from "@/components/Mainone";
import maintwo from "@/components/Maintwo";
import axios from "axios";
import { defineComponent, ref } from "vue";

export default defineComponent({
  name: "vexportlist",
  inject: ["reload"], //刷新引用
  data() {
    return {
      mainonebread: [
        {
          name: "用户",
        },
        {
          name: "导出管理",
        },
        {
          name: "我的导出",
          path: "/exportlist",
        },
      ], // 当前页-【面包屑导航】
      maintwoiconshow: false, // 筛选-【高级筛选按钮】
      maintwoforminfo: {
        myinputoneisshow: true, //筛选查询-左侧字+input
        myinputone: {
          divinterval: "0", //框右侧距离
          titlewidth: "74px", //标题宽度
          inputwidth: "200px", //input宽度
          inputinterval: "20px", //input右侧距离
          input: [
            {
              ismust: false, //是否必填，true表示必填
              title: "输入搜索：",
              placeholder: "文件名称",
              name: "filename",
              content: "",
            },
          ],
        },
        selectleftisshow: true, //筛选查询-左侧字+select单框||select时间
        selectleft: {
          width: "none",
          divinterval: "", //框右侧距离
          selectwidth: "200px",
          selectinterval: "20px", //select右侧距离
          select: [
            {
              selectisshow: true,
              ismust: false,
              title: "状态：",
              placeholder: "全部",
              name: "status",
              value: "",
              options: [
                {
                  value: "0",
                  label: "全部",
                },
                {
                  value: "1",
                  label: "等待导出",
                },
                {
                  value: "2",
                  label: "正在导出",
                },
                {
                  value: "3",
                  label: "导出成功",
                },
                {
                  value: "4",
                  label: "导出失败",
                },
              ],
            },
          ],
        },
      }, // 筛选-控件信息
      mainthreeisshowset: false, // 数据列表中-复选框
      mainthreeisshowope: true, // 数据列表中-操作-根据页面显示模块（整页）
      mainthreetableope: 2, // 数据列表中-操作-根据页面显示控件（整页）
      mainthreehandle: "",
      mainthreetabletitle: [
        {
          fixedstatu: "left",
          prop: "id",
          label: "ID",
          width: "",
        },
        {
          fixedstatu: false,
          prop: "filename",
          label: "文件名",
          width: "",
        },
        {
          fixedstatu: false,
          prop: "status",
          label: "状态",
          width: "",
        },
        {
          fixedstatu: false,
          prop: "addtime",
          label: "发起导出时间",
          width: "",
        },
        {
          fixedstatu: false,
          prop: "endtime",
          label: "完成导出时间",
          width: "200",
        },
      ], // 数据列表中-数据表头
      mainthreestatus: true, // 数据列表中-属于search按钮表示状态
      mainthreetableData: [], // 数据列表中-具体数据
      totalCount: 1, //数据列表中-数据总条数
      currentpage: 1, //数据列表中-数据当前页数
      lastpage: 1, //数据列表中-数据总页数（默认以20条一页）
      loading: false, //初始化-loading加载动效，默认是false关闭

      //table表单相关数据
      seniorform: {},
      currentPage: 1,
      pageSizes: [20, 50, 100, 1000],
      PageSize: 20,
      multipleSelection: [],
      thispagename: "", //通过路由获取当前页面的path值
      searchwidth: "380px",

      //弹窗相关数据
      dialogVisible: ref(false), //弹窗显示&隐藏

      mypowerlimits: JSON.parse(localStorage.getItem("powerlimits")), //获取权限
      mytoken: localStorage.getItem("token"), //获取存储的token
      pagesdatas: [], //存储最近一次的筛选条件，供分页使用
    };
  },
  setup() {},
  mounted() {
    //获取当前页面路由path值
    this.thispagename = this.$route.path;
  },
  methods: {
    // 预览/下载文档
    open_win(data) {
      console.log(data.download);
      window.open(data.download);
    },
    //table表单-点击单位格-带参跳转等
    handleOneData(row, column, cell, event) {
      // console.log(row); //获取当前行数据
      // console.log(column); //获取当前单位格信息 ，通过column.property可获取当前单元的title得name值
      // console.log(event); //获取当前指针事件
      // console.log(event.path[1].id); //获取当前指针所指向的单元格id值，配合标签的id使用

      //获取所点击按钮的id值-并根据不同的id值进行跳转&传值等
      if (event.path[1].id) {
        //由操作-合作-公司列表-向-公司关联设备页面传值
        if (event.path[1].id === "companydvcid") {
          this.$router
            .push({
              path: "/companydvclist",
              query: { id: row.id, name: row.name },
            })
            .catch((error) => error);
        }
        //由操作-合作-设备合伙人列表-向-合伙人关联设备页面传值
        if (event.path[1].id === "partnerdvcid") {
          this.$router
            .push({ path: "/partnerdvclist", query: { id: row.id } })
            .catch((error) => error);
        }
      }

      // console.log("当前的column.property 是：" + column.property);
      if (column.property === "secondagent") {
        //跳转二级代理
        this.$router.push("/agentcensustwo").catch((error) => error);
      }
    },
    //table表-操作中-删除按钮（函数）
    deleteRow(index, rows) {
      //在table表中删除当前行信息，而rows.splice(index, 1)仅属于前端删除，可配合后具体操作
      rows.splice(index, 1);
    },
    //table表中-当选项发生变化是触发该函数
    handleSelectionChange(val) {
      console.log("this is:" + val);
      this.multipleSelection = val;
    },
    //分页器中-当选择条数时触发该函数
    handleSizeChange(val) {
      console.log(`每页 ${val} 条`);
      this.PageSize = val;
      this.currentPage = 1;

      //加载loading
      this.loading = true;
      //通过Ajax向后台获取数据
      axios
        .get("/manage/Manage/exportList", {
          headers: {
            Auth: localStorage.getItem("token"),
          },
          params: {
            filename: this.pagesdatas.filename,
            status: this.pagesdatas.status,
            page: 1,
            page_size: val,
          },
        })
        .then((response) => {
          this.myaxiosfunc(response.data.result);
        })
        .catch(function (error) {
          // 请求失败处理
          console.log(error);
        });
    },
    //分页器中-当选择分页时触发该函数
    handleCurrentChange(val) {
      console.log(`当前页: 第${val}页`);
      this.currentPage = val;

      //加载loading
      this.loading = true;
      //通过Ajax向后台获取数据
      axios
        .get("/manage/Manage/exportList", {
          headers: {
            Auth: localStorage.getItem("token"),
          },
          params: {
            filename: this.pagesdatas.filename,
            status: this.pagesdatas.status,
            page: val,
            page_size: this.PageSize,
          },
        })
        .then((response) => {
          this.myaxiosfunc(response.data.result);
        })
        .catch(function (error) {
          // 请求失败处理
          console.log(error);
        });
    },
    //table表中-表格带状态-可扩展
    tableRowClassName({ rowIndex }) {
      //隔行显示
      if ((rowIndex + 1) % 2 === 0) {
        return "warning-row";
      }
      return "";
    },
    //点击按钮-弹出弹框并赋值弹窗基本配置
    addTanClick(taninfo) {
      console.log(taninfo);
    },
    //点击进行路由页面跳转以及携带参数
    routeraddrClick(routeraddr) {
      this.$router.push(routeraddr).catch((error) => error);
    },

    //提交等相关event事件函数
    //获取Container-Pagedata-Maintwo-搜索-返回的数据
    getSeachinfoEvent(data) {
      console.log("点击查询获取的data数组：" + JSON.stringify(data));
      this.pagesdatas = data; //将筛选条件赋值给本页的pagesdatas存储，供分页使用
      //加载loading
      this.loading = true;
      //通过Ajax向后台获取数据
      axios
        .get("/manage/Manage/exportList", {
          headers: {
            Auth: localStorage.getItem("token"),
          },
          params: {
            filename: data.filename,
            status: data.status,
            page_size: this.PageSize,
          },
        })
        .then((response) => {
          this.myaxiosfunc(response.data.result);
        })
        .catch(function (error) {
          // 请求失败处理
          console.log(error);
        });
    },
    //数据渲染
    myaxiosfunc(successdata) {
      console.log(successdata);
      var newdata = successdata.data;
      for (var i = 0; i < newdata.length; i++) {
        if (newdata[i].status == 1) {
          newdata[i].extstatus = "等待导出";
        } else {
          if (newdata[i].status == 2) {
            newdata[i].extstatus = "正在导出";
          } else {
            if (newdata[i].status == 3) {
              newdata[i].extstatus = "导出成功";
            } else {
              if (newdata[i].status == 4) {
                newdata[i].extstatus = "导出失败";
              }
            }
          }
        }
      }
      // console.log('当前页面的data:' + JSON.stringify(newdata))
      this.mainthreetableData = newdata;
      this.totalCount = successdata.total;
      this.currentpage = successdata.current_page; //当前页面页数
      this.lastpage = successdata.last_page;
      this.loading = false;
    },
  },
  created() {
    if (this.mytoken === null) {
      this.isshow = false;
      this.$router.push("/").catch((error) => error);
    } else {
      axios
        .get("/manage/Manage/exportList", {
          headers: {
            Auth: localStorage.getItem("token"),
          },
          params: {
            page_size: 20,
          },
        })
        .then((response) => {
          this.myaxiosfunc(response.data.result);
        })
        .catch(function (error) {
          // 请求失败处理
          console.log(error);
        });
    }
  },
  components: {
    mainone,
    maintwo,
  },
});
</script>
<style>
.mytabletext {
  color: var(--el-color-primary);
  cursor: pointer;
}
.dialog-box-flex {
  display: flex;
  flex-direction: column;
  justify-self: center;
}

/*操作中-按钮样式*/
.el-button--mini {
  min-height: 28px;
  padding: 0 8px;
  font-size: 12px;
  border-radius: calc(var(--el-border-radius-base) - 1px);
}
</style>
