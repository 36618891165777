<template>
    <div>
        <mainone :mainonebread="mainonebread"></mainone>
        <div v-loading="loading" element-loading-text="正在飞速加载中...">
            <!--标题-->
            <div class="box" style="">
                <div class="box_title" style="">
                    <div class="box_title_font">编辑分类</div>
                    <div class="box_title_button"></div>
                </div>
                <!--内容-->
                <div class="box_content">
                    <el-row style="margin-top: 20px">
                        <!--占位符-->
                        <el-col :span="24" :xs="24" :sm="24" :lg="6">
                            <div style="width: 100%"></div>
                        </el-col>
                        <!--主表样式-->
                        <el-col :span="24" :xs="24" :sm="24" :lg="9">
                            <el-form ref="ruleForm" :model="ruleForm" :rules="rules" label-width="120px" class="demo-ruleForm">
                                <el-form-item label="分类名称：" prop="sort_key">
                                    <el-input v-model="ruleForm.sort_key" clearable show-word-limit maxlength="6"></el-input>
                                </el-form-item>
                                <el-form-item label="排序：" prop="order">
                                    <el-input v-model="ruleForm.order" placeholder="数字越大排序越靠前" clearable></el-input>
                                </el-form-item>
                                <el-form-item label="是否显示：" prop="status">
                                    <el-radio-group v-model="ruleForm.status">
                                        <el-radio label="1">是</el-radio>
                                        <el-radio label="2">否</el-radio>
                                    </el-radio-group>
                                </el-form-item>
                                <el-form-item>
                                    <el-button type="primary" @click="submitForm('ruleForm')" style="width: 40%;margin-right: 10px">提交 </el-button>
                                    <el-button @click="resetForm('ruleForm')">取消</el-button>
                                </el-form-item>
                            </el-form>
                        </el-col>
                        <!--占位符-->
                        <el-col :span="24" :xs="24" :sm="24" :lg="9">
                            <div style="width: 100%"></div>
                        </el-col>
                    </el-row>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import mainone from '@/components/Mainone'
import { ElMessage } from 'element-plus'
import { ref } from 'vue'
import axios from 'axios'

export default {
    name: 'vcurriculumclassifyadd',
    data() {
        return {
            mainonebread: [
                {
                    name: '教育'
                },
                {
                    name: '课程管理'
                },
                {
                    name: '课程分类',
                    path: '/curriculumclassify'
                },
                {
                    name: '编辑分类'
                }
            ], // 当前页-【面包屑导航】

            ruleForm: {
                sort_key: '',
                order: '',
                status: ref('1')
            }, //form表单
            rules: {
                sort_key: [
                    {
                        required: true,
                        message: '分类名称为必填项',
                        trigger: 'blur'
                    }
                ],
                order: [
                    {
                        required: true,
                        message: '排序为必填项',
                        trigger: 'blur'
                    }
                ],
                status: [
                    {
                        required: false,
                        message: '请至少选择一个状态',
                        trigger: 'change'
                    }
                ]
            }, //form表单-必填规则

            mytoken: localStorage.getItem('token'), //获取存储的token

            loading: false
        }
    },
    methods: {
        //提交form表单-并判断是否必填
        submitForm(formName) {
            let getdatas = this.$refs[formName].model
            this.$refs[formName].validate((valid) => {
                if (valid) {
                    axios
                        .post(
                            '/manage/Course/editCourseCate',
                            {
                                id: this.$route.query.id,
                                sort_key: getdatas.sort_key,
                                order: getdatas.order,
                                status: getdatas.status
                            },
                            {
                                headers: {
                                    Auth: localStorage.getItem('token')
                                }
                            }
                        )
                        .then((response) => {
                            if (response.data.code === 0) {
                                ElMessage({
                                    type: 'success',
                                    message: '提交成功！',
                                    duration: 1000,
                                    onClose: () => {
                                        this.$router.back()
                                    }
                                })
                            }
                        })
                        .catch(function(error) {
                            console.log(error)
                        })
                } else {
                    console.log('error submit!!')
                    return false
                }
            })
        },
        //重置form表单中填写的数据
        resetForm(formName) {
            this.$refs[formName].resetFields()
            this.$router.back()
        }
    },
    created() {
        this.loading = true
        if (localStorage.getItem('token') === null || !localStorage.getItem('token')) {
            this.isshow = false
            this.$router.push('/').catch((error) => error)
        } else {
            let routedatas = this.$route.query
            this.ruleForm = {
                sort_key: routedatas.sort_key,
                order: routedatas.order,
                status: ref(routedatas.status.toString())
            }
            this.loading = false
        }
    },
    components: {
        mainone
    }
}
</script>

<style scoped>
.box {
    background-color: #ffffff;
    border-radius: 4px;
    color: #333333;
    padding: 10px 20px;
}

.box_title {
    line-height: 40px;
    height: 40px;
}

.box_title_font {
    float: left;
    font-weight: 400;
    font-size: 14px;
    color: #333333;
}

.box_title_button {
    float: right;
    font-weight: 400;
    font-size: 14px;
}

.box_title_button:hover {
    cursor: pointer;
}

.box_content {
    border-top: 1px solid #eeeeee;
    margin-top: 10px;
}
</style>
