<template>
    <div class="images-box" :style="{ border: images.length > 0 ? '1px dashed #dedede' : 'none' }">
        <div class="images-one">
            <div v-for="(item, index) in images" :key="index">
                <el-image :style="styles" :src="item" :zoom-rate="1.2" :preview-src-list="images" :fit="fit" />
            </div>
        </div>
    </div>
</template>

<script>
import { defineComponent } from 'vue'

export default defineComponent({
    name: 'dpw-images',
    props: {
        styles: {
            default: { width: '100px', height: '100px' }
        },
        fit: {
            type: String,
            default: 'cover'
        },
        images: {
            default: []
        }
    },
    data() {
        return {}
    }
})
</script>

<style scoped>
.images-box {
    display: table;
    padding: 10px;
    border-radius: 8px;
}

.images-one {
    display: flex;
    flex-direction: row;
    justify-content: left;
    align-items: center;
    flex-wrap: wrap;
    gap: 10px;
}
</style>
