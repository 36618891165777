<template>
    <div>
        <el-table
            :header-cell-style="{ background: '#F5F7FA' }"
            ref="multipleTable"
            :row-class-name="tableRowClassName"
            :data="mainthreetableData.slice((currentPage - lastpage) * PageSize, currentPage * PageSize)"
            style="width: 100%;margin-top:10px"
            min-height="900"
            border
            v-loading="loading"
            element-loading-text="正在飞速加载中..."
            @selection-change="handleSelectionChange"
            @cell-click="handleOneData"
        >
            <!-- <el-table-column type="selection" width="55" /> -->
            <el-table-column prop="id" label="序号" min-width="100" />
            <el-table-column prop="code" label="兑换码" min-width="200" />
            <el-table-column prop="qrcode" label="兑换二维码" min-width="120">
                <template #default="scope">
                    <div @click="tanQrcodeEvent(scope.row)">
                        <span class="look-box">查看</span>
                    </div>
                </template>
            </el-table-column>
            <el-table-column prop="status" label="状态" min-width="120">
                <template #default="scope">
                    <div>
                        <span>{{ scope.row.status ? (scope.row.status == 1 ? '已兑换' : '未兑换') : '' }}</span>
                    </div>
                </template>
            </el-table-column>
        </el-table>
        <div class="block" style="margin: 20px 0 10px 0">
            <el-pagination
                background
                @size-change="handleSizeChange"
                @current-change="handleCurrentChange"
                :current-page="currentPage"
                :page-sizes="pageSizes"
                :page-size="PageSize"
                layout="total, sizes, prev, pager, next"
                :total="totalCount"
            >
            </el-pagination>
        </div>
        <!-- 兑换二维码-弹窗 -->
        <el-dialog v-model="dialogVisible" title="兑换二维码" :width="this.searchwidth" :before-close="handleCloseSenior">
            <div class="dialog-box-flex" style="text-align: center;min-height: 400px;">
                <div>
                    <span style="color: red; font-size: 14px"> 小程序兑换码 : {{ this.minicode }} </span>
                </div>
                <div v-if="this.downcodeurl == ''" style="margin-top: 30px;">
                    <i class="el-icon-loading" style="font-size: 32px;"></i>
                </div>
                <div v-else style="margin-top: 10px; padding: 10px;text-align: center;">
                    <el-image :src="this.downcodeurl" style="height: 400px;padding: 10px;border: 1px solid #ececec !important;border-radius: 4px !important;" :fit="'fill'" />
                </div>
            </div>
            <div v-if="this.downcodeurl != ''" style="margin-top: 20px; text-align: center">
                <el-button type="primary" style="width: 30%" @click="downloadImageEvent(this.downcodeurl)" :disabled="disabled_download">下载二维码</el-button>
            </div>
        </el-dialog>
    </div>
</template>

<script>
import { ElMessage } from 'element-plus'
import axios from 'axios'
import { ref } from 'vue'

export default {
    name: 'ExchangeCode',
    props: ['Exchange'],
    watch: {
        Exchange: {
            handler(newVal) {
                this.exchange = newVal
                console.log(3)
            },
            deep: true,
            immediate: true
        }
    },
    data() {
        return {
            mainthreestatus: true, // 数据列表中-属于search按钮表示状态
            mainthreetableData: [], // 数据列表中-具体数据
            totalCount: 1, //数据列表中-数据总条数
            currentpage: 1, //数据列表中-数据当前页数
            lastpage: 1, //数据列表中-数据总页数（默认以20条一页）
            loading: false, //初始化-loading加载动效，默认是false关闭

            //table表单相关数据
            seniorform: {},
            currentPage: 1,
            pageSizes: [10, 20, 50, 100],
            PageSize: 10,
            multipleSelection: [],
            searchwidth: '900px',

            // 兑换二维码-弹窗
            dialogVisible: ref(false),
            downcodeurl: '', // 下载领券码-当前行信息
            minicode: '', // 小程序兑换码-字段
            disabled_download: false, // 防抖-下载按钮

            // 传值
            exchange: {
                id: ''
            },

            mypowerlimits: JSON.parse(localStorage.getItem('powerlimits')), //获取权限
            mytoken: localStorage.getItem('token'), //获取存储的token
            myuserinfo: localStorage.getItem('userinfo'), //获取存储的用户信息
            pagesdatas: [] //存储最近一次的筛选条件，供分页使用
        }
    },
    mounted() {
        console.log(2)
    },
    methods: {
        // 兑换二维码-弹窗
        tanQrcodeEvent(rowdata) {
            this.dialogVisible = ref(true)
            axios
                .post(
                    '/manage/coinActivity/getCodeImage',
                    {
                        code_id: rowdata.id
                    },
                    {
                        headers: {
                            Auth: localStorage.getItem('token')
                        }
                    }
                )
                .then((response) => {
                    if (response.data.code === 0) {
                        let datas = response.data.result
                        this.minicode = datas.code
                        this.downcodeurl = datas.code_img
                    }
                })
                .catch(function(error) {
                    // 请求失败处理
                    ElMessage({
                        type: 'error',
                        message: '请求失败！' + error
                    })
                })
        },
        // 下载二维码
        downloadImageEvent(codeurl) {
            this.disabled_download = true
            window.open(codeurl)
            this.dialogVisible = false
            setTimeout(() => {
                this.disabled_download = false
            }, 1000)
        },
        // 兑换二维码-关闭
        handleCloseSenior() {
            this.dialogVisible = ref(false)
        },

        //分页器中-当选择条数时触发该函数
        handleSizeChange(val) {
            // console.log(`每页 ${val} 条`)
            this.PageSize = val
            this.currentPage = 1

            this.pagesdatas.page = 1
            this.pagesdatas.page_size = val

            this.getAxiosEvent(this.pagesdatas)
        },
        //分页器中-当选择分页时触发该函数
        handleCurrentChange(val) {
            // console.log(`当前页: 第${val}页`)
            this.currentPage = val

            this.pagesdatas.page = val
            this.pagesdatas.page_size = this.PageSize

            this.getAxiosEvent(this.pagesdatas)
        },
        // 获取列表数据
        getAxiosEvent(data) {
            this.loading = true
            axios
                .post('/manage/coinActivity/codeList', data, {
                    headers: {
                        Auth: localStorage.getItem('token')
                    }
                })
                .then((response) => {
                    this.myaxiosfunc(response.data.result)
                })
                .catch(function(error) {
                    console.log(error)
                })
        },
        //数据渲染
        myaxiosfunc(successdata) {
            var newdata = successdata.data
            this.mainthreetableData = newdata
            this.totalCount = successdata.total
            this.currentpage = successdata.current_page //当前页面页数
            this.lastpage = successdata.last_page
            this.loading = false
        },
        //获取当前windows页面信息-实时修改【弹窗】的宽度
        getwindowinfo() {
            this.sreenheight = document.documentElement.clientHeight
            this.sreenwidth = document.documentElement.clientWidth
            if (this.sreenwidth < 920) {
                this.searchwidth = this.sreenwidth * 0.8 + 'px'
            } else {
                this.searchwidth = '900px'
            }
        },

        // 外部获取数据
        outInfoRefEvent(data) {
            this.pagesdatas = {
                id: data.id,
                page: 1,
                page_size: this.PageSize
            }
            this.getAxiosEvent(this.pagesdatas)
        }
    },
    created() {
        if (localStorage.getItem('token') === null || !localStorage.getItem('token')) {
            this.isshow = false
            this.$router.push('/').catch((error) => error)
        } else {
            window.addEventListener('resize', this.getwindowinfo) // 注册监听器-实时检测浏览器页面宽高
            this.getwindowinfo()

            // 首次加载通过created
            this.outInfoRefEvent(this.exchange)
        }
    },
    components: {}
}
</script>

<style scoped>
.look-box {
    text-decoration: underline;
    color: var(--el-color-primary);
}
.look-box:hover {
    cursor: pointer;
    color: var(--el-color-danger);
}
</style>
